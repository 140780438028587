import { ConfiguredAutonomousSystemNumbersSubscription } from "../../graphql/generated/operations";
import { ConfiguredASNsResult } from "../../types/hooks/useConfiguredASNs";
import { isNonEmptyArray } from "../validation";

export const formatter = (
  /** @type {ConfiguredAutonomousSystemNumbersSubscription | undefined} */ data
) => data?.configuredAutonomousSystems.map(({ number }) => number);

/**
 *
 * @param {ConfiguredASNsResult["data"]} configuredASNs
 * @returns {boolean}
 */
export const noAutonomousSystemsConfigured = (configuredASNs) =>
  !isNonEmptyArray(configuredASNs);
