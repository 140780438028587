import { AutonomousSystemsTable } from "../components/AutonomousSystemsTable";
import { ReactElement } from "react";

/**
 * @param {object} props
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
const AutonomousSystems = ({ urlParams }) => {
  return <AutonomousSystemsTable urlParams={urlParams} />;
};

export { AutonomousSystems };
