import { COLUMNS_ENUM } from "../../constants/queries";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsConfiguredASVisible
 * @param {Function} params.setIsNeighborASVisible
 * @param {Function} params.setIsPrefixVisible
 * @param {Function} params.setIsASPathVisible
 * @param {Function} params.setIsCommunitiesVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsConfiguredASVisible,
  setIsNeighborASVisible,
  setIsPrefixVisible,
  setIsASPathVisible,
  setIsCommunitiesVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.CONFIGURED_AS.name] =
    setIsConfiguredASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.NEIGHBOR_AS.name] =
    setIsNeighborASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.PREFIX.name] = setIsPrefixVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.AS_PATH.name] = setIsASPathVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.COMMUNITIES.name] =
    setIsCommunitiesVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};
