import { useConfiguredPrefixesSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useConfiguredPrefixes";
import { configuredPrefixesAtom } from "../other/store";
import { useData } from "./useData";

export const useConfiguredPrefixes = () => {
  useData({
    operation: useConfiguredPrefixesSubscription,
    formatter,
    atom: configuredPrefixesAtom,
  });
};
