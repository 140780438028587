import { allowedPrefixChars } from "../constants/utils/validation";

/**
 * @param {string} prefixInput
 * @returns {boolean}
 */
export const isPrefixInputValid = (prefixInput) =>
  !!prefixInput &&
  allPrefixCharsAreValid(prefixInput) &&
  includesASingleSlash(prefixInput);

/**
 * @param {string[]} [prefixArray]
 * @returns {boolean}
 */
export const isPrefixArrayValid = (prefixArray) => {
  if (!prefixArray || prefixArray.length === 0) return false;
  return prefixArray.reduce((acc, prefix) => {
    return acc && isPrefixInputValid(prefix);
  }, true);
};

/**
 * @param {string} prefix
 * @returns {boolean}
 */
const allPrefixCharsAreValid = (prefix) =>
  Array.from(prefix).every((char) => allowedPrefixChars.includes(char));

/**
 * @param {string} prefix
 * @returns {boolean}
 */
const includesASingleSlash = (prefix) => {
  const firstOccurence = prefix.indexOf("/");
  if (firstOccurence === -1) return false;
  const lastOccurence = prefix.lastIndexOf("/");
  return (
    firstOccurence === lastOccurence &&
    firstOccurence !== 0 &&
    lastOccurence !== prefix.length - 1
  );
};

/**
 * @param {any[]?} [array]
 * @returns {boolean}
 */
export const isNonEmptyArray = (array) =>
  Array.isArray(array) && array.length > 0;

export const isInputNumeric = (input) => {
  if (!input) {
    return false;
  }
  if (input.startsWith("0")) {
    return false;
  }
  for (const char of input) {
    if (!(char >= "0" && char <= "9")) {
      return false;
    }
  }
  return true;
};

/**
 * @param {string} asnInput
 * @returns {boolean}
 */
export const isASNInputValid = (asnInput) => isInputNumeric(asnInput);

/**
 * @param {string[]} [asnArray]
 * @returns {boolean}
 */
export const isASNInputArrayValid = (asnArray) => {
  if (!asnArray || asnArray.length === 0) return false;
  return asnArray.reduce((acc, asn) => {
    return acc && isASNInputValid(asn);
  }, true);
};

/**
 * @param {string} jsonInput
 * @returns {boolean}
 */
export const isJSONInputValid = (jsonInput) => {
  try {
    JSON.parse(jsonInput);
  } catch (e) {
    return false;
  }
  return true;
};
