import { ReactElement } from "react";
import { Dialog, Stack, Typography } from "@mui/material";
import { useDeleteAlertRulesMutation } from "../graphql/generated/operations";
import { Close } from "../types/components/dialog";
import { ButtonStrip } from "./ButtonStrip";
import { parameterNameMap } from "../constants/components/argsTable";
import { ArgsTable } from "./ArgsTable";
import { regexToASes } from "../other/utils";
import { COLUMNS_ENUM } from "../constants/components/confAlertsTable";
import { ToWords } from "to-words";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {string[]} props.alertRules
 * @returns {ReactElement}
 */
export const RemoveConfAlertDialog = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  alertRules,
}) => {
  const [, deleteAlertRules] = useDeleteAlertRulesMutation();
  const numberOfAlertRulesToRemove = alertRules.length;
  const onRemoveAlertRules = async () => {
    if (numberOfAlertRulesToRemove < 1) {
      console.error("Invalid number of alert rules to remove.");
      close();
      return;
    }

    const result = await deleteAlertRules({
      ids: alertRules.map(({ id }) => id),
    });

    let snackbarMessage = `Removed Alert Rule ${alertRules[0]?.name}`;
    let errorMessage = `Could not remove Alert Rule ${alertRules[0]?.name}:`;
    if (numberOfAlertRulesToRemove > 1) {
      snackbarMessage = `Removed ${numberOfAlertRulesToRemove} Alert Rules`;
      errorMessage = `Could not remove ${numberOfAlertRulesToRemove} Alert Rules:`;
    }

    const numberOfRemovedAlertRules =
      result.data?.deleteAlertSubscriptions?.affected_rows;
    if (numberOfRemovedAlertRules === numberOfAlertRulesToRemove) {
      setSnackbarMessage(snackbarMessage);
      openSuccessSnackbar();
    } else {
      console.error(errorMessage, result);
    }

    close();
  };

  const infoTables = [];
  const toWords = new ToWords();

  alertRules.forEach((alert) => {
    switch (alert["alldata"][COLUMNS_ENUM.TYPE.order]) {
      case "as_path_presence":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.asn_present_regex.name,
                  value: regexToASes(
                    alert["alldata"][COLUMNS_ENUM.VARS.order][
                      "asn_present_regex"
                    ]
                  ).join(", "),
                },
              ]}
            />
          </div>
        );
        break;
      case "as_path_regex_violation":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.as_path_regex.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["as_path_regex"],
                },
              ]}
            />
          </div>
        );
        break;
      case "bogon_as":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
              ]}
            />
          </div>
        );
        break;
      case "as_path_diffs":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.base_service.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["base_service"],
                },
                {
                  parameter: parameterNameMap.comp_service.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["comp_service"],
                },
                {
                  parameter: parameterNameMap.end_asn.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["end_asn"],
                },
              ]}
            />
          </div>
        );
        break;
      case "prefix_diffs":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.base_service.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["base_service"],
                },
                {
                  parameter: parameterNameMap.comp_service.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["comp_service"],
                },
              ]}
            />
          </div>
        );
        break;
      case "bogon_prefix_exact":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.bogon_prefixes.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["bogon_prefixes"],
                },
              ]}
            />
          </div>
        );
        break;
      case "as_origin_violation_exact":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
              ]}
            />
          </div>
        );
        break;
      case "as_origin_violation_sub":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
              ]}
            />
          </div>
        );
        break;
      case "unexpected_neighbor_prefix":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.origin_asn.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["origin_asn"],
                },
                {
                  parameter: parameterNameMap.neighbor_asns.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["neighbor_asns"],
                },
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
              ]}
            />
          </div>
        );
        break;
      case "unexpected_peering":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asn.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["configured_asn"],
                },
                {
                  parameter: parameterNameMap.neighbor_asns.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["neighbor_asns"],
                },
              ]}
            />
          </div>
        );
        break;
      case "unexpected_originated_prefixes":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asn.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asn"],
                },
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.ds_thres.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["ds_thres"],
                },
              ]}
            />
          </div>
        );
        break;
      case "rpki_invalid_announce":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.rpki_status.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["rpki_status"],
                },
              ]}
            />
          </div>
        );
        break;
      case "rpki_invalid_detect":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.rpki_status.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["rpki_status"],
                },
              ]}
            />
          </div>
        );
        break;
      case "rpki_invalid_propagate":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: [
                    regexToASes(
                      alert["alldata"][COLUMNS_ENUM.VARS.order]["asn_regex"]
                    ).join(", "),
                  ],
                },
                {
                  parameter: parameterNameMap.rpki_status.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["rpki_status"],
                },
              ]}
            />
          </div>
        );
        break;
      case "rpki_not_found_propagate":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: [
                    regexToASes(
                      alert["alldata"][COLUMNS_ENUM.VARS.order]["asn_regex"]
                    ).join(", "),
                  ],
                },
                {
                  parameter: parameterNameMap.rpki_status.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["rpki_status"],
                },
              ]}
            />
          </div>
        );
        break;
      case "squatting":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.ds_thres.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["ds_thres"],
                },
              ]}
            />
          </div>
        );
        break;
      case "bogon_prefix_sub":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.bogon_prefixes.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["bogon_prefixes"],
                },
              ]}
            />
          </div>
        );
        break;
      case "as_path_length_violation":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.as_path_length_thres.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order][
                      "as_path_length_thres"
                    ],
                },
              ]}
            />
          </div>
        );
        break;
      case "low_peering_visibility":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asn.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["configured_asn"],
                },
                {
                  parameter: parameterNameMap.neighbor_asns.name,
                  value:
                    alert["alldata"][COLUMNS_ENUM.VARS.order]["neighbor_asns"],
                },
                {
                  parameter: parameterNameMap.ds_thres.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["ds_thres"],
                },
              ]}
            />
          </div>
        );
        break;
      case "low_prefix_visibility":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.asns.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["asns"],
                },
                {
                  parameter: parameterNameMap.prefixes.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["prefixes"],
                },
                {
                  parameter: parameterNameMap.ds_thres.name,
                  value: alert["alldata"][COLUMNS_ENUM.VARS.order]["ds_thres"],
                },
              ]}
            />
          </div>
        );
        break;
      case "custom":
        infoTables.push(
          <div style={{ paddingBottom: "50px" }}>
            {alertRules.length > 1 ? (
              <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
                {'"'}
                {alert.name}
                {'"'}
              </Typography>
            ) : null}
            <ArgsTable
              rows={[
                {
                  parameter: parameterNameMap.query.name,
                  value: alert["alldata"][COLUMNS_ENUM.QUERY.order],
                },
                {
                  parameter: parameterNameMap.query_params.name,
                  value: JSON.stringify(
                    alert["alldata"][COLUMNS_ENUM.VARS.order]
                  ),
                },
                {
                  parameter: parameterNameMap.fire_alert_regex.name,
                  value: alert["alldata"][COLUMNS_ENUM.FIRE_ALERT_REGEX.order],
                },
                {
                  parameter: parameterNameMap.description.name,
                  value: alert["alldata"][COLUMNS_ENUM.DESCRIPTION.order],
                },
                {
                  parameter: parameterNameMap.severity.name,
                  value: alert["alldata"][COLUMNS_ENUM.SEVERITY.order],
                },
              ]}
            />
          </div>
        );
        break;
      default:
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          Unsupported Alert Type.
        </Typography>;
        break;
    }
  });

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{ p: "2rem", height: "35rem", justifyContent: "space-between" }}
      >
        {numberOfAlertRulesToRemove > 1 ? (
          <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
            Confirm removal of{" "}
            {toWords.convert(numberOfAlertRulesToRemove).toLowerCase()} (
            {numberOfAlertRulesToRemove}) Alert Rules:
            <br />
            <br />
          </Typography>
        ) : (
          <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
            Confirm removal of Alert Rule {'"'}
            {alertRules[0]?.name}
            {'"'}
          </Typography>
        )}
        {infoTables}
        <Typography sx={{ color: "gray2.main", paddingBottom: "20px" }}>
          {numberOfAlertRulesToRemove > 1
            ? "Once the Alert Rules are deleted, they can not be retrieved."
            : "Once the Alert Rule is deleted, it can not be retrieved."}
        </Typography>
        <Stack style={{ paddingBottom: "50px" }}>
          <ButtonStrip
            onSecondaryButtonClick={close}
            onMainButtonClick={onRemoveAlertRules}
            mainButtonTitle="Remove"
            mainButtonCustomStyles={{
              backgroundColor: "red1.main",
              width: "6.5rem",
              ":hover": {
                backgroundColor: "red1.light",
              },
            }}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
