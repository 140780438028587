import { Close } from "../../types/components/dialog";
import {
  ManuallyConfiguredPrefixes,
  ManuallyConfiguredPrefixes_Insert_Input,
  InsertManuallyConfiguredPrefixMutation,
  InsertManuallyConfiguredPrefixesMutation,
  Exact,
} from "../../graphql/generated/operations";
import { Tab } from "@mui/material";
import { TABS } from "../../constants/components/addConfPrefixDialog";
import { OperationResult } from "urql";

/**
 * @param {object} params
 * @param {string} params.prefixInput
 * @param {Function} params.addConfPrefix
 * @param {Function} params.setFailureReason
 * @param {Function} params.setSnackbarMessage
 * @param {Function} params.openSuccessSnackbar
 * @param {Close} params.close
 * @returns {Promise<void>}
 */
export const onAddPrefix = async ({
  prefixInput,
  addConfPrefix,
  setFailureReason,
  setSnackbarMessage,
  openSuccessSnackbar,
  close,
}) => {
  const prefix = prefixInput.toLowerCase();
  /** @type {OperationResult<InsertManuallyConfiguredPrefixMutation, Exact<...>>} */
  const { data, error } = await addConfPrefix({ prefix });

  if (error) {
    const errorMessage = error.message;
    if (errorMessage) {
      if (errorMessage.includes("Uniqueness violation")) {
        setFailureReason("Prefix already exists");
        return;
      }
      setFailureReason(errorMessage);
      return;
    }
    console.error(error);
    setFailureReason("An error has occurred.");
    return;
  }

  if (
    !data ||
    !data.insertManuallyConfiguredPrefix ||
    data.insertManuallyConfiguredPrefix.reject ||
    !data.insertManuallyConfiguredPrefix.network
  ) {
    console.error(`Could not add Prefix Filter. data: ${JSON.stringify(data)}`);
    setFailureReason("Could not add Prefix Filter");
    return;
  }

  const addedPrefix = data.insertManuallyConfiguredPrefix.network;

  setSnackbarMessage(`Added Prefix ${addedPrefix}`);
  openSuccessSnackbar();
  close();
};

/**
 * @param {object} params
 * @param {object[]} [params.confPrefixes]
 * @param {Function} params.addConfPrefixes
 * @param {Function} params.setFailureReason
 * @param {Function} params.setSnackbarMessage
 * @param {Function} params.openSuccessSnackbar
 * @param {Close} params.close
 * @returns {Promise<void>}
 */
export const onAddPrefixes = async ({
  confPrefixes,
  addConfPrefixes,
  setFailureReason,
  setSnackbarMessage,
  openSuccessSnackbar,
  close,
}) => {
  /** @type {OperationResult<InsertManuallyConfiguredPrefixesMutation, Exact<...>>} */
  const { data, error } = await addConfPrefixes({ prefixes: confPrefixes });
  if (error) {
    const errorMessage = error.message;
    if (errorMessage) {
      if (errorMessage.includes("Uniqueness violation")) {
        setFailureReason("Some prefixes already exist. Operation aborted.");
        return;
      }
      setFailureReason(errorMessage);
      return;
    }
    console.error(error);
    setFailureReason("An error has occurred.");
    return;
  }
  if (
    !data ||
    !data.insertManuallyConfiguredPrefixes ||
    data.insertManuallyConfiguredPrefixes.affected_rows < 1 ||
    !allPrefixesAdded(
      confPrefixes,
      data.insertManuallyConfiguredPrefixes.returning
    )
  ) {
    console.error(
      `Could not add Prefix Filters. data: ${JSON.stringify(data)}`
    );
    setFailureReason("Could not add Prefix Filters");
    return;
  }
  const addedPrefixesLength =
    data.insertManuallyConfiguredPrefixes.returning.length;
  const snackbarMessage =
    addedPrefixesLength === 1
      ? `Added Prefix ${confPrefixes?.[0]["network"]}`
      : `Added ${addedPrefixesLength} Prefixes`;
  setSnackbarMessage(snackbarMessage);
  openSuccessSnackbar();
  close();
};

/**
 * @param {ManuallyConfiguredPrefixes_Insert_Input[] | undefined} expectedPrefixes
 * @param {ManuallyConfiguredPrefixes[]} addedPrefixes
 * @returns {boolean}
 */
const allPrefixesAdded = (expectedPrefixes, addedPrefixes) =>
  !!expectedPrefixes &&
  !!addedPrefixes &&
  Array.isArray(expectedPrefixes) &&
  Array.isArray(addedPrefixes) &&
  expectedPrefixes.length === addedPrefixes.length;

export const tabsJSX = [0, 1].map((tabIndex) => (
  <Tab
    key={TABS[tabIndex]}
    sx={{
      textTransform: "none",
      fontSize: "1rem",
      whiteSpace: "nowrap",
      color: "gray5.main",
    }}
    label={TABS[tabIndex]}
  />
));
