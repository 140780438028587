import { ReactElement } from "react";
import { Table } from "./Table";
import { TryGraphQLButton } from "./TryGraphQLButton";
import { TableProps } from "../types/components/table";
import { Loading, Error } from "../types/dataFetching";
import { getQueryAnswerTableStateAtom } from "../utils/other/store";
import { useAtom } from "jotai";
import { onViewColumnsChange } from "../utils/components/queryAnswerTable";

/**
 * @param {object} props
 * @param {boolean} props.hasSelectedValue
 * @param {TableProps["data"]} props.data
 * @param {TableProps["columns"]} props.columns
 * @param {TableProps["customOptions"]} props.options
 * @param {string} props.link
 * @param {Loading} props.loading
 * @param {Error} props.error
 * @param {string} props.atomKey
 * @param {Function} [props.getRowID]
 * @returns {ReactElement}
 */
export const QueryAnswerTable = ({
  hasSelectedValue,
  data,
  columns,
  options,
  link,
  loading,
  error,
  atomKey,
  getRowID,
}) => {
  const {
    pageAtom,
    rowsPerPageAtom,
    countAtom,
    expandedRowIDsAtom,
    isConfiguredASVisibleAtom,
    isNeighborASVisibleAtom,
    isPrefixVisibleAtom,
    isASPathVisibleAtom,
    isCommunitiesVisibleAtom,
  } = getQueryAnswerTableStateAtom({ atomKey });
  const [, setIsConfiguredASVisible] = useAtom(isConfiguredASVisibleAtom);
  const [, setIsNeighborASVisible] = useAtom(isNeighborASVisibleAtom);
  const [, setIsPrefixVisible] = useAtom(isPrefixVisibleAtom);
  const [, setIsASPathVisible] = useAtom(isASPathVisibleAtom);
  const [, setIsCommunitiesVisible] = useAtom(isCommunitiesVisibleAtom);
  const [count, setCount] = useAtom(countAtom);
  const [expandedRowIDs, setExpandedRowIDs] = useAtom(expandedRowIDsAtom);
  if (!hasSelectedValue) return <></>;

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      setIsConfiguredASVisible,
      setIsNeighborASVisible,
      setIsPrefixVisible,
      setIsASPathVisible,
      setIsCommunitiesVisible,
    });
  };

  return (
    <Table
      title={<TryGraphQLButton link={link} />}
      data={data}
      loading={loading}
      error={error}
      columns={columns}
      customOptions={options}
      pageAtom={pageAtom}
      rowsPerPageAtom={rowsPerPageAtom}
      count={count}
      setCount={setCount}
      onViewColumnsChange={onViewColumnsChangeCallback}
      getRowID={getRowID}
      expandedRowIDs={expandedRowIDs}
      setExpandedRowIDs={setExpandedRowIDs}
    />
  );
};
