// import { Close } from "../../types/components/dialog";
import {
  UpdateAlertSubscriptionMutation,
  OperationResult,
  Exact,
} from "../../graphql/generated/operations";
import { alertRuleReceiverTypeInverse } from "../../constants/components/confAlertsInfoTable";
import {
  unformatDataService,
  getSelectedPrefixesGQLList,
  getSelectedASesRegex,
} from "../../other/utils";

/**
 * @param {object} params
 * @param {object} params.mutationObject
 * @param {Function} params.editAlertRule
 * @param {Function} params.setFailureReason
 * @param {Function} params.setSnackbarMessage
 * @param {Function} params.openSuccessSnackbar
 * @param {Function} params.onClose
 * @returns {Promise<void>}
 */
export const onEditAlertRule = async ({
  mutationObject,
  editAlertRule,
  setFailureReason,
  setSnackbarMessage,
  openSuccessSnackbar,
  onClose,
}) => {
  /** @type {OperationResult<UpdateAlertSubscriptionMutation, Exact<...>>} */
  const { data, error } = await editAlertRule(mutationObject);

  if (error) {
    const errorMessage = error.message;
    if (errorMessage) {
      setFailureReason(errorMessage);
      return;
    }
    console.error(error);
    setFailureReason("An error has occurred.");
    return;
  }

  if (
    !data ||
    !data.updateAlertSubscription ||
    !data.updateAlertSubscription.returning[0].name
  ) {
    console.error(`Could not edit Alert Rule. data: ${JSON.stringify(data)}`);
    setFailureReason("Could not edit Alert Rule");
    return;
  }

  const editedAlertRule = data.updateAlertSubscription.returning[0].name;

  setSnackbarMessage(`Edited Alert Rule ${editedAlertRule}`);
  openSuccessSnackbar();
  onClose();
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedAS
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.selectedDataSourcesThreshold
 * @returns {object}
 */
export const getEditRouteLeakAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedAS,
  selectedPrefixes,
  selectedDataSourcesThreshold,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asn: selectedAS,
      prefixes: selectedPrefixes,
      ds_thres: selectedDataSourcesThreshold,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditExactPrefixHijackAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      prefixes: selectedPrefixes,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditSubPrefixHijackAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      prefixes: getSelectedPrefixesGQLList(selectedPrefixes),
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedBaseService
 * @param {string} params.selectedCompService
 * @param {string} params.selectedEndASN
 * @returns {object}
 */
export const getEditASPathComparisonAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedBaseService,
  selectedCompService,
  selectedEndASN,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      base_service: unformatDataService(selectedBaseService),
      comp_service: unformatDataService(selectedCompService),
      end_asn: selectedEndASN,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.selectedDataSourcesThreshold
 * @returns {object}
 */
export const getEditSquattingAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedPrefixes,
  selectedDataSourcesThreshold,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      prefixes: selectedPrefixes,
      ds_thres: selectedDataSourcesThreshold,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedOriginAS
 * @param {string[]} params.selectedNeighborASes
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditNeighborLeakHijackAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedOriginAS,
  selectedNeighborASes,
  selectedPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      origin_asn: selectedOriginAS,
      neighbor_asns: selectedNeighborASes,
      prefixes: selectedPrefixes,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedRPKIPrefixes
 * @returns {object}
 */
export const getEditRPKIInvalidDetectionAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedRPKIPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      prefixes: selectedRPKIPrefixes,
      rpki_status: "invalid",
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @returns {object}
 */
export const getEditRPKIInvalidAnnouncementAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      rpki_status: "invalid",
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @returns {object}
 */
export const getEditRPKIInvalidPropagationAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asn_regex: getSelectedASesRegex(selectedASes),
      rpki_status: "invalid",
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @returns {object}
 */
export const getEditRPKINotFoundPropagationAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asn_regex: getSelectedASesRegex(selectedASes),
      rpki_status: "not-found",
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedBogonPrefixes
 * @returns {object}
 */
export const getEditBogonExactPrefixAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedBogonPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      bogon_prefixes: selectedBogonPrefixes,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedBogonPrefixes
 * @returns {object}
 */
export const getEditBogonSubPrefixAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedBogonPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      bogon_prefixes: getSelectedPrefixesGQLList(selectedBogonPrefixes),
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedAS
 * @param {string[]} params.selectedNeighborASes
 * @returns {object}
 */
export const getEditNewNeighborAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedAS,
  selectedNeighborASes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      configured_asn: selectedAS,
      neighbor_asns: selectedNeighborASes,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.customQueryParams
 * @param {string} params.customDescription
 * @returns {object}
 */
export const getEditCustomAlertRuleMutationObject = ({
  selectedID,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  customQueryParams,
  customDescription,
}) => {
  var params;
  try {
    params = JSON.parse(customQueryParams);
  } catch (e) {
    params = {};
  }
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: params,
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditBogonASAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedPrefixes
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      prefixes: selectedPrefixes,
      bogon_as_regex: "AS.*",
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedPresentASes
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditASPathPresenceAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedPresentASes,
  selectedPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      prefixes: selectedPrefixes,
      asn_present_regex: getSelectedASesRegex(selectedPresentASes),
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedASPathRegex
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditInvalidASPathPatternAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASPathRegex,
  selectedPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      prefixes: selectedPrefixes,
      as_path_regex: selectedASPathRegex,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string} params.selectedBaseService
 * @param {string} params.selectedCompService
 * @returns {object}
 */
export const getEditPrefixComparisonAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedBaseService,
  selectedCompService,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      base_service: unformatDataService(selectedBaseService),
      comp_service: unformatDataService(selectedCompService),
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.selectedDataSourcesThreshold
 * @returns {object}
 */
export const getEditPrefixVisibilityLossAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASes,
  selectedPrefixes,
  selectedDataSourcesThreshold,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      asns: selectedASes,
      prefixes: selectedPrefixes,
      ds_thres: selectedDataSourcesThreshold,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedAS
 * @param {string[]} params.selectedNeighborASes
 * @param {string} params.selectedDataSourcesThreshold
 * @returns {object}
 */
export const getEditPeeringVisibilityLossAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedAS,
  selectedNeighborASes,
  selectedDataSourcesThreshold,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      configured_asn: selectedAS,
      neighbor_asns: selectedNeighborASes,
      ds_thres: selectedDataSourcesThreshold,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};

/**
 * @param {object} params
 * @param {string} params.selectedID
 * @param {string} params.customDescription
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @param {string} params.selectedASPathLengthThreshold
 * @param {string[]} params.selectedPrefixes
 * @returns {object}
 */
export const getEditLongASPathAlertRuleMutationObject = ({
  selectedID,
  customDescription,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
  selectedASPathLengthThreshold,
  selectedPrefixes,
}) => {
  return {
    id: selectedID,
    description: customDescription,
    name: customAlertName,
    vars: {
      prefixes: selectedPrefixes,
      as_path_length_thres: selectedASPathLengthThreshold,
    },
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
};