import { usePrefixesMyAsNsOriginateSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/usePrefixesMyASNsOriginate";
import {
  displayedConfiguredASNsAtom,
  prefixesMyASNsOriginateAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";

export const usePrefixesMyASNsOriginate = () => {
  const [{ data: asns }] = useAtom(displayedConfiguredASNsAtom);

  useData({
    operation: usePrefixesMyAsNsOriginateSubscription,
    options: {
      variables: { asns },
      pause: !asns,
    },
    formatter,
    atom: prefixesMyASNsOriginateAtom,
  });
};
