import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { SvgWrapper } from "../components/SvgWrapper";
import { getTenantName } from "../other/utils";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { Alert } from "../components/Alert";
import { useSelectTenantEffects } from "../hooks/useSelectTenantEffects";
import { redirectToAuth0LoginPage } from "../utils/components/selectTenant";

export const SelectTenant = () => {
  const { loginWithRedirect } = useAuth0();
  const [tenantName, setTenantName] = useState(getTenantName() ?? "");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const {
    isOpen: isSnackbarOpen,
    open: openSnackbar,
    close: closeSnackbar,
  } = useOpenCloseSnackbar();

  useSelectTenantEffects({
    setSnackbarMessage,
    setSnackbarSeverity,
    openSnackbar,
  });

  const onTextFieldChange = (e) => {
    setTenantName(e.target.value);
  };

  const onKeyDown = async (e) => {
    if (e.key === "Enter") {
      await onSubmitButtonClick();
    }
  };

  const onSubmitButtonClick = async () => {
    await redirectToAuth0LoginPage({ tenantName, loginWithRedirect });
  };

  return (
    <Stack
      sx={{
        height: "100vh",
        backgroundColor: "blue2.main",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        sx={{
          width: "15rem",
          height: "12rem",
          justifyContent: "space-between",
        }}
      >
        <SvgWrapper
          sx={{ width: 240, height: 32 }}
          filename="CodeBGPLogo"
          viewBox="0 0 768 76"
        />
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
            "& .MuiOutlinedInput-input": {
              color: "#ffffff",
            },
            "& .MuiInputLabel-root": {
              color: "#ffffff99",
              fontSize: "0.875rem",
              pt: "0.05rem",
            },
            "& .MuiInputLabel-shrink": {
              color: "#ffffff",
              fontSize: "1rem",
            },
            "& .MuiFormHelperText-root": {
              color: "#ffffff",
              fontSize: "1rem",
              ml: "0",
            },
          }}
          label="Organization ID"
          helperText="Enter your organization ID"
          size="small"
          autoFocus
          value={tenantName}
          onChange={onTextFieldChange}
          onKeyDown={onKeyDown}
        />
        <Button
          sx={{
            textTransform: "none",
            color: "white",
            backgroundColor: "blue7.main",
            ":hover": {
              color: "white",
              backgroundColor: "blue7.main",
            },
          }}
          onClick={onSubmitButtonClick}
        >
          <Typography fontSize="0.875rem">Go!</Typography>
        </Button>
      </Stack>
      <Snackbar
        key={snackbarMessage}
        open={isSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={8000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
