import { atom } from "jotai";
import {
  INITIAL_COUNT,
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";

export const pageAtom = atom(INITIAL_PAGE);
export const rowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const countAtom = atom(INITIAL_COUNT);
export const selectedRowIDsAtom = atom(new Set());

export const numberFilterAtom = atom("");
export const displayedFilterAtom = atom("");

export const isNumberVisibleAtom = atom(true);
export const isDisplayedVisibleAtom = atom(true);
