import {
  CODEBGP_MONITOR,
  RIS_LIVE,
  RPKI,
} from "../../constants/dataSourcesTable";
import { ConfiguredASNsResult } from "../../types/hooks/useConfiguredASNs";
import { ConfiguredPrefixesResult } from "../../types/hooks/useConfiguredPrefixes";
import { DataSources_Bool_Exp } from "../../graphql/generated/operations";

/**
 * @param {object} params
 * @param {string} params.dataService
 * @param {ConfiguredASNsResult["data"]} params.configuredASNs
 * @param {ConfiguredPrefixesResult["data"]} [params.configuredPrefixes]
 * @returns {DataSources_Bool_Exp}
 */
export const getWhereArgument = ({
  dataService,
  configuredASNs,
  configuredPrefixes,
}) => {
  if (!dataService) {
    console.error("dataService is falsy. Aborting getting where argument.");
    return {};
  }
  if (typeof dataService !== "string") {
    console.error(
      "dataService is not a string. Aborting getting where argument."
    );
    return {};
  }

  if (dataService === RPKI) {
    return {
      data_service: { _eq: dataService },
      rpkiROADataSourceAssociations: {
        rpkiROA: {
          _or: [
            {
              network: {
                _in: configuredPrefixes,
              },
            },
            { autonomous_system_number: { _in: configuredASNs } },
          ],
        },
      },
    };
  }

  return {
    data_service: { _eq: dataService },
    _or: [
      {
        autonomousSystemDataSourceAssociations: {
          autonomousSystem: {
            number: {
              _in: configuredASNs,
            },
          },
        },
      },
      {
        prefixDataSourceAssociations: {
          prefix: {
            configured_prefix_best_match: { _in: configuredPrefixes },
          },
        },
      },
    ],
  };
};

/**
 * @param {object} params
 * @param {number} params.ipVersion
 * @param {ConfiguredASNsResult["data"]} params.configuredASNs
 * @param {ConfiguredPrefixesResult["data"]} params.configuredPrefixes
 * @returns {DataSources_Bool_Exp}
 */
export const getRISLiveWhereArgument = ({
  ipVersion,
  configuredASNs,
  configuredPrefixes,
}) => {
  const selectorRegex =
    ipVersion === 6
      ? { _iregex: 'peer_ip": ".*:.*"' }
      : { _iregex: 'peer_ip": "[^:\n]+"' };

  return {
    data_service: { _eq: RIS_LIVE },
    selector: {
      _cast: {
        String: selectorRegex,
      },
    },
    _or: [
      {
        autonomousSystemDataSourceAssociations: {
          autonomousSystem: {
            number: {
              _in: configuredASNs,
            },
          },
        },
      },
      {
        prefixDataSourceAssociations: {
          prefix: {
            configured_prefix_best_match: { _in: configuredPrefixes },
          },
        },
      },
    ],
  };
};

/**
 * @param {object} params
 * @param {number} params.ipVersion
 * @param {ConfiguredASNsResult["data"]} params.configuredASNs
 * @param {ConfiguredPrefixesResult["data"]} params.configuredPrefixes
 * @returns {DataSources_Bool_Exp}
 */
export const getCodeBGPMonitorWhereArgument = ({
  ipVersion,
  configuredASNs,
  configuredPrefixes,
}) => {
  const selectorRegex =
    ipVersion === 6
      ? { _iregex: 'peer_ip": ".*:.*"' }
      : { _iregex: 'peer_ip": "[^:\n]+"' };

  return {
    data_service: { _eq: CODEBGP_MONITOR },
    selector: {
      _cast: {
        String: selectorRegex,
      },
    },
    _or: [
      {
        autonomousSystemDataSourceAssociations: {
          autonomousSystem: {
            number: {
              _in: configuredASNs,
            },
          },
        },
      },
      {
        prefixDataSourceAssociations: {
          prefix: {
            configured_prefix_best_match: { _in: configuredPrefixes },
          },
        },
      },
    ],
  };
};
