import { UsernameResult } from "../types/hooks/useUsername";
import { auth0HookGetter } from "../other/utils";
import { getFullname } from "../utils/hooks/useUsername";

/**
 * @returns {UsernameResult}
 */
export const useUsername = () => {
  const { user } = auth0HookGetter()();

  const name = user?.given_name;
  const surname = user?.family_name;
  const fallback = user?.nickname;

  const fullname = getFullname({ name, surname });

  const resultName = name ?? fallback;
  const resultFullname = fullname ?? fallback;

  return { name: resultName, fullname: resultFullname };
};
