import { useConfAlertsSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useConfiguredAlerts";
import { configuredAlertsAtom } from "../other/store";
import { useData } from "./useData";

export const useConfiguredAlerts = () => {
  useData({
    operation: useConfAlertsSubscription,
    formatter,
    atom: configuredAlertsAtom,
  });
};
