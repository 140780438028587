import { ReactElement } from "react";
import { PrimitiveAtom } from "jotai";
import { SingleDataResult } from "../types/store";
import { Stack, Typography } from "@mui/material";
import { OverviewCardCount } from "./OverviewCardCount";

/**
 * @param {object} props
 * @param {string} props.rateName
 * @param {PrimitiveAtom<SingleDataResult<number>>} props.atom
 * @param {number} props.numberOfColumns
 * @returns {ReactElement}
 */
export const BGPUpdateRatesCardSection = ({
  rateName,
  atom,
  numberOfColumns,
}) => {
  if (numberOfColumns > 2) {
    return (
      <Stack
        sx={{
          alignItems: "center",
          pr: "4rem",
          height: "2rem",
        }}
        direction="row"
      >
        <Typography sx={{ whiteSpace: "nowrap", pr: "0.75rem" }}>
          {rateName}
        </Typography>
        <OverviewCardCount
          atom={atom}
          customProgressStyles={{ width: "100%", mt: "0.2rem", ml: "1rem" }}
        />
      </Stack>
    );
  } else {
    return (
      <Stack
        sx={{
          pl: "24px",
          height: "2rem",
        }}
      >
        <Typography sx={{ whiteSpace: "nowrap", pr: "0.75rem", pb: "0.35rem" }}>
          {rateName}
        </Typography>
        <OverviewCardCount
          atom={atom}
          customProgressStyles={{ width: "100%" }}
        />
      </Stack>
    );
  }
};
