import { Error, Loading } from "../../types/dataFetching";
import { rowsPerPageOptions } from "../../constants/components/table";
import {
  activeAlertsTableTheme,
  alertsTableSetupTheme,
  innerTableTheme,
  outerTableTheme,
  resolvedAlertsTableTheme,
} from "../../other/utils";
import createCache from "@emotion/cache";
import { TableFooter } from "../../components/TableFooter";
import { Box } from "@mui/material";
import { ActionButton } from "../../components/ActionButton";
import { TableProps } from "../../types/components/table";

/**
 * @param {object} params
 * @param {Loading} params.loading
 * @param {Error} params.error
 * @param {object} [params.selectedRowsTextLabels]
 * @param {boolean} [params.displayDownloadButton]
 * @param {string} [params.exportDataFileName]
 * @returns {object}
 */
const getDefaultOptions = ({
  loading,
  error,
  selectedRowsTextLabels,
  displayDownloadButton = true,
  exportDataFileName = "TableDataCodeBGPplatform",
}) => {
  const defaultOptions = {
    expandableRowsHeader: false,
    fixedHeader: true,
    fixedSelectColumn: false,
    rowsPerPage: 10,
    rowsPerPageOptions,
    responsive: "standard",
    pagination: true,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    download: displayDownloadButton,
    downloadOptions: {
      filename: exportDataFileName + ".csv",
      separator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    print: false,
    search: false,
    textLabels: {
      body: {
        noMatch: error
          ? "An error has occurred."
          : loading
          ? "Loading data..."
          : "No data found.",
      },
    },
  };

  if (selectedRowsTextLabels) {
    defaultOptions.textLabels.selectedRows = selectedRowsTextLabels;
  }

  return defaultOptions;
};

/**
 * @param {object} params
 * @param {object | undefined} params.customOptions
 * @param {TableProps["data"]} params.data
 * @param {Loading} params.loading
 * @param {Error} params.error
 * @param {object} [params.selectedRowsTextLabels]
 * @param {number} params.page
 * @param {Function} params.setPage
 * @param {number} params.rowsPerPage
 * @param {Function} params.setRowsPerPage
 * @param {Function} params.resetPage
 * @param {number} params.count
 * @param {Function} [params.setCount]
 * @param {Function} [params.onApplyFiltersButtonClick]
 * @param {Function} [params.onViewColumnsChange]
 * @param {Function} [params.getRowID]
 * @param {number[]} [params.rowsExpanded]
 * @param {Set<string>} [params.expandedRowIDs]
 * @param {Function} [params.setExpandedRowIDs]
 * @param {number[]} [params.rowsSelected]
 * @param {Set<string>} [params.selectedRowIDs]
 * @param {Function} [params.setSelectedRowIDs]
 * @param {boolean} [params.displayDownloadButton]
 * @param {string} [params.exportDataFileName]
 * @returns {object}
 */
export const getOptions = ({
  customOptions,
  data,
  loading,
  error,
  selectedRowsTextLabels,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  resetPage,
  count,
  setCount,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  getRowID,
  rowsExpanded,
  expandedRowIDs,
  setExpandedRowIDs,
  rowsSelected,
  selectedRowIDs,
  setSelectedRowIDs,
  displayDownloadButton,
  exportDataFileName,
}) => {
  if (!customOptions) {
    customOptions = {};
  }
  const defaultOptions = getDefaultOptions({
    loading,
    error,
    selectedRowsTextLabels,
    displayDownloadButton,
    exportDataFileName,
  });
  const optionsFromAtoms = getOptionsFromAtoms({
    data,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    resetPage,
    count,
    setCount,
    onApplyFiltersButtonClick,
    onViewColumnsChange,
    getRowID,
    rowsExpanded,
    expandedRowIDs,
    setExpandedRowIDs,
    rowsSelected,
    selectedRowIDs,
    setSelectedRowIDs,
  });
  return { ...defaultOptions, ...optionsFromAtoms, ...customOptions };
};

/**
 * @param {string} themeKey
 * @returns {object}
 */
export const getTheme = (themeKey) => tableThemes[themeKey];

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

/**
 * @param {object} params
 * @param {TableProps["data"]} params.data
 * @param {number} params.page
 * @param {Function} params.setPage
 * @param {number} params.rowsPerPage
 * @param {Function} params.setRowsPerPage
 * @param {Function} params.resetPage
 * @param {number} params.count
 * @param {Function} [params.setCount]
 * @param {Function} [params.onApplyFiltersButtonClick]
 * @param {Function} [params.onViewColumnsChange]
 * @param {Function} [params.getRowID]
 * @param {number[]} [params.rowsExpanded]
 * @param {Set<string>} [params.expandedRowIDs]
 * @param {Function} [params.setExpandedRowIDs]
 * @param {number[]} [params.rowsSelected]
 * @param {Set<string>} [params.selectedRowIDs]
 * @param {Function} [params.setSelectedRowIDs]
 * @returns {object}
 */
const getOptionsFromAtoms = ({
  data,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  resetPage,
  count,
  setCount,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  getRowID,
  rowsExpanded,
  expandedRowIDs,
  setExpandedRowIDs,
  rowsSelected,
  selectedRowIDs,
  setSelectedRowIDs,
}) => {
  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
    resetPage();
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const customFooter = () => (
    <TableFooter
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      changeRowsPerPage={onChangeRowsPerPage}
      changePage={onChangePage}
    />
  );

  const options = {
    page,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
    customFooter,
  };

  if (onViewColumnsChange) {
    options.onViewColumnsChange = (changedColumn, action) => {
      onViewColumnsChange({ changedColumn, action });
    };
  }

  if (onApplyFiltersButtonClick) {
    options.confirmFilters = true;
    options.customFilterDialogFooter = (_, applyNewFilters) => (
      <Box sx={{ mt: "2.5rem" }}>
        <ActionButton
          title="Apply Filters"
          customStyles={{ width: "8rem" }}
          onClick={() =>
            onApplyFiltersButtonClick({
              applyNewFilters,
            })
          }
        />
      </Box>
    );

    options.onFilterChange = (_, filterList, type) => {
      if (type === "chip") {
        onApplyFiltersButtonClick({
          applyNewFilters: () => filterList,
        });
      }
    };
  }

  if (setCount) {
    options.onTableChange = (action, tableState) => {
      setCount(tableState.displayData.length);
    };
  }

  if (getRowID && rowsExpanded && expandedRowIDs && setExpandedRowIDs) {
    options.rowsExpanded = rowsExpanded;
    options.onRowExpansionChange = (currentRowsExpanded) => {
      if (!data) return;
      const row = data[currentRowsExpanded[0]?.dataIndex];
      const id = getRowID(row);
      if (!id) return;

      const newExpandedRowIDs = new Set(expandedRowIDs);
      if (expandedRowIDs.has(id)) {
        newExpandedRowIDs.delete(id);
      } else {
        newExpandedRowIDs.add(id);
      }

      setExpandedRowIDs(newExpandedRowIDs);
    };
  }

  if (getRowID && rowsSelected && selectedRowIDs && setSelectedRowIDs) {
    options.rowsSelected = rowsSelected;
    options.onRowSelectionChange = (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      if (!data) return;
      const rows = allRowsSelected.map(({ dataIndex }) => data[dataIndex]);
      const ids = rows.map((row) => getRowID(row));
      const newSelectedRowIDs = new Set(ids);
      setSelectedRowIDs(newSelectedRowIDs);
    };
  }

  return options;
};

/**
 * @param {object} params
 * @param {TableProps["data"]} params.data
 * @param {Set<string>} params.expandedRowIDs
 * @param {Function} params.getRowID
 * @returns {number[]}
 */
export const getRowsExpanded = ({ data, expandedRowIDs, getRowID }) => {
  /** @type {number[]} */
  const expandedRows = [];

  data?.forEach((row, index) => {
    const id = getRowID(row);
    if (expandedRowIDs.has(id)) {
      expandedRows.push(index);
    }
  });

  return expandedRows;
};

/**
 * @param {object} params
 * @param {TableProps["data"]} params.data
 * @param {Set<string>} params.selectedRowIDs
 * @param {Function} params.getRowID
 * @returns {number[]}
 */
export const getRowsSelected = ({ data, selectedRowIDs, getRowID }) => {
  /** @type {number[]} */
  const selectedRows = [];

  data?.forEach((row, index) => {
    const id = getRowID(row);
    if (selectedRowIDs.has(id)) {
      selectedRows.push(index);
    }
  });

  return selectedRows;
};

const tableThemes = {
  inner: innerTableTheme,
  outer: outerTableTheme,
  activeAlerts: activeAlertsTableTheme,
  resolvedAlerts: resolvedAlertsTableTheme,
  alertsSetup: alertsTableSetupTheme,
};
