import { PeeringsTable } from "../components/PeeringsTable";
import { ReactElement } from "react";

/**
 * @param {object} props
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
const Peerings = ({ urlParams }) => {
  return <PeeringsTable urlParams={urlParams} />;
};

export { Peerings };
