export const DRAWER_BUTTONS = Object.freeze({
  overview: "Overview",
  setup: {
    index: "Setup",
    autonomousSystems: "AS Filters",
    prefixes: "Prefix Filters",
    alerts: "Alert Rules",
    dataServices: "Data Services",
  },
  lookingglass: "Looking Glass",
  api: {
    index: "API",
    queries: "Queries",
    editor: "Editor",
  },
  integrations: "Integrations",
  opensource: "Open Source",
  alerts: "Alerts",
  dashboards: "Dashboards",
  // alerts: { index: "Alerts", stream: "Stream", configuration: "Configuration" },
  // visibility: "Visibility",
  settings: "Settings",
  newtab: "New Tab",
});

export const DRAWER_FEEDBACK = Object.freeze({
  text: "Share your suggestions with our team!",
  buttonTitle: "Request Support",
});
