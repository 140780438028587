import { ReactElement, useEffect, useState } from "react";
import { Close } from "../types/components/dialog";
import {
  Autocomplete,
  Dialog,
  Stack,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { CancelButton } from "./CancelButton";
import { MainButton } from "./MainButton";
import { AddAlertRuleStepper } from "./AddAlertRuleStepper";
import { SecondaryButton } from "./SecondaryButton";
import {
  alertRuleDisplayName,
  alertRuleReceiverType,
  defaultAlertRuleReceiverEndpointName,
  alertRuleReceiverEndpointName,
  knownBogonPrefixes,
  alertSeverity,
} from "../constants/components/confAlertsInfoTable";
import { useAtom } from "jotai";
import {
  configuredASNsAtom,
  filterOnASAnyAtom,
  prefixesMyASNsOriginateAtom,
  rpkiPrefixesMyASNsOriginateAtom,
  configuredPrefixesAtom,
} from "../other/store";
import { convertIntsToStrings } from "../utils/formatting";
import {
  isInputNumeric,
  isASNInputValid,
  isJSONInputValid,
} from "../utils/validation";
import { ArgsTable } from "./ArgsTable";
import { parameterNameMap } from "../constants/components/argsTable";
import {
  getRouteLeakMutationObject,
  getExactPrefixHijackMutationObject,
  getSubPrefixHijackMutationObject,
  getASPathComparisonMutationObject,
  getSquattingMutationObject,
  getNeighborLeakHijackMutationObject,
  getRPKIInvalidDetectionMutationObject,
  getRPKIInvalidAnnouncementMutationObject,
  getRPKIInvalidPropagationMutationObject,
  getRPKINotFoundPropagationMutationObject,
  getBogonExactPrefixMutationObject,
  getBogonSubPrefixMutationObject,
  getNewNeighborMutationObject,
  getCustomMutationObject,
  getBogonASMutationObject,
  getASPathPresenceMutationObject,
  getInvalidASPathPatternMutationObject,
  getPrefixComparisonMutationObject,
  getPrefixVisibilityLossMutationObject,
  getPeeringVisibilityLossMutationObject,
  getLongASPathMutationObject,
  onAddAlertRule,
} from "../utils/components/addConfAlertsDialog";
import {
  getEditRouteLeakAlertRuleMutationObject,
  getEditExactPrefixHijackAlertRuleMutationObject,
  getEditSubPrefixHijackAlertRuleMutationObject,
  getEditASPathComparisonAlertRuleMutationObject,
  getEditSquattingAlertRuleMutationObject,
  getEditNeighborLeakHijackAlertRuleMutationObject,
  getEditRPKIInvalidDetectionAlertRuleMutationObject,
  getEditRPKIInvalidAnnouncementAlertRuleMutationObject,
  getEditRPKIInvalidPropagationAlertRuleMutationObject,
  getEditRPKINotFoundPropagationAlertRuleMutationObject,
  getEditBogonExactPrefixAlertRuleMutationObject,
  getEditBogonSubPrefixAlertRuleMutationObject,
  getEditNewNeighborAlertRuleMutationObject,
  getEditCustomAlertRuleMutationObject,
  getEditBogonASAlertRuleMutationObject,
  getEditASPathPresenceAlertRuleMutationObject,
  getEditInvalidASPathPatternAlertRuleMutationObject,
  getEditPrefixComparisonAlertRuleMutationObject,
  getEditPrefixVisibilityLossAlertRuleMutationObject,
  getEditPeeringVisibilityLossAlertRuleMutationObject,
  getEditLongASPathAlertRuleMutationObject,
  onEditAlertRule,
} from "../utils/components/editConfAlertsDialog";
import {
  useInsertAlertRuleMutation,
  usePrefixesFromAsnQuery,
  useRpkiPrefixesFromAsnQuery,
  usePolledBgpNeighborsQuery,
  useUpdateAlertRuleMutation,
} from "../graphql/generated/operations";
import { InputFailureMessage } from "./InputFailureMessage";
import {
  getConfPrefixesResult,
  getRPKIPrefixesResult,
  getPrefixesWhereArgument,
} from "../utils/components/addConfPrefixFromDataServiceTab";
import {
  BGP_ROUTER,
  CODEBGP_MONITOR,
  RIS_LIVE,
} from "../constants/dataSourcesTable";
import { formatDataService, getSelectedPrefixesStrList, regexToASes } from "../other/utils";
import { formatter as polledBGPNeighbors } from "../utils/hooks/usePolledNeighbors";
import isCidr from "is-cidr";

const ASPathDataServices = [
  formatDataService(BGP_ROUTER),
  formatDataService(CODEBGP_MONITOR),
  formatDataService(RIS_LIVE),
];

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {object=} props.editableAlertRule
 * @returns {ReactElement}
 */

export const AddConfAlertsDialog = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  editableAlertRule,
}) => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState(
    alertRuleDisplayName.as_origin_violation_exact
  );
  const [customAlertName, setCustomAlertName] = useState("");
  const [selectedAlertReceiverType, setSelectedAlertReceiverType] = useState(
    alertRuleReceiverType.email
  );
  const [selectedAlertReceiverEndpoint, setSelectedAlertReceiverEndpoint] =
    useState("");
  const [
    selectedAlertReceiverEndpointName,
    setSelectedAlertReceiverEndpointName,
  ] = useState(defaultAlertRuleReceiverEndpointName);
  const [selectedAS, setSelectedAS] = useState("");
  const [confirmedSelectedAS, setConfirmedSelectedAS] = useState("");
  const [selectedASes, setSelectedASes] = useState([]);
  const [selectedPresentASes, setSelectedPresentASes] = useState([]);
  const [selectedASPathRegex, setSelectedASPathRegex] = useState("");
  const [selectedPrefixes, setSelectedPrefixes] = useState([]);
  const [selectedRPKIPrefixes, setSelectedRPKIPrefixes] = useState([]);
  const [selectedBogonPrefixes, setSelectedBogonPrefixes] =
    useState(knownBogonPrefixes);
  const [selectedCompService, setSelectedCompService] = useState(
    formatDataService(RIS_LIVE)
  );
  const [selectedBaseService, setSelectedBaseService] = useState(
    formatDataService(BGP_ROUTER)
  );
  const [selectedEndASN, setSelectedEndASN] = useState("");
  const [selectedDataSourcesThreshold, setSelectedDataSourcesThreshold] =
    useState("");
  const [selectedASPathLengthThreshold, setSelectedASPathLengthThreshold] =
    useState("");
  const [selectedOriginAS, setSelectedOriginAS] = useState("");
  const [selectedNeighborASes, setSelectedNeighborASes] = useState([]);
  const [customQuery, setCustomQuery] = useState("");
  const [customQueryParams, setCustomQueryParams] = useState("");
  const [customFireAlertRegex, setCustomFireAlertRegex] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const [customSeverity, setCustomSeverity] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [editModeOn, setEditModeOn] = useState(false);

  const [{ data: configuredASNs }] = useAtom(configuredASNsAtom);
  const [{ data: configuredPrefixes }] = useAtom(configuredPrefixesAtom);
  const [{ data: prefixesMyASNsOriginate }] = useAtom(
    prefixesMyASNsOriginateAtom
  );
  const [{ data: rpkiPrefixesMyASNsOriginate }] = useAtom(
    rpkiPrefixesMyASNsOriginateAtom
  );
  const [failureReason, setFailureReason] = useState("");
  const [, addAlertRule] = useInsertAlertRuleMutation();
  const [, editAlertRule] = useUpdateAlertRuleMutation();
  const failureMessage = <InputFailureMessage failureReason={failureReason} />;

  const [filterOnASAny] = useAtom(filterOnASAnyAtom);

  const [errorEmail, setErrorEmail] = useState(false);
  const emailValidation = (email) => {
    const emailRegex = new RegExp( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, "gm");
    if (emailRegex.test(email)){
      setErrorEmail(false);
      return false;
    } else {
      setErrorEmail(true);
      return true;
    }
  };


  const [
    {
      data: prefixesFromASN,
      fetching: prefixesFromASNFetching,
      error: prefixesFromASNError,
    },
  ] = usePrefixesFromAsnQuery({
    variables: {
      asn: confirmedSelectedAS,
      prefixesWhere: getPrefixesWhereArgument({
        asn: confirmedSelectedAS,
        filterOnASAny,
      }),
    },
    pause: !confirmedSelectedAS,
  });
  const [
    {
      data: rpkiPrefixesFromASN,
      fetching: rpkiPrefixesFromASNFetching,
      error: rpkiPrefixesFromASNError,
    },
  ] = useRpkiPrefixesFromAsnQuery({
    variables: {
      asn: confirmedSelectedAS,
    },
    pause: !confirmedSelectedAS,
  });
  const [
    {
      data: bgpNeighbors,
      fetching: bgpNeighborsFetching,
      error: bgpNeighborsError,
    },
  ] = usePolledBgpNeighborsQuery({
    variables: {
      asns: [confirmedSelectedAS],
    },
    pause: !confirmedSelectedAS,
  });

  useEffect(() => {
    if (editableAlertRule) {
      setEditMode();
    }
  }, [editableAlertRule]);

  useEffect(() => {
    const { confPrefixes: autofilledPrefixes } = getConfPrefixesResult({
      prefixesFromASN,
    });
    if (autofilledPrefixes && autofilledPrefixes.length !== 0) {
      setSelectedPrefixes((selectedPrefixes) => {
        const newPrefixes = Array.from(
          new Set([...selectedPrefixes, ...autofilledPrefixes])
        );
        return newPrefixes;
      });
    }
  }, [prefixesFromASN]);

  useEffect(() => {
    const { rpkiPrefixes: autofilledRPKIPrefixes } = getRPKIPrefixesResult({
      rpkiPrefixesFromASN,
    });
    if (autofilledRPKIPrefixes && autofilledRPKIPrefixes.length !== 0) {
      setSelectedRPKIPrefixes((selectedRPKIPrefixes) => {
        const newRPKIPrefixes = Array.from(
          new Set([...selectedRPKIPrefixes, ...autofilledRPKIPrefixes])
        );
        return newRPKIPrefixes;
      });
    }
  }, [rpkiPrefixesFromASN]);

  useEffect(() => {
    const autofilledNeighbors = polledBGPNeighbors(bgpNeighbors, [
      parseInt(confirmedSelectedAS),
    ]);
    const selfNeighbors = [parseInt(confirmedSelectedAS)];
    if (autofilledNeighbors && autofilledNeighbors.length !== 0) {
      setSelectedNeighborASes((selectedNeighborASes) => {
        const newNeighborASes = Array.from(
          new Set([
            ...selectedNeighborASes,
            ...autofilledNeighbors,
            ...selfNeighbors,
          ])
        );
        return newNeighborASes;
      });
    }
  }, [bgpNeighbors]);

  const routeLeakMutationObject = getRouteLeakMutationObject({
    selectedAS,
    selectedPrefixes,
    selectedDataSourcesThreshold,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editRouteleakAlertRuleMutationObject = getEditRouteLeakAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedAS,
    selectedPrefixes,
    selectedDataSourcesThreshold,
  })

  const exactPrefixHijackMutationObject = getExactPrefixHijackMutationObject({
    selectedASes,
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editExactPrefixHijackAlertRuleMutationObject = getEditExactPrefixHijackAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedPrefixes,
  })

  const subPrefixHijackMutationObject = getSubPrefixHijackMutationObject({
    selectedASes,
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editSubPrefixHijackAlertRuleMutationObject = getEditSubPrefixHijackAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedPrefixes,
  })

  const asPathComparisonMutationObject = getASPathComparisonMutationObject({
    selectedBaseService,
    selectedCompService,
    selectedEndASN,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editASPathComparisonAlertRuleMutationObject = getEditASPathComparisonAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedBaseService,
    selectedCompService,
    selectedEndASN,
  })

  const prefixComparisonMutationObject = getPrefixComparisonMutationObject({
    selectedASes,
    selectedBaseService,
    selectedCompService,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editPrefixComparisonAlertRuleMutationObject = getEditPrefixComparisonAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedBaseService,
    selectedCompService,
  })

  const squattingMutationObject = getSquattingMutationObject({
    selectedPrefixes,
    selectedDataSourcesThreshold,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editSquattingAlertRuleMutationObject = getEditSquattingAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedPrefixes,
    selectedDataSourcesThreshold,
  })

  const neighborLeakHijackMutationObject = getNeighborLeakHijackMutationObject({
    selectedOriginAS,
    selectedNeighborASes,
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editNeighborLeakHijackAlertRuleMutationObject = getEditNeighborLeakHijackAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedOriginAS,
    selectedNeighborASes,
    selectedPrefixes,
  })

  const rpkiInvalidDetectionMutationObject =
    getRPKIInvalidDetectionMutationObject({
      selectedASes,
      selectedRPKIPrefixes,
      customAlertName,
      selectedAlertReceiverType,
      selectedAlertReceiverEndpoint,
    });

  const editRPKIInvalidDetectionAlertRuleMutationObject = getEditRPKIInvalidDetectionAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedRPKIPrefixes,
  })

  const rpkiInvalidAnnouncementMutationObject =
    getRPKIInvalidAnnouncementMutationObject({
      selectedASes,
      customAlertName,
      selectedAlertReceiverType,
      selectedAlertReceiverEndpoint,
    });

  const editRPKIInvalidAnnouncementAlertRuleMutationObject = getEditRPKIInvalidAnnouncementAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
  })

  const rpkiInvalidPropagationMutationObject =
    getRPKIInvalidPropagationMutationObject({
      selectedASes,
      customAlertName,
      selectedAlertReceiverType,
      selectedAlertReceiverEndpoint,
    });

  const editRPKIInvalidPropagationAlertRuleMutationObject = getEditRPKIInvalidPropagationAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
  })

  const rpkiNotFoundPropagationMutationObject =
    getRPKINotFoundPropagationMutationObject({
      selectedASes,
      customAlertName,
      selectedAlertReceiverType,
      selectedAlertReceiverEndpoint,
    });

  const editRPKINotFoundPropagationAlertRuleMutationObject = getEditRPKINotFoundPropagationAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
  })

  const bogonExactPrefixMutationObject = getBogonExactPrefixMutationObject({
    selectedASes,
    selectedBogonPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editBogonExactPrefixAlertRuleMutationObject = getEditBogonExactPrefixAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedBogonPrefixes,
  })

  const bogonSubPrefixMutationObject = getBogonSubPrefixMutationObject({
    selectedASes,
    selectedBogonPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editBogonSubPrefixAlertRuleMutationObject = getEditBogonSubPrefixAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedBogonPrefixes,
  })

  const newNeighborMutationObject = getNewNeighborMutationObject({
    selectedAS,
    selectedNeighborASes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editNewNeighborAlertRuleMutationObject = getEditNewNeighborAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedAS,
    selectedNeighborASes,
  })

  const customMutationObject = getCustomMutationObject({
    customQuery,
    customQueryParams,
    customFireAlertRegex,
    customDescription,
    customSeverity,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editCustomAlertRuleMutationObject = getEditCustomAlertRuleMutationObject({
    selectedID,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    customQueryParams,
    customDescription,
  })

  const bogonASMutationObject = getBogonASMutationObject({
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editBogonASAlertRuleMutationObject = getEditBogonASAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedPrefixes,
  })

  const asPathPresenceMutationObject = getASPathPresenceMutationObject({
    selectedPresentASes,
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editASPathPresenceAlertRuleMutationObject = getEditASPathPresenceAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedPresentASes,
    selectedPrefixes,
  })

  const invalidASPathPatternMutationObject = getInvalidASPathPatternMutationObject({
    selectedASPathRegex,
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editInvalidASPathPatternAlertRuleMutationObject = getEditInvalidASPathPatternAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASPathRegex,
    selectedPrefixes,
  })

  const prefixVisibilityLossMutationObject = getPrefixVisibilityLossMutationObject({
    selectedASes,
    selectedPrefixes,
    selectedDataSourcesThreshold,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editPrefixVisibilityLossAlertRuleMutationObject = getEditPrefixVisibilityLossAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASes,
    selectedPrefixes,
    selectedDataSourcesThreshold,
  })

  const peeringVisibilityLossMutationObject = getPeeringVisibilityLossMutationObject({
    selectedAS,
    selectedNeighborASes,
    selectedDataSourcesThreshold,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editPeeringVisibilityLossAlertRuleMutationObject = getEditPeeringVisibilityLossAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedAS,
    selectedNeighborASes,
    selectedDataSourcesThreshold,
  })

  const longASPathMutationObject = getLongASPathMutationObject({
    selectedASPathLengthThreshold,
    selectedPrefixes,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
  });

  const editLongASPathAlertRuleMutationObject = getEditLongASPathAlertRuleMutationObject({
    selectedID,
    customDescription,
    customAlertName,
    selectedAlertReceiverType,
    selectedAlertReceiverEndpoint,
    selectedASPathLengthThreshold,
    selectedPrefixes,
  })

  const onAddAlertRuleCallbackRouteLeak = () => {
    onAddAlertRule({
      mutationObject: routeLeakMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditRouteLeakAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editRouteleakAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackExactPrefixHijack = () => {
    onAddAlertRule({
      mutationObject: exactPrefixHijackMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };


  const onEditExactPrefixHijackAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editExactPrefixHijackAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackSubPrefixHijack = () => {
    onAddAlertRule({
      mutationObject: subPrefixHijackMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditSubPrefixHijackAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editSubPrefixHijackAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackASPathComparison = () => {
    onAddAlertRule({
      mutationObject: asPathComparisonMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditASPathComparisonAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editASPathComparisonAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackPrefixComparison = () => {
    onAddAlertRule({
      mutationObject: prefixComparisonMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditPrefixComparisonAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editPrefixComparisonAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackSquatting = () => {
    onAddAlertRule({
      mutationObject: squattingMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditSquattingAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editSquattingAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackNeighborLeakHijack = () => {
    onAddAlertRule({
      mutationObject: neighborLeakHijackMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditNeighborLeakHijackAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editNeighborLeakHijackAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackRPKIInvalidDetection = () => {
    onAddAlertRule({
      mutationObject: rpkiInvalidDetectionMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditRPKIInvalidDetectionAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editRPKIInvalidDetectionAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackRPKIInvalidAnnouncement = () => {
    onAddAlertRule({
      mutationObject: rpkiInvalidAnnouncementMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditRPKIInvalidAnnouncementAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editRPKIInvalidAnnouncementAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackRPKIInvalidPropagation = () => {
    onAddAlertRule({
      mutationObject: rpkiInvalidPropagationMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditRPKIInvalidPropagationAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editRPKIInvalidPropagationAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackRPKINotFoundPropagation = () => {
    onAddAlertRule({
      mutationObject: rpkiNotFoundPropagationMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditRPKINotFoundPropagationAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editRPKINotFoundPropagationAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackBogonExactPrefix = () => {
    onAddAlertRule({
      mutationObject: bogonExactPrefixMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditBogonExactPrefixAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editBogonExactPrefixAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackBogonSubPrefix = () => {
    onAddAlertRule({
      mutationObject: bogonSubPrefixMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditBogonSubPrefixAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editBogonSubPrefixAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackNewNeighbor = () => {
    onAddAlertRule({
      mutationObject: newNeighborMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditNewNeighborAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editNewNeighborAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackCustom = () => {
    onAddAlertRule({
      mutationObject: customMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditCustomAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editCustomAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackBogonAS = () => {
    onAddAlertRule({
      mutationObject: bogonASMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditBogonASAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editBogonASAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackASPathPresence = () => {
    onAddAlertRule({
      mutationObject: asPathPresenceMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditASPathPresenceAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editASPathPresenceAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackInvalidASPathPattern = () => {
    onAddAlertRule({
      mutationObject: invalidASPathPatternMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditInvalidASPathPatternAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editInvalidASPathPatternAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackPrefixVisibilityLoss = () => {
    onAddAlertRule({
      mutationObject: prefixVisibilityLossMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditPrefixVisibilityLossAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editPrefixVisibilityLossAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackPeeringVisibilityLoss = () => {
    onAddAlertRule({
      mutationObject: peeringVisibilityLossMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditPeeringVisibilityLossAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editPeeringVisibilityLossAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onAddAlertRuleCallbackLongASPath = () => {
    onAddAlertRule({
      mutationObject: longASPathMutationObject,
      addAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onEditLongASPathAlertRuleCallback = () => {
    onEditAlertRule({
      mutationObject: editLongASPathAlertRuleMutationObject,
      editAlertRule,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      onClose,
    });
  };

  const onNextButtonClick = () => {
    if (selectedStep === 0) {
      if (!!selectedAlert && !!customAlertName) {
        setSelectedStep(selectedStep + 1);
        setFailureReason("");
      }
    } else if (selectedStep === 1) {
      if (!emailValidation(selectedAlertReceiverEndpoint)) {
        setSelectedStep(selectedStep + 1);
        setFailureReason("");
      }
    } else {
      setSelectedStep(selectedStep + 1);
      setFailureReason("");
    }
  };

  const onBackButtonClick = () => {
    setSelectedStep(selectedStep - 1);
  };

  const setEditMode = () => {
    setEditModeOn(true);
    setSelectedStep(0);
    setSelectedID(editableAlertRule.id);
    setCustomAlertName(editableAlertRule.name);
    setSelectedAlert(editableAlertRule.type);
    setCustomDescription(editableAlertRule.description);
    setSelectedAlertReceiverType(editableAlertRule.receiver_type);
    setSelectedAlertReceiverEndpoint(editableAlertRule.receiver_endpoint);
    setSelectedAlertReceiverEndpointName(defaultAlertRuleReceiverEndpointName);
    switch (editableAlertRule.type) {
      case alertRuleDisplayName.unexpected_originated_prefixes:
        setSelectedAS(editableAlertRule.vars.asn);
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        setSelectedDataSourcesThreshold(editableAlertRule.vars.ds_thres);
        break;
      case alertRuleDisplayName.as_origin_violation_exact:
        setSelectedASes(editableAlertRule.vars.asns);
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        break;
      case alertRuleDisplayName.as_origin_violation_sub:
        setSelectedASes(editableAlertRule.vars.asns);
        setSelectedPrefixes(getSelectedPrefixesStrList(editableAlertRule.vars.prefixes));
        break;
      case alertRuleDisplayName.unexpected_peering:
        setSelectedAS(editableAlertRule.vars.configured_asn);
        setSelectedNeighborASes(editableAlertRule.vars.neighbor_asns);
        break;
      case alertRuleDisplayName.unexpected_neighbor_prefix:
        setSelectedOriginAS(editableAlertRule.vars.origin_asn);
        setSelectedNeighborASes(editableAlertRule.vars.neighbor_asns);
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        break;
      case alertRuleDisplayName.squatting:
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        setSelectedDataSourcesThreshold(editableAlertRule.vars.ds_thres);
        break;
      case alertRuleDisplayName.rpki_invalid_detect:
        setSelectedASes(editableAlertRule.vars.asns);
        setSelectedRPKIPrefixes(editableAlertRule.vars.prefixes);
        break;
      case alertRuleDisplayName.rpki_invalid_announce:
        setSelectedASes(editableAlertRule.vars.asns);
        break;
      case alertRuleDisplayName.rpki_invalid_propagate:
        setSelectedASes(regexToASes(editableAlertRule.vars.asn_regex));
        break;
      case alertRuleDisplayName.rpki_not_found_propagate:
        setSelectedASes(regexToASes(editableAlertRule.vars.asn_regex));
        break;
      case alertRuleDisplayName.bogon_prefix_exact:
        setSelectedASes(editableAlertRule.vars.asns);
        setSelectedBogonPrefixes(editableAlertRule.vars.bogon_prefixes);
        break;
      case alertRuleDisplayName.bogon_prefix_sub:
        setSelectedASes(editableAlertRule.vars.asns);
        setSelectedBogonPrefixes(getSelectedPrefixesStrList(editableAlertRule.vars.bogon_prefixes));
        break;
      case alertRuleDisplayName.as_path_diffs:
        setSelectedCompService(formatDataService(editableAlertRule.vars.comp_service));
        setSelectedBaseService(formatDataService(editableAlertRule.vars.base_service));
        setSelectedEndASN(editableAlertRule.vars.end_asn);
        break;
      case alertRuleDisplayName.prefix_diffs:
        setSelectedCompService(formatDataService(editableAlertRule.vars.comp_service));
        setSelectedBaseService(formatDataService(editableAlertRule.vars.base_service));
        setSelectedASes(editableAlertRule.vars.asns);
        break;
      case alertRuleDisplayName.custom:
        setCustomQuery(editableAlertRule.query);
        setCustomQueryParams(JSON.stringify(editableAlertRule.vars));
        setCustomFireAlertRegex(editableAlertRule.fire_alert_regex);
        setCustomDescription(editableAlertRule.description);
        setCustomSeverity(editableAlertRule.severity);
        break;
      case alertRuleDisplayName.bogon_as:
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        break;
      case alertRuleDisplayName.as_path_presence:
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        setSelectedPresentASes(regexToASes(editableAlertRule.vars.asn_present_regex));
        break;
      case alertRuleDisplayName.as_path_regex_violation:
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        setSelectedASPathRegex(editableAlertRule.vars.as_path_regex);
        break;
      case alertRuleDisplayName.low_prefix_visibility:
        setSelectedASes(editableAlertRule.vars.asns);
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        setSelectedDataSourcesThreshold(editableAlertRule.vars.ds_thres);
        break;
      case alertRuleDisplayName.low_peering_visibility:
        setSelectedAS(editableAlertRule.vars.configured_asn);
        setSelectedNeighborASes(editableAlertRule.vars.neighbor_asns);
        setSelectedDataSourcesThreshold(editableAlertRule.vars.ds_thres);
        break;
      case alertRuleDisplayName.as_path_length_violation:
        setSelectedPrefixes(editableAlertRule.vars.prefixes);
        setSelectedASPathLengthThreshold(editableAlertRule.vars.as_path_length_thres);
        break;
      default:
        break;
    }
  };

  const resetState = () => {
    setSelectedStep(0);
    setSelectedAlert(alertRuleDisplayName.as_origin_violation_exact);
    setCustomAlertName("");
    setSelectedAlertReceiverType(alertRuleReceiverType.email);
    setSelectedAlertReceiverEndpoint("");
    setSelectedAlertReceiverEndpointName(defaultAlertRuleReceiverEndpointName);
    setSelectedAS("");
    setConfirmedSelectedAS("");
    setSelectedASes([]);
    setSelectedPresentASes([]);
    setSelectedASPathRegex("");
    setSelectedPrefixes([]);
    setSelectedRPKIPrefixes([]);
    setSelectedBogonPrefixes(knownBogonPrefixes);
    setSelectedCompService(formatDataService(RIS_LIVE));
    setSelectedBaseService(formatDataService(BGP_ROUTER));
    setSelectedEndASN("");
    setSelectedDataSourcesThreshold("");
    setSelectedASPathLengthThreshold("");
    setSelectedOriginAS("");
    setSelectedNeighborASes([]);
    setCustomQuery("");
    setCustomQueryParams("");
    setCustomFireAlertRegex("");
    setCustomDescription("");
    setCustomSeverity("");
    setEditModeOn(false);
    setSelectedID("");
    setErrorEmail(false);
  };

  const onClose = () => {
    close();
    resetState();
  };

  const alertRuleName = `${customAlertName} (${selectedAlert})`;

  let alertTypeOptionsDisabled = false;
  if (editModeOn) {
    alertTypeOptionsDisabled = true;
  }

  const addAlertRuleFirstStep = (
    <>
      <Autocomplete
        key="alert-type"
        disabled={alertTypeOptionsDisabled}
        options={[
          alertRuleDisplayName.as_origin_violation_exact,
          alertRuleDisplayName.as_origin_violation_sub,
          alertRuleDisplayName.unexpected_originated_prefixes,
          alertRuleDisplayName.unexpected_peering,
          alertRuleDisplayName.unexpected_neighbor_prefix,
          alertRuleDisplayName.squatting,
          alertRuleDisplayName.as_path_presence,
          alertRuleDisplayName.as_path_regex_violation,
          alertRuleDisplayName.as_path_length_violation,
          alertRuleDisplayName.low_prefix_visibility,
          alertRuleDisplayName.low_peering_visibility,
          alertRuleDisplayName.rpki_invalid_detect,
          alertRuleDisplayName.rpki_invalid_announce,
          alertRuleDisplayName.rpki_invalid_propagate,
          alertRuleDisplayName.rpki_not_found_propagate,
          alertRuleDisplayName.bogon_prefix_exact,
          alertRuleDisplayName.bogon_prefix_sub,
          alertRuleDisplayName.bogon_as,
          alertRuleDisplayName.as_path_diffs,
          alertRuleDisplayName.prefix_diffs,
          alertRuleDisplayName.custom,
        ]}
        disableClearable
        value={selectedAlert}
        onChange={(event, newValue) => {
          setSelectedAlert(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-custom-name"
        freeSolo={true}
        options={[]}
        disableClearable
        inputValue={customAlertName}
        onInputChange={(event, newValue) => {
          setCustomAlertName(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 65,
              style: {},
            }}
            variant="standard"
            placeholder="Custom Name"
            label="Custom Name"
          />
        )}
      />
    </>
  );

  const routeLeakConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        key="select-as"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        inputValue={selectedAS}
        onInputChange={(event, newValue) => {
          setSelectedAS(newValue);
        }}
        onChange={(event, newValue) => {
          setConfirmedSelectedAS(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="AS"
            label="Origin AS"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          setSelectedPrefixes(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Valid Originated Prefixes"
          />
        )}
      />
      <Autocomplete
        key="select-data-sources-threshold"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedDataSourcesThreshold}
        onInputChange={(event, newValue) => {
          setSelectedDataSourcesThreshold(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 5,
              style: {},
            }}
            variant="standard"
            placeholder="# of Data Sources"
            label="Visibility Threshold (# of Data Sources)"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const exactPrefixHijackConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0 
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Originated Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const subPrefixHijackConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0 
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Originated Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const asPathComparisonConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        key="select-base-service"
        freeSolo={false}
        options={ASPathDataServices}
        disableClearable
        value={selectedBaseService}
        onChange={(event, newValue) => {
          setSelectedBaseService(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Data Service"
            label="Get AS paths from"
          />
        )}
      />
      <Autocomplete
        key="select-comp-service"
        freeSolo={false}
        options={ASPathDataServices}
        disableClearable
        value={selectedCompService}
        onChange={(event, newValue) => {
          setSelectedCompService(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Data Service"
            label="Compare AS paths with"
          />
        )}
      />
      <Autocomplete
        key="end-asn"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedEndASN}
        onInputChange={(event, newValue) => {
          setSelectedEndASN(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder="AS"
            label="Compare AS paths up to AS"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const prefixComparisonConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Autocomplete
        key="select-base-service"
        freeSolo={false}
        options={ASPathDataServices}
        disableClearable
        value={selectedBaseService}
        onChange={(event, newValue) => {
          setSelectedBaseService(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Data Service"
            label="Get prefixes from"
          />
        )}
      />
      <Autocomplete
        key="select-comp-service"
        freeSolo={false}
        options={ASPathDataServices}
        disableClearable
        value={selectedCompService}
        onChange={(event, newValue) => {
          setSelectedCompService(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Data Service"
            label="Compare prefixes with"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const squattingConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Configured ASes (optional, so configured prefixes are loaded)"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0 
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Squattable Prefixes"
          />
        )}
      />
      <Autocomplete
        key="select-data-sources-threshold"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedDataSourcesThreshold}
        onInputChange={(event, newValue) => {
          setSelectedDataSourcesThreshold(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 5,
              style: {},
            }}
            variant="standard"
            placeholder="# of Data Sources"
            label="Visibility Threshold (# of Data Sources)"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const neighborLeakHijackConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        key="select-origin-as"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        inputValue={selectedOriginAS}
        onInputChange={(event, newValue) => {
          setSelectedOriginAS(newValue);
        }}
        onChange={(event, newValue) => {
          setConfirmedSelectedAS(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="AS"
            label="Origin AS"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-neighbor-ases"
        freeSolo={true}
        options={[]}
        value={selectedNeighborASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedNeighborASes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Neighbor ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0 
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Announced Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const rpkiInvalidDetectionConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-rpki-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={rpkiPrefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedRPKIPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0 
          );
          setSelectedRPKIPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Valid Originated Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const rpkiInvalidAnnouncementConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const rpkiInvalidPropagationConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const rpkiNotFoundPropagationConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Origin ASes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const bogonExactPrefixConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Origin ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-bogon-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={knownBogonPrefixes}
        value={selectedBogonPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedBogonPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Bogon Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const bogonSubPrefixConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Origin ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-bogon-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={knownBogonPrefixes}
        value={selectedBogonPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedBogonPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Bogon Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const newNeighborConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        key="select-as"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        inputValue={selectedAS}
        onInputChange={(event, newValue) => {
          setSelectedAS(newValue);
        }}
        onChange={(event, newValue) => {
          setConfirmedSelectedAS(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="AS"
            label="Configured AS"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-neighbor-ases"
        freeSolo={true}
        options={[]}
        value={selectedNeighborASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedNeighborASes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Neighbor ASes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const bogonASConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Configured ASes (optional, so configured prefixes are loaded)"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Prefixes"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const asPathPresenceConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Configured ASes (optional, so configured prefixes are loaded)"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Prefixes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-present-ases"
        freeSolo={true}
        options={[]}
        disableClearable
        value={selectedPresentASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedPresentASes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="ASNs in question"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const invalidASPathConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Configured ASes (optional, so configured prefixes are loaded)"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Prefixes"
          />
        )}
      />
      <Autocomplete
        key="select-as-path-regex"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedASPathRegex}
        onInputChange={(event, newValue) => {
          setSelectedASPathRegex(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Regex"
            label="Valid AS Path Pattern (Regex)"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );


  let customChangeDisabled = false;
  if (editModeOn) {
    customChangeDisabled = true;
  }
  const customConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        key="alert-query"
        freeSolo
        options={[]}
        disabled={customChangeDisabled}
        disableClearable
        inputValue={customQuery}
        onInputChange={(event, newValue) => {
          setCustomQuery(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 500,
              style: {},
            }}
            variant="standard"
            placeholder="Query"
            label="GQL Subscription Query"
          />
        )}
      />
      <Autocomplete
        key="alert-params"
        freeSolo
        options={[]}
        disableClearable
        inputValue={customQueryParams}
        onInputChange={(event, newValue) => {
          setCustomQueryParams(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 500,
              style: {},
            }}
            variant="standard"
            placeholder="Parameters"
            label="GQL Subscription Query Parameters (JSON)"
          />
        )}
      />
      <Autocomplete
        key="alert-fire-regex"
        freeSolo
        options={[]}
        disabled={customChangeDisabled}
        disableClearable
        inputValue={customFireAlertRegex}
        onInputChange={(event, newValue) => {
          setCustomFireAlertRegex(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 200,
              style: {},
            }}
            variant="standard"
            placeholder="Regex"
            label="Alert Fire Regex"
          />
        )}
      />
      <Autocomplete
        key="alert-description"
        freeSolo
        options={[]}
        disableClearable
        inputValue={customDescription}
        onInputChange={(event, newValue) => {
          setCustomDescription(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 200,
              style: {},
            }}
            variant="standard"
            placeholder="Description"
            label="Alert Description"
          />
        )}
      />
      <Autocomplete
        key="alert-severity"
        options={[alertSeverity.critical, alertSeverity.warning]}
        disabled={customChangeDisabled}
        disableClearable
        value={customSeverity}
        onInputChange={(event, newValue) => {
          setCustomSeverity(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder="Severity"
            label="Alert Severity"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const prefixVisibilityLossConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Origin ASes"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        forcePopupIcon={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Originated Prefixes"
          />
        )}
      />
      <Autocomplete
        key="select-data-sources-threshold"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedDataSourcesThreshold}
        onInputChange={(event, newValue) => {
          setSelectedDataSourcesThreshold(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 5,
              style: {},
            }}
            variant="standard"
            placeholder="# of Data Sources"
            label="Visibility Threshold (# of Data Sources)"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const peeringVisibilityLossConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        key="select-as"
        freeSolo={true}
        forcePopupIcon={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        inputValue={selectedAS}
        onInputChange={(event, newValue) => {
          setSelectedAS(newValue);
        }}
        onChange={(event, newValue) => {
          setConfirmedSelectedAS(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="AS"
            label="Configured AS"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-neighbor-ases"
        freeSolo={true}
        options={[]}
        value={selectedNeighborASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedNeighborASes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Valid Neighbor ASes"
          />
        )}
      />
      <Autocomplete
        key="select-data-sources-threshold"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedDataSourcesThreshold}
        onInputChange={(event, newValue) => {
          setSelectedDataSourcesThreshold(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 5,
              style: {},
            }}
            variant="standard"
            placeholder="# of Data Sources"
            label="Visibility Threshold (# of Data Sources)"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const longASPathConfiguration = (
    <>
      <Typography fontWeight="bold">{alertRuleName}</Typography>
      <Divider />
      <Typography fontWeight="bold">Parameters</Typography>
      <Autocomplete
        multiple
        key="select-ases"
        freeSolo={true}
        options={convertIntsToStrings(configuredASNs)}
        disableClearable
        value={selectedASes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (asn) => !isNaN(asn) && !isNaN(parseInt(asn))
          );
          setSelectedASes(filteredArray);
          const newAS = filteredArray.filter(
            (asn) => !selectedASes.includes(asn)
          )[0];
          setConfirmedSelectedAS(newAS);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="ASes"
            label="Configured ASes (optional, so configured prefixes are loaded)"
          />
        )}
      />
      <Autocomplete
        multiple
        key="select-prefixes"
        freeSolo={true}
        options={prefixesMyASNsOriginate?.map((prefix) => prefix)}
        value={selectedPrefixes}
        onChange={(event, newValue) => {
          const filteredArray = newValue.filter(
            (prefix) => isCidr(prefix) !== 0
          );
          setSelectedPrefixes(filteredArray);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Prefixes"
            label="Prefixes"
          />
        )}
      />
      <Autocomplete
        key="select-as-path-length-thres"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedASPathLengthThreshold}
        onInputChange={(event, newValue) => {
          setSelectedASPathLengthThreshold(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Threshold"
            label="AS Path Length Threshold"
          />
        )}
      />
      <Divider />
      <Typography fontWeight="bold">Notifications</Typography>
      <Autocomplete
        key="alert-receiver-type"
        options={[alertRuleReceiverType.email]}
        disableClearable
        value={selectedAlertReceiverType}
        onChange={(event, newValue) => {
          setSelectedAlertReceiverType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 50,
              style: {},
            }}
            variant="standard"
            placeholder="Type"
            label="Type"
          />
        )}
      />
      <Autocomplete
        key="alert-receiver-endpoint"
        freeSolo
        options={[]}
        disableClearable
        inputValue={selectedAlertReceiverEndpoint}
        onInputChange={(event, newValue) => {
          setSelectedAlertReceiverEndpoint(newValue);
          switch (newValue) {
            case alertRuleReceiverType.email:
              setSelectedAlertReceiverEndpointName(
                alertRuleReceiverEndpointName[newValue]
              );
              break;
            default:
              break;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              maxLength: 100,
              style: {},
            }}
            variant="standard"
            placeholder={selectedAlertReceiverEndpointName}
            label={selectedAlertReceiverEndpointName}
            error={errorEmail}
            helperText={errorEmail && "Invalid Email Address!"}
          />
        )}
      />
      <Divider />
    </>
  );

  const routeLeakPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          { parameter: parameterNameMap.asn.name, value: selectedAS },
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
          {
            parameter: parameterNameMap.ds_thres.name,
            value: selectedDataSourcesThreshold,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const exactPrefixHijackPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const subPrefixHijackPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const asPathComparisonPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.base_service.name,
            value: selectedBaseService,
          },
          {
            parameter: parameterNameMap.comp_service.name,
            value: selectedCompService,
          },
          {
            parameter: parameterNameMap.end_asn.name,
            value: selectedEndASN,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const prefixComparisonPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.base_service.name,
            value: selectedBaseService,
          },
          {
            parameter: parameterNameMap.comp_service.name,
            value: selectedCompService,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );


  const squattingPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
          {
            parameter: parameterNameMap.ds_thres.name,
            value: selectedDataSourcesThreshold,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const neighborLeakHijackPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.origin_asn.name,
            value: selectedOriginAS,
          },
          {
            parameter: parameterNameMap.neighbor_asns.name,
            value: selectedNeighborASes,
          },
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const rpkiInvalidDetectionPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedRPKIPrefixes,
          },
          {
            parameter: parameterNameMap.rpki_status.name,
            value: "invalid",
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const rpkiInvalidAnnouncementPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.rpki_status.name,
            value: "invalid",
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const rpkiInvalidPropagationPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.rpki_status.name,
            value: "invalid",
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const rpkiNotFoundPropagationPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.rpki_status.name,
            value: "not-found",
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const bogonExactPrefixPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.bogon_prefixes.name,
            value: selectedBogonPrefixes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const bogonSubPrefixPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.bogon_prefixes.name,
            value: selectedBogonPrefixes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const newNeighborPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asn.name,
            value: selectedAS,
          },
          {
            parameter: parameterNameMap.neighbor_asns.name,
            value: selectedNeighborASes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const customPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.query.name,
            value: customQuery,
          },
          {
            parameter: parameterNameMap.query_params.name,
            value: customQueryParams,
          },
          {
            parameter: parameterNameMap.fire_alert_regex.name,
            value: customFireAlertRegex,
          },
          {
            parameter: parameterNameMap.description.name,
            value: customDescription,
          },
          {
            parameter: parameterNameMap.severity.name,
            value: customSeverity,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const bogonASPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const asPathPresencePreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
          {
            parameter: parameterNameMap.asn_present_regex.name,
            value: selectedPresentASes.join(", "),
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );


  const invalidASPathPatternPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
          {
            parameter: parameterNameMap.as_path_regex.name,
            value: selectedASPathRegex,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const prefixVisibilityLossPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asns.name,
            value: selectedASes,
          },
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
          {
            parameter: parameterNameMap.ds_thres.name,
            value: selectedDataSourcesThreshold,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const peeringVisibilityLossPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.asn.name,
            value: selectedAS,
          },
          {
            parameter: parameterNameMap.neighbor_asns.name,
            value: selectedNeighborASes,
          },
          {
            parameter: parameterNameMap.ds_thres.name,
            value: selectedDataSourcesThreshold,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  const longASPathPreview = (
    <>
      <Typography>
        Summary for alert rule:
        <Typography fontWeight="bold">{alertRuleName}</Typography>
      </Typography>
      <ArgsTable
        rows={[
          {
            parameter: parameterNameMap.prefixes.name,
            value: selectedPrefixes,
          },
          {
            parameter: parameterNameMap.as_path_length_thres.name,
            value: selectedASPathLengthThreshold,
          },
        ]}
      />
      <Typography fontWeight="bold">
        <Typography>Notifications:</Typography>
        {selectedAlertReceiverType} ({selectedAlertReceiverEndpoint})
      </Typography>
      {failureMessage}
    </>
  );

  let stepperContent;
  let secondaryButton = (
    <SecondaryButton onClick={onBackButtonClick} title="Previous" />
  );
  let mainButton = (
    <MainButton
      onClick={onNextButtonClick}
      title="Next"
      customStyles={{ width: "6rem" }}
      enabled={!!selectedAlert && !!customAlertName}
    />
  );

  switch (selectedStep) {
    case 0:
      secondaryButton = null;
      stepperContent = addAlertRuleFirstStep;
      break;
    case 1:
      switch (selectedAlert) {
        case alertRuleDisplayName.unexpected_originated_prefixes:
          stepperContent = routeLeakConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                isASNInputValid(selectedAS) &&
                selectedPrefixes.length !== 0 &&
                isInputNumeric(selectedDataSourcesThreshold) &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.as_origin_violation_exact:
          stepperContent = exactPrefixHijackConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.as_origin_violation_sub:
          stepperContent = subPrefixHijackConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.as_path_diffs:
          stepperContent = asPathComparisonConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedEndASN !== "" &&
                selectedBaseService !== "" &&
                selectedCompService !== "" &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.prefix_diffs:
          stepperContent = prefixComparisonConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedBaseService !== "" &&
                selectedCompService !== "" &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.squatting:
          stepperContent = squattingConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedPrefixes.length !== 0 &&
                isInputNumeric(selectedDataSourcesThreshold) &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.unexpected_neighbor_prefix:
          stepperContent = neighborLeakHijackConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                isASNInputValid(selectedOriginAS) &&
                selectedNeighborASes.length !== 0 &&
                selectedPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.rpki_invalid_detect:
          stepperContent = rpkiInvalidDetectionConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedRPKIPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.rpki_invalid_announce:
          stepperContent = rpkiInvalidAnnouncementConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.rpki_invalid_propagate:
          stepperContent = rpkiInvalidPropagationConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.rpki_not_found_propagate:
          stepperContent = rpkiNotFoundPropagationConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.bogon_prefix_exact:
          stepperContent = bogonExactPrefixConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedBogonPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.bogon_prefix_sub:
          stepperContent = bogonSubPrefixConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedBogonPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.unexpected_peering:
          stepperContent = newNeighborConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                isASNInputValid(selectedAS) &&
                selectedNeighborASes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.custom:
          stepperContent = customConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                customQuery !== "" &&
                isJSONInputValid(customQueryParams) &&
                customFireAlertRegex !== "" &&
                customSeverity !== "" &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.bogon_as:
          stepperContent = bogonASConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedPrefixes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.as_path_presence:
          stepperContent = asPathPresenceConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedPrefixes.length !== 0 &&
                selectedPresentASes.length !== 0 &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.as_path_regex_violation:
          stepperContent = invalidASPathConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedPrefixes.length !== 0 &&
                selectedASPathRegex !== "" &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.low_prefix_visibility:
          stepperContent = prefixVisibilityLossConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedASes.length !== 0 &&
                selectedPrefixes.length !== 0 &&
                isInputNumeric(selectedDataSourcesThreshold) &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.low_peering_visibility:
          stepperContent = peeringVisibilityLossConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                isASNInputValid(selectedAS) &&
                selectedNeighborASes.length !== 0 &&
                isInputNumeric(selectedDataSourcesThreshold) &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        case alertRuleDisplayName.as_path_length_violation:
          stepperContent = longASPathConfiguration;
          mainButton = (
            <MainButton
              onClick={onNextButtonClick}
              title="Next"
              customStyles={{ width: "6rem" }}
              enabled={
                selectedPrefixes.length !== 0 &&
                isInputNumeric(selectedASPathLengthThreshold) &&
                selectedAlertReceiverEndpoint !== ""
              }
            />
          );
          break;
        default:
          break;
      }
      break;
    case 2:
      switch (selectedAlert) {
        case alertRuleDisplayName.unexpected_originated_prefixes:
          stepperContent = routeLeakPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackRouteLeak}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditRouteLeakAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.as_origin_violation_exact:
          stepperContent = exactPrefixHijackPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackExactPrefixHijack}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditExactPrefixHijackAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.as_origin_violation_sub:
          stepperContent = subPrefixHijackPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackSubPrefixHijack}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditSubPrefixHijackAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.as_path_diffs:
          stepperContent = asPathComparisonPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackASPathComparison}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditASPathComparisonAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.prefix_diffs:
          stepperContent = prefixComparisonPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackPrefixComparison}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditPrefixComparisonAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.squatting:
          stepperContent = squattingPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackSquatting}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditSquattingAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.unexpected_neighbor_prefix:
          stepperContent = neighborLeakHijackPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackNeighborLeakHijack}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditNeighborLeakHijackAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.rpki_invalid_detect:
          stepperContent = rpkiInvalidDetectionPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackRPKIInvalidDetection}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditRPKIInvalidDetectionAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.rpki_invalid_announce:
          stepperContent = rpkiInvalidAnnouncementPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackRPKIInvalidAnnouncement}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditRPKIInvalidAnnouncementAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.rpki_invalid_propagate:
          stepperContent = rpkiInvalidPropagationPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackRPKIInvalidPropagation}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditRPKIInvalidPropagationAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.rpki_not_found_propagate:
          stepperContent = rpkiNotFoundPropagationPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackRPKINotFoundPropagation}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditRPKINotFoundPropagationAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.bogon_prefix_exact:
          stepperContent = bogonExactPrefixPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackBogonExactPrefix}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditBogonExactPrefixAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.bogon_prefix_sub:
          stepperContent = bogonSubPrefixPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackBogonSubPrefix}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditBogonSubPrefixAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.unexpected_peering:
          stepperContent = newNeighborPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackNewNeighbor}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditNewNeighborAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.custom:
          stepperContent = customPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackCustom}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditCustomAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.bogon_as:
          stepperContent = bogonASPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackBogonAS}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditBogonASAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.as_path_presence:
          stepperContent = asPathPresencePreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackASPathPresence}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditASPathPresenceAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.as_path_regex_violation:
          stepperContent = invalidASPathPatternPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackInvalidASPathPattern}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditInvalidASPathPatternAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.low_prefix_visibility:
          stepperContent = prefixVisibilityLossPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackPrefixVisibilityLoss}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditPrefixVisibilityLossAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.low_peering_visibility:
          stepperContent = peeringVisibilityLossPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackPeeringVisibilityLoss}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditPeeringVisibilityLossAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        case alertRuleDisplayName.as_path_length_violation:
          stepperContent = longASPathPreview;
          if (!editModeOn) {
            mainButton = (
              <MainButton
                onClick={onAddAlertRuleCallbackLongASPath}
                title="Add Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          } else {
            mainButton = (
              <MainButton
                onClick={onEditLongASPathAlertRuleCallback}
                title="Edit Alert Rule"
                customStyles={{ width: "8.25rem" }}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  var windowTitle = "Add Alert Rule";
  if (editModeOn) {
    windowTitle = "Edit Alert Rule";
  }
  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack sx={{ width: "600px" }}>
        <Typography
          sx={{
            pl: "2rem",
            pt: "1.5rem",
            pb: "1rem",
            backgroundColor: "gray4.main",
            color: "primary.main",
          }}
          fontSize="1.25rem"
        >
          {windowTitle}
        </Typography>
        <Stack
          sx={{
            p: "2rem",
            justifyContent: "space-between",
          }}
        >
          <AddAlertRuleStepper selectedStep={selectedStep} />
          <Stack sx={{ mb: "2rem" }} spacing={4}>
            {stepperContent}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <CancelButton
              onClick={onClose}
              customStyles={{
                width: "unset",
                minWidth: "0",
                padding: "0",
                textDecoration: "underline",
                backgroundColor: "unset",
                borderStyle: "unset",
                ":hover": {
                  backgroundColor: "unset",
                  textDecoration: "underline",
                },
              }}
            />
            <Stack direction="row" justifyContent="space-between" spacing={2.5}>
              {secondaryButton}
              {mainButton}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
