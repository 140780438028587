import {
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  bodyCellStyles,
  headerCellStyles,
} from "../constants/components/confAlertsInfoTable";
import { rows } from "../constants/components/confAlertsInfoTable";

export const ConfAlertsInfoTable = () => {
  return (
    <Table sx={{ mt: "1rem" }}>
      <TableHead>
        <TableRow>
          <TableCell sx={headerCellStyles} align="center">
            Supported Alert Types
          </TableCell>
          <TableCell sx={headerCellStyles} align="center">
            Severity
          </TableCell>
          <TableCell sx={headerCellStyles} align="center">
            Description
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ type, severity, description, comingSoon }) => (
          <TableRow key={type}>
            <TableCell sx={bodyCellStyles} component="th" scope="row">
              <Stack
                sx={{ alignItems: "center", justifyContent: "space-between" }}
                direction="row"
              >
                {type}
                {comingSoon ? (
                  <Chip
                    sx={{
                      height: "1rem",
                      backgroundColor: "blue3.main",
                      color: "white",
                      fontSize: "0.625rem",
                      ".MuiChip-label": {
                        px: "0.25rem",
                      },
                    }}
                    label="Coming soon"
                  />
                ) : (
                  ""
                )}
              </Stack>
            </TableCell>
            <TableCell sx={bodyCellStyles} align="center">
              {severity}
            </TableCell>
            <TableCell sx={bodyCellStyles}>{description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
