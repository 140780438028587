import { getInitialSingleDataResult } from "../constants/store";
import { SingleDataResult } from "../types/store";

/**
 * @param {object} params
 * @param {number | null | undefined} params.data_source_count
 * @param {number} params.totalNumberOfDataSources
 * @returns {string | undefined}
 */
export const getDataSourcesPercentage = ({
  data_source_count,
  totalNumberOfDataSources,
}) => {
  if (!Number.isInteger(data_source_count) || totalNumberOfDataSources <= 0)
    return undefined;
  return ((100 * data_source_count) / totalNumberOfDataSources).toPrecision(2);
};

/**
 * @param {SingleDataResult<number>} count1
 * @param {SingleDataResult<number>} count2
 * @param {SingleDataResult<number>} count3
 * @returns {SingleDataResult<number>}
 */
export const getDataSourcesCountResult = (count1, count2, count3) => {
  const result = getInitialSingleDataResult(0);
  result.data = (count1.data ?? 0) + (count2.data ?? 0) + (count3.data ?? 0);
  result.fetching = count1.fetching || count2.fetching || count3.fetching;
  result.error = count1.error ?? count2.error ?? count3.error;
  return result;
};

/**
 * @param {string} asn
 * @returns {string}
 */
const getASNInPathRegex = (asn) => {
  return `(^${asn}$)|(.*\\s+${asn}\\s+.*)|(.*\\s+${asn}$)|(^${asn}\\s+.*)`;
};

/**
 * @param {string[]} asns
 * @returns {string}
 */
export const getASNInPathRegexes = (asns) => {
  const regexesArray = asns.map((asn) => getASNInPathRegex(asn));
  return regexesArray.join("|");
};

export const doOnViewColumnsChange = ({
  changedColumn,
  action,
  columnNameToVisibleSetterMap,
  columnsEnum,
}) => {
  if (!Object.values(columnsEnum).some(({ name }) => changedColumn === name)) {
    console.error("Unknown onViewColumnsChange changedColumn.");
    return;
  }
  if (action !== "add" && action !== "remove") {
    console.error("Unknown onViewColumnsChange action.");
    return;
  }

  const visibleSetter = columnNameToVisibleSetterMap[changedColumn];
  switch (action) {
    case "add":
      visibleSetter(true);
      break;
    case "remove":
      visibleSetter(false);
      break;
    default:
      break;
  }
};
