import { ReactElement, MouseEventHandler, useState, useEffect } from "react";
import { Table } from "./Table";
import { ActionButton } from "./ActionButton";
import { addSecondsToTimeFilter, auth0HookGetter, createGQLEndpoint, formatTime, getSubscription, isEditor } from "../other/utils";
import { useAtom } from "jotai";
import { configuredAlertsAtom } from "../other/store";
import {
  nameFilterAtom,
  selectedRowIDsAtom,
  countAtom,
  pageAtom,
  rowsPerPageAtom,
  expandedRowIDsAtom,
  typeFilterAtom,
  minTimeCreatedFilterAtom,
  maxTimeCreatedFilterAtom,
} from "../store/confAlertsTable";
import {
  getTableData,
  onApplyFiltersButtonClick,
  getColumns,
  getRowID,
  onApplySortAlertDataButtonClick,
} from "../utils/components/confAlertsTable";
import { COLUMNS_ENUM } from "../constants/components/confAlertsTable";
import { TABLE_KEY_ENUM } from "../constants/components/table";
import { ConfAlertsExpandedRow } from "./ConfAlertsExpandedRow";
import {
  alertRuleDisplayName,
  alertRuleReceiverType,
  alertSeverity,
} from "../constants/components/confAlertsInfoTable";
import { ExportDataButton } from "./ExportDataButton";
import { useAccessToken } from "../hooks/useAccessToken";
import { ConfAlertsQueryDocument, ConfAlertsQueryFilteredDocument, AlertSubscriptions_Bool_Exp } from "../graphql/generated/operations";
import { CircularProgress, Snackbar, Stack, Typography } from "@mui/material";
import { Parser } from '@json2csv/plainjs';
import { SortAlertRulesDataButton } from "./SortAlertRulesDataButton";
import { usePagination } from "../hooks/usePagination";
import { ShareUrlButton } from "./ShareUrlButton";
import { PATHS } from "../constants/paths";
import { Alert } from "./Alert";
import { ImportDataButton } from "./ImportDataButton";
import { useOpenClose } from "../hooks/useOpenClose";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { ImportAlertRulesFromCSV } from "./ImportAlertRulesFromCSV";


/**
 * @param {object} props
 * @param {string} props.role
 * @param {Function} props.openAddConfAlertDialog
 * @param {Function} props.openRemoveConfAlertDialog
 * @param {Function} props.setAlertRulesToRemove
 * @param {Function=} props.setEditableAlertRule
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const ConfAlertsTable = ({
  role,
  openAddConfAlertDialog,
  openRemoveConfAlertDialog,
  setAlertRulesToRemove,
  setEditableAlertRule,
  urlParams
}) => {
  const [
    {
      data: configuredAlerts,
      fetching: configuredAlertsFetching,
      error: configuredAlertsError,
    },
  ] = useAtom(configuredAlertsAtom);
  const [nameFilter, setNameFilter] = useAtom(nameFilterAtom);
  const [minTimeCreatedFilter, setMinTimeCreatedFilter] = useAtom(minTimeCreatedFilterAtom);
  const [maxTimeCreatedFilter, setMaxTimeCreatedFilter] = useAtom(maxTimeCreatedFilterAtom);
  const [typeFilter, setTypeFilter] = useAtom(typeFilterAtom);
  const [selectedRowIDs, setSelectedRowIDs] = useAtom(selectedRowIDsAtom);
  const [expandedRowIDs, setExpandedRowIDs] = useAtom(expandedRowIDsAtom);
  const { resetPage } = usePagination({ pageAtom, rowsPerPageAtom });

  const {
    isOpen: isImportAlertRulesOpen,
    open: openImportAlertRulesDialog,
    close: closeImportAlertRulesDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [copiedURL, setCopiedURL] = useState(false);

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "nameFilter":
            setNameFilter(item[1]);
            break;
          case "minTimeCreatedFilter":
            setMinTimeCreatedFilter(item[1]);
            break;
          case "maxTimeCreatedFilter":
            setMaxTimeCreatedFilter(item[1]);
            break;
          case "typeFilter":
            setTypeFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  const addButton = (
    <ActionButton
      title="Add Alert Rule"
      customStyles={{ width: "8.75rem" }}
      onClick={() => openAddConfAlertDialog()}
      enabled={isEditor(role)}
    />
  );

  /** @type {(string | undefined | ReactElement)[][]} */
  let tableData = [];

  if (configuredAlerts) {
    tableData = getTableData({
      configuredAlerts,
    });
  }

  const onRowsDelete = (rowsDeleted) => {
    const alertRulesToRemove = rowsDeleted.data.map(({ dataIndex }) => ({
      id: tableData[dataIndex][COLUMNS_ENUM.ID.order],
      name: tableData[dataIndex][COLUMNS_ENUM.NAME.order],
      alldata: tableData[dataIndex]
    }));
    setAlertRulesToRemove(alertRulesToRemove);
    openRemoveConfAlertDialog();
    return false;
  };

  const [count, setCount] = useAtom(countAtom);

  /**
   * @param {object} params
   * @param {Function} params.applyNewFilters
   * @returns {void}
   */
  const onApplyFiltersButtonClickCallback = ({ applyNewFilters }) => {
    onApplyFiltersButtonClick({
      applyNewFilters,
      setNameFilter,
      setTypeFilter,
      setMinTimeCreatedFilter,
      setMaxTimeCreatedFilter,
      resetPage
    });
  };

  const columns = getColumns({
    tableData,
    nameFilter,
    minTimeCreatedFilter,
    maxTimeCreatedFilter,
    typeFilter,
    setMinTimeCreatedFilter,
    setMaxTimeCreatedFilter
  });

  const { user } = auth0HookGetter()();
  const accessToken = useAccessToken();

  const [isCircularBarDownloadEnabled, setCircularBarDownload] = useState(false);

  const DownloadFile = () => {
    setCircularBarDownload(true);
    const url = createGQLEndpoint(user);
    const graphqlAPIURL = new URL(url, window.location.origin);
    let documentQuery = getSubscription(ConfAlertsQueryFilteredDocument);
    /** @type {AlertSubscriptions_Bool_Exp} */
    const whereArgument = {};
    const andArray = [];
    let queryVariables = {}; 
    let filename = "Alert_Rules_Filtered.csv";
    if (typeof nameFilter === "string" && nameFilter !== "") {
      andArray.push({name: {_iregex: nameFilter}});
    }
    if (typeof typeFilter === "string" && typeFilter !== "") {
      andArray.push({type: {_eq: Object.keys(alertRuleDisplayName).find(key => alertRuleDisplayName[key] === typeFilter)}});
    }       
    if (minTimeCreatedFilter && maxTimeCreatedFilter) {
      andArray.push({activation_time: {_gt: addSecondsToTimeFilter(minTimeCreatedFilter, -1), _lt: addSecondsToTimeFilter(maxTimeCreatedFilter, +1)}});
    } else if (minTimeCreatedFilter) {
      andArray.push({activation_time: {_gt: addSecondsToTimeFilter(minTimeCreatedFilter, -1)}});
    } else if (maxTimeCreatedFilter) {
      andArray.push({activation_time: {_lt: addSecondsToTimeFilter(maxTimeCreatedFilter, +1)}});
    }

    if (andArray.length === 0) {
      filename = "Alert_Rules.csv";
      documentQuery = getSubscription(ConfAlertsQueryDocument);
    } else {
      whereArgument._and = andArray;
      queryVariables = {where: whereArgument};
    }

    fetch(graphqlAPIURL.href, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: documentQuery,
        variables: queryVariables,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCircularBarDownload(false);
        data["data"]["alertSubscriptions"].forEach((item) => {
          item.type = alertRuleDisplayName[item.type];
        });
        const opts = {
          fields: [
            {
              label: "Name",
              value: "name"
            },
            {
              label: "Type",
              value: "type"
            },
            {
              label: "Variables",
              value: "vars"
            },
            {
              label: "Activation Time",
              value: "activation_time"
            },
            {
              label: "Receiver Type",
              value: "receiver_type"
            },
            {
              label: "Receiver Endpoint",
              value: "receiver_endpoint"
            },
            {
              label: "ID",
              value: "id"
            },
            {
              label: "QUERY",
              value: "query"
            }
          ]
        }
        const parser = new Parser(opts);
        const csv = parser.parse(data["data"]["alertSubscriptions"]);
        const blob = new Blob([csv], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = filename;
        link.href = url;
        link.click();
      })
      .catch((err) => {
        setCircularBarDownload(false);
        console.log(err);
      });
  };

  const [sortAlertsOrder, setSortAlertsOrder] = useState({
    name: "Name",
    direction: 'asc'
  });
  const onApplySortAlertDataButtonClickCallback = (applyNewAlertsSortingType) => {
    onApplySortAlertDataButtonClick({
      applyNewAlertsSortingType,
      setSortAlertsOrder
    });
  };

  const shareUrlData = () => {
    let filters = "";
    if (nameFilter !== "" && typeof nameFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "nameFilter=" + nameFilter + "&";
    }
    if (
      minTimeCreatedFilter !== "" &&
      typeof minTimeCreatedFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters += "minTimeCreatedFilter=" + minTimeCreatedFilter + "&";
    }
    if (maxTimeCreatedFilter !== "" && typeof maxTimeCreatedFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "maxTimeCreatedFilter=" + maxTimeCreatedFilter + "&";
    }
    if (typeFilter !== "" && typeof typeFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "typeFilter=" + typeFilter + "&";
    }
    const serviceURL = `https://${window.location.host}${PATHS.dashboard.setup.alerts}${filters}`;
    navigator.clipboard.writeText(serviceURL);
    setCopiedURL(true);
  }

  const handleClose = () => {
    setCopiedURL(false);
  };

  const HeaderElements = () => (
    <>
      <ImportDataButton onClick={openImportAlertRulesDialog} />
      <ExportDataButton onClick={DownloadFile} />
      <SortAlertRulesDataButton sortCallback={onApplySortAlertDataButtonClickCallback} />
      <ShareUrlButton onClick={shareUrlData} toolbarIconOrder={-2} />
    </>
  );

  let importAlertRulesDialog;
  let successSnackbar;
  if (isEditor(role)) {
    importAlertRulesDialog = (
      <ImportAlertRulesFromCSV
        isOpen={isImportAlertRulesOpen}
        close={closeImportAlertRulesDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        tableData={tableData}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <Table
        title={  
          <Stack direction={"row"}>
            {addButton}
            {isCircularBarDownloadEnabled && <Stack direction={"row"} style={{paddingLeft: "50px"}}>
              <CircularProgress /> 
              <Typography style={{margin: "10px"}}>
                Download In Progress ...
              </Typography>
            </Stack>}
          </Stack>
        }
        data={tableData}
        loading={configuredAlertsFetching}
        error={configuredAlertsError}
        selectedRowsTextLabels={{
          text: "alert rules selected",
          delete: "Delete",
          deleteAria: "Delete Selected Alert Rules",
        }}
        columns={columns}
        customOptions={{
          sortOrder: sortAlertsOrder,
          expandableRows: true,
          selectableRowsHideCheckboxes: !isEditor(role),
          onRowsDelete,
          selectableRows: "multiple",
          customToolbar: HeaderElements,
          selectableRowsHeader: false,
          viewColumns: false,
          pagination: true,
          rowHover: false,
          responsive: "standard",
          tableBodyMaxHeight: "70vh",
          renderExpandableRow: (rowData, rowMeta) => {
            const createdDate = formatTime(
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.ACTIVATION_TIME.order]
            );
            var args = tableData[rowMeta.dataIndex][COLUMNS_ENUM.VARS.order];
            const receiverType =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.RECEIVER_TYPE.order];
            const alertType =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.TYPE.order];
            if (alertType === "custom") {
              args = {
                query_params: JSON.stringify(
                  tableData[rowMeta.dataIndex][COLUMNS_ENUM.VARS.order]
                ),
                query: tableData[rowMeta.dataIndex][COLUMNS_ENUM.QUERY.order],
                fire_alert_regex:
                  tableData[rowMeta.dataIndex][
                  COLUMNS_ENUM.FIRE_ALERT_REGEX.order
                  ],
                description:
                  tableData[rowMeta.dataIndex][COLUMNS_ENUM.DESCRIPTION.order],
                severity:
                  tableData[rowMeta.dataIndex][COLUMNS_ENUM.SEVERITY.order],
              };
            }
            const formattedReceiverType = alertRuleReceiverType[receiverType];
            const receiverEndpoint =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.RECEIVER_ENDPOINT.order];
            return (
              <ConfAlertsExpandedRow
                createdDate={createdDate}
                args={args}
                receiverType={formattedReceiverType}
                receiverEndpoint={receiverEndpoint}
              />
            );
          },
          onCellClick: (colData, cellMeta) => {
            //recognize if the edit button was clicked
            if (colData().props.id === "edit-rule" && isEditor(role)) {
              const alertRuleData = tableData[cellMeta.dataIndex];
              const alertRuleObj = {
                name: alertRuleData[COLUMNS_ENUM.NAME.order],
                type: alertRuleDisplayName[alertRuleData[COLUMNS_ENUM.TYPE.order]],
                vars: alertRuleData[COLUMNS_ENUM.VARS.order],
                activation_time:
                  alertRuleData[COLUMNS_ENUM.ACTIVATION_TIME.order],
                id: alertRuleData[COLUMNS_ENUM.ID.order],
                receiver_type: alertRuleReceiverType[alertRuleData[COLUMNS_ENUM.RECEIVER_TYPE.order]],
                receiver_endpoint:
                  alertRuleData[COLUMNS_ENUM.RECEIVER_ENDPOINT.order],
                query: alertRuleData[COLUMNS_ENUM.QUERY.order],
                fire_alert_regex:
                  alertRuleData[COLUMNS_ENUM.FIRE_ALERT_REGEX.order],
                description: alertRuleData[COLUMNS_ENUM.DESCRIPTION.order],
                severity: alertSeverity[alertRuleData[COLUMNS_ENUM.SEVERITY.order]],
              };
              setEditableAlertRule && setEditableAlertRule(alertRuleObj);
              openAddConfAlertDialog();
            }
          },
          textLabels: {
            body: {
              noMatch: "No Alert Rules found."
            }
          }
        }}
        themeKey={TABLE_KEY_ENUM.ALERTSSETUP}
        pageAtom={pageAtom}
        rowsPerPageAtom={rowsPerPageAtom}
        count={count}
        setCount={setCount}
        onApplyFiltersButtonClick={onApplyFiltersButtonClickCallback}
        getRowID={getRowID}
        selectedRowIDs={selectedRowIDs}
        setSelectedRowIDs={setSelectedRowIDs}
        expandedRowIDs={expandedRowIDs}
        setExpandedRowIDs={setExpandedRowIDs}
        displayDownloadButton={false}
      />
      <Snackbar
        open={copiedURL}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Share link copied to clipboard
        </Alert>
      </Snackbar>
      {importAlertRulesDialog}
      {successSnackbar}
    </Stack>
    );
};
