import { ReactElement, MouseEventHandler, useState, useEffect } from "react";
import { Table } from "./Table";
import { ActionButton } from "./ActionButton";
import { isEditor } from "../other/utils";
import { useAtom } from "jotai";
import { configuredPrefixesAtom, hiddenPrefixesAtom } from "../other/store";
import {
  getColumns,
  getRowID,
  getTableData,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
} from "../utils/components/confPrefixesTable";
import { useInvertedToggleGroup } from "../hooks/useInvertedToggleGroup";
import {
  countAtom,
  displayedFilterAtom,
  isDisplayedVisibleAtom,
  isNetworkVisibleAtom,
  networkFilterAtom,
  pageAtom,
  rowsPerPageAtom,
  selectedRowIDsAtom,
} from "../store/confPrefixesTable";
import { usePagination } from "../hooks/usePagination";
import { Stack } from "@mui/system";
import { Snackbar } from "@mui/material";
import { Alert } from "./Alert";
import { ShareUrlButton } from "./ShareUrlButton";
import { PATHS } from "../constants/paths";
import { useOpenClose } from "../hooks/useOpenClose";
import { ImportDataButton } from "./ImportDataButton";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { ImportPrefixesFromCSV } from "./ImportPrefixesFromCSV";

/**
 * @param {object} props
 * @param {string} props.role
 * @param {MouseEventHandler} props.openAddConfPrefixDialog
 * @param {Function} props.openRemoveConfPrefixDialog
 * @param {Function} props.setPrefixesToRemove
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const ConfPrefixesTable = ({
  role,
  openAddConfPrefixDialog,
  openRemoveConfPrefixDialog,
  setPrefixesToRemove,
  urlParams,
}) => {
  const [
    {
      data: configuredPrefixes,
      fetching: configuredPrefixesFetching,
      error: configuredPrefixesError,
    },
  ] = useAtom(configuredPrefixesAtom);
  const [networkFilter, setNetworkFilter] = useAtom(networkFilterAtom);
  const [displayedFilter, setDisplayedFilter] = useAtom(displayedFilterAtom);
  const [isNetworkVisible, setIsNetworkVisible] = useAtom(isNetworkVisibleAtom);
  const [isDisplayedVisible, setIsDisplayedVisible] = useAtom(
    isDisplayedVisibleAtom
  );
  const [selectedRowIDs, setSelectedRowIDs] = useAtom(selectedRowIDsAtom);
  const [hiddenPrefixes, setHiddenPrefixes] = useAtom(hiddenPrefixesAtom);
  const { areAllChecked, onSelectAllChange, onSelectChange } =
    useInvertedToggleGroup({
      data: configuredPrefixes,
      unchecked: hiddenPrefixes,
      setUnchecked: setHiddenPrefixes,
    });

  const {
    isOpen: isImportPrefixesOpen,
    open: openImportPrefixesDialog,
    close: closeImportPrefixesDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [copiedURL, setCopiedURL] = useState(false);

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "networkFilter":
            setNetworkFilter(item[1]);
            break;
          case "displayedFilter":
            setDisplayedFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  const addButton = (
    <ActionButton
      title="Add Prefix Filters"
      customStyles={{ width: "9.2rem" }}
      onClick={openAddConfPrefixDialog}
      enabled={isEditor(role)}
    />
  );

  /** @type {(string | undefined | ReactElement)[][]} */
  let tableData = [];

  if (configuredPrefixes) {
    tableData = configuredPrefixes.map((prefix) => [prefix]);
    tableData = getTableData({
      configuredPrefixes,
      hiddenPrefixes,
      setHiddenPrefixes,
      onSelectChange,
    });
  }

  const onRowsDelete = (rowsDeleted) => {
    const prefixesToRemove = rowsDeleted.data.map(
      ({ dataIndex }) => tableData[dataIndex][0]
    );
    setPrefixesToRemove(prefixesToRemove);
    openRemoveConfPrefixDialog();
    setSelectedRowIDs(new Set());
    return false;
  };

  const { resetPage } = usePagination({ pageAtom, rowsPerPageAtom });
  const [count, setCount] = useAtom(countAtom);

  /**
   * @param {object} params
   * @param {Function} params.applyNewFilters
   * @returns {void}
   */
  const onApplyFiltersButtonClickCallback = ({ applyNewFilters }) => {
    onApplyFiltersButtonClick({
      applyNewFilters,
      setNetworkFilter,
      setDisplayedFilter,
      resetPage,
    });
  };

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      setIsNetworkVisible,
      setIsDisplayedVisible,
    });
  };

  const columns = getColumns({
    tableData,
    areAllChecked,
    onSelectAllChange,
    networkFilter,
    displayedFilter,
    isNetworkVisible,
    isDisplayedVisible,
  });

  const shareUrlData = () => {
    let filters = "";
    if (networkFilter !== "" && typeof networkFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "networkFilter=" + networkFilter + "&";
    }
    if (displayedFilter !== "" && typeof displayedFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "displayedFilter=" + displayedFilter + "&";
    }

    const serviceURL = `https://${window.location.host}${PATHS.dashboard.setup.prefixes}${filters}`;
    navigator.clipboard.writeText(serviceURL);
    setCopiedURL(true);
  };

  const handleClose = () => {
    setCopiedURL(false);
  };

  const HeaderElements = () => (
    <>
      <ShareUrlButton onClick={shareUrlData} toolbarIconOrder={-1} />
      <ImportDataButton onClick={openImportPrefixesDialog} />
    </>
  );

  let importPrefixesDialog;
  let successSnackbar;
  if (isEditor(role)) {
    importPrefixesDialog = (
      <ImportPrefixesFromCSV
        isOpen={isImportPrefixesOpen}
        close={closeImportPrefixesDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        tableData={tableData}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <Table
        title={addButton}
        data={tableData}
        loading={configuredPrefixesFetching}
        error={configuredPrefixesError}
        columns={columns}
        customOptions={{
          selectableRowsHideCheckboxes: !isEditor(role),
          onRowsDelete,
          selectableRows: "multiple",
          responsive: "standard",
          tableBodyMaxHeight: "70vh",
          customToolbar: HeaderElements,
          textLabels: {
            body: {
              noMatch: "No Prefix Filters found.",
            },
          },
        }}
        pageAtom={pageAtom}
        rowsPerPageAtom={rowsPerPageAtom}
        count={count}
        setCount={setCount}
        onApplyFiltersButtonClick={onApplyFiltersButtonClickCallback}
        onViewColumnsChange={onViewColumnsChangeCallback}
        getRowID={getRowID}
        selectedRowIDs={selectedRowIDs}
        setSelectedRowIDs={setSelectedRowIDs}
        exportDataFileName={"PrefixFilters"}
      />
      <Snackbar
        open={copiedURL}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Share link copied to clipboard
        </Alert>
      </Snackbar>
      {importPrefixesDialog}
      {successSnackbar}
    </Stack>
  );
};
