import { DOMAIN_ENUM, GQL_CLIENT_ENUM, ENV_ENUM } from "../constants/env";

export const onEnvTesting = () =>
  process.env.REACT_APP_ENV === ENV_ENUM.testing;

export const onGQLClientMock = () =>
  process.env.REACT_APP_GQL_CLIENT === GQL_CLIENT_ENUM.mock;

export const onGQLClientLocal = () =>
  process.env.REACT_APP_GQL_CLIENT === GQL_CLIENT_ENUM.local;

export const onDomainLocal = () =>
  window.location.hostname === DOMAIN_ENUM.local;

export const onDomainPreview = () =>
  window.location.hostname.includes(DOMAIN_ENUM.preview);

export const onDomainProd = () => !onDomainLocal() && !onDomainPreview();
