import { OnChange, OnAllChange } from "../types/components/checkboxWithLabel";
import { ToggleGroupResult } from "../types/hooks/useToggleGroup";

/**
 * @param {object} params
 * @param {string[]} params.data
 * @param {Set<string>} params.checked
 * @param {Function} params.setChecked
 * @returns {ToggleGroupResult}
 */
export const useToggleGroup = ({ data, checked, setChecked }) => {
  // Take only valid entries into account when reading.
  checked = new Set(
    Array.from(checked).filter((value) => data.includes(value))
  );
  const numberOfChecked = checked.size;
  const areAllChecked = checked.size === data.length;

  /**
   * @param {boolean} checkedValue
   */
  const updateAll = (checkedValue) => {
    const newChecked = new Set();
    if (checkedValue) {
      data.forEach((value) => newChecked.add(value));
    }
    setChecked(newChecked);
  };

  /**
   * @param {string} value
   * @param {boolean} checkedValue
   */
  const updateOne = (value, checkedValue) => {
    const newChecked = new Set(checked);
    if (checkedValue) {
      newChecked.add(value);
    } else {
      newChecked.delete(value);
    }
    setChecked(newChecked);
  };

  /**
   * @type {OnAllChange}
   */
  const onSelectAllChange = (checkedValue) => {
    updateAll(checkedValue);
  };

  /**
   * @type {OnChange}
   */
  const onSelectChange = (checkedValue, value) => {
    updateOne(value, checkedValue);
  };

  return {
    numberOfChecked,
    areAllChecked,
    onSelectAllChange,
    onSelectChange,
  };
};
