import { ReactElement } from "react";
import { OnAllChange } from "../types/components/checkboxWithLabel";
import { Stack, Switch } from "@mui/material";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.checked
 * @param {OnAllChange} props.onClick
 * @returns {ReactElement}
 */
export const TableHeaderWithToggle = ({ title, checked, onClick }) => {
  return (
    <Stack direction="row" alignItems="center">
      {title}
      <Switch
        data-testid="displayed-all-toggle"
        checked={checked}
        onChange={(event) => {
          onClick(event.target.checked);
        }}
      />
    </Stack>
  );
};
