import { ReactElement, MouseEventHandler } from "react";
import { Button, Typography } from "@mui/material";

/**
 * @param {object} props
 * @param {MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {string} props.title
 * @param {boolean} [props.enabled]
 * @param {object} [props.customStyles]
 * @returns {ReactElement}
 */
export const MainButton = ({
  enabled = true,
  onClick,
  title,
  customStyles,
}) => {
  return (
    <Button
      sx={{
        color: "white",
        textTransform: "none",
        height: "2.5rem",
        backgroundColor: "primary.main",
        ":hover": {
          backgroundColor: "blue5.main",
        },
        ":disabled": {
          backgroundColor: "gray1.main",
        },
        ...customStyles,
      }}
      disabled={!enabled}
      onClick={onClick}
    >
      <Typography noWrap fontSize="0.875rem">
        {title}
      </Typography>
    </Button>
  );
};
