import { ReactElement } from "react";
import { useSelectedValue } from "../hooks/useSelectedValue";
import {
  getAnswerTableData,
  getGraphqlAPILink,
  getSubscription,
  getTextFieldFilterTypeColumn,
} from "../other/utils";
import { QueryEntry } from "./QueryEntry";
import { getASNInPathRegex, getPathsIncludingASNs } from "../utils/queries";
import {
  COLUMNS_ENUM,
  PATHS_BETWEEN_ASNS_ENUM,
  SELECT_ASN,
} from "../constants/queries";
import {
  PathsIncludingAsNsDocument,
  usePathsIncludingAsNsSubscription,
} from "../graphql/generated/operations";
import { getAnswerInputJSX } from "../utils/components/pathsBetweenASNsQuery";
import { formatASNPath } from "../utils/formatting";
import { useAtom } from "jotai";
import { Loading, Error } from "../types/dataFetching";
import { getQueryAnswerTableStateAtom } from "../utils/other/store";

/**
 * @param {object} props
 * @param {string[]} props.myASNsToSelect
 * @param {string[]} props.otherASNsToSelect
 * @param {Loading} props.myASNLoading
 * @param {Error} props.myASNError
 * @param {Loading} props.otherASNLoading
 * @param {Error} props.otherASNError
 * @returns {ReactElement}
 */
export const PathsBetweenASNsQuery = ({
  myASNsToSelect,
  otherASNsToSelect,
  myASNLoading,
  myASNError,
  otherASNLoading,
  otherASNError,
}) => {
  const {
    selectedValue: selectedMyASN,
    setSelectedValue: setSelectedMyASN,
    hasSelectedValue: hasSelectedMyASN,
  } = useSelectedValue({ defaultValue: SELECT_ASN });
  const {
    selectedValue: selectedOtherASN,
    setSelectedValue: setSelectedOtherASN,
    hasSelectedValue: hasSelectedOtherASN,
  } = useSelectedValue({ defaultValue: SELECT_ASN });

  const asnRegex1 = getASNInPathRegex(selectedMyASN);
  const asnRegex2 = getASNInPathRegex(selectedOtherASN);
  const hasSelectedValues = hasSelectedMyASN && hasSelectedOtherASN;
  const [{ data: pathsData, fetching: pathsFetching, error: pathsError }] =
    usePathsIncludingAsNsSubscription({
      variables: { asnRegex1, asnRegex2 },
      pause: !hasSelectedValues,
    });
  const { isASPathVisibleAtom } = getQueryAnswerTableStateAtom({
    atomKey: PATHS_BETWEEN_ASNS_ENUM.atomKey,
  });
  const [isASPathVisible] = useAtom(isASPathVisibleAtom);

  const answerInputJSX = getAnswerInputJSX({
    myASNsToSelect,
    selectedMyASN,
    setSelectedMyASN,
    otherASNsToSelect,
    selectedOtherASN,
    setSelectedOtherASN,
    myASNLoading,
    myASNError,
    otherASNLoading,
    otherASNError,
  });
  const answerTableData = getAnswerTableData(
    hasSelectedValues,
    pathsData,
    getPathsIncludingASNs
  );
  const answerTableColumns = [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.AS_PATH.name, {
      display: isASPathVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASNPath(answerTableData, dataIndex, 0),
      filterOptions: {
        logic: (path, filterValue) => !path.match(filterValue),
      },
    }),
  ];

  return (
    <QueryEntry
      questionTitle={PATHS_BETWEEN_ASNS_ENUM.questionTitle}
      answerInput={answerInputJSX}
      loading={pathsFetching}
      error={pathsError}
      hasSelectedValue={hasSelectedValues}
      answerTableData={answerTableData}
      answerTableColumns={answerTableColumns}
      graphqlAPILink={getGraphqlAPILink(
        getSubscription(PathsIncludingAsNsDocument),
        JSON.stringify({ asnRegex1, asnRegex2 })
      )}
      atomKey={PATHS_BETWEEN_ASNS_ENUM.atomKey}
    />
  );
};
