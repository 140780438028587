import { usePrefixesCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useIpv4PrefixesCount";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredPrefixesAtom,
  displayedConfiguredDataServicesAtom,
  filterOnASAnyAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import { ipv4PrefixesCountAtom } from "../store/overviewContent";
import { getConfFilters } from "../utils/components/prefixes";

export const useIPv4PrefixesCount = () => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );

  const [{ data: prefixes, fetching: prefixesFetching, error: prefixesError }] =
    useAtom(displayedConfiguredPrefixesAtom);

  const [
    {
      data: dataServices,
      fetching: dataServicesFetching,
      error: dataServicesError,
    },
  ] = useAtom(displayedConfiguredDataServicesAtom);
  const [filterOnASAny] = useAtom(filterOnASAnyAtom);

  const confState = {
    fetching: asnsFetching || prefixesFetching || dataServicesFetching,
    error: asnsError ?? prefixesError ?? dataServicesError,
  };

  useData({
    operation: usePrefixesCountSubscription,
    options: {
      variables: {
        where: getConfFilters({
          configuredASNs: asns,
          configuredPrefixes: prefixes,
          configuredDataServices: dataServices,
          filterOnASAny,
          ipVersion: 4,
        }),
      },
      pause: !asns,
    },
    formatter,
    confState,
    atom: ipv4PrefixesCountAtom,
  });
};
