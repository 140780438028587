import useSWR from "swr";
import { getCountriesFromJSON } from "../utils/ripestat";

export const useASCountries = (asn) => {
  const { data, error } = useSWR(
    `https://stat.ripe.net/data/rir-stats-country/data.json?resource=AS${asn}`
  );

  let countries;

  if (data) {
    countries = getCountriesFromJSON(data, asn);
  }

  return {
    data: countries,
    loading: !error && !data,
    error,
  };
};
