/* eslint-disable no-unused-vars */
/* eslint-disable jsdoc/require-jsdoc */
// NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
// You can use npm run graphql-codegen to generate this file.
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _cidr: any;
  _text: any;
  bigint: any;
  cidr: any;
  float8: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "conf.view_alert_receiver_type" */
export type AlertReceiverTypes = {
  __typename?: 'alertReceiverTypes';
  /** An array relationship */
  alertSubscriptions: Array<AlertSubscriptions>;
  /** An aggregate relationship */
  alertSubscriptions_aggregate: AlertSubscriptions_Aggregate;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};


/** columns and relationships of "conf.view_alert_receiver_type" */
export type AlertReceiverTypesAlertSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


/** columns and relationships of "conf.view_alert_receiver_type" */
export type AlertReceiverTypesAlertSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "conf.view_alert_receiver_type". All fields are combined with a logical 'AND'. */
export type AlertReceiverTypes_Bool_Exp = {
  _and?: InputMaybe<Array<AlertReceiverTypes_Bool_Exp>>;
  _not?: InputMaybe<AlertReceiverTypes_Bool_Exp>;
  _or?: InputMaybe<Array<AlertReceiverTypes_Bool_Exp>>;
  alertSubscriptions?: InputMaybe<AlertSubscriptions_Bool_Exp>;
  alertSubscriptions_aggregate?: InputMaybe<AlertSubscriptions_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "conf.view_alert_receiver_type". */
export type AlertReceiverTypes_Order_By = {
  alertSubscriptions_aggregate?: InputMaybe<AlertSubscriptions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_alert_receiver_type" */
export enum AlertReceiverTypes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

/** Streaming cursor of the table "alertReceiverTypes" */
export type AlertReceiverTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlertReceiverTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlertReceiverTypes_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "conf.view_alert_severity" */
export type AlertSeverities = {
  __typename?: 'alertSeverities';
  /** An array relationship */
  alertSubscriptions: Array<AlertSubscriptions>;
  /** An aggregate relationship */
  alertSubscriptions_aggregate: AlertSubscriptions_Aggregate;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};


/** columns and relationships of "conf.view_alert_severity" */
export type AlertSeveritiesAlertSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


/** columns and relationships of "conf.view_alert_severity" */
export type AlertSeveritiesAlertSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "conf.view_alert_severity". All fields are combined with a logical 'AND'. */
export type AlertSeverities_Bool_Exp = {
  _and?: InputMaybe<Array<AlertSeverities_Bool_Exp>>;
  _not?: InputMaybe<AlertSeverities_Bool_Exp>;
  _or?: InputMaybe<Array<AlertSeverities_Bool_Exp>>;
  alertSubscriptions?: InputMaybe<AlertSubscriptions_Bool_Exp>;
  alertSubscriptions_aggregate?: InputMaybe<AlertSubscriptions_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "conf.view_alert_severity". */
export type AlertSeverities_Order_By = {
  alertSubscriptions_aggregate?: InputMaybe<AlertSubscriptions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_alert_severity" */
export enum AlertSeverities_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

/** Streaming cursor of the table "alertSeverities" */
export type AlertSeverities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlertSeverities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlertSeverities_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "conf.view_alert_subscription" */
export type AlertSubscriptions = {
  __typename?: 'alertSubscriptions';
  /** activation time of custom alert subscription */
  activation_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  alertReceiverType?: Maybe<AlertReceiverTypes>;
  /** An object relationship */
  alertSeverity?: Maybe<AlertSeverities>;
  /** An object relationship */
  alertType?: Maybe<AlertTypes>;
  description?: Maybe<Scalars['String']>;
  fire_alert_regex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver_endpoint?: Maybe<Scalars['String']>;
  receiver_type?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vars?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "conf.view_alert_subscription" */
export type AlertSubscriptionsVarsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "conf.view_alert_subscription" */
export type AlertSubscriptions_Aggregate = {
  __typename?: 'alertSubscriptions_aggregate';
  aggregate?: Maybe<AlertSubscriptions_Aggregate_Fields>;
  nodes: Array<AlertSubscriptions>;
};

export type AlertSubscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<AlertSubscriptions_Aggregate_Bool_Exp_Count>;
};

export type AlertSubscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AlertSubscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "conf.view_alert_subscription" */
export type AlertSubscriptions_Aggregate_Fields = {
  __typename?: 'alertSubscriptions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AlertSubscriptions_Max_Fields>;
  min?: Maybe<AlertSubscriptions_Min_Fields>;
};


/** aggregate fields of "conf.view_alert_subscription" */
export type AlertSubscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "conf.view_alert_subscription" */
export type AlertSubscriptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AlertSubscriptions_Max_Order_By>;
  min?: InputMaybe<AlertSubscriptions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AlertSubscriptions_Append_Input = {
  vars?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "conf.view_alert_subscription". All fields are combined with a logical 'AND'. */
export type AlertSubscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<AlertSubscriptions_Bool_Exp>>;
  _not?: InputMaybe<AlertSubscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<AlertSubscriptions_Bool_Exp>>;
  activation_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  alertReceiverType?: InputMaybe<AlertReceiverTypes_Bool_Exp>;
  alertSeverity?: InputMaybe<AlertSeverities_Bool_Exp>;
  alertType?: InputMaybe<AlertTypes_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  fire_alert_regex?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  query?: InputMaybe<String_Comparison_Exp>;
  receiver_endpoint?: InputMaybe<String_Comparison_Exp>;
  receiver_type?: InputMaybe<String_Comparison_Exp>;
  severity?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  vars?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AlertSubscriptions_Delete_At_Path_Input = {
  vars?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AlertSubscriptions_Delete_Elem_Input = {
  vars?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AlertSubscriptions_Delete_Key_Input = {
  vars?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "conf.view_alert_subscription" */
export type AlertSubscriptions_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  fire_alert_regex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  receiver_endpoint?: InputMaybe<Scalars['String']>;
  receiver_type?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vars?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type AlertSubscriptions_Max_Fields = {
  __typename?: 'alertSubscriptions_max_fields';
  /** activation time of custom alert subscription */
  activation_time?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fire_alert_regex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver_endpoint?: Maybe<Scalars['String']>;
  receiver_type?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "conf.view_alert_subscription" */
export type AlertSubscriptions_Max_Order_By = {
  /** activation time of custom alert subscription */
  activation_time?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fire_alert_regex?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  receiver_endpoint?: InputMaybe<Order_By>;
  receiver_type?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AlertSubscriptions_Min_Fields = {
  __typename?: 'alertSubscriptions_min_fields';
  /** activation time of custom alert subscription */
  activation_time?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fire_alert_regex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver_endpoint?: Maybe<Scalars['String']>;
  receiver_type?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "conf.view_alert_subscription" */
export type AlertSubscriptions_Min_Order_By = {
  /** activation time of custom alert subscription */
  activation_time?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fire_alert_regex?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  receiver_endpoint?: InputMaybe<Order_By>;
  receiver_type?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "conf.view_alert_subscription" */
export type AlertSubscriptions_Mutation_Response = {
  __typename?: 'alertSubscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AlertSubscriptions>;
};

/** Ordering options when selecting data from "conf.view_alert_subscription". */
export type AlertSubscriptions_Order_By = {
  activation_time?: InputMaybe<Order_By>;
  alertReceiverType?: InputMaybe<AlertReceiverTypes_Order_By>;
  alertSeverity?: InputMaybe<AlertSeverities_Order_By>;
  alertType?: InputMaybe<AlertTypes_Order_By>;
  description?: InputMaybe<Order_By>;
  fire_alert_regex?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  receiver_endpoint?: InputMaybe<Order_By>;
  receiver_type?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  vars?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AlertSubscriptions_Prepend_Input = {
  vars?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "conf.view_alert_subscription" */
export enum AlertSubscriptions_Select_Column {
  /** column name */
  ActivationTime = 'activation_time',
  /** column name */
  Description = 'description',
  /** column name */
  FireAlertRegex = 'fire_alert_regex',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Query = 'query',
  /** column name */
  ReceiverEndpoint = 'receiver_endpoint',
  /** column name */
  ReceiverType = 'receiver_type',
  /** column name */
  Severity = 'severity',
  /** column name */
  Type = 'type',
  /** column name */
  Vars = 'vars'
}

/** input type for updating data in table "conf.view_alert_subscription" */
export type AlertSubscriptions_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  receiver_endpoint?: InputMaybe<Scalars['String']>;
  receiver_type?: InputMaybe<Scalars['String']>;
  vars?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "alertSubscriptions" */
export type AlertSubscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlertSubscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlertSubscriptions_Stream_Cursor_Value_Input = {
  /** activation time of custom alert subscription */
  activation_time?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  fire_alert_regex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  receiver_endpoint?: InputMaybe<Scalars['String']>;
  receiver_type?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vars?: InputMaybe<Scalars['jsonb']>;
};

export type AlertSubscriptions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AlertSubscriptions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AlertSubscriptions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AlertSubscriptions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AlertSubscriptions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AlertSubscriptions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AlertSubscriptions_Set_Input>;
  where: AlertSubscriptions_Bool_Exp;
};

/** columns and relationships of "conf.view_alert_type" */
export type AlertTypes = {
  __typename?: 'alertTypes';
  /** An array relationship */
  alertSubscriptions: Array<AlertSubscriptions>;
  /** An aggregate relationship */
  alertSubscriptions_aggregate: AlertSubscriptions_Aggregate;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};


/** columns and relationships of "conf.view_alert_type" */
export type AlertTypesAlertSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


/** columns and relationships of "conf.view_alert_type" */
export type AlertTypesAlertSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "conf.view_alert_type". All fields are combined with a logical 'AND'. */
export type AlertTypes_Bool_Exp = {
  _and?: InputMaybe<Array<AlertTypes_Bool_Exp>>;
  _not?: InputMaybe<AlertTypes_Bool_Exp>;
  _or?: InputMaybe<Array<AlertTypes_Bool_Exp>>;
  alertSubscriptions?: InputMaybe<AlertSubscriptions_Bool_Exp>;
  alertSubscriptions_aggregate?: InputMaybe<AlertSubscriptions_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "conf.view_alert_type". */
export type AlertTypes_Order_By = {
  alertSubscriptions_aggregate?: InputMaybe<AlertSubscriptions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_alert_type" */
export enum AlertTypes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

/** Streaming cursor of the table "alertTypes" */
export type AlertTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlertTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlertTypes_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "main.view_alert" */
export type Alerts = {
  __typename?: 'alerts';
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  fire_alert_regex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver_endpoint?: Maybe<Scalars['String']>;
  receiver_type?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vars?: Maybe<Scalars['String']>;
};

/** aggregated selection of "main.view_alert" */
export type Alerts_Aggregate = {
  __typename?: 'alerts_aggregate';
  aggregate?: Maybe<Alerts_Aggregate_Fields>;
  nodes: Array<Alerts>;
};

/** aggregate fields of "main.view_alert" */
export type Alerts_Aggregate_Fields = {
  __typename?: 'alerts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Alerts_Max_Fields>;
  min?: Maybe<Alerts_Min_Fields>;
};


/** aggregate fields of "main.view_alert" */
export type Alerts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alerts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "main.view_alert". All fields are combined with a logical 'AND'. */
export type Alerts_Bool_Exp = {
  _and?: InputMaybe<Array<Alerts_Bool_Exp>>;
  _not?: InputMaybe<Alerts_Bool_Exp>;
  _or?: InputMaybe<Array<Alerts_Bool_Exp>>;
  data?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fire_alert_regex?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  query?: InputMaybe<String_Comparison_Exp>;
  receiver_endpoint?: InputMaybe<String_Comparison_Exp>;
  receiver_type?: InputMaybe<String_Comparison_Exp>;
  severity?: InputMaybe<String_Comparison_Exp>;
  starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  vars?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Alerts_Max_Fields = {
  __typename?: 'alerts_max_fields';
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  fire_alert_regex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver_endpoint?: Maybe<Scalars['String']>;
  receiver_type?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vars?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Alerts_Min_Fields = {
  __typename?: 'alerts_min_fields';
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  fire_alert_regex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  receiver_endpoint?: Maybe<Scalars['String']>;
  receiver_type?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vars?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "main.view_alert" */
export type Alerts_Mutation_Response = {
  __typename?: 'alerts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alerts>;
};

/** Ordering options when selecting data from "main.view_alert". */
export type Alerts_Order_By = {
  data?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ends_at?: InputMaybe<Order_By>;
  fire_alert_regex?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  receiver_endpoint?: InputMaybe<Order_By>;
  receiver_type?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  starts_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  vars?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_alert" */
export enum Alerts_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FireAlertRegex = 'fire_alert_regex',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Query = 'query',
  /** column name */
  ReceiverEndpoint = 'receiver_endpoint',
  /** column name */
  ReceiverType = 'receiver_type',
  /** column name */
  Severity = 'severity',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  Vars = 'vars'
}

/** Streaming cursor of the table "alerts" */
export type Alerts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alerts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alerts_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ends_at?: InputMaybe<Scalars['timestamptz']>;
  fire_alert_regex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  receiver_endpoint?: InputMaybe<Scalars['String']>;
  receiver_type?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Scalars['String']>;
  starts_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vars?: InputMaybe<Scalars['String']>;
};

export type As_Paths_Diff_Prefixes_Args = {
  base_service?: InputMaybe<Scalars['String']>;
  comp_service?: InputMaybe<Scalars['String']>;
  end_asn?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations = {
  __typename?: 'autonomousSystemDataSourceAssociations';
  /** An object relationship */
  autonomousSystem?: Maybe<AutonomousSystems>;
  /** An object relationship */
  dataSource?: Maybe<DataSources>;
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Aggregate = {
  __typename?: 'autonomousSystemDataSourceAssociations_aggregate';
  aggregate?: Maybe<AutonomousSystemDataSourceAssociations_Aggregate_Fields>;
  nodes: Array<AutonomousSystemDataSourceAssociations>;
};

export type AutonomousSystemDataSourceAssociations_Aggregate_Bool_Exp = {
  count?: InputMaybe<AutonomousSystemDataSourceAssociations_Aggregate_Bool_Exp_Count>;
};

export type AutonomousSystemDataSourceAssociations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Aggregate_Fields = {
  __typename?: 'autonomousSystemDataSourceAssociations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AutonomousSystemDataSourceAssociations_Max_Fields>;
  min?: Maybe<AutonomousSystemDataSourceAssociations_Min_Fields>;
};


/** aggregate fields of "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AutonomousSystemDataSourceAssociations_Max_Order_By>;
  min?: InputMaybe<AutonomousSystemDataSourceAssociations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Arr_Rel_Insert_Input = {
  data: Array<AutonomousSystemDataSourceAssociations_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_autonomous_system_data_source_assoc". All fields are combined with a logical 'AND'. */
export type AutonomousSystemDataSourceAssociations_Bool_Exp = {
  _and?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Bool_Exp>>;
  _not?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
  _or?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Bool_Exp>>;
  autonomousSystem?: InputMaybe<AutonomousSystems_Bool_Exp>;
  dataSource?: InputMaybe<DataSources_Bool_Exp>;
  data_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Insert_Input = {
  autonomousSystem?: InputMaybe<AutonomousSystems_Obj_Rel_Insert_Input>;
  autonomous_system_id?: InputMaybe<Scalars['uuid']>;
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AutonomousSystemDataSourceAssociations_Max_Fields = {
  __typename?: 'autonomousSystemDataSourceAssociations_max_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Max_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AutonomousSystemDataSourceAssociations_Min_Fields = {
  __typename?: 'autonomousSystemDataSourceAssociations_min_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Min_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Mutation_Response = {
  __typename?: 'autonomousSystemDataSourceAssociations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AutonomousSystemDataSourceAssociations>;
};

/** Ordering options when selecting data from "main.view_new_autonomous_system_data_source_assoc". */
export type AutonomousSystemDataSourceAssociations_Order_By = {
  autonomousSystem?: InputMaybe<AutonomousSystems_Order_By>;
  dataSource?: InputMaybe<DataSources_Order_By>;
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_new_autonomous_system_data_source_assoc" */
export enum AutonomousSystemDataSourceAssociations_Select_Column {
  /** column name */
  DataTime = 'data_time',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "main.view_new_autonomous_system_data_source_assoc" */
export type AutonomousSystemDataSourceAssociations_Set_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "autonomousSystemDataSourceAssociations" */
export type AutonomousSystemDataSourceAssociations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AutonomousSystemDataSourceAssociations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AutonomousSystemDataSourceAssociations_Stream_Cursor_Value_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type AutonomousSystemDataSourceAssociations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AutonomousSystemDataSourceAssociations_Set_Input>;
  where: AutonomousSystemDataSourceAssociations_Bool_Exp;
};

/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystems = {
  __typename?: 'autonomousSystems';
  /** An array relationship */
  autonomousSystemDataSourceAssociations: Array<AutonomousSystemDataSourceAssociations>;
  /** An aggregate relationship */
  autonomousSystemDataSourceAssociations_aggregate: AutonomousSystemDataSourceAssociations_Aggregate;
  /** total number of AS-data source associations and by extension data sources */
  data_source_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['bigint']>;
  /** AS-originated prefixes of base_service differ from prefixes of comp_service */
  orig_prefixes_diff?: Maybe<Scalars['String']>;
  /** An array relationship */
  peeringLeftAutonomousSystems: Array<Peerings>;
  /** An aggregate relationship */
  peeringLeftAutonomousSystems_aggregate: Peerings_Aggregate;
  /** An array relationship */
  peeringRightAutonomousSystems: Array<Peerings>;
  /** An aggregate relationship */
  peeringRightAutonomousSystems_aggregate: Peerings_Aggregate;
  /** An array relationship */
  routeNeighborAutonomousSystems: Array<Routes>;
  /** An aggregate relationship */
  routeNeighborAutonomousSystems_aggregate: Routes_Aggregate;
  /** An array relationship */
  routeOriginAutonomousSystems: Array<Routes>;
  /** An aggregate relationship */
  routeOriginAutonomousSystems_aggregate: Routes_Aggregate;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsAutonomousSystemDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsAutonomousSystemDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsOrig_Prefixes_DiffArgs = {
  args: Orig_Prefixes_Diff_AutonomousSystems_Args;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsPeeringLeftAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsPeeringLeftAutonomousSystems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsPeeringRightAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsPeeringRightAutonomousSystems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsRouteNeighborAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsRouteNeighborAutonomousSystems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsRouteOriginAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


/** columns and relationships of "main.view_new_autonomous_system" */
export type AutonomousSystemsRouteOriginAutonomousSystems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};

/** aggregated selection of "main.view_new_autonomous_system" */
export type AutonomousSystems_Aggregate = {
  __typename?: 'autonomousSystems_aggregate';
  aggregate?: Maybe<AutonomousSystems_Aggregate_Fields>;
  nodes: Array<AutonomousSystems>;
};

/** aggregate fields of "main.view_new_autonomous_system" */
export type AutonomousSystems_Aggregate_Fields = {
  __typename?: 'autonomousSystems_aggregate_fields';
  avg?: Maybe<AutonomousSystems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AutonomousSystems_Max_Fields>;
  min?: Maybe<AutonomousSystems_Min_Fields>;
  stddev?: Maybe<AutonomousSystems_Stddev_Fields>;
  stddev_pop?: Maybe<AutonomousSystems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AutonomousSystems_Stddev_Samp_Fields>;
  sum?: Maybe<AutonomousSystems_Sum_Fields>;
  var_pop?: Maybe<AutonomousSystems_Var_Pop_Fields>;
  var_samp?: Maybe<AutonomousSystems_Var_Samp_Fields>;
  variance?: Maybe<AutonomousSystems_Variance_Fields>;
};


/** aggregate fields of "main.view_new_autonomous_system" */
export type AutonomousSystems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AutonomousSystems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AutonomousSystems_Avg_Fields = {
  __typename?: 'autonomousSystems_avg_fields';
  number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "main.view_new_autonomous_system". All fields are combined with a logical 'AND'. */
export type AutonomousSystems_Bool_Exp = {
  _and?: InputMaybe<Array<AutonomousSystems_Bool_Exp>>;
  _not?: InputMaybe<AutonomousSystems_Bool_Exp>;
  _or?: InputMaybe<Array<AutonomousSystems_Bool_Exp>>;
  autonomousSystemDataSourceAssociations?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
  autonomousSystemDataSourceAssociations_aggregate?: InputMaybe<AutonomousSystemDataSourceAssociations_Aggregate_Bool_Exp>;
  data_source_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  number?: InputMaybe<Bigint_Comparison_Exp>;
  peeringLeftAutonomousSystems?: InputMaybe<Peerings_Bool_Exp>;
  peeringLeftAutonomousSystems_aggregate?: InputMaybe<Peerings_Aggregate_Bool_Exp>;
  peeringRightAutonomousSystems?: InputMaybe<Peerings_Bool_Exp>;
  peeringRightAutonomousSystems_aggregate?: InputMaybe<Peerings_Aggregate_Bool_Exp>;
  routeNeighborAutonomousSystems?: InputMaybe<Routes_Bool_Exp>;
  routeNeighborAutonomousSystems_aggregate?: InputMaybe<Routes_Aggregate_Bool_Exp>;
  routeOriginAutonomousSystems?: InputMaybe<Routes_Bool_Exp>;
  routeOriginAutonomousSystems_aggregate?: InputMaybe<Routes_Aggregate_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_autonomous_system" */
export type AutonomousSystems_Insert_Input = {
  autonomousSystemDataSourceAssociations?: InputMaybe<AutonomousSystemDataSourceAssociations_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['bigint']>;
  peeringLeftAutonomousSystems?: InputMaybe<Peerings_Arr_Rel_Insert_Input>;
  peeringRightAutonomousSystems?: InputMaybe<Peerings_Arr_Rel_Insert_Input>;
  routeNeighborAutonomousSystems?: InputMaybe<Routes_Arr_Rel_Insert_Input>;
  routeOriginAutonomousSystems?: InputMaybe<Routes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AutonomousSystems_Max_Fields = {
  __typename?: 'autonomousSystems_max_fields';
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type AutonomousSystems_Min_Fields = {
  __typename?: 'autonomousSystems_min_fields';
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "main.view_new_autonomous_system" */
export type AutonomousSystems_Mutation_Response = {
  __typename?: 'autonomousSystems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AutonomousSystems>;
};

/** input type for inserting object relation for remote table "main.view_new_autonomous_system" */
export type AutonomousSystems_Obj_Rel_Insert_Input = {
  data: AutonomousSystems_Insert_Input;
};

/** Ordering options when selecting data from "main.view_new_autonomous_system". */
export type AutonomousSystems_Order_By = {
  autonomousSystemDataSourceAssociations_aggregate?: InputMaybe<AutonomousSystemDataSourceAssociations_Aggregate_Order_By>;
  data_source_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  peeringLeftAutonomousSystems_aggregate?: InputMaybe<Peerings_Aggregate_Order_By>;
  peeringRightAutonomousSystems_aggregate?: InputMaybe<Peerings_Aggregate_Order_By>;
  routeNeighborAutonomousSystems_aggregate?: InputMaybe<Routes_Aggregate_Order_By>;
  routeOriginAutonomousSystems_aggregate?: InputMaybe<Routes_Aggregate_Order_By>;
};

/** select columns of table "main.view_new_autonomous_system" */
export enum AutonomousSystems_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number'
}

/** aggregate stddev on columns */
export type AutonomousSystems_Stddev_Fields = {
  __typename?: 'autonomousSystems_stddev_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AutonomousSystems_Stddev_Pop_Fields = {
  __typename?: 'autonomousSystems_stddev_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AutonomousSystems_Stddev_Samp_Fields = {
  __typename?: 'autonomousSystems_stddev_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "autonomousSystems" */
export type AutonomousSystems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AutonomousSystems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AutonomousSystems_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type AutonomousSystems_Sum_Fields = {
  __typename?: 'autonomousSystems_sum_fields';
  number?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type AutonomousSystems_Var_Pop_Fields = {
  __typename?: 'autonomousSystems_var_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AutonomousSystems_Var_Samp_Fields = {
  __typename?: 'autonomousSystems_var_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AutonomousSystems_Variance_Fields = {
  __typename?: 'autonomousSystems_variance_fields';
  number?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "main.view_avg_bgp_update_rate" */
export type AvgBgpUpdateRate = {
  __typename?: 'avgBGPUpdateRate';
  id?: Maybe<Scalars['uuid']>;
  labels?: Maybe<Scalars['jsonb']>;
  time_updated?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "main.view_avg_bgp_update_rate" */
export type AvgBgpUpdateRateLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "main.view_avg_bgp_update_rate". All fields are combined with a logical 'AND'. */
export type AvgBgpUpdateRate_Bool_Exp = {
  _and?: InputMaybe<Array<AvgBgpUpdateRate_Bool_Exp>>;
  _not?: InputMaybe<AvgBgpUpdateRate_Bool_Exp>;
  _or?: InputMaybe<Array<AvgBgpUpdateRate_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  time_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "main.view_avg_bgp_update_rate". */
export type AvgBgpUpdateRate_Order_By = {
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  time_updated?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_avg_bgp_update_rate" */
export enum AvgBgpUpdateRate_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  TimeUpdated = 'time_updated',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "avgBGPUpdateRate" */
export type AvgBgpUpdateRate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AvgBgpUpdateRate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AvgBgpUpdateRate_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  time_updated?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** columns and relationships of "main.view_bgp_update_rate" */
export type BgpUpdateRate = {
  __typename?: 'bgpUpdateRate';
  id?: Maybe<Scalars['uuid']>;
  labels?: Maybe<Scalars['jsonb']>;
  time_updated?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "main.view_bgp_update_rate" */
export type BgpUpdateRateLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "main.view_bgp_update_rate". All fields are combined with a logical 'AND'. */
export type BgpUpdateRate_Bool_Exp = {
  _and?: InputMaybe<Array<BgpUpdateRate_Bool_Exp>>;
  _not?: InputMaybe<BgpUpdateRate_Bool_Exp>;
  _or?: InputMaybe<Array<BgpUpdateRate_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  time_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "main.view_bgp_update_rate". */
export type BgpUpdateRate_Order_By = {
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  time_updated?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_bgp_update_rate" */
export enum BgpUpdateRate_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  TimeUpdated = 'time_updated',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "bgpUpdateRate" */
export type BgpUpdateRate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BgpUpdateRate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BgpUpdateRate_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  time_updated?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "cidr". All fields are combined with logical 'AND'. */
export type Cidr_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['cidr']>;
  _gt?: InputMaybe<Scalars['cidr']>;
  _gte?: InputMaybe<Scalars['cidr']>;
  _in?: InputMaybe<Array<Scalars['cidr']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['cidr']>;
  _lte?: InputMaybe<Scalars['cidr']>;
  _neq?: InputMaybe<Scalars['cidr']>;
  _nin?: InputMaybe<Array<Scalars['cidr']>>;
};

/** columns and relationships of "conf.view_new_autonomous_system" */
export type ConfiguredAutonomousSystems = {
  __typename?: 'configuredAutonomousSystems';
  data_service?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_autonomous_system". All fields are combined with a logical 'AND'. */
export type ConfiguredAutonomousSystems_Bool_Exp = {
  _and?: InputMaybe<Array<ConfiguredAutonomousSystems_Bool_Exp>>;
  _not?: InputMaybe<ConfiguredAutonomousSystems_Bool_Exp>;
  _or?: InputMaybe<Array<ConfiguredAutonomousSystems_Bool_Exp>>;
  data_service?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "conf.view_new_autonomous_system". */
export type ConfiguredAutonomousSystems_Order_By = {
  data_service?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_new_autonomous_system" */
export enum ConfiguredAutonomousSystems_Select_Column {
  /** column name */
  DataService = 'data_service',
  /** column name */
  Number = 'number'
}

/** Streaming cursor of the table "configuredAutonomousSystems" */
export type ConfiguredAutonomousSystems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConfiguredAutonomousSystems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfiguredAutonomousSystems_Stream_Cursor_Value_Input = {
  data_service?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "conf.view_new_data_adapter" */
export type ConfiguredDataServices = {
  __typename?: 'configuredDataServices';
  configuration?: Maybe<Scalars['jsonb']>;
  data_service?: Maybe<Scalars['String']>;
  data_source_selector_fields?: Maybe<Scalars['jsonb']>;
  enabled?: Maybe<Scalars['Boolean']>;
  filter_on_autonomous_system?: Maybe<Scalars['String']>;
  filter_on_data_source?: Maybe<Scalars['String']>;
  filter_on_prefix?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "conf.view_new_data_adapter" */
export type ConfiguredDataServicesConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "conf.view_new_data_adapter" */
export type ConfiguredDataServicesData_Source_Selector_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ConfiguredDataServices_Append_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_data_adapter". All fields are combined with a logical 'AND'. */
export type ConfiguredDataServices_Bool_Exp = {
  _and?: InputMaybe<Array<ConfiguredDataServices_Bool_Exp>>;
  _not?: InputMaybe<ConfiguredDataServices_Bool_Exp>;
  _or?: InputMaybe<Array<ConfiguredDataServices_Bool_Exp>>;
  configuration?: InputMaybe<Jsonb_Comparison_Exp>;
  data_service?: InputMaybe<String_Comparison_Exp>;
  data_source_selector_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  filter_on_autonomous_system?: InputMaybe<String_Comparison_Exp>;
  filter_on_data_source?: InputMaybe<String_Comparison_Exp>;
  filter_on_prefix?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ConfiguredDataServices_Delete_At_Path_Input = {
  configuration?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ConfiguredDataServices_Delete_Elem_Input = {
  configuration?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ConfiguredDataServices_Delete_Key_Input = {
  configuration?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "conf.view_new_data_adapter" */
export type ConfiguredDataServices_Insert_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
  data_service?: InputMaybe<Scalars['String']>;
  data_source_selector_fields?: InputMaybe<Scalars['jsonb']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filter_on_autonomous_system?: InputMaybe<Scalars['String']>;
  filter_on_data_source?: InputMaybe<Scalars['String']>;
  filter_on_prefix?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** response of any mutation on the table "conf.view_new_data_adapter" */
export type ConfiguredDataServices_Mutation_Response = {
  __typename?: 'configuredDataServices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConfiguredDataServices>;
};

/** Ordering options when selecting data from "conf.view_new_data_adapter". */
export type ConfiguredDataServices_Order_By = {
  configuration?: InputMaybe<Order_By>;
  data_service?: InputMaybe<Order_By>;
  data_source_selector_fields?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  filter_on_autonomous_system?: InputMaybe<Order_By>;
  filter_on_data_source?: InputMaybe<Order_By>;
  filter_on_prefix?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ConfiguredDataServices_Prepend_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "conf.view_new_data_adapter" */
export enum ConfiguredDataServices_Select_Column {
  /** column name */
  Configuration = 'configuration',
  /** column name */
  DataService = 'data_service',
  /** column name */
  DataSourceSelectorFields = 'data_source_selector_fields',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FilterOnAutonomousSystem = 'filter_on_autonomous_system',
  /** column name */
  FilterOnDataSource = 'filter_on_data_source',
  /** column name */
  FilterOnPrefix = 'filter_on_prefix',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "conf.view_new_data_adapter" */
export type ConfiguredDataServices_Set_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filter_on_autonomous_system?: InputMaybe<Scalars['String']>;
  filter_on_data_source?: InputMaybe<Scalars['String']>;
  filter_on_prefix?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "configuredDataServices" */
export type ConfiguredDataServices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConfiguredDataServices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfiguredDataServices_Stream_Cursor_Value_Input = {
  configuration?: InputMaybe<Scalars['jsonb']>;
  data_service?: InputMaybe<Scalars['String']>;
  data_source_selector_fields?: InputMaybe<Scalars['jsonb']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filter_on_autonomous_system?: InputMaybe<Scalars['String']>;
  filter_on_data_source?: InputMaybe<Scalars['String']>;
  filter_on_prefix?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type ConfiguredDataServices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ConfiguredDataServices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ConfiguredDataServices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ConfiguredDataServices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ConfiguredDataServices_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ConfiguredDataServices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConfiguredDataServices_Set_Input>;
  where: ConfiguredDataServices_Bool_Exp;
};

/** columns and relationships of "conf.view_new_data_source" */
export type ConfiguredDataSources = {
  __typename?: 'configuredDataSources';
  data_service?: Maybe<Scalars['String']>;
  selector?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "conf.view_new_data_source" */
export type ConfiguredDataSourcesSelectorArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_data_source". All fields are combined with a logical 'AND'. */
export type ConfiguredDataSources_Bool_Exp = {
  _and?: InputMaybe<Array<ConfiguredDataSources_Bool_Exp>>;
  _not?: InputMaybe<ConfiguredDataSources_Bool_Exp>;
  _or?: InputMaybe<Array<ConfiguredDataSources_Bool_Exp>>;
  data_service?: InputMaybe<String_Comparison_Exp>;
  selector?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** Ordering options when selecting data from "conf.view_new_data_source". */
export type ConfiguredDataSources_Order_By = {
  data_service?: InputMaybe<Order_By>;
  selector?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_new_data_source" */
export enum ConfiguredDataSources_Select_Column {
  /** column name */
  DataService = 'data_service',
  /** column name */
  Selector = 'selector'
}

/** Streaming cursor of the table "configuredDataSources" */
export type ConfiguredDataSources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConfiguredDataSources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfiguredDataSources_Stream_Cursor_Value_Input = {
  data_service?: InputMaybe<Scalars['String']>;
  selector?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "conf.view_new_prefix" */
export type ConfiguredPrefixes = {
  __typename?: 'configuredPrefixes';
  data_service?: Maybe<Scalars['String']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['cidr']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_prefix". All fields are combined with a logical 'AND'. */
export type ConfiguredPrefixes_Bool_Exp = {
  _and?: InputMaybe<Array<ConfiguredPrefixes_Bool_Exp>>;
  _not?: InputMaybe<ConfiguredPrefixes_Bool_Exp>;
  _or?: InputMaybe<Array<ConfiguredPrefixes_Bool_Exp>>;
  data_service?: InputMaybe<String_Comparison_Exp>;
  ip_version?: InputMaybe<Int_Comparison_Exp>;
  mask_length?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Cidr_Comparison_Exp>;
};

/** Ordering options when selecting data from "conf.view_new_prefix". */
export type ConfiguredPrefixes_Order_By = {
  data_service?: InputMaybe<Order_By>;
  ip_version?: InputMaybe<Order_By>;
  mask_length?: InputMaybe<Order_By>;
  network?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_new_prefix" */
export enum ConfiguredPrefixes_Select_Column {
  /** column name */
  DataService = 'data_service',
  /** column name */
  IpVersion = 'ip_version',
  /** column name */
  MaskLength = 'mask_length',
  /** column name */
  Network = 'network'
}

/** Streaming cursor of the table "configuredPrefixes" */
export type ConfiguredPrefixes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConfiguredPrefixes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfiguredPrefixes_Stream_Cursor_Value_Input = {
  data_service?: InputMaybe<Scalars['String']>;
  ip_version?: InputMaybe<Scalars['Int']>;
  mask_length?: InputMaybe<Scalars['Int']>;
  network?: InputMaybe<Scalars['cidr']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "main.view_data_source_metadata" */
export type DataSourceMetadatas = {
  __typename?: 'dataSourceMetadatas';
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** An object relationship */
  dataSource?: Maybe<DataSources>;
  data_source_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "main.view_data_source_metadata" */
export type DataSourceMetadatas_Aggregate = {
  __typename?: 'dataSourceMetadatas_aggregate';
  aggregate?: Maybe<DataSourceMetadatas_Aggregate_Fields>;
  nodes: Array<DataSourceMetadatas>;
};

export type DataSourceMetadatas_Aggregate_Bool_Exp = {
  count?: InputMaybe<DataSourceMetadatas_Aggregate_Bool_Exp_Count>;
};

export type DataSourceMetadatas_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_data_source_metadata" */
export type DataSourceMetadatas_Aggregate_Fields = {
  __typename?: 'dataSourceMetadatas_aggregate_fields';
  avg?: Maybe<DataSourceMetadatas_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DataSourceMetadatas_Max_Fields>;
  min?: Maybe<DataSourceMetadatas_Min_Fields>;
  stddev?: Maybe<DataSourceMetadatas_Stddev_Fields>;
  stddev_pop?: Maybe<DataSourceMetadatas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DataSourceMetadatas_Stddev_Samp_Fields>;
  sum?: Maybe<DataSourceMetadatas_Sum_Fields>;
  var_pop?: Maybe<DataSourceMetadatas_Var_Pop_Fields>;
  var_samp?: Maybe<DataSourceMetadatas_Var_Samp_Fields>;
  variance?: Maybe<DataSourceMetadatas_Variance_Fields>;
};


/** aggregate fields of "main.view_data_source_metadata" */
export type DataSourceMetadatas_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Aggregate_Order_By = {
  avg?: InputMaybe<DataSourceMetadatas_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DataSourceMetadatas_Max_Order_By>;
  min?: InputMaybe<DataSourceMetadatas_Min_Order_By>;
  stddev?: InputMaybe<DataSourceMetadatas_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DataSourceMetadatas_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DataSourceMetadatas_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DataSourceMetadatas_Sum_Order_By>;
  var_pop?: InputMaybe<DataSourceMetadatas_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DataSourceMetadatas_Var_Samp_Order_By>;
  variance?: InputMaybe<DataSourceMetadatas_Variance_Order_By>;
};

/** aggregate avg on columns */
export type DataSourceMetadatas_Avg_Fields = {
  __typename?: 'dataSourceMetadatas_avg_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Avg_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "main.view_data_source_metadata". All fields are combined with a logical 'AND'. */
export type DataSourceMetadatas_Bool_Exp = {
  _and?: InputMaybe<Array<DataSourceMetadatas_Bool_Exp>>;
  _not?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
  _or?: InputMaybe<Array<DataSourceMetadatas_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  continent?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  dataSource?: InputMaybe<DataSources_Bool_Exp>;
  data_source_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lat?: InputMaybe<Float_Comparison_Exp>;
  lon?: InputMaybe<Float_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Inc_Input = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  continent?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  data_source_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type DataSourceMetadatas_Max_Fields = {
  __typename?: 'dataSourceMetadatas_max_fields';
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  data_source_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  continent?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  data_source_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DataSourceMetadatas_Min_Fields = {
  __typename?: 'dataSourceMetadatas_min_fields';
  city?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  data_source_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  continent?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  data_source_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Mutation_Response = {
  __typename?: 'dataSourceMetadatas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DataSourceMetadatas>;
};

/** Ordering options when selecting data from "main.view_data_source_metadata". */
export type DataSourceMetadatas_Order_By = {
  city?: InputMaybe<Order_By>;
  continent?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  dataSource?: InputMaybe<DataSources_Order_By>;
  data_source_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_data_source_metadata" */
export enum DataSourceMetadatas_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Continent = 'continent',
  /** column name */
  Country = 'country',
  /** column name */
  DataSourceId = 'data_source_id',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lon = 'lon'
}

/** input type for updating data in table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  continent?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type DataSourceMetadatas_Stddev_Fields = {
  __typename?: 'dataSourceMetadatas_stddev_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Stddev_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DataSourceMetadatas_Stddev_Pop_Fields = {
  __typename?: 'dataSourceMetadatas_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Stddev_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DataSourceMetadatas_Stddev_Samp_Fields = {
  __typename?: 'dataSourceMetadatas_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Stddev_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dataSourceMetadatas" */
export type DataSourceMetadatas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DataSourceMetadatas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DataSourceMetadatas_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  continent?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  data_source_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type DataSourceMetadatas_Sum_Fields = {
  __typename?: 'dataSourceMetadatas_sum_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Sum_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

export type DataSourceMetadatas_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DataSourceMetadatas_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataSourceMetadatas_Set_Input>;
  where: DataSourceMetadatas_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DataSourceMetadatas_Var_Pop_Fields = {
  __typename?: 'dataSourceMetadatas_var_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Var_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DataSourceMetadatas_Var_Samp_Fields = {
  __typename?: 'dataSourceMetadatas_var_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Var_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DataSourceMetadatas_Variance_Fields = {
  __typename?: 'dataSourceMetadatas_variance_fields';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "main.view_data_source_metadata" */
export type DataSourceMetadatas_Variance_Order_By = {
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
};

/** columns and relationships of "main.view_new_data_source" */
export type DataSources = {
  __typename?: 'dataSources';
  /** An array relationship */
  autonomousSystemDataSourceAssociations: Array<AutonomousSystemDataSourceAssociations>;
  /** An aggregate relationship */
  autonomousSystemDataSourceAssociations_aggregate: AutonomousSystemDataSourceAssociations_Aggregate;
  /** An array relationship */
  dataSourceMetadatas: Array<DataSourceMetadatas>;
  /** An aggregate relationship */
  dataSourceMetadatas_aggregate: DataSourceMetadatas_Aggregate;
  data_service?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  /** An array relationship */
  peeringDataSourceAssociations: Array<PeeringDataSourceAssociations>;
  /** An aggregate relationship */
  peeringDataSourceAssociations_aggregate: PeeringDataSourceAssociations_Aggregate;
  /** An array relationship */
  prefixDataSourceAssociations: Array<PrefixDataSourceAssociations>;
  /** An aggregate relationship */
  prefixDataSourceAssociations_aggregate: PrefixDataSourceAssociations_Aggregate;
  /** An array relationship */
  routeDataSourceAssociations: Array<RouteDataSourceAssociations>;
  /** An aggregate relationship */
  routeDataSourceAssociations_aggregate: RouteDataSourceAssociations_Aggregate;
  /** An array relationship */
  rpkiROADataSourceAssociations: Array<RpkiRoaDataSourceAssociations>;
  /** An aggregate relationship */
  rpkiROADataSourceAssociations_aggregate: RpkiRoaDataSourceAssociations_Aggregate;
  selector?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesAutonomousSystemDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesAutonomousSystemDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesDataSourceMetadatasArgs = {
  distinct_on?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSourceMetadatas_Order_By>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesDataSourceMetadatas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSourceMetadatas_Order_By>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesPeeringDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesPeeringDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesPrefixDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesPrefixDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesRouteDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesRouteDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesRpkiRoaDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesRpkiRoaDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_data_source" */
export type DataSourcesSelectorArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "main.view_new_data_source" */
export type DataSources_Aggregate = {
  __typename?: 'dataSources_aggregate';
  aggregate?: Maybe<DataSources_Aggregate_Fields>;
  nodes: Array<DataSources>;
};

/** aggregate fields of "main.view_new_data_source" */
export type DataSources_Aggregate_Fields = {
  __typename?: 'dataSources_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DataSources_Max_Fields>;
  min?: Maybe<DataSources_Min_Fields>;
};


/** aggregate fields of "main.view_new_data_source" */
export type DataSources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DataSources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "main.view_new_data_source". All fields are combined with a logical 'AND'. */
export type DataSources_Bool_Exp = {
  _and?: InputMaybe<Array<DataSources_Bool_Exp>>;
  _not?: InputMaybe<DataSources_Bool_Exp>;
  _or?: InputMaybe<Array<DataSources_Bool_Exp>>;
  autonomousSystemDataSourceAssociations?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
  autonomousSystemDataSourceAssociations_aggregate?: InputMaybe<AutonomousSystemDataSourceAssociations_Aggregate_Bool_Exp>;
  dataSourceMetadatas?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
  dataSourceMetadatas_aggregate?: InputMaybe<DataSourceMetadatas_Aggregate_Bool_Exp>;
  data_service?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  peeringDataSourceAssociations?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
  peeringDataSourceAssociations_aggregate?: InputMaybe<PeeringDataSourceAssociations_Aggregate_Bool_Exp>;
  prefixDataSourceAssociations?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
  prefixDataSourceAssociations_aggregate?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Bool_Exp>;
  routeDataSourceAssociations?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
  routeDataSourceAssociations_aggregate?: InputMaybe<RouteDataSourceAssociations_Aggregate_Bool_Exp>;
  rpkiROADataSourceAssociations?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
  rpkiROADataSourceAssociations_aggregate?: InputMaybe<RpkiRoaDataSourceAssociations_Aggregate_Bool_Exp>;
  selector?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type DataSources_Max_Fields = {
  __typename?: 'dataSources_max_fields';
  data_service?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DataSources_Min_Fields = {
  __typename?: 'dataSources_min_fields';
  data_service?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "main.view_new_data_source" */
export type DataSources_Mutation_Response = {
  __typename?: 'dataSources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DataSources>;
};

/** Ordering options when selecting data from "main.view_new_data_source". */
export type DataSources_Order_By = {
  autonomousSystemDataSourceAssociations_aggregate?: InputMaybe<AutonomousSystemDataSourceAssociations_Aggregate_Order_By>;
  dataSourceMetadatas_aggregate?: InputMaybe<DataSourceMetadatas_Aggregate_Order_By>;
  data_service?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  peeringDataSourceAssociations_aggregate?: InputMaybe<PeeringDataSourceAssociations_Aggregate_Order_By>;
  prefixDataSourceAssociations_aggregate?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Order_By>;
  routeDataSourceAssociations_aggregate?: InputMaybe<RouteDataSourceAssociations_Aggregate_Order_By>;
  rpkiROADataSourceAssociations_aggregate?: InputMaybe<RpkiRoaDataSourceAssociations_Aggregate_Order_By>;
  selector?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_new_data_source" */
export enum DataSources_Select_Column {
  /** column name */
  DataService = 'data_service',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Selector = 'selector'
}

/** input type for updating data in table "main.view_new_data_source" */
export type DataSources_Set_Input = {
  locality?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "dataSources" */
export type DataSources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DataSources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DataSources_Stream_Cursor_Value_Input = {
  data_service?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  selector?: InputMaybe<Scalars['jsonb']>;
};

export type DataSources_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataSources_Set_Input>;
  where: DataSources_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Is_Sub_Prefix_Multiple_Prefixes_Args = {
  networks?: InputMaybe<Scalars['_cidr']>;
};

export type Is_Sub_Prefix_Prefixes_Args = {
  network?: InputMaybe<Scalars['cidr']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "conf.view_new_manual_autonomous_system" */
export type ManuallyConfiguredAutonomousSystems = {
  __typename?: 'manuallyConfiguredAutonomousSystems';
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['bigint']>;
  reject?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_manual_autonomous_system". All fields are combined with a logical 'AND'. */
export type ManuallyConfiguredAutonomousSystems_Bool_Exp = {
  _and?: InputMaybe<Array<ManuallyConfiguredAutonomousSystems_Bool_Exp>>;
  _not?: InputMaybe<ManuallyConfiguredAutonomousSystems_Bool_Exp>;
  _or?: InputMaybe<Array<ManuallyConfiguredAutonomousSystems_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  number?: InputMaybe<Bigint_Comparison_Exp>;
  reject?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "conf.view_new_manual_autonomous_system" */
export type ManuallyConfiguredAutonomousSystems_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['bigint']>;
  reject?: InputMaybe<Scalars['Boolean']>;
};

/** response of any mutation on the table "conf.view_new_manual_autonomous_system" */
export type ManuallyConfiguredAutonomousSystems_Mutation_Response = {
  __typename?: 'manuallyConfiguredAutonomousSystems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManuallyConfiguredAutonomousSystems>;
};

/** Ordering options when selecting data from "conf.view_new_manual_autonomous_system". */
export type ManuallyConfiguredAutonomousSystems_Order_By = {
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  reject?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_new_manual_autonomous_system" */
export enum ManuallyConfiguredAutonomousSystems_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  Reject = 'reject'
}

/** input type for updating data in table "conf.view_new_manual_autonomous_system" */
export type ManuallyConfiguredAutonomousSystems_Set_Input = {
  reject?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "manuallyConfiguredAutonomousSystems" */
export type ManuallyConfiguredAutonomousSystems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManuallyConfiguredAutonomousSystems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManuallyConfiguredAutonomousSystems_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['bigint']>;
  reject?: InputMaybe<Scalars['Boolean']>;
};

export type ManuallyConfiguredAutonomousSystems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManuallyConfiguredAutonomousSystems_Set_Input>;
  where: ManuallyConfiguredAutonomousSystems_Bool_Exp;
};

/** columns and relationships of "conf.view_new_manual_data_source" */
export type ManuallyConfiguredDataSources = {
  __typename?: 'manuallyConfiguredDataSources';
  data_service?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  reject?: Maybe<Scalars['Boolean']>;
  selector?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "conf.view_new_manual_data_source" */
export type ManuallyConfiguredDataSourcesSelectorArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_manual_data_source". All fields are combined with a logical 'AND'. */
export type ManuallyConfiguredDataSources_Bool_Exp = {
  _and?: InputMaybe<Array<ManuallyConfiguredDataSources_Bool_Exp>>;
  _not?: InputMaybe<ManuallyConfiguredDataSources_Bool_Exp>;
  _or?: InputMaybe<Array<ManuallyConfiguredDataSources_Bool_Exp>>;
  data_service?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reject?: InputMaybe<Boolean_Comparison_Exp>;
  selector?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** input type for inserting data into table "conf.view_new_manual_data_source" */
export type ManuallyConfiguredDataSources_Insert_Input = {
  data_service?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  reject?: InputMaybe<Scalars['Boolean']>;
  selector?: InputMaybe<Scalars['jsonb']>;
};

/** response of any mutation on the table "conf.view_new_manual_data_source" */
export type ManuallyConfiguredDataSources_Mutation_Response = {
  __typename?: 'manuallyConfiguredDataSources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManuallyConfiguredDataSources>;
};

/** Ordering options when selecting data from "conf.view_new_manual_data_source". */
export type ManuallyConfiguredDataSources_Order_By = {
  data_service?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reject?: InputMaybe<Order_By>;
  selector?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_new_manual_data_source" */
export enum ManuallyConfiguredDataSources_Select_Column {
  /** column name */
  DataService = 'data_service',
  /** column name */
  Id = 'id',
  /** column name */
  Reject = 'reject',
  /** column name */
  Selector = 'selector'
}

/** input type for updating data in table "conf.view_new_manual_data_source" */
export type ManuallyConfiguredDataSources_Set_Input = {
  reject?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "manuallyConfiguredDataSources" */
export type ManuallyConfiguredDataSources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManuallyConfiguredDataSources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManuallyConfiguredDataSources_Stream_Cursor_Value_Input = {
  data_service?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  reject?: InputMaybe<Scalars['Boolean']>;
  selector?: InputMaybe<Scalars['jsonb']>;
};

export type ManuallyConfiguredDataSources_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManuallyConfiguredDataSources_Set_Input>;
  where: ManuallyConfiguredDataSources_Bool_Exp;
};

/** columns and relationships of "conf.view_new_manual_prefix" */
export type ManuallyConfiguredPrefixes = {
  __typename?: 'manuallyConfiguredPrefixes';
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['cidr']>;
  reject?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "conf.view_new_manual_prefix". All fields are combined with a logical 'AND'. */
export type ManuallyConfiguredPrefixes_Bool_Exp = {
  _and?: InputMaybe<Array<ManuallyConfiguredPrefixes_Bool_Exp>>;
  _not?: InputMaybe<ManuallyConfiguredPrefixes_Bool_Exp>;
  _or?: InputMaybe<Array<ManuallyConfiguredPrefixes_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ip_version?: InputMaybe<Int_Comparison_Exp>;
  mask_length?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Cidr_Comparison_Exp>;
  reject?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "conf.view_new_manual_prefix" */
export type ManuallyConfiguredPrefixes_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  network?: InputMaybe<Scalars['cidr']>;
  reject?: InputMaybe<Scalars['Boolean']>;
};

/** response of any mutation on the table "conf.view_new_manual_prefix" */
export type ManuallyConfiguredPrefixes_Mutation_Response = {
  __typename?: 'manuallyConfiguredPrefixes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManuallyConfiguredPrefixes>;
};

/** Ordering options when selecting data from "conf.view_new_manual_prefix". */
export type ManuallyConfiguredPrefixes_Order_By = {
  id?: InputMaybe<Order_By>;
  ip_version?: InputMaybe<Order_By>;
  mask_length?: InputMaybe<Order_By>;
  network?: InputMaybe<Order_By>;
  reject?: InputMaybe<Order_By>;
};

/** select columns of table "conf.view_new_manual_prefix" */
export enum ManuallyConfiguredPrefixes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IpVersion = 'ip_version',
  /** column name */
  MaskLength = 'mask_length',
  /** column name */
  Network = 'network',
  /** column name */
  Reject = 'reject'
}

/** input type for updating data in table "conf.view_new_manual_prefix" */
export type ManuallyConfiguredPrefixes_Set_Input = {
  reject?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "manuallyConfiguredPrefixes" */
export type ManuallyConfiguredPrefixes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManuallyConfiguredPrefixes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManuallyConfiguredPrefixes_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  ip_version?: InputMaybe<Scalars['Int']>;
  mask_length?: InputMaybe<Scalars['Int']>;
  network?: InputMaybe<Scalars['cidr']>;
  reject?: InputMaybe<Scalars['Boolean']>;
};

export type ManuallyConfiguredPrefixes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManuallyConfiguredPrefixes_Set_Input>;
  where: ManuallyConfiguredPrefixes_Bool_Exp;
};

/** columns and relationships of "main.view_max_bgp_update_rate" */
export type MaxBgpUpdateRate = {
  __typename?: 'maxBGPUpdateRate';
  id?: Maybe<Scalars['uuid']>;
  labels?: Maybe<Scalars['jsonb']>;
  time_updated?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "main.view_max_bgp_update_rate" */
export type MaxBgpUpdateRateLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "main.view_max_bgp_update_rate". All fields are combined with a logical 'AND'. */
export type MaxBgpUpdateRate_Bool_Exp = {
  _and?: InputMaybe<Array<MaxBgpUpdateRate_Bool_Exp>>;
  _not?: InputMaybe<MaxBgpUpdateRate_Bool_Exp>;
  _or?: InputMaybe<Array<MaxBgpUpdateRate_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  time_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "main.view_max_bgp_update_rate". */
export type MaxBgpUpdateRate_Order_By = {
  id?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  time_updated?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_max_bgp_update_rate" */
export enum MaxBgpUpdateRate_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  TimeUpdated = 'time_updated',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "maxBGPUpdateRate" */
export type MaxBgpUpdateRate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MaxBgpUpdateRate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MaxBgpUpdateRate_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  time_updated?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "conf.view_alert_subscription" */
  deleteAlertSubscriptions?: Maybe<AlertSubscriptions_Mutation_Response>;
  /** delete data from the table: "main.view_alert" */
  deleteAlerts?: Maybe<Alerts_Mutation_Response>;
  /** delete data from the table: "main.view_new_autonomous_system_data_source_assoc" */
  deleteAutonomousSystemDataSourceAssociations?: Maybe<AutonomousSystemDataSourceAssociations_Mutation_Response>;
  /** delete data from the table: "main.view_data_source_metadata" */
  deleteDataSourceMetadatas?: Maybe<DataSourceMetadatas_Mutation_Response>;
  /** delete data from the table: "conf.view_new_manual_autonomous_system" */
  deleteManuallyConfiguredAutonomousSystems?: Maybe<ManuallyConfiguredAutonomousSystems_Mutation_Response>;
  /** delete data from the table: "conf.view_new_manual_data_source" */
  deleteManuallyConfiguredDataSources?: Maybe<ManuallyConfiguredDataSources_Mutation_Response>;
  /** delete data from the table: "conf.view_new_manual_prefix" */
  deleteManuallyConfiguredPrefixes?: Maybe<ManuallyConfiguredPrefixes_Mutation_Response>;
  /** delete data from the table: "main.view_new_peering_link_data_source_assoc" */
  deletePeeringDataSourceAssociations?: Maybe<PeeringDataSourceAssociations_Mutation_Response>;
  /** delete data from the table: "main.view_new_prefix_data_source_assoc" */
  deletePrefixDataSourceAssociations?: Maybe<PrefixDataSourceAssociations_Mutation_Response>;
  /** delete data from the table: "main.view_new_route_data_source_assoc" */
  deleteRouteDataSourceAssociations?: Maybe<RouteDataSourceAssociations_Mutation_Response>;
  /** delete data from the table: "main.view_new_rpki_roa_data_source_assoc" */
  deleteRpkiROADataSourceAssociations?: Maybe<RpkiRoaDataSourceAssociations_Mutation_Response>;
  /** insert a single row into the table: "conf.view_alert_subscription" */
  insertAlertSubscription?: Maybe<AlertSubscriptions>;
  /** insert data into the table: "conf.view_alert_subscription" */
  insertAlertSubscriptions?: Maybe<AlertSubscriptions_Mutation_Response>;
  /** insert a single row into the table: "main.view_new_autonomous_system" */
  insertAutonomousSystem?: Maybe<AutonomousSystems>;
  /** insert a single row into the table: "main.view_new_autonomous_system_data_source_assoc" */
  insertAutonomousSystemDataSourceAssociation?: Maybe<AutonomousSystemDataSourceAssociations>;
  /** insert data into the table: "main.view_new_autonomous_system_data_source_assoc" */
  insertAutonomousSystemDataSourceAssociations?: Maybe<AutonomousSystemDataSourceAssociations_Mutation_Response>;
  /** insert data into the table: "main.view_new_autonomous_system" */
  insertAutonomousSystems?: Maybe<AutonomousSystems_Mutation_Response>;
  /** insert a single row into the table: "conf.view_new_data_adapter" */
  insertConfiguredDataService?: Maybe<ConfiguredDataServices>;
  /** insert data into the table: "conf.view_new_data_adapter" */
  insertConfiguredDataServices?: Maybe<ConfiguredDataServices_Mutation_Response>;
  /** insert a single row into the table: "main.view_data_source_metadata" */
  insertDataSourceMetadata?: Maybe<DataSourceMetadatas>;
  /** insert data into the table: "main.view_data_source_metadata" */
  insertDataSourceMetadatas?: Maybe<DataSourceMetadatas_Mutation_Response>;
  /** insert a single row into the table: "conf.view_new_manual_autonomous_system" */
  insertManuallyConfiguredAutonomousSystem?: Maybe<ManuallyConfiguredAutonomousSystems>;
  /** insert data into the table: "conf.view_new_manual_autonomous_system" */
  insertManuallyConfiguredAutonomousSystems?: Maybe<ManuallyConfiguredAutonomousSystems_Mutation_Response>;
  /** insert a single row into the table: "conf.view_new_manual_data_source" */
  insertManuallyConfiguredDataSource?: Maybe<ManuallyConfiguredDataSources>;
  /** insert data into the table: "conf.view_new_manual_data_source" */
  insertManuallyConfiguredDataSources?: Maybe<ManuallyConfiguredDataSources_Mutation_Response>;
  /** insert a single row into the table: "conf.view_new_manual_prefix" */
  insertManuallyConfiguredPrefix?: Maybe<ManuallyConfiguredPrefixes>;
  /** insert data into the table: "conf.view_new_manual_prefix" */
  insertManuallyConfiguredPrefixes?: Maybe<ManuallyConfiguredPrefixes_Mutation_Response>;
  /** insert a single row into the table: "main.view_new_peering_link" */
  insertPeering?: Maybe<Peerings>;
  /** insert a single row into the table: "main.view_new_peering_link_data_source_assoc" */
  insertPeeringDataSourceAssociation?: Maybe<PeeringDataSourceAssociations>;
  /** insert data into the table: "main.view_new_peering_link_data_source_assoc" */
  insertPeeringDataSourceAssociations?: Maybe<PeeringDataSourceAssociations_Mutation_Response>;
  /** insert data into the table: "main.view_new_peering_link" */
  insertPeerings?: Maybe<Peerings_Mutation_Response>;
  /** insert a single row into the table: "main.view_new_prefix" */
  insertPrefix?: Maybe<Prefixes>;
  /** insert a single row into the table: "main.view_new_prefix_data_source_assoc" */
  insertPrefixDataSourceAssociation?: Maybe<PrefixDataSourceAssociations>;
  /** insert data into the table: "main.view_new_prefix_data_source_assoc" */
  insertPrefixDataSourceAssociations?: Maybe<PrefixDataSourceAssociations_Mutation_Response>;
  /** insert data into the table: "main.view_new_prefix" */
  insertPrefixes?: Maybe<Prefixes_Mutation_Response>;
  /** insert a single row into the table: "main.view_new_route" */
  insertRoute?: Maybe<Routes>;
  /** insert a single row into the table: "main.view_new_route_data_source_assoc" */
  insertRouteDataSourceAssociation?: Maybe<RouteDataSourceAssociations>;
  /** insert data into the table: "main.view_new_route_data_source_assoc" */
  insertRouteDataSourceAssociations?: Maybe<RouteDataSourceAssociations_Mutation_Response>;
  /** insert data into the table: "main.view_new_route" */
  insertRoutes?: Maybe<Routes_Mutation_Response>;
  /** insert a single row into the table: "main.view_new_rpki_roa" */
  insertRpkiROA?: Maybe<RpkiRoAs>;
  /** insert a single row into the table: "main.view_new_rpki_roa_data_source_assoc" */
  insertRpkiROADataSourceAssociation?: Maybe<RpkiRoaDataSourceAssociations>;
  /** insert data into the table: "main.view_new_rpki_roa_data_source_assoc" */
  insertRpkiROADataSourceAssociations?: Maybe<RpkiRoaDataSourceAssociations_Mutation_Response>;
  /** insert data into the table: "main.view_new_rpki_roa" */
  insertRpkiROAs?: Maybe<RpkiRoAs_Mutation_Response>;
  /** update data of the table: "conf.view_alert_subscription" */
  updateAlertSubscription?: Maybe<AlertSubscriptions_Mutation_Response>;
  /** update multiples rows of table: "conf.view_alert_subscription" */
  updateAlertSubscriptions?: Maybe<Array<Maybe<AlertSubscriptions_Mutation_Response>>>;
  /** update data of the table: "main.view_new_autonomous_system_data_source_assoc" */
  updateAutonomousSystemDataSourceAssociation?: Maybe<AutonomousSystemDataSourceAssociations_Mutation_Response>;
  /** update multiples rows of table: "main.view_new_autonomous_system_data_source_assoc" */
  updateAutonomousSystemDataSourceAssociations?: Maybe<Array<Maybe<AutonomousSystemDataSourceAssociations_Mutation_Response>>>;
  /** update data of the table: "conf.view_new_data_adapter" */
  updateConfiguredDataService?: Maybe<ConfiguredDataServices_Mutation_Response>;
  /** update multiples rows of table: "conf.view_new_data_adapter" */
  updateConfiguredDataServices?: Maybe<Array<Maybe<ConfiguredDataServices_Mutation_Response>>>;
  /** update data of the table: "main.view_new_data_source" */
  updateDataSource?: Maybe<DataSources_Mutation_Response>;
  /** update data of the table: "main.view_data_source_metadata" */
  updateDataSourceMetadata?: Maybe<DataSourceMetadatas_Mutation_Response>;
  /** update multiples rows of table: "main.view_data_source_metadata" */
  updateDataSourceMetadatas?: Maybe<Array<Maybe<DataSourceMetadatas_Mutation_Response>>>;
  /** update multiples rows of table: "main.view_new_data_source" */
  updateDataSources?: Maybe<Array<Maybe<DataSources_Mutation_Response>>>;
  /** update data of the table: "conf.view_new_manual_autonomous_system" */
  updateManuallyConfiguredAutonomousSystem?: Maybe<ManuallyConfiguredAutonomousSystems_Mutation_Response>;
  /** update multiples rows of table: "conf.view_new_manual_autonomous_system" */
  updateManuallyConfiguredAutonomousSystems?: Maybe<Array<Maybe<ManuallyConfiguredAutonomousSystems_Mutation_Response>>>;
  /** update data of the table: "conf.view_new_manual_data_source" */
  updateManuallyConfiguredDataSource?: Maybe<ManuallyConfiguredDataSources_Mutation_Response>;
  /** update multiples rows of table: "conf.view_new_manual_data_source" */
  updateManuallyConfiguredDataSources?: Maybe<Array<Maybe<ManuallyConfiguredDataSources_Mutation_Response>>>;
  /** update data of the table: "conf.view_new_manual_prefix" */
  updateManuallyConfiguredPrefix?: Maybe<ManuallyConfiguredPrefixes_Mutation_Response>;
  /** update multiples rows of table: "conf.view_new_manual_prefix" */
  updateManuallyConfiguredPrefixes?: Maybe<Array<Maybe<ManuallyConfiguredPrefixes_Mutation_Response>>>;
  /** update data of the table: "main.view_new_peering_link_data_source_assoc" */
  updatePeeringDataSourceAssociation?: Maybe<PeeringDataSourceAssociations_Mutation_Response>;
  /** update multiples rows of table: "main.view_new_peering_link_data_source_assoc" */
  updatePeeringDataSourceAssociations?: Maybe<Array<Maybe<PeeringDataSourceAssociations_Mutation_Response>>>;
  /** update data of the table: "main.view_new_prefix_data_source_assoc" */
  updatePrefixDataSourceAssociation?: Maybe<PrefixDataSourceAssociations_Mutation_Response>;
  /** update multiples rows of table: "main.view_new_prefix_data_source_assoc" */
  updatePrefixDataSourceAssociations?: Maybe<Array<Maybe<PrefixDataSourceAssociations_Mutation_Response>>>;
  /** update data of the table: "main.view_new_route_data_source_assoc" */
  updateRouteDataSourceAssociation?: Maybe<RouteDataSourceAssociations_Mutation_Response>;
  /** update multiples rows of table: "main.view_new_route_data_source_assoc" */
  updateRouteDataSourceAssociations?: Maybe<Array<Maybe<RouteDataSourceAssociations_Mutation_Response>>>;
  /** update data of the table: "main.view_new_rpki_roa_data_source_assoc" */
  updateRpkiROADataSourceAssociation?: Maybe<RpkiRoaDataSourceAssociations_Mutation_Response>;
  /** update multiples rows of table: "main.view_new_rpki_roa_data_source_assoc" */
  updateRpkiROADataSourceAssociations?: Maybe<Array<Maybe<RpkiRoaDataSourceAssociations_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDeleteAlertSubscriptionsArgs = {
  where: AlertSubscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAlertsArgs = {
  where: Alerts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAutonomousSystemDataSourceAssociationsArgs = {
  where: AutonomousSystemDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteDataSourceMetadatasArgs = {
  where: DataSourceMetadatas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteManuallyConfiguredAutonomousSystemsArgs = {
  where: ManuallyConfiguredAutonomousSystems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteManuallyConfiguredDataSourcesArgs = {
  where: ManuallyConfiguredDataSources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteManuallyConfiguredPrefixesArgs = {
  where: ManuallyConfiguredPrefixes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeletePeeringDataSourceAssociationsArgs = {
  where: PeeringDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeletePrefixDataSourceAssociationsArgs = {
  where: PrefixDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteRouteDataSourceAssociationsArgs = {
  where: RouteDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteRpkiRoaDataSourceAssociationsArgs = {
  where: RpkiRoaDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsertAlertSubscriptionArgs = {
  object: AlertSubscriptions_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertAlertSubscriptionsArgs = {
  objects: Array<AlertSubscriptions_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertAutonomousSystemArgs = {
  object: AutonomousSystems_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertAutonomousSystemDataSourceAssociationArgs = {
  object: AutonomousSystemDataSourceAssociations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertAutonomousSystemDataSourceAssociationsArgs = {
  objects: Array<AutonomousSystemDataSourceAssociations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertAutonomousSystemsArgs = {
  objects: Array<AutonomousSystems_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertConfiguredDataServiceArgs = {
  object: ConfiguredDataServices_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertConfiguredDataServicesArgs = {
  objects: Array<ConfiguredDataServices_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertDataSourceMetadataArgs = {
  object: DataSourceMetadatas_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertDataSourceMetadatasArgs = {
  objects: Array<DataSourceMetadatas_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertManuallyConfiguredAutonomousSystemArgs = {
  object: ManuallyConfiguredAutonomousSystems_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertManuallyConfiguredAutonomousSystemsArgs = {
  objects: Array<ManuallyConfiguredAutonomousSystems_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertManuallyConfiguredDataSourceArgs = {
  object: ManuallyConfiguredDataSources_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertManuallyConfiguredDataSourcesArgs = {
  objects: Array<ManuallyConfiguredDataSources_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertManuallyConfiguredPrefixArgs = {
  object: ManuallyConfiguredPrefixes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertManuallyConfiguredPrefixesArgs = {
  objects: Array<ManuallyConfiguredPrefixes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertPeeringArgs = {
  object: Peerings_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertPeeringDataSourceAssociationArgs = {
  object: PeeringDataSourceAssociations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertPeeringDataSourceAssociationsArgs = {
  objects: Array<PeeringDataSourceAssociations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertPeeringsArgs = {
  objects: Array<Peerings_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertPrefixArgs = {
  object: Prefixes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertPrefixDataSourceAssociationArgs = {
  object: PrefixDataSourceAssociations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertPrefixDataSourceAssociationsArgs = {
  objects: Array<PrefixDataSourceAssociations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertPrefixesArgs = {
  objects: Array<Prefixes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertRouteArgs = {
  object: Routes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertRouteDataSourceAssociationArgs = {
  object: RouteDataSourceAssociations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertRouteDataSourceAssociationsArgs = {
  objects: Array<RouteDataSourceAssociations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertRoutesArgs = {
  objects: Array<Routes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertRpkiRoaArgs = {
  object: RpkiRoAs_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertRpkiRoaDataSourceAssociationArgs = {
  object: RpkiRoaDataSourceAssociations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsertRpkiRoaDataSourceAssociationsArgs = {
  objects: Array<RpkiRoaDataSourceAssociations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsertRpkiRoAsArgs = {
  objects: Array<RpkiRoAs_Insert_Input>;
};


/** mutation root */
export type Mutation_RootUpdateAlertSubscriptionArgs = {
  _append?: InputMaybe<AlertSubscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<AlertSubscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AlertSubscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AlertSubscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<AlertSubscriptions_Prepend_Input>;
  _set?: InputMaybe<AlertSubscriptions_Set_Input>;
  where: AlertSubscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAlertSubscriptionsArgs = {
  updates: Array<AlertSubscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateAutonomousSystemDataSourceAssociationArgs = {
  _set?: InputMaybe<AutonomousSystemDataSourceAssociations_Set_Input>;
  where: AutonomousSystemDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAutonomousSystemDataSourceAssociationsArgs = {
  updates: Array<AutonomousSystemDataSourceAssociations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateConfiguredDataServiceArgs = {
  _append?: InputMaybe<ConfiguredDataServices_Append_Input>;
  _delete_at_path?: InputMaybe<ConfiguredDataServices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ConfiguredDataServices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ConfiguredDataServices_Delete_Key_Input>;
  _prepend?: InputMaybe<ConfiguredDataServices_Prepend_Input>;
  _set?: InputMaybe<ConfiguredDataServices_Set_Input>;
  where: ConfiguredDataServices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateConfiguredDataServicesArgs = {
  updates: Array<ConfiguredDataServices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateDataSourceArgs = {
  _set?: InputMaybe<DataSources_Set_Input>;
  where: DataSources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDataSourceMetadataArgs = {
  _inc?: InputMaybe<DataSourceMetadatas_Inc_Input>;
  _set?: InputMaybe<DataSourceMetadatas_Set_Input>;
  where: DataSourceMetadatas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateDataSourceMetadatasArgs = {
  updates: Array<DataSourceMetadatas_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateDataSourcesArgs = {
  updates: Array<DataSources_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateManuallyConfiguredAutonomousSystemArgs = {
  _set?: InputMaybe<ManuallyConfiguredAutonomousSystems_Set_Input>;
  where: ManuallyConfiguredAutonomousSystems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateManuallyConfiguredAutonomousSystemsArgs = {
  updates: Array<ManuallyConfiguredAutonomousSystems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateManuallyConfiguredDataSourceArgs = {
  _set?: InputMaybe<ManuallyConfiguredDataSources_Set_Input>;
  where: ManuallyConfiguredDataSources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateManuallyConfiguredDataSourcesArgs = {
  updates: Array<ManuallyConfiguredDataSources_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateManuallyConfiguredPrefixArgs = {
  _set?: InputMaybe<ManuallyConfiguredPrefixes_Set_Input>;
  where: ManuallyConfiguredPrefixes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateManuallyConfiguredPrefixesArgs = {
  updates: Array<ManuallyConfiguredPrefixes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdatePeeringDataSourceAssociationArgs = {
  _set?: InputMaybe<PeeringDataSourceAssociations_Set_Input>;
  where: PeeringDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePeeringDataSourceAssociationsArgs = {
  updates: Array<PeeringDataSourceAssociations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdatePrefixDataSourceAssociationArgs = {
  _set?: InputMaybe<PrefixDataSourceAssociations_Set_Input>;
  where: PrefixDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdatePrefixDataSourceAssociationsArgs = {
  updates: Array<PrefixDataSourceAssociations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateRouteDataSourceAssociationArgs = {
  _set?: InputMaybe<RouteDataSourceAssociations_Set_Input>;
  where: RouteDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateRouteDataSourceAssociationsArgs = {
  updates: Array<RouteDataSourceAssociations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdateRpkiRoaDataSourceAssociationArgs = {
  _set?: InputMaybe<RpkiRoaDataSourceAssociations_Set_Input>;
  where: RpkiRoaDataSourceAssociations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateRpkiRoaDataSourceAssociationsArgs = {
  updates: Array<RpkiRoaDataSourceAssociations_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Orig_Prefixes_Diff_AutonomousSystems_Args = {
  base_service?: InputMaybe<Scalars['String']>;
  comp_service?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations = {
  __typename?: 'peeringDataSourceAssociations';
  /** An object relationship */
  dataSource?: Maybe<DataSources>;
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  peering?: Maybe<Peerings>;
};

/** aggregated selection of "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Aggregate = {
  __typename?: 'peeringDataSourceAssociations_aggregate';
  aggregate?: Maybe<PeeringDataSourceAssociations_Aggregate_Fields>;
  nodes: Array<PeeringDataSourceAssociations>;
};

export type PeeringDataSourceAssociations_Aggregate_Bool_Exp = {
  count?: InputMaybe<PeeringDataSourceAssociations_Aggregate_Bool_Exp_Count>;
};

export type PeeringDataSourceAssociations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Aggregate_Fields = {
  __typename?: 'peeringDataSourceAssociations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PeeringDataSourceAssociations_Max_Fields>;
  min?: Maybe<PeeringDataSourceAssociations_Min_Fields>;
};


/** aggregate fields of "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PeeringDataSourceAssociations_Max_Order_By>;
  min?: InputMaybe<PeeringDataSourceAssociations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Arr_Rel_Insert_Input = {
  data: Array<PeeringDataSourceAssociations_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_peering_link_data_source_assoc". All fields are combined with a logical 'AND'. */
export type PeeringDataSourceAssociations_Bool_Exp = {
  _and?: InputMaybe<Array<PeeringDataSourceAssociations_Bool_Exp>>;
  _not?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
  _or?: InputMaybe<Array<PeeringDataSourceAssociations_Bool_Exp>>;
  dataSource?: InputMaybe<DataSources_Bool_Exp>;
  data_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  peering?: InputMaybe<Peerings_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Insert_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  peering?: InputMaybe<Peerings_Obj_Rel_Insert_Input>;
  peering_link_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PeeringDataSourceAssociations_Max_Fields = {
  __typename?: 'peeringDataSourceAssociations_max_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Max_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PeeringDataSourceAssociations_Min_Fields = {
  __typename?: 'peeringDataSourceAssociations_min_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Min_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Mutation_Response = {
  __typename?: 'peeringDataSourceAssociations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PeeringDataSourceAssociations>;
};

/** Ordering options when selecting data from "main.view_new_peering_link_data_source_assoc". */
export type PeeringDataSourceAssociations_Order_By = {
  dataSource?: InputMaybe<DataSources_Order_By>;
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  peering?: InputMaybe<Peerings_Order_By>;
};

/** select columns of table "main.view_new_peering_link_data_source_assoc" */
export enum PeeringDataSourceAssociations_Select_Column {
  /** column name */
  DataTime = 'data_time',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "main.view_new_peering_link_data_source_assoc" */
export type PeeringDataSourceAssociations_Set_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "peeringDataSourceAssociations" */
export type PeeringDataSourceAssociations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PeeringDataSourceAssociations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PeeringDataSourceAssociations_Stream_Cursor_Value_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type PeeringDataSourceAssociations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PeeringDataSourceAssociations_Set_Input>;
  where: PeeringDataSourceAssociations_Bool_Exp;
};

/** columns and relationships of "main.view_new_peering_link" */
export type Peerings = {
  __typename?: 'peerings';
  /** total number of peering link-data source associations and by extension data sources */
  data_source_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  leftAutonomousSystem?: Maybe<AutonomousSystems>;
  /** An array relationship */
  peeringDataSourceAssociations: Array<PeeringDataSourceAssociations>;
  /** An aggregate relationship */
  peeringDataSourceAssociations_aggregate: PeeringDataSourceAssociations_Aggregate;
  /** An object relationship */
  rightAutonomousSystem?: Maybe<AutonomousSystems>;
};


/** columns and relationships of "main.view_new_peering_link" */
export type PeeringsPeeringDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_peering_link" */
export type PeeringsPeeringDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};

/** aggregated selection of "main.view_new_peering_link" */
export type Peerings_Aggregate = {
  __typename?: 'peerings_aggregate';
  aggregate?: Maybe<Peerings_Aggregate_Fields>;
  nodes: Array<Peerings>;
};

export type Peerings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Peerings_Aggregate_Bool_Exp_Count>;
};

export type Peerings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Peerings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Peerings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_peering_link" */
export type Peerings_Aggregate_Fields = {
  __typename?: 'peerings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Peerings_Max_Fields>;
  min?: Maybe<Peerings_Min_Fields>;
};


/** aggregate fields of "main.view_new_peering_link" */
export type Peerings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Peerings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_peering_link" */
export type Peerings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Peerings_Max_Order_By>;
  min?: InputMaybe<Peerings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_peering_link" */
export type Peerings_Arr_Rel_Insert_Input = {
  data: Array<Peerings_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_peering_link". All fields are combined with a logical 'AND'. */
export type Peerings_Bool_Exp = {
  _and?: InputMaybe<Array<Peerings_Bool_Exp>>;
  _not?: InputMaybe<Peerings_Bool_Exp>;
  _or?: InputMaybe<Array<Peerings_Bool_Exp>>;
  data_source_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leftAutonomousSystem?: InputMaybe<AutonomousSystems_Bool_Exp>;
  peeringDataSourceAssociations?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
  peeringDataSourceAssociations_aggregate?: InputMaybe<PeeringDataSourceAssociations_Aggregate_Bool_Exp>;
  rightAutonomousSystem?: InputMaybe<AutonomousSystems_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_peering_link" */
export type Peerings_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  leftAutonomousSystem?: InputMaybe<AutonomousSystems_Obj_Rel_Insert_Input>;
  left_autonomous_system_id?: InputMaybe<Scalars['uuid']>;
  peeringDataSourceAssociations?: InputMaybe<PeeringDataSourceAssociations_Arr_Rel_Insert_Input>;
  rightAutonomousSystem?: InputMaybe<AutonomousSystems_Obj_Rel_Insert_Input>;
  right_autonomous_system_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Peerings_Max_Fields = {
  __typename?: 'peerings_max_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main.view_new_peering_link" */
export type Peerings_Max_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Peerings_Min_Fields = {
  __typename?: 'peerings_min_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main.view_new_peering_link" */
export type Peerings_Min_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_peering_link" */
export type Peerings_Mutation_Response = {
  __typename?: 'peerings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Peerings>;
};

/** input type for inserting object relation for remote table "main.view_new_peering_link" */
export type Peerings_Obj_Rel_Insert_Input = {
  data: Peerings_Insert_Input;
};

/** Ordering options when selecting data from "main.view_new_peering_link". */
export type Peerings_Order_By = {
  data_source_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leftAutonomousSystem?: InputMaybe<AutonomousSystems_Order_By>;
  peeringDataSourceAssociations_aggregate?: InputMaybe<PeeringDataSourceAssociations_Aggregate_Order_By>;
  rightAutonomousSystem?: InputMaybe<AutonomousSystems_Order_By>;
};

/** select columns of table "main.view_new_peering_link" */
export enum Peerings_Select_Column {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "peerings" */
export type Peerings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Peerings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Peerings_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations = {
  __typename?: 'prefixDataSourceAssociations';
  /** An object relationship */
  dataSource?: Maybe<DataSources>;
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  prefix?: Maybe<Prefixes>;
  withdrawn?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Aggregate = {
  __typename?: 'prefixDataSourceAssociations_aggregate';
  aggregate?: Maybe<PrefixDataSourceAssociations_Aggregate_Fields>;
  nodes: Array<PrefixDataSourceAssociations>;
};

export type PrefixDataSourceAssociations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Bool_Exp_Count>;
};

export type PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_And = {
  arguments: PrefixDataSourceAssociations_Select_Column_PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PrefixDataSourceAssociations_Select_Column_PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PrefixDataSourceAssociations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Aggregate_Fields = {
  __typename?: 'prefixDataSourceAssociations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PrefixDataSourceAssociations_Max_Fields>;
  min?: Maybe<PrefixDataSourceAssociations_Min_Fields>;
};


/** aggregate fields of "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PrefixDataSourceAssociations_Max_Order_By>;
  min?: InputMaybe<PrefixDataSourceAssociations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Arr_Rel_Insert_Input = {
  data: Array<PrefixDataSourceAssociations_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_prefix_data_source_assoc". All fields are combined with a logical 'AND'. */
export type PrefixDataSourceAssociations_Bool_Exp = {
  _and?: InputMaybe<Array<PrefixDataSourceAssociations_Bool_Exp>>;
  _not?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
  _or?: InputMaybe<Array<PrefixDataSourceAssociations_Bool_Exp>>;
  dataSource?: InputMaybe<DataSources_Bool_Exp>;
  data_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  prefix?: InputMaybe<Prefixes_Bool_Exp>;
  withdrawn?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Insert_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  prefix?: InputMaybe<Prefixes_Obj_Rel_Insert_Input>;
  prefix_id?: InputMaybe<Scalars['uuid']>;
  withdrawn?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type PrefixDataSourceAssociations_Max_Fields = {
  __typename?: 'prefixDataSourceAssociations_max_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Max_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PrefixDataSourceAssociations_Min_Fields = {
  __typename?: 'prefixDataSourceAssociations_min_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Min_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Mutation_Response = {
  __typename?: 'prefixDataSourceAssociations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PrefixDataSourceAssociations>;
};

/** Ordering options when selecting data from "main.view_new_prefix_data_source_assoc". */
export type PrefixDataSourceAssociations_Order_By = {
  dataSource?: InputMaybe<DataSources_Order_By>;
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prefix?: InputMaybe<Prefixes_Order_By>;
  withdrawn?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_new_prefix_data_source_assoc" */
export enum PrefixDataSourceAssociations_Select_Column {
  /** column name */
  DataTime = 'data_time',
  /** column name */
  Id = 'id',
  /** column name */
  Withdrawn = 'withdrawn'
}

/** select "prefixDataSourceAssociations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "main.view_new_prefix_data_source_assoc" */
export enum PrefixDataSourceAssociations_Select_Column_PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Withdrawn = 'withdrawn'
}

/** select "prefixDataSourceAssociations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "main.view_new_prefix_data_source_assoc" */
export enum PrefixDataSourceAssociations_Select_Column_PrefixDataSourceAssociations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Withdrawn = 'withdrawn'
}

/** input type for updating data in table "main.view_new_prefix_data_source_assoc" */
export type PrefixDataSourceAssociations_Set_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  withdrawn?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "prefixDataSourceAssociations" */
export type PrefixDataSourceAssociations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PrefixDataSourceAssociations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PrefixDataSourceAssociations_Stream_Cursor_Value_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  withdrawn?: InputMaybe<Scalars['Boolean']>;
};

export type PrefixDataSourceAssociations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PrefixDataSourceAssociations_Set_Input>;
  where: PrefixDataSourceAssociations_Bool_Exp;
};

/** columns and relationships of "main.view_new_prefix" */
export type Prefixes = {
  __typename?: 'prefixes';
  /** as paths of base_service differ from as paths of comp_service up to end_asn */
  as_paths_diff?: Maybe<Scalars['String']>;
  /** best match of input prefixes */
  configured_prefix_best_match?: Maybe<Scalars['String']>;
  /** total number of prefix-data source associations and by extension data sources */
  data_source_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  /** is sub prefix of argument */
  is_sub_prefix?: Maybe<Scalars['Boolean']>;
  /** is sub prefix of argument (multiple element list) */
  is_sub_prefix_multiple?: Maybe<Scalars['String']>;
  /** get the last update of a prefix based on prefix-data source associations */
  last_update?: Maybe<Scalars['timestamptz']>;
  mask_length?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['cidr']>;
  /** a string representation of the prefix CIDR network */
  network_str?: Maybe<Scalars['String']>;
  /** An array relationship */
  prefixDataSourceAssociations: Array<PrefixDataSourceAssociations>;
  /** An aggregate relationship */
  prefixDataSourceAssociations_aggregate: PrefixDataSourceAssociations_Aggregate;
  /** An array relationship */
  routes: Array<Routes>;
  /** An aggregate relationship */
  routes_aggregate: Routes_Aggregate;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesAs_Paths_DiffArgs = {
  args: As_Paths_Diff_Prefixes_Args;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesIs_Sub_PrefixArgs = {
  args: Is_Sub_Prefix_Prefixes_Args;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesIs_Sub_Prefix_MultipleArgs = {
  args: Is_Sub_Prefix_Multiple_Prefixes_Args;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesPrefixDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesPrefixDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesRoutesArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


/** columns and relationships of "main.view_new_prefix" */
export type PrefixesRoutes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};

/** aggregated selection of "main.view_new_prefix" */
export type Prefixes_Aggregate = {
  __typename?: 'prefixes_aggregate';
  aggregate?: Maybe<Prefixes_Aggregate_Fields>;
  nodes: Array<Prefixes>;
};

/** aggregate fields of "main.view_new_prefix" */
export type Prefixes_Aggregate_Fields = {
  __typename?: 'prefixes_aggregate_fields';
  avg?: Maybe<Prefixes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Prefixes_Max_Fields>;
  min?: Maybe<Prefixes_Min_Fields>;
  stddev?: Maybe<Prefixes_Stddev_Fields>;
  stddev_pop?: Maybe<Prefixes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prefixes_Stddev_Samp_Fields>;
  sum?: Maybe<Prefixes_Sum_Fields>;
  var_pop?: Maybe<Prefixes_Var_Pop_Fields>;
  var_samp?: Maybe<Prefixes_Var_Samp_Fields>;
  variance?: Maybe<Prefixes_Variance_Fields>;
};


/** aggregate fields of "main.view_new_prefix" */
export type Prefixes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prefixes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Prefixes_Avg_Fields = {
  __typename?: 'prefixes_avg_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "main.view_new_prefix". All fields are combined with a logical 'AND'. */
export type Prefixes_Bool_Exp = {
  _and?: InputMaybe<Array<Prefixes_Bool_Exp>>;
  _not?: InputMaybe<Prefixes_Bool_Exp>;
  _or?: InputMaybe<Array<Prefixes_Bool_Exp>>;
  configured_prefix_best_match?: InputMaybe<String_Comparison_Exp>;
  data_source_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ip_version?: InputMaybe<Int_Comparison_Exp>;
  last_update?: InputMaybe<Timestamptz_Comparison_Exp>;
  mask_length?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Cidr_Comparison_Exp>;
  network_str?: InputMaybe<String_Comparison_Exp>;
  prefixDataSourceAssociations?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
  prefixDataSourceAssociations_aggregate?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Bool_Exp>;
  routes?: InputMaybe<Routes_Bool_Exp>;
  routes_aggregate?: InputMaybe<Routes_Aggregate_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_prefix" */
export type Prefixes_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  network?: InputMaybe<Scalars['cidr']>;
  prefixDataSourceAssociations?: InputMaybe<PrefixDataSourceAssociations_Arr_Rel_Insert_Input>;
  routes?: InputMaybe<Routes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Prefixes_Max_Fields = {
  __typename?: 'prefixes_max_fields';
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Prefixes_Min_Fields = {
  __typename?: 'prefixes_min_fields';
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "main.view_new_prefix" */
export type Prefixes_Mutation_Response = {
  __typename?: 'prefixes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prefixes>;
};

/** input type for inserting object relation for remote table "main.view_new_prefix" */
export type Prefixes_Obj_Rel_Insert_Input = {
  data: Prefixes_Insert_Input;
};

/** Ordering options when selecting data from "main.view_new_prefix". */
export type Prefixes_Order_By = {
  configured_prefix_best_match?: InputMaybe<Order_By>;
  data_source_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip_version?: InputMaybe<Order_By>;
  last_update?: InputMaybe<Order_By>;
  mask_length?: InputMaybe<Order_By>;
  network?: InputMaybe<Order_By>;
  network_str?: InputMaybe<Order_By>;
  prefixDataSourceAssociations_aggregate?: InputMaybe<PrefixDataSourceAssociations_Aggregate_Order_By>;
  routes_aggregate?: InputMaybe<Routes_Aggregate_Order_By>;
};

/** select columns of table "main.view_new_prefix" */
export enum Prefixes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IpVersion = 'ip_version',
  /** column name */
  MaskLength = 'mask_length',
  /** column name */
  Network = 'network'
}

/** aggregate stddev on columns */
export type Prefixes_Stddev_Fields = {
  __typename?: 'prefixes_stddev_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Prefixes_Stddev_Pop_Fields = {
  __typename?: 'prefixes_stddev_pop_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Prefixes_Stddev_Samp_Fields = {
  __typename?: 'prefixes_stddev_samp_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "prefixes" */
export type Prefixes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prefixes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prefixes_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  ip_version?: InputMaybe<Scalars['Int']>;
  mask_length?: InputMaybe<Scalars['Int']>;
  network?: InputMaybe<Scalars['cidr']>;
};

/** aggregate sum on columns */
export type Prefixes_Sum_Fields = {
  __typename?: 'prefixes_sum_fields';
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Prefixes_Var_Pop_Fields = {
  __typename?: 'prefixes_var_pop_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Prefixes_Var_Samp_Fields = {
  __typename?: 'prefixes_var_samp_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Prefixes_Variance_Fields = {
  __typename?: 'prefixes_variance_fields';
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "conf.view_alert_receiver_type" */
  alertReceiverTypes: Array<AlertReceiverTypes>;
  /** fetch data from the table: "conf.view_alert_severity" */
  alertSeverities: Array<AlertSeverities>;
  /** An array relationship */
  alertSubscriptions: Array<AlertSubscriptions>;
  /** fetch aggregated fields from the table: "conf.view_alert_subscription" */
  alertSubscriptionsAggregate: AlertSubscriptions_Aggregate;
  /** fetch data from the table: "conf.view_alert_type" */
  alertTypes: Array<AlertTypes>;
  /** fetch data from the table: "main.view_alert" */
  alerts: Array<Alerts>;
  /** fetch aggregated fields from the table: "main.view_alert" */
  alertsAggregate: Alerts_Aggregate;
  /** An array relationship */
  autonomousSystemDataSourceAssociations: Array<AutonomousSystemDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_autonomous_system_data_source_assoc" */
  autonomousSystemDataSourceAssociationsAggregate: AutonomousSystemDataSourceAssociations_Aggregate;
  /** fetch data from the table: "main.view_new_autonomous_system" */
  autonomousSystems: Array<AutonomousSystems>;
  /** fetch aggregated fields from the table: "main.view_new_autonomous_system" */
  autonomousSystemsAggregate: AutonomousSystems_Aggregate;
  /** fetch data from the table: "main.view_avg_bgp_update_rate" */
  avgBGPUpdateRate: Array<AvgBgpUpdateRate>;
  /** fetch data from the table: "main.view_bgp_update_rate" */
  bgpUpdateRate: Array<BgpUpdateRate>;
  /** fetch data from the table: "conf.view_new_autonomous_system" */
  configuredAutonomousSystems: Array<ConfiguredAutonomousSystems>;
  /** fetch data from the table: "conf.view_new_data_adapter" */
  configuredDataServices: Array<ConfiguredDataServices>;
  /** fetch data from the table: "conf.view_new_data_source" */
  configuredDataSources: Array<ConfiguredDataSources>;
  /** fetch data from the table: "conf.view_new_prefix" */
  configuredPrefixes: Array<ConfiguredPrefixes>;
  /** An array relationship */
  dataSourceMetadatas: Array<DataSourceMetadatas>;
  /** fetch aggregated fields from the table: "main.view_data_source_metadata" */
  dataSourceMetadatasAggregate: DataSourceMetadatas_Aggregate;
  /** fetch data from the table: "main.view_new_data_source" */
  dataSources: Array<DataSources>;
  /** fetch aggregated fields from the table: "main.view_new_data_source" */
  dataSourcesAggregate: DataSources_Aggregate;
  /** fetch data from the table: "conf.view_new_manual_autonomous_system" */
  manuallyConfiguredAutonomousSystems: Array<ManuallyConfiguredAutonomousSystems>;
  /** fetch data from the table: "conf.view_new_manual_data_source" */
  manuallyConfiguredDataSources: Array<ManuallyConfiguredDataSources>;
  /** fetch data from the table: "conf.view_new_manual_prefix" */
  manuallyConfiguredPrefixes: Array<ManuallyConfiguredPrefixes>;
  /** fetch data from the table: "main.view_max_bgp_update_rate" */
  maxBGPUpdateRate: Array<MaxBgpUpdateRate>;
  /** An array relationship */
  peeringDataSourceAssociations: Array<PeeringDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_peering_link_data_source_assoc" */
  peeringDataSourceAssociationsAggregate: PeeringDataSourceAssociations_Aggregate;
  /** fetch data from the table: "main.view_new_peering_link" */
  peerings: Array<Peerings>;
  /** fetch aggregated fields from the table: "main.view_new_peering_link" */
  peeringsAggregate: Peerings_Aggregate;
  /** An array relationship */
  prefixDataSourceAssociations: Array<PrefixDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_prefix_data_source_assoc" */
  prefixDataSourceAssociationsAggregate: PrefixDataSourceAssociations_Aggregate;
  /** fetch data from the table: "main.view_new_prefix" */
  prefixes: Array<Prefixes>;
  /** fetch aggregated fields from the table: "main.view_new_prefix" */
  prefixesAggregate: Prefixes_Aggregate;
  /** An array relationship */
  routeDataSourceAssociations: Array<RouteDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_route_data_source_assoc" */
  routeDataSourceAssociationsAggregate: RouteDataSourceAssociations_Aggregate;
  /** An array relationship */
  routes: Array<Routes>;
  /** fetch aggregated fields from the table: "main.view_new_route" */
  routesAggregate: Routes_Aggregate;
  /** An array relationship */
  rpkiROADataSourceAssociations: Array<RpkiRoaDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_rpki_roa_data_source_assoc" */
  rpkiROADataSourceAssociationsAggregate: RpkiRoaDataSourceAssociations_Aggregate;
  /** fetch data from the table: "main.view_new_rpki_roa" */
  rpkiROAs: Array<RpkiRoAs>;
  /** fetch aggregated fields from the table: "main.view_new_rpki_roa" */
  rpkiROAsAggregate: RpkiRoAs_Aggregate;
};


export type Query_RootAlertReceiverTypesArgs = {
  distinct_on?: InputMaybe<Array<AlertReceiverTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertReceiverTypes_Order_By>>;
  where?: InputMaybe<AlertReceiverTypes_Bool_Exp>;
};


export type Query_RootAlertSeveritiesArgs = {
  distinct_on?: InputMaybe<Array<AlertSeverities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSeverities_Order_By>>;
  where?: InputMaybe<AlertSeverities_Bool_Exp>;
};


export type Query_RootAlertSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


export type Query_RootAlertSubscriptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


export type Query_RootAlertTypesArgs = {
  distinct_on?: InputMaybe<Array<AlertTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertTypes_Order_By>>;
  where?: InputMaybe<AlertTypes_Bool_Exp>;
};


export type Query_RootAlertsArgs = {
  distinct_on?: InputMaybe<Array<Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alerts_Order_By>>;
  where?: InputMaybe<Alerts_Bool_Exp>;
};


export type Query_RootAlertsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alerts_Order_By>>;
  where?: InputMaybe<Alerts_Bool_Exp>;
};


export type Query_RootAutonomousSystemDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


export type Query_RootAutonomousSystemDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


export type Query_RootAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystems_Order_By>>;
  where?: InputMaybe<AutonomousSystems_Bool_Exp>;
};


export type Query_RootAutonomousSystemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystems_Order_By>>;
  where?: InputMaybe<AutonomousSystems_Bool_Exp>;
};


export type Query_RootAvgBgpUpdateRateArgs = {
  distinct_on?: InputMaybe<Array<AvgBgpUpdateRate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AvgBgpUpdateRate_Order_By>>;
  where?: InputMaybe<AvgBgpUpdateRate_Bool_Exp>;
};


export type Query_RootBgpUpdateRateArgs = {
  distinct_on?: InputMaybe<Array<BgpUpdateRate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BgpUpdateRate_Order_By>>;
  where?: InputMaybe<BgpUpdateRate_Bool_Exp>;
};


export type Query_RootConfiguredAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredAutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredAutonomousSystems_Order_By>>;
  where?: InputMaybe<ConfiguredAutonomousSystems_Bool_Exp>;
};


export type Query_RootConfiguredDataServicesArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredDataServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredDataServices_Order_By>>;
  where?: InputMaybe<ConfiguredDataServices_Bool_Exp>;
};


export type Query_RootConfiguredDataSourcesArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredDataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredDataSources_Order_By>>;
  where?: InputMaybe<ConfiguredDataSources_Bool_Exp>;
};


export type Query_RootConfiguredPrefixesArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredPrefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredPrefixes_Order_By>>;
  where?: InputMaybe<ConfiguredPrefixes_Bool_Exp>;
};


export type Query_RootDataSourceMetadatasArgs = {
  distinct_on?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSourceMetadatas_Order_By>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


export type Query_RootDataSourceMetadatasAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSourceMetadatas_Order_By>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


export type Query_RootDataSourcesArgs = {
  distinct_on?: InputMaybe<Array<DataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSources_Order_By>>;
  where?: InputMaybe<DataSources_Bool_Exp>;
};


export type Query_RootDataSourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSources_Order_By>>;
  where?: InputMaybe<DataSources_Bool_Exp>;
};


export type Query_RootManuallyConfiguredAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<ManuallyConfiguredAutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManuallyConfiguredAutonomousSystems_Order_By>>;
  where?: InputMaybe<ManuallyConfiguredAutonomousSystems_Bool_Exp>;
};


export type Query_RootManuallyConfiguredDataSourcesArgs = {
  distinct_on?: InputMaybe<Array<ManuallyConfiguredDataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManuallyConfiguredDataSources_Order_By>>;
  where?: InputMaybe<ManuallyConfiguredDataSources_Bool_Exp>;
};


export type Query_RootManuallyConfiguredPrefixesArgs = {
  distinct_on?: InputMaybe<Array<ManuallyConfiguredPrefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManuallyConfiguredPrefixes_Order_By>>;
  where?: InputMaybe<ManuallyConfiguredPrefixes_Bool_Exp>;
};


export type Query_RootMaxBgpUpdateRateArgs = {
  distinct_on?: InputMaybe<Array<MaxBgpUpdateRate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MaxBgpUpdateRate_Order_By>>;
  where?: InputMaybe<MaxBgpUpdateRate_Bool_Exp>;
};


export type Query_RootPeeringDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


export type Query_RootPeeringDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


export type Query_RootPeeringsArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


export type Query_RootPeeringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


export type Query_RootPrefixDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


export type Query_RootPrefixDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


export type Query_RootPrefixesArgs = {
  distinct_on?: InputMaybe<Array<Prefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prefixes_Order_By>>;
  where?: InputMaybe<Prefixes_Bool_Exp>;
};


export type Query_RootPrefixesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Prefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prefixes_Order_By>>;
  where?: InputMaybe<Prefixes_Bool_Exp>;
};


export type Query_RootRouteDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


export type Query_RootRouteDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


export type Query_RootRoutesArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


export type Query_RootRoutesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


export type Query_RootRpkiRoaDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


export type Query_RootRpkiRoaDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


export type Query_RootRpkiRoAsArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoAs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoAs_Order_By>>;
  where?: InputMaybe<RpkiRoAs_Bool_Exp>;
};


export type Query_RootRpkiRoAsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoAs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoAs_Order_By>>;
  where?: InputMaybe<RpkiRoAs_Bool_Exp>;
};

/** columns and relationships of "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations = {
  __typename?: 'routeDataSourceAssociations';
  communities?: Maybe<Scalars['_text']>;
  /** get the communities of route-data source associations in text format */
  communities_string?: Maybe<Scalars['String']>;
  /** An object relationship */
  dataSource?: Maybe<DataSources>;
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  route?: Maybe<Routes>;
};

/** aggregated selection of "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Aggregate = {
  __typename?: 'routeDataSourceAssociations_aggregate';
  aggregate?: Maybe<RouteDataSourceAssociations_Aggregate_Fields>;
  nodes: Array<RouteDataSourceAssociations>;
};

export type RouteDataSourceAssociations_Aggregate_Bool_Exp = {
  count?: InputMaybe<RouteDataSourceAssociations_Aggregate_Bool_Exp_Count>;
};

export type RouteDataSourceAssociations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Aggregate_Fields = {
  __typename?: 'routeDataSourceAssociations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RouteDataSourceAssociations_Max_Fields>;
  min?: Maybe<RouteDataSourceAssociations_Min_Fields>;
};


/** aggregate fields of "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<RouteDataSourceAssociations_Max_Order_By>;
  min?: InputMaybe<RouteDataSourceAssociations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Arr_Rel_Insert_Input = {
  data: Array<RouteDataSourceAssociations_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_route_data_source_assoc". All fields are combined with a logical 'AND'. */
export type RouteDataSourceAssociations_Bool_Exp = {
  _and?: InputMaybe<Array<RouteDataSourceAssociations_Bool_Exp>>;
  _not?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
  _or?: InputMaybe<Array<RouteDataSourceAssociations_Bool_Exp>>;
  communities?: InputMaybe<_Text_Comparison_Exp>;
  communities_string?: InputMaybe<String_Comparison_Exp>;
  dataSource?: InputMaybe<DataSources_Bool_Exp>;
  data_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  route?: InputMaybe<Routes_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Insert_Input = {
  communities?: InputMaybe<Scalars['_text']>;
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  route?: InputMaybe<Routes_Obj_Rel_Insert_Input>;
  route_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type RouteDataSourceAssociations_Max_Fields = {
  __typename?: 'routeDataSourceAssociations_max_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Max_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type RouteDataSourceAssociations_Min_Fields = {
  __typename?: 'routeDataSourceAssociations_min_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Min_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Mutation_Response = {
  __typename?: 'routeDataSourceAssociations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RouteDataSourceAssociations>;
};

/** Ordering options when selecting data from "main.view_new_route_data_source_assoc". */
export type RouteDataSourceAssociations_Order_By = {
  communities?: InputMaybe<Order_By>;
  communities_string?: InputMaybe<Order_By>;
  dataSource?: InputMaybe<DataSources_Order_By>;
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  route?: InputMaybe<Routes_Order_By>;
};

/** select columns of table "main.view_new_route_data_source_assoc" */
export enum RouteDataSourceAssociations_Select_Column {
  /** column name */
  Communities = 'communities',
  /** column name */
  DataTime = 'data_time',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "main.view_new_route_data_source_assoc" */
export type RouteDataSourceAssociations_Set_Input = {
  communities?: InputMaybe<Scalars['_text']>;
  data_time?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "routeDataSourceAssociations" */
export type RouteDataSourceAssociations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RouteDataSourceAssociations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RouteDataSourceAssociations_Stream_Cursor_Value_Input = {
  communities?: InputMaybe<Scalars['_text']>;
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type RouteDataSourceAssociations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RouteDataSourceAssociations_Set_Input>;
  where: RouteDataSourceAssociations_Bool_Exp;
};

/** columns and relationships of "main.view_new_route" */
export type Routes = {
  __typename?: 'routes';
  as_path?: Maybe<Scalars['String']>;
  /** length of AS path */
  as_path_length?: Maybe<Scalars['Int']>;
  /** if bogon ASes are contained in-path return the corresponding AS and RFC */
  contains_bogon_ases?: Maybe<Scalars['String']>;
  /** total number of route-data source associations and by extension data sources */
  data_source_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** invalid RPKI ROA */
  invalid_rpki_roa?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  neighborAutonomousSystem?: Maybe<AutonomousSystems>;
  /** An object relationship */
  originAutonomousSystem?: Maybe<AutonomousSystems>;
  /** An object relationship */
  prefix?: Maybe<Prefixes>;
  /** An array relationship */
  routeDataSourceAssociations: Array<RouteDataSourceAssociations>;
  /** An aggregate relationship */
  routeDataSourceAssociations_aggregate: RouteDataSourceAssociations_Aggregate;
  rpki_status?: Maybe<Scalars['String']>;
  time_inserted?: Maybe<Scalars['timestamptz']>;
  /** get the latest BGP update time of a route based on route-data source associations */
  time_updated?: Maybe<Scalars['timestamptz']>;
  /** valid RPKI ROA */
  valid_rpki_roa?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "main.view_new_route" */
export type RoutesRouteDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_route" */
export type RoutesRouteDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};

/** aggregated selection of "main.view_new_route" */
export type Routes_Aggregate = {
  __typename?: 'routes_aggregate';
  aggregate?: Maybe<Routes_Aggregate_Fields>;
  nodes: Array<Routes>;
};

export type Routes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Routes_Aggregate_Bool_Exp_Count>;
};

export type Routes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Routes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Routes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_route" */
export type Routes_Aggregate_Fields = {
  __typename?: 'routes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Routes_Max_Fields>;
  min?: Maybe<Routes_Min_Fields>;
};


/** aggregate fields of "main.view_new_route" */
export type Routes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Routes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_route" */
export type Routes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Routes_Max_Order_By>;
  min?: InputMaybe<Routes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_route" */
export type Routes_Arr_Rel_Insert_Input = {
  data: Array<Routes_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_route". All fields are combined with a logical 'AND'. */
export type Routes_Bool_Exp = {
  _and?: InputMaybe<Array<Routes_Bool_Exp>>;
  _not?: InputMaybe<Routes_Bool_Exp>;
  _or?: InputMaybe<Array<Routes_Bool_Exp>>;
  as_path?: InputMaybe<String_Comparison_Exp>;
  as_path_length?: InputMaybe<Int_Comparison_Exp>;
  contains_bogon_ases?: InputMaybe<String_Comparison_Exp>;
  data_source_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invalid_rpki_roa?: InputMaybe<Uuid_Comparison_Exp>;
  neighborAutonomousSystem?: InputMaybe<AutonomousSystems_Bool_Exp>;
  originAutonomousSystem?: InputMaybe<AutonomousSystems_Bool_Exp>;
  prefix?: InputMaybe<Prefixes_Bool_Exp>;
  routeDataSourceAssociations?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
  routeDataSourceAssociations_aggregate?: InputMaybe<RouteDataSourceAssociations_Aggregate_Bool_Exp>;
  rpki_status?: InputMaybe<String_Comparison_Exp>;
  time_inserted?: InputMaybe<Timestamptz_Comparison_Exp>;
  time_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_rpki_roa?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "main.view_new_route" */
export type Routes_Insert_Input = {
  as_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  neighborAutonomousSystem?: InputMaybe<AutonomousSystems_Obj_Rel_Insert_Input>;
  neighbor_autonomous_system_id?: InputMaybe<Scalars['uuid']>;
  originAutonomousSystem?: InputMaybe<AutonomousSystems_Obj_Rel_Insert_Input>;
  origin_autonomous_system_id?: InputMaybe<Scalars['uuid']>;
  prefix?: InputMaybe<Prefixes_Obj_Rel_Insert_Input>;
  prefix_id?: InputMaybe<Scalars['uuid']>;
  routeDataSourceAssociations?: InputMaybe<RouteDataSourceAssociations_Arr_Rel_Insert_Input>;
  rpki_status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Routes_Max_Fields = {
  __typename?: 'routes_max_fields';
  as_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rpki_status?: Maybe<Scalars['String']>;
  time_inserted?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "main.view_new_route" */
export type Routes_Max_Order_By = {
  as_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rpki_status?: InputMaybe<Order_By>;
  time_inserted?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Routes_Min_Fields = {
  __typename?: 'routes_min_fields';
  as_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rpki_status?: Maybe<Scalars['String']>;
  time_inserted?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "main.view_new_route" */
export type Routes_Min_Order_By = {
  as_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rpki_status?: InputMaybe<Order_By>;
  time_inserted?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_route" */
export type Routes_Mutation_Response = {
  __typename?: 'routes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Routes>;
};

/** input type for inserting object relation for remote table "main.view_new_route" */
export type Routes_Obj_Rel_Insert_Input = {
  data: Routes_Insert_Input;
};

/** Ordering options when selecting data from "main.view_new_route". */
export type Routes_Order_By = {
  as_path?: InputMaybe<Order_By>;
  as_path_length?: InputMaybe<Order_By>;
  contains_bogon_ases?: InputMaybe<Order_By>;
  data_source_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invalid_rpki_roa?: InputMaybe<Order_By>;
  neighborAutonomousSystem?: InputMaybe<AutonomousSystems_Order_By>;
  originAutonomousSystem?: InputMaybe<AutonomousSystems_Order_By>;
  prefix?: InputMaybe<Prefixes_Order_By>;
  routeDataSourceAssociations_aggregate?: InputMaybe<RouteDataSourceAssociations_Aggregate_Order_By>;
  rpki_status?: InputMaybe<Order_By>;
  time_inserted?: InputMaybe<Order_By>;
  time_updated?: InputMaybe<Order_By>;
  valid_rpki_roa?: InputMaybe<Order_By>;
};

/** select columns of table "main.view_new_route" */
export enum Routes_Select_Column {
  /** column name */
  AsPath = 'as_path',
  /** column name */
  Id = 'id',
  /** column name */
  RpkiStatus = 'rpki_status',
  /** column name */
  TimeInserted = 'time_inserted'
}

/** Streaming cursor of the table "routes" */
export type Routes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Routes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Routes_Stream_Cursor_Value_Input = {
  as_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  rpki_status?: InputMaybe<Scalars['String']>;
  time_inserted?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations = {
  __typename?: 'rpkiROADataSourceAssociations';
  /** An object relationship */
  dataSource?: Maybe<DataSources>;
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  rpkiROA?: Maybe<RpkiRoAs>;
};

/** aggregated selection of "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Aggregate = {
  __typename?: 'rpkiROADataSourceAssociations_aggregate';
  aggregate?: Maybe<RpkiRoaDataSourceAssociations_Aggregate_Fields>;
  nodes: Array<RpkiRoaDataSourceAssociations>;
};

export type RpkiRoaDataSourceAssociations_Aggregate_Bool_Exp = {
  count?: InputMaybe<RpkiRoaDataSourceAssociations_Aggregate_Bool_Exp_Count>;
};

export type RpkiRoaDataSourceAssociations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Aggregate_Fields = {
  __typename?: 'rpkiROADataSourceAssociations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RpkiRoaDataSourceAssociations_Max_Fields>;
  min?: Maybe<RpkiRoaDataSourceAssociations_Min_Fields>;
};


/** aggregate fields of "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<RpkiRoaDataSourceAssociations_Max_Order_By>;
  min?: InputMaybe<RpkiRoaDataSourceAssociations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Arr_Rel_Insert_Input = {
  data: Array<RpkiRoaDataSourceAssociations_Insert_Input>;
};

/** Boolean expression to filter rows from the table "main.view_new_rpki_roa_data_source_assoc". All fields are combined with a logical 'AND'. */
export type RpkiRoaDataSourceAssociations_Bool_Exp = {
  _and?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Bool_Exp>>;
  _not?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
  _or?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Bool_Exp>>;
  dataSource?: InputMaybe<DataSources_Bool_Exp>;
  data_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rpkiROA?: InputMaybe<RpkiRoAs_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Insert_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  rpkiROA?: InputMaybe<RpkiRoAs_Obj_Rel_Insert_Input>;
  rpki_roa_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type RpkiRoaDataSourceAssociations_Max_Fields = {
  __typename?: 'rpkiROADataSourceAssociations_max_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Max_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type RpkiRoaDataSourceAssociations_Min_Fields = {
  __typename?: 'rpkiROADataSourceAssociations_min_fields';
  data_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Min_Order_By = {
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Mutation_Response = {
  __typename?: 'rpkiROADataSourceAssociations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RpkiRoaDataSourceAssociations>;
};

/** Ordering options when selecting data from "main.view_new_rpki_roa_data_source_assoc". */
export type RpkiRoaDataSourceAssociations_Order_By = {
  dataSource?: InputMaybe<DataSources_Order_By>;
  data_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rpkiROA?: InputMaybe<RpkiRoAs_Order_By>;
};

/** select columns of table "main.view_new_rpki_roa_data_source_assoc" */
export enum RpkiRoaDataSourceAssociations_Select_Column {
  /** column name */
  DataTime = 'data_time',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "main.view_new_rpki_roa_data_source_assoc" */
export type RpkiRoaDataSourceAssociations_Set_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "rpkiROADataSourceAssociations" */
export type RpkiRoaDataSourceAssociations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RpkiRoaDataSourceAssociations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RpkiRoaDataSourceAssociations_Stream_Cursor_Value_Input = {
  data_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type RpkiRoaDataSourceAssociations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RpkiRoaDataSourceAssociations_Set_Input>;
  where: RpkiRoaDataSourceAssociations_Bool_Exp;
};

/** columns and relationships of "main.view_new_rpki_roa" */
export type RpkiRoAs = {
  __typename?: 'rpkiROAs';
  autonomous_system_number?: Maybe<Scalars['bigint']>;
  /** total number of RPKI ROA-data source associations and by extension data sources */
  data_source_count?: Maybe<Scalars['Int']>;
  expiry_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  /** get the latest time of an RPKI ROA based on RPKI ROA-data source associations */
  latest_time?: Maybe<Scalars['timestamptz']>;
  mask_length?: Maybe<Scalars['Int']>;
  max_length?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['cidr']>;
  /** An array relationship */
  rpkiROADataSourceAssociations: Array<RpkiRoaDataSourceAssociations>;
  /** An aggregate relationship */
  rpkiROADataSourceAssociations_aggregate: RpkiRoaDataSourceAssociations_Aggregate;
};


/** columns and relationships of "main.view_new_rpki_roa" */
export type RpkiRoAsRpkiRoaDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


/** columns and relationships of "main.view_new_rpki_roa" */
export type RpkiRoAsRpkiRoaDataSourceAssociations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};

/** aggregated selection of "main.view_new_rpki_roa" */
export type RpkiRoAs_Aggregate = {
  __typename?: 'rpkiROAs_aggregate';
  aggregate?: Maybe<RpkiRoAs_Aggregate_Fields>;
  nodes: Array<RpkiRoAs>;
};

/** aggregate fields of "main.view_new_rpki_roa" */
export type RpkiRoAs_Aggregate_Fields = {
  __typename?: 'rpkiROAs_aggregate_fields';
  avg?: Maybe<RpkiRoAs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RpkiRoAs_Max_Fields>;
  min?: Maybe<RpkiRoAs_Min_Fields>;
  stddev?: Maybe<RpkiRoAs_Stddev_Fields>;
  stddev_pop?: Maybe<RpkiRoAs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RpkiRoAs_Stddev_Samp_Fields>;
  sum?: Maybe<RpkiRoAs_Sum_Fields>;
  var_pop?: Maybe<RpkiRoAs_Var_Pop_Fields>;
  var_samp?: Maybe<RpkiRoAs_Var_Samp_Fields>;
  variance?: Maybe<RpkiRoAs_Variance_Fields>;
};


/** aggregate fields of "main.view_new_rpki_roa" */
export type RpkiRoAs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RpkiRoAs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RpkiRoAs_Avg_Fields = {
  __typename?: 'rpkiROAs_avg_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "main.view_new_rpki_roa". All fields are combined with a logical 'AND'. */
export type RpkiRoAs_Bool_Exp = {
  _and?: InputMaybe<Array<RpkiRoAs_Bool_Exp>>;
  _not?: InputMaybe<RpkiRoAs_Bool_Exp>;
  _or?: InputMaybe<Array<RpkiRoAs_Bool_Exp>>;
  autonomous_system_number?: InputMaybe<Bigint_Comparison_Exp>;
  data_source_count?: InputMaybe<Int_Comparison_Exp>;
  expiry_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ip_version?: InputMaybe<Int_Comparison_Exp>;
  latest_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  mask_length?: InputMaybe<Int_Comparison_Exp>;
  max_length?: InputMaybe<Int_Comparison_Exp>;
  network?: InputMaybe<Cidr_Comparison_Exp>;
  rpkiROADataSourceAssociations?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
  rpkiROADataSourceAssociations_aggregate?: InputMaybe<RpkiRoaDataSourceAssociations_Aggregate_Bool_Exp>;
};

/** input type for inserting data into table "main.view_new_rpki_roa" */
export type RpkiRoAs_Insert_Input = {
  autonomous_system_number?: InputMaybe<Scalars['bigint']>;
  expiry_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  max_length?: InputMaybe<Scalars['Int']>;
  network?: InputMaybe<Scalars['cidr']>;
  rpkiROADataSourceAssociations?: InputMaybe<RpkiRoaDataSourceAssociations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type RpkiRoAs_Max_Fields = {
  __typename?: 'rpkiROAs_max_fields';
  autonomous_system_number?: Maybe<Scalars['bigint']>;
  expiry_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
  max_length?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type RpkiRoAs_Min_Fields = {
  __typename?: 'rpkiROAs_min_fields';
  autonomous_system_number?: Maybe<Scalars['bigint']>;
  expiry_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
  max_length?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "main.view_new_rpki_roa" */
export type RpkiRoAs_Mutation_Response = {
  __typename?: 'rpkiROAs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RpkiRoAs>;
};

/** input type for inserting object relation for remote table "main.view_new_rpki_roa" */
export type RpkiRoAs_Obj_Rel_Insert_Input = {
  data: RpkiRoAs_Insert_Input;
};

/** Ordering options when selecting data from "main.view_new_rpki_roa". */
export type RpkiRoAs_Order_By = {
  autonomous_system_number?: InputMaybe<Order_By>;
  data_source_count?: InputMaybe<Order_By>;
  expiry_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip_version?: InputMaybe<Order_By>;
  latest_time?: InputMaybe<Order_By>;
  mask_length?: InputMaybe<Order_By>;
  max_length?: InputMaybe<Order_By>;
  network?: InputMaybe<Order_By>;
  rpkiROADataSourceAssociations_aggregate?: InputMaybe<RpkiRoaDataSourceAssociations_Aggregate_Order_By>;
};

/** select columns of table "main.view_new_rpki_roa" */
export enum RpkiRoAs_Select_Column {
  /** column name */
  AutonomousSystemNumber = 'autonomous_system_number',
  /** column name */
  ExpiryTime = 'expiry_time',
  /** column name */
  Id = 'id',
  /** column name */
  IpVersion = 'ip_version',
  /** column name */
  MaskLength = 'mask_length',
  /** column name */
  MaxLength = 'max_length',
  /** column name */
  Network = 'network'
}

/** aggregate stddev on columns */
export type RpkiRoAs_Stddev_Fields = {
  __typename?: 'rpkiROAs_stddev_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RpkiRoAs_Stddev_Pop_Fields = {
  __typename?: 'rpkiROAs_stddev_pop_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RpkiRoAs_Stddev_Samp_Fields = {
  __typename?: 'rpkiROAs_stddev_samp_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rpkiROAs" */
export type RpkiRoAs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RpkiRoAs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RpkiRoAs_Stream_Cursor_Value_Input = {
  autonomous_system_number?: InputMaybe<Scalars['bigint']>;
  expiry_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip_version?: InputMaybe<Scalars['Int']>;
  mask_length?: InputMaybe<Scalars['Int']>;
  max_length?: InputMaybe<Scalars['Int']>;
  network?: InputMaybe<Scalars['cidr']>;
};

/** aggregate sum on columns */
export type RpkiRoAs_Sum_Fields = {
  __typename?: 'rpkiROAs_sum_fields';
  autonomous_system_number?: Maybe<Scalars['bigint']>;
  ip_version?: Maybe<Scalars['Int']>;
  mask_length?: Maybe<Scalars['Int']>;
  max_length?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type RpkiRoAs_Var_Pop_Fields = {
  __typename?: 'rpkiROAs_var_pop_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RpkiRoAs_Var_Samp_Fields = {
  __typename?: 'rpkiROAs_var_samp_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RpkiRoAs_Variance_Fields = {
  __typename?: 'rpkiROAs_variance_fields';
  autonomous_system_number?: Maybe<Scalars['Float']>;
  ip_version?: Maybe<Scalars['Float']>;
  mask_length?: Maybe<Scalars['Float']>;
  max_length?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "conf.view_alert_receiver_type" */
  alertReceiverTypes: Array<AlertReceiverTypes>;
  /** fetch data from the table in a streaming manner: "conf.view_alert_receiver_type" */
  alertReceiverTypes_stream: Array<AlertReceiverTypes>;
  /** fetch data from the table: "conf.view_alert_severity" */
  alertSeverities: Array<AlertSeverities>;
  /** fetch data from the table in a streaming manner: "conf.view_alert_severity" */
  alertSeverities_stream: Array<AlertSeverities>;
  /** An array relationship */
  alertSubscriptions: Array<AlertSubscriptions>;
  /** fetch aggregated fields from the table: "conf.view_alert_subscription" */
  alertSubscriptionsAggregate: AlertSubscriptions_Aggregate;
  /** fetch data from the table in a streaming manner: "conf.view_alert_subscription" */
  alertSubscriptions_stream: Array<AlertSubscriptions>;
  /** fetch data from the table: "conf.view_alert_type" */
  alertTypes: Array<AlertTypes>;
  /** fetch data from the table in a streaming manner: "conf.view_alert_type" */
  alertTypes_stream: Array<AlertTypes>;
  /** fetch data from the table: "main.view_alert" */
  alerts: Array<Alerts>;
  /** fetch aggregated fields from the table: "main.view_alert" */
  alertsAggregate: Alerts_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_alert" */
  alerts_stream: Array<Alerts>;
  /** An array relationship */
  autonomousSystemDataSourceAssociations: Array<AutonomousSystemDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_autonomous_system_data_source_assoc" */
  autonomousSystemDataSourceAssociationsAggregate: AutonomousSystemDataSourceAssociations_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_autonomous_system_data_source_assoc" */
  autonomousSystemDataSourceAssociations_stream: Array<AutonomousSystemDataSourceAssociations>;
  /** fetch data from the table: "main.view_new_autonomous_system" */
  autonomousSystems: Array<AutonomousSystems>;
  /** fetch aggregated fields from the table: "main.view_new_autonomous_system" */
  autonomousSystemsAggregate: AutonomousSystems_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_autonomous_system" */
  autonomousSystems_stream: Array<AutonomousSystems>;
  /** fetch data from the table: "main.view_avg_bgp_update_rate" */
  avgBGPUpdateRate: Array<AvgBgpUpdateRate>;
  /** fetch data from the table in a streaming manner: "main.view_avg_bgp_update_rate" */
  avgBGPUpdateRate_stream: Array<AvgBgpUpdateRate>;
  /** fetch data from the table: "main.view_bgp_update_rate" */
  bgpUpdateRate: Array<BgpUpdateRate>;
  /** fetch data from the table in a streaming manner: "main.view_bgp_update_rate" */
  bgpUpdateRate_stream: Array<BgpUpdateRate>;
  /** fetch data from the table: "conf.view_new_autonomous_system" */
  configuredAutonomousSystems: Array<ConfiguredAutonomousSystems>;
  /** fetch data from the table in a streaming manner: "conf.view_new_autonomous_system" */
  configuredAutonomousSystems_stream: Array<ConfiguredAutonomousSystems>;
  /** fetch data from the table: "conf.view_new_data_adapter" */
  configuredDataServices: Array<ConfiguredDataServices>;
  /** fetch data from the table in a streaming manner: "conf.view_new_data_adapter" */
  configuredDataServices_stream: Array<ConfiguredDataServices>;
  /** fetch data from the table: "conf.view_new_data_source" */
  configuredDataSources: Array<ConfiguredDataSources>;
  /** fetch data from the table in a streaming manner: "conf.view_new_data_source" */
  configuredDataSources_stream: Array<ConfiguredDataSources>;
  /** fetch data from the table: "conf.view_new_prefix" */
  configuredPrefixes: Array<ConfiguredPrefixes>;
  /** fetch data from the table in a streaming manner: "conf.view_new_prefix" */
  configuredPrefixes_stream: Array<ConfiguredPrefixes>;
  /** An array relationship */
  dataSourceMetadatas: Array<DataSourceMetadatas>;
  /** fetch aggregated fields from the table: "main.view_data_source_metadata" */
  dataSourceMetadatasAggregate: DataSourceMetadatas_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_data_source_metadata" */
  dataSourceMetadatas_stream: Array<DataSourceMetadatas>;
  /** fetch data from the table: "main.view_new_data_source" */
  dataSources: Array<DataSources>;
  /** fetch aggregated fields from the table: "main.view_new_data_source" */
  dataSourcesAggregate: DataSources_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_data_source" */
  dataSources_stream: Array<DataSources>;
  /** fetch data from the table: "conf.view_new_manual_autonomous_system" */
  manuallyConfiguredAutonomousSystems: Array<ManuallyConfiguredAutonomousSystems>;
  /** fetch data from the table in a streaming manner: "conf.view_new_manual_autonomous_system" */
  manuallyConfiguredAutonomousSystems_stream: Array<ManuallyConfiguredAutonomousSystems>;
  /** fetch data from the table: "conf.view_new_manual_data_source" */
  manuallyConfiguredDataSources: Array<ManuallyConfiguredDataSources>;
  /** fetch data from the table in a streaming manner: "conf.view_new_manual_data_source" */
  manuallyConfiguredDataSources_stream: Array<ManuallyConfiguredDataSources>;
  /** fetch data from the table: "conf.view_new_manual_prefix" */
  manuallyConfiguredPrefixes: Array<ManuallyConfiguredPrefixes>;
  /** fetch data from the table in a streaming manner: "conf.view_new_manual_prefix" */
  manuallyConfiguredPrefixes_stream: Array<ManuallyConfiguredPrefixes>;
  /** fetch data from the table: "main.view_max_bgp_update_rate" */
  maxBGPUpdateRate: Array<MaxBgpUpdateRate>;
  /** fetch data from the table in a streaming manner: "main.view_max_bgp_update_rate" */
  maxBGPUpdateRate_stream: Array<MaxBgpUpdateRate>;
  /** An array relationship */
  peeringDataSourceAssociations: Array<PeeringDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_peering_link_data_source_assoc" */
  peeringDataSourceAssociationsAggregate: PeeringDataSourceAssociations_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_peering_link_data_source_assoc" */
  peeringDataSourceAssociations_stream: Array<PeeringDataSourceAssociations>;
  /** fetch data from the table: "main.view_new_peering_link" */
  peerings: Array<Peerings>;
  /** fetch aggregated fields from the table: "main.view_new_peering_link" */
  peeringsAggregate: Peerings_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_peering_link" */
  peerings_stream: Array<Peerings>;
  /** An array relationship */
  prefixDataSourceAssociations: Array<PrefixDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_prefix_data_source_assoc" */
  prefixDataSourceAssociationsAggregate: PrefixDataSourceAssociations_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_prefix_data_source_assoc" */
  prefixDataSourceAssociations_stream: Array<PrefixDataSourceAssociations>;
  /** fetch data from the table: "main.view_new_prefix" */
  prefixes: Array<Prefixes>;
  /** fetch aggregated fields from the table: "main.view_new_prefix" */
  prefixesAggregate: Prefixes_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_prefix" */
  prefixes_stream: Array<Prefixes>;
  /** An array relationship */
  routeDataSourceAssociations: Array<RouteDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_route_data_source_assoc" */
  routeDataSourceAssociationsAggregate: RouteDataSourceAssociations_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_route_data_source_assoc" */
  routeDataSourceAssociations_stream: Array<RouteDataSourceAssociations>;
  /** An array relationship */
  routes: Array<Routes>;
  /** fetch aggregated fields from the table: "main.view_new_route" */
  routesAggregate: Routes_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_route" */
  routes_stream: Array<Routes>;
  /** An array relationship */
  rpkiROADataSourceAssociations: Array<RpkiRoaDataSourceAssociations>;
  /** fetch aggregated fields from the table: "main.view_new_rpki_roa_data_source_assoc" */
  rpkiROADataSourceAssociationsAggregate: RpkiRoaDataSourceAssociations_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_rpki_roa_data_source_assoc" */
  rpkiROADataSourceAssociations_stream: Array<RpkiRoaDataSourceAssociations>;
  /** fetch data from the table: "main.view_new_rpki_roa" */
  rpkiROAs: Array<RpkiRoAs>;
  /** fetch aggregated fields from the table: "main.view_new_rpki_roa" */
  rpkiROAsAggregate: RpkiRoAs_Aggregate;
  /** fetch data from the table in a streaming manner: "main.view_new_rpki_roa" */
  rpkiROAs_stream: Array<RpkiRoAs>;
};


export type Subscription_RootAlertReceiverTypesArgs = {
  distinct_on?: InputMaybe<Array<AlertReceiverTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertReceiverTypes_Order_By>>;
  where?: InputMaybe<AlertReceiverTypes_Bool_Exp>;
};


export type Subscription_RootAlertReceiverTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AlertReceiverTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<AlertReceiverTypes_Bool_Exp>;
};


export type Subscription_RootAlertSeveritiesArgs = {
  distinct_on?: InputMaybe<Array<AlertSeverities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSeverities_Order_By>>;
  where?: InputMaybe<AlertSeverities_Bool_Exp>;
};


export type Subscription_RootAlertSeverities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AlertSeverities_Stream_Cursor_Input>>;
  where?: InputMaybe<AlertSeverities_Bool_Exp>;
};


export type Subscription_RootAlertSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


export type Subscription_RootAlertSubscriptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertSubscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertSubscriptions_Order_By>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


export type Subscription_RootAlertSubscriptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AlertSubscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<AlertSubscriptions_Bool_Exp>;
};


export type Subscription_RootAlertTypesArgs = {
  distinct_on?: InputMaybe<Array<AlertTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertTypes_Order_By>>;
  where?: InputMaybe<AlertTypes_Bool_Exp>;
};


export type Subscription_RootAlertTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AlertTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<AlertTypes_Bool_Exp>;
};


export type Subscription_RootAlertsArgs = {
  distinct_on?: InputMaybe<Array<Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alerts_Order_By>>;
  where?: InputMaybe<Alerts_Bool_Exp>;
};


export type Subscription_RootAlertsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Alerts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alerts_Order_By>>;
  where?: InputMaybe<Alerts_Bool_Exp>;
};


export type Subscription_RootAlerts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alerts_Stream_Cursor_Input>>;
  where?: InputMaybe<Alerts_Bool_Exp>;
};


export type Subscription_RootAutonomousSystemDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootAutonomousSystemDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystemDataSourceAssociations_Order_By>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootAutonomousSystemDataSourceAssociations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AutonomousSystemDataSourceAssociations_Stream_Cursor_Input>>;
  where?: InputMaybe<AutonomousSystemDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystems_Order_By>>;
  where?: InputMaybe<AutonomousSystems_Bool_Exp>;
};


export type Subscription_RootAutonomousSystemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AutonomousSystems_Order_By>>;
  where?: InputMaybe<AutonomousSystems_Bool_Exp>;
};


export type Subscription_RootAutonomousSystems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AutonomousSystems_Stream_Cursor_Input>>;
  where?: InputMaybe<AutonomousSystems_Bool_Exp>;
};


export type Subscription_RootAvgBgpUpdateRateArgs = {
  distinct_on?: InputMaybe<Array<AvgBgpUpdateRate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AvgBgpUpdateRate_Order_By>>;
  where?: InputMaybe<AvgBgpUpdateRate_Bool_Exp>;
};


export type Subscription_RootAvgBgpUpdateRate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AvgBgpUpdateRate_Stream_Cursor_Input>>;
  where?: InputMaybe<AvgBgpUpdateRate_Bool_Exp>;
};


export type Subscription_RootBgpUpdateRateArgs = {
  distinct_on?: InputMaybe<Array<BgpUpdateRate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BgpUpdateRate_Order_By>>;
  where?: InputMaybe<BgpUpdateRate_Bool_Exp>;
};


export type Subscription_RootBgpUpdateRate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BgpUpdateRate_Stream_Cursor_Input>>;
  where?: InputMaybe<BgpUpdateRate_Bool_Exp>;
};


export type Subscription_RootConfiguredAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredAutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredAutonomousSystems_Order_By>>;
  where?: InputMaybe<ConfiguredAutonomousSystems_Bool_Exp>;
};


export type Subscription_RootConfiguredAutonomousSystems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConfiguredAutonomousSystems_Stream_Cursor_Input>>;
  where?: InputMaybe<ConfiguredAutonomousSystems_Bool_Exp>;
};


export type Subscription_RootConfiguredDataServicesArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredDataServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredDataServices_Order_By>>;
  where?: InputMaybe<ConfiguredDataServices_Bool_Exp>;
};


export type Subscription_RootConfiguredDataServices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConfiguredDataServices_Stream_Cursor_Input>>;
  where?: InputMaybe<ConfiguredDataServices_Bool_Exp>;
};


export type Subscription_RootConfiguredDataSourcesArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredDataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredDataSources_Order_By>>;
  where?: InputMaybe<ConfiguredDataSources_Bool_Exp>;
};


export type Subscription_RootConfiguredDataSources_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConfiguredDataSources_Stream_Cursor_Input>>;
  where?: InputMaybe<ConfiguredDataSources_Bool_Exp>;
};


export type Subscription_RootConfiguredPrefixesArgs = {
  distinct_on?: InputMaybe<Array<ConfiguredPrefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConfiguredPrefixes_Order_By>>;
  where?: InputMaybe<ConfiguredPrefixes_Bool_Exp>;
};


export type Subscription_RootConfiguredPrefixes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConfiguredPrefixes_Stream_Cursor_Input>>;
  where?: InputMaybe<ConfiguredPrefixes_Bool_Exp>;
};


export type Subscription_RootDataSourceMetadatasArgs = {
  distinct_on?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSourceMetadatas_Order_By>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


export type Subscription_RootDataSourceMetadatasAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataSourceMetadatas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSourceMetadatas_Order_By>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


export type Subscription_RootDataSourceMetadatas_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DataSourceMetadatas_Stream_Cursor_Input>>;
  where?: InputMaybe<DataSourceMetadatas_Bool_Exp>;
};


export type Subscription_RootDataSourcesArgs = {
  distinct_on?: InputMaybe<Array<DataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSources_Order_By>>;
  where?: InputMaybe<DataSources_Bool_Exp>;
};


export type Subscription_RootDataSourcesAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataSources_Order_By>>;
  where?: InputMaybe<DataSources_Bool_Exp>;
};


export type Subscription_RootDataSources_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DataSources_Stream_Cursor_Input>>;
  where?: InputMaybe<DataSources_Bool_Exp>;
};


export type Subscription_RootManuallyConfiguredAutonomousSystemsArgs = {
  distinct_on?: InputMaybe<Array<ManuallyConfiguredAutonomousSystems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManuallyConfiguredAutonomousSystems_Order_By>>;
  where?: InputMaybe<ManuallyConfiguredAutonomousSystems_Bool_Exp>;
};


export type Subscription_RootManuallyConfiguredAutonomousSystems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManuallyConfiguredAutonomousSystems_Stream_Cursor_Input>>;
  where?: InputMaybe<ManuallyConfiguredAutonomousSystems_Bool_Exp>;
};


export type Subscription_RootManuallyConfiguredDataSourcesArgs = {
  distinct_on?: InputMaybe<Array<ManuallyConfiguredDataSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManuallyConfiguredDataSources_Order_By>>;
  where?: InputMaybe<ManuallyConfiguredDataSources_Bool_Exp>;
};


export type Subscription_RootManuallyConfiguredDataSources_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManuallyConfiguredDataSources_Stream_Cursor_Input>>;
  where?: InputMaybe<ManuallyConfiguredDataSources_Bool_Exp>;
};


export type Subscription_RootManuallyConfiguredPrefixesArgs = {
  distinct_on?: InputMaybe<Array<ManuallyConfiguredPrefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManuallyConfiguredPrefixes_Order_By>>;
  where?: InputMaybe<ManuallyConfiguredPrefixes_Bool_Exp>;
};


export type Subscription_RootManuallyConfiguredPrefixes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManuallyConfiguredPrefixes_Stream_Cursor_Input>>;
  where?: InputMaybe<ManuallyConfiguredPrefixes_Bool_Exp>;
};


export type Subscription_RootMaxBgpUpdateRateArgs = {
  distinct_on?: InputMaybe<Array<MaxBgpUpdateRate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MaxBgpUpdateRate_Order_By>>;
  where?: InputMaybe<MaxBgpUpdateRate_Bool_Exp>;
};


export type Subscription_RootMaxBgpUpdateRate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MaxBgpUpdateRate_Stream_Cursor_Input>>;
  where?: InputMaybe<MaxBgpUpdateRate_Bool_Exp>;
};


export type Subscription_RootPeeringDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootPeeringDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PeeringDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PeeringDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootPeeringDataSourceAssociations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PeeringDataSourceAssociations_Stream_Cursor_Input>>;
  where?: InputMaybe<PeeringDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootPeeringsArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


export type Subscription_RootPeeringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Peerings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Peerings_Order_By>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


export type Subscription_RootPeerings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Peerings_Stream_Cursor_Input>>;
  where?: InputMaybe<Peerings_Bool_Exp>;
};


export type Subscription_RootPrefixDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootPrefixDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PrefixDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrefixDataSourceAssociations_Order_By>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootPrefixDataSourceAssociations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PrefixDataSourceAssociations_Stream_Cursor_Input>>;
  where?: InputMaybe<PrefixDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootPrefixesArgs = {
  distinct_on?: InputMaybe<Array<Prefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prefixes_Order_By>>;
  where?: InputMaybe<Prefixes_Bool_Exp>;
};


export type Subscription_RootPrefixesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Prefixes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prefixes_Order_By>>;
  where?: InputMaybe<Prefixes_Bool_Exp>;
};


export type Subscription_RootPrefixes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prefixes_Stream_Cursor_Input>>;
  where?: InputMaybe<Prefixes_Bool_Exp>;
};


export type Subscription_RootRouteDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootRouteDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RouteDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RouteDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootRouteDataSourceAssociations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RouteDataSourceAssociations_Stream_Cursor_Input>>;
  where?: InputMaybe<RouteDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootRoutesArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


export type Subscription_RootRoutesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Routes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Routes_Order_By>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


export type Subscription_RootRoutes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Routes_Stream_Cursor_Input>>;
  where?: InputMaybe<Routes_Bool_Exp>;
};


export type Subscription_RootRpkiRoaDataSourceAssociationsArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootRpkiRoaDataSourceAssociationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoaDataSourceAssociations_Order_By>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootRpkiRoaDataSourceAssociations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RpkiRoaDataSourceAssociations_Stream_Cursor_Input>>;
  where?: InputMaybe<RpkiRoaDataSourceAssociations_Bool_Exp>;
};


export type Subscription_RootRpkiRoAsArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoAs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoAs_Order_By>>;
  where?: InputMaybe<RpkiRoAs_Bool_Exp>;
};


export type Subscription_RootRpkiRoAsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RpkiRoAs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RpkiRoAs_Order_By>>;
  where?: InputMaybe<RpkiRoAs_Bool_Exp>;
};


export type Subscription_RootRpkiRoAs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RpkiRoAs_Stream_Cursor_Input>>;
  where?: InputMaybe<RpkiRoAs_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type InsertManuallyConfiguredAutonomousSystemMutationVariables = Exact<{
  asn: Scalars['bigint'];
}>;


export type InsertManuallyConfiguredAutonomousSystemMutation = { __typename?: 'mutation_root', insertManuallyConfiguredAutonomousSystem?: { __typename?: 'manuallyConfiguredAutonomousSystems', reject?: boolean | null } | null };

export type InsertManuallyConfiguredAutonomousSystemsMutationVariables = Exact<{
  asns: Array<ManuallyConfiguredAutonomousSystems_Insert_Input> | ManuallyConfiguredAutonomousSystems_Insert_Input;
}>;


export type InsertManuallyConfiguredAutonomousSystemsMutation = { __typename?: 'mutation_root', insertManuallyConfiguredAutonomousSystems?: { __typename?: 'manuallyConfiguredAutonomousSystems_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'manuallyConfiguredAutonomousSystems', number?: any | null }> } | null };

export type DeleteManuallyConfiguredAutonomousSystemsMutationVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type DeleteManuallyConfiguredAutonomousSystemsMutation = { __typename?: 'mutation_root', deleteManuallyConfiguredAutonomousSystems?: { __typename?: 'manuallyConfiguredAutonomousSystems_mutation_response', affected_rows: number } | null };

export type EnableConfiguredDataServiceMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
  dataServiceName: Scalars['String'];
}>;


export type EnableConfiguredDataServiceMutation = { __typename?: 'mutation_root', updateConfiguredDataService?: { __typename?: 'configuredDataServices_mutation_response', returning: Array<{ __typename?: 'configuredDataServices', enabled?: boolean | null }> } | null };

export type UpdateConfiguredDataServiceFilterOnAsMutationVariables = Exact<{
  filterOnAS: Scalars['String'];
  dataServiceName: Scalars['String'];
}>;


export type UpdateConfiguredDataServiceFilterOnAsMutation = { __typename?: 'mutation_root', updateConfiguredDataService?: { __typename?: 'configuredDataServices_mutation_response', returning: Array<{ __typename?: 'configuredDataServices', filter_on_autonomous_system?: string | null }> } | null };

export type InsertManuallyConfiguredPrefixMutationVariables = Exact<{
  prefix: Scalars['cidr'];
}>;


export type InsertManuallyConfiguredPrefixMutation = { __typename?: 'mutation_root', insertManuallyConfiguredPrefix?: { __typename?: 'manuallyConfiguredPrefixes', network?: any | null, reject?: boolean | null } | null };

export type InsertManuallyConfiguredPrefixesMutationVariables = Exact<{
  prefixes: Array<ManuallyConfiguredPrefixes_Insert_Input> | ManuallyConfiguredPrefixes_Insert_Input;
}>;


export type InsertManuallyConfiguredPrefixesMutation = { __typename?: 'mutation_root', insertManuallyConfiguredPrefixes?: { __typename?: 'manuallyConfiguredPrefixes_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'manuallyConfiguredPrefixes', network?: any | null }> } | null };

export type DeleteManuallyConfiguredPrefixesMutationVariables = Exact<{
  prefixes?: InputMaybe<Array<Scalars['cidr']> | Scalars['cidr']>;
}>;


export type DeleteManuallyConfiguredPrefixesMutation = { __typename?: 'mutation_root', deleteManuallyConfiguredPrefixes?: { __typename?: 'manuallyConfiguredPrefixes_mutation_response', affected_rows: number } | null };

export type InsertAlertRuleMutationVariables = Exact<{
  object: AlertSubscriptions_Insert_Input;
}>;


export type InsertAlertRuleMutation = { __typename?: 'mutation_root', insertAlertSubscription?: { __typename?: 'alertSubscriptions', name?: string | null } | null };

export type DeleteAlertRulesMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type DeleteAlertRulesMutation = { __typename?: 'mutation_root', deleteAlertSubscriptions?: { __typename?: 'alertSubscriptions_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'alertSubscriptions', name?: string | null }> } | null };

export type UpdateAlertRuleMutationVariables = Exact<{
  id: Scalars['uuid'];
  description: Scalars['String'];
  name: Scalars['String'];
  receiver_endpoint: Scalars['String'];
  receiver_type: Scalars['String'];
  vars: Scalars['jsonb'];
}>;


export type UpdateAlertRuleMutation = { __typename?: 'mutation_root', updateAlertSubscription?: { __typename?: 'alertSubscriptions_mutation_response', returning: Array<{ __typename?: 'alertSubscriptions', name?: string | null }> } | null };

export type DeleteAlertsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type DeleteAlertsMutation = { __typename?: 'mutation_root', deleteAlerts?: { __typename?: 'alerts_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'alerts', name?: string | null }> } | null };

export type InsertAlertRulesMutationVariables = Exact<{
  alerts: Array<AlertSubscriptions_Insert_Input> | AlertSubscriptions_Insert_Input;
}>;


export type InsertAlertRulesMutation = { __typename?: 'mutation_root', insertAlertSubscriptions?: { __typename?: 'alertSubscriptions_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'alertSubscriptions', name?: string | null }> } | null };

export type PrefixesFromAsnQueryVariables = Exact<{
  asn: Scalars['bigint'];
  prefixesWhere?: InputMaybe<Prefixes_Bool_Exp>;
}>;


export type PrefixesFromAsnQuery = { __typename?: 'query_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null }>, rpkiROAs: Array<{ __typename?: 'rpkiROAs', network?: any | null }> };

export type RpkiPrefixesFromAsnQueryVariables = Exact<{
  asn: Scalars['bigint'];
}>;


export type RpkiPrefixesFromAsnQuery = { __typename?: 'query_root', rpkiROAs: Array<{ __typename?: 'rpkiROAs', network?: any | null }> };

export type PrefixesFromAsNnotConfiguredQueryVariables = Exact<{
  asn: Scalars['bigint'];
  prefixesWhere?: InputMaybe<Prefixes_Bool_Exp>;
  prefixArray?: InputMaybe<Array<Scalars['cidr']> | Scalars['cidr']>;
}>;


export type PrefixesFromAsNnotConfiguredQuery = { __typename?: 'query_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null }>, rpkiROAs: Array<{ __typename?: 'rpkiROAs', network?: any | null }> };

export type PolledBgpNeighborsQueryVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type PolledBgpNeighborsQuery = { __typename?: 'query_root', peerings: Array<{ __typename?: 'peerings', leftAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, rightAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null }> };

export type ConfAlertsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfAlertsQueryQuery = { __typename?: 'query_root', alertSubscriptions: Array<{ __typename?: 'alertSubscriptions', name?: string | null, type?: string | null, vars?: any | null, activation_time?: any | null, id?: any | null, receiver_type?: string | null, receiver_endpoint?: string | null, query?: string | null, fire_alert_regex?: string | null, description?: string | null, severity?: string | null }> };

export type ConfAlertsQueryFilteredQueryVariables = Exact<{
  where: AlertSubscriptions_Bool_Exp;
}>;


export type ConfAlertsQueryFilteredQuery = { __typename?: 'query_root', alertSubscriptions: Array<{ __typename?: 'alertSubscriptions', name?: string | null, type?: string | null, vars?: any | null, activation_time?: any | null, id?: any | null, receiver_type?: string | null, receiver_endpoint?: string | null, query?: string | null, fire_alert_regex?: string | null, description?: string | null, severity?: string | null }> };

export type PrefixesDownloadServerSideTableQueryVariables = Exact<{
  where: Prefixes_Bool_Exp;
}>;


export type PrefixesDownloadServerSideTableQuery = { __typename?: 'query_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null, ip_version?: number | null, mask_length?: number | null, data_source_count?: number | null, routes: Array<{ __typename?: 'routes', originAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null }> }> };

export type RoutesDownloadServerSideTableQueryVariables = Exact<{
  where: Routes_Bool_Exp;
}>;


export type RoutesDownloadServerSideTableQuery = { __typename?: 'query_root', routes: Array<{ __typename?: 'routes', as_path?: string | null, rpki_status?: string | null, time_updated?: any | null, time_inserted?: any | null, prefix?: { __typename?: 'prefixes', network?: any | null } | null, originAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, neighborAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, routeDataSourceAssociations: Array<{ __typename?: 'routeDataSourceAssociations', communities_string?: string | null }> }> };

export type RpkiRoasDownloadServerSideTableQueryVariables = Exact<{
  where: RpkiRoAs_Bool_Exp;
}>;


export type RpkiRoasDownloadServerSideTableQuery = { __typename?: 'query_root', rpkiROAs: Array<{ __typename?: 'rpkiROAs', network?: any | null, ip_version?: number | null, mask_length?: number | null, autonomous_system_number?: any | null, max_length?: number | null, rpkiROADataSourceAssociations: Array<{ __typename?: 'rpkiROADataSourceAssociations', dataSource?: { __typename?: 'dataSources', selector?: any | null } | null }> }> };

export type AlertsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type AlertsQueryQuery = { __typename?: 'query_root', alerts: Array<{ __typename?: 'alerts', data?: string | null, description?: string | null, ends_at?: any | null, fire_alert_regex?: string | null, id?: any | null, name?: string | null, query?: string | null, receiver_endpoint?: string | null, receiver_type?: string | null, severity?: string | null, starts_at?: any | null, status?: string | null, type?: string | null, vars?: string | null }> };

export type AlertsQueryFilteredQueryVariables = Exact<{
  where: Alerts_Bool_Exp;
}>;


export type AlertsQueryFilteredQuery = { __typename?: 'query_root', alerts: Array<{ __typename?: 'alerts', data?: string | null, description?: string | null, ends_at?: any | null, fire_alert_regex?: string | null, id?: any | null, name?: string | null, query?: string | null, receiver_endpoint?: string | null, receiver_type?: string | null, severity?: string | null, starts_at?: any | null, status?: string | null, type?: string | null, vars?: string | null }> };

export type ConfiguredAutonomousSystemNumbersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConfiguredAutonomousSystemNumbersSubscription = { __typename?: 'subscription_root', configuredAutonomousSystems: Array<{ __typename?: 'configuredAutonomousSystems', number?: any | null }> };

export type AutonomousSystemNumbersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AutonomousSystemNumbersSubscription = { __typename?: 'subscription_root', autonomousSystems: Array<{ __typename?: 'autonomousSystems', number?: any | null }> };

export type ConfiguredDataServicesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConfiguredDataServicesSubscription = { __typename?: 'subscription_root', configuredDataServices: Array<{ __typename?: 'configuredDataServices', data_service?: string | null, configuration?: any | null, filter_on_autonomous_system?: string | null, enabled?: boolean | null }> };

export type ConfiguredPrefixesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConfiguredPrefixesSubscription = { __typename?: 'subscription_root', configuredPrefixes: Array<{ __typename?: 'configuredPrefixes', network?: any | null }> };

export type PrefixesMyAsnOriginatesSubscriptionVariables = Exact<{
  asn: Scalars['bigint'];
}>;


export type PrefixesMyAsnOriginatesSubscription = { __typename?: 'subscription_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null }> };

export type PrefixesMyAsNsOriginateSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type PrefixesMyAsNsOriginateSubscription = { __typename?: 'subscription_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null }> };

export type NeighborsToWhichPrefixIsAnnouncedSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  prefix: Scalars['cidr'];
}>;


export type NeighborsToWhichPrefixIsAnnouncedSubscription = { __typename?: 'subscription_root', routes: Array<{ __typename?: 'routes', originAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, neighborAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, routeDataSourceAssociations: Array<{ __typename?: 'routeDataSourceAssociations', communities_string?: string | null }> }> };

export type BgpNeighborsSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
}>;


export type BgpNeighborsSubscription = { __typename?: 'subscription_root', peerings: Array<{ __typename?: 'peerings', leftAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, rightAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null }> };

export type PrefixesAnnouncedToNeighborSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  neighborASN: Scalars['bigint'];
}>;


export type PrefixesAnnouncedToNeighborSubscription = { __typename?: 'subscription_root', routes: Array<{ __typename?: 'routes', prefix?: { __typename?: 'prefixes', network?: any | null } | null, originAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, routeDataSourceAssociations: Array<{ __typename?: 'routeDataSourceAssociations', communities_string?: string | null }> }> };

export type DataServiceSourcesSubscriptionVariables = Exact<{
  where: DataSources_Bool_Exp;
}>;


export type DataServiceSourcesSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', selector?: any | null, dataSourceMetadatas: Array<{ __typename?: 'dataSourceMetadatas', city?: string | null, continent?: string | null, country?: string | null }> }> };

export type PrefixesSubscriptionVariables = Exact<{
  where: Prefixes_Bool_Exp;
  order_by?: InputMaybe<Array<Prefixes_Order_By> | Prefixes_Order_By>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type PrefixesSubscription = { __typename?: 'subscription_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null, ip_version?: number | null, mask_length?: number | null, data_source_count?: number | null, routes: Array<{ __typename?: 'routes', originAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null }> }> };

export type PrefixesCountSubscriptionVariables = Exact<{
  where: Prefixes_Bool_Exp;
}>;


export type PrefixesCountSubscription = { __typename?: 'subscription_root', prefixesAggregate: { __typename?: 'prefixes_aggregate', aggregate?: { __typename?: 'prefixes_aggregate_fields', count: number } | null } };

export type PrefixSourcesSubscriptionVariables = Exact<{
  prefix: Scalars['cidr'];
}>;


export type PrefixSourcesSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', data_service?: string | null, selector?: any | null, dataSourceMetadatas: Array<{ __typename?: 'dataSourceMetadatas', city?: string | null, continent?: string | null, country?: string | null }> }> };

export type AutonomousSystemsSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  dataServices?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AutonomousSystemsSubscription = { __typename?: 'subscription_root', autonomousSystems: Array<{ __typename?: 'autonomousSystems', number?: any | null, data_source_count?: number | null }> };

export type AutonomousSystemSourcesSubscriptionVariables = Exact<{
  asn: Scalars['bigint'];
}>;


export type AutonomousSystemSourcesSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', data_service?: string | null, selector?: any | null, dataSourceMetadatas: Array<{ __typename?: 'dataSourceMetadatas', city?: string | null, continent?: string | null, country?: string | null }> }> };

export type PeeringsSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  dataServices?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PeeringsSubscription = { __typename?: 'subscription_root', peerings: Array<{ __typename?: 'peerings', data_source_count?: number | null, leftAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, rightAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null }> };

export type PeeringSourcesSubscriptionVariables = Exact<{
  asn1: Scalars['bigint'];
  asn2: Scalars['bigint'];
}>;


export type PeeringSourcesSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', data_service?: string | null, selector?: any | null, dataSourceMetadatas: Array<{ __typename?: 'dataSourceMetadatas', city?: string | null, continent?: string | null, country?: string | null }> }> };

export type RpkiRoasCountSubscriptionVariables = Exact<{
  where: RpkiRoAs_Bool_Exp;
}>;


export type RpkiRoasCountSubscription = { __typename?: 'subscription_root', rpkiROAsAggregate: { __typename?: 'rpkiROAs_aggregate', aggregate?: { __typename?: 'rpkiROAs_aggregate_fields', count: number } | null } };

export type RpkiRoasSubscriptionVariables = Exact<{
  where: RpkiRoAs_Bool_Exp;
  order_by?: InputMaybe<Array<RpkiRoAs_Order_By> | RpkiRoAs_Order_By>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type RpkiRoasSubscription = { __typename?: 'subscription_root', rpkiROAs: Array<{ __typename?: 'rpkiROAs', network?: any | null, ip_version?: number | null, mask_length?: number | null, autonomous_system_number?: any | null, max_length?: number | null, rpkiROADataSourceAssociations: Array<{ __typename?: 'rpkiROADataSourceAssociations', dataSource?: { __typename?: 'dataSources', selector?: any | null } | null }> }> };

export type RpkiRoaSourcesSubscriptionVariables = Exact<{
  prefix: Scalars['cidr'];
  asn: Scalars['bigint'];
  maxLength: Scalars['Int'];
}>;


export type RpkiRoaSourcesSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', data_service?: string | null, selector?: any | null, rpkiROADataSourceAssociations: Array<{ __typename?: 'rpkiROADataSourceAssociations', rpkiROA?: { __typename?: 'rpkiROAs', latest_time?: any | null, max_length?: number | null } | null }> }> };

export type RoutesCountSubscriptionVariables = Exact<{
  where: Routes_Bool_Exp;
}>;


export type RoutesCountSubscription = { __typename?: 'subscription_root', routesAggregate: { __typename?: 'routes_aggregate', aggregate?: { __typename?: 'routes_aggregate_fields', count: number } | null } };

export type RoutesSubscriptionVariables = Exact<{
  where: Routes_Bool_Exp;
  order_by?: InputMaybe<Array<Routes_Order_By> | Routes_Order_By>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type RoutesSubscription = { __typename?: 'subscription_root', routes: Array<{ __typename?: 'routes', as_path?: string | null, rpki_status?: string | null, time_updated?: any | null, time_inserted?: any | null, prefix?: { __typename?: 'prefixes', network?: any | null } | null, originAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, neighborAutonomousSystem?: { __typename?: 'autonomousSystems', number?: any | null } | null, routeDataSourceAssociations: Array<{ __typename?: 'routeDataSourceAssociations', communities_string?: string | null }> }> };

export type RouteSourcesSubscriptionVariables = Exact<{
  prefix: Scalars['cidr'];
  path: Scalars['String'];
}>;


export type RouteSourcesSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', data_service?: string | null, selector?: any | null, dataSourceMetadatas: Array<{ __typename?: 'dataSourceMetadatas', city?: string | null, continent?: string | null, country?: string | null }>, routeDataSourceAssociations: Array<{ __typename?: 'routeDataSourceAssociations', data_time?: any | null }> }> };

export type RouteSourcesWithNoPathSubscriptionVariables = Exact<{
  prefix: Scalars['cidr'];
}>;


export type RouteSourcesWithNoPathSubscription = { __typename?: 'subscription_root', dataSources: Array<{ __typename?: 'dataSources', data_service?: string | null, selector?: any | null, dataSourceMetadatas: Array<{ __typename?: 'dataSourceMetadatas', city?: string | null, continent?: string | null, country?: string | null }>, routeDataSourceAssociations: Array<{ __typename?: 'routeDataSourceAssociations', data_time?: any | null }> }> };

export type PathsRelatedToPrefixSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  prefix: Scalars['cidr'];
}>;


export type PathsRelatedToPrefixSubscription = { __typename?: 'subscription_root', routes: Array<{ __typename?: 'routes', as_path?: string | null }> };

export type AutonomousSystemsCountSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  dataServices?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AutonomousSystemsCountSubscription = { __typename?: 'subscription_root', autonomousSystemsAggregate: { __typename?: 'autonomousSystems_aggregate', aggregate?: { __typename?: 'autonomousSystems_aggregate_fields', count: number } | null } };

export type DataServiceSourcesCountSubscriptionVariables = Exact<{
  where: DataSources_Bool_Exp;
}>;


export type DataServiceSourcesCountSubscription = { __typename?: 'subscription_root', dataSourcesAggregate: { __typename?: 'dataSources_aggregate', aggregate?: { __typename?: 'dataSources_aggregate_fields', count: number } | null } };

export type PeeringsCountSubscriptionVariables = Exact<{
  asns?: InputMaybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  dataServices?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PeeringsCountSubscription = { __typename?: 'subscription_root', peeringsAggregate: { __typename?: 'peerings_aggregate', aggregate?: { __typename?: 'peerings_aggregate_fields', count: number } | null } };

export type PathsIncludingAsNsSubscriptionVariables = Exact<{
  asnRegex1: Scalars['String'];
  asnRegex2: Scalars['String'];
}>;


export type PathsIncludingAsNsSubscription = { __typename?: 'subscription_root', routes: Array<{ __typename?: 'routes', as_path?: string | null }> };

export type PrefixesFromOriginAsnVisibleFromAsnSubscriptionVariables = Exact<{
  originASNRegex: Scalars['String'];
  visibleFromASNRegex: Scalars['String'];
}>;


export type PrefixesFromOriginAsnVisibleFromAsnSubscription = { __typename?: 'subscription_root', prefixes: Array<{ __typename?: 'prefixes', network?: any | null }> };

export type AsNsThatOriginatePrefixesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AsNsThatOriginatePrefixesSubscription = { __typename?: 'subscription_root', autonomousSystems: Array<{ __typename?: 'autonomousSystems', number?: any | null }> };

export type ConfAlertsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConfAlertsSubscription = { __typename?: 'subscription_root', alertSubscriptions: Array<{ __typename?: 'alertSubscriptions', name?: string | null, type?: string | null, vars?: any | null, activation_time?: any | null, id?: any | null, receiver_type?: string | null, receiver_endpoint?: string | null, query?: string | null, fire_alert_regex?: string | null, description?: string | null, severity?: string | null }> };

export type ConfiguredAlertsCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConfiguredAlertsCountSubscription = { __typename?: 'subscription_root', alertSubscriptionsAggregate: { __typename?: 'alertSubscriptions_aggregate', aggregate?: { __typename?: 'alertSubscriptions_aggregate_fields', count: number } | null } };

export type BgpUpdateRateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BgpUpdateRateSubscription = { __typename?: 'subscription_root', bgpUpdateRate: Array<{ __typename?: 'bgpUpdateRate', value?: any | null }> };

export type MaxBgpUpdateRateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MaxBgpUpdateRateSubscription = { __typename?: 'subscription_root', maxBGPUpdateRate: Array<{ __typename?: 'maxBGPUpdateRate', value?: any | null }> };

export type AvgBgpUpdateRateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AvgBgpUpdateRateSubscription = { __typename?: 'subscription_root', avgBGPUpdateRate: Array<{ __typename?: 'avgBGPUpdateRate', value?: any | null }> };

export type AlertsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AlertsSubscription = { __typename?: 'subscription_root', alerts: Array<{ __typename?: 'alerts', data?: string | null, description?: string | null, ends_at?: any | null, fire_alert_regex?: string | null, id?: any | null, name?: string | null, query?: string | null, receiver_endpoint?: string | null, receiver_type?: string | null, severity?: string | null, starts_at?: any | null, status?: string | null, type?: string | null, vars?: string | null }> };

export type ActiveAlertsCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ActiveAlertsCountSubscription = { __typename?: 'subscription_root', alertsAggregate: { __typename?: 'alerts_aggregate', aggregate?: { __typename?: 'alerts_aggregate_fields', count: number } | null } };


export const InsertManuallyConfiguredAutonomousSystemDocument = gql`
    mutation InsertManuallyConfiguredAutonomousSystem($asn: bigint!) {
  insertManuallyConfiguredAutonomousSystem(object: {number: $asn}) {
    reject
  }
}
    `;

export function useInsertManuallyConfiguredAutonomousSystemMutation() {
  return Urql.useMutation<InsertManuallyConfiguredAutonomousSystemMutation, InsertManuallyConfiguredAutonomousSystemMutationVariables>(InsertManuallyConfiguredAutonomousSystemDocument);
};
export const InsertManuallyConfiguredAutonomousSystemsDocument = gql`
    mutation InsertManuallyConfiguredAutonomousSystems($asns: [manuallyConfiguredAutonomousSystems_insert_input!]!) {
  insertManuallyConfiguredAutonomousSystems(objects: $asns) {
    affected_rows
    returning {
      number
    }
  }
}
    `;

export function useInsertManuallyConfiguredAutonomousSystemsMutation() {
  return Urql.useMutation<InsertManuallyConfiguredAutonomousSystemsMutation, InsertManuallyConfiguredAutonomousSystemsMutationVariables>(InsertManuallyConfiguredAutonomousSystemsDocument);
};
export const DeleteManuallyConfiguredAutonomousSystemsDocument = gql`
    mutation DeleteManuallyConfiguredAutonomousSystems($asns: [bigint!]) {
  deleteManuallyConfiguredAutonomousSystems(where: {number: {_in: $asns}}) {
    affected_rows
  }
}
    `;

export function useDeleteManuallyConfiguredAutonomousSystemsMutation() {
  return Urql.useMutation<DeleteManuallyConfiguredAutonomousSystemsMutation, DeleteManuallyConfiguredAutonomousSystemsMutationVariables>(DeleteManuallyConfiguredAutonomousSystemsDocument);
};
export const EnableConfiguredDataServiceDocument = gql`
    mutation EnableConfiguredDataService($enabled: Boolean!, $dataServiceName: String!) {
  updateConfiguredDataService(
    _set: {enabled: $enabled}
    where: {data_service: {_eq: $dataServiceName}}
  ) {
    returning {
      enabled
    }
  }
}
    `;

export function useEnableConfiguredDataServiceMutation() {
  return Urql.useMutation<EnableConfiguredDataServiceMutation, EnableConfiguredDataServiceMutationVariables>(EnableConfiguredDataServiceDocument);
};
export const UpdateConfiguredDataServiceFilterOnAsDocument = gql`
    mutation UpdateConfiguredDataServiceFilterOnAS($filterOnAS: String!, $dataServiceName: String!) {
  updateConfiguredDataService(
    _set: {filter_on_autonomous_system: $filterOnAS}
    where: {data_service: {_eq: $dataServiceName}}
  ) {
    returning {
      filter_on_autonomous_system
    }
  }
}
    `;

export function useUpdateConfiguredDataServiceFilterOnAsMutation() {
  return Urql.useMutation<UpdateConfiguredDataServiceFilterOnAsMutation, UpdateConfiguredDataServiceFilterOnAsMutationVariables>(UpdateConfiguredDataServiceFilterOnAsDocument);
};
export const InsertManuallyConfiguredPrefixDocument = gql`
    mutation InsertManuallyConfiguredPrefix($prefix: cidr!) {
  insertManuallyConfiguredPrefix(object: {network: $prefix}) {
    network
    reject
  }
}
    `;

export function useInsertManuallyConfiguredPrefixMutation() {
  return Urql.useMutation<InsertManuallyConfiguredPrefixMutation, InsertManuallyConfiguredPrefixMutationVariables>(InsertManuallyConfiguredPrefixDocument);
};
export const InsertManuallyConfiguredPrefixesDocument = gql`
    mutation InsertManuallyConfiguredPrefixes($prefixes: [manuallyConfiguredPrefixes_insert_input!]!) {
  insertManuallyConfiguredPrefixes(objects: $prefixes) {
    affected_rows
    returning {
      network
    }
  }
}
    `;

export function useInsertManuallyConfiguredPrefixesMutation() {
  return Urql.useMutation<InsertManuallyConfiguredPrefixesMutation, InsertManuallyConfiguredPrefixesMutationVariables>(InsertManuallyConfiguredPrefixesDocument);
};
export const DeleteManuallyConfiguredPrefixesDocument = gql`
    mutation DeleteManuallyConfiguredPrefixes($prefixes: [cidr!]) {
  deleteManuallyConfiguredPrefixes(where: {network: {_in: $prefixes}}) {
    affected_rows
  }
}
    `;

export function useDeleteManuallyConfiguredPrefixesMutation() {
  return Urql.useMutation<DeleteManuallyConfiguredPrefixesMutation, DeleteManuallyConfiguredPrefixesMutationVariables>(DeleteManuallyConfiguredPrefixesDocument);
};
export const InsertAlertRuleDocument = gql`
    mutation InsertAlertRule($object: alertSubscriptions_insert_input!) {
  insertAlertSubscription(object: $object) {
    name
  }
}
    `;

export function useInsertAlertRuleMutation() {
  return Urql.useMutation<InsertAlertRuleMutation, InsertAlertRuleMutationVariables>(InsertAlertRuleDocument);
};
export const DeleteAlertRulesDocument = gql`
    mutation DeleteAlertRules($ids: [uuid!]) {
  deleteAlertSubscriptions(where: {id: {_in: $ids}}) {
    affected_rows
    returning {
      name
    }
  }
}
    `;

export function useDeleteAlertRulesMutation() {
  return Urql.useMutation<DeleteAlertRulesMutation, DeleteAlertRulesMutationVariables>(DeleteAlertRulesDocument);
};
export const UpdateAlertRuleDocument = gql`
    mutation UpdateAlertRule($id: uuid!, $description: String!, $name: String!, $receiver_endpoint: String!, $receiver_type: String!, $vars: jsonb!) {
  updateAlertSubscription(
    _set: {description: $description, name: $name, receiver_endpoint: $receiver_endpoint, receiver_type: $receiver_type, vars: $vars}
    where: {id: {_eq: $id}}
  ) {
    returning {
      name
    }
  }
}
    `;

export function useUpdateAlertRuleMutation() {
  return Urql.useMutation<UpdateAlertRuleMutation, UpdateAlertRuleMutationVariables>(UpdateAlertRuleDocument);
};
export const DeleteAlertsDocument = gql`
    mutation DeleteAlerts($ids: [uuid!]) {
  deleteAlerts(where: {id: {_in: $ids}}) {
    affected_rows
    returning {
      name
    }
  }
}
    `;

export function useDeleteAlertsMutation() {
  return Urql.useMutation<DeleteAlertsMutation, DeleteAlertsMutationVariables>(DeleteAlertsDocument);
};
export const InsertAlertRulesDocument = gql`
    mutation InsertAlertRules($alerts: [alertSubscriptions_insert_input!]!) {
  insertAlertSubscriptions(objects: $alerts) {
    affected_rows
    returning {
      name
    }
  }
}
    `;

export function useInsertAlertRulesMutation() {
  return Urql.useMutation<InsertAlertRulesMutation, InsertAlertRulesMutationVariables>(InsertAlertRulesDocument);
};
export const PrefixesFromAsnDocument = gql`
    query PrefixesFromASN($asn: bigint!, $prefixesWhere: prefixes_bool_exp) {
  prefixes(order_by: {network: asc}, where: $prefixesWhere) {
    network
  }
  rpkiROAs(
    where: {autonomous_system_number: {_eq: $asn}}
    distinct_on: network
    order_by: {network: asc}
  ) {
    network
  }
}
    `;

export function usePrefixesFromAsnQuery(options: Omit<Urql.UseQueryArgs<PrefixesFromAsnQueryVariables>, 'query'>) {
  return Urql.useQuery<PrefixesFromAsnQuery, PrefixesFromAsnQueryVariables>({ query: PrefixesFromAsnDocument, ...options });
};
export const RpkiPrefixesFromAsnDocument = gql`
    query RpkiPrefixesFromASN($asn: bigint!) {
  rpkiROAs(
    where: {autonomous_system_number: {_eq: $asn}}
    distinct_on: network
    order_by: {network: asc}
  ) {
    network
  }
}
    `;

export function useRpkiPrefixesFromAsnQuery(options: Omit<Urql.UseQueryArgs<RpkiPrefixesFromAsnQueryVariables>, 'query'>) {
  return Urql.useQuery<RpkiPrefixesFromAsnQuery, RpkiPrefixesFromAsnQueryVariables>({ query: RpkiPrefixesFromAsnDocument, ...options });
};
export const PrefixesFromAsNnotConfiguredDocument = gql`
    query PrefixesFromASNnotConfigured($asn: bigint!, $prefixesWhere: prefixes_bool_exp, $prefixArray: [cidr!]) {
  prefixes(order_by: {network: asc}, where: $prefixesWhere) {
    network
  }
  rpkiROAs(
    where: {autonomous_system_number: {_eq: $asn}, network: {_nin: $prefixArray}}
    distinct_on: network
    order_by: {network: asc}
  ) {
    network
  }
}
    `;

export function usePrefixesFromAsNnotConfiguredQuery(options: Omit<Urql.UseQueryArgs<PrefixesFromAsNnotConfiguredQueryVariables>, 'query'>) {
  return Urql.useQuery<PrefixesFromAsNnotConfiguredQuery, PrefixesFromAsNnotConfiguredQueryVariables>({ query: PrefixesFromAsNnotConfiguredDocument, ...options });
};
export const PolledBgpNeighborsDocument = gql`
    query PolledBGPNeighbors($asns: [bigint!] = []) {
  peerings(
    where: {_or: [{leftAutonomousSystem: {number: {_in: $asns}}}, {rightAutonomousSystem: {number: {_in: $asns}}}]}
  ) {
    leftAutonomousSystem {
      number
    }
    rightAutonomousSystem {
      number
    }
  }
}
    `;

export function usePolledBgpNeighborsQuery(options?: Omit<Urql.UseQueryArgs<PolledBgpNeighborsQueryVariables>, 'query'>) {
  return Urql.useQuery<PolledBgpNeighborsQuery, PolledBgpNeighborsQueryVariables>({ query: PolledBgpNeighborsDocument, ...options });
};
export const ConfAlertsQueryDocument = gql`
    query confAlertsQuery {
  alertSubscriptions {
    name
    type
    vars
    activation_time
    id
    receiver_type
    receiver_endpoint
    query
    fire_alert_regex
    description
    severity
  }
}
    `;

export function useConfAlertsQueryQuery(options?: Omit<Urql.UseQueryArgs<ConfAlertsQueryQueryVariables>, 'query'>) {
  return Urql.useQuery<ConfAlertsQueryQuery, ConfAlertsQueryQueryVariables>({ query: ConfAlertsQueryDocument, ...options });
};
export const ConfAlertsQueryFilteredDocument = gql`
    query confAlertsQueryFiltered($where: alertSubscriptions_bool_exp!) {
  alertSubscriptions(where: $where) {
    name
    type
    vars
    activation_time
    id
    receiver_type
    receiver_endpoint
    query
    fire_alert_regex
    description
    severity
  }
}
    `;

export function useConfAlertsQueryFilteredQuery(options: Omit<Urql.UseQueryArgs<ConfAlertsQueryFilteredQueryVariables>, 'query'>) {
  return Urql.useQuery<ConfAlertsQueryFilteredQuery, ConfAlertsQueryFilteredQueryVariables>({ query: ConfAlertsQueryFilteredDocument, ...options });
};
export const PrefixesDownloadServerSideTableDocument = gql`
    query PrefixesDownloadServerSideTable($where: prefixes_bool_exp!) {
  prefixes(where: $where) {
    network
    ip_version
    mask_length
    data_source_count
    routes {
      originAutonomousSystem {
        number
      }
    }
  }
}
    `;

export function usePrefixesDownloadServerSideTableQuery(options: Omit<Urql.UseQueryArgs<PrefixesDownloadServerSideTableQueryVariables>, 'query'>) {
  return Urql.useQuery<PrefixesDownloadServerSideTableQuery, PrefixesDownloadServerSideTableQueryVariables>({ query: PrefixesDownloadServerSideTableDocument, ...options });
};
export const RoutesDownloadServerSideTableDocument = gql`
    query RoutesDownloadServerSideTable($where: routes_bool_exp!) {
  routes(where: $where) {
    prefix {
      network
    }
    originAutonomousSystem {
      number
    }
    neighborAutonomousSystem {
      number
    }
    routeDataSourceAssociations {
      communities_string
    }
    as_path
    rpki_status
    time_updated
    time_inserted
  }
}
    `;

export function useRoutesDownloadServerSideTableQuery(options: Omit<Urql.UseQueryArgs<RoutesDownloadServerSideTableQueryVariables>, 'query'>) {
  return Urql.useQuery<RoutesDownloadServerSideTableQuery, RoutesDownloadServerSideTableQueryVariables>({ query: RoutesDownloadServerSideTableDocument, ...options });
};
export const RpkiRoasDownloadServerSideTableDocument = gql`
    query RpkiRoasDownloadServerSideTable($where: rpkiROAs_bool_exp!) {
  rpkiROAs(where: $where) {
    network
    ip_version
    mask_length
    autonomous_system_number
    max_length
    rpkiROADataSourceAssociations {
      dataSource {
        selector
      }
    }
  }
}
    `;

export function useRpkiRoasDownloadServerSideTableQuery(options: Omit<Urql.UseQueryArgs<RpkiRoasDownloadServerSideTableQueryVariables>, 'query'>) {
  return Urql.useQuery<RpkiRoasDownloadServerSideTableQuery, RpkiRoasDownloadServerSideTableQueryVariables>({ query: RpkiRoasDownloadServerSideTableDocument, ...options });
};
export const AlertsQueryDocument = gql`
    query alertsQuery {
  alerts {
    data
    description
    ends_at
    fire_alert_regex
    id
    name
    query
    receiver_endpoint
    receiver_type
    severity
    starts_at
    status
    type
    vars
  }
}
    `;

export function useAlertsQueryQuery(options?: Omit<Urql.UseQueryArgs<AlertsQueryQueryVariables>, 'query'>) {
  return Urql.useQuery<AlertsQueryQuery, AlertsQueryQueryVariables>({ query: AlertsQueryDocument, ...options });
};
export const AlertsQueryFilteredDocument = gql`
    query alertsQueryFiltered($where: alerts_bool_exp!) {
  alerts(where: $where) {
    data
    description
    ends_at
    fire_alert_regex
    id
    name
    query
    receiver_endpoint
    receiver_type
    severity
    starts_at
    status
    type
    vars
  }
}
    `;

export function useAlertsQueryFilteredQuery(options: Omit<Urql.UseQueryArgs<AlertsQueryFilteredQueryVariables>, 'query'>) {
  return Urql.useQuery<AlertsQueryFilteredQuery, AlertsQueryFilteredQueryVariables>({ query: AlertsQueryFilteredDocument, ...options });
};
export const ConfiguredAutonomousSystemNumbersDocument = gql`
    subscription ConfiguredAutonomousSystemNumbers {
  configuredAutonomousSystems(order_by: {number: asc}) {
    number
  }
}
    `;

export function useConfiguredAutonomousSystemNumbersSubscription<TData = ConfiguredAutonomousSystemNumbersSubscription>(options: Omit<Urql.UseSubscriptionArgs<ConfiguredAutonomousSystemNumbersSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ConfiguredAutonomousSystemNumbersSubscription, TData>) {
  return Urql.useSubscription<ConfiguredAutonomousSystemNumbersSubscription, TData, ConfiguredAutonomousSystemNumbersSubscriptionVariables>({ query: ConfiguredAutonomousSystemNumbersDocument, ...options }, handler);
};
export const AutonomousSystemNumbersDocument = gql`
    subscription AutonomousSystemNumbers {
  autonomousSystems(order_by: {number: asc}) {
    number
  }
}
    `;

export function useAutonomousSystemNumbersSubscription<TData = AutonomousSystemNumbersSubscription>(options: Omit<Urql.UseSubscriptionArgs<AutonomousSystemNumbersSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AutonomousSystemNumbersSubscription, TData>) {
  return Urql.useSubscription<AutonomousSystemNumbersSubscription, TData, AutonomousSystemNumbersSubscriptionVariables>({ query: AutonomousSystemNumbersDocument, ...options }, handler);
};
export const ConfiguredDataServicesDocument = gql`
    subscription ConfiguredDataServices {
  configuredDataServices(
    where: {data_service: {_neq: "PROTOTYPE_ADAPTER"}}
    order_by: {data_service: asc}
  ) {
    data_service
    configuration
    filter_on_autonomous_system
    enabled
  }
}
    `;

export function useConfiguredDataServicesSubscription<TData = ConfiguredDataServicesSubscription>(options: Omit<Urql.UseSubscriptionArgs<ConfiguredDataServicesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ConfiguredDataServicesSubscription, TData>) {
  return Urql.useSubscription<ConfiguredDataServicesSubscription, TData, ConfiguredDataServicesSubscriptionVariables>({ query: ConfiguredDataServicesDocument, ...options }, handler);
};
export const ConfiguredPrefixesDocument = gql`
    subscription ConfiguredPrefixes {
  configuredPrefixes(order_by: {network: asc}) {
    network
  }
}
    `;

export function useConfiguredPrefixesSubscription<TData = ConfiguredPrefixesSubscription>(options: Omit<Urql.UseSubscriptionArgs<ConfiguredPrefixesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ConfiguredPrefixesSubscription, TData>) {
  return Urql.useSubscription<ConfiguredPrefixesSubscription, TData, ConfiguredPrefixesSubscriptionVariables>({ query: ConfiguredPrefixesDocument, ...options }, handler);
};
export const PrefixesMyAsnOriginatesDocument = gql`
    subscription PrefixesMyASNOriginates($asn: bigint!) {
  prefixes(
    where: {routes: {originAutonomousSystem: {number: {_eq: $asn}}}}
    order_by: {network: asc}
  ) {
    network
  }
}
    `;

export function usePrefixesMyAsnOriginatesSubscription<TData = PrefixesMyAsnOriginatesSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixesMyAsnOriginatesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixesMyAsnOriginatesSubscription, TData>) {
  return Urql.useSubscription<PrefixesMyAsnOriginatesSubscription, TData, PrefixesMyAsnOriginatesSubscriptionVariables>({ query: PrefixesMyAsnOriginatesDocument, ...options }, handler);
};
export const PrefixesMyAsNsOriginateDocument = gql`
    subscription PrefixesMyASNsOriginate($asns: [bigint!] = []) {
  prefixes(
    where: {routes: {originAutonomousSystem: {number: {_in: $asns}}}}
    order_by: {network: asc}
  ) {
    network
  }
}
    `;

export function usePrefixesMyAsNsOriginateSubscription<TData = PrefixesMyAsNsOriginateSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixesMyAsNsOriginateSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixesMyAsNsOriginateSubscription, TData>) {
  return Urql.useSubscription<PrefixesMyAsNsOriginateSubscription, TData, PrefixesMyAsNsOriginateSubscriptionVariables>({ query: PrefixesMyAsNsOriginateDocument, ...options }, handler);
};
export const NeighborsToWhichPrefixIsAnnouncedDocument = gql`
    subscription NeighborsToWhichPrefixIsAnnounced($asns: [bigint!] = [], $prefix: cidr!) {
  routes(
    where: {originAutonomousSystem: {number: {_in: $asns}}, prefix: {network: {_eq: $prefix}}}
  ) {
    originAutonomousSystem {
      number
    }
    neighborAutonomousSystem {
      number
    }
    routeDataSourceAssociations {
      communities_string
    }
  }
}
    `;

export function useNeighborsToWhichPrefixIsAnnouncedSubscription<TData = NeighborsToWhichPrefixIsAnnouncedSubscription>(options: Omit<Urql.UseSubscriptionArgs<NeighborsToWhichPrefixIsAnnouncedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<NeighborsToWhichPrefixIsAnnouncedSubscription, TData>) {
  return Urql.useSubscription<NeighborsToWhichPrefixIsAnnouncedSubscription, TData, NeighborsToWhichPrefixIsAnnouncedSubscriptionVariables>({ query: NeighborsToWhichPrefixIsAnnouncedDocument, ...options }, handler);
};
export const BgpNeighborsDocument = gql`
    subscription BGPNeighbors($asns: [bigint!] = []) {
  peerings(
    where: {_or: [{leftAutonomousSystem: {number: {_in: $asns}}}, {rightAutonomousSystem: {number: {_in: $asns}}}]}
  ) {
    leftAutonomousSystem {
      number
    }
    rightAutonomousSystem {
      number
    }
  }
}
    `;

export function useBgpNeighborsSubscription<TData = BgpNeighborsSubscription>(options: Omit<Urql.UseSubscriptionArgs<BgpNeighborsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<BgpNeighborsSubscription, TData>) {
  return Urql.useSubscription<BgpNeighborsSubscription, TData, BgpNeighborsSubscriptionVariables>({ query: BgpNeighborsDocument, ...options }, handler);
};
export const PrefixesAnnouncedToNeighborDocument = gql`
    subscription PrefixesAnnouncedToNeighbor($asns: [bigint!] = [], $neighborASN: bigint!) {
  routes(
    where: {originAutonomousSystem: {number: {_in: $asns}}, neighborAutonomousSystem: {number: {_eq: $neighborASN}}}
  ) {
    prefix {
      network
    }
    originAutonomousSystem {
      number
    }
    routeDataSourceAssociations {
      communities_string
    }
  }
}
    `;

export function usePrefixesAnnouncedToNeighborSubscription<TData = PrefixesAnnouncedToNeighborSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixesAnnouncedToNeighborSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixesAnnouncedToNeighborSubscription, TData>) {
  return Urql.useSubscription<PrefixesAnnouncedToNeighborSubscription, TData, PrefixesAnnouncedToNeighborSubscriptionVariables>({ query: PrefixesAnnouncedToNeighborDocument, ...options }, handler);
};
export const DataServiceSourcesDocument = gql`
    subscription DataServiceSources($where: dataSources_bool_exp!) {
  dataSources(where: $where) {
    selector
    dataSourceMetadatas {
      city
      continent
      country
    }
  }
}
    `;

export function useDataServiceSourcesSubscription<TData = DataServiceSourcesSubscription>(options: Omit<Urql.UseSubscriptionArgs<DataServiceSourcesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<DataServiceSourcesSubscription, TData>) {
  return Urql.useSubscription<DataServiceSourcesSubscription, TData, DataServiceSourcesSubscriptionVariables>({ query: DataServiceSourcesDocument, ...options }, handler);
};
export const PrefixesDocument = gql`
    subscription Prefixes($where: prefixes_bool_exp!, $order_by: [prefixes_order_by!], $limit: Int!, $offset: Int!) {
  prefixes(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
    network
    ip_version
    mask_length
    data_source_count
    routes {
      originAutonomousSystem {
        number
      }
    }
  }
}
    `;

export function usePrefixesSubscription<TData = PrefixesSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixesSubscription, TData>) {
  return Urql.useSubscription<PrefixesSubscription, TData, PrefixesSubscriptionVariables>({ query: PrefixesDocument, ...options }, handler);
};
export const PrefixesCountDocument = gql`
    subscription PrefixesCount($where: prefixes_bool_exp!) {
  prefixesAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function usePrefixesCountSubscription<TData = PrefixesCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixesCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixesCountSubscription, TData>) {
  return Urql.useSubscription<PrefixesCountSubscription, TData, PrefixesCountSubscriptionVariables>({ query: PrefixesCountDocument, ...options }, handler);
};
export const PrefixSourcesDocument = gql`
    subscription PrefixSources($prefix: cidr!) {
  dataSources(
    where: {prefixDataSourceAssociations: {prefix: {network: {_eq: $prefix}}, withdrawn: {_eq: false}}}
  ) {
    data_service
    selector
    dataSourceMetadatas {
      city
      continent
      country
    }
  }
}
    `;

export function usePrefixSourcesSubscription<TData = PrefixSourcesSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixSourcesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixSourcesSubscription, TData>) {
  return Urql.useSubscription<PrefixSourcesSubscription, TData, PrefixSourcesSubscriptionVariables>({ query: PrefixSourcesDocument, ...options }, handler);
};
export const AutonomousSystemsDocument = gql`
    subscription AutonomousSystems($asns: [bigint!] = [], $dataServices: [String!] = []) {
  autonomousSystems(
    order_by: {number: asc}
    where: {number: {_in: $asns}, autonomousSystemDataSourceAssociations: {dataSource: {data_service: {_in: $dataServices}}}}
  ) {
    number
    data_source_count
  }
}
    `;

export function useAutonomousSystemsSubscription<TData = AutonomousSystemsSubscription>(options: Omit<Urql.UseSubscriptionArgs<AutonomousSystemsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AutonomousSystemsSubscription, TData>) {
  return Urql.useSubscription<AutonomousSystemsSubscription, TData, AutonomousSystemsSubscriptionVariables>({ query: AutonomousSystemsDocument, ...options }, handler);
};
export const AutonomousSystemSourcesDocument = gql`
    subscription AutonomousSystemSources($asn: bigint!) {
  dataSources(
    where: {autonomousSystemDataSourceAssociations: {autonomousSystem: {number: {_eq: $asn}}}}
  ) {
    data_service
    selector
    dataSourceMetadatas {
      city
      continent
      country
    }
  }
}
    `;

export function useAutonomousSystemSourcesSubscription<TData = AutonomousSystemSourcesSubscription>(options: Omit<Urql.UseSubscriptionArgs<AutonomousSystemSourcesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AutonomousSystemSourcesSubscription, TData>) {
  return Urql.useSubscription<AutonomousSystemSourcesSubscription, TData, AutonomousSystemSourcesSubscriptionVariables>({ query: AutonomousSystemSourcesDocument, ...options }, handler);
};
export const PeeringsDocument = gql`
    subscription Peerings($asns: [bigint!] = [], $dataServices: [String!] = []) {
  peerings(
    where: {_or: [{leftAutonomousSystem: {number: {_in: $asns}}}, {rightAutonomousSystem: {number: {_in: $asns}}}], peeringDataSourceAssociations: {dataSource: {data_service: {_in: $dataServices}}}}
  ) {
    leftAutonomousSystem {
      number
    }
    rightAutonomousSystem {
      number
    }
    data_source_count
  }
}
    `;

export function usePeeringsSubscription<TData = PeeringsSubscription>(options: Omit<Urql.UseSubscriptionArgs<PeeringsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PeeringsSubscription, TData>) {
  return Urql.useSubscription<PeeringsSubscription, TData, PeeringsSubscriptionVariables>({ query: PeeringsDocument, ...options }, handler);
};
export const PeeringSourcesDocument = gql`
    subscription PeeringSources($asn1: bigint!, $asn2: bigint!) {
  dataSources(
    where: {_or: [{peeringDataSourceAssociations: {peering: {leftAutonomousSystem: {number: {_eq: $asn1}}, rightAutonomousSystem: {number: {_eq: $asn2}}}}}, {peeringDataSourceAssociations: {peering: {leftAutonomousSystem: {number: {_eq: $asn2}}, rightAutonomousSystem: {number: {_eq: $asn1}}}}}]}
  ) {
    data_service
    selector
    dataSourceMetadatas {
      city
      continent
      country
    }
  }
}
    `;

export function usePeeringSourcesSubscription<TData = PeeringSourcesSubscription>(options: Omit<Urql.UseSubscriptionArgs<PeeringSourcesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PeeringSourcesSubscription, TData>) {
  return Urql.useSubscription<PeeringSourcesSubscription, TData, PeeringSourcesSubscriptionVariables>({ query: PeeringSourcesDocument, ...options }, handler);
};
export const RpkiRoasCountDocument = gql`
    subscription RpkiRoasCount($where: rpkiROAs_bool_exp!) {
  rpkiROAsAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useRpkiRoasCountSubscription<TData = RpkiRoasCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<RpkiRoasCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RpkiRoasCountSubscription, TData>) {
  return Urql.useSubscription<RpkiRoasCountSubscription, TData, RpkiRoasCountSubscriptionVariables>({ query: RpkiRoasCountDocument, ...options }, handler);
};
export const RpkiRoasDocument = gql`
    subscription RpkiRoas($where: rpkiROAs_bool_exp!, $order_by: [rpkiROAs_order_by!], $limit: Int!, $offset: Int!) {
  rpkiROAs(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    network
    ip_version
    mask_length
    autonomous_system_number
    max_length
    rpkiROADataSourceAssociations {
      dataSource {
        selector
      }
    }
  }
}
    `;

export function useRpkiRoasSubscription<TData = RpkiRoasSubscription>(options: Omit<Urql.UseSubscriptionArgs<RpkiRoasSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RpkiRoasSubscription, TData>) {
  return Urql.useSubscription<RpkiRoasSubscription, TData, RpkiRoasSubscriptionVariables>({ query: RpkiRoasDocument, ...options }, handler);
};
export const RpkiRoaSourcesDocument = gql`
    subscription RpkiRoaSources($prefix: cidr!, $asn: bigint!, $maxLength: Int!) {
  dataSources(
    where: {rpkiROADataSourceAssociations: {rpkiROA: {network: {_eq: $prefix}, autonomous_system_number: {_eq: $asn}, max_length: {_eq: $maxLength}}}}
    order_by: {rpkiROADataSourceAssociations_aggregate: {max: {data_time: desc_nulls_last}}}
  ) {
    data_service
    selector
    rpkiROADataSourceAssociations(
      where: {rpkiROA: {network: {_eq: $prefix}, autonomous_system_number: {_eq: $asn}, max_length: {_eq: $maxLength}}}
    ) {
      rpkiROA {
        latest_time
        max_length
      }
    }
  }
}
    `;

export function useRpkiRoaSourcesSubscription<TData = RpkiRoaSourcesSubscription>(options: Omit<Urql.UseSubscriptionArgs<RpkiRoaSourcesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RpkiRoaSourcesSubscription, TData>) {
  return Urql.useSubscription<RpkiRoaSourcesSubscription, TData, RpkiRoaSourcesSubscriptionVariables>({ query: RpkiRoaSourcesDocument, ...options }, handler);
};
export const RoutesCountDocument = gql`
    subscription RoutesCount($where: routes_bool_exp!) {
  routesAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useRoutesCountSubscription<TData = RoutesCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<RoutesCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RoutesCountSubscription, TData>) {
  return Urql.useSubscription<RoutesCountSubscription, TData, RoutesCountSubscriptionVariables>({ query: RoutesCountDocument, ...options }, handler);
};
export const RoutesDocument = gql`
    subscription Routes($where: routes_bool_exp!, $order_by: [routes_order_by!], $limit: Int!, $offset: Int!) {
  routes(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    prefix {
      network
    }
    originAutonomousSystem {
      number
    }
    neighborAutonomousSystem {
      number
    }
    routeDataSourceAssociations {
      communities_string
    }
    as_path
    rpki_status
    time_updated
    time_inserted
  }
}
    `;

export function useRoutesSubscription<TData = RoutesSubscription>(options: Omit<Urql.UseSubscriptionArgs<RoutesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RoutesSubscription, TData>) {
  return Urql.useSubscription<RoutesSubscription, TData, RoutesSubscriptionVariables>({ query: RoutesDocument, ...options }, handler);
};
export const RouteSourcesDocument = gql`
    subscription RouteSources($prefix: cidr!, $path: String!) {
  dataSources(
    where: {prefixDataSourceAssociations: {prefix: {network: {_eq: $prefix}}, withdrawn: {_eq: false}}, routeDataSourceAssociations: {route: {as_path: {_eq: $path}, prefix: {network: {_eq: $prefix}}}}}
    order_by: {routeDataSourceAssociations_aggregate: {max: {data_time: desc_nulls_last}}}
  ) {
    data_service
    selector
    dataSourceMetadatas {
      city
      continent
      country
    }
    routeDataSourceAssociations(
      where: {route: {as_path: {_eq: $path}, prefix: {network: {_eq: $prefix}}}}
    ) {
      data_time
    }
  }
}
    `;

export function useRouteSourcesSubscription<TData = RouteSourcesSubscription>(options: Omit<Urql.UseSubscriptionArgs<RouteSourcesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RouteSourcesSubscription, TData>) {
  return Urql.useSubscription<RouteSourcesSubscription, TData, RouteSourcesSubscriptionVariables>({ query: RouteSourcesDocument, ...options }, handler);
};
export const RouteSourcesWithNoPathDocument = gql`
    subscription RouteSourcesWithNoPath($prefix: cidr!) {
  dataSources(
    where: {prefixDataSourceAssociations: {prefix: {network: {_eq: $prefix}}, withdrawn: {_eq: false}}, routeDataSourceAssociations: {route: {prefix: {network: {_eq: $prefix}}}}, _not: {routeDataSourceAssociations: {route: {as_path: {}}}}}
    order_by: {routeDataSourceAssociations_aggregate: {max: {data_time: desc_nulls_last}}}
  ) {
    data_service
    selector
    dataSourceMetadatas {
      city
      continent
      country
    }
    routeDataSourceAssociations(where: {route: {prefix: {network: {_eq: $prefix}}}}) {
      data_time
    }
  }
}
    `;

export function useRouteSourcesWithNoPathSubscription<TData = RouteSourcesWithNoPathSubscription>(options: Omit<Urql.UseSubscriptionArgs<RouteSourcesWithNoPathSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<RouteSourcesWithNoPathSubscription, TData>) {
  return Urql.useSubscription<RouteSourcesWithNoPathSubscription, TData, RouteSourcesWithNoPathSubscriptionVariables>({ query: RouteSourcesWithNoPathDocument, ...options }, handler);
};
export const PathsRelatedToPrefixDocument = gql`
    subscription PathsRelatedToPrefix($asns: [bigint!] = [], $prefix: cidr!) {
  routes(
    where: {prefix: {network: {_eq: $prefix}}, originAutonomousSystem: {number: {_in: $asns}}}
    distinct_on: as_path
    order_by: {as_path: asc_nulls_last}
  ) {
    as_path
  }
}
    `;

export function usePathsRelatedToPrefixSubscription<TData = PathsRelatedToPrefixSubscription>(options: Omit<Urql.UseSubscriptionArgs<PathsRelatedToPrefixSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PathsRelatedToPrefixSubscription, TData>) {
  return Urql.useSubscription<PathsRelatedToPrefixSubscription, TData, PathsRelatedToPrefixSubscriptionVariables>({ query: PathsRelatedToPrefixDocument, ...options }, handler);
};
export const AutonomousSystemsCountDocument = gql`
    subscription AutonomousSystemsCount($asns: [bigint!] = [], $dataServices: [String!] = []) {
  autonomousSystemsAggregate(
    where: {number: {_in: $asns}, autonomousSystemDataSourceAssociations: {dataSource: {data_service: {_in: $dataServices}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function useAutonomousSystemsCountSubscription<TData = AutonomousSystemsCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<AutonomousSystemsCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AutonomousSystemsCountSubscription, TData>) {
  return Urql.useSubscription<AutonomousSystemsCountSubscription, TData, AutonomousSystemsCountSubscriptionVariables>({ query: AutonomousSystemsCountDocument, ...options }, handler);
};
export const DataServiceSourcesCountDocument = gql`
    subscription DataServiceSourcesCount($where: dataSources_bool_exp!) {
  dataSourcesAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export function useDataServiceSourcesCountSubscription<TData = DataServiceSourcesCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<DataServiceSourcesCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<DataServiceSourcesCountSubscription, TData>) {
  return Urql.useSubscription<DataServiceSourcesCountSubscription, TData, DataServiceSourcesCountSubscriptionVariables>({ query: DataServiceSourcesCountDocument, ...options }, handler);
};
export const PeeringsCountDocument = gql`
    subscription PeeringsCount($asns: [bigint!] = [], $dataServices: [String!] = []) {
  peeringsAggregate(
    where: {_or: [{leftAutonomousSystem: {number: {_in: $asns}}}, {rightAutonomousSystem: {number: {_in: $asns}}}], peeringDataSourceAssociations: {dataSource: {data_service: {_in: $dataServices}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export function usePeeringsCountSubscription<TData = PeeringsCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<PeeringsCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PeeringsCountSubscription, TData>) {
  return Urql.useSubscription<PeeringsCountSubscription, TData, PeeringsCountSubscriptionVariables>({ query: PeeringsCountDocument, ...options }, handler);
};
export const PathsIncludingAsNsDocument = gql`
    subscription PathsIncludingASNs($asnRegex1: String!, $asnRegex2: String!) {
  routes(
    where: {_and: [{as_path: {_regex: $asnRegex1}}, {as_path: {_regex: $asnRegex2}}]}
    distinct_on: as_path
    order_by: {as_path: asc}
  ) {
    as_path
  }
}
    `;

export function usePathsIncludingAsNsSubscription<TData = PathsIncludingAsNsSubscription>(options: Omit<Urql.UseSubscriptionArgs<PathsIncludingAsNsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PathsIncludingAsNsSubscription, TData>) {
  return Urql.useSubscription<PathsIncludingAsNsSubscription, TData, PathsIncludingAsNsSubscriptionVariables>({ query: PathsIncludingAsNsDocument, ...options }, handler);
};
export const PrefixesFromOriginAsnVisibleFromAsnDocument = gql`
    subscription PrefixesFromOriginASNVisibleFromASN($originASNRegex: String!, $visibleFromASNRegex: String!) {
  prefixes(
    where: {routes: {_and: [{as_path: {_regex: $originASNRegex}}, {as_path: {_regex: $visibleFromASNRegex}}]}}
    order_by: {network: asc}
  ) {
    network
  }
}
    `;

export function usePrefixesFromOriginAsnVisibleFromAsnSubscription<TData = PrefixesFromOriginAsnVisibleFromAsnSubscription>(options: Omit<Urql.UseSubscriptionArgs<PrefixesFromOriginAsnVisibleFromAsnSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<PrefixesFromOriginAsnVisibleFromAsnSubscription, TData>) {
  return Urql.useSubscription<PrefixesFromOriginAsnVisibleFromAsnSubscription, TData, PrefixesFromOriginAsnVisibleFromAsnSubscriptionVariables>({ query: PrefixesFromOriginAsnVisibleFromAsnDocument, ...options }, handler);
};
export const AsNsThatOriginatePrefixesDocument = gql`
    subscription ASNsThatOriginatePrefixes {
  autonomousSystems(
    where: {routeOriginAutonomousSystems: {originAutonomousSystem: {number: {}}}}
    order_by: {number: asc}
  ) {
    number
  }
}
    `;

export function useAsNsThatOriginatePrefixesSubscription<TData = AsNsThatOriginatePrefixesSubscription>(options: Omit<Urql.UseSubscriptionArgs<AsNsThatOriginatePrefixesSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AsNsThatOriginatePrefixesSubscription, TData>) {
  return Urql.useSubscription<AsNsThatOriginatePrefixesSubscription, TData, AsNsThatOriginatePrefixesSubscriptionVariables>({ query: AsNsThatOriginatePrefixesDocument, ...options }, handler);
};
export const ConfAlertsDocument = gql`
    subscription confAlerts {
  alertSubscriptions {
    name
    type
    vars
    activation_time
    id
    receiver_type
    receiver_endpoint
    query
    fire_alert_regex
    description
    severity
  }
}
    `;

export function useConfAlertsSubscription<TData = ConfAlertsSubscription>(options: Omit<Urql.UseSubscriptionArgs<ConfAlertsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ConfAlertsSubscription, TData>) {
  return Urql.useSubscription<ConfAlertsSubscription, TData, ConfAlertsSubscriptionVariables>({ query: ConfAlertsDocument, ...options }, handler);
};
export const ConfiguredAlertsCountDocument = gql`
    subscription configuredAlertsCount {
  alertSubscriptionsAggregate {
    aggregate {
      count
    }
  }
}
    `;

export function useConfiguredAlertsCountSubscription<TData = ConfiguredAlertsCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<ConfiguredAlertsCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ConfiguredAlertsCountSubscription, TData>) {
  return Urql.useSubscription<ConfiguredAlertsCountSubscription, TData, ConfiguredAlertsCountSubscriptionVariables>({ query: ConfiguredAlertsCountDocument, ...options }, handler);
};
export const BgpUpdateRateDocument = gql`
    subscription bgpUpdateRate {
  bgpUpdateRate {
    value
  }
}
    `;

export function useBgpUpdateRateSubscription<TData = BgpUpdateRateSubscription>(options: Omit<Urql.UseSubscriptionArgs<BgpUpdateRateSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<BgpUpdateRateSubscription, TData>) {
  return Urql.useSubscription<BgpUpdateRateSubscription, TData, BgpUpdateRateSubscriptionVariables>({ query: BgpUpdateRateDocument, ...options }, handler);
};
export const MaxBgpUpdateRateDocument = gql`
    subscription maxBgpUpdateRate {
  maxBGPUpdateRate {
    value
  }
}
    `;

export function useMaxBgpUpdateRateSubscription<TData = MaxBgpUpdateRateSubscription>(options: Omit<Urql.UseSubscriptionArgs<MaxBgpUpdateRateSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<MaxBgpUpdateRateSubscription, TData>) {
  return Urql.useSubscription<MaxBgpUpdateRateSubscription, TData, MaxBgpUpdateRateSubscriptionVariables>({ query: MaxBgpUpdateRateDocument, ...options }, handler);
};
export const AvgBgpUpdateRateDocument = gql`
    subscription avgBGPUpdateRate {
  avgBGPUpdateRate {
    value
  }
}
    `;

export function useAvgBgpUpdateRateSubscription<TData = AvgBgpUpdateRateSubscription>(options: Omit<Urql.UseSubscriptionArgs<AvgBgpUpdateRateSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AvgBgpUpdateRateSubscription, TData>) {
  return Urql.useSubscription<AvgBgpUpdateRateSubscription, TData, AvgBgpUpdateRateSubscriptionVariables>({ query: AvgBgpUpdateRateDocument, ...options }, handler);
};
export const AlertsDocument = gql`
    subscription alerts {
  alerts {
    data
    description
    ends_at
    fire_alert_regex
    id
    name
    query
    receiver_endpoint
    receiver_type
    severity
    starts_at
    status
    type
    vars
  }
}
    `;

export function useAlertsSubscription<TData = AlertsSubscription>(options: Omit<Urql.UseSubscriptionArgs<AlertsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<AlertsSubscription, TData>) {
  return Urql.useSubscription<AlertsSubscription, TData, AlertsSubscriptionVariables>({ query: AlertsDocument, ...options }, handler);
};
export const ActiveAlertsCountDocument = gql`
    subscription activeAlertsCount {
  alertsAggregate(where: {status: {_eq: "firing"}}) {
    aggregate {
      count
    }
  }
}
    `;

export function useActiveAlertsCountSubscription<TData = ActiveAlertsCountSubscription>(options: Omit<Urql.UseSubscriptionArgs<ActiveAlertsCountSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ActiveAlertsCountSubscription, TData>) {
  return Urql.useSubscription<ActiveAlertsCountSubscription, TData, ActiveAlertsCountSubscriptionVariables>({ query: ActiveAlertsCountDocument, ...options }, handler);
};