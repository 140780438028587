import { ReactElement } from "react";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { DrawerButtonIconAndText } from "./DrawerButtonIconAndText";
import { addSelectionIndicator } from "../other/utils";
import { isDrawerButtonSelected } from "../utils/navigation";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.url
 * @param {('_blank'|'_self'|'_top'|'_parent')=} props.target
 * @returns {ReactElement}
 */
export const DrawerButton = ({ title, url, target }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSelected = isDrawerButtonSelected(url, location.pathname);

  const onButtonClick = () => {
    if (target) {
      window.open(url, target);
    } else {
      navigate(url);
    }
  };

  const buttonSX = {
    color: "white",
    textTransform: "none",
    width: "100%",
    height: "3.063rem",
    justifyContent: "left",
    pl: "1rem",
    borderRadius: "0",
  };

  if (isSelected) {
    buttonSX.backgroundColor = (theme) => theme.color.bg.selectedDrawerButton;
    addSelectionIndicator(buttonSX);
    buttonSX[":hover"] = {
      backgroundColor: (theme) => theme.color.bg.selectedDrawerButton,
    };
  } else {
    buttonSX[":hover"] = {
      backgroundColor: (theme) => theme.color.other.onDrawerButtonHover,
    };
  }

  return (
    <Button sx={buttonSX} onClick={onButtonClick}>
      <DrawerButtonIconAndText title={title} isSelected={isSelected} />
    </Button>
  );
};
