import { ReactElement, ChangeEvent } from "react";
import { SetStateAction } from "jotai";
import { HiddenASNsResult } from "../../types/store";
import { Switch } from "@mui/material";
import { COLUMNS_ENUM } from "../../constants/components/confAutonomousSystemsTable";
import { getDropdownFilterTypeColumn } from "../../other/utils";
import { formatASN } from "../formatting";
import {
  OnAllChange,
  OnChange,
} from "../../types/components/checkboxWithLabel";
import { TableHeaderWithToggle } from "../../components/TableHeaderWithToggle";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setNumberFilter
 * @param {Function} params.setDisplayedFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setNumberFilter,
  setDisplayedFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setNumberFilter(filterList[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order][0]);
  setDisplayedFilter(filterList[COLUMNS_ENUM.DISPLAYED.order][0]);
  resetPage();
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsNumberVisible
 * @param {Function} params.setIsDisplayedVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsNumberVisible,
  setIsDisplayedVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.name] =
    setIsNumberVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DISPLAYED.name] =
    setIsDisplayedVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {object} params
 * @param {(number | undefined | ReactElement)[][]} params.tableData
 * @param {string} params.numberFilter
 * @param {string} params.displayedFilter
 * @param {boolean} params.areAllChecked
 * @param {OnAllChange} params.onSelectAllChange
 * @param {boolean} params.isNumberVisible
 * @param {boolean} params.isDisplayedVisible
 * @returns {object[]}
 */
export const getColumns = ({
  tableData,
  areAllChecked,
  onSelectAllChange,
  numberFilter,
  displayedFilter,
  isNumberVisible,
  isDisplayedVisible,
}) => [
  getDropdownFilterTypeColumn(COLUMNS_ENUM.AUTONOMOUS_SYSTEM.name, {
    filterList: numberFilter ? [numberFilter] : [],
    display: isNumberVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASN(tableData, dataIndex, COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order),
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.DISPLAYED.name, {
    filterList: displayedFilter ? [displayedFilter] : [],
    display: isDisplayedVisible,
    filterOptions: getSwitchColumnFilterOptions(),
    sort: false,
    download: false,
    customHeadLabelRender: () => (
      <TableHeaderWithToggle
        title={COLUMNS_ENUM.DISPLAYED.name}
        checked={areAllChecked}
        onClick={onSelectAllChange}
      />
    ),
  }),
];

export const getSwitchColumnFilterOptions = () => ({
  names: ["True", "False"],
  logic: (displayed, filterValue) => {
    const isChecked = displayed.props.checked;
    const filterBoolean = filterValue[0] === "True";
    return (isChecked && !filterBoolean) || (!isChecked && filterBoolean);
  },
});

/**
 * @param {object} params
 * @param {number[]} params.configuredASNs
 * @param {HiddenASNsResult} params.hiddenASNs
 * @param {function(SetStateAction<HiddenASNsResult>): void} params.setHiddenASNs
 * @param {OnChange} params.onSelectChange
 * @returns {(number | undefined | ReactElement)[][]}
 */
export const getTableData = ({
  configuredASNs,
  hiddenASNs,
  setHiddenASNs,
  onSelectChange,
}) =>
  configuredASNs.map((asn) => [
    asn,
    <Switch
      sx={{ ml: "3.3rem" }}
      key={asn}
      checked={!hiddenASNs.has(asn)}
      onChange={(event) =>
        onASNDisplayedSwitchClick({
          event,
          asn,
          hiddenASNs,
          setHiddenASNs,
          onSelectChange,
        })
      }
    />,
  ]);

/**
 *
 * @param {object} params
 * @param {ChangeEvent<HTMLInputElement>} params.event
 * @param {number} params.asn
 * @param {HiddenASNsResult} params.hiddenASNs
 * @param {function(SetStateAction<HiddenASNsResult>): void} params.setHiddenASNs
 * @param {OnChange} params.onSelectChange
 */
const onASNDisplayedSwitchClick = ({
  event,
  asn,
  hiddenASNs,
  setHiddenASNs,
  onSelectChange,
}) => {
  const enabled = event.target.checked;
  onSelectChange(enabled, asn);
  const newHiddenASNs = new Set(hiddenASNs);
  if (!enabled) {
    newHiddenASNs.add(asn);
  } else {
    newHiddenASNs.delete(asn);
  }
  setHiddenASNs(newHiddenASNs);
};

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order];
