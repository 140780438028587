import {
  COLUMNS_ENUM,
  FORMAT_RPKI_ENUM,
  RPKI_EMPTY_FILTER,
} from "../../constants/components/routes";
import {
  formatTime,
  getDropdownFilterTypeColumn,
  getServerSideRangeFilterColumn,
  getTextFieldFilterTypeColumn,
} from "../../other/utils";
import {
  Routes_Bool_Exp,
  RoutesSubscription,
  RoutesCountSubscription,
  Routes_Order_By,
} from "../../graphql/generated/operations";
import {
  formatASN,
  formatASNPath,
  formatPrefix,
  formatRPKIStatus,
  unformatRPKIStatus,
} from "../formatting";
import { TableCell, TableRow } from "@mui/material";
import { ROUTES } from "../../constants/dataSourcesTable";
import { DataSourcesTable } from "../../components/DataSourcesTable";
import { addServerSideOptions } from "./serverSideTable";
import {
  useRouteSourcesSubscription,
  useRouteSourcesWithNoPathSubscription,
  RouteDataSourceAssociations,
} from "../../graphql/generated/operations";
import { getRangeFilterList } from "../filtering";
import { allDataServicesDisplayed } from "./confDataServicesTable";
import { doOnViewColumnsChange, getASNInPathRegexes } from "../state";

/**
 * @param {string[] | number[] | undefined} configuredASNs
 * @param {string[] | undefined} configuredPrefixes
 * @param {string[] | undefined} configuredDataServices
 * @param {boolean} filterOnASAny
 * @returns {Routes_Bool_Exp}
 */
export const getConfFilters = (
  configuredASNs,
  configuredPrefixes,
  configuredDataServices,
  filterOnASAny
) => {
  /** @type {Routes_Bool_Exp} */
  const whereArgument = {};

  if (!configuredASNs) configuredASNs = [];
  if (!configuredPrefixes) configuredPrefixes = [];
  if (!configuredDataServices) configuredDataServices = [];

  const confASNsFilter = {
    originAutonomousSystem: {
      number: {
        _in: configuredASNs,
      },
    },
  };

  const confPrefixesFilter = {
    prefix: {
      configured_prefix_best_match: { _in: configuredPrefixes },
    },
  };

  const orArray = [confASNsFilter, confPrefixesFilter];

  if (filterOnASAny) {
    const asnsInPathFilter = {
      as_path: {
        _regex: getASNInPathRegexes(configuredASNs),
      },
    };
    orArray.push(asnsInPathFilter);
  }

  whereArgument._or = orArray;

  if (!allDataServicesDisplayed(configuredDataServices)) {
    whereArgument._and = [
      {
        routeDataSourceAssociations: {
          dataSource: {
            data_service: {
              _in: configuredDataServices,
            },
          },
        },
      },
    ];
  }

  return whereArgument;
};

/**
 * @param {Routes_Bool_Exp} filters
 * @param {string} rpkiStatusFilter
 */
const setRPKIStatusFilter = (filters, rpkiStatusFilter) => {
  if (rpkiStatusFilter === RPKI_EMPTY_FILTER) {
    filters.rpki_status = { _eq: "" };
    return;
  }
  filters.rpki_status = { _eq: unformatRPKIStatus(rpkiStatusFilter) };
};

export const addSecondsToTimeFilter = (timeFilter, seconds) => {
  if (timeFilter !== undefined) {
    try {
      let date = new Date(timeFilter);
      date.setSeconds(date.getSeconds() + seconds);
      return date.toISOString();
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};

/**
 * @param {Routes_Bool_Exp} filters
 * @param {string} minTimeFilter
 * @param {string} maxTimeFilter
 * @param {string} timeField
 */
const setTimeFilter = (filters, minTimeFilter, maxTimeFilter, timeField) => {
  const minIsoTime = minTimeFilter
    ? addSecondsToTimeFilter(minTimeFilter, -1)
    : "";
  const maxIsoTime = maxTimeFilter
    ? addSecondsToTimeFilter(maxTimeFilter, +1)
    : "";

  if (minIsoTime && maxIsoTime) {
    filters[timeField] = { _gt: minIsoTime, _lt: maxIsoTime };
  } else if (minIsoTime) {
    filters[timeField] = { _gt: minIsoTime };
  } else if (maxIsoTime) {
    filters[timeField] = { _lt: maxIsoTime };
  }
};

/**
 * @param {object} params
 * @param {string[]} params.configuredASNs
 * @param {string[]} params.configuredPrefixes
 * @param {string[]} params.configuredDataServices
 * @param {string} params.prefixFilter
 * @param {string} params.originASFilter
 * @param {string} params.neighborASFilter
 * @param {string} params.asPathFilter
 * @param {string} params.communitiesFilter
 * @param {string} params.rpkiStatusFilter
 * @param {string} params.peerIPFilter
 * @param {string} params.peerASFilter
 * @param {string} params.minTimeInsertedFilter
 * @param {string} params.maxTimeInsertedFilter
 * @param {string} params.minTimeUpdatedFilter
 * @param {string} params.maxTimeUpdatedFilter
 * @param {boolean} params.filterOnASAny
 * @returns {Routes_Bool_Exp}
 */
export const getWhereArgument = ({
  configuredASNs,
  configuredPrefixes,
  configuredDataServices,
  prefixFilter,
  originASFilter,
  neighborASFilter,
  asPathFilter,
  communitiesFilter,
  rpkiStatusFilter,
  peerIPFilter,
  peerASFilter,
  minTimeInsertedFilter,
  maxTimeInsertedFilter,
  minTimeUpdatedFilter,
  maxTimeUpdatedFilter,
  filterOnASAny,
}) => {
  /** @type {Routes_Bool_Exp} */
  const filters = getConfFilters(
    configuredASNs,
    configuredPrefixes,
    configuredDataServices,
    filterOnASAny
  );

  if (prefixFilter) {
    filters.prefix = { network_str: { _iregex: prefixFilter } };
  }
  if (originASFilter) {
    filters.originAutonomousSystem = { number: { _eq: originASFilter } };
  }
  if (neighborASFilter) {
    filters.neighborAutonomousSystem = { number: { _eq: neighborASFilter } };
  }
  if (asPathFilter) {
    filters.as_path = { _regex: asPathFilter };
  }
  if (communitiesFilter) {
    filters.routeDataSourceAssociations = {
      communities_string: { _regex: communitiesFilter },
    };
  }
  if (rpkiStatusFilter) {
    setRPKIStatusFilter(filters, rpkiStatusFilter);
  }
  if (minTimeInsertedFilter || maxTimeInsertedFilter) {
    setTimeFilter(
      filters,
      minTimeInsertedFilter,
      maxTimeInsertedFilter,
      "time_inserted"
    );
  }
  if (minTimeUpdatedFilter || maxTimeUpdatedFilter) {
    setTimeFilter(
      filters,
      minTimeUpdatedFilter,
      maxTimeUpdatedFilter,
      "time_updated"
    );
  }

  if (peerIPFilter && peerASFilter) {
    filters.routeDataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: { _iregex: peerIPFilter + "|" + peerASFilter },
          },
        },
      },
    };
  } else if (peerIPFilter) {
    filters.routeDataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: { _iregex: peerIPFilter },
          },
        },
      },
    };
  } else if (peerASFilter) {
    filters.routeDataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: { _iregex: peerASFilter },
          },
        },
      },
    };
  }

  return filters;
};

/**
 * @param {object} params
 * @param {string} params.sortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @returns {Routes_Order_By[]}
 */
export const getOrderArgument = ({
  sortOrderColumnName,
  sortOrderDirection,
}) => {
  /** @type {Routes_Order_By[]} */
  const orderArgument = [];

  switch (sortOrderColumnName) {
    case COLUMNS_ENUM.PREFIX.name:
      orderArgument.push({ prefix: { network: sortOrderDirection } });
      break;
    case COLUMNS_ENUM.ORIGIN_AS.name:
      orderArgument.push({
        originAutonomousSystem: { number: sortOrderDirection },
      });
      break;
    case COLUMNS_ENUM.NEIGHBOR_AS.name:
      orderArgument.push({
        neighborAutonomousSystem: { number: sortOrderDirection },
      });
      break;
    case COLUMNS_ENUM.AS_PATH.name:
      orderArgument.push({ as_path: sortOrderDirection });
      break;
    case COLUMNS_ENUM.RPKI_STATUS.name:
      orderArgument.push({ rpki_status: sortOrderDirection });
      break;
    case COLUMNS_ENUM.TIME_INSERTED.name:
      orderArgument.push({ time_inserted: sortOrderDirection });
      break;
    case COLUMNS_ENUM.TIME_UPDATED.name:
      orderArgument.push({ time_updated: sortOrderDirection });
      break;
    default:
      break;
  }

  orderArgument.push({ id: "asc" });

  return orderArgument;
};

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setPrefixFilter
 * @param {Function} params.setOriginASFilter
 * @param {Function} params.setNeighborASFilter
 * @param {Function} params.setASPathFilter
 * @param {Function} params.setCommunitiesFilter
 * @param {Function} params.setRpkiStatusFilter
 * @param {Function} params.setPeerIPFilter
 * @param {Function} params.setPeerASFilter
 * @param {Function} params.setMinTimeInsertedFilter
 * @param {Function} params.setMaxTimeInsertedFilter
 * @param {Function} params.setMinTimeUpdatedFilter
 * @param {Function} params.setMaxTimeUpdatedFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setPrefixFilter,
  setOriginASFilter,
  setNeighborASFilter,
  setASPathFilter,
  setCommunitiesFilter,
  setRpkiStatusFilter,
  setPeerIPFilter,
  setPeerASFilter,
  setMinTimeInsertedFilter,
  setMaxTimeInsertedFilter,
  setMinTimeUpdatedFilter,
  setMaxTimeUpdatedFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setPrefixFilter(filterList[COLUMNS_ENUM.PREFIX.order][0]);
  setOriginASFilter(filterList[COLUMNS_ENUM.ORIGIN_AS.order][0]);
  setNeighborASFilter(filterList[COLUMNS_ENUM.NEIGHBOR_AS.order][0]);
  setASPathFilter(filterList[COLUMNS_ENUM.AS_PATH.order][0]);
  setCommunitiesFilter(filterList[COLUMNS_ENUM.COMMUNITIES.order][0]);
  setRpkiStatusFilter(filterList[COLUMNS_ENUM.RPKI_STATUS.order][0]);
  setPeerIPFilter(filterList[COLUMNS_ENUM.PEER_IP.order][0]);
  setPeerASFilter(filterList[COLUMNS_ENUM.PEER_AS.order][0]);
  setMinTimeInsertedFilter(filterList[COLUMNS_ENUM.TIME_INSERTED.order][0]);
  setMaxTimeInsertedFilter(filterList[COLUMNS_ENUM.TIME_INSERTED.order][1]);
  setMinTimeUpdatedFilter(filterList[COLUMNS_ENUM.TIME_UPDATED.order][0]);
  setMaxTimeUpdatedFilter(filterList[COLUMNS_ENUM.TIME_UPDATED.order][1]);
  resetPage();
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsPrefixVisible
 * @param {Function} params.setIsOriginASVisible
 * @param {Function} params.setIsNeighborASVisible
 * @param {Function} params.setIsASPathVisible
 * @param {Function} params.setIsCommunitiesVisible
 * @param {Function} params.setIsRpkiStatusVisible
 * @param {Function} params.setIsTimeInsertedVisible
 * @param {Function} params.setIsTimeUpdatedVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsPrefixVisible,
  setIsOriginASVisible,
  setIsNeighborASVisible,
  setIsASPathVisible,
  setIsCommunitiesVisible,
  setIsRpkiStatusVisible,
  setIsTimeInsertedVisible,
  setIsTimeUpdatedVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.PREFIX.name] = setIsPrefixVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.ORIGIN_AS.name] =
    setIsOriginASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.NEIGHBOR_AS.name] =
    setIsNeighborASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.AS_PATH.name] = setIsASPathVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.COMMUNITIES.name] =
    setIsCommunitiesVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.RPKI_STATUS.name] =
    setIsRpkiStatusVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.TIME_INSERTED.name] =
    setIsTimeInsertedVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.TIME_UPDATED.name] =
    setIsTimeUpdatedVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {object} params
 * @param {string[]} params.rows
 * @param {string} params.prefixFilter
 * @param {string} params.originASFilter
 * @param {string} params.neighborASFilter
 * @param {string} params.asPathFilter
 * @param {string} params.communitiesFilter
 * @param {string} params.rpkiStatusFilter
 * @param {string} params.peerIPFilter
 * @param {string} params.peerASFilter
 * @param {string} params.minTimeInsertedFilter
 * @param {string} params.maxTimeInsertedFilter
 * @param {string} params.minTimeUpdatedFilter
 * @param {string} params.maxTimeUpdatedFilter
 * @param {Function} params.setMinTimeInsertedFilter
 * @param {Function} params.setMaxTimeInsertedFilter
 * @param {Function} params.setMinTimeUpdatedFilter
 * @param {Function} params.setMaxTimeUpdatedFilter
 * @param {boolean} params.isPrefixVisible
 * @param {boolean} params.isOriginASVisible
 * @param {boolean} params.isNeighborASVisible
 * @param {boolean} params.isASPathVisible
 * @param {boolean} params.isCommunitiesVisible
 * @param {boolean} params.isRpkiStatusVisible
 * @param {boolean} params.isTimeInsertedVisible
 * @param {boolean} params.isTimeUpdatedVisible
 * @returns {object[]}
 */
export const getColumns = ({
  rows: routes,
  prefixFilter,
  originASFilter,
  neighborASFilter,
  asPathFilter,
  communitiesFilter,
  rpkiStatusFilter,
  peerIPFilter,
  peerASFilter,
  minTimeInsertedFilter,
  maxTimeInsertedFilter,
  minTimeUpdatedFilter,
  maxTimeUpdatedFilter,
  setMinTimeInsertedFilter,
  setMaxTimeInsertedFilter,
  setMinTimeUpdatedFilter,
  setMaxTimeUpdatedFilter,
  isPrefixVisible,
  isOriginASVisible,
  isNeighborASVisible,
  isASPathVisible,
  isCommunitiesVisible,
  isRpkiStatusVisible,
  isTimeInsertedVisible,
  isTimeUpdatedVisible,
}) => [
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PREFIX.name, {
    filterList: prefixFilter ? [prefixFilter] : [],
    display: isPrefixVisible,
    customBodyRenderLite: (dataIndex) =>
      formatPrefix(routes, dataIndex, COLUMNS_ENUM.PREFIX.order),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.ORIGIN_AS.name, {
    filterList: originASFilter ? [originASFilter] : [],
    display: isOriginASVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASN(routes, dataIndex, COLUMNS_ENUM.ORIGIN_AS.order),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.NEIGHBOR_AS.name, {
    filterList: neighborASFilter ? [neighborASFilter] : [],
    display: isNeighborASVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASN(routes, dataIndex, COLUMNS_ENUM.NEIGHBOR_AS.order),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.AS_PATH.name, {
    filterList: asPathFilter ? [asPathFilter] : [],
    display: isASPathVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASNPath(routes, dataIndex, COLUMNS_ENUM.AS_PATH.order),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.COMMUNITIES.name, {
    filterList: communitiesFilter ? [communitiesFilter] : [],
    display: isCommunitiesVisible,
    sort: false,
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.RPKI_STATUS.name, {
    filterList: rpkiStatusFilter ? [rpkiStatusFilter] : [],
    display: isRpkiStatusVisible,
    filterOptions: {
      names: [
        FORMAT_RPKI_ENUM.valid,
        FORMAT_RPKI_ENUM.invalid,
        FORMAT_RPKI_ENUM["not-found"],
        RPKI_EMPTY_FILTER,
      ],
    },
    customBodyRenderLite: (dataIndex) =>
      formatRPKIStatus(routes, dataIndex, COLUMNS_ENUM.RPKI_STATUS.order),
  }),
  getServerSideRangeFilterColumn(
    COLUMNS_ENUM.TIME_INSERTED.name,
    {
      filterList: getRangeFilterList(
        minTimeInsertedFilter,
        maxTimeInsertedFilter
      ),
      display: isTimeInsertedVisible,
      customBodyRender: formatTime,
    },
    setMinTimeInsertedFilter,
    setMaxTimeInsertedFilter
  ),
  getServerSideRangeFilterColumn(
    COLUMNS_ENUM.TIME_UPDATED.name,
    {
      filterList: getRangeFilterList(
        minTimeUpdatedFilter,
        maxTimeUpdatedFilter
      ),
      display: isTimeUpdatedVisible,
      customBodyRender: formatTime,
    },
    setMinTimeUpdatedFilter,
    setMaxTimeUpdatedFilter
  ),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PEER_IP.name, {
    filterList: peerIPFilter ? [peerIPFilter] : [],
    display: "excluded",
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PEER_AS.name, {
    filterList: peerASFilter ? [peerASFilter] : [],
    display: "excluded",
  }),
];

/**
 * @param {object} params
 * @param {string[][]} params.rows
 * @param {string} params.sortOrderColumnName
 * @param {Function} params.setSortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @param {Function} params.setSortOrderDirection
 * @param {boolean} params.isSubscriptionActive
 * @returns {object}
 */
export const getCustomOptions = ({
  rows: routes,
  sortOrderColumnName,
  setSortOrderColumnName,
  sortOrderDirection,
  setSortOrderDirection,
  isSubscriptionActive,
}) => {
  const customOptions = {
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const prefix = routes[rowMeta.dataIndex][COLUMNS_ENUM.PREFIX.order];
      const path = routes[rowMeta.dataIndex][COLUMNS_ENUM.AS_PATH.order];
      const UnformattedASpath = getUnformattedASpath(path);
      let subscriptionHook = useRouteSourcesSubscription;
      let subscriptionVariables = { prefix, path: UnformattedASpath };
      let title = `Data Sources of Route ${prefix} - ${path}`;
      if (!path) {
        subscriptionHook = useRouteSourcesWithNoPathSubscription;
        subscriptionVariables = { prefix };
        title = `Data Sources of Route ${prefix}`;
      }
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <DataSourcesTable
              sourcesSubscription={subscriptionHook}
              options={{
                variables: subscriptionVariables,
                pause: !isSubscriptionActive,
              }}
              title={title}
              parentComponent={{ name: ROUTES }}
              atomKey={`Routes-${prefix}-${path}`}
            />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: "No Routes found.",
      },
    },
  };

  addServerSideOptions({
    options: customOptions,
    sortOrderColumnName,
    setSortOrderColumnName,
    sortOrderDirection,
    setSortOrderDirection,
  });

  return customOptions;
};

/**
 * @param {RouteDataSourceAssociations[]} routeDataSourceAssociations
 * @returns {Set<string>}
 */
export const getCommunitiesSet = (routeDataSourceAssociations) => {
  const communitiesSet = new Set();
  routeDataSourceAssociations?.forEach(({ communities_string }) =>
    communities_string
      ?.split(" ")
      .forEach((community) => communitiesSet.add(community))
  );
  return communitiesSet;
};

/**
 * @param {string} as_path
 * @returns {string}
 */
export const getFormattedASpath = (as_path) => {
  const asnArray = as_path.split(" ");
  let result = "";
  let counter = 1;
  for (let i = 0; i < asnArray.length; i++) {
    if (asnArray[i] === asnArray[i + 1]) {
      counter++;
    } else {
      if (counter !== 1) {
        result += asnArray[i] + " (" + counter + ") ";
      } else {
        result += asnArray[i] + " ";
      }
      counter = 1;
    }
  }
  return result.trim();
};

/**
 * @param {string} as_path
 * @returns {string}
 */
export const getUnformattedASpath = (as_path) => {
  if (as_path) {
    const asnArray = as_path.split(" ");
    let result = "";
    for (let i = 0; i < asnArray.length - 1; i++) {
      if (isNaN(asnArray[i + 1]) && !isNaN(asnArray[i])) {
        const repeatCount = asnArray[i + 1].match(/\d+/)[0];
        for (let c = 0; c < repeatCount; c++) {
          result += asnArray[i] + " ";
        }
      } else if (isNaN(asnArray[i]) && !isNaN(asnArray[i + 1])) {
        continue;
      } else {
        result += asnArray[i] + " ";
      }
    }
    if (!isNaN(asnArray[asnArray.length - 1])) {
      result += asnArray[asnArray.length - 1];
    }
    return result.trim();
  } else {
    return "";
  }
};

/**
 * @param {object} params
 * @param {RoutesSubscription | undefined} params.data
 * @returns {(string|number)[][]}
 */
export const getRows = ({ data }) => {
  /** @type {(string|number)[][]} */
  const routeRows = [];

  data?.routes.forEach(
    ({
      prefix,
      originAutonomousSystem,
      neighborAutonomousSystem,
      as_path,
      routeDataSourceAssociations,
      rpki_status,
      time_inserted,
      time_updated,
    }) => {
      const communitiesSet = getCommunitiesSet(routeDataSourceAssociations);
      const sortedCommunities = Array.from(communitiesSet).sort().join("\n");
      const formattedASPATH = getFormattedASpath(as_path);

      /** @type {string[]} */
      const routeRowData = [
        prefix?.network,
        originAutonomousSystem?.number,
        neighborAutonomousSystem?.number,
        formattedASPATH,
        sortedCommunities,
        rpki_status,
        time_inserted,
        time_updated,
      ];
      routeRows.push(routeRowData);
    }
  );

  return routeRows;
};

/**
 * @param {object} params
 * @param {RoutesCountSubscription | undefined} params.data
 * @returns {number | undefined}
 */
export const getCount = ({ data }) => data?.routesAggregate?.aggregate?.count;

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => {
  const prefix = row[COLUMNS_ENUM.PREFIX.order];
  const path = row[COLUMNS_ENUM.AS_PATH.order];
  return `${prefix}-${path}`;
};
