import { useAvgBgpUpdateRateSubscription } from "../graphql/generated/operations";
import { useData } from "./useData";
import { avgBGPUpdateRateCountAtom } from "../store/overviewContent";
import { formatter } from "../utils/hooks/useAvgBGPUpdateRateCount";

export const useAvgBGPUpdateRateCount = () => {
  useData({
    operation: useAvgBgpUpdateRateSubscription,
    formatter,
    atom: avgBGPUpdateRateCountAtom,
  });
};
