export const defaultSX = {
  color: "white",
  textTransform: "none",
  height: "2.5rem",
  backgroundColor: "primary.main",
  ":hover": {
    backgroundColor: "blue5.main",
  },
  ":disabled": {
    backgroundColor: "gray1.main",
  },
};
