import { ReactElement } from "react";
import { Divider } from "@mui/material";

/**
 * @param {object} props
 * @param {object} [props.customStyles]
 * @returns {ReactElement}
 */
export const PageDivider = ({ customStyles = {} }) => {
  return (
    <Divider
      sx={{
        borderBottomWidth: (theme) => theme.space.width.divider,
        mt: (theme) => `-${theme.space.width.divider}`,
        mx: (theme) => `-${theme.space.padding.contentX}`,
        ...customStyles,
      }}
    />
  );
};
