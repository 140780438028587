import { TableCell, TableRow } from "@mui/material";
import { DataSourcesTable } from "../../components/DataSourcesTable";
import { COLUMNS_ENUM } from "../../constants/components/autonomousSystemsTable";
import { useAutonomousSystemSourcesSubscription } from "../../graphql/generated/operations";
import {
  getRangeFilterColumn,
  getTextFieldFilterTypeColumn,
} from "../../other/utils";
import { getRangeFilterList } from "../filtering";
import { formatASN, formatDataSourcesPercentage } from "../formatting";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {object} params
 * @param {string[][] | undefined} params.rows
 * @param {string} params.numberFilter
 * @param {string} params.minDataSourcesNumberFilter
 * @param {string} params.maxDataSourcesNumberFilter
 * @param {string} params.minDataSourcesPercentageFilter
 * @param {string} params.maxDataSourcesPercentageFilter
 * @param {Function} params.setMinDataSourcesNumberFilter
 * @param {Function} params.setMaxDataSourcesNumberFilter
 * @param {Function} params.setMinDataSourcesPercentageFilter
 * @param {Function} params.setMaxDataSourcesPercentageFilter
 * @param {boolean} params.isNumberVisible
 * @param {boolean} params.isDataSourcesNumberVisible
 * @param {boolean} params.isDataSourcesPercentageVisible
 * @returns {object[] | undefined}
 */
export const getColumns = ({
  rows: autonomousSystems,
  numberFilter,
  minDataSourcesNumberFilter,
  maxDataSourcesNumberFilter,
  minDataSourcesPercentageFilter,
  maxDataSourcesPercentageFilter,
  setMinDataSourcesNumberFilter,
  setMaxDataSourcesNumberFilter,
  setMinDataSourcesPercentageFilter,
  setMaxDataSourcesPercentageFilter,
  isNumberVisible,
  isDataSourcesNumberVisible,
  isDataSourcesPercentageVisible,
}) => {
  if (!autonomousSystems) return;
  return [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.AUTONOMOUS_SYSTEM.name, {
      filterList: numberFilter ? [numberFilter] : [],
      display: isNumberVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASN(
          autonomousSystems,
          dataIndex,
          COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order
        ),
    }),
    getRangeFilterColumn(
      COLUMNS_ENUM.DATA_SOURCES_NUMBER.name,
      {
        filterList: getRangeFilterList(
          minDataSourcesNumberFilter,
          maxDataSourcesNumberFilter
        ),
        display: isDataSourcesNumberVisible,
      },
      setMinDataSourcesNumberFilter,
      setMaxDataSourcesNumberFilter
    ),
    getRangeFilterColumn(
      COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.name,
      {
        filterList: getRangeFilterList(
          minDataSourcesPercentageFilter,
          maxDataSourcesPercentageFilter
        ),
        display: isDataSourcesPercentageVisible,
        customBodyRenderLite: (dataIndex) =>
          formatDataSourcesPercentage(
            autonomousSystems,
            dataIndex,
            COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order
          ),
        sort: false,
      },
      setMinDataSourcesPercentageFilter,
      setMaxDataSourcesPercentageFilter
    ),
  ];
};

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setNumberFilter
 * @param {Function} params.setMinDataSourcesNumberFilter
 * @param {Function} params.setMaxDataSourcesNumberFilter
 * @param {Function} params.setMinDataSourcesPercentageFilter
 * @param {Function} params.setMaxDataSourcesPercentageFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setNumberFilter,
  setMinDataSourcesNumberFilter,
  setMaxDataSourcesNumberFilter,
  setMinDataSourcesPercentageFilter,
  setMaxDataSourcesPercentageFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setNumberFilter(filterList[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order][0]);
  setMinDataSourcesNumberFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_NUMBER.order][0]
  );
  setMaxDataSourcesNumberFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_NUMBER.order][1]
  );
  setMinDataSourcesPercentageFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order][0]
  );
  setMaxDataSourcesPercentageFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order][1]
  );
  resetPage();
};

/**
 * @param {object} params
 * @param {string[][] | undefined} params.autonomousSystems
 * @returns {object | undefined}
 */
export const getCustomOptions = ({ autonomousSystems }) => {
  if (!autonomousSystems) return;
  return {
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const asn =
        autonomousSystems[rowMeta.dataIndex][
          COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order
        ];
      const title = `Data Sources of ASN ${asn}`;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <DataSourcesTable
              sourcesSubscription={useAutonomousSystemSourcesSubscription}
              options={{ variables: { asn } }}
              title={title}
              atomKey={`AutonomousSystems-${asn}`}
            />
          </TableCell>
        </TableRow>
      );
    },
  };
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsNumberVisible
 * @param {Function} params.setIsDataSourcesNumberVisible
 * @param {Function} params.setIsDataSourcesPercentageVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsNumberVisible,
  setIsDataSourcesNumberVisible,
  setIsDataSourcesPercentageVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.name] =
    setIsNumberVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SOURCES_NUMBER.name] =
    setIsDataSourcesNumberVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.name] =
    setIsDataSourcesPercentageVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order];
