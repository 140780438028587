// App Bar
export const menuToggleButtonAriaLabel = "menu icon";
export const logoButtonAriaLabel = "logo icon";

// Styles
export const theme = {
  // MUI
  palette: {
    background: {
      default: "#f9f9f9",
      paper: "#f9f9f9",
    },
    primary: {
      main: "#0c3153",
    },
    gray1: {
      main: "#e8eaeb",
    },
    gray2: {
      main: "#797979",
    },
    gray3: {
      main: "#4c7090",
    },
    gray4: {
      main: "#e3e7ec",
    },
    gray5: {
      main: "#929292",
    },
    gray6: {
      main: "#e0e0e0",
    },
    blue1: {
      main: "#001529",
    },
    blue2: {
      main: "#072036",
    },
    blue3: {
      main: "#1268fb",
    },
    blue4: {
      main: "#7fa9ee",
    },
    blue5: {
      main: "#053a6a",
    },
    blue6: {
      main: "#458ccd",
    },
    blue7: {
      main: "#3b62f0",
    },
    blue8: {
      main: "#092b50",
    },
    blue9: {
      main: "#1846f0",
    },
    blue10: {
      main: "#dce9ff",
    },
    blue11: {
      main: "#bdd3f9",
    },
    red1: {
      main: "#ab221d",
      light: "#bc4e4a",
    },
    red2: {
      main: "#d32f2f",
    },
    red3: {
      main: "#aa0000",
    },
    red4: {
      main: "#ff0000",
    },
    green1: {
      main: "#10af63",
    },
    green2: {
      main: "#008550",
    },
    green3: {
      main: "#00FC95",
    },
    green4: {
      main: "#04967C",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    // Tables
    MUIDataTable: {
      styleOverrides: {
        paper: {},
        tableRoot: {
          // This is for the table head cell borders
          // not to appear transparent upon scrolling
          borderCollapse: "separate",
          backgroundColor: "#ffffff",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: "2rem",
          paddingBottom: "2rem",
        },
      },
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          paddingTop: "2rem",
          paddingBottom: "2rem",
        },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: {
          marginBottom: "1rem",
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          padding: 0,
        },
        toolButton: {
          marginLeft: 0,
          marginRight: 0,
        },
        data: {
          whiteSpace: "nowrap",
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: "#e3e7ec",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          paddingLeft: "0.3rem",
          whiteSpace: "pre",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  // Custom
  color: {
    bg: {
      appBar: "#001529",
      drawer: "#072036",
      content: "#f9f9f9",
      selectedDrawerButton: "#053a6a",
      helpText: "#ecf3ff",
    },
    other: {
      selectionIndicator: "#1268fb",
      onDrawerButtonHover: "#0c3253",
    },
    button: {
      sendUsAnEmail: "#184d7e",
      onHover: {
        sendUsAnEmail: "#1d5b95",
        action: "#053a6a",
      },
    },
  },
  space: {
    width: {
      divider: "0.14rem",
      selectionIndicator: "0.313rem",
      drawer: "16rem",
    },
    height: {
      appBar: "4rem",
      helpText: "9.5rem",
    },
    padding: {
      contentX: "1.5rem",
      contentY: "1.5rem",
    },
  },
};

// Other
export const TEST_USER_SUB = "testerID";
export const TEST_USER_NICKNAME = "tester nickname";
export const TEST_USER_GIVEN_NAME = "tester name";
export const TEST_USER_FAMILY_NAME = "tester surname";
export const TEST_USER_EMAIL = "tester@codebgp.com";
export const TENANT_NAME_KEY = "tenantName";

export const defaultEditorQuery = `query AutonomousSystemNumbers {
  autonomousSystems(order_by: {number: asc}) {
    number
  }
}
`;

export const ROLES = {
  viewer: "viewer",
  editor: "editor",
};
