import { atom } from "jotai";
import {
  INITIAL_COUNT,
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";

export const pageAtom = atom(INITIAL_PAGE);
export const rowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const countAtom = atom(INITIAL_COUNT);
export const expandedRowIDsAtom = atom(new Set());

export const numberFilterAtom = atom("");
export const minDataSourcesNumberFilterAtom = atom("");
export const maxDataSourcesNumberFilterAtom = atom("");
export const minDataSourcesPercentageFilterAtom = atom("");
export const maxDataSourcesPercentageFilterAtom = atom("");

export const isNumberVisibleAtom = atom(true);
export const isDataSourcesNumberVisibleAtom = atom(true);
export const isDataSourcesPercentageVisibleAtom = atom(true);
