import { ReactElement } from "react";
import { OnChange } from "../types/components/checkboxWithLabel";
import { Checkbox, Divider, FormControlLabel, Stack } from "@mui/material";

/**
 * @param {object} props
 * @param {string} props.label
 * @param {boolean} [props.enableDivider]
 * @param {object} [props.customStyles]
 * @param {boolean} [props.checked]
 * @param {OnChange} props.onChange
 * @returns {ReactElement}
 */
export const CheckboxWithLabel = ({
  label,
  enableDivider = true,
  customStyles,
  checked,
  onChange,
}) => {
  const dividerJSX = enableDivider ? <Divider /> : null;
  return (
    <Stack>
      <FormControlLabel
        sx={{ ...customStyles }}
        control={<Checkbox />}
        label={label}
        checked={checked}
        onChange={(e, checked) => {
          onChange(checked, label);
        }}
      />
      {dividerJSX}
    </Stack>
  );
};
