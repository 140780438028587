import { auth0HookGetter, createGraphiqlFetcher } from "../other/utils";
import { useAccessToken } from "./useAccessToken";
import { useClient } from "urql";
import { useEffect, useState } from "react";

export const useFetcher = () => {
  const { user } = auth0HookGetter()();
  const accessToken = useAccessToken();
  const urqlClient = useClient();
  const [fetcher, setFetcher] = useState();

  useEffect(() => {
    setFetcher(() =>
      createGraphiqlFetcher(user, accessToken, urqlClient.wsClient)
    );
  }, [user, accessToken, urqlClient.wsClient]);

  return fetcher;
};
