import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import {
  INITIAL_COUNT,
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";
import { LOCAL_STORAGE_PREFIX } from "../constants/store";

export const isHelpTextOpenAtom = atomWithStorage(LOCAL_STORAGE_PREFIX, true);
export const pageAtom = atom(INITIAL_PAGE);
export const rowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const countAtom = atom(INITIAL_COUNT);
export const selectedRowIDsAtom = atom(new Set());
export const expandedRowIDsAtom = atom(new Set());
export const nameFilterAtom = atom("");
export const typeFilterAtom = atom("");
export const minTimeStartedFilterAtom = atom("");
export const maxTimeStartedFilterAtom = atom("");
export const minTimeEndedFilterAtom = atom("");
export const maxTimeEndedFilterAtom = atom("");
