import useSWRImmutable from "swr/immutable";
import { getIDFromJSON } from "../utils/peeringDB";

export const useASPeeringDBID = (asn) => {
  const { data, error } = useSWRImmutable(
    `https://www.peeringdb.com/api/net?asn__in=${asn}`
  );

  let id;

  if (data) {
    id = getIDFromJSON(data);
  }

  return {
    data: id,
    loading: !error && !data,
    error,
  };
};
