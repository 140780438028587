import { PolledBgpNeighborsQuery } from "../../graphql/generated/operations";
import { isNonEmptyArray } from "../validation";

/**
 * @param {PolledBgpNeighborsQuery | undefined} data
 * @param {string[]} [configuredASNs]
 * @returns {object | null}
 */
export const formatter = (data, configuredASNs) => {
  if (!configuredASNs || !isNonEmptyArray(configuredASNs)) return null;

  const neighborsSet = new Set();
  // const myASNNeighborPairs = new Map();

  data?.peerings.forEach(({ leftAutonomousSystem, rightAutonomousSystem }) => {
    const leftASN = leftAutonomousSystem?.number;
    const rightASN = rightAutonomousSystem?.number;
    // const [myASN, neighborASN] = configuredASNs.includes(leftASN)
    const neighborASN = configuredASNs.includes(leftASN) ? rightASN : leftASN;
    neighborsSet.add(neighborASN);
    // const pairKey = `${myASN}${neighborASN}`;
    // const pairObject = {
    //   myASN,
    //   neighborASN,
    // };
    // myASNNeighborPairs.set(pairKey, pairObject);
  });

  const neighbors = [...neighborsSet].sort((a, b) => a - b);
  // const pairs = [...myASNNeighborPairs.values()];

  // return { neighbors, pairs };
  return neighbors;
};
