import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {ReactElement} [props.textField]
 * @returns {ReactElement}
 */
export const QueryAnswerInput = ({ title, textField }) => {
  return (
    <Stack direction="row">
      <Typography>{title}</Typography>
      {textField}
    </Stack>
  );
};
