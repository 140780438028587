import { useASNs } from "./useASNs";
import { useAlerts } from "./useAlerts";
import { usePeerings } from "./usePeerings";
import { usePrefixes } from "./usePrefixes";
import { useRoutes } from "./useRoutes";
import { useRpkiRoas } from "./useRpkiRoas";

export const useStateData = () => {
  usePrefixes();
  useASNs();
  usePeerings();
  useRoutes();
  useRpkiRoas();
  useAlerts();
};
