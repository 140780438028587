import { useState } from "react";
import PropTypes from "prop-types";

export const useOpenCloseSnackbar = ({ initialState = false } = {}) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = () => {
    setIsOpen(true);
  };

  const close = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  return { isOpen, open, close };
};

useOpenCloseSnackbar.propTypes = {
  initialState: PropTypes.bool.isRequired,
};
