import { useAutonomousSystemsCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useAutonomousSystemsCount";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredDataServicesAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import { autonomousSystemsCountAtom } from "../store/overviewContent";

export const useAutonomousSystemsCount = () => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );
  const [
    {
      data: dataServices,
      fetching: dataServicesFetching,
      error: dataServicesError,
    },
  ] = useAtom(displayedConfiguredDataServicesAtom);

  const confState = {
    fetching: asnsFetching || dataServicesFetching,
    error: asnsError ?? dataServicesError,
  };

  useData({
    operation: useAutonomousSystemsCountSubscription,
    options: {
      variables: { asns, dataServices },
      pause: !asns,
    },
    formatter,
    confState,
    atom: autonomousSystemsCountAtom,
  });
};
