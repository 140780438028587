import { useAutonomousSystemNumbersSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useAllASNs";
import { allASNsAtom } from "../other/store";
import { useData } from "./useData";

export const useAllASNs = () => {
  useData({
    operation: useAutonomousSystemNumbersSubscription,
    formatter,
    atom: allASNsAtom,
  });
};
