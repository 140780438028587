import { Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { HELP_TEXT_ENUM, TITLE_ENUM } from "../constants/pageHeader";
import { PageTabs } from "../components/PageTabs";
import { useState } from "react";
import { getAlertsPageTabs } from "../other/utils";
import { ActiveAlerts } from "../tabs/ActiveAlerts";
import { ResolvedAlerts } from "../tabs/ResolvedAlerts";

export const Alerts = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const [helptext, setHelpText] = useState(HELP_TEXT_ENUM.alerts.active);

  const tabs = getAlertsPageTabs(<ActiveAlerts />, <ResolvedAlerts />);

  const selectedTabCallback = ({ selectedTab }) => {
    switch (selectedTab) {
      case "active":
        setHelpText(HELP_TEXT_ENUM.alerts.active);
        break;
      case "resolved":
        setHelpText(HELP_TEXT_ENUM.alerts.resolved);
        break;
      default:
        break;
    }
  };

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.alerts}
        helpText={helptext}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <PageTabs tabs={tabs} selectedTabCallback={selectedTabCallback} />
    </Stack>
  );
};
