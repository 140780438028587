import { Stack } from "@mui/material";
import { useState } from "react";
import { OverviewContent } from "../components/OverviewContent";
import { PageTitle } from "../components/PageTitle";
import { SvgWrapper } from "../components/SvgWrapper";
import { useUsername } from "../hooks/useUsername";

export const Overview = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const { name } = useUsername();

  const smileyIcon = (
    <SvgWrapper
      filename="SmileyIcon"
      sx={{ color: "blue4.main", mr: "1rem", fontSize: 35 }}
    />
  );
  const title = name ? (
    <Stack direction="row" sx={{ height: "1.75rem", alignItems: "center" }}>
      {smileyIcon}
      Welcome, {name} | Overview
    </Stack>
  ) : (
    ""
  );

  return (
    <Stack>
      <PageTitle
        text={title}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <OverviewContent />
    </Stack>
  );
};
