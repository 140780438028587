import { ReactElement } from "react";
import Button from "@mui/material/Button";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import {
  addSelectionIndicator,
  compensatePositionForSelectionIndicator,
} from "../other/utils";
import { isDrawerDropdownChildSelected } from "../utils/navigation";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.url
 * @param {boolean} props.isCategorySelected
 * @returns {ReactElement}
 */
export const DrawerDropdownChild = ({ title, url, isCategorySelected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSelected = isDrawerDropdownChildSelected(url, location.pathname);

  const onButtonClick = () => {
    navigate(url);
  };

  const accordionDetailsSX = { p: 0 };
  const buttonSX = {
    color: "white",
    textTransform: "none",
    width: "100%",
    height: "3rem",
    justifyContent: "left",
    pl: "3.5rem",
  };
  const buttonTextSX = {};

  if (isSelected) {
    accordionDetailsSX.backgroundColor = (theme) =>
      theme.color.bg.selectedDrawerButton;
    buttonSX[":hover"] = {
      backgroundColor: (theme) => theme.color.bg.selectedDrawerButton,
    };
  } else {
    buttonSX[":hover"] = {
      backgroundColor: (theme) => theme.color.other.onDrawerButtonHover,
    };
  }

  if (isCategorySelected) {
    addSelectionIndicator(accordionDetailsSX);
    compensatePositionForSelectionIndicator(buttonTextSX);
  }

  return (
    <AccordionDetails sx={accordionDetailsSX}>
      <Button sx={buttonSX} onClick={onButtonClick}>
        <Typography sx={buttonTextSX} noWrap fontSize="0.875rem">
          {title}
        </Typography>
      </Button>
    </AccordionDetails>
  );
};
