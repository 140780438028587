import useSWR from "swr";
import { getNameFromJSON } from "../utils/ripestat";

export const useASName = (asn) => {
  const { data, error } = useSWR(
    `https://stat.ripe.net/data/whois/data.json?resource=AS${asn}`
  );

  let name;

  if (data) {
    name = getNameFromJSON(data);
  }

  return {
    data: name,
    loading: !error && !data,
    error,
  };
};
