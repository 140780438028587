import { useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  useInsertManuallyConfiguredAutonomousSystemsMutation,
  useInsertManuallyConfiguredPrefixesMutation,
} from "../graphql/generated/operations";
import { useUsername } from "../hooks/useUsername";
import { SecondaryButton } from "./SecondaryButton";
import { isASNInputArrayValid, isPrefixArrayValid } from "../utils/validation";

export const WelcomeEditorDialog = ({
  isOpen,
  close,
  openSnackbar,
  setAlertMessage,
}) => {
  const { name } = useUsername();
  const [asnInput, setAsnInput] = useState([]);
  const [prefixesInput, setPrefixesInput] = useState([]);
  const [, addConfASNS] =
    useInsertManuallyConfiguredAutonomousSystemsMutation();
  const [, addConfPrefixes] = useInsertManuallyConfiguredPrefixesMutation();

  const [step, setStep] = useState(0);
  const [isValidAsnInput, setIsValidAsnInput] = useState(true);
  const [isValidPrefixInput, setIsValidPrefixInput] = useState(true);

  const welcomeSteps = [
    {
      id: 0,
      placeholder: "Your AS(es)",
      underline1: "Add your AS(es) in numeric format.",
      underline2: "Press ENTER to input each value.",
      skipText: "Skip for now",
      buttonText: "Add AS(es)",
      alert: "Please add your AS(es) in numeric format.",
    },
    {
      id: 1,
      placeholder: "Your Prefixes",
      underline1: "Add your Prefix(es) in CIDR format.",
      underline2: "Press ENTER to input each value.",
      skipText: "Skip for now",
      buttonText: "Add Prefix(es)",
      alert: "Please add your Prefix(es) in CIDR format.",
    },
  ];

  const getInputValues = () => {
    if (step === 0) {
      return asnInput;
    } else return prefixesInput;
  };

  const handleInput = (value) => {
    if (step === 0) {
      setAsnInput(value);
      setIsValidAsnInput(isASNInputArrayValid(value));
    } else {
      setPrefixesInput(value);
      setIsValidPrefixInput(isPrefixArrayValid(value));
    }
  };

  const handleValidation = () => {
    if (step === 0) {
      return isASNInputArrayValid(asnInput);
    } else {
      return isPrefixArrayValid(prefixesInput);
    }
  };

  const onSkip = () => {
    if (step === 0) {
      setStep(1);
    } else close();
  };

  const handleClick = async () => {
    if (step === 0) {
      await onAddASNs();
      setStep(1);
    } else {
      await onAddPrefixes();
      close();
    }
  };

  const onAddPrefixes = async () => {
    const PREFIXES = prefixesInput.map((prefix) => {
      return { network: prefix };
    });
    const { data, error } = await addConfPrefixes({ prefixes: PREFIXES });
    if (data && !error) {
      openSnackbar();
      setAlertMessage({
        type: "success",
        message: "Prefix(es) added successfully",
      });
    } else {
      openSnackbar();
      setAlertMessage({ type: "error", message: error?.message });
    }
  };

  const onAddASNs = async () => {
    const ASNS = asnInput.map((asn) => {
      return { number: asn };
    });
    const result = await addConfASNS({ asns: ASNS });
    if (result.data) {
      openSnackbar();
      setAlertMessage({
        type: "success",
        message: "AS(es) added successfully",
      });
    } else {
      openSnackbar();
      setAlertMessage({ type: "error", message: result.error?.message });
    }
  };

  return (
    <Dialog open={isOpen}>
      <Stack
        sx={{ p: "2rem", height: "17rem", justifyContent: "space-between" }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          {name}, welcome to Code BGP Platform
        </Typography>
        <Autocomplete
          multiple={true}
          key="select-prefixes"
          freeSolo={true}
          clearOnBlur={true}
          options={[]}
          value={getInputValues()}
          onChange={(event, newValue) => {
            handleInput(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                maxLength: 50,
                style: {},
              }}
              variant="standard"
              placeholder={welcomeSteps[step].placeholder}
              helperText={
                step === 0 ? (
                  <>
                    {isValidAsnInput ? (
                      <Typography
                        sx={{ color: "primary.main" }}
                        fontSize="0.875rem"
                      >
                        {welcomeSteps[step].underline1}
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#D2393D" }} fontSize="0.875rem">
                        {welcomeSteps[step].alert}
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "primary.main" }}
                      fontSize="0.875rem"
                    >
                      {welcomeSteps[step].underline2}
                    </Typography>
                  </>
                ) : (
                  <>
                    {isValidPrefixInput ? (
                      <Typography
                        sx={{ color: "primary.main" }}
                        fontSize="0.875rem"
                      >
                        {welcomeSteps[step].underline1}
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#D2393D" }} fontSize="0.875rem">
                        {welcomeSteps[step].alert}
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "primary.main" }}
                      fontSize="0.875rem"
                    >
                      {welcomeSteps[step].underline2}
                    </Typography>
                  </>
                )
              }
            ></TextField>
          )}
        />
        <Stack sx={{ justifyContent: "space-between" }} direction="row">
          <SecondaryButton
            onClick={onSkip}
            title={"Skip for now"}
            customStyles={{
              width: "unset",
              minWidth: "0",
              padding: "0",
              textDecoration: "underline",
              backgroundColor: "unset",
              borderStyle: "unset",
              ":hover": {
                backgroundColor: "unset",
                textDecoration: "underline",
              },
            }}
          />
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              width: "7rem",
              height: "2.5rem",
              backgroundColor: "primary.main",
              ":hover": {
                backgroundColor: (theme) => theme.color.button.onHover.action,
              },
              ":disabled": {
                backgroundColor: "gray1.main",
              },
            }}
            disabled={!handleValidation()}
            onClick={handleClick}
          >
            <Typography noWrap fontSize="0.875rem">
              {welcomeSteps[step].buttonText}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

WelcomeEditorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
};
