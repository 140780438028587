import { atom } from "jotai";
import { COLUMNS_ENUM } from "../constants/components/routes";
import {
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";
import { getInitialDataResult } from "../constants/store";
import { isConfigurationReadyAtom } from "../other/store";

export const routesTablePageAtom = atom(INITIAL_PAGE);
export const routesTableRowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const expandedRowIDsAtom = atom(new Set());
export const routesTableIsSubscriptionActiveAtom = atom((get) =>
  get(isConfigurationReadyAtom)
);
export const routesTablePrefixFilterAtom = atom("");
export const routesTableOriginASFilterAtom = atom("");
export const routesTableNeighborASFilterAtom = atom("");
export const routesTableASPathFilterAtom = atom("");
export const routesTableCommunitiesFilterAtom = atom("");
export const routesTableRpkiStatusFilterAtom = atom("");
export const routesTablePeerIPFilterAtom = atom("");
export const routesTablePeerASFilterAtom = atom("");
export const routesTableMinTimeInsertedFilterAtom = atom("");
export const routesTableMaxTimeInsertedFilterAtom = atom("");
export const routesTableMinTimeUpdatedFilterAtom = atom("");
export const routesTableMaxTimeUpdatedFilterAtom = atom("");
export const routesTableFilterStateAtom = atom((get) => ({
  prefixFilter: get(routesTablePrefixFilterAtom),
  originASFilter: get(routesTableOriginASFilterAtom),
  neighborASFilter: get(routesTableNeighborASFilterAtom),
  asPathFilter: get(routesTableASPathFilterAtom),
  communitiesFilter: get(routesTableCommunitiesFilterAtom),
  rpkiStatusFilter: get(routesTableRpkiStatusFilterAtom),
  peerIPFilter: get(routesTablePeerIPFilterAtom),
  peerASFilter: get(routesTablePeerASFilterAtom),
  minTimeInsertedFilter: get(routesTableMinTimeInsertedFilterAtom),
  maxTimeInsertedFilter: get(routesTableMaxTimeInsertedFilterAtom),
  minTimeUpdatedFilter: get(routesTableMinTimeUpdatedFilterAtom),
  maxTimeUpdatedFilter: get(routesTableMaxTimeUpdatedFilterAtom),
}));
export const isPrefixVisibleAtom = atom(true);
export const isOriginASVisibleAtom = atom(true);
export const isNeighborASVisibleAtom = atom(true);
export const isASPathVisibleAtom = atom(true);
export const isCommunitiesVisibleAtom = atom(false);
export const isRpkiStatusVisibleAtom = atom(true);
export const isTimeInsertedVisibleAtom = atom(false);
export const isTimeUpdatedVisibleAtom = atom(true);
export const routesTableSubscriptionAtom = atom(getInitialDataResult([""]));
export const routesTableCountSubscriptionAtom = atom(
  getInitialDataResult([""])
);
export const sortOrderColumnNameAtom = atom(COLUMNS_ENUM.TIME_INSERTED.name);
export const sortOrderDirectionAtom = atom("desc_nulls_last");
