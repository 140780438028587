import { ReactElement } from "react";
import { Error, Loading } from "../types/dataFetching";
import { Accordion } from "@mui/material";
import { QueryQuestion } from "./QueryQuestion";
import { QueryAnswer } from "./QueryAnswer";
import { QueryAnswerTable } from "./QueryAnswerTable";

/**
 * @param {object} props
 * @param {string} props.questionTitle
 * @param {object} [props.answerInput]
 * @param {boolean} props.hasSelectedValue
 * @param {string[][]} [props.answerTableData]
 * @param {object[]} props.answerTableColumns
 * @param {object} [props.answerTableOptions]
 * @param {string} props.graphqlAPILink
 * @param {Loading} props.loading
 * @param {Error} props.error
 * @param {string} props.atomKey
 * @param {Function} [props.getRowID]
 * @returns {ReactElement}
 */
export const QueryEntry = ({
  questionTitle,
  answerInput,
  hasSelectedValue,
  answerTableData,
  answerTableColumns,
  answerTableOptions,
  graphqlAPILink,
  loading,
  error,
  atomKey,
  getRowID,
}) => {
  return (
    <Accordion
      sx={{
        mt: "1rem",
        borderStyle: "solid",
        borderWidth: "0.1rem",
        borderColor: "gray1.main",
        borderRadius: "0.5rem",
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
      TransitionProps={{ unmountOnExit: true }}
      disableGutters
      square
    >
      <QueryQuestion title={questionTitle} />
      <QueryAnswer
        input={answerInput}
        output={
          <QueryAnswerTable
            hasSelectedValue={hasSelectedValue}
            data={answerTableData}
            loading={loading}
            error={error}
            columns={answerTableColumns}
            options={answerTableOptions}
            link={graphqlAPILink}
            atomKey={atomKey}
            getRowID={getRowID}
          />
        }
      />
    </Accordion>
  );
};
