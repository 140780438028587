export const COLUMNS_ENUM = Object.freeze({
  PREFIX: { order: 0, name: "Prefix" },
  ORIGIN_AS: { order: 1, name: "Origin AS" },
  NEIGHBOR_AS: { order: 2, name: "Neighbor AS" },
  AS_PATH: { order: 3, name: "AS Path" },
  COMMUNITIES: { order: 4, name: "Communities" },
  RPKI_STATUS: { order: 5, name: "RPKI Status" },
  TIME_INSERTED: { order: 6, name: "First Detected" },
  TIME_UPDATED: { order: 7, name: "Last Update" },
  PEER_IP: { order: 8, name: "Peer IP" },
  PEER_AS: { order: 9, name: "Peer AS" },
});

export const RPKI_EMPTY_FILTER = "(empty)";

export const FORMAT_RPKI_ENUM = Object.freeze({
  valid: "Valid",
  invalid: "Invalid",
  "not-found": "NotFound",
});

export const UNFORMAT_RPKI_ENUM = Object.freeze({
  Valid: "valid",
  Invalid: "invalid",
  NotFound: "not-found",
});
