import { useAllASNs } from "./useAllASNs";
import { useAsnsThatOriginatePrefixes } from "./useAsnsThatOriginatePrefixes";
import { useNeighbors } from "./useNeighbors";
import { usePrefixesMyASNsOriginate } from "./usePrefixesMyASNsOriginate";

export const useQueriesData = () => {
  useAllASNs();
  usePrefixesMyASNsOriginate();
  useNeighbors();
  useAsnsThatOriginatePrefixes();
};
