import { ReactElement } from "react";
import MuiTableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { rowsPerPageOptions } from "../constants/components/table";
import { TableFooterDisplayedRowsLabel } from "./TableFooterDisplayedRowsLabel";

/**
 * @param {object} props
 * @param {number | ReactElement} props.count
 * @param {number} props.page
 * @param {number} props.rowsPerPage
 * @param {Function} props.changeRowsPerPage
 * @param {Function} props.changePage
 * @returns {ReactElement}
 */
export const TableFooter = ({
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
}) => {
  const onRowsPerPageChange = (event) => {
    changeRowsPerPage(event.target.value);
  };

  const onPageChange = (_, page) => {
    changePage(page);
  };

  const footerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 24px 0px 24px",
  };

  return (
    <MuiTableFooter>
      <TableRow>
        <TableCell style={footerStyle} colSpan={1000}>
          <TablePagination
            component="div"
            count={count}
            showFirstButton
            showLastButton
            labelDisplayedRows={TableFooterDisplayedRowsLabel}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableCell>
      </TableRow>
    </MuiTableFooter>
  );
};
