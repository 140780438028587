export const defaultSX = {
  gridColumn: "span 1",
  p: "1.5rem",
  backgroundColor: "white",
  borderColor: "gray1.main",
  borderStyle: "solid",
  borderWidth: "0.1rem",
  borderRadius: "8px",
  boxShadow: "none",
  display: "flex",
  height: "176px",
};
