import {
  createUrqlClient,
  createMockUrqlClient,
  auth0HookGetter,
} from "../other/utils";
import { SelectTenant } from "../pages/SelectTenant";
import { Provider } from "urql";
import { ConfGlobalState } from "./ConfGlobalState";
import { onGQLClientMock, onDomainProd, onEnvTesting } from "../utils/env";
import { useTestingSchema } from "../hooks/useTestingSchema";
import { useUsername } from "../hooks/useUsername";

export const App = () => {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    auth0HookGetter()();
  const { fullname } = useUsername();
  const {
    data: testingSchema,
    loading: testingSchemaLoading,
    error: testingSchemaError,
  } = useTestingSchema();

  if (isLoading) {
    return <div>Authenticating...</div>;
  }

  if (onEnvTesting()) {
    if (testingSchemaLoading) {
      return <div>Loading schema...</div>;
    }

    if (testingSchemaError) {
      return <div>Failed to load schema.</div>;
    }
  }

  if (!isAuthenticated) {
    return <SelectTenant />;
  }

  const urqlClient = onGQLClientMock()
    ? createMockUrqlClient(testingSchema)
    : createUrqlClient(getAccessTokenSilently, user);

  if (!urqlClient) {
    // This is just defensive coding. This block should never be executed.
    // createMockUrqlClient and createUrqlClient should never return a falsy value.
    console.error("Urql client was not created.");
    return <div>Error creating Graphql client.</div>;
  }

  return (
    <Provider value={urqlClient}>
      <ConfGlobalState />
    </Provider>
  );
};
