import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Stack, Tab, Tabs } from "@mui/material";
import { PageDivider } from "./PageDivider";
import { useNavigate, useLocation } from "react-router-dom";

const PageTabs = ({ tabs, selectedTabCallback }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const changeHash = (tabIndex) => {
    const tabHash = tabs[tabIndex].hash;
    navigate(`${location.pathname}#${tabHash}`);
  };

  useEffect(() => {
    if (!location.hash) {
      changeHash(0);
    }
  });

  const getInitialTab = () => {
    const currentHash = location.hash;
    return currentHash
      ? tabs.findIndex((tab) => tab.hash === currentHash.slice(1))
      : 0;
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab());

  const onTabChange = (e, tabIndex) => {
    setSelectedTab(tabIndex);
    changeHash(tabIndex);
    selectedTabCallback({ selectedTab: tabs[tabIndex].hash });
  };

  const tabsJSX = [];
  tabs.map((tab) => {
    return tabsJSX.push(
      <Tab
        key={tab.title}
        sx={{
          textTransform: "none",
          fontSize: "1rem",
          px: (theme) => theme.space.padding.contentX,
          width: `${tab.width}`,
        }}
        label={tab.title}
      />
    );
  });

  const selectedTabContentJSX = tabs[selectedTab].component;

  return (
    <Stack>
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        variant="scrollable"
        sx={{
          mx: (theme) => `-${theme.space.padding.contentX}`,
          "& .MuiTabs-indicator": {
            backgroundColor: (theme) => theme.color.other.selectionIndicator,
            height: "0.2rem",
          },
          "& .MuiTab-root": {
            fontWeight: "regular",
          },
          "& .MuiTab-root.Mui-selected": {
            color: (theme) => theme.color.other.selectionIndicator,
            fontWeight: "medium",
          },
        }}
      >
        {tabsJSX}
      </Tabs>
      <PageDivider />
      {selectedTabContentJSX}
    </Stack>
  );
};

PageTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export { PageTabs };
