import { SvgWrapper } from "./SvgWrapper";
import { Button, Link, Stack, Typography } from "@mui/material";

export const DocsButton = () => {
  return (
    <Link
      href="https://docs.codebgp.com"
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      <Button
        sx={{
          color: "white",
          textTransform: "none",
          width: "100%",
          height: "3.063rem",
          justifyContent: "left",
          pl: "1rem",
          borderRadius: "0",
          ":hover": {
            backgroundColor: (theme) => theme.color.other.onDrawerButtonHover,
          },
        }}
      >
        <Stack direction="row">
          <SvgWrapper
            filename="DocsIcon"
            sx={{ color: "white", fontSize: 25 }}
          />
          <Typography pt="0.2rem" pl="1rem" fontSize="0.875rem">
            Docs
          </Typography>
          <SvgWrapper
            filename="NewTabIcon"
            sx={{
              color: "white",
              width: "20px",
              height: "20px",
              ml: "131px",
              mt: "3px",
            }}
          />
        </Stack>
      </Button>
    </Link>
  );
};
