import { useOverviewData } from "../hooks/useOverviewData";
import { useQueriesData } from "../hooks/useQueriesData";
import { StateGlobalState } from "./StateGlobalState";

export const MainGlobalState = () => {
  useQueriesData();
  useOverviewData();

  return <StateGlobalState />;
};
