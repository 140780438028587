import { ReactElement } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  bodyCellStylesValue,
  bodyCellStylesParameter,
  headerCellStyles,
} from "../constants/components/argsTable";
import { COLUMNS_ENUM } from "../constants/components/alertsTable";
import { formatTime } from "../other/utils";
import { alertSeverity } from "../constants/components/confAlertsInfoTable";

/**
 *
 * @param {object} props
 * @param {object} props.alertData
 * @returns {ReactElement}
 */
export const RemoveAlertsArgsTable = ({ alertData }) => {
  return (
    <Table sx={{ mt: "24px" }}>
      <TableHead>
        <TableRow>
          <TableCell sx={headerCellStyles} align="center" colSpan={2}>
            Event Details
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={alertData[COLUMNS_ENUM.TYPE.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Type
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {alertData[COLUMNS_ENUM.TYPE.order]}
          </TableCell>
        </TableRow>
        <TableRow key={alertData[COLUMNS_ENUM.STARTS_AT.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Started
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {formatTime(alertData[COLUMNS_ENUM.STARTS_AT.order])}
          </TableCell>
        </TableRow>
        <TableRow key={alertData[COLUMNS_ENUM.ENDS_AT.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Ended
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {formatTime(alertData[COLUMNS_ENUM.ENDS_AT.order])}
          </TableCell>
        </TableRow>
        <TableRow key={alertData[COLUMNS_ENUM.SEVERITY.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Severity
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {alertSeverity[alertData[COLUMNS_ENUM.SEVERITY.order]]}
          </TableCell>
        </TableRow>
        <TableRow key={alertData[COLUMNS_ENUM.DATA.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Event
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {alertData[COLUMNS_ENUM.DATA.order]}
          </TableCell>
        </TableRow>
        <TableRow key={alertData[COLUMNS_ENUM.VARS.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Configured Resources
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {alertData[COLUMNS_ENUM.VARS.order]}
          </TableCell>
        </TableRow>
        <TableRow key={alertData[COLUMNS_ENUM.DESCRIPTION.order]}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Description
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>
            {alertData[COLUMNS_ENUM.DESCRIPTION.order]}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
