import {
  getDropdownFilterTypeColumn,
  getServerSideRangeFilterColumn,
  getTextFieldFilterTypeColumn,
} from "../../other/utils";
import { COLUMNS_ENUM } from "../../constants/components/prefixes";
import {
  Prefixes_Bool_Exp,
  Prefixes_Order_By,
  PrefixesSubscription,
  PrefixesCountSubscription,
} from "../../graphql/generated/operations";
import {
  formatASNs,
  formatDataSourcesPercentage,
  formatPrefix,
} from "../formatting";
import { TableCell, TableRow } from "@mui/material";
import { DataSourcesTable } from "../../components/DataSourcesTable";
import { addServerSideOptions } from "./serverSideTable";
import { usePrefixSourcesSubscription } from "../../graphql/generated/operations";
import {
  doOnViewColumnsChange,
  getASNInPathRegexes,
  getDataSourcesPercentage,
} from "../state";
import { getRangeFilterList } from "../filtering";
import { allDataServicesDisplayed } from "./confDataServicesTable";

/**
 * @param {object} params
 * @param {(string|number)[]} [params.configuredASNs]
 * @param {string[]} [params.configuredPrefixes]
 * @param {string[]} [params.configuredDataServices]
 * @param {boolean} params.filterOnASAny
 * @param {number} [params.ipVersion]
 * @returns {Prefixes_Bool_Exp}
 */
export const getConfFilters = ({
  configuredASNs,
  configuredPrefixes,
  configuredDataServices,
  filterOnASAny,
  ipVersion,
}) => {
  /** @type {Prefixes_Bool_Exp} */
  const whereArgument = {};

  if (!configuredASNs) configuredASNs = [];
  if (!configuredPrefixes) configuredPrefixes = [];
  if (!configuredDataServices) configuredDataServices = [];

  const confASNsFilter = {
    routes: {
      originAutonomousSystem: {
        number: {
          _in: configuredASNs,
        },
      },
    },
  };

  const confPrefixesFilter = {
    configured_prefix_best_match: { _in: configuredPrefixes },
  };

  const orArray = [confASNsFilter, confPrefixesFilter];

  if (filterOnASAny) {
    const asnsInPathFilter = {
      routes: {
        as_path: {
          _regex: getASNInPathRegexes(configuredASNs),
        },
      },
    };
    orArray.push(asnsInPathFilter);
  }

  whereArgument._and = [{ _or: orArray }];

  if (!allDataServicesDisplayed(configuredDataServices)) {
    whereArgument._and.push({
      prefixDataSourceAssociations: {
        dataSource: {
          data_service: {
            _in: configuredDataServices,
          },
        },
      },
    });
  }

  if (ipVersion) {
    whereArgument._and.push({ ip_version: { _eq: ipVersion } });
  }

  return whereArgument;
};

/**
 * @param {object} params
 * @param {Prefixes_Bool_Exp} params.whereArgument
 * @param {string} params.maxDataSourcesNumberFilter
 * @param {string} params.maxDataSourcesPercentageFilter
 * @param {number} params.totalNumberOfIPv4DataSources
 * @param {number} params.totalNumberOfIPv6DataSources
 */
const addMaxDataSourcesFilter = ({
  whereArgument,
  maxDataSourcesNumberFilter,
  maxDataSourcesPercentageFilter,
  totalNumberOfIPv4DataSources,
  totalNumberOfIPv6DataSources,
}) => {
  if (!maxDataSourcesNumberFilter && !maxDataSourcesPercentageFilter) return;

  let ipv4LimitFromPercentage = Number.POSITIVE_INFINITY;
  let ipv6LimitFromPercentage = Number.POSITIVE_INFINITY;
  if (maxDataSourcesPercentageFilter) {
    ipv4LimitFromPercentage = Math.ceil(
      (+maxDataSourcesPercentageFilter / 100) * totalNumberOfIPv4DataSources
    );
    ipv6LimitFromPercentage = Math.ceil(
      (+maxDataSourcesPercentageFilter / 100) * totalNumberOfIPv6DataSources
    );
  }

  if (maxDataSourcesNumberFilter && maxDataSourcesPercentageFilter) {
    const ipv4limit = Math.min(
      +maxDataSourcesNumberFilter,
      ipv4LimitFromPercentage
    );
    const ipv6limit = Math.min(
      +maxDataSourcesNumberFilter,
      ipv6LimitFromPercentage
    );
    whereArgument._and?.push({
      _or: [
        {
          ip_version: { _eq: 4 },
          data_source_count: { _lte: ipv4limit },
        },
        {
          ip_version: { _eq: 6 },
          data_source_count: { _lte: ipv6limit },
        },
      ],
    });
  } else if (maxDataSourcesNumberFilter) {
    whereArgument._and?.push({
      data_source_count: { _lte: +maxDataSourcesNumberFilter },
    });
  } else if (maxDataSourcesPercentageFilter) {
    whereArgument._and?.push({
      _or: [
        {
          ip_version: { _eq: 4 },
          data_source_count: { _lte: ipv4LimitFromPercentage },
        },
        {
          ip_version: { _eq: 6 },
          data_source_count: { _lte: ipv6LimitFromPercentage },
        },
      ],
    });
  }
};

/**
 * @param {object} params
 * @param {Prefixes_Bool_Exp} params.whereArgument
 * @param {string} params.minDataSourcesNumberFilter
 * @param {string} params.minDataSourcesPercentageFilter
 * @param {number} params.totalNumberOfIPv4DataSources
 * @param {number} params.totalNumberOfIPv6DataSources
 */
const addMinDataSourcesFilter = ({
  whereArgument,
  minDataSourcesNumberFilter,
  minDataSourcesPercentageFilter,
  totalNumberOfIPv4DataSources,
  totalNumberOfIPv6DataSources,
}) => {
  if (!minDataSourcesNumberFilter && !minDataSourcesPercentageFilter) return;

  let ipv4LimitFromPercentage = Number.NEGATIVE_INFINITY;
  let ipv6LimitFromPercentage = Number.NEGATIVE_INFINITY;
  if (minDataSourcesPercentageFilter) {
    ipv4LimitFromPercentage = Math.floor(
      (+minDataSourcesPercentageFilter / 100) * totalNumberOfIPv4DataSources
    );
    ipv6LimitFromPercentage = Math.floor(
      (+minDataSourcesPercentageFilter / 100) * totalNumberOfIPv6DataSources
    );
  }

  if (minDataSourcesNumberFilter && minDataSourcesPercentageFilter) {
    const ipv4limit = Math.max(
      +minDataSourcesNumberFilter,
      ipv4LimitFromPercentage
    );
    const ipv6limit = Math.max(
      +minDataSourcesNumberFilter,
      ipv6LimitFromPercentage
    );
    whereArgument._and?.push({
      _or: [
        {
          ip_version: { _eq: 4 },
          data_source_count: { _gte: ipv4limit },
        },
        {
          ip_version: { _eq: 6 },
          data_source_count: { _gte: ipv6limit },
        },
      ],
    });
  } else if (minDataSourcesNumberFilter) {
    whereArgument._and?.push({
      data_source_count: { _gte: +minDataSourcesNumberFilter },
    });
  } else if (minDataSourcesPercentageFilter) {
    whereArgument._and?.push({
      _or: [
        {
          ip_version: { _eq: 4 },
          data_source_count: { _gte: ipv4LimitFromPercentage },
        },
        {
          ip_version: { _eq: 6 },
          data_source_count: { _gte: ipv6LimitFromPercentage },
        },
      ],
    });
  }
};

/**
 * @param {object} params
 * @param {Prefixes_Bool_Exp} params.whereArgument
 * @param {string} params.minDataSourcesNumberFilter
 * @param {string} params.maxDataSourcesNumberFilter
 * @param {string} params.minDataSourcesPercentageFilter
 * @param {string} params.maxDataSourcesPercentageFilter
 * @param {number} params.totalNumberOfIPv4DataSources
 * @param {number} params.totalNumberOfIPv6DataSources
 */
const addDataSourcesFilter = ({
  whereArgument,
  minDataSourcesNumberFilter,
  maxDataSourcesNumberFilter,
  minDataSourcesPercentageFilter,
  maxDataSourcesPercentageFilter,
  totalNumberOfIPv4DataSources,
  totalNumberOfIPv6DataSources,
}) => {
  if (!whereArgument._and) {
    whereArgument._and = [];
  }
  addMaxDataSourcesFilter({
    whereArgument,
    maxDataSourcesNumberFilter,
    maxDataSourcesPercentageFilter,
    totalNumberOfIPv4DataSources,
    totalNumberOfIPv6DataSources,
  });
  addMinDataSourcesFilter({
    whereArgument,
    minDataSourcesNumberFilter,
    minDataSourcesPercentageFilter,
    totalNumberOfIPv4DataSources,
    totalNumberOfIPv6DataSources,
  });
};

/**
 * @param {object} params
 * @param {string[]} params.configuredASNs
 * @param {string[]} params.configuredPrefixes
 * @param {string[]} params.configuredDataServices
 * @param {string} params.prefixFilter
 * @param {string} params.originASFilter
 * @param {string} params.ipVersionFilter
 * @param {string} params.maskLengthFilter
 * @param {string} params.peerIPFilter
 * @param {string} params.peerASFilter
 * @param {string} params.minDataSourcesNumberFilter
 * @param {string} params.maxDataSourcesNumberFilter
 * @param {string} params.minDataSourcesPercentageFilter
 * @param {string} params.maxDataSourcesPercentageFilter
 * @param {number} params.totalNumberOfIPv4DataSources
 * @param {number} params.totalNumberOfIPv6DataSources
 * @param {boolean} params.filterOnASAny
 * @returns {Prefixes_Bool_Exp}
 */
export const getWhereArgument = ({
  configuredASNs,
  configuredPrefixes,
  configuredDataServices,
  prefixFilter,
  originASFilter,
  ipVersionFilter,
  maskLengthFilter,
  peerIPFilter,
  peerASFilter,
  minDataSourcesNumberFilter,
  maxDataSourcesNumberFilter,
  minDataSourcesPercentageFilter,
  maxDataSourcesPercentageFilter,
  totalNumberOfIPv4DataSources,
  totalNumberOfIPv6DataSources,
  filterOnASAny,
}) => {
  /** @type {Prefixes_Bool_Exp} */
  const whereArgument = getConfFilters({
    configuredASNs,
    configuredPrefixes,
    configuredDataServices,
    filterOnASAny,
  });

  if (prefixFilter) {
    whereArgument.network_str = { _iregex: prefixFilter };
  }

  if (originASFilter) {
    whereArgument.routes = {
      originAutonomousSystem: {
        number: {
          _in: [originASFilter],
        },
      },
    };
  }

  if (ipVersionFilter) {
    whereArgument.ip_version = { _eq: +ipVersionFilter };
  }
  if (maskLengthFilter) {
    whereArgument.mask_length = { _eq: +maskLengthFilter };
  }

  if (peerIPFilter && peerASFilter) {
    whereArgument.prefixDataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: { _iregex: peerIPFilter + "|" + peerASFilter },
          },
        },
      },
    };
  } else if (peerIPFilter) {
    whereArgument.prefixDataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: { _iregex: peerIPFilter },
          },
        },
      },
    };
  } else if (peerASFilter) {
    whereArgument.prefixDataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: { _iregex: peerASFilter },
          },
        },
      },
    };
  }

  addDataSourcesFilter({
    whereArgument,
    minDataSourcesNumberFilter,
    maxDataSourcesNumberFilter,
    minDataSourcesPercentageFilter,
    maxDataSourcesPercentageFilter,
    totalNumberOfIPv4DataSources,
    totalNumberOfIPv6DataSources,
  });

  return whereArgument;
};

/**
 * @param {object} params
 * @param {string} params.sortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @returns {Prefixes_Order_By}
 */
export const getOrderArgument = ({
  sortOrderColumnName,
  sortOrderDirection,
}) => {
  /** @type {Prefixes_Order_By} */
  const orderArgument = {};

  switch (sortOrderColumnName) {
    case COLUMNS_ENUM.PREFIX.name:
      orderArgument.network = sortOrderDirection;
      break;
    case COLUMNS_ENUM.IP_VERSION.name:
      orderArgument.ip_version = sortOrderDirection;
      break;
    case COLUMNS_ENUM.MASK_LENGTH.name:
      orderArgument.mask_length = sortOrderDirection;
      break;
    case COLUMNS_ENUM.DATA_SOURCES_NUMBER.name:
      orderArgument.data_source_count = sortOrderDirection;
      break;
    default:
      break;
  }

  return orderArgument;
};

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setPrefixFilter
 * @param {Function} params.setOriginASFilter
 * @param {Function} params.setIpVersionFilter
 * @param {Function} params.setMaskLengthFilter
 * @param {Function} params.setPeerIPFilter
 * @param {Function} params.setPeerASFilter
 * @param {Function} params.setMinDataSourcesNumberFilter
 * @param {Function} params.setMaxDataSourcesNumberFilter
 * @param {Function} params.setMinDataSourcesPercentageFilter
 * @param {Function} params.setMaxDataSourcesPercentageFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setPrefixFilter,
  setOriginASFilter,
  setIpVersionFilter,
  setMaskLengthFilter,
  setPeerIPFilter,
  setPeerASFilter,
  setMinDataSourcesNumberFilter,
  setMaxDataSourcesNumberFilter,
  setMinDataSourcesPercentageFilter,
  setMaxDataSourcesPercentageFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setPrefixFilter(filterList[COLUMNS_ENUM.PREFIX.order][0]);
  setOriginASFilter(filterList[COLUMNS_ENUM.ORIGIN_AS.order][0]);
  setIpVersionFilter(filterList[COLUMNS_ENUM.IP_VERSION.order][0]);
  setMaskLengthFilter(filterList[COLUMNS_ENUM.MASK_LENGTH.order][0]);
  setPeerIPFilter(filterList[COLUMNS_ENUM.PEER_IP.order][0]);
  setPeerASFilter(filterList[COLUMNS_ENUM.PEER_AS.order][0]);
  setMinDataSourcesNumberFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_NUMBER.order][0]
  );
  setMaxDataSourcesNumberFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_NUMBER.order][1]
  );
  setMinDataSourcesPercentageFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order][0]
  );
  setMaxDataSourcesPercentageFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order][1]
  );
  resetPage();
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsPrefixVisible
 * @param {Function} params.setIsOriginASVisible
 * @param {Function} params.setIsIPVersionVisible
 * @param {Function} params.setIsMaskLengthVisible
 * @param {Function} params.setIsDataSourcesNumberVisible
 * @param {Function} params.setIsDataSourcesPercentageVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsPrefixVisible,
  setIsOriginASVisible,
  setIsIPVersionVisible,
  setIsMaskLengthVisible,
  setIsDataSourcesNumberVisible,
  setIsDataSourcesPercentageVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.PREFIX.name] = setIsPrefixVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.ORIGIN_AS.name] =
    setIsOriginASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.IP_VERSION.name] =
    setIsIPVersionVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.MASK_LENGTH.name] =
    setIsMaskLengthVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SOURCES_NUMBER.name] =
    setIsDataSourcesNumberVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.name] =
    setIsDataSourcesPercentageVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {object} params
 * @param {string[]} params.rows
 * @param {string} params.prefixFilter
 * @param {string} params.originASFilter
 * @param {string} params.ipVersionFilter
 * @param {string} params.maskLengthFilter
 * @param {string} params.peerIPFilter
 * @param {string} params.peerASFilter
 * @param {string} params.minDataSourcesNumberFilter
 * @param {string} params.maxDataSourcesNumberFilter
 * @param {string} params.minDataSourcesPercentageFilter
 * @param {string} params.maxDataSourcesPercentageFilter
 * @param {Function} params.setMinDataSourcesNumberFilter
 * @param {Function} params.setMaxDataSourcesNumberFilter
 * @param {Function} params.setMinDataSourcesPercentageFilter
 * @param {Function} params.setMaxDataSourcesPercentageFilter
 * @param {boolean} params.isPrefixVisible
 * @param {boolean} params.isOriginASVisible
 * @param {boolean} params.isIPVersionVisible
 * @param {boolean} params.isMaskLengthVisible
 * @param {boolean} params.isDataSourcesNumberVisible
 * @param {boolean} params.isDataSourcesPercentageVisible
 * @returns {object[]}
 */
export const getColumns = ({
  rows: prefixes,
  prefixFilter,
  originASFilter,
  ipVersionFilter,
  maskLengthFilter,
  peerIPFilter,
  peerASFilter,
  minDataSourcesNumberFilter,
  maxDataSourcesNumberFilter,
  minDataSourcesPercentageFilter,
  maxDataSourcesPercentageFilter,
  setMinDataSourcesNumberFilter,
  setMaxDataSourcesNumberFilter,
  setMinDataSourcesPercentageFilter,
  setMaxDataSourcesPercentageFilter,
  isPrefixVisible,
  isOriginASVisible,
  isIPVersionVisible,
  isMaskLengthVisible,
  isDataSourcesNumberVisible,
  isDataSourcesPercentageVisible,
}) => [
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PREFIX.name, {
    filterList: prefixFilter ? [prefixFilter] : [],
    display: isPrefixVisible,
    customBodyRenderLite: (dataIndex) =>
      formatPrefix(prefixes, dataIndex, COLUMNS_ENUM.PREFIX.order),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.ORIGIN_AS.name, {
    filterList: originASFilter ? [originASFilter] : [],
    display: isOriginASVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASNs(prefixes, dataIndex, COLUMNS_ENUM.ORIGIN_AS.order),
    sort: false,
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.IP_VERSION.name, {
    filterList: ipVersionFilter ? [ipVersionFilter] : [],
    display: isIPVersionVisible,
    filterOptions: {
      names: ["4", "6"],
    },
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.MASK_LENGTH.name, {
    filterList: maskLengthFilter ? [maskLengthFilter] : [],
    display: isMaskLengthVisible,
  }),
  getServerSideRangeFilterColumn(
    COLUMNS_ENUM.DATA_SOURCES_NUMBER.name,
    {
      filterList: getRangeFilterList(
        minDataSourcesNumberFilter,
        maxDataSourcesNumberFilter
      ),
      display: isDataSourcesNumberVisible,
    },
    setMinDataSourcesNumberFilter,
    setMaxDataSourcesNumberFilter
  ),
  getServerSideRangeFilterColumn(
    COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.name,
    {
      filterList: getRangeFilterList(
        minDataSourcesPercentageFilter,
        maxDataSourcesPercentageFilter
      ),
      display: isDataSourcesPercentageVisible,
      customBodyRenderLite: (dataIndex) =>
        formatDataSourcesPercentage(
          prefixes,
          dataIndex,
          COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order
        ),
      sort: false,
    },
    setMinDataSourcesPercentageFilter,
    setMaxDataSourcesPercentageFilter
  ),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PEER_IP.name, {
    filterList: peerIPFilter ? [peerIPFilter] : [],
    display: "excluded",
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PEER_AS.name, {
    filterList: peerASFilter ? [peerASFilter] : [],
    display: "excluded",
  }),
];

/**
 * @param {object} params
 * @param {string[][]} params.rows
 * @param {string} params.sortOrderColumnName
 * @param {Function} params.setSortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @param {Function} params.setSortOrderDirection
 * @param {boolean} params.isSubscriptionActive
 * @returns {object}
 */
export const getCustomOptions = ({
  rows: prefixes,
  sortOrderColumnName,
  setSortOrderColumnName,
  sortOrderDirection,
  setSortOrderDirection,
  isSubscriptionActive,
}) => {
  const customOptions = {
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const prefix = prefixes[rowMeta.dataIndex][COLUMNS_ENUM.PREFIX.order];
      const title = `Data Sources of Prefix ${prefix}`;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <DataSourcesTable
              sourcesSubscription={usePrefixSourcesSubscription}
              options={{ variables: { prefix }, pause: !isSubscriptionActive }}
              title={title}
              atomKey={`Prefixes-${prefix}`}
            />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: "No Prefixes found.",
      },
    },
  };

  addServerSideOptions({
    options: customOptions,
    sortOrderColumnName,
    setSortOrderColumnName,
    sortOrderDirection,
    setSortOrderDirection,
  });

  return customOptions;
};

/**
 * @param {PrefixesSubscription | undefined} data
 * @returns {Map<any, any[]>}
 */
const getPrefixToOriginASNs = (data) => {
  const prefixToOriginASNs = new Map();

  data?.prefixes.forEach(({ routes, network }) => {
    const originASNs = new Set();

    routes.forEach(({ originAutonomousSystem }) => {
      if (!originAutonomousSystem) return;
      originASNs.add(originAutonomousSystem.number);
    });

    if (originASNs.size !== 0) {
      prefixToOriginASNs.set(network, [...originASNs].sort());
    }
  });

  return prefixToOriginASNs;
};

/**
 * @param {object} params
 * @param {PrefixesSubscription | undefined} params.data
 * @param {number} params.totalNumberOfIPv4DataSources
 * @param {number} params.totalNumberOfIPv6DataSources
 * @returns {(string|number|undefined)[][]}
 */
export const getRows = ({
  data,
  totalNumberOfIPv4DataSources,
  totalNumberOfIPv6DataSources,
}) => {
  const prefixToOriginASNs = getPrefixToOriginASNs(data);

  /** @type {string[][]} */
  const prefixRows = [];

  data?.prefixes.forEach(
    ({ network, ip_version, mask_length, data_source_count }) => {
      const totalNumberOfDataSources =
        ip_version === 6
          ? totalNumberOfIPv6DataSources
          : totalNumberOfIPv4DataSources;
      const dataSourcesPercentage = getDataSourcesPercentage({
        data_source_count,
        totalNumberOfDataSources,
      });
      prefixRows.push([
        network,
        prefixToOriginASNs?.get(network),
        ip_version,
        mask_length,
        data_source_count,
        dataSourcesPercentage,
      ]);
    }
  );

  return prefixRows;
};

/**
 * @param {object} params
 * @param {PrefixesCountSubscription | undefined} params.data
 * @returns {number | undefined}
 */
export const getCount = ({ data }) => data?.prefixesAggregate?.aggregate?.count;

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.PREFIX.order];
