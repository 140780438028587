import { ReactElement, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material";
import { components, TABLE_KEY_ENUM } from "../constants/components/table";
import { TableTitle } from "./TableTitle";
import {
  getOptions,
  getRowsExpanded,
  getRowsSelected,
  getTheme,
  muiCache,
} from "../utils/components/table";
import { TableProps } from "../types/components/table";
import { CacheProvider } from "@emotion/react";
import { usePagination } from "../hooks/usePagination";

/**
 * @param {TableProps} props
 * @returns {ReactElement}
 */
export const Table = ({
  title,
  data,
  loading,
  error,
  selectedRowsTextLabels,
  columns,
  customOptions,
  themeKey,
  pageAtom,
  rowsPerPageAtom,
  count,
  setCount,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  getRowID,
  expandedRowIDs,
  setExpandedRowIDs,
  selectedRowIDs,
  setSelectedRowIDs,
  displayDownloadButton,
  exportDataFileName,
}) => {
  if (error) {
    console.error(error);
  }

  // count is normally set by a setCount() call in onTableChange callback,
  // to take filtering etc. into account. This effect is needed
  // so count is not 0 on first render. In subsequent renders,
  // data?.length value may be stale. This is automatically corrected
  // by the setCount() call in onTableChange callback.
  useEffect(() => {
    if (setCount) {
      setCount(data?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let rowsExpanded;
  if (expandedRowIDs && getRowID) {
    rowsExpanded = getRowsExpanded({ data, expandedRowIDs, getRowID });
  }
  let rowsSelected;
  if (selectedRowIDs && getRowID) {
    rowsSelected = getRowsSelected({ data, selectedRowIDs, getRowID });
  }
  const { page, setPage, rowsPerPage, setRowsPerPage, resetPage } =
    usePagination({ pageAtom, rowsPerPageAtom });
  const options = getOptions({
    customOptions,
    data,
    loading,
    error,
    selectedRowsTextLabels,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    resetPage,
    count,
    setCount,
    onApplyFiltersButtonClick,
    onViewColumnsChange,
    getRowID,
    rowsExpanded,
    expandedRowIDs,
    setExpandedRowIDs,
    rowsSelected,
    selectedRowIDs,
    setSelectedRowIDs,
    displayDownloadButton,
    exportDataFileName,
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getTheme(themeKey ?? TABLE_KEY_ENUM.OUTER)}>
        <MUIDataTable
          title={<TableTitle text={title} loading={loading} />}
          data={error ? undefined : data ?? undefined}
          columns={columns}
          options={options}
          components={components}
        />
      </ThemeProvider>
    </CacheProvider>
  );
};
