import { useState } from "react";

export const useTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (e, tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return { selectedTab, onTabChange };
};
