import { Stack, TextField, Typography } from "@mui/material";
import { addSecondsToTimeFilter } from "../other/utils";

export const rangedFilterOptionsLogic = (
  value,
  filters,
  dataTransformer,
  filterMode
) => {
  let minValue;
  let maxValue;
  let newValue;

  if (filterMode === "date") {
    minValue = dataTransformer
      ? +dataTransformer(addSecondsToTimeFilter(filters[0], 0))
      : +filters[0];
    maxValue = dataTransformer
      ? +dataTransformer(addSecondsToTimeFilter(filters[1], +1))
      : +filters[1];
    newValue = dataTransformer ? dataTransformer(value) : value;
  } else {
    //default
    minValue = dataTransformer ? +dataTransformer(filters[0]) : +filters[0];
    maxValue = dataTransformer ? +dataTransformer(filters[1]) : +filters[1];
    newValue = dataTransformer ? dataTransformer(value) : value;
  }

  if (minValue && maxValue) {
    return newValue < minValue || newValue > maxValue;
  } else if (minValue) {
    return newValue < minValue;
  } else if (maxValue) {
    return newValue > maxValue;
  }
  return false;
};

export const rangedFilterOptionsDisplay = (
  filterList,
  onChange,
  index,
  column,
  filterData
) => {
  return (
    <Stack>
      <Typography>{column.name}</Typography>
      <Stack direction="row">
        <TextField
          variant="standard"
          sx={{ width: "45%", mr: "5%" }}
          label="min"
          value={filterList[index][0] ?? ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
        />
        <TextField
          variant="standard"
          label="max"
          value={filterList[index][1] ?? ""}
          onChange={(event) => {
            filterList[index][1] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "45%" }}
        />
      </Stack>
    </Stack>
  );
};

export const rangedCustomFilterListOptionsRender = (value, columnName) => {
  const minValue = value[0];
  const maxValue = value[1];

  if (minValue && maxValue) {
    return [`Min ${columnName}: ${value[0]}`, `Max ${columnName}: ${value[1]}`];
  } else if (minValue) {
    return `Min ${columnName}: ${minValue}`;
  } else if (maxValue) {
    return `Max ${columnName}: ${maxValue}`;
  }
  return [];
};

export const rangedServerSideCustomFilterListOptionsUpdate = (
  filterPos,
  setMinFilter,
  setMaxFilter
) => {
  if (filterPos === 0) {
    setMinFilter("");
  } else if (filterPos === 1) {
    setMaxFilter("");
  } else if (filterPos === -1) {
    setMinFilter("");
    setMaxFilter("");
  }
};

export const getRangeFilterList = (minNumberFilter, maxNumberFilter) => {
  if (minNumberFilter && maxNumberFilter) {
    return [minNumberFilter, maxNumberFilter];
  } else if (minNumberFilter) {
    return [minNumberFilter];
  } else if (maxNumberFilter) {
    return [undefined, maxNumberFilter];
  } else {
    return [];
  }
};
