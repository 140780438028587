import { ReactElement, MouseEventHandler } from "react";
import { SvgWrapper } from "./SvgWrapper";
import { IconButton, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";

/**
 * @param {object} props
 * @param {MouseEventHandler} props.onClick
 * @returns {ReactElement}
 */
export const ExportDataButton = ({ onClick }) => {
  const iconJSX = (
    <SvgWrapper
      filename="DownloadCSVIcon"
      sx={{ "&:hover": { color: "#0c3153" } }}
    />
  );

  const buttonJSX = (
    <Stack style={{ order: -1 }}>
      <Tooltip title="Download CSV">
        <IconButton onClick={onClick}>{iconJSX}</IconButton>
      </Tooltip>
    </Stack>
  );
  return buttonJSX;
};
