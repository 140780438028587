import { Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import { CheckboxWithLabel } from "./CheckboxWithLabel";
import { useToggleGroup } from "../hooks/useToggleGroup";

/**
 * @param {object} props
 * @param {string[]} props.prefixes
 * @param {Set<string>} props.checked
 * @param {Function} props.setChecked
 * @returns {ReactElement}
 */
export const AddConfPrefixDialogSuggestedPrefixes = ({
  prefixes,
  checked,
  setChecked,
}) => {
  const { numberOfChecked, areAllChecked, onSelectAllChange, onSelectChange } =
    useToggleGroup({ data: prefixes, checked, setChecked });

  const selectablePrefixesJSX = prefixes.map((prefix) => (
    <CheckboxWithLabel
      key={prefix}
      label={prefix}
      customStyles={{ "& .MuiTypography-root": { fontSize: 14 } }}
      checked={checked.has(prefix)}
      onChange={onSelectChange}
    />
  ));

  return (
    <Stack>
      <CheckboxWithLabel
        enableDivider={false}
        label={`All Prefix Filters (${prefixes.length})`}
        checked={areAllChecked}
        onChange={onSelectAllChange}
      />
      <Stack sx={{ maxHeight: "13.5rem", overflow: "auto" }}>
        {selectablePrefixesJSX}
      </Stack>
      <Typography sx={{ mt: "0.75rem", fontSize: "small" }}>
        Selected Prefix Filters: {numberOfChecked}
      </Typography>
    </Stack>
  );
};
