import { ReactElement, ChangeEvent } from "react";
import { SetStateAction } from "jotai";
import { HiddenPrefixesResult } from "../../types/store";
import { Switch } from "@mui/material";
import { COLUMNS_ENUM } from "../../constants/components/confPrefixesTable";
import {
  getDropdownFilterTypeColumn,
  getTextFieldFilterTypeColumn,
} from "../../other/utils";
import { formatPrefix } from "../formatting";
import { getSwitchColumnFilterOptions } from "./confAutonomousSystemsTable";
import {
  OnAllChange,
  OnChange,
} from "../../types/components/checkboxWithLabel";
import { TableHeaderWithToggle } from "../../components/TableHeaderWithToggle";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setNetworkFilter
 * @param {Function} params.setDisplayedFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setNetworkFilter,
  setDisplayedFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setNetworkFilter(filterList[COLUMNS_ENUM.PREFIX.order][0]);
  setDisplayedFilter(filterList[COLUMNS_ENUM.DISPLAYED.order][0]);
  resetPage();
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsNetworkVisible
 * @param {Function} params.setIsDisplayedVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsNetworkVisible,
  setIsDisplayedVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.PREFIX.name] = setIsNetworkVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DISPLAYED.name] =
    setIsDisplayedVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {object} params
 * @param {(string | undefined | ReactElement)[][]} params.tableData
 * @param {string} params.networkFilter
 * @param {string} params.displayedFilter
 * @param {boolean} params.areAllChecked
 * @param {OnAllChange} params.onSelectAllChange
 * @param {boolean} params.isNetworkVisible
 * @param {boolean} params.isDisplayedVisible
 * @returns {object[]}
 */
export const getColumns = ({
  tableData,
  areAllChecked,
  onSelectAllChange,
  networkFilter,
  displayedFilter,
  isNetworkVisible,
  isDisplayedVisible,
}) => [
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PREFIX.name, {
    filterList: networkFilter ? [networkFilter] : [],
    display: isNetworkVisible,
    customBodyRenderLite: (dataIndex) =>
      formatPrefix(tableData, dataIndex, COLUMNS_ENUM.PREFIX.order),
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.DISPLAYED.name, {
    filterList: displayedFilter ? [displayedFilter] : [],
    display: isDisplayedVisible,
    filterOptions: getSwitchColumnFilterOptions(),
    sort: false,
    download: false,
    customHeadLabelRender: () => (
      <TableHeaderWithToggle
        title={COLUMNS_ENUM.DISPLAYED.name}
        checked={areAllChecked}
        onClick={onSelectAllChange}
      />
    ),
  }),
];

/**
 * @param {object} params
 * @param {string[]} params.configuredPrefixes
 * @param {HiddenPrefixesResult} params.hiddenPrefixes
 * @param {function(SetStateAction<HiddenPrefixesResult>): void} params.setHiddenPrefixes
 * @param {OnChange} params.onSelectChange
 * @returns {(string | undefined | ReactElement)[][]}
 */
export const getTableData = ({
  configuredPrefixes,
  hiddenPrefixes,
  setHiddenPrefixes,
  onSelectChange,
}) =>
  configuredPrefixes.map((prefix) => [
    prefix,
    <Switch
      sx={{ ml: "3.3rem" }}
      key={prefix}
      checked={!hiddenPrefixes.has(prefix)}
      onChange={(event) =>
        onPrefixDisplayedSwitchClick({
          event,
          prefix,
          hiddenPrefixes,
          setHiddenPrefixes,
          onSelectChange,
        })
      }
    />,
  ]);

/**
 *
 * @param {object} params
 * @param {ChangeEvent<HTMLInputElement>} params.event
 * @param {string} params.prefix
 * @param {HiddenPrefixesResult} params.hiddenPrefixes
 * @param {function(SetStateAction<HiddenPrefixesResult>): void} params.setHiddenPrefixes
 * @param {OnChange} params.onSelectChange
 */
const onPrefixDisplayedSwitchClick = ({
  event,
  prefix,
  hiddenPrefixes,
  setHiddenPrefixes,
  onSelectChange,
}) => {
  const enabled = event.target.checked;
  onSelectChange(enabled, prefix);
  const newHiddenPrefixes = new Set(hiddenPrefixes);
  if (!enabled) {
    newHiddenPrefixes.add(prefix);
  } else {
    newHiddenPrefixes.delete(prefix);
  }
  setHiddenPrefixes(newHiddenPrefixes);
};

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.PREFIX.order];
