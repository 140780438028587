import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

/**
 * @param {object} props
 * @param {string?} props.failureReason
 * @param {object} [props.customStyles]
 * @returns {ReactElement?}
 */
export const InputFailureMessage = ({ failureReason, customStyles }) => {
  return failureReason ? (
    <Stack
      sx={{ color: "red2.main", justifyContent: "flex-start", ...customStyles }}
      direction="row"
    >
      <ErrorIcon sx={{ height: "1.05rem" }} />
      <Typography fontSize="0.75rem">{failureReason}</Typography>
    </Stack>
  ) : null;
};
