import { useState } from "react";
import { PageTitle } from "../components/PageTitle";
import { Button, Popover, Stack, Typography } from "@mui/material";
import { auth0HookGetter } from "../other/utils";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";

const Settings = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const { getAccessTokenSilently } = auth0HookGetter()();
  const [anchorEl, setAnchorEl] = useState(null);

  const revision = process.env.REACT_APP_REVISION.slice(0, 7);

  const onCopyTokenButtonClick = async (e) => {
    setAnchorEl(e.currentTarget);
    navigator.clipboard.writeText(await getAccessTokenSilently());
  };

  const onPopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.settings}
        helpText={HELP_TEXT_ENUM.settings}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <Stack spacing={2}>
        <Typography>{`Version: ${revision}`}</Typography>
        <Button
          onClick={onCopyTokenButtonClick}
          sx={{
            color: "white",
            textTransform: "none",
            height: "3rem",
            width: "8rem",
            backgroundColor: (theme) => theme.color.button.sendUsAnEmail,
            ":hover": {
              backgroundColor: (theme) =>
                theme.color.button.onHover.sendUsAnEmail,
            },
          }}
        >
          <Typography>Copy Token</Typography>
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onPopoverClose}
          anchorOrigin={{
            vertical: 3,
            horizontal: 140,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ color: "green" }}
        >
          <Typography sx={{ p: 1, backgroundColor: "green", color: "white" }}>
            Copied!
          </Typography>
        </Popover>
      </Stack>
    </Stack>
  );
};

export { Settings };
