import { Chip, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import { AlertFieldAndValue } from "../../components/AlertFieldAndValue";
import { alertRuleDisplayName, alertSeverity } from "../../constants/components/confAlertsInfoTable";
import { COLUMNS_ENUM } from "../../constants/components/confAlertsTable";
import {
  getTextFieldFilterTypeColumn,
  formatDataService,
  regexToASes,
  getRangeFilterColumn,
  isoDateToNumber,
} from "../../other/utils";
import {
  getValueFromEnum
} from "../formatting";
import {
  FORMAT_RPKI_ENUM,
} from "../../constants/components/routes";
import { SvgWrapper } from "../../components/SvgWrapper";
import { getRangeFilterList } from "../filtering";

/**
 * @param {object} params
 * @param {string[]} params.configuredAlerts
 * @returns {(string | undefined | ReactElement)[][]}
 */
export const getTableData = ({ configuredAlerts }) =>
  configuredAlerts.map(
    ({
      name,
      type,
      vars,
      activation_time,
      id,
      receiver_type,
      receiver_endpoint,
      query,
      fire_alert_regex,
      description,
      severity,
    }) => [
      name,
      type,
      vars,
      activation_time,
      id,
      receiver_type,
      receiver_endpoint,
      query,
      fire_alert_regex,
      description,
      severity,
    ]
  );

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setNameFilter
 * @param {Function} params.setTypeFilter
 * @param {Function} params.setMinTimeCreatedFilter
 * @param {Function} params.setMaxTimeCreatedFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setNameFilter,
  setTypeFilter,
  setMinTimeCreatedFilter,
  setMaxTimeCreatedFilter,
  resetPage
}) => {
  const filterList = applyNewFilters();
  setNameFilter(filterList[COLUMNS_ENUM.NAME.order][0]);
  setTypeFilter(filterList[COLUMNS_ENUM.TYPE.order][0]);
  setMinTimeCreatedFilter(filterList[COLUMNS_ENUM.ACTIVATION_TIME.order][0]);
  setMaxTimeCreatedFilter(filterList[COLUMNS_ENUM.ACTIVATION_TIME.order][1]);
  resetPage();
};

/**
 * @param {object} params
 * @param {object} params.applyNewAlertsSortingType
 * @param {Function} params.setSortAlertsOrder
 * @returns {void}
 */
export const onApplySortAlertDataButtonClick = ({
  applyNewAlertsSortingType,
  setSortAlertsOrder,
}) => {
  setSortAlertsOrder(applyNewAlertsSortingType);
}

/**
 * @param {object} params
 * @param {(string | undefined | ReactElement)[][]} params.tableData
 * @param {string} params.nameFilter
 * @param {string} params.minTimeCreatedFilter
 * @param {string} params.maxTimeCreatedFilter
 * @param {string} params.typeFilter
 * @param {Function} params.setMinTimeCreatedFilter
 * @param {Function} params.setMaxTimeCreatedFilter
 * @returns {object[]}
 */
export const getColumns = ({ tableData, nameFilter, minTimeCreatedFilter, maxTimeCreatedFilter, typeFilter, setMinTimeCreatedFilter, setMaxTimeCreatedFilter }) => [
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.NAME.name, {
    sort: true,
    filterList: nameFilter ? [nameFilter] : [],
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const name = tableData[dataIndex][COLUMNS_ENUM.NAME.order];
      return (
        <Typography style={{width: "283px", fontSize: "16px", whiteSpace: "pre-wrap", wordWrap: "break-word"}} >
          {name}
        </Typography>
      );
    },
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.TYPE.name, {
    /**
     *  for some reason I get alert type data here on sorting (instead of EDIT_RULE)
     *  so I apply the string transformation and comparison here. So I renamed the column
     *  to "Type" for the filtering purposes. See next column "Type2" for the Chip we render. 
     * */
    sort: true,
    sortCompare: (order) => {
      return (row1, row2) => {
        let type1 = alertRuleDisplayName[row1.data];
        let type2 = alertRuleDisplayName[row2.data];
        return (type1.localeCompare(type2)) * (order === 'asc' ? 1 : -1);
      };
    },
    customHeadRender: () => <></>,
    customBodyRenderLite: () => {
      return (
        <SvgWrapper
          id="edit-rule"
          filename="EditIcon"
          sx={{ fontSize: 18 }}
        />
      );
    },
    filterList: typeFilter ? [typeFilter] : [],
    filterType: "custom",
    filterOptions: {
      logic: (value, filters) => {
        if (alertRuleDisplayName[value] === filters[0]) return false;
        return true;
      }
    },
  }),
  getTextFieldFilterTypeColumn("Type2", {
    filter: false,
    sort: false,
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const type = tableData[dataIndex][COLUMNS_ENUM.TYPE.order];
      const formattedType = alertRuleDisplayName[type];
      if (!formattedType) return;
      return (
        <Stack direction="row" sx={{width: "200px"}}>
          <Chip
            sx={{
              height: "1.5rem",
              backgroundColor: "blue3.main",
              color: "white",
              fontSize: "0.75rem",
              ".MuiChip-label": {
                px: "0.5rem",
              },
            }}
            label={formattedType}
          />
        </Stack>
      );
    },
  }),
  getRangeFilterColumn(
    "Time Created",
    {
      filterList: getRangeFilterList(
        minTimeCreatedFilter,
        maxTimeCreatedFilter
      ),
      display: "excluded",
      customHeadRender: () => <></>,
      sort: true,
      sortCompare: (order) => {
        return (row1, row2) => {
          let date1 = new Date(row1.data);
          let date2 = new Date(row2.data);
          return (date1 - date2) * (order === 'asc' ? 1 : -1);
        };
      }
    },
    setMinTimeCreatedFilter,
    setMaxTimeCreatedFilter,
    isoDateToNumber,
    "date"
  ),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.RECEIVER_ENDPOINT.name, {
    display: "excluded",
    filter: false,
    customHeadRender: () => <></>,
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.VARS.name, {
    filter: false,
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const vars = tableData[dataIndex][COLUMNS_ENUM.VARS.order];
      if (!vars) return;
      const type = tableData[dataIndex][COLUMNS_ENUM.TYPE.order];
      const formattedType = alertRuleDisplayName[type];
      switch (formattedType) {
        case alertRuleDisplayName.unexpected_originated_prefixes: {
          const { asn, prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="AS" value={asn} />
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.as_origin_violation_exact: {
          const { asns, prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.as_origin_violation_sub: {
          const { asns, prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes
                  ?.replace(/{/g, "")
                  .replace(/}/g, "")
                  .split(",")
                  .join(",  ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.as_path_diffs: {
          const { base_service, comp_service } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Get AS paths from"
                value={formatDataService(base_service)}
              />
              <AlertFieldAndValue
                field="Compare AS paths with"
                value={formatDataService(comp_service)}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.prefix_diffs: {
          const { asns, base_service } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue
                field="Get prefixes from"
                value={formatDataService(base_service)}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.squatting: {
          const { prefixes, ds_thres } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
              <AlertFieldAndValue
                field="Detecting Sources Threshold"
                value={ds_thres}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.unexpected_neighbor_prefix: {
          const { origin_asn, neighbor_asns } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="Origin AS" value={origin_asn} />
              <AlertFieldAndValue
                field="Neighbor ASes"
                value={neighbor_asns?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.rpki_invalid_detect: {
          const { asns, prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.rpki_invalid_announce: {
          const { asns, rpki_status } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue field="RPKI status" value={getValueFromEnum(FORMAT_RPKI_ENUM, rpki_status)} />
            </Stack>
          );
        }
        case alertRuleDisplayName.rpki_invalid_propagate: {
          const { asn_regex, rpki_status } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="ASes"
                value={regexToASes(asn_regex)?.join(", ")}
              />
              <AlertFieldAndValue field="RPKI status" value={getValueFromEnum(FORMAT_RPKI_ENUM, rpki_status)} />
            </Stack>
          );
        }
        case alertRuleDisplayName.rpki_not_found_propagate: {
          const { asn_regex, rpki_status } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="ASes"
                value={regexToASes(asn_regex)?.join(", ")}
              />
              <AlertFieldAndValue field="RPKI status" value={getValueFromEnum(FORMAT_RPKI_ENUM, rpki_status)} />
            </Stack>
          );
        }
        case alertRuleDisplayName.bogon_prefix_exact: {
          const { asns, bogon_prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue
                field="Bogon prefixes"
                value={bogon_prefixes?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.bogon_prefix_sub: {
          const { asns, bogon_prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue field="ASes" value={asns?.join(", ")} />
              <AlertFieldAndValue
                field="Bogon prefixes"
                value={bogon_prefixes
                  ?.replace(/{/g, "")
                  .replace(/}/g, "")
                  .split(",")
                  .join(",  ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.unexpected_peering: {
          const { configured_asn, neighbor_asns } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Configured AS"
                value={configured_asn}
              />
              <AlertFieldAndValue
                field="Neighbor ASes"
                value={neighbor_asns?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.custom: {
          const query = tableData[dataIndex][COLUMNS_ENUM.QUERY.order];
          const query_params = JSON.stringify(vars);
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Query Parameters"
                value={query_params}
              />
              <AlertFieldAndValue field="Query" value={query} />
            </Stack>
          );
        }
        case alertRuleDisplayName.bogon_as: {
          const { prefixes, bogon_as_regex } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
              <AlertFieldAndValue
                field="Bogon AS Regex"
                value={bogon_as_regex}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.as_path_presence: {
          const { prefixes, asn_present_regex } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
              <AlertFieldAndValue
                field="ASes"
                value={regexToASes(asn_present_regex)?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.as_path_regex_violation: {
          const { prefixes, as_path_regex } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
              <AlertFieldAndValue
                field="AS Path Pattern"
                value={as_path_regex}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.low_prefix_visibility: {
          const { asns, prefixes } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="ASes"
                value={asns?.join(", ")}
              />
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.low_peering_visibility: {
          const { configured_asn, neighbor_asns } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Configured AS"
                value={configured_asn}
              />
              <AlertFieldAndValue
                field="Neighbor ASes"
                value={neighbor_asns?.join(", ")}
              />
            </Stack>
          );
        }
        case alertRuleDisplayName.as_path_length_violation: {
          const { prefixes, as_path_length_thres } = vars;
          return (
            <Stack direction="row" sx={{width: "436px", display: "inline-block"}}>
              <AlertFieldAndValue
                field="Prefixes"
                value={prefixes?.join(", ")}
              />
              <AlertFieldAndValue
                field="AS Path Length Threshold"
                value={as_path_length_thres}
              />
            </Stack>
          );
        }
        default:
          return <Stack direction="row"></Stack>;
      }
    },
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.ID.name, {
    display: "excluded",
    filter: false,
    customHeadRender: () => <></>,
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.QUERY.name, {
    display: "excluded",
    filter: false,
    customHeadRender: () => <></>,
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.FIRE_ALERT_REGEX.name, {
    display: "excluded",
    filter: false,
    customHeadRender: () => <></>,
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.DESCRIPTION.name, {
    display: "excluded",
    filter: false,
    customHeadRender: () => <></>,
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.SEVERITY.name, {
    display: "excluded",
    filter: false,
    customHeadRender: () => <></>,
  }),
];

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.ID.order];
