import { ReactElement } from "react";
import { PrimitiveAtom } from "jotai";
import { SingleDataResult } from "../types/store";
import { Stack, Typography } from "@mui/material";
import { SvgWrapper } from "./SvgWrapper";
import { OverviewCardCount } from "./OverviewCardCount";

/**
 * @param {object} props
 * @param {string} props.icon
 * @param {object} props.iconSX
 * @param {string} props.dataServiceName
 * @param {PrimitiveAtom<SingleDataResult<number>>} props.atom
 * @returns {ReactElement}
 */
export const DataSourcesCardSection = ({
  icon,
  iconSX,
  dataServiceName,
  atom,
}) => {
  const svgWrapperSX = { fontSize: 30, ...iconSX };

  return (
    <Stack>
      <SvgWrapper filename={icon} sx={svgWrapperSX} />
      <Stack
        sx={{
          alignItems: "center",
          pr: "1.5rem",
          height: "2rem",
          mb: "40px",
        }}
        direction="row"
      >
        <Typography sx={{ whiteSpace: "nowrap", pr: "0.5rem" }}>
          {dataServiceName}:
        </Typography>
        <OverviewCardCount
          atom={atom}
          customProgressStyles={{ width: "100%", mt: "0.2rem", ml: "1rem" }}
        />
      </Stack>
    </Stack>
  );
};
