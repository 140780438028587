import { useState, ReactElement, MouseEventHandler, useEffect } from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import Papa from "papaparse";
import ErrorIcon from "@mui/icons-material/Error";
import { useInsertManuallyConfiguredPrefixesMutation } from "../graphql/generated/operations";
import { onAddPrefixes } from "../utils/components/addConfPrefixDialog";
import { COLUMNS_ENUM } from "../constants/components/confPrefixesTable";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {MouseEventHandler} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {object[]} props.tableData
 * @returns {ReactElement}
 */
export const ImportPrefixesFromCSV = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  tableData,
}) => {
  const [failureReason, setFailureReason] = useState("");
  const [file, setFile] = useState("");
  const [, addConfPrefixes] = useInsertManuallyConfiguredPrefixesMutation();

  const clearState = () => {
    setFile("");
    setFailureReason("");
  };

  useEffect(() => {
    if (!isOpen) {
      clearState();
    }
  }, [isOpen]);

  const readCSVfile = () => {
    if (file) {
      Papa.parse(file, {
        delimiter: ",",
        header: true,
        complete: (result) => {
          const prefixesTableData = tableData.map((item) => {
            return item[COLUMNS_ENUM.PREFIX.order];
          });
          const prefixesNotInTable = result.data.filter((prefix) => {
            if (!prefixesTableData.includes(prefix["Prefix"])) {
              return prefix;
            }
          });
          if (prefixesNotInTable.length > 0) {
            addPrefixes(prefixesNotInTable);
          } else {
            setFailureReason("All Prefix Filters are already configured.");
          }
        },
      });
    }
  };

  const addPrefixes = (importData) => {
    onAddPrefixes({
      confPrefixes: importData.map((prefix) => {
        return { network: prefix["Prefix"] };
      }),
      addConfPrefixes,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      close,
    });
  };

  const failureMessage = failureReason ? (
    <Stack
      sx={{ color: "#D2393D", width: "17rem", justifyContent: "flex-start" }}
      direction="row"
    >
      <ErrorIcon sx={{ height: "1.05rem" }} />
      <Typography fontSize="0.75rem">{failureReason}</Typography>
    </Stack>
  ) : undefined;

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{
          p: "2rem",
          height: "16rem",
          minwidth: "20rem",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          Import Prefix Filters CSV File
        </Typography>
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />
        </form>
        {failureMessage}
        <Stack sx={{ justifyContent: "space-evenly" }} direction="row">
          <Button
            sx={{
              color: "primary.main",
              textTransform: "none",
              width: "6rem",
              height: "2.5rem",
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: (theme) => theme.color.bg.selectedDrawerButton,
            }}
            onClick={close}
          >
            <Typography noWrap fontSize="0.875rem">
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              width: "6.7rem",
              height: "2.5rem",
              backgroundColor: "primary.main",
              ":hover": {
                backgroundColor: (theme) => theme.color.button.onHover.action,
              },
              ":disabled": {
                backgroundColor: "gray1.main",
              },
            }}
            onClick={readCSVfile}
          >
            <Typography noWrap fontSize="0.875rem">
              Import
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
