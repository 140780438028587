import { ReactElement, Key } from "react";
import { OnChange } from "../types/components/tableSelectButton";
import { MenuItem, Select } from "@mui/material";
import { toPascalCase } from "../utils/formatting";

/**
 *
 * @param {object} props
 * @param {Key} props.selectKey
 * @param {string} props.value
 * @param {string[]} props.items
 * @param {boolean} props.disabled
 * @param {OnChange} props.onChange
 * @returns {ReactElement}
 */
export const TableSelectButton = ({
  selectKey,
  value,
  items,
  disabled,
  onChange,
}) => {
  /** @type {ReactElement[]} */
  const menuItems = [];

  items.map((item) =>
    menuItems.push(
      <MenuItem key={item} value={item}>
        {toPascalCase(item)}
      </MenuItem>
    )
  );

  return (
    <Select
      key={selectKey}
      value={value}
      variant="standard"
      disabled={disabled}
      disableUnderline
      displayEmpty
      renderValue={(value) =>
        items.includes(value) ? toPascalCase(value) : "None"
      }
      onChange={onChange}
    >
      {menuItems}
    </Select>
  );
};
