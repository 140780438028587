import { ReactElement } from "react";
import { Dialog, Stack, Typography } from "@mui/material";
import { useDeleteManuallyConfiguredPrefixesMutation } from "../graphql/generated/operations";
import { Close } from "../types/components/dialog";
import { ButtonStrip } from "./ButtonStrip";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {string[]} props.prefixes
 * @returns {ReactElement}
 */
export const RemoveConfPrefixDialog = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  prefixes,
}) => {
  const [, removeConfPrefixes] = useDeleteManuallyConfiguredPrefixesMutation();
  const numberOfPrefixesToRemove = prefixes.length;

  const onRemovePrefixes = async () => {
    if (numberOfPrefixesToRemove < 1) {
      console.error("Invalid number of prefixes to remove.");
      close();
      return;
    }

    const result = await removeConfPrefixes({ prefixes });

    let snackbarMessage = `Removed Prefix ${prefixes[0]}`;
    let errorMessage = `Could not remove Prefix ${prefixes[0]}:`;
    if (numberOfPrefixesToRemove > 1) {
      snackbarMessage = `Removed ${numberOfPrefixesToRemove} Prefixes`;
      errorMessage = `Could not remove ${numberOfPrefixesToRemove} Prefixes:`;
    }

    const numberOfRemovedPrefixes =
      result.data?.deleteManuallyConfiguredPrefixes?.affected_rows;
    if (numberOfRemovedPrefixes === numberOfPrefixesToRemove) {
      setSnackbarMessage(snackbarMessage);
      openSuccessSnackbar();
    } else {
      console.error(errorMessage, result);
    }

    close();
  };

  let title = `Confirm removal of Prefix Filter ${prefixes[0]}`;
  if (numberOfPrefixesToRemove > 1) {
    title = `Confirm removal of ${numberOfPrefixesToRemove} Prefix Filters`;
  }

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{ p: "2rem", minHeight: "15rem", justifyContent: "space-between" }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          {title}
        </Typography>
        {numberOfPrefixesToRemove > 1 ? (
          <Typography sx={{ fontWeight: "bold", pt: "30px", pb: "30px" }}>
            {prefixes.join(", ")}
          </Typography>
        ) : undefined}
        <Typography sx={{ color: "gray2.main", pb: "1rem" }}>
          You can always add {numberOfPrefixesToRemove > 1 ? "them" : "it"} back
          using the {'"Add Prefix Filter"'} button.
        </Typography>
        <ButtonStrip
          onSecondaryButtonClick={close}
          onMainButtonClick={onRemovePrefixes}
          mainButtonTitle="Remove"
          mainButtonCustomStyles={{
            backgroundColor: "red1.main",
            width: "6.5rem",
            ":hover": {
              backgroundColor: "red1.light",
            },
          }}
        />
      </Stack>
    </Dialog>
  );
};
