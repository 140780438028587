import { ReactElement } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { defaultStyles } from "../constants/components/addAlertRuleStepper";

/**
 * @param {object} props
 * @param {number} props.selectedStep
 * @returns {ReactElement}
 */
export const AddAlertRuleStepper = ({ selectedStep }) => {
  return (
    <Stepper sx={{ mb: "2rem" }} activeStep={selectedStep} alternativeLabel>
      <Step sx={defaultStyles} key={"Alert Rule"}>
        <StepLabel>Alert Rule</StepLabel>
      </Step>
      <Step sx={defaultStyles} key={"Configuration"}>
        <StepLabel>Configuration</StepLabel>
      </Step>
      <Step sx={defaultStyles} key={"Preview"}>
        <StepLabel>Preview</StepLabel>
      </Step>
    </Stepper>
  );
};
