import { Typography } from "@mui/material";
import { ConfAlertsInfoTable } from "../components/ConfAlertsInfoTable";
import { InlineBoldText } from "../components/InlineBoldText";

export const TITLE_ENUM = Object.freeze({
  setup: {
    autonomousSystems: "Setup | AS Filters",
    prefixes: "Setup | Prefix Filters",
    alerts: "Setup | Alert Rules",
    dataServices: "Setup | Data Services",
  },
  lookingglass: "Looking Glass",
  opensource: "Open Source",
  api: {
    queries: "API | Queries",
    editor: "API | Editor",
  },
  alerts: "Alerts",
  // alerts: {
  //   stream: "Alerts | Stream",
  //   configuration: "Alerts | Configuration",
  // },
  // visibility: "Visibility",
  settings: "Settings",
  integrations: "Integrations"
});

export const HELP_TEXT_ENUM = Object.freeze({
  setup: {
    autonomousSystems: (
      <Typography>
        Set the AS(es) [Autonomous System(s)] you want to monitor. Click on
        <InlineBoldText text="Add AS Filter" />
        to add a new AS Filter. You can switch off the
        <InlineBoldText text="Displayed" />
        toggle next to each AS if you want to stop viewing data from it.
        However, the AS will keep being monitored. To remove an AS you do not
        want to monitor anymore, select it and click
        <InlineBoldText text="Delete." />
        <br />
        <br />
        AS filters accept route announcements when a given AS appears as origin
        or anywhere in the AS path. Filtering on origin (default option) or
        anywhere in the AS path can be configured in Setup {">"} Data Services,
        by selecting AS Path options {'"'}Origin{'"'} or {'"'}Any{'"'}{" "}
        respectively.
      </Typography>
    ),
    prefixes: (
      <Typography>
        Set the Prefix(es) you want to monitor. Click on
        <InlineBoldText text="Add Prefix Filter" />
        to add a new prefix filter. You can switch off the
        <InlineBoldText text="Displayed" />
        toggle next to each prefix if you want to stop viewing data from it.
        However, the prefix will keep being monitored. To remove a prefix you do
        not want to monitor anymore, select it and click
        <InlineBoldText text="Delete." />
        <br />
        <br />
        Prefix filters accept route announcements with a given prefix,
        regardless of origin AS. They can be set either manually by typing them
        in CIDR format, or they can be automatically created in bulk by the
        Platform, which detects the prefixes originated by defined AS filters.
      </Typography>
    ),
    alerts: (
      <>
        <Typography>
          Select, configure and activate the alert rules for routing events you
          would like to be notified about. Click on
          <InlineBoldText text="Add Alert Rule" />
          to add a new alert rule. To edit an existing alert rule, click
          on<InlineBoldText text="Edit."/>
          To remove an alert rule for an event you do
          not want to be notified about anymore, select it and click
          <InlineBoldText text="Delete." />
          All active alert rules are displayed in the current page.
          <br />
          <br />
          Alert rules can be configured with different parameters, like
          different type, origin ASes, neighbors, prefixes and notification
          channel options. Note that for correct alerting operation, you should
          setup properly your prefix filters besides the AS filters.
        </Typography>
        <ConfAlertsInfoTable />
      </>
    ),
    dataServices: (
      <Typography>
        Enable/disable data services and select their AS path filtering option (
        {'"'}Origin{'"'} or {'"'}Any{'"'}). AS path filters accept route
        announcements when a given AS (previously set in {'"'}AS Filters{'"'})
        appears as origin or anywhere in the AS path. You can switch off the
        <InlineBoldText text="Displayed" /> toggle next to each data service if
        you want to stop viewing data from it. However, the Data Service will
        keep collecting data.
        <br />
        <br />
        Data services can be your own Routers (via a BGP or BMP session) or
        external (e.g., Code BGP Monitor, RIS Live websocket) and filter
        incoming data related to the AS and prefix filters you have setup. Each
        table row represents a data service.
        <InlineBoldText text="Click on each row" />
        to reveal the source(s) contributing data for each service.
      </Typography>
    ),
  },
  lookingglass: {
    prefixes: (
      <Typography>
        Real-time views of prefixes, based on the AS and prefix filters you have setup. The prefixes in this table appear in routes that originate from the AS(es) you configured in {'"'}Setup | AS Filters{'"'} and the prefixes you configured in {'"'}Setup | Prefix Filters{'"'}, regardless of which AS originates them.
        <br />
        <br />
        Each table row represents a Prefix. <InlineBoldText text="Click on each row" /> to reveal the sources (Code BGP Monitors, RIS Live peers or your own routers) that have seen each Prefix, along with their geolocation information.
        <br />
        <br />
        Data Sources (#) indicates the total number of peers that have seen each Prefix. Data Sources (%) shows the ratio of peers that have seen each prefix to the total number of active peers that could potentially see it, depending on the IP protocol (IPv4/IPv6). 
        <br />
        <br />
        Use the table filters to filter Routes based on all available fields. Note that the Prefix filter field supports POSIX regular expressions.
        <br />
        <br />
        <InlineBoldText text='Click on "Show Columns"'/> to hide unwanted data columns or reveal additional ones (IP Version and Mask Length).
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"'/> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"'/> to copy a URL of the current Prefixes view.
      </Typography>
    ),
    autonomous_systems: (
      <Typography>
        Real-time views of Autonomous Systems (ASes), based on the AS filters you have setup. The ASes in this table are configured in {'"'}Setup | AS Filters{'"'}.
        <br />
        <br />
        Each table row represents an AS. <InlineBoldText text="Click on a row" /> to reveal the sources (Code BGP Monitors, RIS Live peers or your own routers) that have seen each AS, along with their geolocation information. Click on any column to sort the data.
        <br />
        <br />
        Data Sources (#) indicates the total number of peers that have seen each Prefix. Data Sources (%) shows the ratio of peers that have seen each AS to the total number of active peers that could potentially see it. 
        <br />
        <br />
        Use the table filters to filter Routes based on all available fields.
        <br />
        <br />
        <InlineBoldText text='Click on "Show Columns"' /> to hide unwanted data columns.
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"' /> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"'/> to copy a URL of the current Autonomous Systems view.
      </Typography>
    ),
    peerings: (
      <Typography>
        Real-time views of Peerings, based on the AS filters you have setup. The ASes in this table are configured in {'"'}Setup | AS Filters{'"'}, and appear in the column {'"'}Configured AS{'"'}.
        <br />
        <br />
        Each table row represents an AS level Peering, which is inferred based on the next hop in the AS Path. <InlineBoldText text="Click on each row" /> to reveal the sources (Code BGP Monitors, RIS Live peers or your own routers) that have seen each Peering, along with their geolocation information. Click on any column to sort the data.
        <br />
        <br />
        Data Sources (#) indicates the total number of peers that have seen each Peering. Data Sources (%) shows the ratio of peers that have seen each Peering to the total number of active peers that could potentially see it.
        <br />
        <br />
        Use the table filters to filter Routes based on all available fields.
        <br />
        <br />
        <InlineBoldText text='Click on "Show Columns"' /> to hide unwanted data columns.
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"' /> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"'/> to copy a URL of the current Peerings view.
      </Typography>
    ),
    routes: (
      <Typography>
        Real-time views of Routes, based on the AS and prefix filters you have set up. The Routes in this table either originate from the AS(es) you have configured in {'"'}Setup | AS Filters{'"'}, or they contain the prefixes you configured in {'"'}Setup | Prefix Filters{'"'}, regardless of which AS originates them.
        <br />
        <br />
        Each table row represents a Route, which includes the Prefix, AS Path, RPKI Status, and the timestamp of the last update related to that Route. <InlineBoldText text="Click on each row" /> to display the sources (Code BGP Monitors, RIS Live peers, or your own routers) that have seen each Route, as well as their geolocation information.
        <br />
        <br />
        Use the table filters to filter Routes based on all available fields. Note that the Prefix and AS Path filter fields support POSIX regular expressions.
        <br />
        <br />
        <InlineBoldText text='Click on "Show Columns"' /> to hide unwanted data columns or reveal additional ones (Communities and First Detected).
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"' /> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"'/> to copy a URL of the current Routes view.
      </Typography>
    ),
    rpki_roas: (
      <Typography>
        Table view of active RPKI ROAS, related to the AS filters you have configured in {'"'}Setup | AS Filters{'"'}.
        <br />
        <br />
        Each table row represents an RPKI ROA, which includes the Prefix, Max Length, and AS. <InlineBoldText text="Click on a row" /> to reveal the Data Service and URI for each ROA. Click on any column to sort the data.
        <br />
        <br />
        Use the table filters to filter RPKI ROAS based on all available fields.
        <br />
        <br />
        <InlineBoldText text='Click on "Show Columns"' /> to hide unwanted data columns or reveal additional ones (IP Version and Mask Length).
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"' /> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"'/> to copy a URL of the current RPKI ROAs view.
      </Typography>
    )
  },
  alerts: {
    active: (
      <Typography>
        Active/Ongoing Alerts, based on the Alert Rules you have setup. The Alerts in this page are triggered based on the configuration of the matching Alert Rule in the Setup | Alert Rules section.
        <br />
        <br />
        Each card represents an Active Alert, and includes the Custom Alert Name, Type, Timestamp and Severity.<br /><InlineBoldText text="Click on each card&apos;s arrow"/> to expand the view and reveal the event&apos;s details.
        <br />
        <br />
        Use the filters to narrow down Active Alerts based on all available fields.
        <br />
        <br />
        <InlineBoldText text='Click on "Sort By"' /> to arrange the data based on your chosen field.
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"' /> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"' /> to copy a URL of the current Active Alerts view.
      </Typography>
    ),
    resolved: (
      <Typography>
        The Alerts displayed on this page are resolved. They were originally triggered based on the configuration of a matching Alert Rule in the Setup | Alert Rules section. These Alerts are now marked as resolved, either because the Routes that triggered them have been withdrawn, or because the Alert Rule was adjusted by the user to encompass the aforementioned Routes.
        <br />
        <br />
        Each card represents a Resolved Alert, and includes the Custom Alert Name, Type, Timestamp and Severity.<br /><InlineBoldText text="Click on each card&apos;s arrow"/> to expand the view and reveal the event&apos;s details.<br />&nbsp;To remove a Resolved Alert, select it and click<InlineBoldText text='"Delete"' />.
        <br />
        <br />
        Use the filters to narrow down Resolved Alerts based on all available fields.
        <br />
        <br />
        <InlineBoldText text='Click on "Sort By"' /> to arrange the data based on your chosen field.
        <br />
        <br />
        <InlineBoldText text='Click on "Download CSV"' /> to export the data in CSV format.
        <br />
        <br />
        <InlineBoldText text='Click on "Share"' /> to copy a URL of the current Resolved Alerts view.
      </Typography>
    )
  },
  api: {
    queries: (
      <Typography>
        Use our powerful GraphQL API to answer common questions about the
        configured BGP resources in real-time.
        <br />
        <br />
        Moreover, click on
        <InlineBoldText text="Try GraphQL" />
        to open each query in the GraphQL editor.
      </Typography>
    ),
  },
  // alerts: {
  //   stream: `Streaming alerts about your BGP resources.`,
  //   configuration: `Alerts configuration.`,
  // },
  // visibility: `BGP Visibility.`,
  settings: `Code BGP Platform User Settings.`,
  integrations: (
    <Typography>
      Integrations of Code BGP platform with external services. 
      Click on <InlineBoldText text="View"/> to launch each integration and 
      <InlineBoldText text="Docs"/> to check out its documentation.
      <br />
      <br /> 
      Note that you will need access credentials from Code BGP to view the integrations. 
      Contact Code BGP team for more details about your access capabilities 
      (support@codebgp.com).
    </Typography>
  ),
  opensource: (
    <Typography>
      These are the open source licensed components downloaded with the minified JS bundle on your browser upon visit. The component names, licenses, licensors and sources are listed. For more information please contact support@codebgp.com.
    </Typography>
  )
});
