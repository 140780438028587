import { LinearProgress, Stack, Typography } from "@mui/material";

export const TableFooterDisplayedRowsLabel = ({ from, to, count }) => {
  const fromToJSX = (
    <Typography sx={{ fontSize: 14 }}>
      {from}-{to} of
    </Typography>
  );
  const totalNumberOfItemsJSX =
    count !== -1 ? (
      <Typography
        sx={{
          minWidth: "2rem",
          fontSize: 14,
          ml: "0.3rem",
        }}
      >
        {count}
      </Typography>
    ) : (
      <LinearProgress sx={{ width: "2rem", ml: "0.3rem", mt: "0.6rem" }} />
    );

  return (
    <Stack direction="row">
      {fromToJSX}
      {totalNumberOfItemsJSX}
    </Stack>
  );
};
