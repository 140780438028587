import { AutonomousSystemsSubscription } from "../../graphql/generated/operations";
import { getDataSourcesPercentage } from "../state";

/**
 * @param {AutonomousSystemsSubscription | undefined} data
 * @param {number} totalNumberOfDataSources
 * @returns {number[][]}
 */
export const formatter = (data, totalNumberOfDataSources) => {
  /** @type {number[][]} */
  const autonomousSystems = [];

  data?.autonomousSystems.forEach(({ number, data_source_count }) => {
    const dataSourcesPercentage = getDataSourcesPercentage({
      data_source_count,
      totalNumberOfDataSources,
    });
    autonomousSystems.push([number, data_source_count, dataSourcesPercentage]);
  });

  return autonomousSystems;
};
