import { useActiveAlertsCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useActiveAlertsCount";
import { useData } from "./useData";
import { firingAlertsCountAtom } from "../store/overviewContent";

export const useActiveAlertsCount = () => {
  useData({
    operation: useActiveAlertsCountSubscription,
    formatter,
    atom: firingAlertsCountAtom,
  });
};
