import { ReactElement } from "react";
import { AccordionDetails, Stack } from "@mui/material";

/**
 * @param {object} props
 * @param {object} props.input
 * @param {object} props.output
 * @returns {ReactElement}
 */
export const QueryAnswer = ({ input, output }) => {
  return (
    <AccordionDetails
      sx={{
        borderTopStyle: "solid",
        borderTopWidth: "0.15rem",
        borderTopColor: "gray1.main",
        pl: "3.2rem",
        pt: "1rem",
      }}
    >
      <Stack>
        <>
          {input}
          {output}
        </>
      </Stack>
    </AccordionDetails>
  );
};
