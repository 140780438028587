import PropTypes from "prop-types";
import { SvgWrapper } from "./SvgWrapper";
import { Button, Link } from "@mui/material";

export const TryGraphQLButton = ({ link }) => {
  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      <Button
        sx={{
          color: "white",
          textTransform: "none",
          height: "2.5rem",
          width: "8.75rem",
          backgroundColor: "primary.main",
          ":hover": {
            backgroundColor: (theme) => theme.color.button.onHover.action,
          },
        }}
      >
        Try GraphQL
        <SvgWrapper
          sx={{ color: "white", fontSize: 16, ml: "0.625rem" }}
          filename="NewTabIcon"
        />
      </Button>
    </Link>
  );
};

TryGraphQLButton.propTypes = {
  link: PropTypes.string.isRequired,
};
