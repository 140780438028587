import { useAtom } from "jotai";
import Stack from "@mui/material/Stack";
import { DrawerDropdown } from "./DrawerDropdown";
import { DrawerButton } from "./DrawerButton";
import { Feedback } from "./Feedback";
import Slide from "@mui/material/Slide";
import { IconButton, Typography } from "@mui/material";
import { RipeDialog } from "./RipeDialog";
import { useOpenClose } from "../hooks/useOpenClose";
import { SvgWrapper } from "./SvgWrapper";
import { isDrawerOpenAtom } from "../other/store";
import { PATHS } from "../constants/paths";
import { DRAWER_BUTTONS } from "../constants/drawer";
import { DocsButton } from "./DocsButton";
import { getTenantName } from "../other/utils";
import { DrawerButtonAlerts } from "./DrawerButtonAlerts";

export const ApplicationDrawer = () => {
  const [isDrawerOpen] = useAtom(isDrawerOpenAtom);
  const {
    isOpen: isRipeDialogOpen,
    open: openRipeDialog,
    close: closeRipeDialog,
  } = useOpenClose();

  return (
    <Slide in={isDrawerOpen} direction="right" mountOnEnter unmountOnExit>
      <Stack
        sx={{
          backgroundColor: (theme) => theme.color.bg.drawer,
          position: "fixed",
          left: 0,
          top: (theme) => theme.space.height.appBar,
          width: (theme) => theme.space.width.drawer,
          height: (theme) => `calc(100% - ${theme.space.height.appBar})`,
        }}
        justifyContent="space-between"
        overflow="auto"
        data-testid="AppDrawer"
      >
        <Stack>
          <DrawerButton
            key={DRAWER_BUTTONS.overview}
            title={DRAWER_BUTTONS.overview}
            url={PATHS.dashboard.overview}
          />
          <DrawerDropdown
            key={DRAWER_BUTTONS.setup.index}
            title={DRAWER_BUTTONS.setup.index}
            url={PATHS.dashboard.setup.index}
            childrenButtons={[
              {
                title: DRAWER_BUTTONS.setup.autonomousSystems,
                path: PATHS.dashboard.setup.autonomousSystems,
              },
              {
                title: DRAWER_BUTTONS.setup.prefixes,
                path: PATHS.dashboard.setup.prefixes,
              },
              {
                title: DRAWER_BUTTONS.setup.alerts,
                path: PATHS.dashboard.setup.alerts,
              },
              {
                title: DRAWER_BUTTONS.setup.dataServices,
                path: PATHS.dashboard.setup.dataServices,
              },
            ]}
          />
          <DrawerButton
            key={DRAWER_BUTTONS.lookingglass}
            title={DRAWER_BUTTONS.lookingglass}
            url={PATHS.dashboard.lookingglass.prefixes}
          />
          <DrawerButtonAlerts
            key={DRAWER_BUTTONS.alerts}
            title={DRAWER_BUTTONS.alerts}
            url={PATHS.dashboard.alerts.active}
          />
          <DrawerDropdown
            key={DRAWER_BUTTONS.api.index}
            title={DRAWER_BUTTONS.api.index}
            url={PATHS.dashboard.api.index}
            childrenButtons={[
              {
                title: DRAWER_BUTTONS.api.queries,
                path: PATHS.dashboard.api.queries,
              },
              {
                title: DRAWER_BUTTONS.api.editor,
                path: PATHS.dashboard.api.editor,
              },
            ]}
          />
          {getTenantName() !== "bogons" ? (
            <DrawerButton
              key={DRAWER_BUTTONS.integrations}
              title={DRAWER_BUTTONS.integrations}
              url={PATHS.dashboard.integrations}
            />
          ) : (
            <></>
          )}
          {/* <DrawerDropdown
            key={DRAWER_BUTTONS.alerts.index}
            title={DRAWER_BUTTONS.alerts.index}
            url={PATHS.dashboard.alerts.index}
            childrenButtons={[
              {
                title: DRAWER_BUTTONS.alerts.stream,
                path: PATHS.dashboard.alerts.stream,
              },
              {
                title: DRAWER_BUTTONS.alerts.configuration,
                path: PATHS.dashboard.alerts.configuration,
              },
            ]}
          /> */}
          {/* <DrawerButton
            key={DRAWER_BUTTONS.visibility}
            title={DRAWER_BUTTONS.visibility}
            url={PATHS.dashboard.visibility}
          /> */}
        </Stack>
        <Stack
        // commented out together with settings button
        // sx={{
        //   borderTopColor: (theme) => theme.color.button.sendUsAnEmail,
        //   borderTopStyle: "solid",
        //   borderTopWidth: (theme) => theme.space.width.divider,
        // }}
        >
          {/* <DrawerButton
            key={DRAWER_BUTTONS.settings}
            title={DRAWER_BUTTONS.settings}
            url={PATHS.dashboard.settings}
          /> */}
          <DocsButton />
          <DrawerButton
            key={DRAWER_BUTTONS.opensource}
            title={DRAWER_BUTTONS.opensource}
            url={PATHS.dashboard.opensource}
          />
          <Feedback />
          <Stack
            direction="row"
            sx={{
              borderTopColor: (theme) => theme.color.button.sendUsAnEmail,
              borderTopStyle: "solid",
              borderTopWidth: (theme) => theme.space.width.divider,
            }}
            justifyContent="space-between"
            alignItems="center"
            p="1rem"
          >
            <Typography fontSize="0.75rem" color="white">
              Acknowledgments:
            </Typography>
            <IconButton
              sx={{ py: 0 }}
              aria-label="ripe icon"
              onClick={openRipeDialog}
            >
              <SvgWrapper
                filename="RipeNccLogoWhite"
                sx={{ width: 100, height: 28 }}
                viewBox="0 0 707.9 177.8"
              />
            </IconButton>
          </Stack>
          <RipeDialog isOpen={isRipeDialogOpen} close={closeRipeDialog} />
        </Stack>
      </Stack>
    </Slide>
  );
};
