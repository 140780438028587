import { useCallback, useState } from "react";
import PropTypes from "prop-types";

export const useOpenClose = ({ initialState = false } = {}) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = () => {
    setIsOpen(false);
  };

  return { isOpen, open, close };
};

useOpenClose.propTypes = {
  initialState: PropTypes.bool.isRequired,
};
