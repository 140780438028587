import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { AlertFieldAndValue } from "./AlertFieldAndValue";

/**
 * @param {object} props
 * @param {string} props.createdDate
 * @param {string} props.receiverType
 * @param {string} props.receiverEndpoint
 * @returns {ReactElement}
 */
export const ConfAlertsExpandedRowHeader = ({
  createdDate,
  receiverType,
  receiverEndpoint,
}) => {
  const notifications = receiverType + " (" + receiverEndpoint + ")";
  return (
    <Stack direction="row" fontSize="0.875rem">
      <Stack sx={{ mr: "0.5rem" }}>
        <AlertFieldAndValue
          field="Created"
          value={createdDate}
          fontSize="0.875rem"
        />
      </Stack>
      |
      <Stack sx={{ ml: "0.5rem" }}>
        <AlertFieldAndValue
          field="Notifications"
          value={notifications}
          fontSize="0.875rem"
        />
      </Stack>
    </Stack>
  );
};
