import { useRpkiRoasCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useRpkiRoasCount";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredDataServicesAtom,
  displayedConfiguredPrefixesAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import { rpkiRoasCountAtom } from "../store/overviewContent";
import { getConfFilters } from "../utils/components/rpkiRoas";

export const useRpkiRoasCount = () => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );
  const [{ data: prefixes, fetching: prefixesFetching, error: prefixesError }] =
    useAtom(displayedConfiguredPrefixesAtom);
  const [
    {
      data: dataServices,
      fetching: dataServicesFetching,
      error: dataServicesError,
    },
  ] = useAtom(displayedConfiguredDataServicesAtom);

  const confState = {
    fetching: asnsFetching || prefixesFetching || dataServicesFetching,
    error: asnsError ?? prefixesError ?? dataServicesError,
  };

  useData({
    operation: useRpkiRoasCountSubscription,
    options: {
      variables: {
        where: getConfFilters(asns, prefixes, dataServices),
      },
      pause: !asns || !prefixes || !dataServices,
    },
    formatter,
    confState,
    atom: rpkiRoasCountAtom,
  });
};
