import { ReactElement } from "react";
import { Dialog, Stack, Typography } from "@mui/material";
import { useDeleteAlertsMutation } from "../graphql/generated/operations";
import { Close } from "../types/components/dialog";
import { ButtonStrip } from "./ButtonStrip";
import { RemoveAlertsArgsTable } from "./RemoveAlertsArgsTable";
import { ToWords } from "to-words";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {string[]} props.alerts
 * @returns {ReactElement}
 */
export const RemoveResolvedAlertsDialog = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  alerts,
}) => {
  const [, deleteAlerts] = useDeleteAlertsMutation();
  const numberOfAlertsToRemove = alerts.length;
  const onRemoveAlerts = async () => {
    if (numberOfAlertsToRemove < 1) {
      console.error("Invalid number of resolved alerts to remove.");
      close();
      return;
    }

    const result = await deleteAlerts({
      ids: alerts.map(({ id }) => id),
    });

    let snackbarMessage = `Removed Resolved Alert ${alerts[0]?.name}`;
    let errorMessage = `Could not remove Alert ${alerts[0]?.name}:`;
    if (numberOfAlertsToRemove > 1) {
      snackbarMessage = `Removed ${numberOfAlertsToRemove} Alerts`;
      errorMessage = `Could not remove ${numberOfAlertsToRemove} Alerts:`;
    }

    const numberOfRemovedAlerts = result.data?.deleteAlerts?.affected_rows;
    if (numberOfRemovedAlerts === numberOfAlertsToRemove) {
      setSnackbarMessage(snackbarMessage);
      openSuccessSnackbar();
    } else {
      console.error(errorMessage, result);
    }

    close();
  };

  const infoTables = [];
  const toWords = new ToWords();

  if (alerts.length > 1) {
    alerts.forEach((alert) => {
      infoTables.push(
        <div style={{ paddingBottom: "50px" }}>
          <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
            {'"'}
            {alert.name}
            {'"'}
          </Typography>
          <RemoveAlertsArgsTable alertData={alert.alldata} />
        </div>
      );
    });
  } else {
    infoTables.push(
      <div style={{ paddingBottom: "50px" }}>
        <RemoveAlertsArgsTable alertData={alerts[0]?.alldata} />
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{ p: "2rem", height: "35rem", justifyContent: "space-between" }}
      >
        {numberOfAlertsToRemove > 1 ? (
          <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
            Confirm removal of{" "}
            {toWords.convert(numberOfAlertsToRemove).toLowerCase()} (
            {numberOfAlertsToRemove}) Alerts:
            <br />
            <br />
          </Typography>
        ) : (
          <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
            Confirm removal of Alert {'"'}
            {alerts[0]?.name}
            {'"'}
          </Typography>
        )}
        {infoTables}
        <Typography sx={{ color: "gray2.main", paddingBottom: "20px" }}>
          {numberOfAlertsToRemove > 1
            ? "Once the Alerts are deleted, they can not be retrieved"
            : "Once the Alert is deleted, it can not be retrieved"}
        </Typography>
        <Stack style={{ paddingBottom: "50px" }}>
          <ButtonStrip
            onSecondaryButtonClick={close}
            onMainButtonClick={onRemoveAlerts}
            mainButtonTitle="Remove"
            mainButtonCustomStyles={{
              backgroundColor: "red1.main",
              width: "6.5rem",
              ":hover": {
                backgroundColor: "red1.light",
              },
            }}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
