import { ReactElement } from "react";
import { Button, Card, Stack, Typography } from "@mui/material";
import { ActionButton } from "./ActionButton";
import { defaultSX } from "../constants/components/overviewCard";
import { SvgWrapper } from "./SvgWrapper";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.serviceURL
 * @param {string} props.docsURL
 * @param {ReactElement} [props.numberOfItemsJSX]
 * @param {string} [props.buttonTitle]
 * @param {string} [props.icon]
 * @returns {ReactElement}
 */
export const IntegrationsCard = ({
  title,
  serviceURL,
  docsURL,
  buttonTitle = "View",
  icon,
}) => {
  const cardSX = { ...defaultSX, height: "279px", p: "32px" };

  const content = icon ? (
    <Stack sx={{ justifyContent: "space-between", alignItems: "center" }}>
      <SvgWrapper
        filename={icon}
        sx={{ color: "blue4.main", fontSize: 64 }}
        viewBox={"0 0 128 128"}
      />
      <Typography sx={{ whiteSpace: "pre-wrap", mt: "20px", height: "37px" }}>
        {title}
      </Typography>
    </Stack>
  ) : (
    <Typography sx={{ pb: "0.35rem" }}>{title}</Typography>
  );

  return (
    <Card sx={cardSX}>
      <Stack sx={{ justifyContent: "space-between", ml: "15px" }}>
        <Stack>{content}</Stack>
        <Stack sx={{ mt: "20px" }}>
          <ActionButton
            customStyles={{ height: "40px", width: "10rem" }}
            title={buttonTitle}
            onClick={() => {
              window.open(serviceURL, "_blank");
            }}
          />
          <Button
            href={docsURL}
            target="_blank"
            sx={{
              color: "#1268FB",
              textTransform: "none",
              fontWeight: "400",
              pt: "16px",
            }}
          >
            Docs
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
