import { useState } from "react";
import { Snackbar, Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { useRole } from "../hooks/useRole";
import { useOpenClose } from "../hooks/useOpenClose";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { isEditor } from "../other/utils";
import { AddConfPrefixDialog } from "../components/AddConfPrefixDialog";
import { Alert } from "../components/Alert";
import { RemoveConfPrefixDialog } from "../components/RemoveConfPrefixDialog";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { ConfPrefixesTable } from "../components/ConfPrefixesTable";
import { useSearchParams } from "react-router-dom";

export const Prefixes = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [prefixesToRemove, setPrefixesToRemove] = useState([]);
  const role = useRole();
  const {
    isOpen: isAddConfPrefixDialogOpen,
    open: openAddConfPrefixDialog,
    close: closeAddConfPrefixDialog,
  } = useOpenClose();
  const {
    isOpen: isRemoveConfPrefixDialogOpen,
    open: openRemoveConfPrefixDialog,
    close: closeRemoveConfPrefixDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();

  const [searchParams] = useSearchParams();
  const urlParams = [];
  for (let entry of searchParams.entries()) {
    urlParams.push(entry);
  }

  let addConfPrefixDialog;
  let removeConfPrefixDialog;
  let successSnackbar;
  if (isEditor(role)) {
    addConfPrefixDialog = (
      <AddConfPrefixDialog
        isOpen={isAddConfPrefixDialogOpen}
        close={closeAddConfPrefixDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
      />
    );
    removeConfPrefixDialog = (
      <RemoveConfPrefixDialog
        isOpen={isRemoveConfPrefixDialogOpen}
        close={closeRemoveConfPrefixDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        prefixes={prefixesToRemove}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.setup.prefixes}
        helpText={HELP_TEXT_ENUM.setup.prefixes}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <ConfPrefixesTable
        role={role}
        openAddConfPrefixDialog={openAddConfPrefixDialog}
        openRemoveConfPrefixDialog={openRemoveConfPrefixDialog}
        setPrefixesToRemove={setPrefixesToRemove}
        urlParams={urlParams}
      />
      {addConfPrefixDialog}
      {removeConfPrefixDialog}
      {successSnackbar}
    </Stack>
  );
};
