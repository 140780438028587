import { parameterNameMap } from "../../constants/components/argsTable";
import { formatDataService, regexToASes } from "../../other/utils";
import {
  BGP_ROUTER,
  CODEBGP_MONITOR,
  RIS_LIVE,
} from "../../constants/dataSourcesTable";
import {
  getValueFromEnum
} from "../formatting";
import {
  FORMAT_RPKI_ENUM,
} from "../../constants/components/routes";

const ASPathDataServicesSet = new Set();
ASPathDataServicesSet.add(BGP_ROUTER);
ASPathDataServicesSet.add(CODEBGP_MONITOR);
ASPathDataServicesSet.add(RIS_LIVE);

/**
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
export const sortParameters = (a, b) => {
  if (a === parameterNameMap.asn.name) return -1;
  if (b === parameterNameMap.asn.name) return 1;
  if (a === parameterNameMap.asns.name) return -1;
  if (b === parameterNameMap.asns.name) return 1;
  if (a === parameterNameMap.prefixes.name) return -1;
  if (b === parameterNameMap.prefixes.name) return 1;
  if (a === parameterNameMap.origin_asn.name) return -1;
  if (b === parameterNameMap.origin_asn.name) return 1;
  if (a === parameterNameMap.neighbor_asns.name) return -1;
  if (b === parameterNameMap.neighbor_asns.name) return 1;
  if (a === parameterNameMap.bogon_prefixes.name) return -1;
  if (b === parameterNameMap.bogon_prefixes.name) return 1;
  return 0;
};

/**
 * @param {string} parameter
 * @param {string|string[]} value
 * @returns {string|string[]}
 */
export const formatValue = (parameter, value) => {
  switch (parameter) {
    case parameterNameMap.asns.name:
      return value.join(",  ");
    case parameterNameMap.neighbor_asns.name:
      return value.join(",  ");
    case parameterNameMap.prefixes.name:
      switch (typeof value) {
        case "string":
          return value
            .replace(/{/g, "")
            .replace(/}/g, "")
            .split(",")
            .join(",  ");
        default:
          return value.join(",  ");
      }
    case parameterNameMap.base_service.name:
      if (ASPathDataServicesSet.has(value)) {
        return formatDataService(value);
      }
      return value;
    case parameterNameMap.comp_service.name:
      if (ASPathDataServicesSet.has(value)) {
        return formatDataService(value);
      }
      return value;
    case parameterNameMap.bogon_prefixes.name:
      switch (typeof value) {
        case "string":
          return value
            .replace(/{/g, "")
            .replace(/}/g, "")
            .split(",")
            .join(",  ");
        default:
          return value.join(",  ");
      }
    case parameterNameMap.asn_regex.name:
      return regexToASes(value).join(",  ");
    case parameterNameMap.asn_present_regex.name:
      return regexToASes(value).join(",  ");
    case parameterNameMap.rpki_status.name:
      return getValueFromEnum(FORMAT_RPKI_ENUM, value);
    default:
      return value;
  }
};