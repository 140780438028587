import { ReactElement } from "react";
import { Typography } from "@mui/material";

/**
 * @param {object} props
 * @param {string} props.text
 * @returns {ReactElement}
 */
export const InlineBoldText = ({ text }) => {
  return (
    <Typography sx={{ fontWeight: "bold", display: "inline" }}>
      {` ${text} `}
    </Typography>
  );
};
