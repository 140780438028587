export const headerCellStyles = {
  backgroundColor: "blue10.main",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "blue11.main",
  py: "0.455rem",
};

export const bodyCellStyles = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "blue11.main",
  py: "0.33rem",
};

export const alertRuleDisplayName = Object.freeze({
  unexpected_originated_prefixes: "Route Leak",
  unexpected_neighbor_prefix: "Neighbor Leak/Hijack",
  as_origin_violation_exact: "Exact Prefix Hijack",
  as_origin_violation_sub: "Sub-Prefix Hijack",
  sub_prefix: "Possible Sub-prefix Hijack",
  squatting: "Squatting",
  rpki_invalid_detect: "RPKI-Invalid Detection",
  rpki_invalid_announce: "RPKI-Invalid Announcement",
  rpki_invalid_propagate: "RPKI-Invalid Propagation",
  rpki_not_found_propagate: "RPKI-NotFound Propagation",
  unexpected_peering: "New Neighbor",
  bogon_prefix_exact: "Bogon (Exact-)Prefix",
  bogon_prefix_sub: "Bogon (Sub-)Prefix",
  bogon_as: "Bogon AS",
  as_path_diffs: "AS Path Comparison",
  prefix_diffs: "Prefix Comparison",
  custom: "Custom",
  as_path_presence: "Presence in AS Path",
  as_path_regex_violation: "Invalid AS Path Pattern",
  low_prefix_visibility: "Prefix Visibility Loss",
  low_peering_visibility: "Peering Visibility Loss",
  as_path_length_violation: "Long AS Path",
});

export const alertRuleReceiverType = Object.freeze({
  email: "Email",
});

export const alertRuleReceiverTypeInverse = Object.freeze({
  Email: "email",
});

export const alertRuleReceiverEndpointName = Object.freeze({
  Email: "Email Address",
});

export const defaultAlertRuleReceiverEndpointName = "Email Address";

export const alertSeverity = Object.freeze({
  critical: "Critical",
  warning: "Warning",
});

export const alertSeverityInverse = Object.freeze({
  Critical: "critical",
  Warning: "warning",
});

export const knownBogonPrefixes = [
  "0.0.0.0/8",
  "10.0.0.0/8",
  "100.64.0.0/10",
  "127.0.0.0/8",
  "169.254.0.0/16",
  "172.16.0.0/12",
  "192.0.2.0/24",
  "192.88.99.0/24",
  "192.168.0.0/16",
  "198.18.0.0/15",
  "198.51.100.0/24",
  "203.0.113.0/24",
  "224.0.0.0/4",
  "240.0.0.0/4",
  "::/8",
  "0100::/64",
  "2001:2::/48",
  "2001:10::/28",
  "2001:db8::/32",
  "2002::/16",
  "ffe::/16",
  "fc00::/7",
  "fe80::/10",
  "fec0::/10",
  "ff00::/8",
];

export const rows = [
  {
    type: alertRuleDisplayName.as_origin_violation_exact,
    severity: alertSeverity.critical,
    description: "Illegal origin ASes that announce configured prefixes.",
  },
  {
    type: alertRuleDisplayName.as_origin_violation_sub,
    severity: alertSeverity.critical,
    description:
      "Illegal origin ASes that announce subprefixes of configured prefixes.",
  },
  {
    type: alertRuleDisplayName.unexpected_originated_prefixes,
    severity: alertSeverity.critical,
    description:
      "Unexpected prefixes in the list of prefixes that are announced by configured ASes.",
  },
  {
    type: alertRuleDisplayName.unexpected_peering,
    severity: alertSeverity.warning,
    description:
      "New neighbors that appear to peer with configured ASes. Possible AS path manipulation.",
  },
  {
    type: alertRuleDisplayName.unexpected_neighbor_prefix,
    severity: alertSeverity.critical,
    description:
      "New neighbors that not only appear to peer with configured ASes, but also propagate their prefixes.",
  },
  // {
  //   type: alertRuleDisplayName.sub_prefix,
  //   comingSoon: true,
  //   severity: alertSeverity.warning,
  //   description:
  //     "Announcements of more specific prefixes of configured prefixes. It can be either a benign TE event or a malicious attack which includes AS path manipulation.",
  // },
  {
    type: alertRuleDisplayName.squatting,
    severity: alertSeverity.critical,
    description:
      "Illegal origin ASes announcing prefixes that are not currently announced by configured ASes.",
  },
  {
    type: alertRuleDisplayName.as_path_presence,
    severity: alertSeverity.warning,
    description:
      "Presence of ASes in paths towards configured prefixes.",
  },
  {
    type: alertRuleDisplayName.as_path_regex_violation,
    severity: alertSeverity.warning,
    description:
      "Violation of valid pattern by AS paths towards configured prefixes.",
  },
  {
    type: alertRuleDisplayName.as_path_length_violation,
    severity: alertSeverity.warning,
    description:
      "Paths towards configured prefixes exceed a specified length threshold.",
  },
  {
    type: alertRuleDisplayName.low_prefix_visibility,
    severity: alertSeverity.critical,
    description:
      "Visibility of prefix falls below a configured data source count threshold.",
  },
  {
    type: alertRuleDisplayName.low_peering_visibility,
    severity: alertSeverity.critical,
    description:
      "Visibility of peering falls below a configured data source count threshold.",
  },
  {
    type: alertRuleDisplayName.rpki_invalid_detect,
    severity: alertSeverity.critical,
    description:
      "RPKI-Invalid announcements of configured prefixes by other ASes.",
  },
  {
    type: alertRuleDisplayName.rpki_invalid_announce,
    severity: alertSeverity.critical,
    description: "RPKI-Invalid announcements by configured ASes.",
  },
  {
    type: alertRuleDisplayName.rpki_invalid_propagate,
    severity: alertSeverity.warning,
    description: "RPKI-Invalid routes propagated by configured ASes.",
  },
  {
    type: alertRuleDisplayName.rpki_not_found_propagate,
    severity: alertSeverity.warning,
    description: "RPKI-NotFound routes propagated by configured ASes.",
  },
  {
    type: alertRuleDisplayName.bogon_prefix_exact,
    severity: alertSeverity.warning,
    description: "Announcements of bogon prefixes by configured ASes.",
  },
  {
    type: alertRuleDisplayName.bogon_prefix_sub,
    severity: alertSeverity.warning,
    description: "Announcements of bogon subprefixes by configured ASes.",
  },
  {
    type: alertRuleDisplayName.bogon_as,
    severity: alertSeverity.warning,
    description:
      "In-path presence of bogon ASes, in routes towards configured prefixes.",
  },
  {
    type: alertRuleDisplayName.as_path_diffs,
    severity: alertSeverity.warning,
    description:
      "Discrepancies in AS paths towards the same prefix, comparing between different Data Services, up to a terminating (end) AS.",
  },
  {
    type: alertRuleDisplayName.prefix_diffs,
    severity: alertSeverity.warning,
    description:
      "Discrepancies in prefixes announced by configured ASes, comparing between different Data Services.",
  },
  {
    type: alertRuleDisplayName.custom,
    severity: "User-defined",
    description: "User-defined",
  },
];
