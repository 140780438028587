import { ReactElement, MouseEventHandler } from "react";
import { Button, Typography } from "@mui/material";

/**
 * @param {object} props
 * @param {MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {string} props.title
 * @param {object} [props.customStyles]
 * @returns {ReactElement}
 */
export const SecondaryButton = ({ onClick, title, customStyles }) => {
  return (
    <Button
      sx={{
        color: "primary.main",
        textTransform: "none",
        width: "6rem",
        height: "2.5rem",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: "0.063rem",
        borderColor: "blue5.main",
        ...customStyles,
      }}
      onClick={onClick}
    >
      <Typography noWrap fontSize="0.875rem">
        {title}
      </Typography>
    </Button>
  );
};
