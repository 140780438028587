import { ReactElement } from "react";
import { Tooltip, Typography } from "@mui/material";

const onTooltipClick = (e) => {
  e.stopPropagation();
};

/**
 * @param {object} props
 * @param {string} props.string
 * @param {string} [props.width]
 * @param {ReactElement} props.title
 * @returns {ReactElement}
 */
export const StringWithTooltipOriginASNS = ({
  string,
  title,
  width = "20rem",
}) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "white",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            width,
            maxWidth: "20rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
          },
          onClick: onTooltipClick,
        },
        arrow: {
          sx: {
            color: "white",
          },
        },
      }}
      title={title}
      arrow
      placement="top"
    >
      <Typography
        sx={{ width: "fit-content" }}
        fontSize="0.875rem"
        component="span"
      >
        {string}
      </Typography>
    </Tooltip>
  );
};
