import { Error, Loading } from "../../types/dataFetching";

/**
 * @param {object} params
 * @param {object} params.options
 * @param {string} params.sortOrderColumnName
 * @param {Function} params.setSortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @param {Function} params.setSortOrderDirection
 * @returns {void}
 */
export const addServerSideOptions = ({
  options,
  sortOrderColumnName,
  setSortOrderColumnName,
  sortOrderDirection,
  setSortOrderDirection,
}) => {
  const onColumnSortChange = (changedColumn, direction) => {
    if (direction === "desc") {
      direction = "desc_nulls_last";
    }
    setSortOrderColumnName(changedColumn);
    setSortOrderDirection(direction);
  };

  options.sortOrder = {
    name: sortOrderColumnName,
    direction: sortOrderDirection,
  };
  options.onColumnSortChange = onColumnSortChange;
  options.serverSide = true;
};

/**
 * @param {object} params
 * @param {object} params.data
 * @param {Loading} params.fetching
 * @param {Error} params.error
 * @param {Function} params.getCount
 * @returns {number}
 */
export const getCountLabel = ({ data, fetching, error, getCount }) => {
  if (fetching) return -1;
  if (error) {
    console.error(error);
    return 0;
  }
  if (!data) return 0;
  return getCount({ data });
};
