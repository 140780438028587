import { ReactElement, useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { PageDivider } from "./PageDivider";

/**
 * @param {object} props
 * @param {string|ReactElement} props.text
 * @param {(string|ReactElement)?} [props.helpText]
 * @param {boolean} props.isHelpTextOpen
 * @param {Function} props.setIsHelpTextOpen
 * @param {string} props.hashTagUrlValue
 * @param {Function} props.selectedTabCallback
 * @returns {ReactElement}
 */
export const PageTitle = ({
  text,
  helpText,
  isHelpTextOpen,
  setIsHelpTextOpen,
  hashTagUrlValue,
  selectedTabCallback,
}) => {
  useEffect(() => {
    switch (hashTagUrlValue) {
      case "prefixes":
        selectedTabCallback({ selectedTab: "prefixes" });
        break;
      case "autonomous-systems":
        selectedTabCallback({ selectedTab: "autonomous-systems" });
        break;
      case "peerings":
        selectedTabCallback({ selectedTab: "peerings" });
        break;
      case "routes":
        selectedTabCallback({ selectedTab: "routes" });
        break;
      case "rpki-roas":
        selectedTabCallback({ selectedTab: "rpki-roas" });
        break;
      default:
        console.error("Invalid Looking Glass Tab!");
    }
  }, [hashTagUrlValue]);

  const toggleHelpText = () => {
    setIsHelpTextOpen(!isHelpTextOpen);
  };

  const arrowIcon = isHelpTextOpen ? (
    <ExpandLessIcon
      sx={{
        color: (theme) => theme.color.other.selectionIndicator,
        fontSize: "larger",
      }}
    />
  ) : (
    <ExpandMoreIcon
      sx={{
        color: (theme) => theme.color.other.selectionIndicator,
        fontSize: "larger",
      }}
    />
  );

  const helpTextJSX = isHelpTextOpen ? (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.color.bg.helpText,
        mx: (theme) => `-${theme.space.padding.contentX}`,
        height: (theme) => `-${theme.space.height.helpText}`,
        p: "1.5rem",
      }}
    >
      <Typography
        fontSize="0.875rem"
        whiteSpace="pre-wrap"
        data-testid="HelperText"
      >
        {helpText}
      </Typography>
    </Stack>
  ) : null;

  const infoButtonJSX = helpText ? (
    <Button
      sx={{
        color: (theme) => theme.color.other.selectionIndicator,
        textTransform: "none",
        fontWeight: "normal",
        mb: "1rem",
        mt: "-0.13rem",
      }}
      onClick={toggleHelpText}
    >
      Info
      {arrowIcon}
    </Button>
  ) : null;

  const titleSX = { lineHeight: "1.75rem" };
  if (!helpText) {
    titleSX.mb = "1.4rem";
  }

  return (
    <Stack>
      <Stack direction="row">
        <Typography variant="h6" sx={titleSX}>
          {text}
        </Typography>
        {infoButtonJSX}
      </Stack>
      <PageDivider />
      {helpTextJSX}
    </Stack>
  );
};
