import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { useASName } from "../hooks/useASName";
import { useASCountries } from "../hooks/useASCountries";
import { useASAbuseEmails } from "../hooks/useASAbuseEmails";
import {
  getAbuseEmailsJSX,
  getCountriesJSX,
  getNameJSX,
} from "../utils/autonomousSystemNumberTooltip";
import { Tooltip } from "./Tooltip";
import { contentfontSize as fontSize } from "../themes/tooltip";
import { useASPeeringDBID } from "../hooks/useASPeeringDBID";

/**
 * @param {object} props
 * @param {number} props.asn
 * @returns {ReactElement}
 */
export const AutonomousSystemNumberTooltip = ({ asn }) => {
  const { data: name, loading: nameLoading, error: nameError } = useASName(asn);
  const {
    data: countries,
    loading: countriesLoading,
    error: countriesError,
  } = useASCountries(asn);
  const {
    data: abuseEmails,
    loading: abuseEmailsLoading,
    error: abuseEmailsError,
  } = useASAbuseEmails(asn);
  const {
    data: peeringDBASNID,
    loading: peeringDBASNIDLoading,
    error: peeringDBASNIDError,
  } = useASPeeringDBID(asn);

  const ripeStatURL = `https://stat.ripe.net/app/AS${asn}`;
  const irrExplorerURL = `https://irrexplorer.nlnog.net/asn/AS${asn}`;
  const cloudflareRadarURL = `https://radar.cloudflare.com/as${asn}`;

  const nameJSX = getNameJSX(name, nameLoading, nameError);
  const countriesJSX = getCountriesJSX(
    countries,
    countriesLoading,
    countriesError,
    fontSize
  );
  const abuseEmailsJSX = getAbuseEmailsJSX(
    abuseEmails,
    abuseEmailsLoading,
    abuseEmailsError
  );

  const title = `AS ${asn}`;
  const contents = [
    {
      key: "Name",
      keyJSX: (
        <Typography sx={{ width: "50%", color: "gray3.main", fontSize }}>
          Name
        </Typography>
      ),
      value: (
        <Typography sx={{ width: "50%", color: "blue1.main", fontSize }}>
          {nameJSX}
        </Typography>
      ),
    },
    {
      key: "Country",
      keyJSX: (
        <Typography sx={{ width: "50%", color: "gray3.main", fontSize }}>
          Country
        </Typography>
      ),
      value: <Box sx={{ width: "50%", pt: "0.26rem" }}>{countriesJSX}</Box>,
    },
    {
      key: "Abuse Contact",
      keyJSX: (
        <Typography sx={{ width: "50%", color: "gray3.main", fontSize }}>
          Abuse contact
        </Typography>
      ),
      value: (
        <Typography sx={{ width: "50%", color: "blue1.main", fontSize }}>
          {abuseEmailsJSX}
        </Typography>
      ),
    },
  ];
  const moreInfoLinks = [
    { name: "RIPEstat", url: ripeStatURL },
    { name: "IRR Explorer", url: irrExplorerURL },
    {
      name: "Peering DB",
      url: peeringDBASNID
        ? `https://www.peeringdb.com/net/${peeringDBASNID}`
        : null,
      loading: peeringDBASNIDLoading,
      error: peeringDBASNIDError,
    },
    { name: "Cloudflare Radar", url: cloudflareRadarURL },
  ];

  return (
    <Tooltip title={title} contents={contents} moreInfoLinks={moreInfoLinks} />
  );
};
