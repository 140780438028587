import { SvgWrapper } from "../../components/SvgWrapper";

export const rowsPerPageOptions = [10, 25, 50, 100];
export const components = {
  icons: {
    SearchIcon: () => <SvgWrapper filename="SearchIcon" />,
    DownloadIcon: () => <SvgWrapper filename="DownloadCSVIcon" />,
    PrintIcon: () => <SvgWrapper filename="PrintIcon" />,
    ViewColumnIcon: () => <SvgWrapper filename="ViewColumnsIcon" />,
    FilterIcon: () => <SvgWrapper filename="FilterTableIcon" />,
  },
};

export const TABLE_KEY_ENUM = Object.freeze({
  INNER: "inner",
  OUTER: "outer",
  ACTIVEALERTS: "activeAlerts",
  RESOLVEDALERTS: "resolvedAlerts",
  ALERTSSETUP: "alertsSetup",
});
