import { useAsNsThatOriginatePrefixesSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useAsnsThatOriginatePrefixes";
import { asnsThatOriginatePrefixesAtom } from "../other/store";
import { useData } from "./useData";

export const useAsnsThatOriginatePrefixes = () => {
  useData({
    operation: useAsNsThatOriginatePrefixesSubscription,
    formatter,
    atom: asnsThatOriginatePrefixesAtom,
  });
};
