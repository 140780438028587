import { ServerSideTable } from "../components/ServerSideTable";
import {
  getColumns,
  getCustomOptions,
  getCount,
  getRows,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  getRowID,
} from "../utils/components/prefixes";
import {
  prefixesTableCountSubscriptionAtom,
  prefixesTableMinDataSourcesNumberFilterAtom,
  prefixesTableMaxDataSourcesNumberFilterAtom,
  prefixesTableMinDataSourcesPercentageFilterAtom,
  prefixesTableMaxDataSourcesPercentageFilterAtom,
  prefixesTableFilterStateAtom,
  prefixesTableIPVersionFilterAtom,
  prefixesTableIsSubscriptionActiveAtom,
  prefixesTableMaskLengthFilterAtom,
  prefixesTableOriginASFilterAtom,
  prefixesTablePageAtom,
  prefixesTablePrefixFilterAtom,
  prefixesTableRowsPerPageAtom,
  prefixesTableSubscriptionAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
  isPrefixVisibleAtom,
  isOriginASVisibleAtom,
  isIPVersionVisibleAtom,
  isMaskLengthVisibleAtom,
  isDataSourcesNumberVisibleAtom,
  isDataSourcesPercentageVisibleAtom,
  expandedRowIDsAtom,
  prefixesTablePeerIPFilterAtom,
  prefixesTablePeerASFilterAtom,
} from "../store/prefixesTable";
import { useAtom } from "jotai";
import {
  totalNumberOfIPv4DataSourcesAtom,
  totalNumberOfIPv6DataSourcesAtom,
} from "../store/overviewContent";
import { getWhereArgument } from "../utils/components/prefixes";
import { PrefixesDownloadServerSideTableDocument } from "../graphql/generated/operations";
import { filterOnASAnyAtom } from "../other/store";
import { useEffect } from "react";
import { ReactElement } from "react";

/**
 * @param {object} props
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const Prefixes = ({ urlParams }) => {
  const [filterState] = useAtom(prefixesTableFilterStateAtom);
  const [, setPrefixFilter] = useAtom(prefixesTablePrefixFilterAtom);
  const [, setOriginASFilter] = useAtom(prefixesTableOriginASFilterAtom);
  const [, setIpVersionFilter] = useAtom(prefixesTableIPVersionFilterAtom);
  const [, setMaskLengthFilter] = useAtom(prefixesTableMaskLengthFilterAtom);
  const [, setPeerIPFilter] = useAtom(prefixesTablePeerIPFilterAtom);
  const [, setPeerASFilter] = useAtom(prefixesTablePeerASFilterAtom);
  const [, setMinDataSourcesNumberFilter] = useAtom(
    prefixesTableMinDataSourcesNumberFilterAtom
  );
  const [, setMaxDataSourcesNumberFilter] = useAtom(
    prefixesTableMaxDataSourcesNumberFilterAtom
  );
  const [, setMinDataSourcesPercentageFilter] = useAtom(
    prefixesTableMinDataSourcesPercentageFilterAtom
  );
  const [, setMaxDataSourcesPercentageFilter] = useAtom(
    prefixesTableMaxDataSourcesPercentageFilterAtom
  );
  const [{ data: totalNumberOfIPv4DataSources }] = useAtom(
    totalNumberOfIPv4DataSourcesAtom
  );
  const [{ data: totalNumberOfIPv6DataSources }] = useAtom(
    totalNumberOfIPv6DataSourcesAtom
  );
  const [isPrefixVisible, setIsPrefixVisible] = useAtom(isPrefixVisibleAtom);
  const [isOriginASVisible, setIsOriginASVisible] = useAtom(
    isOriginASVisibleAtom
  );
  const [isIPVersionVisible, setIsIPVersionVisible] = useAtom(
    isIPVersionVisibleAtom
  );
  const [isMaskLengthVisible, setIsMaskLengthVisible] = useAtom(
    isMaskLengthVisibleAtom
  );
  const [isDataSourcesNumberVisible, setIsDataSourcesNumberVisible] = useAtom(
    isDataSourcesNumberVisibleAtom
  );
  const [isDataSourcesPercentageVisible, setIsDataSourcesPercentageVisible] =
    useAtom(isDataSourcesPercentageVisibleAtom);

  const [filterOnASAny] = useAtom(filterOnASAnyAtom);

  const getWhereArgumentParams = {
    totalNumberOfIPv4DataSources,
    totalNumberOfIPv6DataSources,
    filterOnASAny,
  };

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "prefixFilter":
            setPrefixFilter(item[1]);
            break;
          case "originASFilter":
            setOriginASFilter(item[1]);
            break;
          case "ipVersionFilter":
            setIpVersionFilter(item[1]);
            break;
          case "maskLengthFilter":
            setMaskLengthFilter(item[1]);
            break;
          case "peerIPFilter":
            setPeerIPFilter(item[1]);
            break;
          case "peerASFilter":
            setPeerASFilter(item[1]);
            break;
          case "minDataSourcesNumberFilter":
            setMinDataSourcesNumberFilter(item[1]);
            break;
          case "maxDataSourcesNumberFilter":
            setMaxDataSourcesNumberFilter(item[1]);
            break;
          case "minDataSourcesPercentageFilter":
            setMinDataSourcesPercentageFilter(item[1]);
            break;
          case "maxDataSourcesPercentageFilter":
            setMaxDataSourcesPercentageFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  return (
    <ServerSideTable
      objectName="Prefixes"
      filterState={filterState}
      filterSetters={{
        setPrefixFilter,
        setOriginASFilter,
        setIpVersionFilter,
        setMaskLengthFilter,
        setPeerIPFilter,
        setPeerASFilter,
        setMinDataSourcesNumberFilter,
        setMaxDataSourcesNumberFilter,
        setMinDataSourcesPercentageFilter,
        setMaxDataSourcesPercentageFilter,
      }}
      visibleSetters={{
        setIsPrefixVisible,
        setIsOriginASVisible,
        setIsIPVersionVisible,
        setIsMaskLengthVisible,
        setIsDataSourcesNumberVisible,
        setIsDataSourcesPercentageVisible,
      }}
      getColumns={getColumns}
      getColumnsParams={{
        setMinDataSourcesNumberFilter,
        setMaxDataSourcesNumberFilter,
        setMinDataSourcesPercentageFilter,
        setMaxDataSourcesPercentageFilter,
        isPrefixVisible,
        isOriginASVisible,
        isIPVersionVisible,
        isMaskLengthVisible,
        isDataSourcesNumberVisible,
        isDataSourcesPercentageVisible,
      }}
      getCustomOptions={getCustomOptions}
      getCount={getCount}
      getRows={getRows}
      getRowsParams={{
        totalNumberOfIPv4DataSources,
        totalNumberOfIPv6DataSources,
      }}
      getWhereArgument={getWhereArgument}
      getWhereArgumentParams={getWhereArgumentParams}
      onApplyFiltersButtonClick={onApplyFiltersButtonClick}
      onViewColumnsChange={onViewColumnsChange}
      pageAtom={prefixesTablePageAtom}
      rowsPerPageAtom={prefixesTableRowsPerPageAtom}
      mainSubscriptionAtom={prefixesTableSubscriptionAtom}
      countSubscriptionAtom={prefixesTableCountSubscriptionAtom}
      isSubscriptionActiveAtom={prefixesTableIsSubscriptionActiveAtom}
      sortOrderColumnNameAtom={sortOrderColumnNameAtom}
      sortOrderDirectionAtom={sortOrderDirectionAtom}
      getRowID={getRowID}
      expandedRowIDsAtom={expandedRowIDsAtom}
      serverSideTableDataQueryDocument={PrefixesDownloadServerSideTableDocument}
    />
  );
};
