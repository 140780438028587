import {
  displayedConfiguredASNsAtom,
  displayedConfiguredDataServicesAtom,
  displayedConfiguredPrefixesAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom, Atom, PrimitiveAtom } from "jotai";

/**
 * @param {object} params
 * @param {PrimitiveAtom<number>} params.pageAtom
 * @param {PrimitiveAtom<number>} params.rowsPerPageAtom
 * @param {Atom<boolean>} params.isSubscriptionActiveAtom
 * @param {PrimitiveAtom<any>} params.mainSubscriptionAtom
 * @param {PrimitiveAtom<any>} params.countSubscriptionAtom
 * @param {Atom<any>} params.filterStateAtom
 * @param {Function} params.getWhereArgument
 * @param {object} [params.getWhereArgumentParams]
 * @param {Function} params.getOrderArgument
 * @param {object} [params.getOrderArgumentParams]
 * @param {Function} params.mainSubscription
 * @param {Function} params.countSubscription
 */
export const useServerSideTableData = ({
  pageAtom,
  rowsPerPageAtom,
  isSubscriptionActiveAtom,
  mainSubscriptionAtom,
  countSubscriptionAtom,
  filterStateAtom,
  getWhereArgument,
  getWhereArgumentParams,
  getOrderArgument,
  getOrderArgumentParams,
  mainSubscription,
  countSubscription,
}) => {
  const [configuredASNsState] = useAtom(displayedConfiguredASNsAtom);
  const { data: configuredASNs } = configuredASNsState;
  const [configuredPrefixesState] = useAtom(displayedConfiguredPrefixesAtom);
  const { data: configuredPrefixes } = configuredPrefixesState;
  const [configuredDataServicesState] = useAtom(
    displayedConfiguredDataServicesAtom
  );
  const { data: configuredDataServices } = configuredDataServicesState;
  const confState = {
    fetching:
      configuredASNsState.fetching ||
      configuredPrefixesState.fetching ||
      configuredDataServicesState.fetching,
    error:
      configuredASNsState.error ??
      configuredPrefixesState.error ??
      configuredDataServicesState.error,
  };
  const [page] = useAtom(pageAtom);
  const [rowsPerPage] = useAtom(rowsPerPageAtom);
  const [isSubscriptionActive] = useAtom(isSubscriptionActiveAtom);
  const [filterState] = useAtom(filterStateAtom);

  const whereArgument = getWhereArgument({
    configuredASNs,
    configuredPrefixes,
    configuredDataServices,
    ...filterState,
    ...getWhereArgumentParams,
  });
  const orderArgument = getOrderArgument({ ...getOrderArgumentParams });

  useData({
    operation: mainSubscription,
    options: {
      variables: {
        where: whereArgument,
        order_by: orderArgument,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      },
      pause: !isSubscriptionActive,
    },
    confState,
    atom: mainSubscriptionAtom,
  });
  useData({
    operation: countSubscription,
    options: {
      variables: {
        where: whereArgument,
      },
      pause: !isSubscriptionActive,
    },
    confState,
    atom: countSubscriptionAtom,
  });
};
