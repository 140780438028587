import { useAtom } from "jotai";
import {
  usePrefixesCountSubscription,
  usePrefixesSubscription,
} from "../graphql/generated/operations";
import { filterOnASAnyAtom } from "../other/store";
import {
  totalNumberOfIPv4DataSourcesAtom,
  totalNumberOfIPv6DataSourcesAtom,
} from "../store/overviewContent";
import {
  prefixesTableCountSubscriptionAtom,
  prefixesTableFilterStateAtom,
  prefixesTableIsSubscriptionActiveAtom,
  prefixesTablePageAtom,
  prefixesTableRowsPerPageAtom,
  prefixesTableSubscriptionAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
} from "../store/prefixesTable";
import {
  getOrderArgument,
  getWhereArgument,
} from "../utils/components/prefixes";
import { useServerSideTableData } from "./useServerSideTableData";

export const usePrefixes = () => {
  const [{ data: totalNumberOfIPv4DataSources }] = useAtom(
    totalNumberOfIPv4DataSourcesAtom
  );
  const [{ data: totalNumberOfIPv6DataSources }] = useAtom(
    totalNumberOfIPv6DataSourcesAtom
  );
  const [filterOnASAny] = useAtom(filterOnASAnyAtom);
  const getWhereArgumentParams = {
    totalNumberOfIPv4DataSources,
    totalNumberOfIPv6DataSources,
    filterOnASAny,
  };
  const [sortOrderColumnName] = useAtom(sortOrderColumnNameAtom);
  const [sortOrderDirection] = useAtom(sortOrderDirectionAtom);
  const getOrderArgumentParams = {
    sortOrderColumnName,
    sortOrderDirection,
  };

  useServerSideTableData({
    pageAtom: prefixesTablePageAtom,
    rowsPerPageAtom: prefixesTableRowsPerPageAtom,
    isSubscriptionActiveAtom: prefixesTableIsSubscriptionActiveAtom,
    mainSubscriptionAtom: prefixesTableSubscriptionAtom,
    countSubscriptionAtom: prefixesTableCountSubscriptionAtom,
    filterStateAtom: prefixesTableFilterStateAtom,
    getWhereArgument,
    getWhereArgumentParams,
    getOrderArgument,
    getOrderArgumentParams,
    mainSubscription: usePrefixesSubscription,
    countSubscription: usePrefixesCountSubscription,
  });
};
