import { atom, PrimitiveAtom } from "jotai";
import {
  INITIAL_COUNT,
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../../constants/hooks/usePagination";

export const getSerializableSetStorage = (createJSONStorage) => ({
  ...createJSONStorage(() => localStorage),
  getItem,
  setItem,
  subscribe: (key, callback) => {
    const storageEventCallback = (e) => {
      if (e.key === key && e.newValue) {
        callback(getItemFromValue(e.newValue));
      }
    };
    window.addEventListener("storage", storageEventCallback);
    return () => {
      window.removeEventListener("storage", storageEventCallback);
    };
  },
});

const getItem = (key) => {
  const item = localStorage.getItem(key);
  if (!item) return new Set();
  return new Set(JSON.parse(item));
};
const setItem = (key, set) => {
  localStorage.setItem(key, JSON.stringify([...set]));
};
const getItemFromValue = (value) => new Set(JSON.parse(value));

/**
 * @param {object} params
 * @param {string} params.atomKey
 * @returns {Object<string, PrimitiveAtom<any>>}
 */
export const getDataSourcesTableStateAtom = ({ atomKey }) => {
  if (!Object.prototype.hasOwnProperty.call(tableStateAtoms, atomKey)) {
    tableStateAtoms[atomKey] = {
      pageAtom: atom(INITIAL_PAGE),
      rowsPerPageAtom: atom(INITIAL_ROWS_PER_PAGE),
      countAtom: atom(INITIAL_COUNT),
      isDataServiceVisibleAtom: atom(true),
      isRouteCollectorVisibleAtom: atom(false),
      isIPVisibleAtom: atom(true),
      isASNVisibleAtom: atom(true),
      isCityVisibleAtom: atom(true),
      isCountryVisibleAtom: atom(true),
      isContinentVisibleAtom: atom(true),
      isLastUpdateVisibleAtom: atom(true),
      isURIVisibleAtom: atom(true),
      isEndpointVisibleAtom: atom(true),
    };
  }
  return tableStateAtoms[atomKey];
};

/**
 * @param {object} params
 * @param {string} params.atomKey
 * @returns {Object<string, PrimitiveAtom<any>>}
 */
export const getQueryAnswerTableStateAtom = ({ atomKey }) => {
  if (!Object.prototype.hasOwnProperty.call(tableStateAtoms, atomKey)) {
    tableStateAtoms[atomKey] = {
      pageAtom: atom(INITIAL_PAGE),
      rowsPerPageAtom: atom(INITIAL_ROWS_PER_PAGE),
      countAtom: atom(INITIAL_COUNT),
      expandedRowIDsAtom: atom(new Set()),
      isConfiguredASVisibleAtom: atom(true),
      isNeighborASVisibleAtom: atom(true),
      isPrefixVisibleAtom: atom(true),
      isASPathVisibleAtom: atom(true),
      isCommunitiesVisibleAtom: atom(false),
    };
  }
  return tableStateAtoms[atomKey];
};

/** @type {Object<string, Object<string, PrimitiveAtom<any>>>} */
const tableStateAtoms = {};
