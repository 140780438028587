import { Box, Typography } from "@mui/material";
import { PATHS } from "../constants/paths";
import { useNumberOfOverviewColumns } from "../hooks/useNumberOfOverviewColumns";
import {
  autonomousSystemsCountAtom,
  ipv4PrefixesCountAtom,
  ipv6PrefixesCountAtom,
  peeringsCountAtom,
  routesCountAtom,
  rpkiRoasCountAtom,
  alertRulesCountAtom,
} from "../store/overviewContent";
import { DataServicesOverviewCard } from "./DataServicesOverviewCard";
import { OverviewCard } from "./OverviewCard";
import { OverviewCardCount } from "./OverviewCardCount";
import { NoFiltersFlag } from "./NoFiltersFlag";
import { BGPUpdateRatesOverviewCard } from "./BGPUpdateRatesOverviewCard";
import { getTenantName } from "../other/utils";

export const OverviewContent = () => {
  const numberOfColumns = useNumberOfOverviewColumns();

  return (
    <>
      <NoFiltersFlag />
      <Box
        sx={{ py: "2rem", px: "1.5rem", justifyContent: "center" }}
        display="grid"
        gridTemplateColumns={`repeat(${numberOfColumns}, 16.5rem)`}
        gap={4}
      >
        <BGPUpdateRatesOverviewCard numberOfColumns={numberOfColumns} />
        <DataServicesOverviewCard />
        <OverviewCard
          title="Autonomous Systems (#)"
          redirectURL={PATHS.dashboard.lookingglass.autonomousSystems}
          numberOfItemsJSX={
            <OverviewCardCount atom={autonomousSystemsCountAtom} />
          }
        />
        <OverviewCard
          title="IPv4 Prefixes  (#)"
          redirectURL={PATHS.dashboard.lookingglass.v4Prefixes}
          numberOfItemsJSX={<OverviewCardCount atom={ipv4PrefixesCountAtom} />}
        />
        <OverviewCard
          title="IPv6 Prefixes  (#)"
          redirectURL={PATHS.dashboard.lookingglass.v6Prefixes}
          numberOfItemsJSX={<OverviewCardCount atom={ipv6PrefixesCountAtom} />}
        />
        <OverviewCard
          title="Peerings  (#)"
          redirectURL={PATHS.dashboard.lookingglass.peerings}
          numberOfItemsJSX={<OverviewCardCount atom={peeringsCountAtom} />}
        />
        <OverviewCard
          title="Routes (#)"
          redirectURL={PATHS.dashboard.lookingglass.routes}
          numberOfItemsJSX={<OverviewCardCount atom={routesCountAtom} />}
        />
        <OverviewCard
          title="RPKI ROAs (#)"
          redirectURL={PATHS.dashboard.lookingglass.rpkiRoas}
          numberOfItemsJSX={<OverviewCardCount atom={rpkiRoasCountAtom} />}
        />
        <OverviewCard
          title="Alert Rules (#)"
          redirectURL={PATHS.dashboard.setup.alerts}
          numberOfItemsJSX={<OverviewCardCount atom={alertRulesCountAtom} />}
        />
        <OverviewCard
          title={"NetOps Queries (#)"}
          redirectURL={PATHS.dashboard.api.queries}
          numberOfItemsJSX={
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: 20,
              }}
            >
              {5}
            </Typography>
          }
        />
        {getTenantName() !== "bogons" ? (
          <OverviewCard
            title={"Integrations (#)"}
            redirectURL={PATHS.dashboard.integrations}
            numberOfItemsJSX={
              <Typography
                sx={{
                  fontWeight: "medium",
                  fontSize: 20,
                }}
              >
                {4}
              </Typography>
            }
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};
