import { useAtom } from "jotai";
import { usePrefixesAnnouncedToNeighborSubscription } from "../graphql/generated/operations";
import { displayedConfiguredASNsAtom } from "../other/store";

export const usePrefixesAnnouncedToNeighbor = (
  neighborASN,
  hasSelectedValue
) => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );
  const [{ data: prefixesData, fetching, error }] =
    usePrefixesAnnouncedToNeighborSubscription({
      variables: { asns, neighborASN },
      pause: !hasSelectedValue,
    });

  if (asnsError) {
    console.error(asnsError);
  }

  if (error) {
    console.error(error);
  }

  return {
    data: prefixesData,
    fetching: asnsFetching || fetching,
    error: asnsError ?? error,
  };
};
