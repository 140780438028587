import Papa from "papaparse";
import { IconButton, Stack, Typography } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { useEffect, useState } from "react";
import openSourceFile from "../constants/opensource/opensource.tsv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  bodyCellStylesParameter,
  headerCellStyles,
} from "../constants/components/argsTable";
import { isHelpTextOpenAtom } from "../store/opensource";
import { useAtom } from "jotai";
import {
  endingTSVFileIndex,
  startingTSVFileIndex,
} from "../constants/components/opensource";

import { SvgWrapper } from "../components/SvgWrapper";
import { useOpenClose } from "../hooks/useOpenClose";
import { LicenseDialog } from "../components/LicenseDialog";

export const OpenSource = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useAtom(isHelpTextOpenAtom);
  const [inputdata, setInputData] = useState([]);
  const [header, setHeaders] = useState();
  const [isInputDataAvailable, setIsInputDataAvailable] = useState(false);
  const [isHeadersAvailable, setIsHeadersAvailable] = useState(false);

  const {
    isOpen: isLicenseDialogOpen,
    open: openLicenseDialog,
    close: closeLicenseDialog,
  } = useOpenClose();

  const [licenseText, setLicenseText] = useState("");

  const triggerLicenseDialog = (text) => {
    setLicenseText(text);
    openLicenseDialog();
  };

  useEffect(() => {
    Papa.parse(openSourceFile, {
      download: true,
      complete: (result) => {
        const inputData = result.data;
        const header = inputData.shift();
        setInputData(inputData);
        setIsInputDataAvailable(true);
        setHeaders(header);
        setIsHeadersAvailable(true);
      },
    });
  }, []);

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.opensource}
        helpText={HELP_TEXT_ENUM.opensource}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      {isHeadersAvailable && isInputDataAvailable && (
        <Table sx={{ mt: "24px", mb: "24px" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyles} align="center">
                {header[0]}
              </TableCell>
              <TableCell sx={headerCellStyles} align="center">
                {header[1]}
              </TableCell>
              <TableCell sx={headerCellStyles} align="center">
                {header[2]}
              </TableCell>
              <TableCell sx={headerCellStyles} align="center">
                {header[3]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inputdata
              ?.filter((row, index) => {
                if (index > startingTSVFileIndex && index < endingTSVFileIndex)
                  return row;
              })
              .map((row) => (
                <TableRow key={row[0]}>
                  <TableCell
                    sx={bodyCellStylesParameter}
                    component="th"
                    scope="row"
                  >
                    {row[0]}
                  </TableCell>
                  <TableCell
                    sx={bodyCellStylesParameter}
                    component="th"
                    scope="row"
                    align="center"
                  >
                    <Stack
                      direction="row"
                      sx={{ display: "inline-flex", ml: "10px" }}
                    >
                      <Typography sx={{ mt: "4px" }}>{row[1]}</Typography>
                      <IconButton
                        sx={{ ml: "6px" }}
                        aria-label="license icon"
                        onClick={() => triggerLicenseDialog(row[0])}
                      >
                        <SvgWrapper
                          filename={"MoreIcon"}
                          sx={{ color: "black", fontSize: 17 }}
                        />
                      </IconButton>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={bodyCellStylesParameter}
                    component="th"
                    scope="row"
                  >
                    {row[2]}
                  </TableCell>
                  <TableCell
                    sx={bodyCellStylesParameter}
                    component="th"
                    scope="row"
                  >
                    {row[3]}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
      <LicenseDialog
        isOpen={isLicenseDialogOpen}
        close={closeLicenseDialog}
        licenseName={licenseText}
      />
    </Stack>
  );
};
