import { useState, ReactElement, MouseEventHandler, useEffect } from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import Papa from "papaparse";
import ErrorIcon from "@mui/icons-material/Error";
import { useInsertManuallyConfiguredAutonomousSystemsMutation } from "../graphql/generated/operations";
import { COLUMNS_ENUM } from "../constants/components/confAutonomousSystemsTable";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {MouseEventHandler} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {object[]} props.tableData
 * @returns {ReactElement}
 */
export const ImportAutonomousSystemsFromCSV = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  tableData,
}) => {
  const [failureReason, setFailureReason] = useState("");
  const [file, setFile] = useState("");
  const [, addConfASNS] =
    useInsertManuallyConfiguredAutonomousSystemsMutation();

  const clearState = () => {
    setFile("");
    setFailureReason("");
  };

  useEffect(() => {
    if (!isOpen) {
      clearState();
    }
  }, [isOpen]);

  const readCSVfile = () => {
    if (file) {
      Papa.parse(file, {
        delimiter: ",",
        header: true,
        complete: (result) => {
          const ASesTableData = tableData.map((item) => {
            return item[COLUMNS_ENUM.AUTONOMOUS_SYSTEM.order].toString();
          });
          const ASesNotInTable = result.data.filter((asn) => {
            if (!ASesTableData.includes(asn["Number"])) {
              return asn;
            }
          });
          if (ASesNotInTable.length > 0) {
            onAddASNs(ASesNotInTable);
          } else {
            setFailureReason("All AS Filters are already configured.");
          }
        },
      });
    }
  };

  const onAddASNs = async (importData) => {
    const ASNS = importData.map((asn) => {
      return { number: asn["Number"] };
    });
    const result = await addConfASNS({ asns: ASNS });
    if (result.data) {
      setSnackbarMessage("AS(es) added successfully");
      openSuccessSnackbar();
      close();
    } else {
      if (result.error.message.includes("Uniqueness violation")) {
        setFailureReason("Some ASNs already exist. Operation aborted.");
      } else {
        setFailureReason(result.error.message);
      }
    }
  };

  const failureMessage = failureReason ? (
    <Stack
      sx={{ color: "#D2393D", width: "17rem", justifyContent: "flex-start" }}
      direction="row"
    >
      <ErrorIcon sx={{ height: "1.05rem" }} />
      <Typography fontSize="0.75rem">{failureReason}</Typography>
    </Stack>
  ) : undefined;

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{
          p: "2rem",
          height: "16rem",
          minwidth: "20rem",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          Import AS Filters CSV File
        </Typography>
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />
        </form>
        {failureMessage}
        <Stack sx={{ justifyContent: "space-evenly" }} direction="row">
          <Button
            sx={{
              color: "primary.main",
              textTransform: "none",
              width: "6rem",
              height: "2.5rem",
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: (theme) => theme.color.bg.selectedDrawerButton,
            }}
            onClick={close}
          >
            <Typography noWrap fontSize="0.875rem">
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              width: "6.7rem",
              height: "2.5rem",
              backgroundColor: "primary.main",
              ":hover": {
                backgroundColor: (theme) => theme.color.button.onHover.action,
              },
              ":disabled": {
                backgroundColor: "gray1.main",
              },
            }}
            onClick={readCSVfile}
          >
            <Typography noWrap fontSize="0.875rem">
              Import
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
