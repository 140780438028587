import {
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
} from "@mui/material";
import { DRAWER_FEEDBACK } from "../constants/drawer";

const Feedback = () => {
  return (
    <Card
      sx={{
        backgroundColor: (theme) => theme.color.bg.drawer,
        borderRadius: 0,
        color: "white",
        borderTopColor: (theme) => theme.color.button.sendUsAnEmail,
        borderTopStyle: "solid",
        borderTopWidth: (theme) => theme.space.width.divider,
      }}
    >
      <CardActions>
        <Link
          href={`mailto:${process.env.REACT_APP_FEEDBACK_TO_EMAIL}?subject=Support Request`}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              height: "2.5rem",
              width: "8.5rem",
              mt: "1rem",
              mr: "1rem",
              ml: "0.5rem",
              mb: "1rem",
              backgroundColor: (theme) => theme.color.button.sendUsAnEmail,
              ":hover": {
                backgroundColor: (theme) =>
                  theme.color.button.onHover.sendUsAnEmail,
              },
            }}
          >
            <Typography noWrap fontSize="0.875rem">
              {DRAWER_FEEDBACK.buttonTitle}
            </Typography>
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export { Feedback };
