import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { AlertsExpandedRowHeader } from "./AlertsExpandedRowHeader";
import { AlertsArgsTable } from "./AlertsArgsTable";

/**
 * @param {object} props
 * @param {string} props.startedDate
 * @param {string} props.endedDate
 * @param {string} props.receiverType
 * @param {string} props.receiverEndpoint
 * @param {string} props.eventData
 * @param {string} props.eventConfResources
 * @param {string} props.eventDescription
 * @param {string} props.selectedTab
 * @returns {ReactElement}
 */
export const AlertsExpandedRow = ({
  startedDate,
  endedDate,
  receiverType,
  receiverEndpoint,
  eventData,
  eventConfResources,
  eventDescription,
  selectedTab,
}) => {
  return (
    <Stack
      sx={{
        borderRadius: "8px 8px 8px 8px",
        borderStyle: "solid",
        borderWidth: "0.063rem",
        borderColor: "#e8eaeb",
      }}
      py="24px"
      px="24px"
      bgcolor="white"
    >
      <AlertsExpandedRowHeader
        startedDate={startedDate}
        endedDate={endedDate}
        receiverType={receiverType}
        receiverEndpoint={receiverEndpoint}
        selectedTab={selectedTab}
      />
      <AlertsArgsTable
        eventData={eventData}
        eventConfResources={eventConfResources}
        eventDescription={eventDescription}
      />
    </Stack>
  );
};
