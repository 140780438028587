import { useConfiguredAutonomousSystemNumbersSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useConfiguredASNs";
import { configuredASNsAtom } from "../other/store";
import { useData } from "./useData";

export const useConfiguredASNs = () => {
  useData({
    operation: useConfiguredAutonomousSystemNumbersSubscription,
    formatter,
    atom: configuredASNsAtom,
  });
};
