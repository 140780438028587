/**
 * @param {string} url
 * @param {string} pathname
 * @returns {boolean}
 */
export const isDrawerButtonSelected = (url, pathname) =>
  url.startsWith(pathname);

/**
 * @param {string} url
 * @param {string} pathname
 * @returns {boolean}
 */
export const isDrawerDropdownSelected = (url, pathname) =>
  pathname.startsWith(url);

/**
 * @param {string} url
 * @param {string} pathname
 * @returns {boolean}
 */
export const isDrawerDropdownChildSelected = (url, pathname) =>
  pathname === url;
