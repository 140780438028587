import useSWR from "swr";
import { getRRCInfoFromJSON } from "../utils/ripestat";

/**
 * @param {string} rrcName
 * @returns {object}
 */
export const useRRCInfo = (rrcName) => {
  const { data, error } = useSWR(
    "https://stat.ripe.net/data/rrc-info/data.json"
  );

  let info;

  if (data) {
    info = getRRCInfoFromJSON(data, rrcName);
  }

  return {
    data: info,
    loading: !error && !data,
    error,
  };
};
