import { useConfiguredDataServicesSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useConfiguredDataServices";
import { configuredDataServicesAtom } from "../other/store";
import { useData } from "./useData";

export const useConfiguredDataServices = () => {
  useData({
    operation: useConfiguredDataServicesSubscription,
    formatter,
    atom: configuredDataServicesAtom,
  });
};
