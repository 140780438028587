import { ChangeEvent, ReactElement } from "react";
import {
  ConfiguredDataServicesSubscription,
  useDataServiceSourcesSubscription,
} from "../../graphql/generated/operations";
import { ConfiguredASNsResult } from "../../types/hooks/useConfiguredASNs";
import { SelectChangeEvent, Switch, TableCell, TableRow } from "@mui/material";
import {
  formatDataService,
  getDropdownFilterTypeColumn,
  unformatDataService,
} from "../../other/utils";
import { Conf_Data_Adapter } from "../../graphql/generated/operations";
import {
  COLUMNS_ENUM,
  TOTAL_NUMBER_OF_DATA_SERVICES,
} from "../../constants/components/confDataServicesTable";
import { TableSelectButton } from "../../components/TableSelectButton";
import { ROLES } from "../../other/constants";
import { DATA_SERVICES } from "../../constants/dataSourcesTable";
import { DataSourcesTable } from "../../components/DataSourcesTable";
import { getSwitchColumnFilterOptions } from "./confAutonomousSystemsTable";
import { getWhereArgument } from "./dataSourcesCardSection";
import { TableHeaderWithToggle } from "../../components/TableHeaderWithToggle";
import {
  OnAllChange,
  OnChange,
} from "../../types/components/checkboxWithLabel";
import { HiddenDataServicesResult } from "../../types/store";
import { SetStateAction } from "jotai";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsDataServiceVisible
 * @param {Function} params.setIsConfigurationVisible
 * @param {Function} params.setIsFilterOnASVisible
 * @param {Function} params.setIsDisplayedVisible
 * @param {Function} params.setIsEnabledVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsDataServiceVisible,
  setIsConfigurationVisible,
  setIsFilterOnASVisible,
  setIsDisplayedVisible,
  setIsEnabledVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SERVICE.name] =
    setIsDataServiceVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.CONFIGURATION.name] =
    setIsConfigurationVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.FILTER_ON_AS.name] =
    setIsFilterOnASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DISPLAYED.name] =
    setIsDisplayedVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.ENABLED.name] = setIsEnabledVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {object} params
 * @param {boolean} params.areAllChecked
 * @param {OnAllChange} params.onSelectAllChange
 * @param {boolean} params.isDataServiceVisible
 * @param {boolean} params.isConfigurationVisible
 * @param {boolean} params.isFilterOnASVisible
 * @param {boolean} params.isDisplayedVisible
 * @param {boolean} params.isEnabledVisible
 * @returns {object[]}
 */
export const getColumns = ({
  areAllChecked,
  onSelectAllChange,
  isDataServiceVisible,
  isConfigurationVisible,
  isFilterOnASVisible,
  isDisplayedVisible,
  isEnabledVisible,
}) => [
  getDropdownFilterTypeColumn(COLUMNS_ENUM.DATA_SERVICE.name, {
    display: isDataServiceVisible,
    customBodyRender: (
      /** @type {Conf_Data_Adapter["data_service"]}  */ value
    ) => formatDataService(value),
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.CONFIGURATION.name, {
    display: isConfigurationVisible,
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.FILTER_ON_AS.name, {
    display: isFilterOnASVisible,
  }),
  // getDropdownFilterTypeColumn("Filter on Data Source", { display: false }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.DISPLAYED.name, {
    display: isDisplayedVisible,
    filterOptions: getSwitchColumnFilterOptions(),
    sort: false,
    customHeadLabelRender: () => (
      <TableHeaderWithToggle
        title="Displayed"
        checked={areAllChecked}
        onClick={onSelectAllChange}
      />
    ),
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.ENABLED.name, {
    display: isEnabledVisible,
    filterOptions: getSwitchColumnFilterOptions(),
  }),
];

/**
 * @param {object} params
 * @param {ConfiguredASNsResult["data"]} params.configuredASNs
 * @returns {object}
 */
export const getCustomOptions = ({ configuredASNs }) => ({
  responsive: "standard",
  tableBodyMaxHeight: "70vh",
  expandableRows: true,
  sortOrder: {
    name: COLUMNS_ENUM.DATA_SERVICE.name,
    direction: "asc",
  },
  filter: false,
  customFooter: () => <></>,
  renderExpandableRow: (rowData) => {
    const colSpan = rowData.length + 1;
    const formattedDataService = rowData[COLUMNS_ENUM.DATA_SERVICE.order];
    const dataService = unformatDataService(formattedDataService);
    const subscriptionVariables = {
      where: getWhereArgument({
        dataService,
        configuredASNs,
      }),
    };
    const title = `${formattedDataService} Data Sources`;
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <DataSourcesTable
            sourcesSubscription={useDataServiceSourcesSubscription}
            options={{
              variables: subscriptionVariables,
            }}
            title={title}
            parentComponent={{
              name: DATA_SERVICES,
              dataServiceName: dataService,
            }}
            atomKey={`ConfDataServices-${DATA_SERVICES.toString()}-${dataService}`}
            exportDataFileName={title}
          />
        </TableCell>
      </TableRow>
    );
  },
});

/**
 *
 * @param {object} params
 * @param {ChangeEvent<HTMLInputElement>} params.event
 * @param {string?} [params.dataServiceName]
 * @param {Function} params.enableConfDataService
 */
const onDataServiceSwitchClick = async ({
  event,
  dataServiceName,
  enableConfDataService,
}) => {
  if (!dataServiceName) {
    console.error(
      "dataServiceName is falsy. Aborting Data Service activation update."
    );
    return;
  }
  const enabled = event.target.checked;
  const { error } = await enableConfDataService({
    enabled,
    dataServiceName,
  });
  if (error) {
    console.error(`Data Service activation update failed: ${error}`);
  }
};

/**
 *
 * @param {object} params
 * @param {SelectChangeEvent<string>} params.event
 * @param {string?} [params.dataServiceName]
 * @param {Function} params.updateConfDataServiceFilterOnAS
 */
const onFilterOnASDropdownChange = async ({
  event,
  dataServiceName,
  updateConfDataServiceFilterOnAS,
}) => {
  if (!dataServiceName) {
    console.error("dataServiceName is falsy. Aborting filter on AS update.");
    return;
  }
  const filterOnAS = event.target.value;
  const { error } = await updateConfDataServiceFilterOnAS({
    filterOnAS,
    dataServiceName,
  });
  if (error) {
    console.error(`Data Service filter on AS update failed: ${error}`);
  }
};

// /**
//  *
//  * @param {object} params
//  * @param {SelectChangeEvent<string>} params.event
//  * @param {string?} [params.dataServiceName]
//  * @param {Function} params.updateConfDataServiceFilterOnPrefix
//  */
// const onFilterOnPrefixDropdownChange = async ({
//   event,
//   dataServiceName,
//   updateConfDataServiceFilterOnPrefix,
// }) => {
//   if (!dataServiceName) {
//     console.error(
//       "dataServiceName is falsy. Aborting filter on Prefix update."
//     );
//     return;
//   }
//   const filterOnPrefix = event.target.value;
//   const { error } = await updateConfDataServiceFilterOnPrefix({
//     filterOnPrefix,
//     dataServiceName,
//   });
//   if (error) {
//     console.error(`Data Service filter on Prefix update failed: ${error}`);
//   }
// };

/**
 * @param {object} params
 * @param {ConfiguredDataServicesSubscription["configuredDataServices"] | undefined} params.confData
 * @param {string} params.role
 * @param {Function} params.updateConfDataServiceFilterOnAS
 * @param {Function} params.enableConfDataService
 * @param {HiddenDataServicesResult} params.hiddenDataServices
 * @param {function(SetStateAction<HiddenDataServicesResult>): void} params.setHiddenDataServices
 * @param {OnChange} params.onSelectChange
 * @returns {(string | null | undefined | ReactElement)[][]}
 */
export const getDataServices = ({
  confData,
  role,
  updateConfDataServiceFilterOnAS,
  enableConfDataService,
  hiddenDataServices,
  setHiddenDataServices,
  onSelectChange,
}) => {
  /** @type {(string | null | undefined | ReactElement)[][]} */
  const dataServices = [];

  confData?.map(
    ({ data_service, configuration, filter_on_autonomous_system, enabled }) =>
      dataServices.push([
        data_service,
        configuration
          ? `${Object.entries(configuration).toString().replaceAll(",", " ")}`
          : null,
        // <TableSelectButton
        //   key={data_service}
        //   selectKey={data_service ?? Math.random()}
        //   value={filter_on_prefix ?? ""}
        //   items={["any"]}
        //   disabled={role !== ROLES.editor}
        //   onChange={(event) =>
        //     onFilterOnPrefixDropdownChange({
        //       event,
        //       dataServiceName: data_service,
        //       updateConfDataServiceFilterOnPrefix,
        //     })
        //   }
        // />,
        <TableSelectButton
          key={data_service}
          selectKey={data_service ?? Math.random()}
          value={filter_on_autonomous_system ?? ""}
          items={["origin", "any"]}
          disabled={role !== ROLES.editor}
          onChange={(event) =>
            onFilterOnASDropdownChange({
              event,
              dataServiceName: data_service,
              updateConfDataServiceFilterOnAS,
            })
          }
        />,
        // <TableSelectButton
        //   key={data_service}
        //   selectKey={data_service}
        //   value={filter_on_data_source}
        //   items={["none", "any"]}
        //   disabled={role !== ROLES.editor}
        // />,
        <Switch
          sx={{ ml: "3.3rem" }}
          key={data_service}
          checked={!hiddenDataServices.has(data_service)}
          onChange={(event) =>
            onDataServiceDisplayedSwitchClick({
              event,
              dataService: data_service,
              hiddenDataServices,
              setHiddenDataServices,
              onSelectChange,
            })
          }
        />,
        <Switch
          key={data_service}
          checked={enabled ?? undefined}
          disabled={role !== ROLES.editor}
          onChange={(event) =>
            onDataServiceSwitchClick({
              event,
              dataServiceName: data_service,
              enableConfDataService,
            })
          }
        />,
      ])
  );

  return dataServices;
};

/**
 *
 * @param {object} params
 * @param {ChangeEvent<HTMLInputElement>} params.event
 * @param {string} params.dataService
 * @param {HiddenDataServicesResult} params.hiddenDataServices
 * @param {function(SetStateAction<HiddenDataServicesResult>): void} params.setHiddenDataServices
 * @param {OnChange} params.onSelectChange
 */
const onDataServiceDisplayedSwitchClick = ({
  event,
  dataService,
  hiddenDataServices,
  setHiddenDataServices,
  onSelectChange,
}) => {
  const enabled = event.target.checked;
  onSelectChange(enabled, dataService);
  const newHiddenDataServices = new Set(hiddenDataServices);
  if (!enabled) {
    newHiddenDataServices.add(dataService);
  } else {
    newHiddenDataServices.delete(dataService);
  }
  setHiddenDataServices(newHiddenDataServices);
};

/**
 * @param {string[] | undefined} configuredDataServices
 * @returns {boolean}
 */
export const allDataServicesDisplayed = (configuredDataServices) =>
  configuredDataServices?.length === TOTAL_NUMBER_OF_DATA_SERVICES;

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.DATA_SERVICE.order];
