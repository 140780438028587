import { idJSON } from "../types/peeringDB";
import { printServiceWarning } from "./thirdPartyAPIs";

/**
 * @param {string} field
 * @param {object?} data
 * @returns {void}
 */
const printPeeringDBWarning = (field, data) => {
  printServiceWarning(field, data, "Peering DB");
};

/**
 * @param {idJSON?} idJSON
 * @returns {string?}
 */
const validateIDJSON = (idJSON) => {
  if (!idJSON) {
    printPeeringDBWarning("idJSON", idJSON);
    return null;
  }
  if (!idJSON.data) {
    printPeeringDBWarning("idJSON.data", idJSON);
    return null;
  }
  if (!idJSON.data[0]) {
    printPeeringDBWarning("idJSON.data[0]", idJSON);
    return null;
  }
  const id = idJSON.data[0].id;
  if (!id) {
    printPeeringDBWarning("idJSON.data[0].id", idJSON);
    return null;
  }
  return id;
};

/**
 * @param {idJSON?} idJSON
 * @returns {string?}
 */
export const getIDFromJSON = (idJSON) => {
  const id = validateIDJSON(idJSON);
  return id;
};
