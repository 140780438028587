import { useAtom } from "jotai";
import {
  useRoutesCountSubscription,
  useRoutesSubscription,
} from "../graphql/generated/operations";
import { filterOnASAnyAtom } from "../other/store";
import {
  routesTableCountSubscriptionAtom,
  routesTableFilterStateAtom,
  routesTableIsSubscriptionActiveAtom,
  routesTablePageAtom,
  routesTableRowsPerPageAtom,
  routesTableSubscriptionAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
} from "../store/routesTable";
import { getOrderArgument, getWhereArgument } from "../utils/components/routes";
import { useServerSideTableData } from "./useServerSideTableData";

export const useRoutes = () => {
  const [sortOrderColumnName] = useAtom(sortOrderColumnNameAtom);
  const [sortOrderDirection] = useAtom(sortOrderDirectionAtom);
  const getOrderArgumentParams = {
    sortOrderColumnName,
    sortOrderDirection,
  };
  const [filterOnASAny] = useAtom(filterOnASAnyAtom);
  const getWhereArgumentParams = {
    filterOnASAny,
  };

  useServerSideTableData({
    pageAtom: routesTablePageAtom,
    rowsPerPageAtom: routesTableRowsPerPageAtom,
    isSubscriptionActiveAtom: routesTableIsSubscriptionActiveAtom,
    mainSubscriptionAtom: routesTableSubscriptionAtom,
    countSubscriptionAtom: routesTableCountSubscriptionAtom,
    filterStateAtom: routesTableFilterStateAtom,
    getWhereArgument,
    getWhereArgumentParams,
    getOrderArgument,
    getOrderArgumentParams,
    mainSubscription: useRoutesSubscription,
    countSubscription: useRoutesCountSubscription,
  });
};
