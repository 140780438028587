import { atom } from "jotai";
import { COLUMNS_ENUM } from "../constants/components/prefixes";
import {
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";
import { getInitialDataResult } from "../constants/store";
import { isConfigurationReadyAtom } from "../other/store";

export const prefixesTablePageAtom = atom(INITIAL_PAGE);
export const prefixesTableRowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const expandedRowIDsAtom = atom(new Set());
export const prefixesTableIsSubscriptionActiveAtom = atom((get) =>
  get(isConfigurationReadyAtom)
);
export const prefixesTablePrefixFilterAtom = atom("");
export const prefixesTableOriginASFilterAtom = atom("");
export const prefixesTableIPVersionFilterAtom = atom("");
export const prefixesTableMaskLengthFilterAtom = atom("");
export const prefixesTableMinDataSourcesNumberFilterAtom = atom("");
export const prefixesTableMaxDataSourcesNumberFilterAtom = atom("");
export const prefixesTableMinDataSourcesPercentageFilterAtom = atom("");
export const prefixesTableMaxDataSourcesPercentageFilterAtom = atom("");
export const prefixesTablePeerIPFilterAtom = atom("");
export const prefixesTablePeerASFilterAtom = atom("");
export const prefixesTableFilterStateAtom = atom((get) => ({
  prefixFilter: get(prefixesTablePrefixFilterAtom),
  originASFilter: get(prefixesTableOriginASFilterAtom),
  ipVersionFilter: get(prefixesTableIPVersionFilterAtom),
  maskLengthFilter: get(prefixesTableMaskLengthFilterAtom),
  minDataSourcesNumberFilter: get(prefixesTableMinDataSourcesNumberFilterAtom),
  maxDataSourcesNumberFilter: get(prefixesTableMaxDataSourcesNumberFilterAtom),
  minDataSourcesPercentageFilter: get(
    prefixesTableMinDataSourcesPercentageFilterAtom
  ),
  maxDataSourcesPercentageFilter: get(
    prefixesTableMaxDataSourcesPercentageFilterAtom
  ),
  peerIPFilter: get(prefixesTablePeerIPFilterAtom),
  peerASFilter: get(prefixesTablePeerASFilterAtom),
}));
export const isPrefixVisibleAtom = atom(true);
export const isOriginASVisibleAtom = atom(true);
export const isIPVersionVisibleAtom = atom(false);
export const isMaskLengthVisibleAtom = atom(false);
export const isDataSourcesNumberVisibleAtom = atom(true);
export const isDataSourcesPercentageVisibleAtom = atom(true);
export const prefixesTableSubscriptionAtom = atom(getInitialDataResult([""]));
export const prefixesTableCountSubscriptionAtom = atom(
  getInitialDataResult([""])
);
export const sortOrderColumnNameAtom = atom(COLUMNS_ENUM.PREFIX.name);
export const sortOrderDirectionAtom = atom("asc");
