import { SvgWrapper } from "./SvgWrapper";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { compensatePositionForSelectionIndicator } from "../other/utils";

const DrawerButtonIconAndText = ({ title, isSelected }) => {
  const iconFileName = title.replace(" ", "") + "Icon";
  const stackSX = {};

  if (isSelected) {
    compensatePositionForSelectionIndicator(stackSX);
  }

  return (
    <Stack direction="row" sx={stackSX}>
      <SvgWrapper
        filename={iconFileName}
        sx={{ color: "white", fontSize: 25 }}
      />
      <Typography pt="0.2rem" pl="1rem" fontSize="0.875rem">
        {title}
      </Typography>
    </Stack>
  );
};

DrawerButtonIconAndText.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export { DrawerButtonIconAndText };
