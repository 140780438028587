import {
  record,
  nameJSON,
  countriesJSON,
  abuseEmailsJSON,
  rrc,
  rrcJSON,
  rrcInfo,
} from "../types/ripestat";
import { printServiceWarning } from "./thirdPartyAPIs";

/**
 * @param {string} field
 * @param {object?} data
 * @returns {void}
 */
const printRipestatWarning = (field, data) => {
  printServiceWarning(field, data, "RIPEstat");
};

/**
 * @param {record[][]?} recordArrays
 * @returns {record?}
 */
const getNameRecord = (recordArrays) => {
  if (!recordArrays) return null;

  // Search for org name first
  for (const recordArray of recordArrays) {
    const orgNameRecord = recordArray.find((record) => {
      if (!record.key) return false;
      return (
        record.key.toLowerCase().includes("org-name") ||
        record.key.toLowerCase().includes("org_name") ||
        record.key.toLowerCase().includes("orgname")
      );
    });
    if (orgNameRecord) return orgNameRecord;
  }

  // Search for AS name if org name was not found
  for (const recordArray of recordArrays) {
    const asNameRecord = recordArray.find((record) => {
      if (!record.key) return false;
      return (
        record.key.toLowerCase().includes("as-name") ||
        record.key.toLowerCase().includes("as_name") ||
        record.key.toLowerCase().includes("asname")
      );
    });
    if (asNameRecord) return asNameRecord;
  }

  return null;
};

/**
 * @param {nameJSON?} nameJSON
 * @returns {string?}
 */
const validateNameJSON = (nameJSON) => {
  if (!nameJSON) {
    printRipestatWarning("nameJSON", nameJSON);
    return null;
  }
  if (!nameJSON.data) {
    printRipestatWarning("nameJSON.data", nameJSON);
    return null;
  }
  if (!nameJSON.data.records) {
    printRipestatWarning("nameJSON.data.records", nameJSON);
    return null;
  }
  const nameRecord = getNameRecord(nameJSON.data.records);
  if (!nameRecord) {
    printRipestatWarning("Name record", nameJSON);
    return null;
  }
  if (!nameRecord.value) {
    printRipestatWarning("Value field in name record", nameJSON);
    return null;
  }

  return nameRecord.value;
};

/**
 * @param {countriesJSON?} countriesJSON
 * @param {string?} asn
 * @returns {(string|null)[] | null}
 */
const validateCountriesJSON = (countriesJSON, asn) => {
  if (!countriesJSON) {
    printRipestatWarning("countryJSON", countriesJSON);
    return null;
  }
  if (!countriesJSON.data) {
    printRipestatWarning("countryJSON.data", countriesJSON);
    return null;
  }
  if (
    !countriesJSON.data.located_resources ||
    !countriesJSON.data.located_resources.length
  ) {
    printRipestatWarning("countryJSON.data.located_resources", countriesJSON);
    return null;
  }
  const resourcesWithASN = countriesJSON.data.located_resources.filter(
    (located_resource) => located_resource.resource === `${asn}`
  );
  if (!resourcesWithASN || !resourcesWithASN.length) {
    printRipestatWarning(`Resources with asn ${asn}`, countriesJSON);
    return null;
  }
  const countryCodes = resourcesWithASN.map(
    (located_resource) => located_resource.location
  );
  if (!countryCodes || !countryCodes.length) {
    printRipestatWarning(
      `Location field in resources with asn ${asn}`,
      countriesJSON
    );
    return null;
  }

  return countryCodes;
};

/**
 * @param {abuseEmailsJSON?} abuseEmailsJSON
 * @returns {string[]?}
 */
const validateAbuseEmailsJSON = (abuseEmailsJSON) => {
  if (!abuseEmailsJSON) {
    printRipestatWarning("abuseEmailJSON", abuseEmailsJSON);
    return null;
  }
  if (!abuseEmailsJSON.data) {
    printRipestatWarning("abuseEmailJSON.data", abuseEmailsJSON);
    return null;
  }
  if (
    !abuseEmailsJSON.data.abuse_contacts ||
    !abuseEmailsJSON.data.abuse_contacts.length
  ) {
    printRipestatWarning("abuseEmailJSON.data.abuse_contacts", abuseEmailsJSON);
    return null;
  }

  return abuseEmailsJSON.data.abuse_contacts;
};

/**
 * @param {rrc[]} rrcs
 * @param {string} rrcName
 * @returns {rrc?}
 */
const getRRCRecord = (rrcs, rrcName) => {
  const rrcRecord = rrcs.find((rrc) => rrc.name === rrcName);
  if (rrcRecord) return rrcRecord;
  return null;
};

/**
 * @param {string} geolocation
 * @returns {rrcInfo}
 */
const parseGeolocation = (geolocation) => {
  const rrcInfoArray = geolocation.split(", ");
  const country = rrcInfoArray?.[rrcInfoArray.length - 1];
  rrcInfoArray?.pop();
  const city = rrcInfoArray?.join(", ");
  return { city, country };
};

/**
 * @param {rrcJSON?} rrcJSON
 * @param {string} rrcName
 * @returns {rrcInfo?}
 */
const validateRRCJSON = (rrcJSON, rrcName) => {
  if (!rrcJSON) {
    printRipestatWarning("rrcJSON", rrcJSON);
    return null;
  }
  if (!rrcJSON.data) {
    printRipestatWarning("rrcJSON.data", rrcJSON);
    return null;
  }
  if (!rrcJSON.data.rrcs) {
    printRipestatWarning("rrcJSON.data.rrcs", rrcJSON);
    return null;
  }
  const rrcRecord = getRRCRecord(rrcJSON.data.rrcs, rrcName);
  if (!rrcRecord) {
    printRipestatWarning("RRC record", rrcJSON);
    return null;
  }
  if (!rrcRecord.geographical_location) {
    printRipestatWarning("geographical_location field in RRC record", rrcJSON);
    return null;
  }
  const { city, country } = parseGeolocation(rrcRecord.geographical_location);

  return { city, country };
};

/**
 * @param {nameJSON?} nameJSON
 * @returns {string?}
 */
export const getNameFromJSON = (nameJSON) => {
  const name = validateNameJSON(nameJSON);
  if (name === null) return null;
  return name.replaceAll("_", " ");
};

/**
 * @param {countriesJSON?} countriesJSON
 * @param {string?} asn
 * @returns {(string|null)[]?}
 */
export const getCountriesFromJSON = (countriesJSON, asn) => {
  const countries = validateCountriesJSON(countriesJSON, asn);
  return countries;
};

/**
 * @param {abuseEmailsJSON?} abuseEmailsJSON
 * @returns {string?}
 */
export const getAbuseEmailsFromJSON = (abuseEmailsJSON) => {
  const abuseEmails = validateAbuseEmailsJSON(abuseEmailsJSON);
  if (abuseEmails === null) return null;
  return abuseEmails.join(", ");
};

/**
 * @param {rrcJSON?} rrcJSON
 * @param {string} rrcName
 * @returns {rrcInfo?}
 */
export const getRRCInfoFromJSON = (rrcJSON, rrcName) => {
  const info = validateRRCJSON(rrcJSON, rrcName);
  return info;
};
