import { ReactElement, MouseEventHandler } from "react";
import { Stack } from "@mui/material";
import { MainButton } from "./MainButton";
import { SecondaryButton } from "./SecondaryButton";

/**
 * @param {object} props
 * @param {MouseEventHandler<HTMLButtonElement>} props.onSecondaryButtonClick
 * @param {string} [props.secondaryButtonTitle]
 * @param {MouseEventHandler<HTMLButtonElement>} props.onMainButtonClick
 * @param {string} props.mainButtonTitle
 * @param {boolean} [props.mainButtonEnabled]
 * @param {object} [props.buttonStripCustomStyles]
 * @param {object} [props.cancelButtonCustomStyles]
 * @param {object} [props.mainButtonCustomStyles]
 * @returns {ReactElement}
 */
export const ButtonStrip = ({
  onSecondaryButtonClick,
  secondaryButtonTitle,
  onMainButtonClick,
  mainButtonTitle,
  mainButtonEnabled = true,
  buttonStripCustomStyles,
  cancelButtonCustomStyles,
  mainButtonCustomStyles,
}) => {
  return (
    <Stack
      sx={{ justifyContent: "space-evenly", ...buttonStripCustomStyles }}
      direction="row"
    >
      <SecondaryButton
        onClick={onSecondaryButtonClick}
        customStyles={cancelButtonCustomStyles}
        title={secondaryButtonTitle ?? "Cancel"}
      />
      <MainButton
        onClick={onMainButtonClick}
        title={mainButtonTitle}
        enabled={mainButtonEnabled}
        customStyles={mainButtonCustomStyles}
      />
    </Stack>
  );
};
