import { ReactElement } from "react";
import { Loading, Error } from "../types/dataFetching";
import {
  Autocomplete,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";

/**
 * @param {object} props
 * @param {string[]} props.options
 * @param {string} props.selectedItem
 * @param {Function} props.setSelectedItem
 * @param {Loading} props.loading
 * @param {Error} props.error
 * @returns {ReactElement}
 */
export const QueryAnswerInputAutocomplete = ({
  options,
  selectedItem,
  setSelectedItem,
  loading,
  error,
}) => {
  if (loading)
    return (
      <LinearProgress
        sx={{ width: "5rem", ml: "1rem", mt: "0.7rem", mb: "0.85rem" }}
      />
    );
  if (error)
    return (
      <Typography sx={{ ml: "0.7rem", mb: "0.3rem" }}>
        (Error loading data)
      </Typography>
    );
  return (
    <Autocomplete
      sx={{ pl: "1rem", mt: "-0.2rem" }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width: "12rem" }}
          inputProps={{
            ...params.inputProps,
            maxLength: 100,
            style: { textAlign: "center", paddingLeft: "1rem" },
          }}
          variant="standard"
        />
      )}
      value={selectedItem}
      onChange={(_event, newValue) => {
        setSelectedItem(newValue);
      }}
      disableClearable
    />
  );
};
