import { TableCell, TableRow } from "@mui/material";
import { DataSourcesTable } from "../../components/DataSourcesTable";
import { COLUMNS_ENUM } from "../../constants/components/peeringsTable";
import { usePeeringSourcesSubscription } from "../../graphql/generated/operations";
import {
  getRangeFilterColumn,
  getTextFieldFilterTypeColumn,
} from "../../other/utils";
import { getRangeFilterList } from "../filtering";
import { formatASN, formatDataSourcesPercentage } from "../formatting";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {object} params
 * @param {string[][] | undefined} params.rows
 * @param {string} params.configuredASFilter
 * @param {string} params.neighborASFilter
 * @param {string} params.minDataSourcesNumberFilter
 * @param {string} params.maxDataSourcesNumberFilter
 * @param {string} params.minDataSourcesPercentageFilter
 * @param {string} params.maxDataSourcesPercentageFilter
 * @param {Function} params.setMinDataSourcesNumberFilter
 * @param {Function} params.setMaxDataSourcesNumberFilter
 * @param {Function} params.setMinDataSourcesPercentageFilter
 * @param {Function} params.setMaxDataSourcesPercentageFilter
 * @param {boolean} params.isConfiguredASVisible
 * @param {boolean} params.isNeighborASVisible
 * @param {boolean} params.isDataSourcesNumberVisible
 * @param {boolean} params.isDataSourcesPercentageVisible
 * @returns {object[] | undefined}
 */
export const getColumns = ({
  rows: peerings,
  configuredASFilter,
  neighborASFilter,
  minDataSourcesNumberFilter,
  maxDataSourcesNumberFilter,
  minDataSourcesPercentageFilter,
  maxDataSourcesPercentageFilter,
  setMinDataSourcesNumberFilter,
  setMaxDataSourcesNumberFilter,
  setMinDataSourcesPercentageFilter,
  setMaxDataSourcesPercentageFilter,
  isConfiguredASVisible,
  isNeighborASVisible,
  isDataSourcesNumberVisible,
  isDataSourcesPercentageVisible,
}) => {
  if (!peerings) return;
  return [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.MY_ASN.name, {
      filterList: configuredASFilter ? [configuredASFilter] : [],
      display: isConfiguredASVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASN(peerings, dataIndex, COLUMNS_ENUM.MY_ASN.order),
    }),
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.NEIGHBOR_ASN.name, {
      filterList: neighborASFilter ? [neighborASFilter] : [],
      display: isNeighborASVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASN(peerings, dataIndex, COLUMNS_ENUM.NEIGHBOR_ASN.order),
    }),
    getRangeFilterColumn(
      COLUMNS_ENUM.DATA_SOURCES_NUMBER.name,
      {
        filterList: getRangeFilterList(
          minDataSourcesNumberFilter,
          maxDataSourcesNumberFilter
        ),
        display: isDataSourcesNumberVisible,
      },
      setMinDataSourcesNumberFilter,
      setMaxDataSourcesNumberFilter
    ),
    getRangeFilterColumn(
      COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.name,
      {
        filterList: getRangeFilterList(
          minDataSourcesPercentageFilter,
          maxDataSourcesPercentageFilter
        ),
        display: isDataSourcesPercentageVisible,
        customBodyRenderLite: (dataIndex) =>
          formatDataSourcesPercentage(
            peerings,
            dataIndex,
            COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order
          ),
        sort: false,
      },
      setMinDataSourcesPercentageFilter,
      setMaxDataSourcesPercentageFilter
    ),
  ];
};

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setConfiguredASFilter
 * @param {Function} params.setNeighborASFilter
 * @param {Function} params.setMinDataSourcesNumberFilter
 * @param {Function} params.setMaxDataSourcesNumberFilter
 * @param {Function} params.setMinDataSourcesPercentageFilter
 * @param {Function} params.setMaxDataSourcesPercentageFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setConfiguredASFilter,
  setNeighborASFilter,
  setMinDataSourcesNumberFilter,
  setMaxDataSourcesNumberFilter,
  setMinDataSourcesPercentageFilter,
  setMaxDataSourcesPercentageFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setConfiguredASFilter(filterList[COLUMNS_ENUM.MY_ASN.order][0]);
  setNeighborASFilter(filterList[COLUMNS_ENUM.NEIGHBOR_ASN.order][0]);
  setMinDataSourcesNumberFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_NUMBER.order][0]
  );
  setMaxDataSourcesNumberFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_NUMBER.order][1]
  );
  setMinDataSourcesPercentageFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order][0]
  );
  setMaxDataSourcesPercentageFilter(
    filterList[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.order][1]
  );
  resetPage();
};

/**
 * @param {object} params
 * @param {string[][] | undefined} params.peerings
 * @returns {object | undefined}
 */
export const getCustomOptions = ({ peerings }) => {
  if (!peerings) return;
  return {
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const asn1 = peerings[rowMeta.dataIndex][COLUMNS_ENUM.MY_ASN.order];
      const asn2 = peerings[rowMeta.dataIndex][COLUMNS_ENUM.NEIGHBOR_ASN.order];
      const title = `Data Sources of Peering ${asn1} - ${asn2}`;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <DataSourcesTable
              sourcesSubscription={usePeeringSourcesSubscription}
              options={{
                variables: { asn1, asn2 },
              }}
              title={title}
              atomKey={`Peerings-${asn1}-${asn2}`}
            />
          </TableCell>
        </TableRow>
      );
    },
  };
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsConfiguredASVisible
 * @param {Function} params.setIsNeighborASVisible
 * @param {Function} params.setIsDataSourcesNumberVisible
 * @param {Function} params.setIsDataSourcesPercentageVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsConfiguredASVisible,
  setIsNeighborASVisible,
  setIsDataSourcesNumberVisible,
  setIsDataSourcesPercentageVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.MY_ASN.name] =
    setIsConfiguredASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.NEIGHBOR_ASN.name] =
    setIsNeighborASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SOURCES_NUMBER.name] =
    setIsDataSourcesNumberVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SOURCES_PERCENTAGE.name] =
    setIsDataSourcesPercentageVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => {
  const asn1 = row[COLUMNS_ENUM.MY_ASN.order];
  const asn2 = row[COLUMNS_ENUM.NEIGHBOR_ASN.order];
  return `${asn1}-${asn2}`;
};
