import { Button, Dialog, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { ReactElement, useState } from "react";
import { Close } from "../types/components/dialog";
import auth0_react_1_12_1 from "../constants/opensource/auth0-react-1.12.1";
import react_dom_18_2_0 from "../constants/opensource/react-dom-18.2.0";
import emotion_cache_11_10_5 from "../constants/opensource/emotion-cache-11.10.5";
import fontsource_roboto_4_5_8 from "../constants/opensource/fontsource-roboto-4.5.8";
import graphiql_toolkit_0_6_1 from "../constants/opensource/graphiql-toolkit-0.6.1";
import mui_icons_material_5_11_0 from "../constants/opensource/mui-icons-material-5.11.0";
import urql_exchange_auth_1_0_0 from "../constants/opensource/urql-exchange-auth-1.0.0";
import country_list_2_3_0 from "../constants/opensource/country-list-2.3.0";
import graphiql_1_11_5 from "../constants/opensource/graphiql-1.11.5";
import graphiql_explorer_0_9_0 from "../constants/opensource/graphiql-explorer-0.9.0";
import graphql_16_6_0 from "../constants/opensource/graphql-16.6.0";
import graphql_tag_2_12_6 from "../constants/opensource/graphql-tag-2.12.6";
import graphql_ws_5_11_2 from "../constants/opensource/graphql-ws-5.11.2";
import is_cidr_5_0_3 from "../constants/opensource/is-cidr-5.0.3";
import jotai_1_13_1 from "../constants/opensource/jotai-1.13.1";
import logrocket_3_0_1 from "../constants/opensource/logrocket-3.0.1";
import react_router_dom_6_6_2 from "../constants/opensource/react-router-dom-6.6.2";
import react_scripts_5_0_1 from "../constants/opensource/react-scripts-5.0.1";
import swr_2_0_0 from "../constants/opensource/swr-2.0.0";
import typescript_4_9_4 from "../constants/opensource/typescript-4.9.4";
import urql_3_0_3 from "../constants/opensource/urql-3.0.3";
import wonka_6_1_2 from "../constants/opensource/wonka-6.1.2";
import commitlint_cli_17_4_2 from "../constants/opensource/commitlint-cli-17.4.2";
import cypress_code_coverage_3_10_0 from "../constants/opensource/cypress-code-coverage-3.10.0";
import cypress_instrument_cra_1_4_0 from "../constants/opensource/cypress-instrument-cra-1.4.0";
import faker_js_faker_7_6_0 from "../constants/opensource/faker-js-faker-7.6.0";
import graphql_codegen_add_3_2_3 from "../constants/opensource/graphql-codegen-add-3.2.3";
import graphql_inspector_ci_3_4_0 from "../constants/opensource/graphql-inspector-ci-3.4.0";
import graphql_tools_mock_8_7_15 from "../constants/opensource/graphql-tools-mock-8.7.15";
import json2csv_plainjs_6_1_3 from "../constants/opensource/json2csv-plainjs-6.1.3";
import commitlint_config_gitmoji_2_2_10 from "../constants/opensource/commitlint-config-gitmoji-2.2.10";
import concurrently_7_6_0 from "../constants/opensource/concurrently-7.6.0";
import cypress_12_3_0 from "../constants/opensource/cypress-12.3.0";
import cz_emoji_1_3_1 from "../constants/opensource/cz-emoji-1.3.1";
import eslint_8_32_0 from "../constants/opensource/eslint-8.32.0";
import husky_8_0_3 from "../constants/opensource/husky-8.0.3";
import jsonlint_1_6_3 from "../constants/opensource/jsonlint-1.6.3";
import lint_staged_13_1_0 from "../constants/opensource/lint-staged-13.1.0";
import prop_types_15_8_1 from "../constants/opensource/prop-types-15.8.1";
import rimraf_4_0_7 from "../constants/opensource/rimraf-4.0.7";
import serve_14_1_2 from "../constants/opensource/serve-14.1.2";
import unimported_1_24_0 from "../constants/opensource/unimported-1.24.0";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Close} props.close
 * @param {string} props.licenseName
 * @returns {ReactElement}
 */
export const LicenseDialog = ({ isOpen, close, licenseName }) => {
  const [licenseText, setLicenseText] = useState("");
  const [isLicenseTextReady, setIsLicenseTextReady] = useState(false);

  const readLicenseFile = (fileName) => {
    fetch(fileName)
      .then((row) => row.text())
      .then((text) => {
        setIsLicenseTextReady(false);
        setLicenseText(text);
        setIsLicenseTextReady(true);
      });
  };

  const formattedLicenseName = licenseName
    .replace(/' '| \^/, "_")
    .replace("@", "")
    .replace("/", "_")
    .replace("-", "_");
  let licenseFile = "";
  switch (formattedLicenseName) {
    case "react_dom_18.2.0":
      licenseFile = react_dom_18_2_0;
      break;
    case "auth0_auth0_react_1.12.1":
      licenseFile = auth0_react_1_12_1;
      break;
    case "emotion_cache_11.10.5":
      licenseFile = emotion_cache_11_10_5;
      break;
    case "emotion_react_11.10.5":
      licenseFile = emotion_cache_11_10_5;
      break;
    case "emotion_styled_11.10.5":
      licenseFile = emotion_cache_11_10_5;
      break;
    case "fontsource_roboto_4.5.8":
      licenseFile = fontsource_roboto_4_5_8;
      break;
    case "graphiql_toolkit_0.6.1":
      licenseFile = graphiql_toolkit_0_6_1;
      break;
    case "mui_icons_material_5.11.0":
      licenseFile = mui_icons_material_5_11_0;
      break;
    case "mui_material_5.11.4":
      licenseFile = mui_icons_material_5_11_0;
      break;
    case "mui_system_5.11.4":
      licenseFile = mui_icons_material_5_11_0;
      break;
    case "urql_exchange_auth_1.0.0":
      licenseFile = urql_exchange_auth_1_0_0;
      break;
    case "country_list_2.3.0":
      licenseFile = country_list_2_3_0;
      break;
    case "graphiql_1.11.5":
      licenseFile = graphiql_1_11_5;
      break;
    case "graphiql_explorer_0.9.0":
      licenseFile = graphiql_explorer_0_9_0;
      break;
    case "graphql_16.6.0":
      licenseFile = graphql_16_6_0;
      break;
    case "graphql_tag_2.12.6":
      licenseFile = graphql_tag_2_12_6;
      break;
    case "graphql_ws_5.11.2":
      licenseFile = graphql_ws_5_11_2;
      break;
    case "is_cidr_5.0.3":
      licenseFile = is_cidr_5_0_3;
      break;
    case "jotai_1.13.1":
      licenseFile = jotai_1_13_1;
      break;
    case "logrocket_3.0.1":
      licenseFile = logrocket_3_0_1;
      break;
    case "mui_datatables_4.3.0":
      licenseFile = mui_icons_material_5_11_0;
      break;
    case "react_router-dom_6.6.2":
      licenseFile = react_router_dom_6_6_2;
      break;
    case "react_scripts_5.0.1":
      licenseFile = react_scripts_5_0_1;
      break;
    case "swr_2.0.0":
      licenseFile = swr_2_0_0;
      break;
    case "typescript_4.9.4":
      licenseFile = typescript_4_9_4;
      break;
    case "urql_3.0.3":
      licenseFile = urql_3_0_3;
      break;
    case "wonka_6.1.2":
      licenseFile = wonka_6_1_2;
      break;
    case "commitlint_cli_17.4.2":
      licenseFile = commitlint_cli_17_4_2;
      break;
    case "commitlint_config_conventional_17.4.2":
      licenseFile = commitlint_cli_17_4_2;
      break;
    case "cypress_code_coverage_3.10.0":
      licenseFile = cypress_code_coverage_3_10_0;
      break;
    case "cypress_instrument_cra_1.4.0":
      licenseFile = cypress_instrument_cra_1_4_0;
      break;
    case "faker_js_faker_7.6.0":
      licenseFile = faker_js_faker_7_6_0;
      break;
    case "graphql_codegen_add_3.2.3":
      licenseFile = graphql_codegen_add_3_2_3;
      break;
    case "graphql_codegen_cli_2.16.4":
      licenseFile = graphql_codegen_add_3_2_3;
      break;
    case "graphql_codegen_typescript_2.8.7":
      licenseFile = graphql_codegen_add_3_2_3;
      break;
    case "graphql_codegen_typescript-operations_2.5.12":
      licenseFile = graphql_codegen_add_3_2_3;
      break;
    case "graphql_codegen_typescript-urql_3.7.3":
      licenseFile = graphql_codegen_add_3_2_3;
      break;
    case "graphql_inspector_ci_3.4.0":
      licenseFile = graphql_inspector_ci_3_4_0;
      break;
    case "graphql_inspector_github-loader_3.4.0":
      licenseFile = graphql_inspector_ci_3_4_0;
      break;
    case "graphql_inspector_graphql-loader_3.4.0":
      licenseFile = graphql_inspector_ci_3_4_0;
      break;
    case "graphql_inspector_validate-command_3.4.0":
      licenseFile = graphql_inspector_ci_3_4_0;
      break;
    case "graphql_tools_mock_8.7.15":
      licenseFile = graphql_tools_mock_8_7_15;
      break;
    case "graphql_tools_schema_9.0.13":
      licenseFile = graphql_tools_mock_8_7_15;
      break;
    case "json2csv_plainjs_6.1.3":
      licenseFile = json2csv_plainjs_6_1_3;
      break;
    case "urql_devtools_2.0.3":
      licenseFile = urql_3_0_3;
      break;
    case "commitlint_config-gitmoji_2.2.10":
      licenseFile = commitlint_config_gitmoji_2_2_10;
      break;
    case "concurrently_7.6.0":
      licenseFile = concurrently_7_6_0;
      break;
    case "cypress_12.3.0":
      licenseFile = cypress_12_3_0;
      break;
    case "cz_emoji_1.3.1":
      licenseFile = cz_emoji_1_3_1;
      break;
    case "eslint_8.32.0":
      licenseFile = eslint_8_32_0;
      break;
    case "eslint_config-prettier_8.6.0":
      licenseFile = eslint_8_32_0;
      break;
    case "eslint_plugin-cypress_2.12.1":
      licenseFile = eslint_8_32_0;
      break;
    case "eslint_plugin-jsdoc_39.6.4":
      licenseFile = eslint_8_32_0;
      break;
    case "eslint_plugin-no-only-tests_3.1.0":
      licenseFile = eslint_8_32_0;
      break;
    case "eslint_plugin-react_7.32.0":
      licenseFile = eslint_8_32_0;
      break;
    case "husky_8.0.3":
      licenseFile = husky_8_0_3;
      break;
    case "jsonlint_1.6.3":
      licenseFile = jsonlint_1_6_3;
      break;
    case "lint_staged_13.1.0":
      licenseFile = lint_staged_13_1_0;
      break;
    case "prop_types_15.8.1":
      licenseFile = prop_types_15_8_1;
      break;
    case "rimraf_4.0.7":
      licenseFile = rimraf_4_0_7;
      break;
    case "serve_14.1.2":
      licenseFile = serve_14_1_2;
      break;
    case "unimported_1.24.0":
      licenseFile = unimported_1_24_0;
      break;
    default:
      break;
  }
  readLicenseFile(licenseFile);

  return (
    <Dialog onClose={close} open={isOpen} maxWidth={"md"}>
      <Stack p="32px" sx={{ color: "#07345B", width: "690px" }}>
        <Typography sx={{ fontSize: 20 }}>License</Typography>
        {isLicenseTextReady && (
          <Typography
            pb="0.5rem"
            pt="2.3rem"
            fontSize="0.875rem"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {licenseText}
          </Typography>
        )}
        <Button
          sx={{
            color: (theme) => theme.color.bg.selectedDrawerButton,
            textTransform: "none",
            height: "40px",
            width: "104px",
            marginLeft: "500px",
            marginTop: "25px",
            backgroundColor: "white",
            borderStyle: "solid",
            borderWidth: "0.063rem",
            borderColor: (theme) => theme.color.bg.selectedDrawerButton,
            ":hover": {
              color: "white",
              backgroundColor: (theme) => theme.color.bg.selectedDrawerButton,
            },
          }}
          onClick={close}
        >
          <Typography noWrap fontSize="0.875rem">
            Got it
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};

LicenseDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
