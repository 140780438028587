export const COLUMNS_ENUM = Object.freeze({
  PREFIX: { order: 0, name: "Prefix" },
  ORIGIN_AS: { order: 1, name: "Origin AS" },
  IP_VERSION: { order: 2, name: "IP Version" },
  MASK_LENGTH: { order: 3, name: "Mask Length" },
  DATA_SOURCES_NUMBER: {
    order: 4,
    name: "Data Sources (#)",
  },
  DATA_SOURCES_PERCENTAGE: { order: 5, name: "Data Sources (%)" },
  PEER_IP: { order: 6, name: "Peer IP" },
  PEER_AS: { order: 7, name: "Peer AS" },
});

export const forbidden_prefixes = ["0.0.0.0/0", "::0/0"];
