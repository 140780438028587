import { useAtom } from "jotai";
import { useMediaQuery } from "@mui/material";
import { isDrawerOpenAtom } from "../other/store";
import { theme } from "../other/constants";

/**
 * @returns {number}
 */
export const useNumberOfOverviewColumns = () => {
  const [isDrawerOpen] = useAtom(isDrawerOpenAtom);
  const { values } = theme.breakpoints;
  const smValue = isDrawerOpen ? values.sm + 256 : values.sm;
  const mdValue = isDrawerOpen ? values.md + 256 : values.md;
  const lgValue = isDrawerOpen ? values.lg + 256 : values.lg;
  const isSM = useMediaQuery(`(min-width:${smValue}px)`);
  const isMD = useMediaQuery(`(min-width:${mdValue}px)`);
  const isLG = useMediaQuery(`(min-width:${lgValue}px)`);
  let numberOfColumns = 1;
  if (isSM) {
    numberOfColumns = 2;
  }
  if (isMD) {
    numberOfColumns = 3;
  }
  if (isLG) {
    numberOfColumns = 4;
  }

  return numberOfColumns;
};
