import { ReactElement } from "react";
import { useAtom } from "jotai";
import { displayedConfiguredASNsAtom } from "../other/store";
import { AddConfPrefixDialogAutocomplete } from "./AddConfPrefixDialogAutocomplete";
import { Stack } from "@mui/material";
import { SELECT_AS_FILTER } from "../constants/queries";
import { getASFiltersToSelect } from "../utils/components/queries";

/**
 * @param {object} props
 * @param {string} props.selectedASN
 * @param {Function} props.setSelectedASN
 * @param {ReactElement} props.errorMessage
 * @returns {ReactElement}
 */
export const AddConfPrefixDialogAutocompletes = ({
  selectedASN,
  setSelectedASN,
  errorMessage,
}) => {
  const [{ data: configuredASNs }] = useAtom(displayedConfiguredASNsAtom);
  const asnsToSelect = getASFiltersToSelect(configuredASNs);

  return (
    <Stack sx={{ my: "1rem" }}>
      <AddConfPrefixDialogAutocomplete
        key={SELECT_AS_FILTER}
        options={asnsToSelect}
        selectedItem={selectedASN}
        setSelectedItem={setSelectedASN}
        helperText={errorMessage}
      />
    </Stack>
  );
};
