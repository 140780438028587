import { ReactElement } from "react";
import { Error, Loading } from "../types/dataFetching";
import { useSelectedValue } from "../hooks/useSelectedValue";
import {
  getAnswerTableData,
  getGraphqlAPILink,
  getSubscription,
  getTextFieldFilterTypeColumn,
} from "../other/utils";
import { QueryEntry } from "./QueryEntry";
import { getASNInPathRegex, getOriginASNInPathRegex } from "../utils/queries";
import {
  COLUMNS_ENUM,
  PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN,
  SELECT_ASN,
} from "../constants/queries";
import {
  PrefixesFromOriginAsnVisibleFromAsnDocument,
  usePrefixesFromOriginAsnVisibleFromAsnSubscription,
} from "../graphql/generated/operations";
import {
  getAnswerInputJSX,
  getPrefixes,
} from "../utils/components/prefixesFromOriginASNVisibleFromASNQuery";
import { formatPrefix } from "../utils/formatting";
import { useAtom } from "jotai";
import { getQueryAnswerTableStateAtom } from "../utils/other/store";

/**
 * @param {object} props
 * @param {string[]} props.originASNsToSelect
 * @param {Loading} props.originASNsToSelectLoading
 * @param {Error} props.originASNsToSelectError
 * @param {string[]} props.visibleFromASNsToSelect
 * @param {Loading} props.visibleFromASNsToSelectLoading
 * @param {Error} props.visibleFromASNsToSelectError
 * @returns {ReactElement}
 */
export const PrefixesFromOriginASNVisibleFromASNQuery = ({
  originASNsToSelect,
  originASNsToSelectLoading,
  originASNsToSelectError,
  visibleFromASNsToSelect,
  visibleFromASNsToSelectLoading,
  visibleFromASNsToSelectError,
}) => {
  const {
    selectedValue: selectedOriginASN,
    setSelectedValue: setSelectedOriginASN,
    hasSelectedValue: hasSelectedOriginASN,
  } = useSelectedValue({ defaultValue: SELECT_ASN });
  const {
    selectedValue: selectedVisibleFromASN,
    setSelectedValue: setSelectedVisibleFromASN,
    hasSelectedValue: hasSelectedVisibleFromASN,
  } = useSelectedValue({ defaultValue: SELECT_ASN });

  const originASNRegex = getOriginASNInPathRegex(selectedOriginASN);
  const visibleFromASNRegex = getASNInPathRegex(selectedVisibleFromASN);
  const hasSelectedValues = hasSelectedVisibleFromASN && hasSelectedOriginASN;
  const [
    { data: prefixesData, fetching: prefixesFetching, error: prefixesError },
  ] = usePrefixesFromOriginAsnVisibleFromAsnSubscription({
    variables: { originASNRegex, visibleFromASNRegex },
    pause: !hasSelectedValues,
  });
  const { isPrefixVisibleAtom } = getQueryAnswerTableStateAtom({
    atomKey: PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN.atomKey,
  });
  const [isPrefixVisible] = useAtom(isPrefixVisibleAtom);

  const answerInputJSX = getAnswerInputJSX({
    originASNsToSelect,
    originASNsToSelectLoading,
    originASNsToSelectError,
    selectedOriginASN,
    setSelectedOriginASN,
    visibleFromASNsToSelect,
    visibleFromASNsToSelectLoading,
    visibleFromASNsToSelectError,
    selectedVisibleFromASN,
    setSelectedVisibleFromASN,
  });
  const answerTableData = getAnswerTableData(
    hasSelectedValues,
    prefixesData,
    getPrefixes
  );
  const answerTableColumns = [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.PREFIX.name, {
      display: isPrefixVisible,
      customBodyRenderLite: (dataIndex) =>
        formatPrefix(answerTableData, dataIndex, 0),
    }),
  ];

  return (
    <QueryEntry
      questionTitle={PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN.questionTitle}
      answerInput={answerInputJSX}
      loading={prefixesFetching}
      error={prefixesError}
      hasSelectedValue={hasSelectedValues}
      answerTableData={answerTableData}
      answerTableColumns={answerTableColumns}
      graphqlAPILink={getGraphqlAPILink(
        getSubscription(PrefixesFromOriginAsnVisibleFromAsnDocument),
        JSON.stringify({ originASNRegex, visibleFromASNRegex })
      )}
      atomKey={PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN.atomKey}
    />
  );
};
