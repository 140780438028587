/**
 * @param {object} params
 * @param {string | undefined} params.name
 * @param {string | undefined} params.surname
 * @returns {string | undefined}
 */
export const getFullname = ({ name, surname }) => {
  if (name === undefined || surname === undefined) return;
  return `${name} ${surname}`;
};
