import { ServerSideTable } from "../components/ServerSideTable";
import {
  getColumns,
  getCustomOptions,
  getCount,
  getRows,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  getRowID,
} from "../utils/components/rpkiRoas";
import {
  expandedRowIDsAtom,
  isASVisibleAtom,
  isIPVersionVisibleAtom,
  isMaskLengthVisibleAtom,
  isMaxLengthVisibleAtom,
  isPrefixVisibleAtom,
  isSourceVisibleAtom,
  rpkiRoasTableASFilterAtom,
  rpkiRoasTableCountSubscriptionAtom,
  rpkiRoasTableFilterStateAtom,
  rpkiRoasTableIPVersionFilterAtom,
  rpkiRoasTableIsSubscriptionActiveAtom,
  rpkiRoasTableMaskLengthFilterAtom,
  rpkiRoasTableMaxLengthFilterAtom,
  rpkiRoasTablePageAtom,
  rpkiRoasTablePrefixFilterAtom,
  rpkiRoasTableRowsPerPageAtom,
  rpkiRoasTableSourceFilterAtom,
  rpkiRoasTableSubscriptionAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
} from "../store/rpkiRoasTable";
import { useAtom } from "jotai";
import { getWhereArgument } from "../utils/components/rpkiRoas";
import { RpkiRoasDownloadServerSideTableDocument } from "../graphql/generated/operations";
import { ReactElement, useEffect, useState } from "react";

/**
 * @param {object} props
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const RpkiRoas = ({ urlParams }) => {
  const [filterState] = useAtom(rpkiRoasTableFilterStateAtom);
  const [, setPrefixFilter] = useAtom(rpkiRoasTablePrefixFilterAtom);
  const [, setASFilter] = useAtom(rpkiRoasTableASFilterAtom);
  const [, setIpVersionFilter] = useAtom(rpkiRoasTableIPVersionFilterAtom);
  const [, setMaskLengthFilter] = useAtom(rpkiRoasTableMaskLengthFilterAtom);
  const [, setMaxLengthFilter] = useAtom(rpkiRoasTableMaxLengthFilterAtom);
  const [, setSourceFilter] = useAtom(rpkiRoasTableSourceFilterAtom);
  const [isPrefixVisible, setIsPrefixVisible] = useAtom(isPrefixVisibleAtom);
  const [isMaxLengthVisible, setIsMaxLengthVisible] = useAtom(
    isMaxLengthVisibleAtom
  );
  const [isASVisible, setIsASVisible] = useAtom(isASVisibleAtom);
  const [isSourceVisible, setIsSourceVisible] = useAtom(isSourceVisibleAtom);
  const [isIPVersionVisible, setIsIPVersionVisible] = useAtom(
    isIPVersionVisibleAtom
  );
  const [isMaskLengthVisible, setIsMaskLengthVisible] = useAtom(
    isMaskLengthVisibleAtom
  );

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "prefixFilter":
            setPrefixFilter(item[1]);
            break;
          case "asFilter":
            setASFilter(item[1]);
            break;
          case "ipVersionFilter":
            setIpVersionFilter(item[1]);
            break;
          case "maskLengthFilter":
            setMaskLengthFilter(item[1]);
            break;
          case "maxLengthFilter":
            setMaxLengthFilter(item[1]);
            break;
          case "sourceFilter":
            setSourceFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  return (
    <ServerSideTable
      objectName="RPKI ROAs"
      filterState={filterState}
      filterSetters={{
        setPrefixFilter,
        setASFilter,
        setIpVersionFilter,
        setMaskLengthFilter,
        setMaxLengthFilter,
        setSourceFilter,
      }}
      visibleSetters={{
        setIsPrefixVisible,
        setIsMaxLengthVisible,
        setIsASVisible,
        setIsIPVersionVisible,
        setIsMaskLengthVisible,
        setIsSourceVisible,
      }}
      getColumns={getColumns}
      getColumnsParams={{
        isPrefixVisible,
        isMaxLengthVisible,
        isASVisible,
        isIPVersionVisible,
        isMaskLengthVisible,
        isSourceVisible,
      }}
      getCustomOptions={getCustomOptions}
      getCount={getCount}
      getRows={getRows}
      getWhereArgument={getWhereArgument}
      onApplyFiltersButtonClick={onApplyFiltersButtonClick}
      onViewColumnsChange={onViewColumnsChange}
      pageAtom={rpkiRoasTablePageAtom}
      rowsPerPageAtom={rpkiRoasTableRowsPerPageAtom}
      mainSubscriptionAtom={rpkiRoasTableSubscriptionAtom}
      countSubscriptionAtom={rpkiRoasTableCountSubscriptionAtom}
      isSubscriptionActiveAtom={rpkiRoasTableIsSubscriptionActiveAtom}
      sortOrderColumnNameAtom={sortOrderColumnNameAtom}
      sortOrderDirectionAtom={sortOrderDirectionAtom}
      getRowID={getRowID}
      expandedRowIDsAtom={expandedRowIDsAtom}
      serverSideTableDataQueryDocument={RpkiRoasDownloadServerSideTableDocument}
    />
  );
};
