import { useAtom } from "jotai";
import { Table } from "./Table";
import { ReactElement, useEffect, useState } from "react";
import {
  getColumns,
  getRowID,
  getTableData,
  onApplyFiltersButtonClick,
  onApplySortAlertDataButtonClick,
} from "../utils/components/alertsTable";
import { TABLE_KEY_ENUM } from "../constants/components/table";
import { usePagination } from "../hooks/usePagination";
import {
  countAtom,
  expandedRowIDsAtom,
  maxTimeEndedFilterAtom,
  maxTimeStartedFilterAtom,
  minTimeEndedFilterAtom,
  minTimeStartedFilterAtom,
  nameFilterAtom,
  pageAtom,
  rowsPerPageAtom,
  selectedRowIDsAtom,
  typeFilterAtom,
} from "../store/resolvedAlertsTable";
import { AlertsExpandedRow } from "./AlertsExpandedRow";
import { COLUMNS_ENUM } from "../constants/components/alertsTable";
import {
  addSecondsToTimeFilter,
  auth0HookGetter,
  createGQLEndpoint,
  formatTime,
  getSubscription,
  isEditor,
} from "../other/utils";
import { SortAlertDataButton } from "./SortAlertDataButton";
import { ExportDataButton } from "./ExportDataButton";
import {
  AlertsQueryFilteredDocument,
  Alerts_Bool_Exp,
} from "../graphql/generated/operations";
import { useAccessToken } from "../hooks/useAccessToken";
import { CircularProgress, Snackbar, Stack, Typography } from "@mui/material";
import { Parser } from "@json2csv/plainjs";
import { ShareUrlButton } from "./ShareUrlButton";
import { Alert } from "./Alert";
import { PATHS } from "../constants/paths";

/**
 * @param {object} params
 * @param {string[] | undefined} params.alerts
 * @param {boolean} params.alertsFetching
 * @param {Error | undefined} params.alertsError
 * @param {string} params.selectedTab
 * @param {object []} params.urlParams
 * @param {string} params.role
 * @param {Function} params.openRemoveAlertsDialog
 * @param {Function} params.setAlertsToRemove
 * @returns {ReactElement}
 */
export const ResolvedAlertsTable = ({
  alerts,
  alertsFetching,
  alertsError,
  selectedTab,
  urlParams,
  role,
  openRemoveAlertsDialog,
  setAlertsToRemove,
}) => {
  const [nameFilter, setNameFilter] = useAtom(nameFilterAtom);
  const [typeFilter, setTypeFilter] = useAtom(typeFilterAtom);
  const [minTimeStartedFilter, setMinTimeStartedFilter] = useAtom(
    minTimeStartedFilterAtom
  );
  const [maxTimeStartedFilter, setMaxTimeStartedFilter] = useAtom(
    maxTimeStartedFilterAtom
  );
  const [minTimeEndedFilter, setMinTimeEndedFilter] = useAtom(
    minTimeEndedFilterAtom
  );
  const [maxTimeEndedFilter, setMaxTimeEndedFilter] = useAtom(
    maxTimeEndedFilterAtom
  );
  const [selectedRowIDs, setSelectedRowIDs] = useAtom(selectedRowIDsAtom);
  const [expandedRowIDs, setExpandedRowIDs] = useAtom(expandedRowIDsAtom);
  const [count, setCount] = useAtom(countAtom);
  const { resetPage } = usePagination({ pageAtom, rowsPerPageAtom });

  const [copiedURL, setCopiedURL] = useState(false);

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "nameFilter":
            setNameFilter(item[1]);
            break;
          case "typeFilter":
            setTypeFilter(item[1]);
            break;
          case "minTimeStartedFilter":
            setMinTimeStartedFilter(item[1]);
            break;
          case "maxTimeStartedFilter":
            setMaxTimeStartedFilter(item[1]);
            break;
          case "minTimeEndedFilter":
            setMinTimeEndedFilter(item[1]);
            break;
          case "maxTimeEndedFilter":
            setMaxTimeEndedFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  /** @type {(string | undefined | ReactElement)[][]} */
  let tableData = [];

  if (alerts) {
    tableData = getTableData({ alerts });
  }

  /**
   * @param {object} params
   * @param {Function} params.applyNewFilters
   * @returns {void}
   */
  const onApplyFiltersButtonClickCallback = ({ applyNewFilters }) => {
    onApplyFiltersButtonClick({
      applyNewFilters,
      setNameFilter,
      setTypeFilter,
      setMinTimeStartedFilter,
      setMaxTimeStartedFilter,
      setMinTimeEndedFilter,
      setMaxTimeEndedFilter,
      resetPage,
    });
  };

  const columns = getColumns({
    selectedTab,
    tableData,
    nameFilter,
    typeFilter,
    minTimeStartedFilter,
    maxTimeStartedFilter,
    minTimeEndedFilter,
    maxTimeEndedFilter,
    setMinTimeStartedFilter,
    setMaxTimeStartedFilter,
    setMinTimeEndedFilter,
    setMaxTimeEndedFilter,
  });

  const { user } = auth0HookGetter()();
  const accessToken = useAccessToken();

  const [isCircularBarDownloadEnabled, setCircularBarDownload] =
    useState(false);

  const DownloadFile = () => {
    setCircularBarDownload(true);
    const url = createGQLEndpoint(user);
    const graphqlAPIURL = new URL(url, window.location.origin);
    let documentQuery = getSubscription(AlertsQueryFilteredDocument);
    /** @type {Alerts_Bool_Exp} */
    const whereArgument = {};
    const andArray = [];
    let queryVariables = {};
    let filename = "Resolved_Alerts.csv";

    andArray.push({ status: { _eq: "resolved" } });
    if (typeof nameFilter === "string" && nameFilter !== "") {
      andArray.push({ name: { _iregex: nameFilter } });
    }
    if (typeof typeFilter === "string" && typeFilter !== "") {
      andArray.push({ type: { _eq: typeFilter } });
    }
    if (minTimeStartedFilter && maxTimeStartedFilter) {
      andArray.push({
        starts_at: {
          _gt: addSecondsToTimeFilter(minTimeStartedFilter, -1),
          _lt: addSecondsToTimeFilter(maxTimeStartedFilter, +1),
        },
      });
    } else if (minTimeStartedFilter) {
      andArray.push({
        starts_at: { _gt: addSecondsToTimeFilter(minTimeStartedFilter, -1) },
      });
    } else if (maxTimeStartedFilter) {
      andArray.push({
        starts_at: { _lt: addSecondsToTimeFilter(maxTimeStartedFilter, +1) },
      });
    }
    if (minTimeEndedFilter && maxTimeEndedFilter) {
      andArray.push({
        ends_at: {
          _gt: addSecondsToTimeFilter(minTimeEndedFilter, -1),
          _lt: addSecondsToTimeFilter(maxTimeEndedFilter, +1),
        },
      });
    } else if (minTimeEndedFilter) {
      andArray.push({
        ends_at: { _gt: addSecondsToTimeFilter(minTimeEndedFilter, -1) },
      });
    } else if (maxTimeEndedFilter) {
      andArray.push({
        ends_at: { _lt: addSecondsToTimeFilter(maxTimeEndedFilter, +1) },
      });
    }

    whereArgument._and = andArray;
    queryVariables = { where: whereArgument };

    fetch(graphqlAPIURL.href, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: documentQuery,
        variables: queryVariables,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCircularBarDownload(false);
        const opts = {
          fields: [
            {
              label: "Name",
              value: "name",
            },
            {
              label: "Type",
              value: "type",
            },
            {
              label: "Started",
              value: "starts_at",
            },
            {
              label: "Ended",
              value: "ends_at",
            },
            {
              label: "Severity",
              value: "severity",
            },
            {
              label: "Event",
              value: "data",
            },
            {
              label: "Configured Resources",
              value: "vars",
            },
            {
              label: "Description",
              value: "description",
            },
            {
              label: "Receiver Type",
              value: "receiver_type",
            },
            {
              label: "Receiver Endpoint",
              value: "receiver_endpoint",
            },
            {
              label: "ID",
              value: "id",
            },
          ],
        };
        const parser = new Parser(opts);
        const csv = parser.parse(data["data"]["alerts"]);
        const blob = new Blob([csv], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = filename;
        link.href = url;
        link.click();
      })
      .catch((err) => {
        setCircularBarDownload(false);
        console.log(err);
      });
  };

  const [sortAlertsOrder, setSortAlertsOrder] = useState({
    name: "Name",
    direction: "asc",
  });
  const onApplySortAlertDataButtonClickCallback = (
    applyNewAlertsSortingType
  ) => {
    onApplySortAlertDataButtonClick({
      applyNewAlertsSortingType,
      setSortAlertsOrder,
    });
  };

  const shareUrlData = () => {
    let filters = "";
    if (nameFilter !== "" && typeof nameFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "nameFilter=" + nameFilter + "&";
    }
    if (typeFilter !== "" && typeof typeFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "typeFilter=" + typeFilter + "&";
    }
    if (
      minTimeStartedFilter !== "" &&
      typeof minTimeStartedFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters += "minTimeStartedFilter=" + minTimeStartedFilter + "&";
    }
    if (
      maxTimeStartedFilter !== "" &&
      typeof maxTimeStartedFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters += "maxTimeStartedFilter=" + maxTimeStartedFilter + "&";
    }
    if (
      minTimeEndedFilter !== "" &&
      typeof minTimeEndedFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters += "minTimeEndedFilter=" + minTimeEndedFilter + "&";
    }
    if (
      maxTimeEndedFilter !== "" &&
      typeof maxTimeEndedFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters += "maxTimeEndedFilter=" + maxTimeEndedFilter + "&";
    }
    const serviceURL = `https://${window.location.host}${PATHS.dashboard.alerts.index}${filters}#${selectedTab}`;
    navigator.clipboard.writeText(serviceURL);
    setCopiedURL(true);
  };

  const handleClose = () => {
    setCopiedURL(false);
  };

  const HeaderElements = () => (
    <>
      <ExportDataButton onClick={DownloadFile} />
      <SortAlertDataButton
        sortCallback={onApplySortAlertDataButtonClickCallback}
        selectedTab={selectedTab}
      />
      <ShareUrlButton onClick={shareUrlData} toolbarIconOrder={-2} />
    </>
  );

  const onRowsDelete = (rowsDeleted) => {
    const alertsToRemove = rowsDeleted.data.map(({ dataIndex }) => ({
      id: tableData[dataIndex][COLUMNS_ENUM.ID.order],
      name: tableData[dataIndex][COLUMNS_ENUM.NAME.order],
      alldata: tableData[dataIndex],
    }));
    setAlertsToRemove(alertsToRemove);
    openRemoveAlertsDialog();
    return false;
  };

  return (
    <Stack>
      <Table
        title={
          <Stack direction={"row"}>
            {isCircularBarDownloadEnabled && (
              <Stack direction={"row"}>
                <CircularProgress />
                <Typography style={{ margin: "10px" }}>
                  Download In Progress ...
                </Typography>
              </Stack>
            )}
          </Stack>
        }
        data={tableData}
        loading={alertsFetching}
        error={alertsError}
        columns={columns}
        customOptions={{
          sortOrder: sortAlertsOrder,
          expandableRows: true,
          selectableRowsHideCheckboxes: !isEditor(role),
          onRowsDelete,
          selectableRows: "multiple",
          selectableRowsHeader: false,
          viewColumns: false,
          pagination: true,
          rowHover: false,
          responsive: "standard",
          tableBodyMaxHeight: "70vh",
          customToolbar: HeaderElements,
          renderExpandableRow: (rowData, rowMeta) => {
            const startedDate = formatTime(
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.STARTS_AT.order]
            );
            let endedDate = undefined;
            if (selectedTab === "resolved") {
              endedDate = formatTime(
                tableData[rowMeta.dataIndex][COLUMNS_ENUM.ENDS_AT.order]
              );
            }
            const receiverType =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.RECEIVER_TYPE.order];
            const receiverEndpoint =
              tableData[rowMeta.dataIndex][
                COLUMNS_ENUM.RECEIVER_ENDPOINT.order
              ];
            const eventData =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.DATA.order];
            const eventConfResources =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.VARS.order];
            const eventDescription =
              tableData[rowMeta.dataIndex][COLUMNS_ENUM.DESCRIPTION.order];
            return (
              <AlertsExpandedRow
                startedDate={startedDate}
                endedDate={endedDate}
                receiverType={receiverType}
                receiverEndpoint={receiverEndpoint}
                eventData={eventData}
                eventConfResources={eventConfResources}
                eventDescription={eventDescription}
                selectedTab={selectedTab}
              />
            );
          },
          textLabels: {
            body: {
              noMatch: "No Resolved Alerts found.",
            },
          },
        }}
        themeKey={TABLE_KEY_ENUM.RESOLVEDALERTS}
        pageAtom={pageAtom}
        rowsPerPageAtom={rowsPerPageAtom}
        count={count}
        setCount={setCount}
        onApplyFiltersButtonClick={onApplyFiltersButtonClickCallback}
        getRowID={getRowID}
        selectedRowIDs={selectedRowIDs}
        setSelectedRowIDs={setSelectedRowIDs}
        expandedRowIDs={expandedRowIDs}
        setExpandedRowIDs={setExpandedRowIDs}
        displayDownloadButton={false}
      />
      <Snackbar
        open={copiedURL}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Share link copied to clipboard
        </Alert>
      </Snackbar>
    </Stack>
  );
};
