import useSWR from "swr";
import { getAbuseEmailsFromJSON } from "../utils/ripestat";

export const useASAbuseEmails = (asn) => {
  const { data, error } = useSWR(
    `https://stat.ripe.net/data/abuse-contact-finder/data.json?resource=AS${asn}`
  );

  let abuseEmails;

  if (data) {
    abuseEmails = getAbuseEmailsFromJSON(data);
  }

  return {
    data: abuseEmails,
    loading: !error && !data,
    error,
  };
};
