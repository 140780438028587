import { INITIAL_PAGE } from "../constants/hooks/usePagination";
import { PrimitiveAtom, useAtom } from "jotai";
import { PaginationResult } from "../types/hooks/usePagination";

/**
 * @param {object} params
 * @param {PrimitiveAtom<number>} params.pageAtom
 * @param {PrimitiveAtom<number>} params.rowsPerPageAtom
 * @returns {PaginationResult}
 */
export const usePagination = ({ pageAtom, rowsPerPageAtom }) => {
  const [page, setPage] = useAtom(pageAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);

  const resetPage = () => {
    if (page !== INITIAL_PAGE) {
      setPage(INITIAL_PAGE);
    }
  };

  return { page, setPage, rowsPerPage, setRowsPerPage, resetPage };
};
