// import { Close } from "../../types/components/dialog";
import {
  InsertAlertRuleMutation,
  OperationResult,
  Exact,
} from "../../graphql/generated/operations";
import { alertRuleReceiverTypeInverse, alertSeverityInverse } from "../../constants/components/confAlertsInfoTable";
import { unformatDataService, getSelectedPrefixesGQLList, getSelectedASesRegex } from "../../other/utils";

/**
 * @param {object} params
 * @param {object} params.mutationObject
 * @param {Function} params.addAlertRule
 * @param {Function} params.setFailureReason
 * @param {Function} params.setSnackbarMessage
 * @param {Function} params.openSuccessSnackbar
 * @param {Function} params.onClose
 * @returns {Promise<void>}
 */
export const onAddAlertRule = async ({
  mutationObject,
  addAlertRule,
  setFailureReason,
  setSnackbarMessage,
  openSuccessSnackbar,
  onClose,
}) => {
  /** @type {OperationResult<InsertAlertRuleMutation, Exact<...>>} */
  const { data, error } = await addAlertRule({
    object: mutationObject,
  });

  if (error) {
    const errorMessage = error.message;
    if (errorMessage) {
      if (errorMessage.includes("Uniqueness violation")) {
        setFailureReason("Alert Rule already exists");
        return;
      }
      setFailureReason(errorMessage);
      return;
    }
    console.error(error);
    setFailureReason("An error has occurred.");
    return;
  }

  if (
    !data ||
    !data.insertAlertSubscription ||
    !data.insertAlertSubscription.name
  ) {
    console.error(`Could not add Alert Rule. data: ${JSON.stringify(data)}`);
    setFailureReason("Could not add Alert Rule");
    return;
  }

  const addedAlertRule = data.insertAlertSubscription.name;

  setSnackbarMessage(`Added Alert Rule ${addedAlertRule}`);
  openSuccessSnackbar();
  onClose();
};

/**
 * @param {object} params
 * @param {object} params.mutationObject
 * @param {Function} params.addAlertRules
 * @param {Function} params.setFailureReason
 * @param {Function} params.setSnackbarMessage
 * @param {Function} params.openSuccessSnackbar
 * @param {Function} params.onClose
 * @returns {Promise<void>}
 */
export const onAddAlertRules = async ({
  mutationObject,
  addAlertRules,
  setFailureReason,
  setSnackbarMessage,
  openSuccessSnackbar,
  onClose,
}) => {
  const { data, error } = await addAlertRules({
    alerts: mutationObject,
  });
  
  if (error) {
    const errorMessage = error.message;
    if (errorMessage) {
      if (errorMessage.includes("Uniqueness violation")) {
        setFailureReason("Alert Rules already exists");
        return;
      }
      setFailureReason(errorMessage);
      return;
    }
    console.error(error);
    setFailureReason("An error has occurred.");
    return;
  }

  if (
    !data ||
    !data.insertAlertSubscriptions ||
    data.insertAlertSubscriptions.affected_rows < 1
  ) {
    console.error(`Could not add Alert Rules. data: ${JSON.stringify(data)}`);
    setFailureReason("Could not add Alert Rules");
    return;
  }

  const addedAlertRulesLength = data.insertAlertSubscriptions.returning.length;

  setSnackbarMessage(`Added ${addedAlertRulesLength} Alert Rules`);
  openSuccessSnackbar();
  onClose();
};

/**
 * @param {object} params
 * @param {string} params.selectedAS
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.selectedDataSourcesThreshold
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getRouteLeakMutationObject = ({
  selectedAS,
  selectedPrefixes,
  selectedDataSourcesThreshold,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription RouteLeak($asn: bigint!, $prefixes: [cidr!] = [], $ds_thres: Int!) {prefixes(where: {routes: {originAutonomousSystem: {number: {_eq: $asn}}}, network: {_nin: $prefixes}, data_source_count: {_gte: $ds_thres} } order_by: {network: asc}) {network }}",
  vars: {
    asn: selectedAS,
    prefixes: selectedPrefixes,
    ds_thres: selectedDataSourcesThreshold,
  },
  fire_alert_regex: "^.*prefixes.*network.*$",
  type: "unexpected_originated_prefixes",
  severity: "critical",
  name: customAlertName,
  description:
    "Unexpected prefixes in the list of prefixes that are announced by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getExactPrefixHijackMutationObject = ({
  selectedASes,
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription ExactPrefixHijack($asns: [bigint!] = [], $prefixes: [cidr!] = []) {routes(where: {originAutonomousSystem: {number: {_nin: $asns}}, prefix: {network: {_in: $prefixes}}} order_by: {prefix: {network: asc}, originAutonomousSystem: {number: asc}}) {originAutonomousSystem {number} prefix {network}}}",
  vars: {
    asns: selectedASes,
    prefixes: selectedPrefixes,
  },
  fire_alert_regex: "^.*routes.*originAutonomousSystem.*$",
  type: "as_origin_violation_exact",
  severity: "critical",
  name: customAlertName,
  description: "Illegal origin ASes that announce configured prefixes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getSubPrefixHijackMutationObject = ({
  selectedASes,
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription IllegalOriginsFromWhichSubPrefixIsAnnounced($asns: [bigint!] = [], $prefixes: _cidr!) { routes(where: {originAutonomousSystem: {number: {_nin: $asns}}} order_by: {originAutonomousSystem: {number: asc}, prefix: {network: asc}}) { originAutonomousSystem { number } prefix { network is_sub_prefix_multiple(args: {networks: $prefixes}) }}}",
  vars: {
    asns: selectedASes,
    prefixes: getSelectedPrefixesGQLList(selectedPrefixes),
  },
  fire_alert_regex: ".*is_sub_prefix_multiple...?.[0-9].*",
  type: "as_origin_violation_sub",
  severity: "critical",
  name: customAlertName,
  description:
    "Illegal origin ASes that announce subprefixes of configured prefixes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.selectedBaseService
 * @param {string} params.selectedCompService
 * @param {string} params.selectedEndASN
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getASPathComparisonMutationObject = ({
  selectedBaseService,
  selectedCompService,
  selectedEndASN,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription DiffereringASPathsForPrefix($base_service: String!, $comp_service: String!, $end_asn: String!) { prefixes(order_by: {network: asc}) { as_paths_diff(args: {base_service: $base_service, comp_service: $comp_service, end_asn: $end_asn}) network }}",
  vars: {
    base_service: unformatDataService(selectedBaseService),
    comp_service: unformatDataService(selectedCompService),
    end_asn: selectedEndASN,
  },
  fire_alert_regex: "^.*prefixes.*as_paths_diff.*prefix.*",
  type: "as_path_diffs",
  severity: "warning",
  name: customAlertName,
  description:
    "Discrepancies in AS paths towards the same prefix, comparing between different Data Services, up to a terminating (end) AS.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.selectedDataSourcesThreshold
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getSquattingMutationObject = ({
  selectedPrefixes,
  selectedDataSourcesThreshold,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription SquattedPrefixes($prefixes: [String!] = [], $ds_thres: Int!) { prefixes(where: {_and: [{_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, {data_source_count: {_gte: $ds_thres}}]} order_by: {network: asc}) { network routes { originAutonomousSystem { number } } }}",
  vars: {
    prefixes: selectedPrefixes,
    ds_thres: selectedDataSourcesThreshold,
  },
  fire_alert_regex: "^.*prefixes.*network.*$",
  type: "squatting",
  severity: "critical",
  name: customAlertName,
  description:
    "Illegal origin ASes announcing prefixes that are not currently announced by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.selectedOriginAS
 * @param {string[]} params.selectedNeighborASes
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getNeighborLeakHijackMutationObject = ({
  selectedOriginAS,
  selectedNeighborASes,
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription InvalidNeighborsToWhichPrefixesAreAnnounced($origin_asn: bigint!, $neighbor_asns: [bigint!] = [], $prefixes: [String!] = []) { routes(where: {originAutonomousSystem: {number: {_eq: $origin_asn}}, neighborAutonomousSystem: {number: {_nin: $neighbor_asns}}, prefix: {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}} order_by: {neighborAutonomousSystem: {number: asc}, prefix: {network: asc}}) { neighborAutonomousSystem { number } prefix { network } }}",
  vars: {
    origin_asn: selectedOriginAS,
    neighbor_asns: selectedNeighborASes,
    prefixes: selectedPrefixes,
  },
  fire_alert_regex: "^.*routes.*neighborAutonomousSystem.*$",
  type: "unexpected_neighbor_prefix",
  severity: "critical",
  name: customAlertName,
  description:
    "New neighbors that not only appear to peer with configured ASes, but also propagate their prefixes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedRPKIPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getRPKIInvalidDetectionMutationObject = ({
  selectedASes,
  selectedRPKIPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription RPKIInvalidPrefixesAnnouncedByOtherASNs($asns: [bigint!] = [], $prefixes: [String!] = [], $rpki_status: String!) { routes(where: {originAutonomousSystem: {number: {_nin: $asns}}, prefix: {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, rpki_status: {_eq: $rpki_status}} order_by: {prefix: {network: asc}, originAutonomousSystem: {number: asc}}) { prefix { network } originAutonomousSystem { number } }}",
  vars: {
    asns: selectedASes,
    prefixes: selectedRPKIPrefixes,
    rpki_status: "invalid",
  },
  fire_alert_regex: "^.*routes.*prefix.*$",
  type: "rpki_invalid_detect",
  severity: "critical",
  name: customAlertName,
  description:
    "RPKI-Invalid announcements of configured prefixes by other ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedBogonPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getBogonExactPrefixMutationObject = ({
  selectedASes,
  selectedBogonPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription ExactMatchBogonPrefix($asns: [bigint!] = [], $bogon_prefixes: [cidr!] = []) { routes(where: {originAutonomousSystem: {number: {_in: $asns}}, prefix: {network: {_in: $bogon_prefixes}}} order_by: {prefix: {network: asc}, originAutonomousSystem: {number: asc}}) { originAutonomousSystem { number } prefix { network } } }",
  vars: {
    asns: selectedASes,
    bogon_prefixes: selectedBogonPrefixes,
  },
  fire_alert_regex: "^.*routes.*originAutonomousSystem.*$",
  type: "bogon_prefix_exact",
  severity: "warning",
  name: customAlertName,
  description: "Announcements of bogon prefixes by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedBogonPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getBogonSubPrefixMutationObject = ({
  selectedASes,
  selectedBogonPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription SubMatchBogonPrefix($asns: [bigint!] = [], $bogon_prefixes: _cidr!) { routes(where: {originAutonomousSystem: {number: {_in: $asns}}} order_by: {prefix: {network: asc}, originAutonomousSystem: {number: asc}}) { originAutonomousSystem { number } prefix { network is_sub_prefix_multiple(args: {networks: $bogon_prefixes}) } } }",
  vars: {
    asns: selectedASes,
    bogon_prefixes: getSelectedPrefixesGQLList(selectedBogonPrefixes),
  },
  fire_alert_regex: ".*is_sub_prefix_multiple...?.[0-9].*",
  type: "bogon_prefix_sub",
  severity: "warning",
  name: customAlertName,
  description: "Announcements of bogon prefixes by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getRPKIInvalidAnnouncementMutationObject = ({
  selectedASes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription RPKIInvalidPrefixesAnnouncedByMyASNs($asns: [bigint!] = [], $rpki_status: String!) { routes(where: {originAutonomousSystem: {number: {_in: $asns}}, rpki_status: {_eq: $rpki_status}} order_by: {prefix: {network: asc}, originAutonomousSystem: {number: asc}}) { prefix { network } originAutonomousSystem { number } }}",
  vars: {
    asns: selectedASes,
    rpki_status: "invalid",
  },
  fire_alert_regex: "^.*routes.*prefix.*$",
  type: "rpki_invalid_announce",
  severity: "critical",
  name: customAlertName,
  description:
    "RPKI-Invalid announcements by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.selectedAS
 * @param {string[]} params.selectedNeighborASes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getNewNeighborMutationObject = ({
  selectedAS,
  selectedNeighborASes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription InvalidPeerings($configured_asn: bigint!, $neighbor_asns: [bigint!] = []) { peerings(where: {_or: [{leftAutonomousSystem: {number: {_eq: $configured_asn}}, rightAutonomousSystem: {number: {_nin: $neighbor_asns}}}, {rightAutonomousSystem: {number: {_eq: $configured_asn}}, leftAutonomousSystem: {number: {_nin: $neighbor_asns}}}]} order_by: {leftAutonomousSystem: {number: asc}, rightAutonomousSystem: {number: asc}}) { leftAutonomousSystem { number } rightAutonomousSystem { number } }}",
  vars: {
    configured_asn: selectedAS,
    neighbor_asns: selectedNeighborASes,
  },
  fire_alert_regex: "^.*peerings.*leftAutonomousSystem.*$",
  type: "unexpected_peering",
  severity: "warning",
  name: customAlertName,
  description:
    "New neighbors that appear to peer with configured ASes. Possible AS path manipulation.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getRPKIInvalidPropagationMutationObject = ({
  selectedASes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription RPKIInvalidPrefixesPropagatedByMyASNs($asn_regex: String, $rpki_status: String!) { routes(order_by: {as_path: asc, prefix: {network: asc}} where: {as_path: {_iregex: $asn_regex}, rpki_status: {_eq: $rpki_status}}) { prefix { network } as_path }}",
  vars: {
    asn_regex: getSelectedASesRegex(selectedASes),
    rpki_status: "invalid",
  },
  fire_alert_regex: "^.*routes.*as_path.*$",
  type: "rpki_invalid_propagate",
  severity: "warning",
  name: customAlertName,
  description:
    "RPKI-Invalid routes propagated by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getRPKINotFoundPropagationMutationObject = ({
  selectedASes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription RPKINotFoundPrefixesPropagatedByMyASNs($asn_regex: String, $rpki_status: String!) { routes(order_by: {as_path: asc, prefix: {network: asc}} where: {as_path: {_iregex: $asn_regex}, rpki_status: {_eq: $rpki_status}}) { prefix { network } as_path }}",
  vars: {
    asn_regex: getSelectedASesRegex(selectedASes),
    rpki_status: "not-found",
  },
  fire_alert_regex: "^.*routes.*as_path.*$",
  type: "rpki_not_found_propagate",
  severity: "warning",
  name: customAlertName,
  description:
    "RPKI-NotFound routes propagated by configured ASes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.customQuery
 * @param {string} params.customQueryParams
 * @param {string} params.customFireAlertRegex
 * @param {string} params.customDescription
 * @param {string} params.customSeverity
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getCustomMutationObject = ({
  customQuery,
  customQueryParams,
  customFireAlertRegex,
  customDescription,
  customSeverity,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => {
  var params;
  try {
    params = JSON.parse(customQueryParams);
  } catch (e) {
    params = {};
  }
  return {
    query: customQuery,
    vars: params,
    fire_alert_regex: customFireAlertRegex,
    type: "custom",
    severity: alertSeverityInverse[customSeverity],
    name: customAlertName,
    description: customDescription,
    receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
    receiver_endpoint: selectedAlertReceiverEndpoint,
  };
}

/**
 * @param {object} params
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getBogonASMutationObject = ({
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription BogonAS($prefixes: [String!] = [], $bogon_as_regex: String!) { routes(where: {prefix: {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, contains_bogon_ases: {_regex: $bogon_as_regex}} order_by: {as_path: asc, prefix: {network: asc}}) { prefix { network } as_path contains_bogon_ases }}",
  vars: {
    prefixes: selectedPrefixes,
    bogon_as_regex: "AS.*",
  },
  fire_alert_regex: "^.*routes.*as_path.*$",
  type: "bogon_as",
  severity: "warning",
  name: customAlertName,
  description:
    "In-path presence of bogon ASes, in routes towards configured prefixes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedPresentASes
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getASPathPresenceMutationObject = ({
  selectedPresentASes,
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription ASPathPresence($prefixes: [String!] = [], $asn_present_regex: String!) { routes(where: {prefix: {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, as_path: {_regex: $asn_present_regex}} order_by: {as_path: asc, prefix: {network: asc}}) { prefix { network } as_path }}",
  vars: {
    prefixes: selectedPrefixes,
    asn_present_regex: getSelectedASesRegex(selectedPresentASes),
  },
  fire_alert_regex: "^.*routes.*as_path.*$",
  type: "as_path_presence",
  severity: "warning",
  name: customAlertName,
  description:
    "Presence of ASes in paths towards configured prefixes.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.selectedASPathRegex
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getInvalidASPathPatternMutationObject = ({
  selectedASPathRegex,
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription ASPathRegexViolation($prefixes: [String!] = [], $as_path_regex: String!) { routes(where: {prefix: {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, as_path: {_nregex: $as_path_regex}} order_by: {as_path: asc, prefix: {network: asc}}) { prefix { network } as_path }}",
  vars: {
    prefixes: selectedPrefixes,
    as_path_regex: selectedASPathRegex,
  },
  fire_alert_regex: "^.*routes.*as_path.*$",
  type: "as_path_regex_violation",
  severity: "warning",
  name: customAlertName,
  description:
    "AS paths towards configured prefixes violate a valid pattern.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string} params.selectedBaseService
 * @param {string} params.selectedCompService
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getPrefixComparisonMutationObject = ({
  selectedASes,
  selectedBaseService,
  selectedCompService,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription DiffereringPrefixesForASes($asns: [bigint!], $base_service: String!, $comp_service: String!) { autonomousSystems(where: {number: {_in: $asns}}) { orig_prefixes_diff(args: {base_service: $base_service, comp_service: $comp_service}) number }}",
  vars: {
    asns: selectedASes,
    base_service: unformatDataService(selectedBaseService),
    comp_service: unformatDataService(selectedCompService),
  },
  fire_alert_regex: "^.*autonomousSystems.*orig_prefixes_diff.*origin_as.*",
  type: "prefix_diffs",
  severity: "warning",
  name: customAlertName,
  description:
    "Discrepancies in prefixes announced by configured ASes, comparing between different Data Services.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string[]} params.selectedASes
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.selectedDataSourcesThreshold
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getPrefixVisibilityLossMutationObject = ({
  selectedASes,
  selectedPrefixes,
  selectedDataSourcesThreshold,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription LowPrefixVisibility($asns: [bigint!] = [], $prefixes: [String!] = [], $ds_thres: Int!) { prefixes(where: {_and: [{routes: {originAutonomousSystem: {number: {_in: $asns}}}}, {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, {data_source_count:{_lte: $ds_thres}}]} order_by: {network: asc}) { network data_source_count }}",
    vars: {
      asns: selectedASes,
      prefixes: selectedPrefixes,
      ds_thres: selectedDataSourcesThreshold,
    },
  fire_alert_regex: "^.*prefixes.*network.*$",
  type: "low_prefix_visibility",
  severity: "critical",
  name: customAlertName,
  description:
    "Visibility of prefix falls below a configured data source count threshold.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.selectedAS
 * @param {string[]} params.selectedNeighborASes
 * @param {string} params.selectedDataSourcesThreshold
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getPeeringVisibilityLossMutationObject = ({
  selectedAS,
  selectedNeighborASes,
  selectedDataSourcesThreshold,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription LowPeeringVisibility($configured_asn: bigint!, $neighbor_asns: [bigint!] = [], $ds_thres: Int!) { peerings(where: {_or: [{leftAutonomousSystem: {number: {_eq: $configured_asn}}, rightAutonomousSystem: {number: {_in: $neighbor_asns}}, data_source_count: {_lte: $ds_thres}}, {rightAutonomousSystem: {number: {_eq: $configured_asn}}, leftAutonomousSystem: {number: {_in: $neighbor_asns}}, data_source_count: {_lte: $ds_thres}}]} order_by: {leftAutonomousSystem: {number: asc}, rightAutonomousSystem: {number: asc}}) { leftAutonomousSystem { number } rightAutonomousSystem { number } data_source_count }}",
    vars: {
      configured_asn: selectedAS,
      neighbor_asns: selectedNeighborASes,
      ds_thres: selectedDataSourcesThreshold,
    },
  fire_alert_regex: "^.*peerings.*leftAutonomousSystem.*$",
  type: "low_peering_visibility",
  severity: "critical",
  name: customAlertName,
  description:
    "Visibility of peering falls below a configured data source count threshold.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});

/**
 * @param {object} params
 * @param {string} params.selectedASPathLengthThreshold
 * @param {string[]} params.selectedPrefixes
 * @param {string} params.customAlertName
 * @param {string} params.selectedAlertReceiverType
 * @param {string} params.selectedAlertReceiverEndpoint
 * @returns {object}
 */
export const getLongASPathMutationObject = ({
  selectedASPathLengthThreshold,
  selectedPrefixes,
  customAlertName,
  selectedAlertReceiverType,
  selectedAlertReceiverEndpoint,
}) => ({
  query:
    "subscription LongASPath($prefixes: [String!] = [], $as_path_length_thres: Int!) { routes(where: {prefix: {_or: [{network_str: {_in: $prefixes}}, {configured_prefix_best_match: {_in: $prefixes}}]}, as_path_length: {_gt: $as_path_length_thres}} order_by: {as_path: asc, prefix: {network: asc}}) { prefix { network } as_path as_path_length } }",
  vars: {
    prefixes: selectedPrefixes,
    as_path_length_thres: selectedASPathLengthThreshold,
  },
  fire_alert_regex: "^.*routes.*as_path.*$",
  type: "as_path_length_violation",
  severity: "warning",
  name: customAlertName,
  description:
    "Paths towards configured prefixes exceed a specified length threshold.",
  receiver_type: alertRuleReceiverTypeInverse[selectedAlertReceiverType],
  receiver_endpoint: selectedAlertReceiverEndpoint,
});