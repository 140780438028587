import { ReactElement } from "react";
import {
  formatTime,
  getRangeFilterColumn,
  getTextFieldFilterTypeColumn,
  isoDateToNumber,
} from "../../other/utils";
import { COLUMNS_ENUM } from "../../constants/components/alertsTable";
import { Chip, Stack, Typography } from "@mui/material";
import { getRangeFilterList } from "../filtering";
import { AlertFieldAndValue } from "../../components/AlertFieldAndValue";
import { alertSeverity } from "../../constants/components/confAlertsInfoTable";

/**
 * @param {object} params
 * @param {string[]} params.alerts
 * @returns {(string | undefined | ReactElement)[][]}
 */
export const getTableData = ({ alerts }) =>
  alerts.map(
    ({
      data,
      description,
      ends_at,
      fire_alert_regex,
      id,
      name,
      query,
      receiver_endpoint,
      receiver_type,
      severity,
      starts_at,
      status,
      type,
      vars,
    }) => [
      name,
      type,
      starts_at,
      ends_at,
      severity,
      data,
      description,
      fire_alert_regex,
      id,
      query,
      receiver_endpoint,
      receiver_type,
      status,
      vars,
    ]
  );

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setNameFilter
 * @param {Function} params.setTypeFilter
 * @param {Function} params.setMinTimeStartedFilter
 * @param {Function} params.setMaxTimeStartedFilter
 * @param {Function} params.setMinTimeEndedFilter
 * @param {Function} params.setMaxTimeEndedFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setNameFilter,
  setTypeFilter,
  setMinTimeStartedFilter,
  setMaxTimeStartedFilter,
  setMinTimeEndedFilter,
  setMaxTimeEndedFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setNameFilter(filterList[0][0]);
  setTypeFilter(filterList[1][0]);
  setMinTimeStartedFilter(filterList[2][0]);
  setMaxTimeStartedFilter(filterList[2][1]);
  setMinTimeEndedFilter(filterList[3][0]);
  setMaxTimeEndedFilter(filterList[3][1]);
  resetPage();
};

/**
 * @param {object} params
 * @param {object} params.applyNewAlertsSortingType
 * @param {Function} params.setSortAlertsOrder
 * @returns {void}
 */
export const onApplySortAlertDataButtonClick = ({
  applyNewAlertsSortingType,
  setSortAlertsOrder,
}) => {
  setSortAlertsOrder(applyNewAlertsSortingType);
};

/**
 * @param {object} params
 * @param {string} params.selectedTab
 * @param {(string | undefined | ReactElement)[][]} params.tableData
 * @param {string} params.nameFilter
 * @param {string} params.typeFilter
 * @param {string} params.minTimeStartedFilter
 * @param {string} params.maxTimeStartedFilter
 * @param {string} params.minTimeEndedFilter
 * @param {string} params.maxTimeEndedFilter
 * @param {Function} params.setMinTimeStartedFilter
 * @param {Function} params.setMaxTimeStartedFilter
 * @param {Function} params.setMinTimeEndedFilter
 * @param {Function} params.setMaxTimeEndedFilter
 * @returns {object[]}
 */
export const getColumns = ({
  selectedTab,
  tableData,
  nameFilter,
  typeFilter,
  minTimeStartedFilter,
  maxTimeStartedFilter,
  minTimeEndedFilter,
  maxTimeEndedFilter,
  setMinTimeStartedFilter,
  setMaxTimeStartedFilter,
  setMinTimeEndedFilter,
  setMaxTimeEndedFilter,
}) => [
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.NAME.name, {
    sort: true,
    filterList: nameFilter ? [nameFilter] : [],
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const name = tableData[dataIndex][COLUMNS_ENUM.NAME.order];
      switch (selectedTab) {
        case "active":
          return (
            <Typography
              style={{
                width: "283px",
                fontSize: "16px",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {name}
            </Typography>
          );
        case "resolved":
          return (
            <Typography
              style={{
                width: "283px",
                fontSize: "16px",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                marginLeft: "22px",
              }}
            >
              {name}
            </Typography>
          );
        default:
          break;
      }
    },
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.TYPE.name, {
    filter: true,
    sort: true,
    sortCompare: (order) => {
      return (row1, row2) => {
        return row1.data.localeCompare(row2.data) * (order === "asc" ? 1 : -1);
      };
    },
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const type = tableData[dataIndex][COLUMNS_ENUM.TYPE.order];
      if (!type) return;
      return (
        <Stack direction="row" sx={{ width: "200px" }}>
          <Chip
            sx={{
              height: "1.5rem",
              backgroundColor: "blue3.main",
              color: "white",
              fontSize: "0.75rem",
              ".MuiChip-label": {
                px: "0.5rem",
              },
            }}
            label={type}
          />
        </Stack>
      );
    },
    filterList: typeFilter ? [typeFilter] : [],
    filterType: "custom",
    filterOptions: {
      logic: (value, filters) => {
        if (value === filters[0]) return false;
        return true;
      },
    },
  }),
  getRangeFilterColumn(
    COLUMNS_ENUM.STARTS_AT.name,
    {
      filterList: getRangeFilterList(
        minTimeStartedFilter,
        maxTimeStartedFilter
      ),
      display: selectedTab === "active" ? true : "excluded",
      customHeadRender: () => <></>,
      customBodyRenderLite: (dataIndex) => {
        const startedDate = formatTime(
          tableData[dataIndex][COLUMNS_ENUM.STARTS_AT.order]
        );
        return (
          <Stack sx={{ width: "200px" }}>
            <AlertFieldAndValue
              field="Started"
              value={startedDate}
              fontSize="0.875rem"
            />
          </Stack>
        );
      },
      sort: true,
      sortCompare: (order) => {
        return (row1, row2) => {
          let date1 = new Date(row1.data);
          let date2 = new Date(row2.data);
          return (date1 - date2) * (order === "asc" ? 1 : -1);
        };
      },
    },
    setMinTimeStartedFilter,
    setMaxTimeStartedFilter,
    isoDateToNumber,
    "date"
  ),
  getRangeFilterColumn(
    COLUMNS_ENUM.ENDS_AT.name,
    {
      filter: selectedTab === "resolved" ? true : false,
      filterList: getRangeFilterList(minTimeEndedFilter, maxTimeEndedFilter),
      display: selectedTab === "resolved" ? true : "excluded",
      customHeadRender: () => <></>,
      customBodyRenderLite: (dataIndex) => {
        const endedDate = formatTime(
          tableData[dataIndex][COLUMNS_ENUM.ENDS_AT.order]
        );
        return (
          <Stack sx={{ width: "200px" }}>
            <AlertFieldAndValue
              field="Ended"
              value={endedDate}
              fontSize="0.875rem"
            />
          </Stack>
        );
      },
      sort: true,
      sortCompare: (order) => {
        return (row1, row2) => {
          let date1 = new Date(row1.data);
          let date2 = new Date(row2.data);
          return (date1 - date2) * (order === "asc" ? 1 : -1);
        };
      },
    },
    setMinTimeEndedFilter,
    setMaxTimeEndedFilter,
    isoDateToNumber,
    "date"
  ),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.SEVERITY.name, {
    filter: false,
    sort: false,
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const type = tableData[dataIndex][COLUMNS_ENUM.SEVERITY.order];
      let typeBGColor = "#A52222";
      let typeFontColor = "white";
      if (!type) return;
      if (type === "warning") {
        typeBGColor = "#FDB81E";
        typeFontColor = "#072036";
      }
      return (
        <Stack sx={{ width: "72px" }}>
          <Chip
            sx={{
              height: "1.5rem",
              backgroundColor: typeBGColor,
              color: typeFontColor,
              fontSize: "0.75rem",
              ".MuiChip-label": {
                px: "0.5rem",
              },
            }}
            label={alertSeverity[type]}
          />
        </Stack>
      );
    },
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.DATA.name, {
    filter: false,
    customHeadRender: () => <></>,
    customBodyRenderLite: (dataIndex) => {
      const eventData = tableData[dataIndex][COLUMNS_ENUM.DATA.order];
      switch (selectedTab) {
        case "active":
          return (
            <Stack sx={{ width: "1038px", ml: "50px" }}>
              <AlertFieldAndValue
                field="Event"
                value={eventData}
                fontSize="0.875rem"
              />
            </Stack>
          );
        case "resolved":
          return (
            <Stack sx={{ width: "1038px", ml: "32px" }}>
              <AlertFieldAndValue
                field="Event"
                value={eventData}
                fontSize="0.875rem"
              />
            </Stack>
          );
        default:
          break;
      }
    },
  }),
];

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => row?.[COLUMNS_ENUM.ID.order];
