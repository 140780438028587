import { useDataServiceSourcesCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useDataSourcesCount";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredPrefixesAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import {
  bgpRouterDataSourcesCountAtom,
  codebgpMonitorDataSourcesCountAtom,
  codebgpMonitorIPv4DataSourcesCountAtom,
  codebgpMonitorIPv6DataSourcesCountAtom,
  risLiveDataSourcesCountAtom,
  risLiveIPv4DataSourcesCountAtom,
  risLiveIPv6DataSourcesCountAtom,
  rpkiDataSourcesCountAtom,
} from "../store/overviewContent";
import {
  getCodeBGPMonitorWhereArgument,
  getRISLiveWhereArgument,
  getWhereArgument,
} from "../utils/components/dataSourcesCardSection";
import {
  BGP_ROUTER,
  CODEBGP_MONITOR,
  RIS_LIVE,
  RPKI,
} from "../constants/dataSourcesTable";

export const useDataSourcesCount = () => {
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(displayedConfiguredASNsAtom);
  const [
    {
      data: configuredPrefixes,
      fetching: configuredPrefixesFetching,
      error: configuredPrefixesError,
    },
  ] = useAtom(displayedConfiguredPrefixesAtom);

  const confState = {
    fetching: configuredASNsFetching || configuredPrefixesFetching,
    error: configuredASNsError ?? configuredPrefixesError,
  };

  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getRISLiveWhereArgument({
          ipVersion: 4,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: risLiveIPv4DataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getRISLiveWhereArgument({
          ipVersion: 6,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: risLiveIPv6DataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getWhereArgument({
          dataService: RIS_LIVE,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: risLiveDataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getWhereArgument({
          dataService: RPKI,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: rpkiDataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getCodeBGPMonitorWhereArgument({
          ipVersion: 6,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: codebgpMonitorIPv6DataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getCodeBGPMonitorWhereArgument({
          ipVersion: 4,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: codebgpMonitorIPv4DataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getWhereArgument({
          dataService: CODEBGP_MONITOR,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: codebgpMonitorDataSourcesCountAtom,
  });
  useData({
    operation: useDataServiceSourcesCountSubscription,
    options: {
      variables: {
        where: getWhereArgument({
          dataService: BGP_ROUTER,
          configuredASNs,
          configuredPrefixes,
        }),
      },
    },
    formatter,
    confState,
    atom: bgpRouterDataSourcesCountAtom,
  });
};
