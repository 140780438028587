import { useAtom } from "jotai";
import { ActiveAlertsTable } from "../components/ActiveAlertsTable";
import { alertsAtom } from "../other/store";
import { useSearchParams } from "react-router-dom";

export const ActiveAlerts = () => {
  const [{ data: alerts, fetching: alertsFetching, error: alertsError }] =
    useAtom(alertsAtom);

  const activeAlerts = alerts?.filter((item) => {
    return item.status === "firing";
  });

  const [searchParams] = useSearchParams();
  const urlParams = [];
  for (let entry of searchParams.entries()) {
    urlParams.push(entry);
  }

  return (
    <ActiveAlertsTable
      alerts={activeAlerts}
      alertsFetching={alertsFetching}
      alertsError={alertsError}
      selectedTab="active"
      urlParams={urlParams}
    />
  );
};
