import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import { DrawerButtonIconAndText } from "./DrawerButtonIconAndText";
import { addSelectionIndicator } from "../other/utils";

const DrawerDropdownTitle = ({ title, isCategorySelected }) => {
  const expandMoreIconSX = { color: "white", fontSize: "larger" };
  const accordionSummarySX = {
    color: "white",
    ":hover": {
      backgroundColor: (theme) => theme.color.other.onDrawerButtonHover,
    },
  };

  if (isCategorySelected) {
    addSelectionIndicator(accordionSummarySX);
  }

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={expandMoreIconSX} />}
      sx={accordionSummarySX}
    >
      <DrawerButtonIconAndText title={title} isSelected={isCategorySelected} />
    </AccordionSummary>
  );
};

DrawerDropdownTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isCategorySelected: PropTypes.bool.isRequired,
};

export { DrawerDropdownTitle };
