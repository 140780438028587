import { Routes, Route, Outlet } from "react-router-dom";
import { Dashboard } from "../pages/Dashboard";
import { LookingGlass } from "../pages/State";
import { NotFound } from "./NotFound";
import { AutonomousSystems } from "../pages/AutonomousSystems";
import { DataServices } from "../pages/DataServices";
import { Queries } from "../pages/Queries";
import { Editor } from "../pages/Editor";
// import { Stream } from "../pages/Stream";
import { AlertRules } from "../pages/AlertRules";
import { Settings } from "../pages/Settings";
// import { Visibility } from "../pages/Visibility";
import { Overview } from "../pages/Overview";
import { HOME_PAGE_PATH } from "../constants/paths";
import { Prefixes } from "../pages/Prefixes";
import { Integrations } from "../pages/Integrations";
import { getTenantName } from "../other/utils";
import { Alerts } from "../pages/Alerts";
import { OpenSource } from "../pages/OpenSource";


const Routing = () => {

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<NotFound redirectTo="dashboard/overview" />} />
        <Route path="dashboard" element={<Outlet />}>
          <Route index element={<NotFound redirectTo="overview" />} />
          <Route path="overview" element={<Dashboard />}>
            <Route index element={<Overview />} />
          </Route>
          <Route path="setup" element={<Dashboard />}>
            <Route index element={<NotFound redirectTo="as-filters" />} />
            <Route path="as-filters" element={<AutonomousSystems />} />
            <Route path="prefix-filters" element={<Prefixes />} />
            <Route path="alert-rules" element={<AlertRules />} />
            <Route path="data-services" element={<DataServices />} />
            <Route path="*" element={<NotFound redirectTo="as-filters" />} />
          </Route>
          <Route path="lookingglass" element={<Dashboard />}>
            <Route index element={<LookingGlass />} />
          </Route>
          <Route path="alerts" element={<Dashboard />}>
            <Route index element={<Alerts />} />
          </Route>
          <Route path="api" element={<Dashboard />}>
            <Route index element={<NotFound redirectTo="queries" />} />
            <Route path="queries" element={<Queries />} />
            <Route path="editor" element={<Editor />} />
            <Route path="*" element={<NotFound redirectTo="queries" />} />
          </Route>
          <Route path="integrations" element={<Dashboard />}>
            <Route index element={getTenantName() !== "bogons" ? <Integrations /> : <Overview />} />
          </Route>
          <Route path="opensource" element={<Dashboard />}>
            <Route index element={<OpenSource />} />
          </Route>
          {/* <Route path="alerts" element={<Dashboard />}>
            <Route index element={<NotFound redirectTo="stream" />} />
            <Route path="stream" element={<Stream />} />
            <Route path="*" element={<NotFound redirectTo="stream" />} />
          </Route> */}
          {/* <Route path="visibility" element={<Dashboard />}>
              <Route index element={<Visibility />} />
            </Route> */}
          <Route path="settings" element={<Dashboard />}>
            <Route index element={<Settings />} />
          </Route>
          <Route path="*" element={<NotFound redirectTo="overview" />} />
        </Route>
        <Route
          path="*"
          element={<NotFound redirectTo="dashboard/overview" />}
        />
      </Route>
      <Route path="*" element={<NotFound redirectTo={HOME_PAGE_PATH} />} />
    </Routes >
  );
};

export { Routing };
