import { usePeeringsCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/usePeeringsCount";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredDataServicesAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import { peeringsCountAtom } from "../store/overviewContent";

export const usePeeringsCount = () => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );
  const [
    {
      data: dataServices,
      fetching: dataServicesFetching,
      error: dataServicesError,
    },
  ] = useAtom(displayedConfiguredDataServicesAtom);

  const confState = {
    fetching: asnsFetching || dataServicesFetching,
    error: asnsError ?? dataServicesError,
  };

  useData({
    operation: usePeeringsCountSubscription,
    options: {
      variables: { asns, dataServices },
      pause: !asns,
    },
    formatter,
    confState,
    atom: peeringsCountAtom,
  });
};
