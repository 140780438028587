import { ReactElement } from "react";
import { Table } from "./Table";
import { useRole } from "../hooks/useRole";
// import { useUpdateConfiguredDataServiceFilterOnPrefixMutation } from "../graphql/generated/operations";
import { useEnableConfiguredDataServiceMutation } from "../graphql/generated/operations";
import { useUpdateConfiguredDataServiceFilterOnAsMutation } from "../graphql/generated/operations";
import {
  getColumns,
  getCustomOptions,
  getDataServices,
  getRowID,
  onViewColumnsChange,
} from "../utils/components/confDataServicesTable";
import {
  configuredDataServicesAtom,
  displayedConfiguredASNsAtom,
  hiddenDataServicesAtom,
} from "../other/store";
import { useAtom } from "jotai";
import { useInvertedToggleGroup } from "../hooks/useInvertedToggleGroup";
import {
  countAtom,
  expandedRowIDsAtom,
  isConfigurationVisibleAtom,
  isDataServiceVisibleAtom,
  isDisplayedVisibleAtom,
  isEnabledVisibleAtom,
  isFilterOnASVisibleAtom,
  pageAtom,
  rowsPerPageAtom,
} from "../store/confDataServicesTable";

/**
 * @returns {ReactElement}
 */
export const ConfDataServicesTable = () => {
  const [{ data: confData, fetching: confFetching, error: confError }] =
    useAtom(configuredDataServicesAtom);
  const [isDataServiceVisible, setIsDataServiceVisible] = useAtom(
    isDataServiceVisibleAtom
  );
  const [isConfigurationVisible, setIsConfigurationVisible] = useAtom(
    isConfigurationVisibleAtom
  );
  const [isFilterOnASVisible, setIsFilterOnASVisible] = useAtom(
    isFilterOnASVisibleAtom
  );
  const [isDisplayedVisible, setIsDisplayedVisible] = useAtom(
    isDisplayedVisibleAtom
  );
  const [isEnabledVisible, setIsEnabledVisible] = useAtom(isEnabledVisibleAtom);
  const [expandedRowIDs, setExpandedRowIDs] = useAtom(expandedRowIDsAtom);
  const [hiddenDataServices, setHiddenDataServices] = useAtom(
    hiddenDataServicesAtom
  );
  const { areAllChecked, onSelectAllChange, onSelectChange } =
    useInvertedToggleGroup({
      data: confData?.map(({ data_service }) => data_service),
      unchecked: hiddenDataServices,
      setUnchecked: setHiddenDataServices,
    });
  const [, enableConfDataService] = useEnableConfiguredDataServiceMutation();
  const [, updateConfDataServiceFilterOnAS] =
    useUpdateConfiguredDataServiceFilterOnAsMutation();
  // const [, updateConfDataServiceFilterOnPrefix] =
  //   useUpdateConfiguredDataServiceFilterOnPrefixMutation();
  const [{ data: configuredASNs }] = useAtom(displayedConfiguredASNsAtom);

  const role = useRole();

  const [count, setCount] = useAtom(countAtom);

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      setIsDataServiceVisible,
      setIsConfigurationVisible,
      setIsFilterOnASVisible,
      setIsDisplayedVisible,
      setIsEnabledVisible,
    });
  };

  const columns = getColumns({
    areAllChecked,
    onSelectAllChange,
    isDataServiceVisible,
    isConfigurationVisible,
    isFilterOnASVisible,
    isDisplayedVisible,
    isEnabledVisible,
  });

  const dataServices = getDataServices({
    confData,
    role,
    updateConfDataServiceFilterOnAS,
    enableConfDataService,
    hiddenDataServices,
    setHiddenDataServices,
    onSelectChange,
  });

  const customOptions = getCustomOptions({
    configuredASNs,
  });

  return (
    <Table
      data={dataServices}
      loading={confFetching}
      error={confError}
      columns={columns}
      customOptions={customOptions}
      pageAtom={pageAtom}
      rowsPerPageAtom={rowsPerPageAtom}
      count={count}
      setCount={setCount}
      onViewColumnsChange={onViewColumnsChangeCallback}
      getRowID={getRowID}
      expandedRowIDs={expandedRowIDs}
      setExpandedRowIDs={setExpandedRowIDs}
      displayDownloadButton={false}
    />
  );
};
