import { useAutonomousSystemsSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useASNs";
import {
  asnsTableAtom,
  displayedConfiguredASNsAtom,
  displayedConfiguredDataServicesAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import { totalNumberOfDataSourcesAtom } from "../store/overviewContent";

export const useASNs = () => {
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(displayedConfiguredASNsAtom);
  const [
    {
      data: configuredDataServices,
      fetching: configuredDataServicesFetching,
      error: configuredDataServicesError,
    },
  ] = useAtom(displayedConfiguredDataServicesAtom);
  const [{ data: totalNumberOfDataSources }] = useAtom(
    totalNumberOfDataSourcesAtom
  );
  const confState = {
    fetching: configuredASNsFetching || configuredDataServicesFetching,
    error: configuredASNsError ?? configuredDataServicesError,
  };

  useData({
    operation: useAutonomousSystemsSubscription,
    options: {
      variables: { asns: configuredASNs, dataServices: configuredDataServices },
      pause: !configuredASNs || !configuredDataServices,
    },
    formatter,
    formatterParams: [totalNumberOfDataSources],
    confState,
    atom: asnsTableAtom,
  });
};
