import { ReactElement } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  bodyCellStylesValue,
  bodyCellStylesParameter,
  headerCellStyles,
  parameterNameMap,
} from "../constants/components/argsTable";
import { formatValue, sortParameters } from "../utils/components/argsTable";
import { width } from "@mui/system";

/**
 *
 * @param {object} props
 * @param {object[]} props.rows
 * @returns {ReactElement}
 */
export const ArgsTable = ({ rows }) => {
  rows.sort((a, b) => sortParameters(a.parameter, b.parameter));

  return (
    <Table sx={{ mt: "24px" }}>
      <TableHead>
        <TableRow>
          <TableCell sx={headerCellStyles} align="center">
            Parameter
          </TableCell>
          <TableCell sx={headerCellStyles} align="center">
            Value
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ parameter, value }) => (
          <TableRow key={parameter}>
            <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
              {parameterNameMap[parameter].displayName}
            </TableCell>
            <TableCell sx={bodyCellStylesValue}>
              {formatValue(parameter, value)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
