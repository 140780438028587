import useSWR from "swr";
import { onEnvTesting } from "../utils/env";

export const useTestingSchema = () => {
  const { data, error } = useSWR(
    onEnvTesting()
      ? "https://raw.githubusercontent.com/codebgp/graphql-tools/main/schema.graphql"
      : null,
    (...args) => fetch(...args).then((res) => res.text())
  );

  let testingSchema;

  if (data) {
    testingSchema = data;
  }

  if (error) {
    console.error(error);
  }

  return {
    data: testingSchema,
    loading: !error && !data && onEnvTesting(),
    error,
  };
};
