import { useAtom } from "jotai";
import { usePathsRelatedToPrefixSubscription } from "../graphql/generated/operations";
import { displayedConfiguredASNsAtom } from "../other/store";

export const usePathsRelatedToPrefix = (prefix, hasSelectedValue) => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );
  const [{ data, fetching, error }] = usePathsRelatedToPrefixSubscription({
    variables: { asns, prefix },
    pause: !hasSelectedValue,
  });

  if (asnsError) {
    console.error(asnsError);
  }

  if (error) {
    console.error(error);
  }

  return {
    data,
    fetching: asnsFetching || fetching,
    error: asnsError ?? error,
  };
};
