import { useEffect, useState } from "react";
import { auth0HookGetter, getRoleFromUserWithFallback } from "../other/utils";

export const useRole = () => {
  const { user } = auth0HookGetter()();
  const [role, setRole] = useState("");

  useEffect(() => {
    setRole(getRoleFromUserWithFallback(user, ""));
  }, [user]);

  return role;
};
