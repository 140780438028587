import { useAtom } from "jotai";
import { ApplicationBar } from "../components/ApplicationBar";
import { ApplicationDrawer } from "../components/ApplicationDrawer";
import { Outlet } from "react-router";
import { Box } from "@mui/system";
import { WelcomeDialog } from "../components/WelcomeDialog";
import { isDrawerOpenAtom } from "../other/store";

const Dashboard = () => {
  const [isDrawerOpen] = useAtom(isDrawerOpenAtom);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.color.bg.content,
      }}
    >
      <ApplicationBar />
      <ApplicationDrawer />
      <Box
        sx={{
          backgroundColor: (theme) => theme.color.bg.content,
          position: "fixed",
          left: (theme) => (isDrawerOpen ? theme.space.width.drawer : 0),
          top: (theme) => theme.space.height.appBar,
          width: (theme) =>
            isDrawerOpen ? `calc(100% - ${theme.space.width.drawer})` : "100%",
          height: (theme) => `calc(100% - ${theme.space.height.appBar})`,
          px: (theme) => theme.space.padding.contentX,
          pt: (theme) => theme.space.padding.contentY,
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
      <WelcomeDialog />
    </Box>
  );
};

export { Dashboard };
