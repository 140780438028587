import { ReactElement, MouseEventHandler, useState, useEffect } from "react";
import { Table } from "./Table";
import { ActionButton } from "./ActionButton";
import { isEditor } from "../other/utils";
import { useAtom } from "jotai";
import { configuredASNsAtom, hiddenASNsAtom } from "../other/store";
import {
  getColumns,
  getRowID,
  getTableData,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
} from "../utils/components/confAutonomousSystemsTable";
import { useInvertedToggleGroup } from "../hooks/useInvertedToggleGroup";
import {
  countAtom,
  displayedFilterAtom,
  isDisplayedVisibleAtom,
  isNumberVisibleAtom,
  numberFilterAtom,
  pageAtom,
  rowsPerPageAtom,
  selectedRowIDsAtom,
} from "../store/confAutonomousSystemsTable";
import { usePagination } from "../hooks/usePagination";
import { Stack } from "@mui/system";
import { Snackbar } from "@mui/material";
import { Alert } from "./Alert";
import { ShareUrlButton } from "./ShareUrlButton";
import { PATHS } from "../constants/paths";
import { ImportDataButton } from "./ImportDataButton";
import { ImportAutonomousSystemsFromCSV } from "./ImportAutonomousSystemsFromCSV";
import { useOpenClose } from "../hooks/useOpenClose";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";

/**
 * @param {object} props
 * @param {string} props.role
 * @param {MouseEventHandler} props.openAddConfAutonomousSystemDialog
 * @param {Function} props.openRemoveConfAutonomousSystemDialog
 * @param {Function} props.setAsnsToRemove
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const ConfAutonomousSystemsTable = ({
  role,
  openAddConfAutonomousSystemDialog,
  openRemoveConfAutonomousSystemDialog,
  setAsnsToRemove,
  urlParams,
}) => {
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(configuredASNsAtom);
  const [numberFilter, setNumberFilter] = useAtom(numberFilterAtom);
  const [displayedFilter, setDisplayedFilter] = useAtom(displayedFilterAtom);
  const [isNumberVisible, setIsNumberVisible] = useAtom(isNumberVisibleAtom);
  const [isDisplayedVisible, setIsDisplayedVisible] = useAtom(
    isDisplayedVisibleAtom
  );
  const [selectedRowIDs, setSelectedRowIDs] = useAtom(selectedRowIDsAtom);
  const [hiddenASNs, setHiddenASNs] = useAtom(hiddenASNsAtom);
  const { areAllChecked, onSelectAllChange, onSelectChange } =
    useInvertedToggleGroup({
      data: configuredASNs,
      unchecked: hiddenASNs,
      setUnchecked: setHiddenASNs,
    });

  const {
    isOpen: isImportASesOpen,
    open: openImportASesDialog,
    close: closeImportASesDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const [copiedURL, setCopiedURL] = useState(false);

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "numberFilter":
            setNumberFilter(parseInt(item[1]));
            break;
          case "displayedFilter":
            setDisplayedFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  const addButton = (
    <ActionButton
      title="Add AS Filters"
      customStyles={{ width: "8rem" }}
      onClick={openAddConfAutonomousSystemDialog}
      enabled={isEditor(role)}
    />
  );

  /** @type {(number | undefined | ReactElement)[][]} */
  let tableData = [];

  if (configuredASNs) {
    tableData = getTableData({
      configuredASNs,
      hiddenASNs,
      setHiddenASNs,
      onSelectChange,
    });
  }

  const onRowsDelete = (rowsDeleted) => {
    const asnsToRemove = rowsDeleted.data.map(
      ({ dataIndex }) => tableData[dataIndex][0]
    );
    setAsnsToRemove(asnsToRemove);
    openRemoveConfAutonomousSystemDialog();
    setSelectedRowIDs(new Set());
    return false;
  };

  const { resetPage } = usePagination({ pageAtom, rowsPerPageAtom });
  const [count, setCount] = useAtom(countAtom);

  /**
   * @param {object} params
   * @param {Function} params.applyNewFilters
   * @returns {void}
   */
  const onApplyFiltersButtonClickCallback = ({ applyNewFilters }) => {
    onApplyFiltersButtonClick({
      applyNewFilters,
      setNumberFilter,
      setDisplayedFilter,
      resetPage,
    });
  };

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      setIsNumberVisible,
      setIsDisplayedVisible,
    });
  };

  const columns = getColumns({
    tableData,
    areAllChecked,
    onSelectAllChange,
    numberFilter,
    displayedFilter,
    isNumberVisible,
    isDisplayedVisible,
  });

  const shareUrlData = () => {
    let filters = "";
    if (numberFilter !== "" && typeof numberFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "numberFilter=" + numberFilter + "&";
    }
    if (displayedFilter !== "" && typeof displayedFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "displayedFilter=" + displayedFilter + "&";
    }

    const serviceURL = `https://${window.location.host}${PATHS.dashboard.setup.autonomousSystems}${filters}`;
    navigator.clipboard.writeText(serviceURL);
    setCopiedURL(true);
  };

  const handleClose = () => {
    setCopiedURL(false);
  };

  const HeaderElements = () => (
    <>
      <ShareUrlButton onClick={shareUrlData} toolbarIconOrder={-1} />
      <ImportDataButton onClick={openImportASesDialog} />
    </>
  );

  let importAutonomousSystemsDialog;
  let successSnackbar;
  if (isEditor(role)) {
    importAutonomousSystemsDialog = (
      <ImportAutonomousSystemsFromCSV
        isOpen={isImportASesOpen}
        close={closeImportASesDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        tableData={tableData}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <Table
        title={addButton}
        data={tableData}
        loading={configuredASNsFetching}
        error={configuredASNsError}
        columns={columns}
        customOptions={{
          selectableRowsHideCheckboxes: !isEditor(role),
          onRowsDelete,
          selectableRows: "multiple",
          responsive: "standard",
          tableBodyMaxHeight: "70vh",
          customToolbar: HeaderElements,
          textLabels: {
            body: {
              noMatch: "No AS Filters found.",
            },
          },
        }}
        pageAtom={pageAtom}
        rowsPerPageAtom={rowsPerPageAtom}
        count={count}
        setCount={setCount}
        onApplyFiltersButtonClick={onApplyFiltersButtonClickCallback}
        onViewColumnsChange={onViewColumnsChangeCallback}
        getRowID={getRowID}
        selectedRowIDs={selectedRowIDs}
        setSelectedRowIDs={setSelectedRowIDs}
        exportDataFileName={"ASFilters"}
      />
      <Snackbar
        open={copiedURL}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Share link copied to clipboard
        </Alert>
      </Snackbar>
      {importAutonomousSystemsDialog}
      {successSnackbar}
    </Stack>
  );
};
