/**
 * @param {string} field
 * @param {object?} data
 * @param {string} serviceName
 * @returns {void}
 */
export const printServiceWarning = (field, data, serviceName) => {
  console.warn(
    `${field} not found. Data from ${serviceName}: ${JSON.stringify(data)}`
  );
};
