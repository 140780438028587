import { LinearProgress, Typography } from "@mui/material";
import { SingleDataResult } from "../types/store";
import { useAtom, PrimitiveAtom } from "jotai";
import { ReactElement } from "react";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredPrefixesAtom,
} from "../other/store";

/**
 * @param {object} props
 * @param {PrimitiveAtom<SingleDataResult<number>>} props.atom
 * @param {object} [props.customProgressStyles]
 * @returns {ReactElement}
 */
export const OverviewCardCount = ({ atom, customProgressStyles }) => {
  const [{ fetching: configuredASNsFetching, error: configuredASNsError }] =
    useAtom(displayedConfiguredASNsAtom);
  const [
    { fetching: configuredPrefixesFetching, error: configuredPrefixesError },
  ] = useAtom(displayedConfiguredPrefixesAtom);
  const [{ data: count, fetching: countFetching, error: countError }] =
    useAtom(atom);

  const fetching =
    configuredASNsFetching || configuredPrefixesFetching || countFetching;
  const error = configuredASNsError ?? configuredPrefixesError ?? countError;

  if (fetching) {
    const defaultProgressStyles = {
      mt: "0.9rem",
    };
    const progressStyles = {
      ...defaultProgressStyles,
      ...customProgressStyles,
    };
    return <LinearProgress sx={progressStyles} />;
  } else if (error) {
    console.error(error);
    return <Typography>Could not fetch count.</Typography>;
  } else if (Number.isInteger(count)) {
    return (
      <Typography
        sx={{
          fontWeight: "medium",
          fontSize: 20,
        }}
      >
        {count}
      </Typography>
    );
  } else if (Number(count) === count && count % 1 !== 0) {
    //is Float
    return (
      <Typography
        sx={{
          fontWeight: "medium",
          fontSize: 20,
        }}
      >
        {Math.trunc(count)}
      </Typography>
    );
  } else {
    return <Typography>Not found.</Typography>;
  }
};
