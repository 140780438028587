export const headerCellStyles = {
  backgroundColor: "gray4.main",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "gray6.main",
  py: "0.455rem",
};

export const bodyCellStylesValue = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "gray6.main",
  py: "0.6rem",
  width: "798px"
};

export const bodyCellStylesParameter = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "gray6.main",
  py: "0.6rem",
  width: "220px"
};

export const parameterNameMap = {
  asn: { name: "asn", displayName: "AS" },
  asns: { name: "asns", displayName: "ASes" },
  prefixes: { name: "prefixes", displayName: "Prefixes" },
  ds_thres: { name: "ds_thres", displayName: "Data Sources Threshold" },
  base_service: { name: "base_service", displayName: "Get from" },
  comp_service: {
    name: "comp_service",
    displayName: "Compare with",
  },
  end_asn: { name: "end_asn", displayName: "Compare AS paths up to AS" },
  origin_asn: { name: "origin_asn", displayName: "Origin AS" },
  neighbor_asns: { name: "neighbor_asns", displayName: "Neighbor ASes" },
  rpki_status: { name: "rpki_status", displayName: "RPKI Status" },
  bogon_prefixes: { name: "bogon_prefixes", displayName: "Bogon Prefixes" },
  configured_asn: { name: "configured_asn", displayName: "Configured AS" },
  asn_regex: { name: "asn_regex", displayName: "ASes"},
  query: { name: "query", displayName: "Query"},
  query_params: { name: "query_params", displayName: "Query Parameters"},
  fire_alert_regex: { name: "fire_alert_regex", displayName: "Fire Alert Regex"},
  description: { name: "description", displayName: "Description" },
  severity: { name: "severity", displayName: "Severity" },
  bogon_as_regex: { name: "bogon_as_regex", displayName: "Bogon AS Regex" },
  asn_present_regex: { name: "asn_present_regex", displayName: "ASes" },
  as_path_regex: { name: "as_path_regex", displayName: "AS Path Pattern" },
  as_path_length_thres: { name: "as_path_length_thres", displayName: "AS Path Length Threshold" },
};

