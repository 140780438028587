import { ServerSideTable } from "../components/ServerSideTable";
import {
  getColumns,
  getCustomOptions,
  getCount,
  getRows,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  getRowID,
} from "../utils/components/routes";
import {
  routesTableASPathFilterAtom,
  routesTableCommunitiesFilterAtom,
  routesTableCountSubscriptionAtom,
  routesTableFilterStateAtom,
  routesTableIsSubscriptionActiveAtom,
  routesTableOriginASFilterAtom,
  routesTableNeighborASFilterAtom,
  routesTablePageAtom,
  routesTablePrefixFilterAtom,
  routesTableRowsPerPageAtom,
  routesTableRpkiStatusFilterAtom,
  routesTableSubscriptionAtom,
  routesTableMinTimeInsertedFilterAtom,
  routesTableMaxTimeInsertedFilterAtom,
  routesTableMinTimeUpdatedFilterAtom,
  routesTableMaxTimeUpdatedFilterAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
  isPrefixVisibleAtom,
  isOriginASVisibleAtom,
  isNeighborASVisibleAtom,
  isASPathVisibleAtom,
  isCommunitiesVisibleAtom,
  isRpkiStatusVisibleAtom,
  isTimeInsertedVisibleAtom,
  isTimeUpdatedVisibleAtom,
  expandedRowIDsAtom,
  routesTablePeerIPFilterAtom,
  routesTablePeerASFilterAtom,
} from "../store/routesTable";
import { useAtom } from "jotai";
import { filterOnASAnyAtom } from "../other/store";
import { getWhereArgument } from "../utils/components/routes";
import { RoutesDownloadServerSideTableDocument } from "../graphql/generated/operations";
import { ReactElement, useEffect } from "react";

/**
 * @param {object} props
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const Routes = ({ urlParams }) => {
  const [filterState] = useAtom(routesTableFilterStateAtom);
  const [, setPrefixFilter] = useAtom(routesTablePrefixFilterAtom);
  const [, setOriginASFilter] = useAtom(routesTableOriginASFilterAtom);
  const [, setNeighborASFilter] = useAtom(routesTableNeighborASFilterAtom);
  const [, setASPathFilter] = useAtom(routesTableASPathFilterAtom);
  const [, setCommunitiesFilter] = useAtom(routesTableCommunitiesFilterAtom);
  const [, setRpkiStatusFilter] = useAtom(routesTableRpkiStatusFilterAtom);
  const [, setPeerIPFilter] = useAtom(routesTablePeerIPFilterAtom);
  const [, setPeerASFilter] = useAtom(routesTablePeerASFilterAtom);
  const [, setMinTimeInsertedFilter] = useAtom(
    routesTableMinTimeInsertedFilterAtom
  );
  const [, setMaxTimeInsertedFilter] = useAtom(
    routesTableMaxTimeInsertedFilterAtom
  );
  const [, setMinTimeUpdatedFilter] = useAtom(
    routesTableMinTimeUpdatedFilterAtom
  );
  const [, setMaxTimeUpdatedFilter] = useAtom(
    routesTableMaxTimeUpdatedFilterAtom
  );
  const [isPrefixVisible, setIsPrefixVisible] = useAtom(isPrefixVisibleAtom);
  const [isOriginASVisible, setIsOriginASVisible] = useAtom(
    isOriginASVisibleAtom
  );
  const [isNeighborASVisible, setIsNeighborASVisible] = useAtom(
    isNeighborASVisibleAtom
  );
  const [isASPathVisible, setIsASPathVisible] = useAtom(isASPathVisibleAtom);
  const [isCommunitiesVisible, setIsCommunitiesVisible] = useAtom(
    isCommunitiesVisibleAtom
  );
  const [isRpkiStatusVisible, setIsRpkiStatusVisible] = useAtom(
    isRpkiStatusVisibleAtom
  );
  const [isTimeInsertedVisible, setIsTimeInsertedVisible] = useAtom(
    isTimeInsertedVisibleAtom
  );
  const [isTimeUpdatedVisible, setIsTimeUpdatedVisible] = useAtom(
    isTimeUpdatedVisibleAtom
  );
  const [filterOnASAny] = useAtom(filterOnASAnyAtom);

  const getWhereArgumentParams = {
    filterOnASAny,
  };

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "prefixFilter":
            setPrefixFilter(item[1]);
            break;
          case "originASFilter":
            setOriginASFilter(item[1]);
            break;
          case "neighborASFilter":
            setNeighborASFilter(item[1]);
            break;
          case "asPathFilter":
            setASPathFilter(item[1]);
            break;
          case "communitiesFilter":
            setCommunitiesFilter(item[1]);
            break;
          case "rpkiStatusFilter":
            setRpkiStatusFilter(item[1]);
            break;
          case "minTimeInsertedFilter":
            setMinTimeInsertedFilter(item[1]);
            break;
          case "maxTimeInsertedFilter":
            setMaxTimeInsertedFilter(item[1]);
            break;
          case "minTimeUpdatedFilter":
            setMinTimeUpdatedFilter(item[1]);
            break;
          case "maxTimeUpdatedFilter":
            setMaxTimeUpdatedFilter(item[1]);
            break;
          case "peerIPFilter":
            setPeerIPFilter(item[1]);
            break;
          case "peerASFilter":
            setPeerASFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  return (
    <ServerSideTable
      objectName="Routes"
      filterState={filterState}
      filterSetters={{
        setPrefixFilter,
        setOriginASFilter,
        setNeighborASFilter,
        setASPathFilter,
        setCommunitiesFilter,
        setRpkiStatusFilter,
        setPeerIPFilter,
        setPeerASFilter,
        setMinTimeInsertedFilter,
        setMaxTimeInsertedFilter,
        setMinTimeUpdatedFilter,
        setMaxTimeUpdatedFilter,
      }}
      visibleSetters={{
        setIsPrefixVisible,
        setIsOriginASVisible,
        setIsNeighborASVisible,
        setIsASPathVisible,
        setIsCommunitiesVisible,
        setIsRpkiStatusVisible,
        setIsTimeInsertedVisible,
        setIsTimeUpdatedVisible,
      }}
      getColumns={getColumns}
      getColumnsParams={{
        setMinTimeInsertedFilter,
        setMaxTimeInsertedFilter,
        setMinTimeUpdatedFilter,
        setMaxTimeUpdatedFilter,
        isPrefixVisible,
        isOriginASVisible,
        isNeighborASVisible,
        isASPathVisible,
        isCommunitiesVisible,
        isRpkiStatusVisible,
        isTimeInsertedVisible,
        isTimeUpdatedVisible,
      }}
      getCustomOptions={getCustomOptions}
      getCount={getCount}
      getRows={getRows}
      getWhereArgument={getWhereArgument}
      getWhereArgumentParams={getWhereArgumentParams}
      onApplyFiltersButtonClick={onApplyFiltersButtonClick}
      onViewColumnsChange={onViewColumnsChange}
      pageAtom={routesTablePageAtom}
      rowsPerPageAtom={routesTableRowsPerPageAtom}
      mainSubscriptionAtom={routesTableSubscriptionAtom}
      countSubscriptionAtom={routesTableCountSubscriptionAtom}
      isSubscriptionActiveAtom={routesTableIsSubscriptionActiveAtom}
      sortOrderColumnNameAtom={sortOrderColumnNameAtom}
      sortOrderDirectionAtom={sortOrderDirectionAtom}
      getRowID={getRowID}
      expandedRowIDsAtom={expandedRowIDsAtom}
      serverSideTableDataQueryDocument={RoutesDownloadServerSideTableDocument}
    />
  );
};
