import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { auth0HookGetter } from "../other/utils";

const NotFound = ({ redirectTo }) => {
  const { isLoading } = auth0HookGetter()();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      navigate(redirectTo);
    }
  });

  return <div>Page not found. Redirecting...</div>;
};

NotFound.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

export { NotFound };
