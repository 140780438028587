import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { redirectToAuth0LoginPage } from "../utils/components/selectTenant";

/**
 *
 * @param {object} params
 * @param {Function} params.setSnackbarMessage
 * @param {Function} params.setSnackbarSeverity
 * @param {Function} params.openSnackbar
 */
export const useSelectTenantEffects = ({
  setSnackbarMessage,
  setSnackbarSeverity,
  openSnackbar,
}) => {
  const { loginWithRedirect } = useAuth0();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      const parseURL = () => ({
        error: searchParams.get("error") ?? "",
        errorMessage: searchParams.get("error_description") ?? "",
        success: searchParams.get("success") ?? "",
        message: searchParams.get("message") ?? "",
        tenantName: searchParams.get("tenant") ?? "",
        initialScreen: searchParams.get("initialScreen") ?? "",
      });

      const {
        error,
        errorMessage,
        success,
        message,
        tenantName,
        initialScreen,
      } = parseURL();

      /**
       * @param {string} message
       */
      const showErrorSnackbar = (message) => {
        setSnackbarSeverity("error");
        setSnackbarMessage(message);
        openSnackbar();
      };

      /**
       * @param {string} message
       */
      const showSuccessSnackbar = (message) => {
        setSnackbarSeverity("success");
        setSnackbarMessage(message);
        openSnackbar();
      };

      if (error && errorMessage) return showErrorSnackbar(errorMessage);
      if (success === "true" && message) return showSuccessSnackbar(message);
      if (success === "false" && message) return showErrorSnackbar(message);

      await redirectToAuth0LoginPage({
        tenantName,
        loginWithRedirect,
        initialScreen,
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
