import { ReactElement } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  bodyCellStylesValue,
  bodyCellStylesParameter,
  headerCellStyles,
} from "../constants/components/argsTable";

/**
 *
 * @param {object} props
 * @param {string} props.eventData
 * @param {string} props.eventConfResources
 * @param {string} props.eventDescription
 * @returns {ReactElement}
 */
export const AlertsArgsTable = ({
  eventData,
  eventConfResources,
  eventDescription,
}) => {
  return (
    <Table sx={{ mt: "24px" }}>
      <TableHead>
        <TableRow>
          <TableCell sx={headerCellStyles} align="center" colSpan={2}>
            Event Details
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={eventData}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Event
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>{eventData}</TableCell>
        </TableRow>
        <TableRow key={eventConfResources}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Configured Resources
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>{eventConfResources}</TableCell>
        </TableRow>
        <TableRow key={eventDescription}>
          <TableCell sx={bodyCellStylesParameter} component="th" scope="row">
            Description
          </TableCell>
          <TableCell sx={bodyCellStylesValue}>{eventDescription}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
