import { TENANT_NAME_KEY } from "../../other/constants";
import { RedirectLoginOptions } from "@auth0/auth0-react";

/**
 * @param {string} tenantName
 */
const saveTenantName = (tenantName) => {
  localStorage.setItem(TENANT_NAME_KEY, tenantName);
};

/**
 * @param {string} tenantName
 * @returns {string}
 */
const getAuth0ConnectionName = (tenantName) => `${tenantName}-db`;

/**
 * @param {object} params
 * @param {string} params.tenantName
 * @param {string} [params.initialScreen]
 * @returns {RedirectLoginOptions}
 */
const getRedirectLoginOptions = ({ tenantName, initialScreen }) => {
  const connection = getAuth0ConnectionName(tenantName);

  /** @type {RedirectLoginOptions} */
  const options = { connection };

  if (initialScreen) {
    options.initialScreen = initialScreen;
  }

  return options;
};

/**
 * @param {object} params
 * @param {string} [params.tenantName]
 * @param {Function} params.loginWithRedirect
 * @param {string} [params.initialScreen]
 */
export const redirectToAuth0LoginPage = async ({
  tenantName,
  loginWithRedirect,
  initialScreen,
}) => {
  if (!tenantName) return;
  saveTenantName(tenantName);
  const options = getRedirectLoginOptions({ tenantName, initialScreen });
  await loginWithRedirect(options);
};
