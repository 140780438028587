import PropTypes from "prop-types";
import {
  COLUMNS_ENUM,
  PATHS_RELATED_TO_PREFIX_ENUM,
  SELECT_PREFIX,
} from "../constants/queries";
import {
  getGraphqlAPILink,
  getTextFieldFilterTypeColumn,
  getAnswerTableData,
  getSubscription,
} from "../other/utils";
import { QueryAnswerInputAutocomplete } from "./QueryAnswerInputAutocomplete";
import { QueryEntry } from "./QueryEntry";
import { useSelectedValue } from "../hooks/useSelectedValue";
import { getPathsRelatedToPrefix } from "../utils/queries";
import { formatASNPath } from "../utils/formatting";
import { usePathsRelatedToPrefix } from "../hooks/usePathsRelatedToPrefix";
import { useAtom } from "jotai";
import { displayedConfiguredASNsAtom } from "../other/store";
import { QueryAnswerInput } from "./QueryAnswerInput";
import { PathsRelatedToPrefixDocument } from "../graphql/generated/operations";
import { getQueryAnswerTableStateAtom } from "../utils/other/store";

export const PathsRelatedToPrefixQuery = ({ prefixesToSelect }) => {
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(displayedConfiguredASNsAtom);
  const {
    selectedValue: selectedPrefix,
    setSelectedValue: setSelectedPrefix,
    hasSelectedValue,
  } = useSelectedValue({ defaultValue: SELECT_PREFIX });
  const {
    data: pathsData,
    fetching,
    error,
  } = usePathsRelatedToPrefix(selectedPrefix, hasSelectedValue);
  const { isASPathVisibleAtom } = getQueryAnswerTableStateAtom({
    atomKey: PATHS_RELATED_TO_PREFIX_ENUM.atomKey,
  });
  const [isASPathVisible] = useAtom(isASPathVisibleAtom);

  const answerTableData = getAnswerTableData(
    hasSelectedValue,
    pathsData,
    getPathsRelatedToPrefix
  );

  const answerTableColumns = [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.AS_PATH.name, {
      display: isASPathVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASNPath(answerTableData, dataIndex, 0),
      filterOptions: {
        logic: (path, filterValue) => !path.match(filterValue),
      },
    }),
  ];

  return (
    <QueryEntry
      questionTitle={PATHS_RELATED_TO_PREFIX_ENUM.questionTitle}
      answerInput={
        <QueryAnswerInput
          title={PATHS_RELATED_TO_PREFIX_ENUM.answerTitle}
          textField={
            <QueryAnswerInputAutocomplete
              options={prefixesToSelect}
              selectedItem={selectedPrefix}
              setSelectedItem={setSelectedPrefix}
              loading={configuredASNsFetching}
              error={configuredASNsError}
            />
          }
        />
      }
      loading={fetching}
      error={error}
      hasSelectedValue={hasSelectedValue}
      answerTableData={answerTableData}
      answerTableColumns={answerTableColumns}
      graphqlAPILink={getGraphqlAPILink(
        getSubscription(PathsRelatedToPrefixDocument),
        JSON.stringify({ asns: configuredASNs, prefix: selectedPrefix })
      )}
      atomKey={PATHS_RELATED_TO_PREFIX_ENUM.atomKey}
    />
  );
};

PathsRelatedToPrefixQuery.propTypes = {
  prefixesToSelect: PropTypes.array.isRequired,
};
