export const COLUMNS_ENUM = Object.freeze({
  NAME: { order: 0, name: "Name" },
  TYPE: { order: 1, name: "Type" },
  VARS: { order: 2, name: "Variables" },
  ACTIVATION_TIME: { order: 3, name: "Activation Time" },
  ID: { order: 4, name: "ID" },
  RECEIVER_TYPE: { order: 5, name: "Notification type" },
  RECEIVER_ENDPOINT: { order: 6, name: "Notification endpoint" },
  QUERY: { order: 7, name: "Query" },
  FIRE_ALERT_REGEX: { order: 8, name: "Fire Alert Regex" },
  DESCRIPTION: { order: 9, name: "Description" },
  SEVERITY: { order: 10, name: "Severity" },
});
