import {
  BGP_ROUTER,
  RIS_LIVE,
  CODEBGP_MONITOR,
  RPKI,
  DATA_SERVICES,
  ROUTES,
  RPKI_ROAS,
  DEFAULT_COLUMNS_ENUM,
  ROUTES_COLUMNS_ENUM,
  RPKI_ROAS_COLUMNS_ENUM,
  RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM,
  BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM,
  RPKI_ROAS_DATA_SERVICE_COLUMNS_ENUM,
  COLUMNS_ENUM,
} from "../constants/dataSourcesTable";
import {
  compareTimes,
  formatDataService,
  formatTime,
  getDropdownFilterTypeColumn,
  getRangeFilterColumn,
  getTextFieldFilterTypeColumn,
  isoDateToNumber,
  parseSelectorAttributes,
} from "../other/utils";
import {
  formatASN,
  formatCountryCode,
  formatPrefix,
  formatRRC,
} from "./formatting";
import { doOnViewColumnsChange } from "./state";
import { getName } from "country-list";

const removeParenthesis = (text) => {
  return text.replace(/\s*\(.*?\)\s*/g, '');
}

const getDefaultSources = (sourcesData) => {
  const sources = [];

  sourcesData?.dataSources.forEach(
    ({ data_service, selector, dataSourceMetadatas }) => {
      const { HOST, IP, ASN } = parseSelectorAttributes(selector);
      const { city, country, continent } = dataSourceMetadatas?.[0] || {};
      const row = {
        "Data Service": data_service,
        "Route Collector": HOST,
        "Peer IP": IP,
        ASN,
        City: city !== undefined ? removeParenthesis(city) : "",
        Country: country !== undefined ? getName(country) : "",
        Continent: continent,
      };
      sources.push(row);
    }
  );

  return sources;
};

const getSourcesForDataService = (dataServiceName, sourcesData) => {
  const sources = [];

  switch (dataServiceName) {
    case RIS_LIVE:
      sourcesData?.dataSources.forEach(({ selector, dataSourceMetadatas }) => {
        const { HOST, IP, ASN } = parseSelectorAttributes(selector);
        const { city, country, continent } = dataSourceMetadatas?.[0] || {};
        const row = {
          "Route Collector": HOST,
          "Peer IP": IP,
          ASN,
          City: city !== undefined ? removeParenthesis(city) : "",
          Country: country !== undefined ? getName(country) : "",
          Continent: continent,
        };
        sources.push(row);
      });
      return sources;
    case BGP_ROUTER:
    case CODEBGP_MONITOR:
      sourcesData?.dataSources.forEach(({ selector, dataSourceMetadatas }) => {
        const { IP, ASN } = parseSelectorAttributes(selector);
        const { city, country, continent } = dataSourceMetadatas?.[0] || {};
        const row = {
          "Peer IP": IP,
          ASN,
          City: city !== undefined ? removeParenthesis(city) : "",
          Country: country !== undefined ? getName(country) : "",
          Continent: continent,
        };
        sources.push(row);
      });
      return sources;
    case RPKI:
      sourcesData?.dataSources.forEach(({ selector }) => {
        const { URI, ENDPOINT } = parseSelectorAttributes(selector);
        const row = {
          URI,
          Endpoint: ENDPOINT,
        };
        sources.push(row);
      });
      return sources;
    default:
      console.warn(
        "Data Service name not found. Getting default data source attributes."
      );
      return getDefaultSources(sourcesData);
  }
};

const getMaxDateTime = (dateTimes) => {
  if (!dateTimes || !dateTimes.length) return "";
  const maxDateTime = dateTimes.reduce(
    (dateTime1, dateTime2) =>
      isoDateToNumber(dateTime1) >= isoDateToNumber(dateTime2)
        ? dateTime1
        : dateTime2,
    0
  );
  return maxDateTime;
};

const getSourcesForRoutes = (sourcesData) => {
  const sources = [];

  sourcesData?.dataSources.forEach(
    ({
      data_service,
      selector,
      dataSourceMetadatas,
      routeDataSourceAssociations,
    }) => {
      const { HOST, IP, ASN } = parseSelectorAttributes(selector);
      const { city, country, continent } = dataSourceMetadatas?.[0] || {};
      const row = {
        "Data Service": data_service,
        "Route Collector": HOST,
        "Peer IP": IP,
        ASN,
        City: city !== undefined ? removeParenthesis(city) : "",
        Country: country !== undefined ? getName(country) : "",
        Continent: continent,
        "Last Update": getMaxDateTime(
          routeDataSourceAssociations?.map(({ data_time }) => data_time)
        ),
      };
      sources.push(row);
    }
  );

  return sources;
};

const getSourcesForRPKIROAs = (sourcesData) => {
  const sources = [];

  sourcesData?.dataSources.forEach(({ data_service, selector }) => {
    const { URI, ENDPOINT } = parseSelectorAttributes(selector);
    const row = {
      "Data Service": data_service,
      URI,
      Endpoint: ENDPOINT,
    };
    sources.push(row);
  });

  return sources;
};

export const getSources = ({ sourcesData, parentComponent }) => {
  switch (parentComponent?.name) {
    case DATA_SERVICES:
      return getSourcesForDataService(
        parentComponent.dataServiceName,
        sourcesData
      );
    case ROUTES:
      return getSourcesForRoutes(sourcesData);
    case RPKI_ROAS:
      return getSourcesForRPKIROAs(sourcesData);
    default:
      break;
  }

  return getDefaultSources(sourcesData);
};

const getDefaultColumns = (
  sources,
  isDataServiceVisible,
  isRouteCollectorVisible,
  isIPVisible,
  isASNVisible,
  isCityVisible,
  isCountryVisible,
  isContinentVisible
) => [
  getDropdownFilterTypeColumn(DEFAULT_COLUMNS_ENUM.DATA_SERVICE.name, {
    display: isDataServiceVisible,
    customBodyRender: (value) => formatDataService(value),
  }),
  getTextFieldFilterTypeColumn(DEFAULT_COLUMNS_ENUM.ROUTE_COLLECTOR.name, {
    display: isRouteCollectorVisible,
    customBodyRenderLite: (dataIndex) =>
      formatRRC(sources, dataIndex, DEFAULT_COLUMNS_ENUM.ROUTE_COLLECTOR.name),
  }),
  getTextFieldFilterTypeColumn(DEFAULT_COLUMNS_ENUM.IP.name, {
    display: isIPVisible,
    customBodyRenderLite: (dataIndex) =>
      formatPrefix(sources, dataIndex, DEFAULT_COLUMNS_ENUM.IP.name, true),
  }),
  getTextFieldFilterTypeColumn(DEFAULT_COLUMNS_ENUM.ASN.name, {
    display: isASNVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASN(sources, dataIndex, DEFAULT_COLUMNS_ENUM.ASN.name),
  }),
  getTextFieldFilterTypeColumn(DEFAULT_COLUMNS_ENUM.CITY.name, {
    display: isCityVisible,
  }),
  getTextFieldFilterTypeColumn(DEFAULT_COLUMNS_ENUM.COUNTRY.name, {
    display: isCountryVisible,
    customBodyRenderLite: (dataIndex) =>
      formatCountryCode(sources, dataIndex, DEFAULT_COLUMNS_ENUM.COUNTRY.name),
  }),
  getTextFieldFilterTypeColumn(DEFAULT_COLUMNS_ENUM.CONTINENT.name, {
    display: isContinentVisible,
  }),
];

const getColumnsForDataService = (
  dataServiceName,
  sources,
  isRouteCollectorVisible,
  isIPVisible,
  isASNVisible,
  isCityVisible,
  isCountryVisible,
  isContinentVisible,
  isURIVisible,
  isEndpointVisible
) => {
  switch (dataServiceName) {
    case RIS_LIVE:
      return [
        getTextFieldFilterTypeColumn(
          RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.ROUTE_COLLECTOR.name,
          {
            display: isRouteCollectorVisible,
            customBodyRenderLite: (dataIndex) =>
              formatRRC(
                sources,
                dataIndex,
                RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.ROUTE_COLLECTOR.name
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.IP.name,
          {
            display: isIPVisible,
            customBodyRenderLite: (dataIndex) =>
              formatPrefix(
                sources,
                dataIndex,
                RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.IP.name,
                true
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.ASN.name,
          {
            display: isASNVisible,
            customBodyRenderLite: (dataIndex) =>
              formatASN(
                sources,
                dataIndex,
                RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.ASN.name
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.CITY.name,
          {
            display: isCityVisible,
          }
        ),
        getTextFieldFilterTypeColumn(
          RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.COUNTRY.name,
          {
            display: isCountryVisible,
            customBodyRenderLite: (dataIndex) =>
              formatCountryCode(
                sources,
                dataIndex,
                DEFAULT_COLUMNS_ENUM.COUNTRY.name
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM.CONTINENT.name,
          {
            display: isContinentVisible,
          }
        ),
      ];
    case BGP_ROUTER:
    case CODEBGP_MONITOR:
      return [
        getTextFieldFilterTypeColumn(
          BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.IP.name,
          {
            display: isIPVisible,
            customBodyRenderLite: (dataIndex) =>
              formatPrefix(
                sources,
                dataIndex,
                BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.IP.name,
                true
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.ASN.name,
          {
            display: isASNVisible,
            customBodyRenderLite: (dataIndex) =>
              formatASN(
                sources,
                dataIndex,
                BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.ASN.name
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.CITY.name,
          {
            display: isCityVisible,
          }
        ),
        getTextFieldFilterTypeColumn(
          BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.COUNTRY.name,
          {
            display: isCountryVisible,
            customBodyRenderLite: (dataIndex) =>
              formatCountryCode(
                sources,
                dataIndex,
                DEFAULT_COLUMNS_ENUM.COUNTRY.name
              ),
          }
        ),
        getTextFieldFilterTypeColumn(
          BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM.CONTINENT.name,
          {
            display: isContinentVisible,
          }
        ),
      ];
    case RPKI:
      return [
        getTextFieldFilterTypeColumn(
          RPKI_ROAS_DATA_SERVICE_COLUMNS_ENUM.URI.name,
          { display: isURIVisible }
        ),
        getTextFieldFilterTypeColumn(
          RPKI_ROAS_DATA_SERVICE_COLUMNS_ENUM.ENDPOINT.name,
          { display: isEndpointVisible }
        ),
      ];
    default:
      console.warn(
        "Data Service name not found. Getting default data source attributes."
      );
      break;
  }
};

const getColumnsForRoutes = (
  sources,
  isDataServiceVisible,
  isRouteCollectorVisible,
  isIPVisible,
  isASNVisible,
  isCityVisible,
  isCountryVisible,
  isContinentVisible,
  isLastUpdateVisible
) => [
  getDropdownFilterTypeColumn(ROUTES_COLUMNS_ENUM.DATA_SERVICE.name, {
    display: isDataServiceVisible,
    customBodyRender: (value) => formatDataService(value),
  }),
  getTextFieldFilterTypeColumn(ROUTES_COLUMNS_ENUM.ROUTE_COLLECTOR.name, {
    display: isRouteCollectorVisible,
    customBodyRenderLite: (dataIndex) =>
      formatRRC(sources, dataIndex, ROUTES_COLUMNS_ENUM.ROUTE_COLLECTOR.name),
  }),
  getTextFieldFilterTypeColumn(ROUTES_COLUMNS_ENUM.IP.name, {
    display: isIPVisible,
    customBodyRenderLite: (dataIndex) =>
      formatPrefix(sources, dataIndex, ROUTES_COLUMNS_ENUM.IP.name, true),
  }),
  getTextFieldFilterTypeColumn(ROUTES_COLUMNS_ENUM.ASN.name, {
    display: isASNVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASN(sources, dataIndex, ROUTES_COLUMNS_ENUM.ASN.name),
  }),
  getTextFieldFilterTypeColumn(ROUTES_COLUMNS_ENUM.CITY.name, {
    display: isCityVisible,
  }),
  getTextFieldFilterTypeColumn(ROUTES_COLUMNS_ENUM.COUNTRY.name, {
    display: isCountryVisible,
    customBodyRenderLite: (dataIndex) =>
      formatCountryCode(sources, dataIndex, DEFAULT_COLUMNS_ENUM.COUNTRY.name),
  }),
  getTextFieldFilterTypeColumn(ROUTES_COLUMNS_ENUM.CONTINENT.name, {
    display: isContinentVisible,
  }),
  getRangeFilterColumn(
    ROUTES_COLUMNS_ENUM.LAST_UPDATE.name,
    {
      display: isLastUpdateVisible,
      customBodyRender: formatTime,
      sortCompare: compareTimes,
    },
    isoDateToNumber
  ),
];

const getColumnsForRPKIROAs = (
  sources,
  isDataServiceVisible,
  isURIVisible,
  isEndpointVisible
) => [
  getDropdownFilterTypeColumn(RPKI_ROAS_COLUMNS_ENUM.DATA_SERVICE.name, {
    display: isDataServiceVisible,
    customBodyRender: (value) => formatDataService(value),
  }),
  getTextFieldFilterTypeColumn(RPKI_ROAS_COLUMNS_ENUM.URI.name, {
    display: isURIVisible,
  }),
  getTextFieldFilterTypeColumn(RPKI_ROAS_COLUMNS_ENUM.ENDPOINT.name, {
    display: isEndpointVisible,
  }),
];

export const getColumns = ({
  sources,
  parentComponent,
  isDataServiceVisible,
  isRouteCollectorVisible,
  isIPVisible,
  isASNVisible,
  isCityVisible,
  isCountryVisible,
  isContinentVisible,
  isLastUpdateVisible,
  isURIVisible,
  isEndpointVisible,
}) => {
  switch (parentComponent?.name) {
    case DATA_SERVICES:
      return getColumnsForDataService(
        parentComponent.dataServiceName,
        sources,
        isRouteCollectorVisible,
        isIPVisible,
        isASNVisible,
        isCityVisible,
        isCountryVisible,
        isContinentVisible,
        isURIVisible,
        isEndpointVisible
      );
    case ROUTES:
      return getColumnsForRoutes(
        sources,
        isDataServiceVisible,
        isRouteCollectorVisible,
        isIPVisible,
        isASNVisible,
        isCityVisible,
        isCountryVisible,
        isContinentVisible,
        isLastUpdateVisible
      );
    case RPKI_ROAS:
      return getColumnsForRPKIROAs(
        sources,
        isDataServiceVisible,
        isURIVisible,
        isEndpointVisible
      );
    default:
      break;
  }

  return getDefaultColumns(
    sources,
    isDataServiceVisible,
    isRouteCollectorVisible,
    isIPVisible,
    isASNVisible,
    isCityVisible,
    isCountryVisible,
    isContinentVisible
  );
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsDataServiceVisible
 * @param {Function} params.setIsRouteCollectorVisible
 * @param {Function} params.setIsIPVisible
 * @param {Function} params.setIsASNVisible
 * @param {Function} params.setIsCityVisible
 * @param {Function} params.setIsCountryVisible
 * @param {Function} params.setIsContinentVisible
 * @param {Function} params.setIsLastUpdateVisible
 * @param {Function} params.setIsURIVisible
 * @param {Function} params.setIsEndpointVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsDataServiceVisible,
  setIsRouteCollectorVisible,
  setIsIPVisible,
  setIsASNVisible,
  setIsCityVisible,
  setIsCountryVisible,
  setIsContinentVisible,
  setIsLastUpdateVisible,
  setIsURIVisible,
  setIsEndpointVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.DATA_SERVICE.name] =
    setIsDataServiceVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.ROUTE_COLLECTOR.name] =
    setIsRouteCollectorVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.IP.name] = setIsIPVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.ASN.name] = setIsASNVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.CITY.name] = setIsCityVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.COUNTRY.name] = setIsCountryVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.CONTINENT.name] =
    setIsContinentVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.LAST_UPDATE.name] =
    setIsLastUpdateVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.URI.name] = setIsURIVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.ENDPOINT.name] =
    setIsEndpointVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

export const getCustomOptions = ({ parentComponent }) => {
  switch (parentComponent?.name) {
    case ROUTES:
      return { sortOrder: { name: "Last Update", direction: "desc" } };
    default:
      break;
  }
  switch (parentComponent?.dataServiceName) {
    case BGP_ROUTER:
      return {
        textLabels: {
          body: {
            noMatch: "No Router Data Sources found."
          }
        }
      }
    case CODEBGP_MONITOR:
      return {
        textLabels: {
          body: {
            noMatch: "No Code BGP Monitor Data Sources found."
          }
        }
      }
    case RIS_LIVE:
      return {
        textLabels: {
          body: {
            noMatch: "No RIS Live Data Sources found."
          }
        }
      }
    case RPKI:
      return {
        textLabels: {
          body: {
            noMatch: "No RPKI Data Sources found."
          }
        }
      }
    default:
      break;
  }
};
