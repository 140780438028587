import { useState } from "react";
import { Snackbar, Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { ConfAutonomousSystemsTable } from "../components/ConfAutonomousSystemsTable";
import { useRole } from "../hooks/useRole";
import { useOpenClose } from "../hooks/useOpenClose";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { isEditor } from "../other/utils";
import { AddConfAutonomousSystemDialog } from "../components/AddConfAutonomousSystemDialog";
import { Alert } from "../components/Alert";
import { RemoveConfAutonomousSystemDialog } from "../components/RemoveConfAutonomousSystemDialog";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { useSearchParams } from "react-router-dom";

export const AutonomousSystems = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [asnsToRemove, setAsnsToRemove] = useState([]);
  const role = useRole();
  const {
    isOpen: isAddConfAutonomousSystemDialogOpen,
    open: openAddConfAutonomousSystemDialog,
    close: closeAddConfAutonomousSystemDialog,
  } = useOpenClose();
  const {
    isOpen: isRemoveConfAutonomousSystemDialogOpen,
    open: openRemoveConfAutonomousSystemDialog,
    close: closeRemoveConfAutonomousSystemDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();

  const [searchParams] = useSearchParams();
  const urlParams = [];
  for (let entry of searchParams.entries()) {
    urlParams.push(entry);
  }

  let addConfAutonomousSystemDialog;
  let removeConfAutonomousSystemDialog;
  let successSnackbar;
  if (isEditor(role)) {
    addConfAutonomousSystemDialog = (
      <AddConfAutonomousSystemDialog
        isOpen={isAddConfAutonomousSystemDialogOpen}
        close={closeAddConfAutonomousSystemDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
      />
    );
    removeConfAutonomousSystemDialog = (
      <RemoveConfAutonomousSystemDialog
        isOpen={isRemoveConfAutonomousSystemDialogOpen}
        close={closeRemoveConfAutonomousSystemDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        asns={asnsToRemove}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.setup.autonomousSystems}
        helpText={HELP_TEXT_ENUM.setup.autonomousSystems}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <ConfAutonomousSystemsTable
        role={role}
        openAddConfAutonomousSystemDialog={openAddConfAutonomousSystemDialog}
        openRemoveConfAutonomousSystemDialog={
          openRemoveConfAutonomousSystemDialog
        }
        setAsnsToRemove={setAsnsToRemove}
        urlParams={urlParams}
      />
      {addConfAutonomousSystemDialog}
      {removeConfAutonomousSystemDialog}
      {successSnackbar}
    </Stack>
  );
};
