import { ReactElement } from "react";
import { Autocomplete, TextField } from "@mui/material";

/**
 * @param {object} props
 * @param {string[]} props.options
 * @param {string} props.selectedItem
 * @param {Function} props.setSelectedItem
 * @param {ReactElement} [props.helperText]
 * @returns {ReactElement}
 */
export const AddConfPrefixDialogAutocomplete = ({
  options,
  selectedItem,
  setSelectedItem,
  helperText,
}) => (
  <Autocomplete
    sx={{ my: "1rem" }}
    options={options}
    renderInput={(params) => (
      <TextField
        {...params}
        inputProps={{
          ...params.inputProps,
          maxLength: 100,
          style: {},
        }}
        variant="standard"
        helperText={helperText}
        FormHelperTextProps={{ component: "div" }}
      />
    )}
    value={selectedItem}
    onChange={(_event, newValue) => {
      setSelectedItem(newValue);
    }}
    disableClearable
  />
);
