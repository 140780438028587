import { ReactElement } from "react";
import {
  PrefixesFromAsnQuery,
  RpkiPrefixesFromAsnQuery,
  Prefixes_Bool_Exp,
  Routes_Bool_Exp,
} from "../../graphql/generated/operations";
import {
  ConfPrefixesResult,
  RpkiPrefixesResult,
} from "../../types/components/addConfPrefixFromDataServiceTab";
import { CircularProgress, Typography } from "@mui/material";
import { AddConfPrefixDialogSuggestedPrefixes } from "../../components/AddConfPrefixDialogSuggestedPrefixes";
import { Error, Loading } from "../../types/dataFetching";
import { getASNInPathRegexes } from "../state";

/**
 * @param {object} params
 * @param {boolean} params.hasSelectedASN
 * @param {string[]} [params.confPrefixes]
 * @param {Loading} params.confPrefixesFetching
 * @param {Error} [params.confPrefixesError]
 * @param {Set<string>} params.checked
 * @param {Function} params.setChecked
 * @param {Loading} params.allconfPrefixesFetching
 * @returns {ReactElement?}
 */
export const getSuggestedPrefixesJSX = ({
  hasSelectedASN,
  confPrefixes,
  confPrefixesFetching,
  confPrefixesError,
  checked,
  setChecked,
  allConfPrefixesFetching,
}) => {
  if (!hasSelectedASN) return null;
  if (confPrefixesFetching || allConfPrefixesFetching) {
    return <CircularProgress />;
  } else if (confPrefixesError) {
    console.error(confPrefixesError);
    return <Typography>{confPrefixesError.message}</Typography>;
  } else if (confPrefixes && confPrefixes.length) {
    return (
      <AddConfPrefixDialogSuggestedPrefixes
        prefixes={confPrefixes}
        checked={checked}
        setChecked={setChecked}
      />
    );
  }
  return (
    <Typography>
      All prefixes originated by this AS are already configured.
    </Typography>
  );
};

/**
 * @param {object} params
 * @param {PrefixesFromAsnQuery | undefined} params.prefixesFromASN
 * @returns {ConfPrefixesResult}
 */
export const getConfPrefixesResult = ({ prefixesFromASN }) => {
  const fetchedConfPrefixesNotFromRPKI = prefixesFromASN?.prefixes.map(
    ({ network }) => network
  );
  const fetchedConfPrefixesFromRPKI = prefixesFromASN?.rpkiROAs.map(
    ({ network }) => network
  );
  /** @type {string[] | undefined} */
  let fetchedConfPrefixesArray;
  if (fetchedConfPrefixesNotFromRPKI && fetchedConfPrefixesFromRPKI) {
    fetchedConfPrefixesArray = [
      ...fetchedConfPrefixesNotFromRPKI,
      ...fetchedConfPrefixesFromRPKI,
    ];
  } else if (fetchedConfPrefixesNotFromRPKI) {
    fetchedConfPrefixesArray = fetchedConfPrefixesNotFromRPKI;
  } else if (fetchedConfPrefixesFromRPKI) {
    fetchedConfPrefixesArray = fetchedConfPrefixesFromRPKI;
  } else {
    return {
      confPrefixes: undefined,
    };
  }

  const fetchedConfPrefixesSet = new Set(fetchedConfPrefixesArray);
  const confPrefixes = Array.from(fetchedConfPrefixesSet).sort();

  return { confPrefixes };
};

/**
 * @param {object} params
 * @param {RpkiPrefixesFromAsnQuery | undefined} params.rpkiPrefixesFromASN
 * @returns {RpkiPrefixesResult}
 */
export const getRPKIPrefixesResult = ({ rpkiPrefixesFromASN }) => {
  const fetchedRPKIPrefixes = rpkiPrefixesFromASN?.rpkiROAs.map(
    ({ network }) => network
  );
  if (!fetchedRPKIPrefixes) {
    return {
      rpkiPrefixes: undefined,
    };
  }

  const fetchedRPKIPrefixesSet = new Set(fetchedRPKIPrefixes);
  const rpkiPrefixes = Array.from(fetchedRPKIPrefixesSet).sort();

  return { rpkiPrefixes };
};

/**
 * @param {object} params
 * @param {string} params.asn
 * @param {boolean} params.filterOnASAny
 * @returns {Prefixes_Bool_Exp}
 */
export const getPrefixesWhereArgument = ({ asn, filterOnASAny }) => {
  /** @type {Prefixes_Bool_Exp} */
  const whereArgument = {};

  const originASNFilter = {
    originAutonomousSystem: {
      number: {
        _eq: asn,
      },
    },
  };

  /** @type {Routes_Bool_Exp[]} */
  const orArray = [originASNFilter];

  if (filterOnASAny) {
    const asnsInPathFilter = {
      as_path: {
        _regex: getASNInPathRegexes([asn]),
      },
    };
    orArray.push(asnsInPathFilter);
  }

  whereArgument.routes = { _or: orArray };

  return whereArgument;
};

/**
 * @param {object} params
 * @param {string} params.asn
 * @param {boolean} params.filterOnASAny
 * @param {object} params.allConfPrefixes
 * @returns {Prefixes_Bool_Exp}
 */
export const getFilteredPrefixesFromASWhereArgument = ({
  asn,
  filterOnASAny,
  allConfPrefixes,
}) => {
  /** @type {Prefixes_Bool_Exp} */
  const whereArgument = {};

  const originASNFilter = {
    originAutonomousSystem: {
      number: {
        _eq: asn,
      },
    },
  };

  /** @type {Routes_Bool_Exp[]} */
  const orArray = [originASNFilter];

  if (filterOnASAny) {
    const asnsInPathFilter = {
      as_path: {
        _regex: getASNInPathRegexes([asn]),
      },
    };
    orArray.push(asnsInPathFilter);
  }

  const allConfPrefixesFormatted = allConfPrefixes?.configuredPrefixes.map(
    ({ network }) => network
  );

  const confPrefixesFilter = [
    {
      prefix: {
        network: {
          _nin: allConfPrefixesFormatted,
        },
      },
    },
  ];

  whereArgument.routes = { _or: orArray, _and: confPrefixesFilter };

  return whereArgument;
};
