import { ReactElement, MouseEventHandler } from "react";
import Button from "@mui/material/Button";
import { SvgWrapper } from "./SvgWrapper";
import { Typography } from "@mui/material";
import { defaultSX } from "../constants/components/actionButton";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {object} [props.customStyles]
 * @param {MouseEventHandler} props.onClick
 * @param {string} [props.icon]
 * @param {boolean} [props.enabled]
 * @returns {ReactElement}
 */
export const ActionButton = ({
  title,
  customStyles,
  onClick,
  icon,
  enabled = true,
}) => {
  const iconJSX = icon ? (
    <SvgWrapper
      filename={icon}
      sx={{ color: "white", fontSize: 16, mr: "0.5rem" }}
    />
  ) : null;

  const buttonSX = { ...defaultSX, ...customStyles };

  const buttonJSX = (
    <Button sx={buttonSX} onClick={onClick} disabled={!enabled}>
      {iconJSX}
      <Typography fontSize="0.875rem">{title}</Typography>
    </Button>
  );

  return buttonJSX;
};
