import { useAtom } from "jotai";
import { alertsAtom } from "../other/store";
import { ResolvedAlertsTable } from "../components/ResolvedAlertsTable";
import { useSearchParams } from "react-router-dom";
import { useRole } from "../hooks/useRole";
import { useOpenClose } from "../hooks/useOpenClose";
import { useState } from "react";
import { isEditor } from "../other/utils";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { Snackbar, Stack } from "@mui/material";
import { Alert } from "../components/Alert";
import { RemoveResolvedAlertsDialog } from "../components/RemoveResolvedAlertsDialog";

export const ResolvedAlerts = () => {
  const [{ data: alerts, fetching: alertsFetching, error: alertsError }] =
    useAtom(alertsAtom);
  const [alertsToRemove, setAlertsToRemove] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const {
    isOpen: isRemoveAlertsDialogOpen,
    open: openRemoveAlertsDialog,
    close: closeRemoveAlertsDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();

  const role = useRole();

  const resolvedAlerts = alerts?.filter((item) => {
    return item.status === "resolved";
  });

  const [searchParams] = useSearchParams();
  const urlParams = [];
  for (let entry of searchParams.entries()) {
    urlParams.push(entry);
  }

  let removeAlertsDialog;
  let successSnackbar;
  if (isEditor(role)) {
    removeAlertsDialog = (
      <RemoveResolvedAlertsDialog
        isOpen={isRemoveAlertsDialogOpen}
        close={closeRemoveAlertsDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        alerts={alertsToRemove}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <ResolvedAlertsTable
        alerts={resolvedAlerts}
        alertsFetching={alertsFetching}
        alertsError={alertsError}
        selectedTab="resolved"
        urlParams={urlParams}
        role={role}
        openRemoveAlertsDialog={openRemoveAlertsDialog}
        setAlertsToRemove={setAlertsToRemove}
      />
      {removeAlertsDialog}
      {successSnackbar}
    </Stack>
  );
};
