import { useNavigate } from "react-router-dom";
import { Paper, Typography, Link } from "@mui/material";
import { PATHS } from "../constants/paths";
import { useRole } from "../hooks/useRole";
import { useAtom } from "jotai";
import { configuredASNsAtom, configuredPrefixesAtom } from "../other/store";
import { isEditor } from "../other/utils";

export const NoFiltersFlag = () => {
  const navigate = useNavigate();
  const role = useRole();
  const [{ data: configuredASNs }] = useAtom(configuredASNsAtom);
  const [{ data: configuredPrefixes }] = useAtom(configuredPrefixesAtom);

  const onClickSetup = () => {
    navigate(PATHS.dashboard.setup.index);
  };

  const message = (
    <>
      {isEditor(role) ? (
        <Typography>
          {`There are no filters configured. Go to `}
          <Link
            onClick={onClickSetup}
            sx={{
              color: (theme) => theme.color.other.selectionIndicator,
              textDecorationColor: (theme) =>
                theme.color.other.selectionIndicator,
              cursor: "pointer",
            }}
          >
            {`Setup`}
          </Link>
          {` to configure your ASes, Prefixes and Alert Rules.`}
        </Typography>
      ) : (
        <Typography>{`There are no filters configured. Contact your administrators, so they configure your AS and Prefix filters.`}</Typography>
      )}
    </>
  );

  return (configuredPrefixes && configuredPrefixes.length > 0) ||
    (configuredASNs && configuredASNs?.length > 0) ? null : (
    <Paper
      sx={{
        backgroundColor: "white",
        p: "1rem",
        mx: "2rem",
        mt: "2rem",
        width: "fit-content",
      }}
      variant="outlined"
      elevation={0}
    >
      {message}
    </Paper>
  );
};
