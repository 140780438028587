import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { QueryAnswerInput } from "../../components/QueryAnswerInput";
import { QueryAnswerInputAutocomplete } from "../../components/QueryAnswerInputAutocomplete";
import { PATHS_BETWEEN_ASNS_ENUM } from "../../constants/queries";
import { Loading, Error } from "../../types/dataFetching";

/**
 * @param {object} params
 * @param {string[]} params.myASNsToSelect
 * @param {string} params.selectedMyASN
 * @param {Function} params.setSelectedMyASN
 * @param {string[]} params.otherASNsToSelect
 * @param {string} params.selectedOtherASN
 * @param {Function} params.setSelectedOtherASN
 * @param {Loading} params.myASNLoading
 * @param {Error} params.myASNError
 * @param {Loading} params.otherASNLoading
 * @param {Error} params.otherASNError
 * @returns {ReactElement}
 */
export const getAnswerInputJSX = ({
  myASNsToSelect,
  selectedMyASN,
  setSelectedMyASN,
  otherASNsToSelect,
  selectedOtherASN,
  setSelectedOtherASN,
  myASNLoading,
  myASNError,
  otherASNLoading,
  otherASNError,
}) => (
  <Stack direction="row" spacing={2}>
    <QueryAnswerInput
      title={PATHS_BETWEEN_ASNS_ENUM.answerTitle1}
      textField={
        <QueryAnswerInputAutocomplete
          options={myASNsToSelect}
          selectedItem={selectedMyASN}
          setSelectedItem={setSelectedMyASN}
          loading={myASNLoading}
          error={myASNError}
        />
      }
    />
    <QueryAnswerInput
      title={PATHS_BETWEEN_ASNS_ENUM.answerTitle2}
      textField={
        <QueryAnswerInputAutocomplete
          options={otherASNsToSelect}
          selectedItem={selectedOtherASN}
          setSelectedItem={setSelectedOtherASN}
          loading={otherASNLoading}
          error={otherASNError}
        />
      }
    />
  </Stack>
);
