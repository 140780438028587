import { ReactElement } from "react";
import { rrcInfo } from "../../types/ripestat";
import { TooltipLinearProgress } from "../../components/TooltipLinearProgress";
import { CountryTooltip } from "../../components/CountryTooltip";
import { Box, Typography } from "@mui/material";
import { countryNameToCodeMap } from "../../constants/ripestat";
/**
 * @param {rrcInfo?} info
 * @param {boolean} infoLoading
 * @param {object} infoError
 * @returns {string|ReactElement}
 */
export const getCityJSX = (info, infoLoading, infoError) => {
  let cityJSX;

  if (infoLoading) {
    cityJSX = <TooltipLinearProgress />;
  } else if (infoError) {
    cityJSX = "Could not fetch city.";
  } else if (info && info.city) {
    cityJSX = info.city;
  } else {
    cityJSX = "Not found.";
  }

  return cityJSX;
};

/**
 * @param {rrcInfo?} info
 * @param {boolean} infoLoading
 * @param {object} infoError
 * @returns {string|ReactElement}
 */
export const getCountryJSX = (info, infoLoading, infoError) => {
  if (infoLoading) {
    return <TooltipLinearProgress />;
  } else if (infoError) {
    return "Could not fetch country.";
  } else if (info && info.country) {
    const countryCode = countryNameToCodeMap[info.country];
    if (countryCode) {
      return (
        <Box sx={{ width: "50%" }}>
          <CountryTooltip key={countryCode} countryCode={countryCode} />
        </Box>
      );
    }
  }
  return (
    <Typography
      sx={{ width: "50%", color: "blue1.main", fontSize: "0.875rem" }}
    >
      Not found.
    </Typography>
  );
};
