import { ReactElement } from "react";
// import { useNavigate } from "react-router-dom";
import { Card, Divider, Stack, Typography } from "@mui/material";
// import { ActionButton } from "./ActionButton";
import { defaultSX } from "../constants/components/overviewCard";
import { DataSourcesCardSection } from "./DataSourcesCardSection";
import {
  bgpRouterDataSourcesCountAtom,
  codebgpMonitorDataSourcesCountAtom,
  risLiveDataSourcesCountAtom,
  rpkiDataSourcesCountAtom,
} from "../store/overviewContent";
// import { PATHS } from "../constants/paths";

/**
 * @returns {ReactElement}
 */
export const DataServicesOverviewCard = () => {
  // const navigate = useNavigate();

  const cardSX = {
    ...defaultSX,
    gridRow: "span 3",
    pt: "0.5rem",
    height: "491px",
  };

  return (
    <Card sx={cardSX}>
      <Stack sx={{ justifyContent: "space-between" }}>
        <Stack>
          <Typography sx={{ fontWeight: "medium" }}>Data Services</Typography>
          <Divider sx={{ width: "16.3rem", ml: "-1.5rem", mt: "0.5rem" }} />
        </Stack>
        <Stack sx={{ py: "32px" }}>
          <DataSourcesCardSection
            icon="RISLiveIcon"
            iconSX={{ color: "green1.main" }}
            dataServiceName="RIS Live"
            atom={risLiveDataSourcesCountAtom}
          />
          <DataSourcesCardSection
            icon="RISLiveIcon"
            iconSX={{ color: "blue6.main" }}
            dataServiceName="RPKI"
            atom={rpkiDataSourcesCountAtom}
          />
          <DataSourcesCardSection
            icon="BGPRouterIcon"
            iconSX={{ color: "blue6.main" }}
            dataServiceName="My Routers"
            atom={bgpRouterDataSourcesCountAtom}
          />
          <DataSourcesCardSection
            icon="CodeBGPBrandmarkIcon"
            iconSX={{ color: "primary.main", height: "2.3rem" }}
            dataServiceName="Code BGP Monitor"
            atom={codebgpMonitorDataSourcesCountAtom}
          />
        </Stack>
        {/* <ActionButton
          customStyles={{ width: "10rem" }}
          title="View"
          onClick={() => {
            navigate(PATHS.dashboard.lookingglass.dataServices);
          }}
        /> */}
      </Stack>
    </Card>
  );
};
