import { Client, CombinedError } from "urql";
import { fromValue, makeSubject } from "wonka";
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  subscriptionExchange,
} from "urql";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ROLES,
  TENANT_NAME_KEY,
  TEST_USER_EMAIL,
  TEST_USER_FAMILY_NAME,
  TEST_USER_GIVEN_NAME,
  TEST_USER_NICKNAME,
  TEST_USER_SUB,
  theme as defaultTheme,
} from "./constants";
import { createClient as createWSClient } from "graphql-ws";
import { authExchange } from "@urql/exchange-auth";
import { makeOperation } from "@urql/core";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { onGQLClientLocal, onEnvTesting } from "../utils/env";
import { GQL_LOCAL_HOSTNAME } from "../constants/env";
import { toPascalCase, toSnakeUpperCase } from "../utils/formatting";
import {
  BGP_ROUTER,
  CODEBGP_MONITOR,
  RIS_LIVE,
  RPKI,
} from "../constants/dataSourcesTable";
// import { devtoolsExchange } from '@urql/devtools';
import { makeExecutableSchema } from "@graphql-tools/schema";
import { addMocksToSchema } from "@graphql-tools/mock";
import { graphql, DocumentNode } from "graphql";
import { faker } from "@faker-js/faker";
import { GRAPHQL_API_PATH } from "../constants/paths";
import { deepmerge } from "@mui/utils";
import { createTheme } from "@mui/material";
import {
  rangedFilterOptionsLogic,
  rangedCustomFilterListOptionsRender,
  rangedFilterOptionsDisplay,
  rangedServerSideCustomFilterListOptionsUpdate,
} from "../utils/filtering";
import { isInputNumeric } from "../utils/validation";

export const addSelectionIndicator = (sx) => {
  sx.borderLeftColor = (theme) => theme.color.other.selectionIndicator;
  sx.borderLeftStyle = "solid";
  sx.borderLeftWidth = (theme) => theme.space.width.selectionIndicator;
};

export const compensatePositionForSelectionIndicator = (sx) => {
  const negativeSelectionIndicatorWidth = (theme) =>
    `-${theme.space.width.selectionIndicator}`;
  sx.ml = negativeSelectionIndicatorWidth;
};

const auth0MockObject = {
  user: {
    sub: TEST_USER_SUB,
    nickname: TEST_USER_NICKNAME,
    given_name: TEST_USER_GIVEN_NAME,
    family_name: TEST_USER_FAMILY_NAME,
    picture: undefined,
    email: TEST_USER_EMAIL,
    "https://localhost.codebgp.com/claims": {
      user_role: ROLES.viewer,
    },
    "https://tenant.codebgp.com/claims": {
      api: GQL_LOCAL_HOSTNAME,
    },
  },
  isLoading: false,
  isAuthenticated: true,
  logout: () => {},
  getAccessTokenSilently: () => "testing mode - no token provided",
};

// Returns Auth0 hook on prod,
// mock Auth0 hook on testing.
export const auth0HookGetter = () => {
  if (onEnvTesting()) {
    return () => auth0MockObject;
  } else {
    return useAuth0;
  }
};

const getRandomDataService = () =>
  faker.helpers.arrayElement([BGP_ROUTER, CODEBGP_MONITOR, RIS_LIVE, RPKI]);

const executeMockOperation = ({ schemaWithMocks, query, variables }) => {
  const result = graphql({
    schema: schemaWithMocks,
    source: query.loc.source.body,
    variableValues: variables,
  });

  const { source, next, complete } = makeSubject();

  result.then(({ data }) => {
    setTimeout(() => {
      next({ data });
      next(complete);
    }, 1500);
  });

  return source;
};

export const createMockUrqlClient = (schemaString) => {
  const schema = makeExecutableSchema({ typeDefs: schemaString });
  const schemaWithMocks = addMocksToSchema({
    schema,
    mocks: {
      bigint: () => faker.random.numeric(5),
      uuid: () => faker.datatype.uuid(),
      cidr: () => `${faker.internet.ip()}/${24}`,
      timestamptz: () => `${faker.date.recent()}`,
      prefix: () => ({
        ip_version: 4,
        mask_length: 24,
      }),
      dataSources: () => ({
        data_service: () => getRandomDataService(),
      }),
      conf_data_adapter: () => ({
        data_service: () => getRandomDataService(),
        configuration: {},
        filter_on_autonomous_system: "origin",
        enabled: true,
      }),
      routes: () => ({
        as_path: () => `${faker.random.numeric(5)} ${faker.random.numeric(5)}`,
      }),
    },
  });

  return {
    executeSubscription: ({ query, variables }) =>
      executeMockOperation({ schemaWithMocks, query, variables }),
    executeQuery: ({ query, variables }) =>
      executeMockOperation({ schemaWithMocks, query, variables }),
    wsClient: "mock client",
  };
};

export const createUrqlErrorOnlyClient = (errorMessage) => {
  const errorGetter = () => {
    return fromValue({
      error: new CombinedError({
        networkError: Error(errorMessage),
      }),
    });
  };

  return {
    executeQuery: () => {
      return errorGetter();
    },
    executeMutation: () => {
      return errorGetter();
    },
    executeSubscription: () => {
      return errorGetter();
    },
  };
};

export const createUrqlClient = (getAccessTokenSilently, user) => {
  const getAuth = async ({ authState }) => {
    if (!authState) {
      // Load token from cache
      const token = await getAccessTokenSilently();
      // console.debug("getAuth - should load token:", token);
      return { token };
    }

    // Refresh token
    const token = await getAccessTokenSilently();
    // console.debug("getAuth - should refresh token:", token);
    return { token };
  };

  const addAuthToOperation = ({ authState, operation }) => {
    // console.debug("addAuthToOperation:", authState);
    if (!authState || !authState.token) {
      return operation;
    }

    const fetchOptions =
      typeof operation.context.fetchOptions === "function"
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${authState.token}`,
        },
      },
    });
  };

  const didAuthError = ({ error }) => {
    console.error("didAuthError:", error);
    if (error && error.networkError && error.networkError.reason) {
      if(error.networkError.reason.includes("JWTExpired") || 
          error.networkError.reason.includes("[Network]") ||
          error.networkError.reason.includes("[GraphQL]")){
          return true;
      } else {
        return false;
      }
    } else if (error && error.message) {
      if(error.message.includes("JWTExpired") ||
          error.message.includes("[Network]") || 
          error.message.includes("[GraphQL]")){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getConnectionParams = async () => {
    const accessToken = await getAccessTokenSilently();
    // console.debug("getConnectionParams - should load or refresh token:", accessToken);
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };

  let wssURL;
  let httpsURL;

  try {
    wssURL = getWSSGQLEndpoint(user);
    httpsURL = getHTTPSGQLEndpoint(user);
  } catch (error) {
    console.warn("Creating error-only (mock) urql client.");
    return createUrqlErrorOnlyClient(error.message);
  }

  const wsClient = createWSClient({
    url: wssURL,
    connectionParams: async () => await getConnectionParams(),
  });

  /** @type {Client} */
  const client = createClient({
    url: httpsURL,
    exchanges: [
      // devtoolsExchange,
      dedupExchange,
      cacheExchange,
      authExchange({
        getAuth,
        addAuthToOperation,
        didAuthError,
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) => ({
          subscribe: (sink) => ({
            unsubscribe: wsClient.subscribe(operation, sink),
          }),
        }),
      }),
    ],
    requestPolicy: "cache-and-network",
  });

  // This is for Graphiql to grab the wsClient
  client.wsClient = wsClient;

  return client;
};

export const createGraphiqlFetcher = (user, accessToken, wsClient) => {
  if (!user || !accessToken || !wsClient) {
    return null;
  }

  let url;

  try {
    url = getHTTPSGQLEndpoint(user);
  } catch (error) {
    console.error(`${error.message} - cannot create fetcher for GraphiQL`);
    return null;
  }

  return createGraphiQLFetcher({
    url,
    wsClient,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getStatePageTabs = (
  prefixesComponent,
  autonomousSystemsComponent,
  peeringsComponent,
  routesComponent,
  rpkiRoasComponent
) => [
  {
    title: "Prefixes",
    width: "6.938rem",
    component: prefixesComponent,
    hash: "prefixes",
  },
  {
    title: "Autonomous Systems",
    width: "13.375rem",
    component: autonomousSystemsComponent,
    hash: "autonomous-systems",
  },
  {
    title: "Peerings",
    width: "7.188rem",
    component: peeringsComponent,
    hash: "peerings",
  },
  {
    title: "Routes",
    width: "6rem",
    component: routesComponent,
    hash: "routes",
  },
  {
    title: "RPKI ROAs",
    width: "9.6rem",
    component: rpkiRoasComponent,
    hash: "rpki-roas",
  },
];

export const getAlertsPageTabs = (
  activeComponent,
  resolvedComponent
) => [
  {
    title: "Active",
    width: "98px",
    component: activeComponent,
    hash: "active",
  },
  {
    title: "Resolved",
    width: "160px",
    component: resolvedComponent,
    hash: "resolved",
  },
];

const getRoleFromUser = (user) => {
  const customClaim = `https://${getTenantName()}.codebgp.com/claims`;
  if (user && user[customClaim] && user[customClaim].user_role) {
    return user[customClaim].user_role;
  } else {
    throw new Error("No role found in user object.");
  }
};

export const getRoleFromUserWithFallback = (user, fallbackRole) => {
  let role;
  try {
    role = getRoleFromUser(user);
  } catch (error) {
    console.error(`${error.message} - assuming "${fallbackRole}" role`);
    role = fallbackRole;
  }
  return role;
};

export const getTenantName = () =>
  onEnvTesting() ? "localhost" : localStorage.getItem(TENANT_NAME_KEY);

export const getGQLHostnameFromUser = (user) => {
  const customClaim = "https://tenant.codebgp.com/claims";
  if (user && user[customClaim] && user[customClaim].api) {
    return user[customClaim].api;
  } else {
    throw new Error("No Graphql hostname found in user object.");
  }
};

const getGQLEndpoint = (user) => {
  const hostname = onGQLClientLocal()
    ? GQL_LOCAL_HOSTNAME
    : getGQLHostnameFromUser(user);
  return `${hostname}/graphql`;
};

const getWSSGQLEndpoint = (user) => {
  const protocol = onGQLClientLocal() ? "ws://" : "wss://";
  return `${protocol}${getGQLEndpoint(user)}`;
};

const getHTTPSGQLEndpoint = (user) => {
  const protocol = onGQLClientLocal() ? "http://" : "https://";
  return `${protocol}${getGQLEndpoint(user)}`;
};

export const createGQLEndpoint = (user) => {
  if (!user) {
    return null;
  }

  let url;

  try {
    url = getHTTPSGQLEndpoint(user);
  } catch (error) {
    console.error(`${error.message} - cannot create HTTPS GraphQL Endpoint URL`);
    return null;
  }

  return url;
}

/**
 * @param {string?} [dataService]
 * @returns {string}
 */
export const formatDataService = (dataService) => {
  switch (dataService) {
    case RIS_LIVE:
      return "RIS Live";
    case BGP_ROUTER:
      return "My Routers";
    case CODEBGP_MONITOR:
      return "Code BGP Monitor";
    case RPKI:
      return "RPKI";
    default:
      return toPascalCase(dataService);
  }
};

/**
 * @param {string?} [dataService]
 * @returns {string}
 */
export const unformatDataService = (dataService) => {
  switch (dataService) {
    case "RIS Live":
      return RIS_LIVE;
    case "My Routers":
      return BGP_ROUTER;
    case "Code BGP Monitor":
      return CODEBGP_MONITOR;
    case "RPKI":
      return RPKI;
    default:
      return toSnakeUpperCase(dataService);
  }
};

export const parseSelectorAttributes = (selector) => {
  const newSelector = {};

  // peer_ip -> IP, user_id -> ID, user -> USER, user_x_y -> USER_X_Y
  Object.entries(selector).map((entry) => {
    const parts = entry[0].split("_");
    const key = (parts.length === 2 ? parts[1] : entry[0]).toUpperCase();
    return (newSelector[key] = entry[1]);
  });

  return newSelector;
};

const getDefaultFilterTypeColumn = (columnName, customOptions) => {
  return {
    name: columnName,
    options: {
      ...customOptions,
      customFilterListOptions: getCustomFilterListOptions(columnName),
    },
  };
};

/**
 * @param {string} isoDate
 * @returns {number}
 */
export const isoDateToNumber = (isoDate) => new Date(isoDate).getTime();

export const addSecondsToTimeFilter = (timeFilter, seconds) => {
  if (timeFilter !== undefined){
    try {
      let date = new Date(timeFilter);
      date.setSeconds(date.getSeconds() + seconds);
      return date.toISOString(); 
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};

export const getRangeFilterColumn = (
  columnName,
  customOptions,
  setMinFilter,
  setMaxFilter,
  dataTransformer,
  filterMode="default"
) => {
  return {
    name: columnName,
    options: {
      filterType: "custom",
      filterOptions: {
        logic: (value, filters) =>
          rangedFilterOptionsLogic(value, filters, dataTransformer, filterMode),
        display: rangedFilterOptionsDisplay,
      },
      customFilterListOptions: {
        render: (value) =>
          rangedCustomFilterListOptionsRender(value, columnName),
        update: (filterList, filterPos, index) =>
          rangedServerSideCustomFilterListOptionsUpdate(
            filterPos,
            setMinFilter,
            setMaxFilter
          ),
      },
      ...customOptions,
    },
  };
};

export const getServerSideRangeFilterColumn = (
  columnName,
  customOptions,
  setMinFilter,
  setMaxFilter
) => {
  return {
    name: columnName,
    options: {
      filterType: "custom",
      filterOptions: {
        display: rangedFilterOptionsDisplay,
      },
      customFilterListOptions: {
        render: (value) =>
          rangedCustomFilterListOptionsRender(value, columnName),
        update: (filterList, filterPos, index) =>
          rangedServerSideCustomFilterListOptionsUpdate(
            filterPos,
            setMinFilter,
            setMaxFilter
          ),
      },
      ...customOptions,
    },
  };
};

export const getDropdownFilterTypeColumn = (columnName, customOptions) =>
  getDefaultFilterTypeColumn(columnName, {
    ...customOptions,
  });

export const getTextFieldFilterTypeColumn = (columnName, customOptions) =>
  getDefaultFilterTypeColumn(columnName, {
    ...customOptions,
    filterType: "textField",
  });

export const compareTimes =
  (order) =>
  ({ data: dateTime1 }, { data: dateTime2 }) =>
    (isoDateToNumber(dateTime1) - isoDateToNumber(dateTime2)) *
    (order === "asc" ? 1 : -1);

const getCustomFilterListOptions = (columnName) => {
  return {
    render: (filterValue) => `${columnName}: ${filterValue}`,
  };
};

/**
 * @param {DocumentNode} documentNode
 * @returns {string | undefined}
 */
export const getSubscription = (documentNode) => documentNode.loc?.source.body;

export const getGraphqlAPILink = (query, variables) => {
  const graphqlAPIURL = new URL(GRAPHQL_API_PATH, window.location.origin);
  if (query) {
    graphqlAPIURL.searchParams.append("query", query);
  }
  if (variables) {
    graphqlAPIURL.searchParams.append("variables", variables);
  }
  return graphqlAPIURL.href;
};

export const getAnswerTableData = (hasSelectedValue, data, transformData) =>
  hasSelectedValue && data ? transformData(data) : undefined;

/**
 * @returns {object}
 */
export const getOuterTableTheme = () =>
  getTableTheme({
    tableOverrides: {
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              boxShadow: "none",
            },
            tableRoot: {
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: "#e8eaeb",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              backgroundColor: "#e3e7ec",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              fontSize: "1.25rem",
            },
            actions: {
              display: 'flex',
              flexDirection: 'row',
              flex: 'initial',
            },
          },
        },
      },
    },
  });

/**
 * @returns {object}
 */
export const getInnerTableTheme = () =>
  getTableTheme({
    tableOverrides: {
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              backgroundColor: "#ffffff",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              backgroundColor: "#ffffff",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              fontSize: "1.25rem",
            },
            actions: {
              display: 'flex',
              flexDirection: 'row',
              flex: 'initial',
            },
          },
        },
      },
    },
  });

/**
 * @returns {object}
 */
export const getAlertsTableSetupTheme = () =>
  getTableTheme({
    tableOverrides: {
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              boxShadow: "none",
            },
            tableRoot: {
              backgroundColor: "unset",
            }
          },
        },
        MUIDataTableHead: {
          styleOverrides: {
            main: {
              display: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              backgroundColor: "#e3e7ec",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            root: {
              borderBottom: "unset",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              borderBottom: "unset",
              width: "30%"
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              marginTop: "0.75rem",
              borderRadius: "8px",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: "#e8eaeb",
              padding: "0.5rem",
              display: "block",
              backgroundColor: "white",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              fontSize: "1.25rem",
            },
            actions: {
              display: 'flex',
              flexDirection: 'row',
              flex: 'initial',
            },
          },
        },
      },
    },
  });

  /**
 * @returns {object}
 */
export const getActiveAlertsTableTheme = () =>
  getTableTheme({
    tableOverrides: {
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              boxShadow: "none",
            },
            tableRoot: {
              backgroundColor: "unset",
            }
          },
        },
        MUIDataTableHead: {
          styleOverrides: {
            main: {
              display: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              backgroundColor: "#e3e7ec",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            root: {
              borderBottom: "unset",
              marginTop: "17px" //for expand button
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              borderBottom: "unset",
              width: "23%"
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              marginTop: "0.75rem",
              borderRadius: "8px",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: "#e8eaeb",
              padding: "0.5rem",
              display: "flex",
              backgroundColor: "white",
              flexWrap: "wrap",
              width: "100%"
            },
          }
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              fontSize: "1.25rem",
            },
            actions: {
              display: 'flex',
              flexDirection: 'row',
              flex: 'initial',
            },
          },
        },
      },
    },
  });

/**
 * @returns {object}
 */
export const getResolvedAlertsTableTheme = () =>
  getTableTheme({
    tableOverrides: {
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              boxShadow: "none",
            },
            tableRoot: {
              backgroundColor: "unset",
            }
          },
        },
        MUIDataTableHead: {
          styleOverrides: {
            main: {
              display: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              backgroundColor: "#e3e7ec",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            root: {
              borderBottom: "unset",
              marginTop: "8px" //for expand button
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              borderBottom: "unset",
              width: "23%"
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              marginTop: "0.75rem",
              borderRadius: "8px",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: "#e8eaeb",
              padding: "0.5rem",
              display: "flex",
              backgroundColor: "white",
              flexWrap: "wrap",
              width: "100%"
            },
          }
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              fontSize: "1.25rem",
            },
            actions: {
              display: 'flex',
              flexDirection: 'row',
              flex: 'initial',
            },
          },
        },
      },
    },
  });

/**
 * @param {object} params
 * @param {object} params.tableOverrides
 * @returns {object}
 */
const getTableTheme = ({ tableOverrides }) => {
  const mergedThemes = deepmerge(defaultTheme, tableOverrides);
  return createTheme(mergedThemes);
};

export const isViewer = (role) => role === ROLES.viewer;
export const isEditor = (role) => role === ROLES.editor;

export const formatTime = (time) =>
  new Date(time).toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hourCycle: "h23"
  });

export const outerTableTheme = getOuterTableTheme();
export const innerTableTheme = getInnerTableTheme();
export const activeAlertsTableTheme = getActiveAlertsTableTheme();
export const resolvedAlertsTableTheme = getResolvedAlertsTableTheme();
export const alertsTableSetupTheme = getAlertsTableSetupTheme();


/**
 * @param {string} asRegex
 * @returns {number[]}
 */
export const regexToASes = (asRegex) => {
  var ases = new Set();
  var asMatches = asRegex.match(/\d+/g);
  asMatches?.forEach((m) => {
    ases.add(parseInt(m));
  });
  var sortedASes = Array.from(ases);
  sortedASes.sort();
  return sortedASes;
};

/**
 * @param {string[]} selectedPrefixes
 * @returns {string}
 */
export const getSelectedPrefixesGQLList = (selectedPrefixes) => {
  return "{" + selectedPrefixes.join() + "}";
};

/**
 * @param {string} selectedPrefixesStr
 * @returns {string[]}
 */
export const getSelectedPrefixesStrList = (selectedPrefixesStr) => {
  return selectedPrefixesStr.replace(/{/, "").replace(/}/, "").split(",");
};

/**
 * @param {string[]} selectedASes
 * @returns {string}
 */
export const getSelectedASesRegex = (selectedASes) => {
  var regex = "";
  for (let i = 0; i < selectedASes.length; i++) {
    if (i > 0) {
      regex += "|";
    }
    regex += "( " + selectedASes[i] + " )|(^" + selectedASes[i] + " )|( " + selectedASes[i] + "$)|(^" + selectedASes[i] + "$)";
  }
  return regex;
};