import { useEffect, useRef, useState } from "react";
import { GraphiQL as GraphiQLEditor } from "graphiql";
import { Box, Link } from "@mui/material";
import "graphiql/graphiql.min.css";
import "../other/editor-styles.css";
import { defaultEditorQuery } from "../other/constants";
import GraphiQLExplorer from "graphiql-explorer";
import { useFetcher } from "../hooks/useFetcher";
import { useSchema } from "../hooks/useSchema";
import { useSearchParams } from "react-router-dom";

export const Graphiql = () => {
  let [searchParams] = useSearchParams();

  const getDefaultQuery = () => {
    return searchParams.get("query") ?? defaultEditorQuery;
  };
  const getDefaultVariables = () => {
    return searchParams.get("variables") ?? "";
  };

  const [query, setQuery] = useState(getDefaultQuery());
  const [variables, setVariables] = useState(getDefaultVariables());
  const [explorerIsOpen, setExplorerIsOpen] = useState(true);
  const graphiql = useRef(GraphiQLEditor);
  const fetcher = useFetcher();
  const schema = useSchema(fetcher);

  const prettifyQuery = () => {
    try {
      graphiql?.current?.ref?.props.prettify();
    } catch (error) {
      console.error(error);
    }
  };

  const toggleHistory = () => {
    try {
      graphiql?.current?.ref?.props.historyContext?.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(prettifyQuery);
  }, []);

  if (!fetcher) {
    return <div>Loading...</div>;
  }

  const handleEditQuery = (query) => {
    setQuery(query);
  };
  const handleEditVariables = (variables) => {
    setVariables(variables);
  };

  const handleToggleExplorer = () => {
    setExplorerIsOpen(!explorerIsOpen);
  };

  return (
    <Box style={{ height: "calc(100vh - 7rem)" }}>
      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={handleEditQuery}
          explorerIsOpen={explorerIsOpen}
          onToggleExplorer={handleToggleExplorer}
        />
        <GraphiQLEditor
          fetcher={fetcher}
          ref={graphiql}
          schema={schema}
          query={query}
          variables={variables}
          onEditQuery={handleEditQuery}
          onEditVariables={handleEditVariables}
        >
          <GraphiQLEditor.Logo>GraphQL API | Editor</GraphiQLEditor.Logo>
          <GraphiQLEditor.Toolbar>
            <GraphiQLEditor.Button
              onClick={prettifyQuery}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQLEditor.Button
              onClick={toggleHistory}
              label="History"
              title="Show History"
            />
            <GraphiQLEditor.Button
              onClick={handleToggleExplorer}
              label="Explorer"
              title="Toggle Explorer"
            />
            <Link
              href="https://graphql-docs.codebgp.com"
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <GraphiQLEditor.Button
                onClick={() => {}}
                label="Docs"
                title="Go to Docs"
              />
            </Link>
          </GraphiQLEditor.Toolbar>
        </GraphiQLEditor>
      </div>
    </Box>
  );
};
