import { useState, ReactElement, MouseEventHandler, useEffect } from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import Papa from "papaparse";
import ErrorIcon from "@mui/icons-material/Error";
import { useInsertAlertRulesMutation } from "../graphql/generated/operations";
import {
  getASPathComparisonMutationObject,
  getASPathPresenceMutationObject,
  getBogonASMutationObject,
  getBogonExactPrefixMutationObject,
  getBogonSubPrefixMutationObject,
  getCustomMutationObject,
  getExactPrefixHijackMutationObject,
  getInvalidASPathPatternMutationObject,
  getLongASPathMutationObject,
  getNeighborLeakHijackMutationObject,
  getNewNeighborMutationObject,
  getPeeringVisibilityLossMutationObject,
  getPrefixComparisonMutationObject,
  getPrefixVisibilityLossMutationObject,
  getRPKIInvalidAnnouncementMutationObject,
  getRPKIInvalidDetectionMutationObject,
  getRPKIInvalidPropagationMutationObject,
  getRPKINotFoundPropagationMutationObject,
  getRouteLeakMutationObject,
  getSquattingMutationObject,
  getSubPrefixHijackMutationObject,
  onAddAlertRules,
} from "../utils/components/addConfAlertsDialog";
import {
  alertRuleDisplayName,
  alertRuleReceiverType,
  alertSeverity,
} from "../constants/components/confAlertsInfoTable";
import {
  formatDataService,
  getSelectedPrefixesStrList,
  regexToASes,
} from "../other/utils";
import { COLUMNS_ENUM } from "../constants/components/confAlertsTable";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {MouseEventHandler} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {object[]} props.tableData
 * @returns {ReactElement}
 */
export const ImportAlertRulesFromCSV = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  tableData,
}) => {
  const [failureReason, setFailureReason] = useState("");
  const [file, setFile] = useState("");
  const [, addAlertRulesMutation] = useInsertAlertRulesMutation();

  const clearState = () => {
    setFile("");
    setFailureReason("");
  };

  useEffect(() => {
    if (!isOpen) {
      clearState();
    }
  }, [isOpen]);

  const readCSVfile = () => {
    if (file) {
      Papa.parse(file, {
        delimiter: ",",
        header: true,
        complete: (result) => {
          const alertTableDataVars = tableData.map((item) => {
            return JSON.stringify(item[COLUMNS_ENUM.VARS.order]);
          });
          const alertTableDataQueries = tableData.map((item) => {
            return item[COLUMNS_ENUM.QUERY.order];
          });
          const alertTableDataNames = tableData.map((item) => {
            return item[COLUMNS_ENUM.NAME.order];
          });
          const alertsNotInTable = result.data.filter((alertRule) => {
            if (
              !alertTableDataVars.includes(alertRule["Variables"]) &&
              !alertTableDataQueries.includes(alertRule["QUERY"]) &&
              !alertTableDataNames.includes(alertRule["Name"])
            ) {
              return alertRule;
            }
          });
          if (alertsNotInTable.length > 0) {
            addAlertRules(alertsNotInTable);
          } else {
            setFailureReason("All Alert Rules are already configured.");
          }
        },
      });
    }
  };

  const addAlertRules = (importData) => {
    try {
      let mutationObjects = [];
      importData.map((alertRule) => {
        const varsJSONobject = JSON.parse(alertRule["Variables"]);
        switch (alertRule["Type"]) {
          case alertRuleDisplayName.unexpected_originated_prefixes: {
            const routeLeakMutationObject = getRouteLeakMutationObject({
              selectedAS: varsJSONobject["asn"],
              selectedPrefixes: varsJSONobject["prefixes"],
              selectedDataSourcesThreshold: varsJSONobject["ds_thres"],
              customAlertName: alertRule["Name"],
              selectedAlertReceiverType:
                alertRuleReceiverType[alertRule["Receiver Type"]],
              selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
            });
            mutationObjects.push(routeLeakMutationObject);
            break;
          }
          case alertRuleDisplayName.unexpected_neighbor_prefix: {
            const neighborLeakHijackMutationObject =
              getNeighborLeakHijackMutationObject({
                selectedOriginAS: varsJSONobject["origin_asn"],
                selectedNeighborASes: varsJSONobject["neighbor_asns"],
                selectedPrefixes: varsJSONobject["prefixes"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(neighborLeakHijackMutationObject);
            break;
          }
          case alertRuleDisplayName.as_origin_violation_exact: {
            const exactPrefixHijackMutationObject =
              getExactPrefixHijackMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedPrefixes: varsJSONobject["prefixes"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(exactPrefixHijackMutationObject);
            break;
          }
          case alertRuleDisplayName.as_origin_violation_sub: {
            const subPrefixHijackMutationObject =
              getSubPrefixHijackMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedPrefixes: getSelectedPrefixesStrList(
                  varsJSONobject["prefixes"]
                ),
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(subPrefixHijackMutationObject);
            break;
          }
          case alertRuleDisplayName.squatting: {
            const squattingMutationObject = getSquattingMutationObject({
              selectedPrefixes: varsJSONobject["prefixes"],
              selectedDataSourcesThreshold: varsJSONobject["ds_thres"],
              customAlertName: alertRule["Name"],
              selectedAlertReceiverType:
                alertRuleReceiverType[alertRule["Receiver Type"]],
              selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
            });
            mutationObjects.push(squattingMutationObject);
            break;
          }
          case alertRuleDisplayName.rpki_invalid_detect: {
            const rpkiInvalidDetectionMutationObject =
              getRPKIInvalidDetectionMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedRPKIPrefixes: varsJSONobject["prefixes"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(rpkiInvalidDetectionMutationObject);
            break;
          }
          case alertRuleDisplayName.rpki_invalid_announce: {
            const rpkiInvalidAnnouncementMutationObject =
              getRPKIInvalidAnnouncementMutationObject({
                selectedASes: varsJSONobject["asns"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(rpkiInvalidAnnouncementMutationObject);
            break;
          }
          case alertRuleDisplayName.rpki_invalid_propagate: {
            const rpkiInvalidPropagationMutationObject =
              getRPKIInvalidPropagationMutationObject({
                selectedASes: regexToASes(varsJSONobject["asn_regex"]).map(
                  String
                ),
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(rpkiInvalidPropagationMutationObject);
            break;
          }
          case alertRuleDisplayName.rpki_not_found_propagate: {
            const rpkiNotFoundPropagationMutationObject =
              getRPKINotFoundPropagationMutationObject({
                selectedASes: regexToASes(varsJSONobject["asn_regex"]).map(
                  String
                ),
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(rpkiNotFoundPropagationMutationObject);
            break;
          }
          case alertRuleDisplayName.unexpected_peering: {
            const newNeighborMutationObject = getNewNeighborMutationObject({
              selectedAS: varsJSONobject["configured_asn"],
              selectedNeighborASes: varsJSONobject["neighbor_asns"],
              customAlertName: alertRule["Name"],
              selectedAlertReceiverType:
                alertRuleReceiverType[alertRule["Receiver Type"]],
              selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
            });
            mutationObjects.push(newNeighborMutationObject);
            break;
          }
          case alertRuleDisplayName.bogon_prefix_exact: {
            const bogonExactPrefixMutationObject =
              getBogonExactPrefixMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedBogonPrefixes: varsJSONobject["bogon_prefixes"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(bogonExactPrefixMutationObject);
            break;
          }
          case alertRuleDisplayName.bogon_prefix_sub: {
            const bogonSubPrefixMutationObject =
              getBogonSubPrefixMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedBogonPrefixes: regexToASes(
                  varsJSONobject["bogon_prefixes"]
                ).map(String),
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(bogonSubPrefixMutationObject);
            break;
          }
          case alertRuleDisplayName.bogon_as: {
            const bogonASMutationObject = getBogonASMutationObject({
              selectedPrefixes: varsJSONobject["prefixes"],
              customAlertName: alertRule["Name"],
              selectedAlertReceiverType:
                alertRuleReceiverType[alertRule["Receiver Type"]],
              selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
            });
            mutationObjects.push(bogonASMutationObject);
            break;
          }
          case alertRuleDisplayName.as_path_diffs: {
            const asPathComparisonMutationObject =
              getASPathComparisonMutationObject({
                selectedBaseService: formatDataService(
                  varsJSONobject["base_service"]
                ),
                selectedCompService: formatDataService(
                  varsJSONobject["comp_service"]
                ),
                selectedEndASN: varsJSONobject["end_asn"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(asPathComparisonMutationObject);
            break;
          }
          case alertRuleDisplayName.prefix_diffs: {
            const prefixComparisonMutationObject =
              getPrefixComparisonMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedBaseService: formatDataService(
                  varsJSONobject["base_service"]
                ),
                selectedCompService: formatDataService(
                  varsJSONobject["comp_service"]
                ),
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(prefixComparisonMutationObject);
            break;
          }
          case alertRuleDisplayName.custom: {
            const customMutationObject = getCustomMutationObject({
              customQuery: alertRule["query"],
              customQueryParams: alertRule["vars"],
              customFireAlertRegex: alertRule["fire_alert_regex"],
              customDescription: alertRule["description"],
              customSeverity: alertSeverity[alertRule["severity"]],
              customAlertName: alertRule["name"],
              selectedAlertReceiverType:
                alertRuleReceiverType[alertRule["Receiver Type"]],
              selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
            });
            mutationObjects.push(customMutationObject);
            break;
          }
          case alertRuleDisplayName.as_path_presence: {
            const asPathPresenceMutationObject =
              getASPathPresenceMutationObject({
                selectedPresentASes: regexToASes(
                  varsJSONobject["asn_present_regex"]
                ).map(String),
                selectedPrefixes: varsJSONobject["prefixes"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(asPathPresenceMutationObject);
            break;
          }
          case alertRuleDisplayName.as_path_regex_violation: {
            const invalidASPathPatternMutationObject =
              getInvalidASPathPatternMutationObject({
                selectedASPathRegex: varsJSONobject["as_path_regex"],
                selectedPrefixes: varsJSONobject["prefixes"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(invalidASPathPatternMutationObject);
            break;
          }
          case alertRuleDisplayName.low_prefix_visibility: {
            const prefixVisibilityLossMutationObject =
              getPrefixVisibilityLossMutationObject({
                selectedASes: varsJSONobject["asns"],
                selectedPrefixes: varsJSONobject["prefixes"],
                selectedDataSourcesThreshold: varsJSONobject["ds_thres"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(prefixVisibilityLossMutationObject);
            break;
          }
          case alertRuleDisplayName.low_peering_visibility: {
            const peeringVisibilityLossMutationObject =
              getPeeringVisibilityLossMutationObject({
                selectedAS: varsJSONobject["configured_asn"],
                selectedNeighborASes: varsJSONobject["neighbor_asns"],
                selectedDataSourcesThreshold: varsJSONobject["ds_thres"],
                customAlertName: alertRule["Name"],
                selectedAlertReceiverType:
                  alertRuleReceiverType[alertRule["Receiver Type"]],
                selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
              });
            mutationObjects.push(peeringVisibilityLossMutationObject);
            break;
          }
          case alertRuleDisplayName.as_path_length_violation: {
            const longASPathMutationObject = getLongASPathMutationObject({
              selectedASPathLengthThreshold:
                varsJSONobject["as_path_length_thres"],
              selectedPrefixes: varsJSONobject["prefixes"],
              customAlertName: alertRule["Name"],
              selectedAlertReceiverType:
                alertRuleReceiverType[alertRule["Receiver Type"]],
              selectedAlertReceiverEndpoint: alertRule["Receiver Endpoint"],
            });
            mutationObjects.push(longASPathMutationObject);
            break;
          }
          default:
            break;
        }
      });
      onAddAlertRules({
        mutationObject: mutationObjects,
        addAlertRules: addAlertRulesMutation,
        setFailureReason,
        setSnackbarMessage,
        openSuccessSnackbar,
        onClose: close,
      });
    } catch (error) {
      setFailureReason(error.message);
    }
  };

  const failureMessage = failureReason ? (
    <Stack
      sx={{ color: "#D2393D", width: "17rem", justifyContent: "flex-start" }}
      direction="row"
    >
      <ErrorIcon sx={{ height: "1.05rem" }} />
      <Typography fontSize="0.75rem">{failureReason}</Typography>
    </Stack>
  ) : undefined;

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{
          p: "2rem",
          height: "16rem",
          minwidth: "20rem",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          Import Alert Rules CSV File
        </Typography>
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />
        </form>
        {failureMessage}
        <Stack sx={{ justifyContent: "space-evenly" }} direction="row">
          <Button
            sx={{
              color: "primary.main",
              textTransform: "none",
              width: "6rem",
              height: "2.5rem",
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: (theme) => theme.color.bg.selectedDrawerButton,
            }}
            onClick={close}
          >
            <Typography noWrap fontSize="0.875rem">
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              width: "6.7rem",
              height: "2.5rem",
              backgroundColor: "primary.main",
              ":hover": {
                backgroundColor: (theme) => theme.color.button.onHover.action,
              },
              ":disabled": {
                backgroundColor: "gray1.main",
              },
            }}
            onClick={readCSVfile}
          >
            <Typography noWrap fontSize="0.875rem">
              Import
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
