import { useState, ReactElement, MouseEventHandler, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isASNInputArrayValid } from "../utils/validation";
import ErrorIcon from "@mui/icons-material/Error";
import { useInsertManuallyConfiguredAutonomousSystemsMutation } from "../graphql/generated/operations";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {MouseEventHandler} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @returns {ReactElement}
 */
export const AddConfAutonomousSystemDialog = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
}) => {
  const [asnInput, setAsnInput] = useState([]);
  const [isValidAsnInput, setIsValidAsnInput] = useState(true);
  const [failureReason, setFailureReason] = useState("");
  const [, addConfASNS] =
    useInsertManuallyConfiguredAutonomousSystemsMutation();

  const clearState = () => {
    setAsnInput([]);
    setIsValidAsnInput(true);
    setFailureReason("");
  };

  useEffect(() => {
    if (!isOpen) {
      clearState();
    }
  }, [isOpen]);

  const onTextFieldChange = (value) => {
    setAsnInput(value);
    setIsValidAsnInput(isASNInputArrayValid(value));
    setFailureReason("");
  };

  const onAddASNs = async () => {
    const ASNS = asnInput.map((asn) => {
      return { number: asn };
    });
    const result = await addConfASNS({ asns: ASNS });
    // TODO improve response handling (change mutation to return added ASN and print that)
    if (result.data) {
      setSnackbarMessage("AS(es) added successfully");
      openSuccessSnackbar();
      close();
    } else {
      if (result.error.message.includes("Uniqueness violation")) {
        setFailureReason("Some ASNs already exist. Operation aborted.");
      } else {
        setFailureReason(result.error.message);
      }
    }
  };

  const failureMessage = failureReason ? (
    <Stack
      sx={{ width: "17rem", justifyContent: "flex-start" }}
      direction="row"
    >
      <ErrorIcon sx={{ height: "1.05rem" }} />
      <Typography fontSize="0.75rem">{failureReason}</Typography>
    </Stack>
  ) : undefined;

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{
          p: "2rem",
          height: "16rem",
          minwidth: "20rem",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          Add AS Filters
        </Typography>
        <Autocomplete
          multiple={true}
          key="select-ASes"
          freeSolo={true}
          options={[]}
          value={asnInput}
          onChange={(event, newValue) => {
            onTextFieldChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                maxLength: 50,
                style: {},
              }}
              autoFocus
              error={!!failureReason}
              placeholder="Your AS Filters"
              variant="standard"
              helperText={
                failureMessage ? (
                  <>
                    {failureMessage}
                    {isValidAsnInput ? (
                      <Typography
                        sx={{ color: "primary.main" }}
                        fontSize="0.875rem"
                      >
                        Add your AS(es) in numeric format.
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#D2393D" }} fontSize="0.875rem">
                        Please add your AS(es) in numeric format.
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "primary.main" }}
                      fontSize="0.875rem"
                    >
                      Press ENTER to input each value.
                    </Typography>
                  </>
                ) : (
                  <>
                    {isValidAsnInput ? (
                      <Typography
                        sx={{ color: "primary.main" }}
                        fontSize="0.875rem"
                      >
                        Add your AS(es) in numeric format.
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#D2393D" }} fontSize="0.875rem">
                        Please add your AS(es) in numeric format.
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "primary.main" }}
                      fontSize="0.875rem"
                    >
                      Press ENTER to input each value.
                    </Typography>
                  </>
                )
              }
            ></TextField>
          )}
        />
        <Stack sx={{ justifyContent: "space-evenly" }} direction="row">
          <Button
            sx={{
              color: "primary.main",
              textTransform: "none",
              width: "6rem",
              height: "2.5rem",
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: (theme) => theme.color.bg.selectedDrawerButton,
            }}
            onClick={close}
          >
            <Typography noWrap fontSize="0.875rem">
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              width: "6.7rem",
              height: "2.5rem",
              backgroundColor: "primary.main",
              ":hover": {
                backgroundColor: (theme) => theme.color.button.onHover.action,
              },
              ":disabled": {
                backgroundColor: "gray1.main",
              },
            }}
            disabled={!isASNInputArrayValid(asnInput)}
            onClick={onAddASNs}
          >
            <Typography noWrap fontSize="0.875rem">
              Add AS Filters
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
