import { useEffect, useState } from "react";
import { buildClientSchema, getIntrospectionQuery } from "graphql";
import { onEnvTesting } from "../utils/env";

export const useSchema = (fetcher) => {
  const [schema, setSchema] = useState();

  useEffect(() => {
    (async () => {
      let result;
      if (fetcher) {
        try {
          result = await fetcher({
            query: getIntrospectionQuery(),
            operationName: "IntrospectionQuery",
          });
          setSchema(buildClientSchema(result.data));
        } catch (error) {
          if (!onEnvTesting()) {
            console.error(error.message);
          }
          setSchema();
        }
      }
    })();
  }, [fetcher, setSchema]);

  return schema;
};
