import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { QueryAnswerInput } from "../../components/QueryAnswerInput";
import { QueryAnswerInputAutocomplete } from "../../components/QueryAnswerInputAutocomplete";
import { NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM } from "../../constants/queries";
import { Loading, Error } from "../../types/dataFetching";

/**
 * @param {object} params
 * @param {string[]} params.prefixesToSelect
 * @param {string} params.selectedPrefix
 * @param {Function} params.setSelectedPrefix
 * @param {Loading} params.configuredASNsFetching
 * @param {Error} params.configuredASNsError
 * @returns {ReactElement}
 */
export const getAnswerInputJSX = ({
  prefixesToSelect,
  selectedPrefix,
  setSelectedPrefix,
  configuredASNsFetching,
  configuredASNsError,
}) => (
  <Stack direction="row" spacing={2}>
    <QueryAnswerInput
      title={NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM.answerTitle1}
      textField={
        <QueryAnswerInputAutocomplete
          options={prefixesToSelect}
          selectedItem={selectedPrefix}
          setSelectedItem={setSelectedPrefix}
          loading={configuredASNsFetching}
          error={configuredASNsError}
        />
      }
    />
    <QueryAnswerInput
      title={NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM.answerTitle2}
    />
  </Stack>
);

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => {
  const asn1 = row[0];
  const asn2 = row[1];
  return `${asn1}-${asn2}`;
};
