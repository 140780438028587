import { ReactElement, useEffect, useState } from "react";
import { Close } from "../types/components/dialog";
import { Stack } from "@mui/material";
import { onAddPrefixes } from "../utils/components/addConfPrefixDialog";
import { AddConfPrefixDialogAutocompletes } from "./AddConfPrefixDialogAutocompletes";
import { ButtonStrip } from "./ButtonStrip";
import {
  useInsertManuallyConfiguredPrefixesMutation,
  usePrefixesFromAsNnotConfiguredQuery,
  useConfiguredPrefixesSubscription,
} from "../graphql/generated/operations";
import { InputFailureMessage } from "./InputFailureMessage";
import {
  getConfPrefixesResult,
  getFilteredPrefixesFromASWhereArgument,
  getSuggestedPrefixesJSX,
} from "../utils/components/addConfPrefixFromDataServiceTab";
import { filterOnASAnyAtom } from "../other/store";
import { useAtom } from "jotai";

/**
 * @param {object} props
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {string} props.selectedASN
 * @param {Function} props.setSelectedASN
 * @param {boolean} props.hasSelectedASN
 * @param {string} props.failureReason
 * @param {Function} props.setFailureReason
 * @returns {ReactElement}
 */
export const AddConfPrefixFromDataServiceTab = ({
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  selectedASN,
  setSelectedASN,
  hasSelectedASN,
  failureReason,
  setFailureReason,
}) => {
  const [, addConfPrefixes] = useInsertManuallyConfiguredPrefixesMutation();
  const [filterOnASAny] = useAtom(filterOnASAnyAtom);

  const [
    {
      data: allConfPrefixes,
      fetching: allConfPrefixesFetching,
      error: allConfPrefixesError,
    },
  ] = useConfiguredPrefixesSubscription();

  const [
    {
      data: prefixesFromASN,
      fetching: prefixesFromASNFetching,
      error: prefixesFromASNError,
    },
  ] = usePrefixesFromAsNnotConfiguredQuery({
    variables: {
      asn: selectedASN,
      prefixesWhere: getFilteredPrefixesFromASWhereArgument({
        asn: selectedASN,
        filterOnASAny,
        allConfPrefixes,
      }),
      prefixArray: allConfPrefixes?.configuredPrefixes.map(
        ({ network }) => network
      ),
    },
    pause: !hasSelectedASN,
  });

  const { confPrefixes } = getConfPrefixesResult({
    prefixesFromASN,
  });

  const [checked, setChecked] = useState(new Set());

  /** @type {object[]} */
  const checkedPrefixes = [];
  checked.forEach((prefix) => {
    checkedPrefixes.push({ network: prefix });
  });

  const onAddPrefixesCallback = () =>
    onAddPrefixes({
      confPrefixes: checkedPrefixes,
      addConfPrefixes,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      close,
    });

  const suggestedPrefixesJSX = getSuggestedPrefixesJSX({
    hasSelectedASN,
    confPrefixes,
    confPrefixesFetching: prefixesFromASNFetching,
    confPrefixesError: prefixesFromASNError,
    checked,
    setChecked,
    allConfPrefixesFetching: allConfPrefixesFetching,
  });

  const atLeastOneChecked = checked.size > 0;

  const isMainButtonEnabled =
    (hasSelectedASN &&
      confPrefixes &&
      !!confPrefixes.length &&
      !prefixesFromASNFetching &&
      !prefixesFromASNError &&
      !allConfPrefixesFetching &&
      !allConfPrefixesError &&
      atLeastOneChecked) ??
    false;

  return (
    <Stack>
      <AddConfPrefixDialogAutocompletes
        selectedASN={selectedASN}
        setSelectedASN={setSelectedASN}
        errorMessage={<InputFailureMessage failureReason={failureReason} />}
      />
      {suggestedPrefixesJSX}
      <ButtonStrip
        onSecondaryButtonClick={close}
        onMainButtonClick={onAddPrefixesCallback}
        mainButtonTitle="Add Prefix Filters"
        mainButtonEnabled={isMainButtonEnabled}
        buttonStripCustomStyles={{ mt: "3rem" }}
      />
    </Stack>
  );
};
