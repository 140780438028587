import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";

/**
 *
 * @param {object} props
 * @param {string} props.field
 * @param {string} props.value
 * @param {string} [props.fontSize]
 * @returns {ReactElement}
 */
export const AlertFieldAndValue = ({ field, value, fontSize = "13px" }) => {
  return (
    <Stack
      sx={{
        maxWidth: "100rem",
      }}
      direction="row"
    >
      <Typography sx={{ fontWeight: "medium", fontSize }}>{field}:</Typography>
      <Typography
        sx={{
          ml: "0.2rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};
