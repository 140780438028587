import { Close } from "../types/components/dialog";
import { ReactElement, useState } from "react";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { ButtonStrip } from "./ButtonStrip";
import { isPrefixArrayValid } from "../utils/validation";
import { onAddPrefixes } from "../utils/components/addConfPrefixDialog";
import { useInsertManuallyConfiguredPrefixesMutation } from "../graphql/generated/operations";
import { InputFailureMessage } from "./InputFailureMessage";
import { forbidden_prefixes } from "../constants/components/prefixes";
import { getTenantName } from "../other/utils";

/**
 * @param {object} props
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @param {object[]} props.prefixInput
 * @param {Function} props.setPrefixInput
 * @param {string} props.failureReason
 * @param {Function} props.setFailureReason
 * @returns {ReactElement}
 */
export const AddConfPrefixManuallyTab = ({
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
  prefixInput,
  setPrefixInput,
  failureReason,
  setFailureReason,
}) => {
  const [, addConfPrefixes] = useInsertManuallyConfiguredPrefixesMutation();
  const failureMessage = <InputFailureMessage failureReason={failureReason} />;
  const [isValidPrefixInput, setIsValidPrefixInput] = useState(true);

  const onTextFieldChange = (value) => {
    setPrefixInput(value);
    setIsValidPrefixInput(isPrefixArrayValid(value));
    setFailureReason("");
    value.forEach((item) => {
      if (forbidden_prefixes.includes(item) && getTenantName() !== "bogons") {
        setIsValidPrefixInput(false);
        setFailureReason(item + " is a forbidden prefix filter value");
        return;
      }
    });
  };

  const onAddPrefixCallback = () => {
    onAddPrefixes({
      confPrefixes: prefixInput.map((prefix) => {
        return { network: prefix };
      }),
      addConfPrefixes,
      setFailureReason,
      setSnackbarMessage,
      openSuccessSnackbar,
      close,
    });
  };

  return (
    <Stack>
      <Stack>
        <Autocomplete
          multiple={true}
          key="select-prefixes"
          freeSolo={true}
          options={[]}
          value={prefixInput}
          onChange={(event, newValue) => {
            onTextFieldChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                maxLength: 50,
                style: {},
              }}
              sx={{ mt: "2rem" }}
              autoFocus
              error={!!failureReason}
              placeholder="Your Prefix Filters"
              variant="standard"
              helperText={
                failureMessage ? (
                  <>
                    {failureMessage}
                    {isValidPrefixInput ? (
                      <Typography
                        sx={{ color: "primary.main" }}
                        fontSize="0.875rem"
                      >
                        Add your Prefix(es) in CIDR format.
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#D2393D" }} fontSize="0.875rem">
                        Please add your Prefix(es) in CIDR format.
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "primary.main" }}
                      fontSize="0.875rem"
                    >
                      Press ENTER to input each value.
                    </Typography>
                  </>
                ) : (
                  <>
                    {isValidPrefixInput ? (
                      <Typography
                        sx={{ color: "primary.main" }}
                        fontSize="0.875rem"
                      >
                        Add your Prefix(es) in CIDR format.
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#D2393D" }} fontSize="0.875rem">
                        Please add your Prefix(es) in CIDR format.
                      </Typography>
                    )}
                    <Typography
                      sx={{ color: "primary.main" }}
                      fontSize="0.875rem"
                    >
                      Press ENTER to input each value.
                    </Typography>
                  </>
                )
              }
            ></TextField>
          )}
        />
      </Stack>
      <ButtonStrip
        onSecondaryButtonClick={close}
        onMainButtonClick={onAddPrefixCallback}
        mainButtonTitle="Add Prefix Filters"
        mainButtonEnabled={isValidPrefixInput}
        buttonStripCustomStyles={{ mt: "3rem" }}
      />
    </Stack>
  );
};
