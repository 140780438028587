import { useBgpNeighborsSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useNeighbors";
import { displayedConfiguredASNsAtom, neighborsAtom } from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";

export const useNeighbors = () => {
  const [{ data: asns }] = useAtom(displayedConfiguredASNsAtom);

  useData({
    operation: useBgpNeighborsSubscription,
    options: {
      variables: { asns },
      pause: !asns,
    },
    formatter,
    formatterParams: [asns],
    atom: neighborsAtom,
  });
};
