import { useRef } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";
import { ReactComponent as MenuIconSvg } from "../assets/svg/MenuIcon.svg";
import { ReactComponent as CodeBGPLogoSvg } from "../assets/svg/CodeBGPLogo.svg";
import { ReactComponent as SetupIconSvg } from "../assets/svg/SetupIcon.svg";
import { ReactComponent as LookingGlassIconSvg } from "../assets/svg/LookingGlassIcon.svg";
import { ReactComponent as APIIconSvg } from "../assets/svg/APIIcon.svg";
import { ReactComponent as AlertsIconSvg } from "../assets/svg/AlertsIcon.svg";
// import { ReactComponent as VisibilityIconSvg } from "../assets/svg/VisibilityIcon.svg";
import { ReactComponent as SettingsIconSvg } from "../assets/svg/SettingsIcon.svg";
import { ReactComponent as DocsIconSvg } from "../assets/svg/DocsIcon.svg";
import { ReactComponent as SearchIconSvg } from "../assets/svg/SearchIcon.svg";
import { ReactComponent as DownloadCSVIconSvg } from "../assets/svg/DownloadCSVIcon.svg";
import { ReactComponent as UploadCSVIconSvg } from "../assets/svg/UploadCSVIcon.svg";
import { ReactComponent as PrintIconSvg } from "../assets/svg/PrintIcon.svg";
import { ReactComponent as ViewColumnsIconSvg } from "../assets/svg/ViewColumnsIcon.svg";
import { ReactComponent as FilterTableIconSvg } from "../assets/svg/FilterTableIcon.svg";
import { ReactComponent as DropdownArrowIconSvg } from "../assets/svg/DropdownArrowIcon.svg";
import { ReactComponent as NewTabIconSvg } from "../assets/svg/NewTabIcon.svg";
import { ReactComponent as RipeNccLogoWhiteSvg } from "../assets/svg/RipeNccLogoWhite.svg";
import { ReactComponent as RipeNccLogoSvg } from "../assets/svg/RipeNccLogo.svg";
import { ReactComponent as OverviewIconSvg } from "../assets/svg/OverviewIcon.svg";
import { ReactComponent as SmileyIconSvg } from "../assets/svg/SmileyIcon.svg";
import { ReactComponent as QuestionMarkIconSvg } from "../assets/svg/QuestionMarkIcon.svg";
import { ReactComponent as RISLiveIconSvg } from "../assets/svg/RISLiveIcon.svg";
import { ReactComponent as BGPRouterIconSvg } from "../assets/svg/BGPRouterIcon.svg";
import { ReactComponent as CodeBGPBrandmarkIconSvg } from "../assets/svg/CodeBGPBrandmarkIcon.svg";
import { ReactComponent as EditIconSvg } from "../assets/svg/EditIcon.svg";
import { ReactComponent as DashboardsIconSvg } from "../assets/svg/DashboardsIcon.svg";
import { ReactComponent as SpeedIconSvg } from "../assets/svg/SpeedIcon.svg";
import { ReactComponent as IntegrationsIconSvg } from "../assets/svg/IntegrationsIcon.svg";
import { ReactComponent as AlertmanagerIconSvg } from "../assets/svg/AlertmanagerIcon.svg";
import { ReactComponent as GrafanaIconSvg } from "../assets/svg/GrafanaIcon.svg";
import { ReactComponent as PrometheusIconSvg } from "../assets/svg/PrometheusIcon.svg";
import { ReactComponent as LokiIconSvg } from "../assets/svg/LokiIcon.svg";
import { ReactComponent as SortByIconSvg } from "../assets/svg/SortByIcon.svg";
import { ReactComponent as ShareIconSvg } from "../assets/svg/ShareIcon.svg";

import { ReactComponent as OpenSourceIconSvg } from "../assets/svg/OpenSourceIcon.svg";
import { ReactComponent as MoreIconSvg } from "../assets/svg/MoreIcon.svg";

const SvgWrapper = (props) => {
  const ImportedIconRef = useRef(null);

  switch (props.filename) {
    case "MenuIcon":
      ImportedIconRef.current = MenuIconSvg;
      break;
    case "CodeBGPLogo":
      ImportedIconRef.current = CodeBGPLogoSvg;
      break;
    case "SetupIcon":
      ImportedIconRef.current = SetupIconSvg;
      break;
    case "LookingGlassIcon":
      ImportedIconRef.current = LookingGlassIconSvg;
      break;
    case "AlertsIcon":
      ImportedIconRef.current = AlertsIconSvg;
      break;
    case "APIIcon":
      ImportedIconRef.current = APIIconSvg;
      break;
    // case "VisibilityIcon":
    //   ImportedIconRef.current = VisibilityIconSvg;
    //   break;
    case "SettingsIcon":
      ImportedIconRef.current = SettingsIconSvg;
      break;
    case "DocsIcon":
      ImportedIconRef.current = DocsIconSvg;
      break;
    case "SearchIcon":
      ImportedIconRef.current = SearchIconSvg;
      break;
    case "DownloadCSVIcon":
      ImportedIconRef.current = DownloadCSVIconSvg;
      break;
    case "UploadCSVIcon":
      ImportedIconRef.current = UploadCSVIconSvg;
      break;
    case "PrintIcon":
      ImportedIconRef.current = PrintIconSvg;
      break;
    case "ViewColumnsIcon":
      ImportedIconRef.current = ViewColumnsIconSvg;
      break;
    case "FilterTableIcon":
      ImportedIconRef.current = FilterTableIconSvg;
      break;
    case "DropdownArrowIcon":
      ImportedIconRef.current = DropdownArrowIconSvg;
      break;
    case "NewTabIcon":
      ImportedIconRef.current = NewTabIconSvg;
      break;
    case "RipeNccLogoWhite":
      ImportedIconRef.current = RipeNccLogoWhiteSvg;
      break;
    case "RipeNccLogo":
      ImportedIconRef.current = RipeNccLogoSvg;
      break;
    case "OverviewIcon":
      ImportedIconRef.current = OverviewIconSvg;
      break;
    case "SmileyIcon":
      ImportedIconRef.current = SmileyIconSvg;
      break;
    case "QuestionMarkIcon":
      ImportedIconRef.current = QuestionMarkIconSvg;
      break;
    case "RISLiveIcon":
      ImportedIconRef.current = RISLiveIconSvg;
      break;
    case "BGPRouterIcon":
      ImportedIconRef.current = BGPRouterIconSvg;
      break;
    case "CodeBGPBrandmarkIcon":
      ImportedIconRef.current = CodeBGPBrandmarkIconSvg;
      break;
    case "EditIcon":
      ImportedIconRef.current = EditIconSvg;
      break;
    case "DashboardsIcon":
      ImportedIconRef.current = DashboardsIconSvg;
      break;
    case "SpeedIcon":
      ImportedIconRef.current = SpeedIconSvg;
      break;
    case "IntegrationsIcon":
      ImportedIconRef.current = IntegrationsIconSvg;
      break;
    case "AlertmanagerIcon":
      ImportedIconRef.current = AlertmanagerIconSvg;
      break;
    case "GrafanaIcon":
      ImportedIconRef.current = GrafanaIconSvg;
      break;
    case "PrometheusIcon":
      ImportedIconRef.current = PrometheusIconSvg;
      break;
    case "LokiIcon":
      ImportedIconRef.current = LokiIconSvg;
      break;
    case "SortByIcon":
      ImportedIconRef.current = SortByIconSvg;
      break;
    case "ShareIcon":
      ImportedIconRef.current = ShareIconSvg;
      break;
    case "OpenSourceIcon":
      ImportedIconRef.current = OpenSourceIconSvg;
      break;
    case "MoreIcon":
      ImportedIconRef.current = MoreIconSvg;
      break;
    default:
      console.error("Icon file to be imported was not found.");
      return null;
  }

  return <SvgIcon component={ImportedIconRef.current} {...props} />;
};

SvgWrapper.propTypes = {
  filename: PropTypes.string.isRequired,
};

export { SvgWrapper };
