import { COLUMNS_ENUM } from "../../constants/components/rpkiRoas";
import {
  getDropdownFilterTypeColumn,
  getTextFieldFilterTypeColumn,
} from "../../other/utils";
import { RPKI_ROAS } from "../../constants/dataSourcesTable";
import {
  RpkiRoAs_Bool_Exp,
  RpkiRoAs_Order_By,
  RpkiRoasSubscription,
  RpkiRoasCountSubscription,
} from "../../graphql/generated/operations";
import {
  formatASN,
  formatPrefix,
  formatRpkiRoaDatasource,
} from "../formatting";
import { TableCell, TableRow } from "@mui/material";
import { DataSourcesTable } from "../../components/DataSourcesTable";
import { addServerSideOptions } from "./serverSideTable";
import { useRpkiRoaSourcesSubscription } from "../../graphql/generated/operations";
import { allDataServicesDisplayed } from "./confDataServicesTable";
import { doOnViewColumnsChange } from "../state";

/**
 * @param {string[] | number[] | undefined} configuredASNs
 * @param {string[] | undefined} configuredPrefixes
 * @param {string[] | undefined} configuredDataServices
 * @returns {RpkiRoAs_Bool_Exp}
 */
export const getConfFilters = (
  configuredASNs,
  configuredPrefixes,
  configuredDataServices
) => {
  /** @type {RpkiRoAs_Bool_Exp} */
  const whereArgument = {};

  if (!configuredASNs) configuredASNs = [];
  if (!configuredPrefixes) configuredPrefixes = [];
  if (!configuredDataServices) configuredDataServices = [];

  const confASNFilter = {
    autonomous_system_number: {
      _in: configuredASNs,
    },
  };

  const confPrefixFilter = {
    network: {
      _in: configuredPrefixes,
    },
  };

  whereArgument._or = [confASNFilter, confPrefixFilter];

  if (!allDataServicesDisplayed(configuredDataServices)) {
    whereArgument._and = [
      {
        rpkiROADataSourceAssociations: {
          dataSource: {
            data_service: {
              _in: configuredDataServices,
            },
          },
        },
      },
    ];
  }

  return whereArgument;
};

/**
 * @param {object} params
 * @param {string[]} params.configuredASNs
 * @param {string[]} params.configuredPrefixes
 * @param {string[]} params.configuredDataServices
 * @param {string} params.prefixFilter
 * @param {string} params.asFilter
 * @param {string} params.ipVersionFilter
 * @param {string} params.maskLengthFilter
 * @param {string} params.maxLengthFilter
 * @param {string} params.sourceFilter
 * @returns {RpkiRoAs_Bool_Exp}
 */
export const getWhereArgument = ({
  configuredASNs,
  configuredPrefixes,
  configuredDataServices,
  prefixFilter,
  asFilter,
  ipVersionFilter,
  maskLengthFilter,
  maxLengthFilter,
  sourceFilter,
}) => {
  /** @type {RpkiRoAs_Bool_Exp} */
  const whereArgument = getConfFilters(
    configuredASNs,
    configuredPrefixes,
    configuredDataServices
  );

  if (prefixFilter) {
    whereArgument.network = { _eq: prefixFilter };
  }
  if (asFilter) {
    whereArgument.autonomous_system_number = { _eq: asFilter };
  }
  if (ipVersionFilter) {
    whereArgument.ip_version = { _eq: +ipVersionFilter };
  }
  if (maskLengthFilter) {
    whereArgument.mask_length = { _eq: +maskLengthFilter };
  }
  if (maxLengthFilter) {
    whereArgument.max_length = { _eq: +maxLengthFilter };
  }
  if (sourceFilter) {
    whereArgument.rpkiROADataSourceAssociations = {
      dataSource: {
        selector: {
          _cast: {
            String: {
              _iregex: "^.*" + sourceFilter + ".*$",
            },
          },
        },
      },
    };
  }

  return whereArgument;
};

/**
 * @param {object} params
 * @param {string} params.sortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @returns {RpkiRoAs_Order_By[]}
 */
export const getOrderArgument = ({
  sortOrderColumnName,
  sortOrderDirection,
}) => {
  /** @type {RpkiRoAs_Order_By[]} */
  const orderArgument = [];

  switch (sortOrderColumnName) {
    case COLUMNS_ENUM.PREFIX.name:
      orderArgument.push({ network: sortOrderDirection });
      break;
    case COLUMNS_ENUM.MAX_LENGTH.name:
      orderArgument.push({
        max_length: sortOrderDirection,
      });
      break;
    case COLUMNS_ENUM.AS.name:
      orderArgument.push({
        autonomous_system_number: sortOrderDirection,
      });
      break;
    case COLUMNS_ENUM.IP_VERSION.name:
      orderArgument.push({ ip_version: sortOrderDirection });
      break;
    case COLUMNS_ENUM.MASK_LENGTH.name:
      orderArgument.push({ mask_length: sortOrderDirection });
      break;
    default:
      break;
  }

  orderArgument.push({ id: "asc" });

  return orderArgument;
};

/**
 * @param {object} params
 * @param {Function} params.applyNewFilters
 * @param {Function} params.setPrefixFilter
 * @param {Function} params.setASFilter
 * @param {Function} params.setIpVersionFilter
 * @param {Function} params.setMaskLengthFilter
 * @param {Function} params.setMaxLengthFilter
 * @param {Function} params.setSourceFilter
 * @param {Function} params.resetPage
 * @returns {void}
 */
export const onApplyFiltersButtonClick = ({
  applyNewFilters,
  setPrefixFilter,
  setASFilter,
  setIpVersionFilter,
  setMaskLengthFilter,
  setMaxLengthFilter,
  setSourceFilter,
  resetPage,
}) => {
  const filterList = applyNewFilters();
  setPrefixFilter(filterList[COLUMNS_ENUM.PREFIX.order][0]);
  setASFilter(filterList[COLUMNS_ENUM.AS.order][0]);
  setIpVersionFilter(filterList[COLUMNS_ENUM.IP_VERSION.order][0]);
  setMaskLengthFilter(filterList[COLUMNS_ENUM.MASK_LENGTH.order][0]);
  setMaxLengthFilter(filterList[COLUMNS_ENUM.MAX_LENGTH.order][0]);
  setSourceFilter(
    filterList[COLUMNS_ENUM.RPKI_ROA_DATASOURCE_ASSOCIATIONS.order][0]
  );
  resetPage();
};

/**
 * @param {object} params
 * @param {string} params.changedColumn
 * @param {string} params.action
 * @param {Function} params.setIsPrefixVisible
 * @param {Function} params.setIsMaxLengthVisible
 * @param {Function} params.setIsASVisible
 * @param {Function} params.setIsIPVersionVisible
 * @param {Function} params.setIsMaskLengthVisible
 * @param {Function} params.setIsSourceVisible
 */
export const onViewColumnsChange = ({
  changedColumn,
  action,
  setIsPrefixVisible,
  setIsMaxLengthVisible,
  setIsASVisible,
  setIsIPVersionVisible,
  setIsMaskLengthVisible,
  setIsSourceVisible,
}) => {
  /** @type {Object<string, Function>}  */
  const columnNameToVisibleSetterMap = {};
  columnNameToVisibleSetterMap[COLUMNS_ENUM.PREFIX.name] = setIsPrefixVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.MAX_LENGTH.name] =
    setIsMaxLengthVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.AS.name] = setIsASVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.IP_VERSION.name] =
    setIsIPVersionVisible;
  columnNameToVisibleSetterMap[COLUMNS_ENUM.MASK_LENGTH.name] =
    setIsMaskLengthVisible;
  columnNameToVisibleSetterMap[
    COLUMNS_ENUM.RPKI_ROA_DATASOURCE_ASSOCIATIONS.name
  ] = setIsSourceVisible;

  doOnViewColumnsChange({
    changedColumn,
    action,
    columnNameToVisibleSetterMap,
    columnsEnum: COLUMNS_ENUM,
  });
};

/**
 * @param {object} params
 * @param {string[]} params.rows
 * @param {string} params.prefixFilter
 * @param {string} params.asFilter
 * @param {string} params.ipVersionFilter
 * @param {string} params.maskLengthFilter
 * @param {string} params.maxLengthFilter
 * @param {string} params.sourceFilter
 * @param {boolean} params.isPrefixVisible
 * @param {boolean} params.isMaxLengthVisible
 * @param {boolean} params.isASVisible
 * @param {boolean} params.isIPVersionVisible
 * @param {boolean} params.isMaskLengthVisible
 * @param {boolean} params.isSourceFilterVisible
 * @returns {object[]}
 */
export const getColumns = ({
  rows: rpkiRoas,
  prefixFilter,
  asFilter,
  ipVersionFilter,
  maskLengthFilter,
  maxLengthFilter,
  sourceFilter,
  isPrefixVisible,
  isMaxLengthVisible,
  isASVisible,
  isIPVersionVisible,
  isMaskLengthVisible,
  isSourceFilterVisible,
}) => [
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.PREFIX.name, {
    filterList: prefixFilter ? [prefixFilter] : [],
    display: isPrefixVisible,
    customBodyRenderLite: (dataIndex) =>
      formatPrefix(rpkiRoas, dataIndex, COLUMNS_ENUM.PREFIX.order),
    setCellProps: () => ({
      style: { paddingLeft: 25 },
    }),
    setCellHeaderProps: () => ({ style: { paddingLeft: 35 } }),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.MAX_LENGTH.name, {
    filterList: maxLengthFilter ? [maxLengthFilter] : [],
    display: isMaxLengthVisible,
    setCellProps: () => ({
      style: { paddingLeft: 30 },
    }),
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.AS.name, {
    filterList: asFilter ? [asFilter] : [],
    display: isASVisible,
    customBodyRenderLite: (dataIndex) =>
      formatASN(rpkiRoas, dataIndex, COLUMNS_ENUM.AS.order),
    setCellHeaderProps: () => ({ style: { paddingLeft: 12 } }),
  }),
  getDropdownFilterTypeColumn(COLUMNS_ENUM.IP_VERSION.name, {
    filterList: ipVersionFilter ? [ipVersionFilter] : [],
    display: isIPVersionVisible,
    filterOptions: {
      names: ["4", "6"],
    },
  }),
  getTextFieldFilterTypeColumn(COLUMNS_ENUM.MASK_LENGTH.name, {
    filterList: maskLengthFilter ? [maskLengthFilter] : [],
    display: isMaskLengthVisible,
  }),
  getTextFieldFilterTypeColumn(
    COLUMNS_ENUM.RPKI_ROA_DATASOURCE_ASSOCIATIONS.name,
    {
      filterList: sourceFilter ? [sourceFilter] : [],
      display: true,
      sort: false,
      setCellHeaderProps: () => ({ style: { paddingLeft: 5 } }),
    }
  ),
];

/**
 * @param {object} params
 * @param {string[][]} params.rows
 * @param {string} params.sortOrderColumnName
 * @param {Function} params.setSortOrderColumnName
 * @param {string} params.sortOrderDirection
 * @param {Function} params.setSortOrderDirection
 * @param {boolean} params.isSubscriptionActive
 * @returns {object}
 */
export const getCustomOptions = ({
  rows: rpkiRoas,
  sortOrderColumnName,
  setSortOrderColumnName,
  sortOrderDirection,
  setSortOrderDirection,
  isSubscriptionActive,
}) => {
  const customOptions = {
    textLabels: {
      body: {
        noMatch: "No RPKI ROAs found.",
      },
    },
  };
  addServerSideOptions({
    options: customOptions,
    sortOrderColumnName,
    setSortOrderColumnName,
    sortOrderDirection,
    setSortOrderDirection,
  });

  return customOptions;
};

/**
 * @param {object} params
 * @param {RpkiRoasSubscription | undefined} params.data
 * @returns {(string | number)[][]}
 */
export const getRows = ({ data }) => {
  /** @type {string[][]} */
  const rpkiRoaRows = [];

  data?.rpkiROAs.forEach(
    ({
      network,
      max_length,
      autonomous_system_number,
      ip_version,
      mask_length,
      rpkiROADataSourceAssociations,
    }) => {
      rpkiRoaRows.push([
        network,
        max_length,
        autonomous_system_number,
        ip_version,
        mask_length,
        formatRpkiRoaDatasource(rpkiROADataSourceAssociations),
      ]);
    }
  );

  return rpkiRoaRows;
};

/**
 * @param {object} params
 * @param {RpkiRoasCountSubscription | undefined} params.data
 * @returns {number | undefined}
 */
export const getCount = ({ data }) => data?.rpkiROAsAggregate?.aggregate?.count;

/**
 * @param {string[]} row
 * @returns {string}
 */
export const getRowID = (row) => {
  const prefix = row[COLUMNS_ENUM.PREFIX.order];
  const maxLength = row[COLUMNS_ENUM.MAX_LENGTH.order];
  const asn = row[COLUMNS_ENUM.AS.order];
  return `${prefix}-${maxLength}-${asn}`;
};
