import {
  COLUMNS_ENUM,
  COMMUNITIES_COLUMN_INDEX,
  MAX_NUM_OF_COMMUNITIES_TO_SHOW,
} from "../constants/queries";
import { getTextFieldFilterTypeColumn } from "../other/utils";
import {
  NeighborsToWhichPrefixIsAnnouncedSubscription,
  PathsIncludingAsNsSubscription,
  PrefixesAnnouncedToNeighborSubscription,
  PathsRelatedToPrefixSubscription,
} from "../graphql/generated/operations";
import { getCommunitiesSet } from "./components/routes";

export const getPrefixesMyASNOriginates = (data) =>
  data.prefix.map((prefix) => [prefix.network]);

const getKey = (str1, str2) => `${str1}-${str2}`;
const getStringsFromKey = (key) => ({
  str1: key.split("-")[0],
  str2: key.split("-")[1],
});

/**
 * @param {NeighborsToWhichPrefixIsAnnouncedSubscription} data
 * @returns {string[][]}
 */
export const getNeighborsToWhichPrefixIsAnnounced = (data) => {
  // origin-neighbor ASN pair -> set of communities
  /** @type {Map<string, Set<string>>} */
  const originNeighborToCommunities = new Map();

  data.routes.forEach(
    ({
      originAutonomousSystem,
      neighborAutonomousSystem,
      routeDataSourceAssociations,
    }) => {
      const originASN = originAutonomousSystem?.number;
      const neighborASN = neighborAutonomousSystem?.number;
      const originNeighborKey = getKey(originASN, neighborASN);
      if (!originNeighborToCommunities.has(originNeighborKey)) {
        originNeighborToCommunities.set(originNeighborKey, new Set());
      }
      const existingCommunities =
        originNeighborToCommunities.get(originNeighborKey);
      const newCommunities = getCommunitiesSet(routeDataSourceAssociations);
      newCommunities.forEach((community) => {
        existingCommunities.add(community);
      });
    }
  );

  /** @type {string[][]} */
  const answerTableRows = [];
  originNeighborToCommunities.forEach((communitiesSet, originNeighborKey) => {
    const { str1: originASN, str2: neighborASN } =
      getStringsFromKey(originNeighborKey);
    answerTableRows.push([
      originASN,
      neighborASN,
      [...communitiesSet].sort().join(" "),
    ]);
  });

  return answerTableRows;
};

const numOfCommunities = (dataIndex, answerTableData) =>
  answerTableData[dataIndex][COMMUNITIES_COLUMN_INDEX].split(" ").length;

export const isQueryTableRowExpandable = (dataIndex, answerTableData) =>
  numOfCommunities(dataIndex, answerTableData) > MAX_NUM_OF_COMMUNITIES_TO_SHOW;

export const getCommunitiesColumn = (answerTableData, isCommunitiesVisible) => {
  const communitiesColumn = getTextFieldFilterTypeColumn(
    COLUMNS_ENUM.COMMUNITIES.name,
    {
      display: isCommunitiesVisible,
    }
  );

  communitiesColumn.options.customBodyRenderLite = (dataIndex, rowIndex) => {
    const communitiesToShowArray = answerTableData[dataIndex][
      COMMUNITIES_COLUMN_INDEX
    ].split(" ", MAX_NUM_OF_COMMUNITIES_TO_SHOW);
    const communitiesToShowString = communitiesToShowArray.join(" ");
    return isQueryTableRowExpandable(dataIndex, answerTableData)
      ? `${communitiesToShowString} ...(+${
          numOfCommunities(dataIndex, answerTableData) -
          communitiesToShowArray.length
        })`
      : communitiesToShowString;
  };

  return communitiesColumn;
};

/**
 * @param {PrefixesAnnouncedToNeighborSubscription} data
 * @returns {string[][]}
 */
export const getPrefixesAnnouncedToNeighbor = (data) => {
  // origin-prefix pair -> set of communities
  /** @type {Map<string, Set<string>>} */
  const originPrefixToCommunities = new Map();

  data.routes.forEach(
    ({
      originAutonomousSystem,
      prefix: prefixObj,
      routeDataSourceAssociations,
    }) => {
      const originASN = originAutonomousSystem?.number;
      const prefix = prefixObj?.network;
      const originPrefixKey = getKey(originASN, prefix);
      if (!originPrefixToCommunities.has(originPrefixKey)) {
        originPrefixToCommunities.set(originPrefixKey, new Set());
      }
      const existingCommunities =
        originPrefixToCommunities.get(originPrefixKey);
      const newCommunities = getCommunitiesSet(routeDataSourceAssociations);
      newCommunities.forEach((community) => {
        existingCommunities.add(community);
      });
    }
  );

  /** @type {string[][]} */
  const answerTableRows = [];
  originPrefixToCommunities.forEach((communitiesSet, originPrefixKey) => {
    const { str1: originASN, str2: prefix } =
      getStringsFromKey(originPrefixKey);
    answerTableRows.push([
      originASN,
      prefix,
      [...communitiesSet].sort().join(" "),
    ]);
  });

  return answerTableRows;
};

/**
 * @param {PathsRelatedToPrefixSubscription | undefined} data
 * @returns {string[][] | undefined}
 */
export const getPathsRelatedToPrefix = (data) =>
  data?.routes
    .filter(({ as_path }) => !!as_path)
    .map(({ as_path }) => [as_path]);

export const getBGPNeighborPairs = (data) =>
  data.map(({ myASN, neighborASN }) => [myASN, neighborASN]);

/**
 * @param {PathsIncludingAsNsSubscription | undefined} pathsData
 * @returns {string[][] | undefined}
 */
export const getPathsIncludingASNs = (pathsData) =>
  pathsData?.routes
    .filter(({ as_path }) => !!as_path)
    .map(({ as_path }) => [as_path]);

/**
 * @param {string} asn
 * @returns {string}
 */
export const getASNInPathRegex = (asn) => `( |^)${asn}( |$)`;

/**
 * @param {string} asn
 * @returns {string}
 */
export const getOriginASNInPathRegex = (asn) => `( |^)${asn}$`;
