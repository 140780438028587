import { useAtom } from "jotai";
import {
  useRpkiRoasCountSubscription,
  useRpkiRoasSubscription,
} from "../graphql/generated/operations";
import {
  rpkiRoasTableCountSubscriptionAtom,
  rpkiRoasTableFilterStateAtom,
  rpkiRoasTableIsSubscriptionActiveAtom,
  rpkiRoasTablePageAtom,
  rpkiRoasTableRowsPerPageAtom,
  rpkiRoasTableSubscriptionAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
} from "../store/rpkiRoasTable";
import {
  getWhereArgument,
  getOrderArgument,
} from "../utils/components/rpkiRoas";
import { useServerSideTableData } from "./useServerSideTableData";

export const useRpkiRoas = () => {
  const [sortOrderColumnName] = useAtom(sortOrderColumnNameAtom);
  const [sortOrderDirection] = useAtom(sortOrderDirectionAtom);
  const getOrderArgumentParams = {
    sortOrderColumnName,
    sortOrderDirection,
  };

  useServerSideTableData({
    pageAtom: rpkiRoasTablePageAtom,
    rowsPerPageAtom: rpkiRoasTableRowsPerPageAtom,
    isSubscriptionActiveAtom: rpkiRoasTableIsSubscriptionActiveAtom,
    mainSubscriptionAtom: rpkiRoasTableSubscriptionAtom,
    countSubscriptionAtom: rpkiRoasTableCountSubscriptionAtom,
    filterStateAtom: rpkiRoasTableFilterStateAtom,
    getWhereArgument,
    getOrderArgument,
    getOrderArgumentParams,
    mainSubscription: useRpkiRoasSubscription,
    countSubscription: useRpkiRoasCountSubscription,
  });
};
