import { Box } from "@mui/material";
import { PATHS } from "../constants/paths";
import { useNumberOfOverviewColumns } from "../hooks/useNumberOfOverviewColumns";
import { NoFiltersFlag } from "./NoFiltersFlag";
import { IntegrationsCard } from "./IntegrationsCard";
import { getTenantName } from "../other/utils";

export const IntegrationsContent = () => {
  const numberOfColumns = useNumberOfOverviewColumns();

  return (
    <>
      <NoFiltersFlag />
      <Box
        sx={{ py: "2rem", px: "1.5rem", justifyContent: "center" }}
        display="grid"
        gridTemplateColumns={`repeat(${numberOfColumns}, 16rem)`}
        gap={4}
      >
        <IntegrationsCard
          title="Alertmanager"
          icon="AlertmanagerIcon"
          serviceURL={`https://${getTenantName()}${
            PATHS.dashboard.alert_manager.main_link
          }`}
          docsURL={PATHS.dashboard.alert_manager.docs_link}
        />
        <IntegrationsCard
          title="Grafana"
          icon="GrafanaIcon"
          serviceURL={`https://${getTenantName()}${
            PATHS.dashboard.graphana_dashboards.main_link
          }`}
          docsURL={PATHS.dashboard.graphana_dashboards.docs_link}
        />
        <IntegrationsCard
          title="Prometheus"
          icon="PrometheusIcon"
          serviceURL={`https://${getTenantName()}${
            PATHS.dashboard.prometheus.main_link
          }`}
          docsURL={PATHS.dashboard.prometheus.docs_link}
        />
        <IntegrationsCard
          title="Loki"
          icon="LokiIcon"
          serviceURL={`https://${getTenantName()}${
            PATHS.dashboard.loki.main_link
          }`}
          docsURL={PATHS.dashboard.loki.docs_link}
        />
      </Box>
    </>
  );
};
