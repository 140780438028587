import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

export const CountryTooltip = ({ countryCode }) => {
  if (!countryCode) return <></>;

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "gray4.main",
            color: "blue1.main",
            width: "1.25rem",
            height: "1.125rem",
            fontSize: "0.5rem",
            whiteSpace: "nowrap",
            padding: "0.3rem",
            paddingTop: "0.2rem",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.32)",
            marginLeft: "0.9rem",
            marginBottom: "-0.1rem !important",
          },
        },
      }}
      key={countryCode}
      title={countryCode}
      placement="top-start"
    >
      <img
        key={countryCode}
        alt={countryCode}
        width="22"
        style={{ borderRadius: "0.15rem", marginRight: "0.5rem" }}
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
      />
    </Tooltip>
  );
};

CountryTooltip.propTypes = {
  countryCode: PropTypes.string.isRequired,
};
