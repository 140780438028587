import { ReactElement } from "react";
import { Tooltip } from "./Tooltip";

/**
 * @param {object} props
 * @param {string} props.prefix
 * @param {boolean} [props.isIP]
 * @returns {ReactElement}
 */
export const PrefixTooltip = ({ prefix, isIP = false }) => {
  const ripeStatURL = `https://stat.ripe.net/app/${prefix}`;
  const irrExplorerURL = `https://irrexplorer.nlnog.net/prefix/${prefix}`;

  const title = isIP ? `IP ${prefix}` : `Prefix ${prefix}`;
  const moreInfoLinks = [
    { name: "RIPEstat", url: ripeStatURL },
    { name: "IRR Explorer", url: irrExplorerURL },
  ];

  return <Tooltip title={title} moreInfoLinks={moreInfoLinks} />;
};
