import {
  SELECT_ASN,
  SELECT_AS_FILTER,
  SELECT_PREFIX,
} from "../../constants/queries";
import { convertIntsToStrings } from "../formatting";

/**
 * @param {number[]} [asns]
 * @returns {string[]}
 */
export const getASFiltersToSelect = (asns) =>
  getNumbersToSelect(SELECT_AS_FILTER, asns);

/**
 * @param {number[]} [asns]
 * @returns {string[]}
 */
export const getASNsToSelect = (asns) => getNumbersToSelect(SELECT_ASN, asns);

/**
 * @param {string[]} [prefixes]
 * @returns {string[]}
 */
export const getPrefixesToSelect = (prefixes) =>
  getStringsToSelect(SELECT_PREFIX, prefixes);

/**
 * @param {string} emptySelectionValue
 * @param {number[]} [data]
 * @returns {string[]}
 */
const getNumbersToSelect = (emptySelectionValue, data) => {
  if (!data) return [emptySelectionValue];
  const dataStrings = convertIntsToStrings(data);
  return [emptySelectionValue, ...dataStrings];
};

/**
 * @param {string} emptySelectionValue
 * @param {string[]} [data]
 * @returns {string[]}
 */
const getStringsToSelect = (emptySelectionValue, data) => {
  if (!data) return [emptySelectionValue];
  return [emptySelectionValue, ...data];
};
