export const COLUMNS_ENUM = Object.freeze({
  NAME: { order: 0, name: "Name" },
  TYPE: { order: 1, name: "Type" },
  STARTS_AT: { order: 2, name: "Started" },
  ENDS_AT: { order: 3, name: "Ended" },
  SEVERITY: { order: 4, name: "Severity" },
  DATA: { order: 5, name: "Event" },
  DESCRIPTION: { order: 6, name: "Description" },
  FIRE_ALERT_REGEX: { order: 7, name: "Fire Alert Regex" },
  ID: { order: 8, name: "ID" },
  QUERY: { order: 9, name: "Query" },
  RECEIVER_ENDPOINT: { order: 10, name: "Notification endpoint" },
  RECEIVER_TYPE: { order: 11, name: "Notification type" },
  STATUS: { order: 12, name: "Status" },
  VARS: { order: 13, name: "Variables" },
});
