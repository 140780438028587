import { HiddenResult, DataResult, SingleDataResult } from "../types/store";

/**
 * @template T
 * @param {T} type
 * @returns {HiddenResult<T>}
 */
export const getInitialHiddenResult = (type) => new Set();

/**
 * @template T
 * @param {T} type
 * @returns {DataResult<T>}
 */
export const getInitialDataResult = (type) => ({
  data: undefined,
  fetching: true,
  error: undefined,
  reexecuteOperation: undefined,
});

/**
 * @template T
 * @param {T} type
 * @returns {SingleDataResult<T>}
 */
export const getInitialSingleDataResult = (type) => ({
  data: undefined,
  fetching: true,
  error: undefined,
  reexecuteOperation: undefined,
});

export const LOCAL_STORAGE_PREFIX = "codebgp:";
