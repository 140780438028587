import { LinearProgress } from "@mui/material";

export const TooltipLinearProgress = () => {
  return (
    <LinearProgress
      sx={{
        mt: "0.6rem",
      }}
    />
  );
};
