import { useAutonomousSystemsCount } from "./useAutonomousSystemsCount";
import { useIPv4PrefixesCount } from "./useIPv4PrefixesCount";
import { useIPv6PrefixesCount } from "./useIPv6PrefixesCount";
import { usePeeringsCount } from "./usePeeringsCount";
import { useRoutesCount } from "./useRoutesCount";
import { useRpkiRoasCount } from "./useRpkiRoasCount";
import { useDataSourcesCount } from "./useDataSourcesCount";
import { useConfiguredAlertsCount } from "./useConfiguredAlertsCount";
import { useBGPUpdateRateCount } from "./useBGPUpdateRateCount";
import { useAvgBGPUpdateRateCount } from "./useAvgBGPUpdateRateCount";
import { useActiveAlertsCount } from "./useActiveAlertsCount";

export const useOverviewData = () => {
  useBGPUpdateRateCount();
  useAvgBGPUpdateRateCount();
  useAutonomousSystemsCount();
  useIPv4PrefixesCount();
  useIPv6PrefixesCount();
  usePeeringsCount();
  useRoutesCount();
  useRpkiRoasCount();
  useConfiguredAlertsCount();
  useActiveAlertsCount();
  useDataSourcesCount();
};
