import { useConfiguredAlertsCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useConfiguredAlertsCount";
import { useData } from "./useData";
import { alertRulesCountAtom } from "../store/overviewContent";

export const useConfiguredAlertsCount = () => {
  useData({
    operation: useConfiguredAlertsCountSubscription,
    formatter,
    atom: alertRulesCountAtom,
  });
};
