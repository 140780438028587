import { ReactElement, useState } from "react";
import { Table } from "./Table";
import { useAtom, PrimitiveAtom, Atom } from "jotai";
import { usePagination } from "../hooks/usePagination";
import { getCountLabel } from "../utils/components/serverSideTable";
import { ExportDataButton } from "./ExportDataButton";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredDataServicesAtom,
  displayedConfiguredPrefixesAtom,
} from "../other/store";
import {
  auth0HookGetter,
  createGQLEndpoint,
  getSubscription,
} from "../other/utils";
import { useAccessToken } from "../hooks/useAccessToken";
import { CircularProgress, Snackbar, Typography } from "@mui/material";
import { Stack, margin } from "@mui/system";
import { getUnformattedASpath } from "../utils/components/routes";
import { getValueFromEnum } from "../utils/formatting";
import { COLUMNS_ENUM, FORMAT_RPKI_ENUM } from "../constants/components/routes";
import { ShareUrlButton } from "./ShareUrlButton";
import { Alert } from "./Alert";
import { PATHS } from "../constants/paths";

/**
 * @param {object} props
 * @param {string} props.objectName
 * @param {object} props.filterState
 * @param {object} props.filterSetters
 * @param {object} props.visibleSetters
 * @param {Function} props.getColumns
 * @param {object} [props.getColumnsParams]
 * @param {Function} props.getCustomOptions
 * @param {Function} props.getCount
 * @param {Function} props.getRows
 * @param {object} [props.getRowsParams]
 * @param {Function} [props.getWhereArgument]
 * @param {object} [props.getWhereArgumentParams]
 * @param {Function} props.onApplyFiltersButtonClick
 * @param {Function} props.onViewColumnsChange
 * @param {PrimitiveAtom<number>} props.pageAtom
 * @param {PrimitiveAtom<number>} props.rowsPerPageAtom
 * @param {PrimitiveAtom<any>} props.mainSubscriptionAtom
 * @param {PrimitiveAtom<any>} props.countSubscriptionAtom
 * @param {Atom<boolean>} props.isSubscriptionActiveAtom
 * @param {Atom<string>} props.sortOrderColumnNameAtom
 * @param {Atom<string>} props.sortOrderDirectionAtom
 * @param {Function} props.getRowID
 * @param {PrimitiveAtom<Set<string>>} props.expandedRowIDsAtom
 * @param { object } props.serverSideTableDataQueryDocument
 * @returns {ReactElement}
 */
export const ServerSideTable = ({
  objectName,
  filterState,
  filterSetters,
  visibleSetters,
  getColumns,
  getColumnsParams,
  getCustomOptions,
  getCount,
  getRows,
  getRowsParams,
  getWhereArgument,
  getWhereArgumentParams,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
  pageAtom,
  rowsPerPageAtom,
  mainSubscriptionAtom,
  countSubscriptionAtom,
  isSubscriptionActiveAtom,
  sortOrderColumnNameAtom,
  sortOrderDirectionAtom,
  getRowID,
  expandedRowIDsAtom,
  serverSideTableDataQueryDocument,
}) => {
  const { resetPage } = usePagination({
    pageAtom,
    rowsPerPageAtom,
  });
  const [expandedRowIDs, setExpandedRowIDs] = useAtom(expandedRowIDsAtom);
  const [isSubscriptionActive] = useAtom(isSubscriptionActiveAtom);
  const [sortOrderColumnName, setSortOrderColumnName] = useAtom(
    sortOrderColumnNameAtom
  );
  const [sortOrderDirection, setSortOrderDirection] = useAtom(
    sortOrderDirectionAtom
  );
  const [configuredASNsState] = useAtom(displayedConfiguredASNsAtom);
  const { data: configuredASNs } = configuredASNsState;
  const [configuredPrefixesState] = useAtom(displayedConfiguredPrefixesAtom);
  const { data: configuredPrefixes } = configuredPrefixesState;
  const [configuredDataServicesState] = useAtom(
    displayedConfiguredDataServicesAtom
  );
  const { data: configuredDataServices } = configuredDataServicesState;

  const [copiedURL, setCopiedURL] = useState(false);

  /**
   * @param {object} params
   * @param {Function} params.applyNewFilters
   * @returns {void}
   */
  const onApplyFiltersButtonClickCallback = ({ applyNewFilters }) => {
    onApplyFiltersButtonClick({
      applyNewFilters,
      ...filterSetters,
      resetPage,
    });
  };

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      ...visibleSetters,
    });
  };

  const [
    {
      data: countSubscriptionData,
      fetching: countSubscriptionFetching,
      error: countSubscriptionError,
    },
  ] = useAtom(countSubscriptionAtom);
  const [
    {
      data: mainSubscriptionData,
      fetching: mainSubscriptionFetching,
      error: mainSubscriptionError,
    },
  ] = useAtom(mainSubscriptionAtom);

  const countData = countSubscriptionData;
  const countFetching = countSubscriptionFetching;
  const countError = countSubscriptionError;
  const mainData = mainSubscriptionData;
  const mainFetching = mainSubscriptionFetching;
  const mainError = mainSubscriptionError;

  const rows = getRows({ data: mainData, ...getRowsParams });
  const columns = getColumns({
    rows,
    ...filterState,
    ...getColumnsParams,
  });

  const rowsCount = getCountLabel({
    data: countData,
    fetching: countFetching,
    error: countError,
    getCount,
  });

  const customOptions = getCustomOptions({
    rows,
    sortOrderColumnName,
    setSortOrderColumnName,
    sortOrderDirection,
    setSortOrderDirection,
    isSubscriptionActive,
  });

  const whereArgument = getWhereArgument({
    configuredASNs,
    configuredPrefixes,
    configuredDataServices,
    ...filterState,
    ...getWhereArgumentParams,
  });

  const { user } = auth0HookGetter()();
  const accessToken = useAccessToken();

  const [isCircularBarDownloadEnabled, setCircularBarDownload] =
    useState(false);

  const DownloadFile = () => {
    setCircularBarDownload(true);
    const url = createGQLEndpoint(user);
    const graphqlAPIURL = new URL(url, window.location.origin);
    fetch(graphqlAPIURL.href, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: getSubscription(serverSideTableDataQueryDocument),
        variables: { where: whereArgument },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCircularBarDownload(false);
        const rows = getRows({ data: data["data"], ...getRowsParams });
        const columns = getColumns({
          rows,
          ...filterState,
          ...getColumnsParams,
        });
        const formatedColumns = columns.map((item) => item.name);
        let csv = formatedColumns.join(";") + "\n";
        rows.forEach((item) => {
          if (objectName === "Routes") {
            item[COLUMNS_ENUM.AS_PATH.order] = getUnformattedASpath(
              item[COLUMNS_ENUM.AS_PATH.order]
            );
            item[COLUMNS_ENUM.RPKI_STATUS.order] = getValueFromEnum(
              FORMAT_RPKI_ENUM,
              item[COLUMNS_ENUM.RPKI_STATUS.order]
            );
          }
          csv += item.join(";").replace(/\n/g, " ");
          csv += "\n";
        });
        const blob = new Blob([csv], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = objectName + " Table Looking Glass Data.csv";
        link.href = url;
        link.click();
      })
      .catch((err) => {
        setCircularBarDownload(false);
        console.log(err);
      });
  };

  const ShareUrlData = () => {
    let filters = "";
    let tabName = "";
    switch (objectName) {
      case "Prefixes":
        tabName = "prefixes";
        break;
      case "Routes":
        tabName = "routes";
        break;
      case "RPKI ROAs":
        tabName = "rpki-roas";
        break;
      default:
        console.error("Invalid Tab!");
    }

    Object.keys(filterState).forEach((key) => {
      if (filterState[key] !== "" && typeof filterState[key] !== "undefined") {
        if (filters === "") filters += "?";
        filters += key + "=" + filterState[key] + "&";
      }
    });

    const serviceURL = `https://${window.location.host}${PATHS.dashboard.lookingglass.index}${filters}#${tabName}`;
    navigator.clipboard.writeText(serviceURL);
    setCopiedURL(true);
  };

  const handleClose = () => {
    setCopiedURL(false);
  };

  const HeaderElements = () => (
    <>
      <ExportDataButton onClick={DownloadFile} />
      <ShareUrlButton onClick={ShareUrlData} toolbarIconOrder={-2} />
    </>
  );

  return (
    <Stack>
      <Table
        title={
          isCircularBarDownloadEnabled && (
            <Stack direction={"row"}>
              <CircularProgress />
              <Typography style={{ margin: "10px" }}>
                Download In Progress ...
              </Typography>
            </Stack>
          )
        }
        data={rows}
        loading={mainFetching}
        error={mainError}
        columns={columns}
        customOptions={{
          responsive: "standard",
          tableBodyMaxHeight: "70vh",
          customToolbar: HeaderElements,
          ...customOptions,
        }}
        pageAtom={pageAtom}
        rowsPerPageAtom={rowsPerPageAtom}
        count={rowsCount}
        onApplyFiltersButtonClick={onApplyFiltersButtonClickCallback}
        onViewColumnsChange={onViewColumnsChangeCallback}
        getRowID={getRowID}
        expandedRowIDs={expandedRowIDs}
        setExpandedRowIDs={setExpandedRowIDs}
        displayDownloadButton={false}
      />
      <Snackbar
        open={copiedURL}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Share link copied to clipboard
        </Alert>
      </Snackbar>
    </Stack>
  );
};
