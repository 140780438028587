import { ReactElement } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { Loading } from "../types/dataFetching";
import { TableProps } from "../types/components/table";

/**
 * @param {object} props
 * @param {TableProps["title"]} [props.text]
 * @param {Loading} props.loading
 * @returns {ReactElement}
 */
export const TableTitle = ({ text, loading }) => {
  if (loading) {
    return (
      <Stack direction="row">
        {text} <CircularProgress sx={{ ml: "1.5rem" }} />
      </Stack>
    );
  }

  return <>{text}</>;
};
