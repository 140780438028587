import { Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { ConfDataServicesTable } from "../components/ConfDataServicesTable";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { useState } from "react";

export const DataServices = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.setup.dataServices}
        helpText={HELP_TEXT_ENUM.setup.dataServices}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <ConfDataServicesTable />
    </Stack>
  );
};
