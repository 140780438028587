import { PeeringsSubscription } from "../../graphql/generated/operations";
import { isNonEmptyArray } from "../validation";
import { ConfiguredASNsResult } from "../../types/hooks/useConfiguredASNs";
import { getDataSourcesPercentage } from "../state";

/**
 * @param {PeeringsSubscription | undefined} data
 * @param {number} totalNumberOfDataSources
 * @param {ConfiguredASNsResult["data"]} [configuredASNs]
 * @returns {string[][] | null}
 */
export const formatter = (data, totalNumberOfDataSources, configuredASNs) => {
  if (!configuredASNs || !isNonEmptyArray(configuredASNs)) return null;

  // peering key -> peering row
  const peeringMap = new Map();

  data?.peerings.forEach(
    ({ leftAutonomousSystem, rightAutonomousSystem, data_source_count }) => {
      const dataSourcesPercentage = getDataSourcesPercentage({
        data_source_count,
        totalNumberOfDataSources,
      });
      const leftASN = leftAutonomousSystem?.number;
      const rightASN = rightAutonomousSystem?.number;
      const [myASN, neighborASN] = configuredASNs.includes(leftASN)
        ? [leftASN, rightASN]
        : [rightASN, leftASN];
      const peeringRow = [
        myASN,
        neighborASN,
        data_source_count,
        dataSourcesPercentage,
      ];
      const peeringKey = `${myASN}-${neighborASN}`;
      if (!peeringMap.has(peeringKey)) {
        peeringMap.set(peeringKey, peeringRow);
      }
    }
  );

  return [...peeringMap.values()];
};
