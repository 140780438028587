import { useState } from "react";
import { Snackbar, Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { useRole } from "../hooks/useRole";
import { useOpenClose } from "../hooks/useOpenClose";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { isEditor } from "../other/utils";
import { AddConfAlertsDialog } from "../components/AddConfAlertsDialog";
import { Alert } from "../components/Alert";
import { RemoveConfAlertDialog } from "../components/RemoveConfAlertDialog";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { ConfAlertsTable } from "../components/ConfAlertsTable";
import { useAtom } from "jotai";
import { isHelpTextOpenAtom } from "../store/confAlertsTable";
import { useSearchParams } from "react-router-dom";

export const AlertRules = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useAtom(isHelpTextOpenAtom);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [editableAlertRule, setEditableAlertRule] = useState();
  const [alertRulesToRemove, setAlertRulesToRemove] = useState([]);
  const role = useRole();
  const {
    isOpen: isAddConfAlertDialogOpen,
    open: openAddConfAlertDialog,
    close: closeAddConfAlertDialog,
  } = useOpenClose();
  const {
    isOpen: isRemoveConfAlertDialogOpen,
    open: openRemoveConfAlertDialog,
    close: closeRemoveConfAlertDialog,
  } = useOpenClose();
  const {
    isOpen: isSuccessSnackbarOpen,
    open: openSuccessSnackbar,
    close: closeSuccessSnackbar,
  } = useOpenCloseSnackbar();

  const [searchParams] = useSearchParams();
  const urlParams = [];
  for (let entry of searchParams.entries()) {
    urlParams.push(entry);
  }

  let addConfAlertDialog;
  let removeConfAlertDialog;
  let successSnackbar;
  if (isEditor(role)) {
    addConfAlertDialog = (
      <AddConfAlertsDialog
        isOpen={isAddConfAlertDialogOpen}
        close={closeAddConfAlertDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        editableAlertRule={editableAlertRule}
      />
    );
    removeConfAlertDialog = (
      <RemoveConfAlertDialog
        isOpen={isRemoveConfAlertDialogOpen}
        close={closeRemoveConfAlertDialog}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        alertRules={alertRulesToRemove}
      />
    );
    successSnackbar = (
      <Snackbar
        key={snackbarMessage}
        open={isSuccessSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
      >
        <Alert onClose={closeSuccessSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.setup.alerts}
        helpText={HELP_TEXT_ENUM.setup.alerts}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      <ConfAlertsTable
        role={role}
        openAddConfAlertDialog={openAddConfAlertDialog}
        setEditableAlertRule={setEditableAlertRule}
        openRemoveConfAlertDialog={openRemoveConfAlertDialog}
        setAlertRulesToRemove={setAlertRulesToRemove}
        urlParams={urlParams}
      />
      {addConfAlertDialog}
      {removeConfAlertDialog}
      {successSnackbar}
    </Stack>
  );
};
