import { useState } from "react";
import { Avatar, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { auth0HookGetter } from "../other/utils";
import { useRole } from "../hooks/useRole";
import { getTenantName } from "../other/utils";
import { useUsername } from "../hooks/useUsername";

const AccountMenu = () => {
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const { user, logout } = auth0HookGetter()();
  const { fullname } = useUsername();
  const role = useRole();

  const onAccountButtonClick = (e) => {
    setAccountMenuAnchor(e.currentTarget);
  };

  const onAccountMenuClose = () => {
    setAccountMenuAnchor(null);
  };

  const onLogoutButtonClick = () => {
    onAccountMenuClose();
    logout({ returnTo: window.location.origin });
  };

  return (
    <Stack>
      <Button
        onClick={onAccountButtonClick}
        sx={{ color: "white", textTransform: "none", ml: "1.5rem" }}
      >
        <Stack textAlign="end">
          <Typography>{fullname}</Typography>
          <Typography fontSize="0.75rem" fontWeight="light">
            {role} | {getTenantName()}
          </Typography>
        </Stack>
        <Avatar
          sx={{ ml: "0.75rem" }}
          alt={`${fullname} profile picture`}
          src={user?.picture}
        />
      </Button>
      <Menu
        anchorEl={accountMenuAnchor}
        open={accountMenuAnchor !== null}
        onClose={onAccountMenuClose}
        sx={{ ml: 4 }}
      >
        <MenuItem onClick={onLogoutButtonClick}>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export { AccountMenu };
