import { Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { Prefixes } from "../tabs/Prefixes";
import { PageTabs } from "../components/PageTabs";
import { AutonomousSystems } from "../tabs/AutonomousSystems";
import { Peerings } from "../tabs/Peerings";
import { Routes } from "../tabs/Routes";
import { RpkiRoas } from "../tabs/RpkiRoas";
import { getStatePageTabs } from "../other/utils";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const LookingGlass = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const [helptext, setHelpText] = useState(
    HELP_TEXT_ENUM.lookingglass.prefixes
  );

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const urlParams = [];
  for (let entry of searchParams.entries()) {
    urlParams.push(entry);
  }

  let PrefixesTabUrlParams = [];
  let ASesTabUrlParams = [];
  let PeeringsTabUrlParams = [];
  let RoutesTabUrlParams = [];
  let RpkiRoasTabUrlParams = [];
  const hashTagUrlValue = location.hash.replace("#", "");
  switch (hashTagUrlValue) {
    case "prefixes":
      PrefixesTabUrlParams = urlParams;
      break;
    case "autonomous-systems":
      ASesTabUrlParams = urlParams;
      break;
    case "peerings":
      PeeringsTabUrlParams = urlParams;
      break;
    case "routes":
      RoutesTabUrlParams = urlParams;
      break;
    case "rpki-roas":
      RpkiRoasTabUrlParams = urlParams;
      break;
    default:
      console.error("Invalid Looking Glass Tab!");
  }

  const tabs = getStatePageTabs(
    <Prefixes urlParams={PrefixesTabUrlParams} />,
    <AutonomousSystems urlParams={ASesTabUrlParams} />,
    <Peerings urlParams={PeeringsTabUrlParams} />,
    <Routes urlParams={RoutesTabUrlParams} />,
    <RpkiRoas urlParams={RpkiRoasTabUrlParams} />
  );

  const selectedTabCallback = ({ selectedTab }) => {
    switch (selectedTab) {
      case "prefixes":
        setHelpText(HELP_TEXT_ENUM.lookingglass.prefixes);
        break;
      case "autonomous-systems":
        setHelpText(HELP_TEXT_ENUM.lookingglass.autonomous_systems);
        break;
      case "peerings":
        setHelpText(HELP_TEXT_ENUM.lookingglass.peerings);
        break;
      case "routes":
        setHelpText(HELP_TEXT_ENUM.lookingglass.routes);
        break;
      case "rpki-roas":
        setHelpText(HELP_TEXT_ENUM.lookingglass.rpki_roas);
        break;
      default:
        break;
    }
  };

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.lookingglass}
        helpText={helptext}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
        hashTagUrlValue={hashTagUrlValue}
        selectedTabCallback={selectedTabCallback}
      />
      <PageTabs tabs={tabs} selectedTabCallback={selectedTabCallback} />
    </Stack>
  );
};
