import { OnChange, OnAllChange } from "../types/components/checkboxWithLabel";
import { ToggleGroupResult } from "../types/hooks/useToggleGroup";

/**
 * @param {object} params
 * @param {(string|number)[]} [params.data]
 * @param {Set<string|number>} params.unchecked
 * @param {Function} params.setUnchecked
 * @returns {ToggleGroupResult}
 */
export const useInvertedToggleGroup = ({ data, unchecked, setUnchecked }) => {
  if (!data) {
    return {
      numberOfChecked: 0,
      areAllChecked: false,
      onSelectAllChange: () => {},
      onSelectChange: () => {},
    };
  }

  // Take only valid entries into account when reading.
  unchecked = new Set(
    Array.from(unchecked).filter((value) => data.includes(value))
  );
  const numberOfChecked = data.length - unchecked.size;
  const areAllChecked = unchecked.size === 0;

  /**
   * @param {boolean} checkedValue
   */
  const updateAll = (checkedValue) => {
    const newUnchecked = new Set();
    if (!checkedValue) {
      data.forEach((value) => newUnchecked.add(value));
    }
    setUnchecked(newUnchecked);
  };

  /**
   * @param {string|number} value
   * @param {boolean} checkedValue
   */
  const updateOne = (value, checkedValue) => {
    const newUnchecked = new Set(unchecked);
    if (!checkedValue) {
      newUnchecked.add(value);
    } else {
      newUnchecked.delete(value);
    }
    setUnchecked(newUnchecked);
  };

  /**
   * @type {OnAllChange}
   */
  const onSelectAllChange = (checkedValue) => {
    updateAll(checkedValue);
  };

  /**
   * @type {OnChange}
   */
  const onSelectChange = (checkedValue, value) => {
    updateOne(value, checkedValue);
  };

  return {
    numberOfChecked,
    areAllChecked,
    onSelectAllChange,
    onSelectChange,
  };
};
