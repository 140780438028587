import { atom } from "jotai";
import {
  INITIAL_COUNT,
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";

export const pageAtom = atom(INITIAL_PAGE);
export const rowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const countAtom = atom(INITIAL_COUNT);
export const expandedRowIDsAtom = atom(new Set());

export const isDataServiceVisibleAtom = atom(true);
export const isConfigurationVisibleAtom = atom(false);
export const isFilterOnASVisibleAtom = atom(true);
export const isDisplayedVisibleAtom = atom(true);
export const isEnabledVisibleAtom = atom(true);
