import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";
import { AutonomousSystemNumberTooltip } from "../components/AutonomousSystemNumberTooltip";
import { StringWithTooltip } from "../components/StringWithTooltip";
import { StringWithTooltipOriginASNS } from "../components/StringWithTooltipOriginASNS";
import { PrefixTooltip } from "../components/PrefixTooltip";
import { RRCTooltip } from "../components/RRCTooltip";
import {
  FORMAT_RPKI_ENUM,
  UNFORMAT_RPKI_ENUM,
} from "../constants/components/routes";
import { CountryTooltip } from "../components/CountryTooltip";
import { LinearProgressWithLabel } from "../components/LinearProgressWithLabel";
import { getCode } from "country-list";

/**
 * @param {string[]} asns
 * @returns {ReactElement}
 */
const getPathJSX = (asns) => {
  const pathJSX = [];
  for (let i = 0; i < asns.length - 1; i++) {
    const asn = asns[i];
    if (isNaN(asn)) {
      pathJSX.push(
        <span key={i}>
          {asn}
          <span>&nbsp;&nbsp;</span>
        </span>
      );
    } else {
      if (isNaN(asns[i + 1])) {
        pathJSX.push(
          <span key={i}>
            <StringWithTooltip
              string={asn}
              title={<AutonomousSystemNumberTooltip asn={+asn} />}
              pr="0px"
            />
          </span>
        );
      } else {
        pathJSX.push(
          <span key={i}>
            <StringWithTooltip
              string={asn}
              title={<AutonomousSystemNumberTooltip asn={+asn} />}
            />
          </span>
        );
      }
    }
  }
  const lastIndex = asns.length - 1;
  const lastASN = asns[asns.length - 1];
  if (isNaN(lastASN)) {
    pathJSX.push(<span key={lastIndex}>{lastASN}</span>);
  } else {
    pathJSX.push(
      <span key={lastIndex}>
        <StringWithTooltip
          string={lastASN}
          title={<AutonomousSystemNumberTooltip asn={+lastASN} />}
        />
      </span>
    );
  }
  return (
    <Stack sx={{ display: "inline-block" }} direction="row">
      {pathJSX.reduce((prev, curr) => [prev, " ", curr])}
    </Stack>
  );
};

/**
 * @param {string[]} asns
 * @returns {ReactElement}
 */
const getASNsJSX = (asns) => {
  const asnsJSX = [];
  for (let i = 0; i < asns.length - 1; i++) {
    const asn = asns[i];
    asnsJSX.push(
      <span key={i}>
        <span style={{ whiteSpace: "nowrap" }}>
          <StringWithTooltipOriginASNS
            string={asn}
            title={<AutonomousSystemNumberTooltip asn={+asn} />}
          />
          <span style={{ paddingRight: "0.3rem" }}>,</span>
        </span>
        <span>&#8203;</span>
      </span>
    );
  }
  const lastIndex = asns.length - 1;
  const lastASN = asns[asns.length - 1];
  asnsJSX.push(
    <span key={lastIndex} style={{ whiteSpace: "nowrap" }}>
      <StringWithTooltipOriginASNS
        string={lastASN}
        title={<AutonomousSystemNumberTooltip asn={+lastASN} />}
      />
      <span>&nbsp;</span>
    </span>
  );
  return (
    <Stack
      sx={{
        minWidth: "80px",
        maxWidth: "300px",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        display: "inline-block",
      }}
      direction="row"
    >
      {asnsJSX}
    </Stack>
  );
};

export const formatASN = (tableData, dataIndex, columnIndex) => {
  const asn = tableData[dataIndex][columnIndex];
  return (
    <StringWithTooltip
      key={asn}
      string={asn}
      title={<AutonomousSystemNumberTooltip asn={+asn} />}
    />
  );
};

export const formatASNs = (tableData, dataIndex, columnIndex) => {
  const asns = tableData[dataIndex][columnIndex];
  return getASNsJSX(asns);
};

export const formatASNPath = (tableData, dataIndex, columnIndex) => {
  const path = tableData[dataIndex][columnIndex];
  if (path) {
    const asns = path.split(" ");
    const pathJSX = getPathJSX(asns);
    return pathJSX;
  }
  return;
};

export const formatPrefix = (tableData, dataIndex, columnIndex, isIP) => {
  const prefix = tableData[dataIndex][columnIndex];
  return (
    <StringWithTooltip
      string={prefix}
      title={<PrefixTooltip prefix={prefix} isIP={isIP} />}
      width="14.625rem"
    />
  );
};

export const formatDataSourcesPercentage = (
  tableData,
  dataIndex,
  columnIndex
) => {
  const percentage = tableData[dataIndex][columnIndex];
  const sanitisedPercentage = percentage ? +percentage : 0;
  return <LinearProgressWithLabel value={sanitisedPercentage} />;
};

export const formatRRC = (tableData, dataIndex, columnIndex) => {
  const rrcName = tableData[dataIndex][columnIndex]?.toUpperCase();
  if (rrcName) {
    return (
      <StringWithTooltip
        string={rrcName}
        title={<RRCTooltip rrcName={rrcName} />}
        width="13.375rem"
      />
    );
  }
};

export const formatRPKIStatus = (tableData, dataIndex, columnIndex) => {
  const rpkiStatus = tableData[dataIndex][columnIndex];
  return getValueFromEnum(FORMAT_RPKI_ENUM, rpkiStatus);
};

export const unformatRPKIStatus = (rpkiStatusFilter) =>
  getValueFromEnum(UNFORMAT_RPKI_ENUM, rpkiStatusFilter);

export const formatCountryCode = (tableData, dataIndex, columnIndex) => {
  const countryCode =
    tableData[dataIndex][columnIndex] !== undefined
      ? getCode(tableData[dataIndex][columnIndex])
      : "";
  let countryName = tableData[dataIndex][columnIndex];
  const countryRenames = new Map();
  countryRenames.set(
    "United Kingdom of Great Britain and Northern Ireland",
    "United Kingdom"
  );
  countryRenames.set("United States of America", "United States");
  countryRenames.set("Russian Federation", "Russia");
  countryRenames.set("Taiwan, Province of China", "Taiwan");
  countryRenames.set("Korea, Republic of", "South Korea");
  countryRenames.set("Iran, Islamic Republic of", "Iran");
  countryRenames.set("Türkiye", "Turkey");

  if (countryRenames.has(countryName)) {
    countryName = countryRenames.get(countryName);
  }

  if (countryCode) {
    return (
      <Stack direction="row">
        <Typography
          sx={{ width: "fit-content", pr: "8px" }}
          fontSize="0.875rem"
          component="span"
        >
          {countryName}
        </Typography>
        <CountryTooltip countryCode={countryCode} />
      </Stack>
    );
  }
};

/**
 * @param {Object<string, any>} object
 * @param {string} key
 * @returns {any}
 */
export const getValueFromEnum = (object, key) => object[key] ?? key;

/**
 * @param {string?} [word]
 * @returns {string}
 */
export const toPascalCase = (word) => {
  if (!word) return "";
  if (word.length === 0) return word;
  if (word.length === 1) return word.toUpperCase();
  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
};

/**
 * @param {string?} [word]
 * @returns {string}
 */
export const toSnakeUpperCase = (word) => {
  if (!word) return "";
  if (word.length === 0) return word;
  if (word.length === 1) return word.toUpperCase();
  return word
    .split(" ")
    .map((chars) => chars.toUpperCase())
    .join("_");
};

/**
 * @param {number[] | undefined} data
 * @returns {string[] | undefined}
 */
export const convertIntsToStrings = (data) => data?.map((x) => `${x}`);

/**
 * @param {object[]} rpkiROADataSourceAssociations
 * @returns {string}
 */
export const formatRpkiRoaDatasource = (rpkiROADataSourceAssociations) => {
  const sourceStringArray = [];
  rpkiROADataSourceAssociations?.forEach((item) => {
    sourceStringArray.push(
      item.dataSource.selector?.uri.split(".")[1].toUpperCase()
    );
  });
  return sourceStringArray.join(", ");
};
