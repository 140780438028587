export const ENV_ENUM = Object.freeze({
  testing: "testing",
});

export const GQL_CLIENT_ENUM = Object.freeze({
  mock: "mock",
  local: "local",
});

export const DOMAIN_ENUM = Object.freeze({
  local: "localhost",
  preview: "bgp-data-ui.pages.dev",
});

export const GQL_LOCAL_HOSTNAME = "localhost:8080/v1";
