import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { ConfAlertsExpandedRowHeader } from "./ConfAlertsExpandedRowHeader";
import { ArgsTable } from "./ArgsTable";
import { getRows } from "../utils/components/confAlertsExpandedRow";

/**
 * @param {object} props
 * @param {string} props.createdDate
 * @param {object} props.args
 * @param {string} props.receiverType
 * @param {string} props.receiverEndpoint
 * @returns {ReactElement}
 */
export const ConfAlertsExpandedRow = ({
  createdDate,
  args,
  receiverType,
  receiverEndpoint,
}) => {
  const rows = getRows(args);
  return (
    <Stack
      sx={{
        borderRadius: "8px 8px 8px 8px",
        borderStyle: "solid",
        borderWidth: "0.063rem",
        borderColor: "#e8eaeb",
      }}
      py="24px"
      px="24px"
      bgcolor="white"
    >
      <ConfAlertsExpandedRowHeader
        createdDate={createdDate}
        receiverType={receiverType}
        receiverEndpoint={receiverEndpoint}
      />
      <ArgsTable rows={rows} />
    </Stack>
  );
};
