import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { Tooltip } from "./Tooltip";
import { useRRCInfo } from "../hooks/useRRCInfo";
import { getCityJSX, getCountryJSX } from "../utils/components/rrcTooltip";
import { contentfontSize as fontSize } from "../themes/tooltip";

/**
 * @param {object} props
 * @param {string} props.rrcName
 * @returns {ReactElement}
 */
export const RRCTooltip = ({ rrcName }) => {
  const {
    data: info,
    loading: infoLoading,
    error: infoError,
  } = useRRCInfo(rrcName);

  const risDocsURL = "https://ris.ripe.net/docs/10_routecollectors.html";

  const cityJSX = getCityJSX(info, infoLoading, infoError);
  const countryJSX = getCountryJSX(info, infoLoading, infoError);

  const contents = [
    {
      key: "Name",
      keyJSX: (
        <Typography sx={{ width: "50%", color: "gray3.main", fontSize }}>
          City
        </Typography>
      ),
      value: (
        <Typography sx={{ width: "50%", color: "blue1.main", fontSize }}>
          {cityJSX}
        </Typography>
      ),
    },
    {
      key: "Country",
      keyJSX: (
        <Typography sx={{ width: "50%", color: "gray3.main", fontSize }}>
          Country
        </Typography>
      ),
      value: <Box sx={{ width: "50%", pt: "0.26rem" }}>{countryJSX}</Box>,
    },
  ];
  const moreInfoLinks = [{ name: "RIS Docs", url: risDocsURL }];

  return (
    <Tooltip
      title={rrcName}
      contents={contents}
      moreInfoLinks={moreInfoLinks}
    />
  );
};
