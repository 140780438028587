import { ReactElement } from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";

/**
 * @param {object} props
 * @param {number} props.value
 * @returns {ReactElement}
 */
export const LinearProgressWithLabel = ({ value }) => {
  return (
    <Stack sx={{ alignItems: "center" }} direction="row">
      <LinearProgress
        sx={{ width: "100%", maxWidth: "10rem", mr: 1 }}
        variant="determinate"
        value={value}
      />
      <Typography sx={{ fontSize: "0.875rem" }}>{`${value}%`}</Typography>
    </Stack>
  );
};
