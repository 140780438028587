import { ReactElement } from "react";
import {
  getGraphqlAPILink,
  getTextFieldFilterTypeColumn,
  getAnswerTableData,
  getSubscription,
} from "../other/utils";
import { QueryAnswerInputAutocomplete } from "./QueryAnswerInputAutocomplete";
import { QueryEntry } from "./QueryEntry";
import { TableCell, TableRow } from "@mui/material";
import { useSelectedValue } from "../hooks/useSelectedValue";
import { usePrefixesAnnouncedToNeighbor } from "../hooks/usePrefixesAnnouncedToNeighbor";
import {
  getCommunitiesColumn,
  getPrefixesAnnouncedToNeighbor,
  isQueryTableRowExpandable,
} from "../utils/queries";
import {
  COLUMNS_ENUM,
  COMMUNITIES_COLUMN_INDEX,
  PREFIXES_ANNOUNCED_TO_NEIGHBOR_ENUM,
  SELECT_ASN,
} from "../constants/queries";
import { formatASN, formatPrefix } from "../utils/formatting";
import { useAtom } from "jotai";
import { displayedConfiguredASNsAtom } from "../other/store";
import { QueryAnswerInput } from "./QueryAnswerInput";
import { PrefixesAnnouncedToNeighborDocument } from "../graphql/generated/operations";
import { getQueryAnswerTableStateAtom } from "../utils/other/store";
import { getRowID } from "../utils/components/prefixesAnnouncedToNeighborQuery";

const MY_ASN_COLUMN = 0;
const PREFIX_COLUMN = 1;

/**
 * @param {object} props
 * @param {string[]} props.neighborsToSelect
 * @returns {ReactElement}
 */
export const PrefixesAnnouncedToNeighborQuery = ({ neighborsToSelect }) => {
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(displayedConfiguredASNsAtom);
  const {
    selectedValue: selectedNeighbor,
    setSelectedValue: setSelectedNeighbor,
    hasSelectedValue: hasSelectedNeighbor,
  } = useSelectedValue({ defaultValue: SELECT_ASN });
  const {
    data: prefixesData,
    fetching,
    error,
  } = usePrefixesAnnouncedToNeighbor(selectedNeighbor, hasSelectedNeighbor);
  const {
    isConfiguredASVisibleAtom,
    isPrefixVisibleAtom,
    isCommunitiesVisibleAtom,
  } = getQueryAnswerTableStateAtom({
    atomKey: PREFIXES_ANNOUNCED_TO_NEIGHBOR_ENUM.atomKey,
  });
  const [isConfiguredASVisible] = useAtom(isConfiguredASVisibleAtom);
  const [isPrefixVisible] = useAtom(isPrefixVisibleAtom);
  const [isCommunitiesVisible] = useAtom(isCommunitiesVisibleAtom);

  const answerTableData = getAnswerTableData(
    hasSelectedNeighbor,
    prefixesData,
    getPrefixesAnnouncedToNeighbor
  );

  const answerTableColumns = [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.CONFIGURED_AS.name, {
      display: isConfiguredASVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASN(answerTableData, dataIndex, MY_ASN_COLUMN),
    }),
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.PREFIX.name, {
      display: isPrefixVisible,
      customBodyRenderLite: (dataIndex) =>
        formatPrefix(answerTableData, dataIndex, PREFIX_COLUMN),
    }),
    getCommunitiesColumn(answerTableData, isCommunitiesVisible),
  ];

  return (
    <QueryEntry
      questionTitle={PREFIXES_ANNOUNCED_TO_NEIGHBOR_ENUM.questionTitle}
      answerInput={
        <QueryAnswerInput
          title={PREFIXES_ANNOUNCED_TO_NEIGHBOR_ENUM.answerTitle}
          textField={
            <QueryAnswerInputAutocomplete
              options={neighborsToSelect}
              selectedItem={selectedNeighbor}
              setSelectedItem={setSelectedNeighbor}
              loading={configuredASNsFetching}
              error={configuredASNsError}
            />
          }
        />
      }
      loading={fetching}
      error={error}
      hasSelectedValue={hasSelectedNeighbor}
      answerTableData={answerTableData}
      answerTableColumns={answerTableColumns}
      answerTableOptions={{
        expandableRows: true,
        isRowExpandable: (dataIndex) =>
          isQueryTableRowExpandable(dataIndex, answerTableData),
        renderExpandableRow: (rowData, rowMeta) => {
          const formattedCommunities = answerTableData[rowMeta.rowIndex][
            COMMUNITIES_COLUMN_INDEX
          ]?.split(" ").map((x) => `${x}\n`);
          return (
            <TableRow>
              <TableCell sx={{ whiteSpace: "pre" }} colSpan={1}>
                <b>Communities:</b>
                <br />
                {formattedCommunities}
              </TableCell>
            </TableRow>
          );
        },
      }}
      graphqlAPILink={getGraphqlAPILink(
        getSubscription(PrefixesAnnouncedToNeighborDocument),
        JSON.stringify({ asns: configuredASNs, neighborASN: selectedNeighbor })
      )}
      atomKey={PREFIXES_ANNOUNCED_TO_NEIGHBOR_ENUM.atomKey}
      getRowID={getRowID}
    />
  );
};
