import { ReactElement } from "react";
import { childrenButton } from "../types/components/drawerDropdown";
import { DrawerDropdownChild } from "./DrawerDropdownChild";

/**
 * @param {object} props
 * @param {childrenButton[]} props.childrenButtons
 * @param {boolean} props.isCategorySelected
 * @returns {ReactElement[]}
 */
export const DrawerDropdownChildren = ({
  childrenButtons,
  isCategorySelected,
}) => {
  /** @type {ReactElement[]} */
  const buttons = [];

  childrenButtons.map((childrenButton) =>
    buttons.push(
      <DrawerDropdownChild
        key={childrenButton.title}
        title={childrenButton.title}
        url={childrenButton.path}
        isCategorySelected={isCategorySelected}
      />
    )
  );

  return buttons;
};
