import { App } from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { SWRConfig } from "swr";
import { globalTheme } from "../utils/components/providers";

export const Providers = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      useRefreshTokens={true}
    >
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <Router>
          <SWRConfig
            value={{
              fetcher: (...args) => fetch(...args).then((res) => res.json()),
            }}
          >
            <App />
          </SWRConfig>
        </Router>
      </ThemeProvider>
    </Auth0Provider>
  );
};
