import { ReactElement } from "react";
import { childrenButton } from "../types/components/drawerDropdown";
import Accordion from "@mui/material/Accordion";
import { DrawerDropdownTitle } from "./DrawerDropdownTitle";
import { DrawerDropdownChildren } from "./DrawerDropdownChildren";
import { useLocation } from "react-router";
import { isDrawerDropdownSelected } from "../utils/navigation";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.url
 * @param {childrenButton[]} props.childrenButtons
 * @returns {ReactElement}
 */
export const DrawerDropdown = ({ title, url, childrenButtons }) => {
  const location = useLocation();
  const isCategorySelected = isDrawerDropdownSelected(url, location.pathname);

  const accordionSX = {
    backgroundColor: (theme) => theme.color.bg.drawer,
    boxShadow: "none",
  };

  return (
    <Accordion
      sx={accordionSX}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <DrawerDropdownTitle
        title={title}
        isCategorySelected={isCategorySelected}
      />
      <DrawerDropdownChildren
        childrenButtons={childrenButtons}
        isCategorySelected={isCategorySelected}
      />
    </Accordion>
  );
};
