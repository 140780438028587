import { atom } from "jotai";
import { COLUMNS_ENUM } from "../constants/components/rpkiRoas";
import {
  INITIAL_PAGE,
  INITIAL_ROWS_PER_PAGE,
} from "../constants/hooks/usePagination";
import { getInitialDataResult } from "../constants/store";
import { isConfigurationReadyAtom } from "../other/store";

export const rpkiRoasTablePageAtom = atom(INITIAL_PAGE);
export const rpkiRoasTableRowsPerPageAtom = atom(INITIAL_ROWS_PER_PAGE);
export const expandedRowIDsAtom = atom(new Set());
export const rpkiRoasTableIsSubscriptionActiveAtom = atom((get) =>
  get(isConfigurationReadyAtom)
);
export const rpkiRoasTablePrefixFilterAtom = atom("");
export const rpkiRoasTableMaxLengthFilterAtom = atom("");
export const rpkiRoasTableASFilterAtom = atom("");
export const rpkiRoasTableIPVersionFilterAtom = atom("");
export const rpkiRoasTableMaskLengthFilterAtom = atom("");
export const rpkiRoasTableSourceFilterAtom = atom("");
export const rpkiRoasTableFilterStateAtom = atom((get) => ({
  prefixFilter: get(rpkiRoasTablePrefixFilterAtom),
  maxLengthFilter: get(rpkiRoasTableMaxLengthFilterAtom),
  asFilter: get(rpkiRoasTableASFilterAtom),
  ipVersionFilter: get(rpkiRoasTableIPVersionFilterAtom),
  maskLengthFilter: get(rpkiRoasTableMaskLengthFilterAtom),
  sourceFilter: get(rpkiRoasTableSourceFilterAtom),
}));
export const isPrefixVisibleAtom = atom(true);
export const isMaxLengthVisibleAtom = atom(true);
export const isASVisibleAtom = atom(true);
export const isSourceVisibleAtom = atom(true);
export const isIPVersionVisibleAtom = atom(false);
export const isMaskLengthVisibleAtom = atom(false);
export const rpkiRoasTableSubscriptionAtom = atom(getInitialDataResult([""]));
export const rpkiRoasTableCountSubscriptionAtom = atom(
  getInitialDataResult([""])
);
export const sortOrderColumnNameAtom = atom(COLUMNS_ENUM.PREFIX.name);
export const sortOrderDirectionAtom = atom("asc");
