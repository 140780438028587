import { Stack } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
// import { PrefixesMyASNOriginatesQuery } from "../components/PrefixesMyASNOriginatesQuery";
import { NeighborsToWhichPrefixIsAnnouncedQuery } from "../components/NeighborsToWhichPrefixIsAnnouncedQuery";
import { PrefixesAnnouncedToNeighborQuery } from "../components/PrefixesAnnouncedToNeighborQuery";
import { TITLE_ENUM, HELP_TEXT_ENUM } from "../constants/pageHeader";
import { PathsRelatedToPrefixQuery } from "../components/PathsRelatedToPrefixQuery";
import { useAtom } from "jotai";
import {
  allASNsAtom,
  asnsThatOriginatePrefixesAtom,
  displayedConfiguredASNsAtom,
  neighborsAtom,
  prefixesMyASNsOriginateAtom,
} from "../other/store";
import { PathsBetweenASNsQuery } from "../components/PathsBetweenASNsQuery";
import {
  getASNsToSelect,
  getPrefixesToSelect,
} from "../utils/components/queries";
import { PrefixesFromOriginASNVisibleFromASNQuery } from "../components/PrefixesFromOriginASNVisibleFromASNQuery";
import { useState } from "react";
// import { BGPNeighborsQuery } from "../components/BGPNeighborsQuery";

export const Queries = () => {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(displayedConfiguredASNsAtom);
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] =
    useAtom(allASNsAtom);
  const [{ data: prefixesMyASNsOriginate }] = useAtom(
    prefixesMyASNsOriginateAtom
  );
  // const { neighbors, pairs } = useNeighbors(configuredASNs);
  const [{ data: neighbors }] = useAtom(neighborsAtom);
  const [
    { data: originASNs, fetching: originASNsLoading, error: originASNsError },
  ] = useAtom(asnsThatOriginatePrefixesAtom);

  const confASNsToSelect = getASNsToSelect(configuredASNs);
  const asnsToSelect = getASNsToSelect(asns);
  const originASNsToSelect = getASNsToSelect(originASNs);
  const prefixesToSelect = getPrefixesToSelect(prefixesMyASNsOriginate);
  const neighborsToSelect = getASNsToSelect(neighbors);

  return (
    <Stack>
      <PageTitle
        text={TITLE_ENUM.api.queries}
        helpText={HELP_TEXT_ENUM.api.queries}
        isHelpTextOpen={isHelpTextOpen}
        setIsHelpTextOpen={setIsHelpTextOpen}
      />
      {/* <PrefixesMyASNOriginatesQuery asnsToSelect={asnsToSelect} /> */}
      <NeighborsToWhichPrefixIsAnnouncedQuery
        prefixesToSelect={prefixesToSelect}
      />
      <PrefixesAnnouncedToNeighborQuery neighborsToSelect={neighborsToSelect} />
      <PathsRelatedToPrefixQuery prefixesToSelect={prefixesToSelect} />
      <PathsBetweenASNsQuery
        myASNsToSelect={confASNsToSelect}
        otherASNsToSelect={asnsToSelect}
        myASNLoading={configuredASNsFetching}
        myASNError={configuredASNsError}
        otherASNLoading={asnsFetching}
        otherASNError={asnsError}
      />
      <PrefixesFromOriginASNVisibleFromASNQuery
        originASNsToSelect={originASNsToSelect}
        originASNsToSelectLoading={originASNsLoading}
        originASNsToSelectError={originASNsError}
        visibleFromASNsToSelect={confASNsToSelect}
        visibleFromASNsToSelectLoading={configuredASNsFetching}
        visibleFromASNsToSelectError={configuredASNsError}
      />
      {/* <BGPNeighborsQuery pairs={pairs} /> */}
    </Stack>
  );
};
