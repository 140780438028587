import { ReactElement, useEffect, useState } from "react";
import { Close } from "../types/components/dialog";
import { Dialog, Stack, Tabs, Typography } from "@mui/material";
import { PageDivider } from "./PageDivider";
import { tabsJSX } from "../utils/components/addConfPrefixDialog";
import { tabToTitle } from "../constants/components/addConfPrefixDialog";
import { useTabs } from "../hooks/useTabs";
import { AddConfPrefixManuallyTab } from "./AddConfPrefixManuallyTab";
import { AddConfPrefixFromDataServiceTab } from "./AddConfPrefixFromDataServiceTab";
import { useSelectedValue } from "../hooks/useSelectedValue";
import { SELECT_AS_FILTER } from "../constants/queries";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {Close} props.close
 * @param {Function} props.openSuccessSnackbar
 * @param {Function} props.setSnackbarMessage
 * @returns {ReactElement}
 */
export const AddConfPrefixDialog = ({
  isOpen,
  close,
  openSuccessSnackbar,
  setSnackbarMessage,
}) => {
  const { selectedTab, onTabChange } = useTabs();
  const [prefixInput, setPrefixInput] = useState([]);
  const [prefixFailureReason, setPrefixFailureReason] = useState("");
  const {
    selectedValue: selectedASN,
    setSelectedValue: setSelectedASN,
    hasSelectedValue: hasSelectedASN,
  } = useSelectedValue({
    defaultValue: SELECT_AS_FILTER,
  });
  const [prefixesFailureReason, setPrefixesFailureReason] = useState("");

  const clearState = () => {
    setPrefixInput([]);
    setPrefixFailureReason("");
    setPrefixesFailureReason("");
  };

  useEffect(() => {
    if (!isOpen) {
      clearState();
    }
  }, [isOpen]);

  /**
   * @param {string} asn
   */
  const setSelectedASNCallback = (asn) => {
    setSelectedASN(asn);
    setPrefixesFailureReason("");
  };

  const title = tabToTitle[selectedTab];
  const tabContent =
    selectedTab === 0 ? (
      <AddConfPrefixManuallyTab
        close={close}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        prefixInput={prefixInput}
        setPrefixInput={setPrefixInput}
        failureReason={prefixFailureReason}
        setFailureReason={setPrefixFailureReason}
      />
    ) : (
      <AddConfPrefixFromDataServiceTab
        close={close}
        openSuccessSnackbar={openSuccessSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        selectedASN={selectedASN}
        setSelectedASN={setSelectedASNCallback}
        hasSelectedASN={hasSelectedASN}
        failureReason={prefixesFailureReason}
        setFailureReason={setPrefixesFailureReason}
      />
    );

  return (
    <Dialog open={isOpen} onClose={close}>
      <Stack
        sx={{
          p: "2rem",
          width: "25rem",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "primary.main" }} fontSize="1.25rem">
          {title}
        </Typography>
        <Stack sx={{ my: "1rem" }}>
          <Tabs
            value={selectedTab}
            onChange={onTabChange}
            variant="fullWidth"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "blue3.main",
                height: "0.2rem",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "blue3.main",
              },
            }}
          >
            {tabsJSX}
          </Tabs>
          <PageDivider
            customStyles={{ mx: 0, backgroundColor: "gray5.main" }}
          />
        </Stack>
        {tabContent}
      </Stack>
    </Dialog>
  );
};
