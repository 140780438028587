import { useAtom } from "jotai";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import {
  logoButtonAriaLabel,
  menuToggleButtonAriaLabel,
} from "../other/constants";
import { SvgWrapper } from "./SvgWrapper";
import { AccountMenu } from "./AccountMenu";
import { Stack } from "@mui/material";
import { isDrawerOpenAtom } from "../other/store";
import { HOME_PAGE_PATH } from "../constants/paths";

export const ApplicationBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
  const navigate = useNavigate();

  const onMenuButtonClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onLogoClick = () => {
    navigate(HOME_PAGE_PATH);
  };

  return (
    <AppBar
      sx={{
        backgroundColor: (theme) => theme.color.bg.appBar,
        position: "fixed",
        left: 0,
        top: 0,
        height: (theme) => theme.space.height.appBar,
        justifyContent: "center",
      }}
    >
      <Toolbar disableGutters>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px="0.5rem"
          width="100%"
        >
          <IconButton
            aria-label={menuToggleButtonAriaLabel}
            onClick={onMenuButtonClick}
          >
            <SvgWrapper
              filename="MenuIcon"
              sx={{ color: "white", fontSize: 28 }}
            />
          </IconButton>
          <IconButton aria-label={logoButtonAriaLabel} onClick={onLogoClick}>
            <SvgWrapper
              filename="CodeBGPLogo"
              sx={{ width: 240, height: 32 }}
              viewBox="0 0 768 76"
            />
          </IconButton>
          <AccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
