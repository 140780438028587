export const RIS_LIVE = "RIS_LIVE";
export const BGP_ROUTER = "BGP_ROUTER";
export const CODEBGP_MONITOR = "CODEBGP_MONITOR";
export const RPKI = "RPKI";
export const DATA_SERVICES = Symbol("DATA_SERVICES");
export const ROUTES = Symbol("ROUTES");
export const RPKI_ROAS = Symbol("RPKI_ROAS");

export const COLUMNS_ENUM = Object.freeze({
  DATA_SERVICE: { name: "Data Service" },
  ROUTE_COLLECTOR: { name: "Route Collector" },
  IP: { name: "Peer IP" },
  ASN: { name: "ASN" },
  LAST_UPDATE: { name: "Last Update" },
  URI: { name: "URI" },
  ENDPOINT: { name: "Endpoint" },
  CITY: { name: "City" },
  COUNTRY: { name: "Country" },
  CONTINENT: { name: "Continent" },
});

export const DEFAULT_COLUMNS_ENUM = Object.freeze({
  DATA_SERVICE: { order: 0, name: COLUMNS_ENUM.DATA_SERVICE.name },
  ROUTE_COLLECTOR: { order: 1, name: COLUMNS_ENUM.ROUTE_COLLECTOR.name },
  IP: { order: 2, name: COLUMNS_ENUM.IP.name },
  ASN: { order: 3, name: COLUMNS_ENUM.ASN.name },
  CITY: { order: 4, name: COLUMNS_ENUM.CITY.name },
  COUNTRY: { order: 5, name: COLUMNS_ENUM.COUNTRY.name },
  CONTINENT: { order: 6, name: COLUMNS_ENUM.CONTINENT.name },
});

export const ROUTES_COLUMNS_ENUM = Object.freeze({
  DATA_SERVICE: { order: 0, name: COLUMNS_ENUM.DATA_SERVICE.name },
  ROUTE_COLLECTOR: { order: 1, name: COLUMNS_ENUM.ROUTE_COLLECTOR.name },
  IP: { order: 2, name: COLUMNS_ENUM.IP.name },
  ASN: { order: 3, name: COLUMNS_ENUM.ASN.name },
  CITY: { order: 4, name: COLUMNS_ENUM.CITY.name },
  COUNTRY: { order: 5, name: COLUMNS_ENUM.COUNTRY.name },
  CONTINENT: { order: 6, name: COLUMNS_ENUM.CONTINENT.name },
  LAST_UPDATE: { order: 7, name: COLUMNS_ENUM.LAST_UPDATE.name },
});

export const RPKI_ROAS_COLUMNS_ENUM = Object.freeze({
  DATA_SERVICE: { order: 0, name: COLUMNS_ENUM.DATA_SERVICE.name },
  URI: { order: 1, name: COLUMNS_ENUM.URI.name },
  ENDPOINT: { order: 2, name: COLUMNS_ENUM.ENDPOINT.name },
});

export const RIS_LIVE_DATA_SERVICE_COLUMNS_ENUM = Object.freeze({
  ROUTE_COLLECTOR: { order: 0, name: COLUMNS_ENUM.ROUTE_COLLECTOR.name },
  IP: { order: 1, name: COLUMNS_ENUM.IP.name },
  ASN: { order: 2, name: COLUMNS_ENUM.ASN.name },
  CITY: { order: 3, name: COLUMNS_ENUM.CITY.name },
  COUNTRY: { order: 4, name: COLUMNS_ENUM.COUNTRY.name },
  CONTINENT: { order: 5, name: COLUMNS_ENUM.CONTINENT.name },
});

export const BGP_ROUTER_DATA_SERVICE_COLUMNS_ENUM = Object.freeze({
  IP: { order: 0, name: COLUMNS_ENUM.IP.name },
  ASN: { order: 1, name: COLUMNS_ENUM.ASN.name },
  CITY: { order: 2, name: COLUMNS_ENUM.CITY.name },
  COUNTRY: { order: 3, name: COLUMNS_ENUM.COUNTRY.name },
  CONTINENT: { order: 4, name: COLUMNS_ENUM.CONTINENT.name },
});

export const RPKI_ROAS_DATA_SERVICE_COLUMNS_ENUM = Object.freeze({
  URI: { order: 0, name: COLUMNS_ENUM.URI.name },
  ENDPOINT: { order: 1, name: COLUMNS_ENUM.ENDPOINT.name },
});
