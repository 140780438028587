import { useState } from "react";
import { ret } from "../types/hooks/useSelectedValue";

/**
 * @param {object} params
 * @param {string} params.defaultValue
 * @returns {ret}
 */
export const useSelectedValue = ({ defaultValue }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const hasSelectedValue = selectedValue !== defaultValue;

  return { selectedValue, setSelectedValue, hasSelectedValue };
};
