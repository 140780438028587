import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import {
  getInitialHiddenResult,
  getInitialDataResult,
} from "../constants/store";
import { getSerializableSetStorage } from "../utils/other/store";
import { getTenantName } from "./utils";

export const isDrawerOpenAtom = atom(true);

export const configuredDataServicesAtom = atom(getInitialDataResult({}));
export const configuredASNsAtom = atom(getInitialDataResult(0));
export const configuredPrefixesAtom = atom(getInitialDataResult(""));
export const configuredAlertsAtom = atom(getInitialDataResult(""));
export const alertsAtom = atom(getInitialDataResult(""));
export const allASNsAtom = atom(getInitialDataResult(0));
export const isConfigurationReadyAtom = atom((get) => {
  const { fetching: configuredASNsFetching, error: configuredASNsError } = get(
    displayedConfiguredASNsAtom
  );
  const {
    fetching: configuredPrefixesFetching,
    error: configuredPrefixesError,
  } = get(displayedConfiguredPrefixesAtom);
  const {
    fetching: configuredDataServicesFetching,
    error: configuredDataServicesError,
  } = get(displayedConfiguredDataServicesAtom);

  return (
    !configuredASNsFetching &&
    !configuredASNsError &&
    !configuredPrefixesFetching &&
    !configuredPrefixesError &&
    !configuredDataServicesFetching &&
    !configuredDataServicesError
  );
});

export const asnsTableAtom = atom(getInitialDataResult([""]));
export const peeringsTableAtom = atom(getInitialDataResult([""]));

export const prefixesMyASNsOriginateAtom = atom(getInitialDataResult(""));
export const rpkiPrefixesMyASNsOriginateAtom = atom(getInitialDataResult(""));
export const neighborsAtom = atom(getInitialDataResult(0));
export const asnsThatOriginatePrefixesAtom = atom(getInitialDataResult(0));

export const hiddenASNsAtom = atomWithStorage(
  `${getTenantName()}-hiddenASNs`,
  getInitialHiddenResult(0),
  getSerializableSetStorage(createJSONStorage)
);
export const hiddenPrefixesAtom = atomWithStorage(
  `${getTenantName()}-hiddenPrefixes`,
  getInitialHiddenResult(""),
  getSerializableSetStorage(createJSONStorage)
);
export const hiddenDataServicesAtom = atomWithStorage(
  `${getTenantName()}-hiddenDataServices`,
  getInitialHiddenResult(""),
  getSerializableSetStorage(createJSONStorage)
);

export const displayedConfiguredASNsAtom = atom((get) => {
  const configuredASNs = get(configuredASNsAtom);
  const hiddenASNs = get(hiddenASNsAtom);
  if (hiddenASNs.size === 0) return configuredASNs;
  const displayedConfiguredASNs = configuredASNs.data?.filter(
    (asn) => !hiddenASNs.has(asn)
  );
  return { ...configuredASNs, data: displayedConfiguredASNs };
});

export const displayedConfiguredPrefixesAtom = atom((get) => {
  const configuredPrefixes = get(configuredPrefixesAtom);
  const hiddenPrefixes = get(hiddenPrefixesAtom);
  if (hiddenPrefixes.size === 0) return configuredPrefixes;
  const displayedConfiguredPrefixes = configuredPrefixes.data?.filter(
    (prefix) => !hiddenPrefixes.has(prefix)
  );
  return { ...configuredPrefixes, data: displayedConfiguredPrefixes };
});

export const displayedConfiguredDataServicesAtom = atom((get) => {
  const configuredDataServiceObjects = get(configuredDataServicesAtom);
  const configuredDataServiceNames = configuredDataServiceObjects.data?.map(
    ({ data_service }) => data_service
  );
  const configuredDataServices = {
    ...configuredDataServiceObjects,
    data: configuredDataServiceNames,
  };
  const hiddenDataServices = get(hiddenDataServicesAtom);
  if (hiddenDataServices.size === 0) return configuredDataServices;
  const displayedConfiguredDataServices = configuredDataServices.data?.filter(
    (dataService) => !hiddenDataServices.has(dataService)
  );
  return { ...configuredDataServices, data: displayedConfiguredDataServices };
});

export const filterOnASAnyAtom = atom((get) => {
  const configuredDataServiceObjects = get(configuredDataServicesAtom);
  return (
    configuredDataServiceObjects.data?.some(
      ({ filter_on_autonomous_system }) => filter_on_autonomous_system === "any"
    ) ?? false
  );
});
