export const SELECT_ASN = "(Number)";
export const SELECT_AS_FILTER = "(Select AS Filter)";
export const SELECT_PREFIX = "(Select Prefix)";
export const COMMUNITIES_COLUMN_INDEX = 2;
export const MAX_NUM_OF_COMMUNITIES_TO_SHOW = 3;
// export const PREFIXES_MY_ASN_ORIGINATES_ENUM = Object.freeze({
//   questionTitle: "Which are the prefixes the configured AS originates ?",
//   answerTitle: "Prefixes originated from the configured AS",
// });
export const NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM = Object.freeze({
  questionTitle: "By which neighbor ASes is prefix P visible ?",
  answerTitle1: "Neighbors by which prefix",
  answerTitle2: "is visible",
  atomKey: "NeighborsToWhichPrefixIsAnnounced",
});
export const PREFIXES_ANNOUNCED_TO_NEIGHBOR_ENUM = Object.freeze({
  questionTitle: "Which prefixes are visible by neighbor N ?",
  answerTitle: "Prefixes visible by neighbor",
  atomKey: "PrefixesAnnouncedToNeighbor",
});
export const PATHS_RELATED_TO_PREFIX_ENUM = Object.freeze({
  questionTitle: "What are the visible AS paths related to prefix P ?",
  answerTitle: "Paths related to prefix",
  atomKey: "PathsRelatedToPrefix",
});
// export const BGP_NEIGHBORS_ENUM = Object.freeze({
//   questionTitle: "Which are my BGP neighbors ?",
//   answerTitle: "Your BGP neighbors:",
// });
export const PATHS_BETWEEN_ASNS_ENUM = Object.freeze({
  questionTitle: "What are the AS paths between the configured AS A and AS B ?",
  answerTitle1: "Paths between the configured AS",
  answerTitle2: "and AS",
  atomKey: "PathsBetweenASNs",
});
export const PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN = Object.freeze({
  questionTitle:
    "What prefixes from AS A are visible from the configured AS B ?",
  answerTitle1: "Prefixes from AS",
  answerTitle2: "visible from the configured AS",
  atomKey: "PrefixesFromOriginASNVisibleFromASN",
});

export const COLUMNS_ENUM = Object.freeze({
  CONFIGURED_AS: { name: "Configured AS" },
  NEIGHBOR_AS: { name: "Neighbor AS" },
  PREFIX: { name: "Prefix" },
  AS_PATH: { name: "AS Path" },
  COMMUNITIES: { name: "Communities" },
});
