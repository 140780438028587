export const PATHS = Object.freeze({
  dashboard: {
    overview: "/dashboard/overview",
    setup: {
      index: "/dashboard/setup",
      autonomousSystems: "/dashboard/setup/as-filters",
      prefixes: "/dashboard/setup/prefix-filters",
      alerts: "/dashboard/setup/alert-rules",
      dataServices: "/dashboard/setup/data-services",
    },
    lookingglass: {
      index: "/dashboard/lookingglass",
      prefixes: "/dashboard/lookingglass#prefixes",
      v4Prefixes: "/dashboard/lookingglass?ipVersionFilter=4&#prefixes",
      v6Prefixes: "/dashboard/lookingglass?ipVersionFilter=6&#prefixes",
      autonomousSystems: "/dashboard/lookingglass#autonomous-systems",
      peerings: "/dashboard/lookingglass#peerings",
      routes: "/dashboard/lookingglass#routes",
      rpkiRoas: "/dashboard/lookingglass#rpki-roas",
      dataServices: "/dashboard/lookingglass#data-services",
    },
    alerts: {
      index: "/dashboard/alerts",
      active: "/dashboard/alerts#active",
      resolved: "/dashboard/alerts#resolved",
    },
    api: {
      index: "/dashboard/api",
      queries: "/dashboard/api/queries",
      editor: "/dashboard/api/editor",
    },
    alert_manager: {
      main_link: "-api.codebgp.com/alerts",
      docs_link: "https://docs.codebgp.com/alerts/alertmanager/",
    },
    graphana_dashboards: {
      main_link: "-api.codebgp.com/visualization/dashboards",
      docs_link: "https://docs.codebgp.com/dashboards/grafana/",
    },
    prometheus: {
      main_link: "-api.codebgp.com/metrics",
      docs_link: "https://docs.codebgp.com/metrics/prometheus/",
    },
    loki: {
      main_link:
        "-api.codebgp.com/visualization/d/qZoOZZbVk/update-logs?orgId=1",
      docs_link: "https://docs.codebgp.com/logs/loki/",
    },
    // alerts: {
    //   index: "/dashboard/alerts",
    //   stream: "/dashboard/alerts/stream",
    //   configuration: "/dashboard/alerts/configuration",
    // },
    // visibility: "/dashboard/visibility",
    settings: "/dashboard/settings",
    integrations: "/dashboard/integrations",
    opensource: "/dashboard/opensource",
  },
});

export const HOME_PAGE_PATH = PATHS.dashboard.overview;
export const SETUP_AUTONOMOUS_SYSTEMS_PATH =
  PATHS.dashboard.setup.autonomousSystems;
export const GRAPHQL_API_PATH = PATHS.dashboard.api.editor;
