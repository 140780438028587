import { useRoutesCountSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useRoutesCount";
import {
  displayedConfiguredASNsAtom,
  displayedConfiguredPrefixesAtom,
  displayedConfiguredDataServicesAtom,
  filterOnASAnyAtom,
} from "../other/store";
import { useData } from "./useData";
import { useAtom } from "jotai";
import { routesCountAtom } from "../store/overviewContent";
import { getConfFilters } from "../utils/components/routes";

export const useRoutesCount = () => {
  const [{ data: asns, fetching: asnsFetching, error: asnsError }] = useAtom(
    displayedConfiguredASNsAtom
  );

  const [{ data: prefixes, fetching: prefixesFetching, error: prefixesError }] =
    useAtom(displayedConfiguredPrefixesAtom);

  const [
    {
      data: dataServices,
      fetching: dataServicesFetching,
      error: dataServicesError,
    },
  ] = useAtom(displayedConfiguredDataServicesAtom);
  const [filterOnASAny] = useAtom(filterOnASAnyAtom);

  const confState = {
    fetching: asnsFetching || prefixesFetching || dataServicesFetching,
    error: asnsError ?? prefixesError ?? dataServicesError,
  };

  useData({
    operation: useRoutesCountSubscription,
    options: {
      variables: {
        where: getConfFilters(asns, prefixes, dataServices, filterOnASAny),
      },
      pause: (!asns && !prefixes) || !dataServices,
    },
    formatter,
    confState,
    atom: routesCountAtom,
  });
};
