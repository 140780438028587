import { useAlertsSubscription } from "../graphql/generated/operations";
import { formatter } from "../utils/hooks/useAlerts";
import { alertsAtom } from "../other/store";
import { useData } from "./useData";

export const useAlerts = () => {
  useData({
    operation: useAlertsSubscription,
    formatter,
    atom: alertsAtom,
  });
};
