import { useState, useEffect } from "react";
import { auth0HookGetter } from "../other/utils";

export const useAccessToken = () => {
  const [token, setToken] = useState();
  const { isLoading, getAccessTokenSilently } = auth0HookGetter()();

  useEffect(() => {
    let setIntervalID;

    (async () => {
      setToken(await getAccessTokenSilently());
      // Attempt to refresh the token every 20 seconds.
      // It should refresh only upon expiration.
      setIntervalID = setInterval(async () => {
        setToken(await getAccessTokenSilently());
      }, 20000);
    })();

    return () => {
      clearInterval(setIntervalID);
    };
  }, [isLoading, getAccessTokenSilently]);

  return token;
};
