import { ReactElement } from "react";
import { content, moreInfoLink } from "../types/components/tooltip";
import { LinearProgress, Link, Stack, Typography } from "@mui/material";
import { TooltipDivider } from "./TooltipDivider";
import { contentfontSize as fontSize } from "../themes/tooltip";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {content[]} [props.contents]
 * @param {moreInfoLink[]} props.moreInfoLinks
 * @returns {ReactElement}
 */
export const Tooltip = ({ title, contents, moreInfoLinks }) => {
  let contentsJSX = null;
  let contentsJSXWrapper = null;

  if (contents) {
    contentsJSX = contents.map(({ key, keyJSX, value }) => (
      <Stack key={key} direction="row" justifyContent="space-between">
        {keyJSX}
        {value}
      </Stack>
    ));
    contentsJSXWrapper = (
      <>
        <Stack spacing={1}>{contentsJSX}</Stack>
        <TooltipDivider />
      </>
    );
  }

  const moreInfoLinksJSX = moreInfoLinks.map(
    ({ name, url, loading, error }) => {
      if (loading) return <LinearProgress key={name} sx={{ my: "0.53rem" }} />;
      if (error) {
        console.error(error);
        return (
          <Typography key={name} sx={{ fontSize, color: "black" }}>
            An error has occurred.
          </Typography>
        );
      }
      if (!url)
        return (
          <Typography key={name} sx={{ fontSize, color: "black" }}>
            Peering DB (N/A)
          </Typography>
        );
      return (
        <Typography key={name} sx={{ fontSize }}>
          <Link
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            sx={{
              color: "blue3.main",
              textDecorationColor: "blue3.main",
            }}
          >
            {name}
          </Link>
        </Typography>
      );
    }
  );

  return (
    <Stack>
      <Typography sx={{ color: "blue2.main", fontWeight: "medium", fontSize }}>
        {title}
      </Typography>
      <TooltipDivider />
      {contentsJSXWrapper}
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ width: "50%", color: "gray3.main", fontSize }}>
          More Info
        </Typography>
        <Stack sx={{ width: "50%" }}>{moreInfoLinksJSX}</Stack>
      </Stack>
    </Stack>
  );
};
