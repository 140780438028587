import { Button, Dialog, Link, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { SvgWrapper } from "./SvgWrapper";

export const RipeDialog = ({ isOpen, close }) => {
  return (
    <Dialog onClose={close} open={isOpen}>
      <Stack p="2rem">
        <SvgWrapper
          filename="RipeNccLogo"
          sx={{ mx: "6.5rem", width: "20rem", height: "4.45rem" }}
          viewBox="0 0 707.9 177.8"
        />
        <Typography px="1rem" pb="0.5rem" pt="2.3rem" fontSize="0.875rem">
          The RIPE Routing Information Service (RIS) is a RIPE NCC service. With
          the help of network operators all over the world, RIS employs a
          globally distributed set of Remote Route Collectors (RRCs), typically
          located at Internet Exchange Points, to collect and store Internet
          routing data. Volunteers peer with the RRCs using the BGP protocol and
          RIS stores the update and withdraw messages. RIS data can be accessed
          via:
          <br />
          <br />
          RIPEstat, the “one-stop shop” for all available information about
          Internet number resources. RIPEstat uses individual widgets to display
          routing and other information;
          <br />
          RIS Live, a real time BGP streaming API allowing server-side filtering
          of BGP messages by prefix or autonomous system;
          <br />
          RIS Raw Data, available for each route collector, with state dumps and
          batches of updates made available periodically;
          <br />
          RISwhois, that searches the latest RIS data for details of an IP
          address using a plaintext “whois”-style interface. It is useful when
          querying RIS data using scripts.
          <br />
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px="1rem"
        >
          <Typography fontSize="0.875rem">
            Learn more at{" "}
            <Link
              href="https://www.ripe.net/ris"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: (theme) => theme.color.other.selectionIndicator,
                textDecorationColor: (theme) =>
                  theme.color.other.selectionIndicator,
              }}
            >
              https://www.ripe.net/ris
            </Link>
          </Typography>
          <Button
            sx={{
              color: (theme) => theme.color.bg.selectedDrawerButton,
              textTransform: "none",
              height: "2.2rem",
              width: "4rem",
              backgroundColor: "white",
              borderStyle: "solid",
              borderWidth: "0.063rem",
              borderColor: (theme) => theme.color.bg.selectedDrawerButton,
              ":hover": {
                color: "white",
                backgroundColor: (theme) => theme.color.bg.selectedDrawerButton,
              },
            }}
            onClick={close}
          >
            <Typography noWrap fontSize="0.875rem">
              Got it
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

RipeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
