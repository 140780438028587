import { atom } from "jotai";
import { getInitialSingleDataResult } from "../constants/store";
import { getDataSourcesCountResult } from "../utils/state";

export const autonomousSystemsCountAtom = atom(getInitialSingleDataResult(0));
export const bgpUpdateRateCountAtom = atom(getInitialSingleDataResult(0));
export const avgBGPUpdateRateCountAtom = atom(getInitialSingleDataResult(0));
export const ipv4PrefixesCountAtom = atom(getInitialSingleDataResult(0));
export const ipv6PrefixesCountAtom = atom(getInitialSingleDataResult(0));
export const peeringsCountAtom = atom(getInitialSingleDataResult(0));
export const routesCountAtom = atom(getInitialSingleDataResult(0));
export const rpkiRoasCountAtom = atom(getInitialSingleDataResult(0));
export const alertRulesCountAtom = atom(getInitialSingleDataResult(0));
export const firingAlertsCountAtom = atom(getInitialSingleDataResult(0));
export const risLiveDataSourcesCountAtom = atom(getInitialSingleDataResult(0));
export const risLiveIPv4DataSourcesCountAtom = atom(
  getInitialSingleDataResult(0)
);
export const risLiveIPv6DataSourcesCountAtom = atom(
  getInitialSingleDataResult(0)
);
export const rpkiDataSourcesCountAtom = atom(getInitialSingleDataResult(0));
export const codebgpMonitorDataSourcesCountAtom = atom(
  getInitialSingleDataResult(0)
);
export const codebgpMonitorIPv4DataSourcesCountAtom = atom(
  getInitialSingleDataResult(0)
);
export const codebgpMonitorIPv6DataSourcesCountAtom = atom(
  getInitialSingleDataResult(0)
);
export const bgpRouterDataSourcesCountAtom = atom(
  getInitialSingleDataResult(0)
);
export const totalNumberOfDataSourcesAtom = atom((get) => {
  const risLiveDataSourcesCount = get(risLiveDataSourcesCountAtom);
  const codebgpMonitorDataSourcesCount = get(
    codebgpMonitorDataSourcesCountAtom
  );
  const bgpRouterDataSourcesCount = get(bgpRouterDataSourcesCountAtom);
  return getDataSourcesCountResult(
    risLiveDataSourcesCount,
    codebgpMonitorDataSourcesCount,
    bgpRouterDataSourcesCount
  );
});
export const totalNumberOfIPv4DataSourcesAtom = atom((get) => {
  const risLiveIPv4DataSourcesCount = get(risLiveIPv4DataSourcesCountAtom);
  const codebgpMonitorIPv4DataSourcesCount = get(
    codebgpMonitorIPv4DataSourcesCountAtom
  );
  const bgpRouterDataSourcesCount = get(bgpRouterDataSourcesCountAtom);
  return getDataSourcesCountResult(
    risLiveIPv4DataSourcesCount,
    codebgpMonitorIPv4DataSourcesCount,
    bgpRouterDataSourcesCount
  );
});
export const totalNumberOfIPv6DataSourcesAtom = atom((get) => {
  const risLiveIPv6DataSourcesCount = get(risLiveIPv6DataSourcesCountAtom);
  const codebgpMonitorIPv6DataSourcesCount = get(
    codebgpMonitorIPv6DataSourcesCountAtom
  );
  const bgpRouterDataSourcesCount = get(bgpRouterDataSourcesCountAtom);
  return getDataSourcesCountResult(
    risLiveIPv6DataSourcesCount,
    codebgpMonitorIPv6DataSourcesCount,
    bgpRouterDataSourcesCount
  );
});
