import { ReactElement, useEffect, useState } from "react";
import { Table } from "./Table";
import { useAtom } from "jotai";
import { peeringsTableAtom } from "../other/store";
import {
  getColumns,
  getCustomOptions,
  getRowID,
  onApplyFiltersButtonClick,
  onViewColumnsChange,
} from "../utils/components/peeringsTable";
import {
  configuredASFilterAtom,
  countAtom,
  expandedRowIDsAtom,
  isConfiguredASVisibleAtom,
  isDataSourcesNumberVisibleAtom,
  isDataSourcesPercentageVisibleAtom,
  isNeighborASVisibleAtom,
  maxDataSourcesNumberFilterAtom,
  maxDataSourcesPercentageFilterAtom,
  minDataSourcesNumberFilterAtom,
  minDataSourcesPercentageFilterAtom,
  neighborASFilterAtom,
  pageAtom,
  rowsPerPageAtom,
} from "../store/peeringsTable";
import { usePagination } from "../hooks/usePagination";
import { ShareUrlButton } from "./ShareUrlButton";
import { Stack } from "@mui/system";
import { Snackbar } from "@mui/material";
import { Alert } from "./Alert";
import { PATHS } from "../constants/paths";

/**
 * @param {object} props
 * @param {object []} props.urlParams
 * @returns {ReactElement}
 */
export const PeeringsTable = ({ urlParams }) => {
  const [{ data: peerings, fetching: peeringsFetching, error: peeringsError }] =
    useAtom(peeringsTableAtom);
  const [configuredASFilter, setConfiguredASFilter] = useAtom(
    configuredASFilterAtom
  );
  const [neighborASFilter, setNeighborASFilter] = useAtom(neighborASFilterAtom);
  const [minDataSourcesNumberFilter, setMinDataSourcesNumberFilter] = useAtom(
    minDataSourcesNumberFilterAtom
  );
  const [maxDataSourcesNumberFilter, setMaxDataSourcesNumberFilter] = useAtom(
    maxDataSourcesNumberFilterAtom
  );
  const [minDataSourcesPercentageFilter, setMinDataSourcesPercentageFilter] =
    useAtom(minDataSourcesPercentageFilterAtom);
  const [maxDataSourcesPercentageFilter, setMaxDataSourcesPercentageFilter] =
    useAtom(maxDataSourcesPercentageFilterAtom);
  const [isConfiguredASVisible, setIsConfiguredASVisible] = useAtom(
    isConfiguredASVisibleAtom
  );
  const [isNeighborASVisible, setIsNeighborASVisible] = useAtom(
    isNeighborASVisibleAtom
  );
  const [isDataSourcesNumberVisible, setIsDataSourcesNumberVisible] = useAtom(
    isDataSourcesNumberVisibleAtom
  );
  const [isDataSourcesPercentageVisible, setIsDataSourcesPercentageVisible] =
    useAtom(isDataSourcesPercentageVisibleAtom);
  const { resetPage } = usePagination({ pageAtom, rowsPerPageAtom });
  const [count, setCount] = useAtom(countAtom);
  const [expandedRowIDs, setExpandedRowIDs] = useAtom(expandedRowIDsAtom);

  const [copiedURL, setCopiedURL] = useState(false);

  useEffect(() => {
    if (urlParams.length !== 0) {
      urlParams.forEach((item) => {
        switch (item[0]) {
          case "configuredASFilter":
            setConfiguredASFilter(item[1]);
            break;
          case "neighborASFilter":
            setNeighborASFilter(item[1]);
            break;
          case "minDataSourcesNumberFilter":
            setMinDataSourcesNumberFilter(item[1]);
            break;
          case "maxDataSourcesNumberFilter":
            setMaxDataSourcesNumberFilter(item[1]);
            break;
          case "minDataSourcesPercentageFilter":
            setMinDataSourcesPercentageFilter(item[1]);
            break;
          case "maxDataSourcesPercentageFilter":
            setMaxDataSourcesPercentageFilter(item[1]);
            break;
          default:
            console.error("Invalid URL parameter!");
        }
      });
    }
  }, []);

  /**
   * @param {object} params
   * @param {Function} params.applyNewFilters
   * @returns {void}
   */
  const onApplyFiltersButtonClickCallback = ({ applyNewFilters }) => {
    onApplyFiltersButtonClick({
      applyNewFilters,
      setConfiguredASFilter,
      setNeighborASFilter,
      setMinDataSourcesNumberFilter,
      setMaxDataSourcesNumberFilter,
      setMinDataSourcesPercentageFilter,
      setMaxDataSourcesPercentageFilter,
      resetPage,
    });
  };

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      setIsConfiguredASVisible,
      setIsNeighborASVisible,
      setIsDataSourcesNumberVisible,
      setIsDataSourcesPercentageVisible,
    });
  };

  const columns = getColumns({
    rows: peerings,
    configuredASFilter,
    neighborASFilter,
    minDataSourcesNumberFilter,
    maxDataSourcesNumberFilter,
    minDataSourcesPercentageFilter,
    maxDataSourcesPercentageFilter,
    setMinDataSourcesNumberFilter,
    setMaxDataSourcesNumberFilter,
    setMinDataSourcesPercentageFilter,
    setMaxDataSourcesPercentageFilter,
    isConfiguredASVisible,
    isNeighborASVisible,
    isDataSourcesNumberVisible,
    isDataSourcesPercentageVisible,
  });
  const customOptions = getCustomOptions({
    peerings,
  });

  const shareUrlData = () => {
    let filters = "";
    if (
      configuredASFilter !== "" &&
      typeof configuredASFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters += "configuredASFilter=" + configuredASFilter + "&";
    }
    if (neighborASFilter !== "" && typeof neighborASFilter !== "undefined") {
      if (filters === "") filters += "?";
      filters += "neighborASFilter=" + neighborASFilter + "&";
    }
    if (
      minDataSourcesNumberFilter !== "" &&
      typeof minDataSourcesNumberFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters +=
        "minDataSourcesNumberFilter=" + minDataSourcesNumberFilter + "&";
    }
    if (
      maxDataSourcesNumberFilter !== "" &&
      typeof maxDataSourcesNumberFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters +=
        "maxDataSourcesNumberFilter=" + maxDataSourcesNumberFilter + "&";
    }
    if (
      minDataSourcesPercentageFilter !== "" &&
      typeof minDataSourcesPercentageFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters +=
        "minDataSourcesPercentageFilter=" +
        minDataSourcesPercentageFilter +
        "&";
    }
    if (
      maxDataSourcesPercentageFilter !== "" &&
      typeof maxDataSourcesPercentageFilter !== "undefined"
    ) {
      if (filters === "") filters += "?";
      filters +=
        "maxDataSourcesPercentageFilter=" +
        maxDataSourcesPercentageFilter +
        "&";
    }
    const serviceURL = `https://${window.location.host}${PATHS.dashboard.lookingglass.index}${filters}#peerings`;
    navigator.clipboard.writeText(serviceURL);
    setCopiedURL(true);
  };

  const handleClose = () => {
    setCopiedURL(false);
  };

  const HeaderElements = () => (
    <>
      <ShareUrlButton onClick={shareUrlData} toolbarIconOrder={-1} />
    </>
  );

  return (
    <Stack>
      <Table
        data={peerings}
        loading={peeringsFetching}
        error={peeringsError}
        columns={columns}
        customOptions={{
          responsive: "standard",
          tableBodyMaxHeight: "70vh",
          customToolbar: HeaderElements,
          textLabels: {
            body: {
              noMatch: "No Peerings found.",
            },
          },
          ...customOptions,
        }}
        pageAtom={pageAtom}
        rowsPerPageAtom={rowsPerPageAtom}
        count={count}
        setCount={setCount}
        onApplyFiltersButtonClick={onApplyFiltersButtonClickCallback}
        onViewColumnsChange={onViewColumnsChangeCallback}
        getRowID={getRowID}
        expandedRowIDs={expandedRowIDs}
        setExpandedRowIDs={setExpandedRowIDs}
        exportDataFileName={"Peerings Table Looking Glass Data"}
      />
      <Snackbar
        open={copiedURL}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Share link copied to clipboard
        </Alert>
      </Snackbar>
    </Stack>
  );
};
