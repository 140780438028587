import { ReactElement } from "react";
import { PrefixesFromOriginAsnVisibleFromAsnSubscription } from "../../graphql/generated/operations";
import { Stack } from "@mui/material";
import { QueryAnswerInput } from "../../components/QueryAnswerInput";
import { QueryAnswerInputAutocomplete } from "../../components/QueryAnswerInputAutocomplete";
import { PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN } from "../../constants/queries";
import { Loading, Error } from "../../types/dataFetching";

/**
 * @param {object} params
 * @param {string[]} params.originASNsToSelect
 * @param {Loading} params.originASNsToSelectLoading
 * @param {Error} params.originASNsToSelectError
 * @param {string[]} params.visibleFromASNsToSelect
 * @param {Loading} params.visibleFromASNsToSelectLoading
 * @param {Error} params.visibleFromASNsToSelectError
 * @param {string} params.selectedOriginASN
 * @param {Function} params.setSelectedOriginASN
 * @param {string} params.selectedVisibleFromASN
 * @param {Function} params.setSelectedVisibleFromASN
 * @returns {ReactElement}
 */
export const getAnswerInputJSX = ({
  originASNsToSelect,
  originASNsToSelectLoading,
  originASNsToSelectError,
  selectedOriginASN,
  setSelectedOriginASN,
  visibleFromASNsToSelect,
  visibleFromASNsToSelectLoading,
  visibleFromASNsToSelectError,
  selectedVisibleFromASN,
  setSelectedVisibleFromASN,
}) => (
  <Stack direction="row" spacing={2}>
    <QueryAnswerInput
      title={PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN.answerTitle1}
      textField={
        <QueryAnswerInputAutocomplete
          options={originASNsToSelect}
          selectedItem={selectedOriginASN}
          setSelectedItem={setSelectedOriginASN}
          loading={originASNsToSelectLoading}
          error={originASNsToSelectError}
        />
      }
    />
    <QueryAnswerInput
      title={PREFIXES_FROM_ORIGIN_ASN_VISIBLE_FROM_ASN.answerTitle2}
      textField={
        <QueryAnswerInputAutocomplete
          options={visibleFromASNsToSelect}
          selectedItem={selectedVisibleFromASN}
          setSelectedItem={setSelectedVisibleFromASN}
          loading={visibleFromASNsToSelectLoading}
          error={visibleFromASNsToSelectError}
        />
      }
    />
  </Stack>
);

/**
 * @param {PrefixesFromOriginAsnVisibleFromAsnSubscription | undefined} prefixesData
 * @returns {(string | null | undefined)[][] | undefined}
 */
export const getPrefixes = (prefixesData) =>
  prefixesData?.prefixes
    .filter(({ network }) => !!network)
    .map(({ network }) => [network]);
