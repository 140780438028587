import { useCallback, useEffect } from "react";
import { PrimitiveAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { DataResult } from "../types/store";

/**
 * This hook wraps urql calls and is used to:
 * 1) Fetch data
 * 2) Print data fetching errors to console
 * 3) Format incoming data depending on their type
 * 4) Load the formatted data to global state
 *
 * @param {object} params
 * @param {Function} params.operation - The operation used to fetch data
 * @param {object} [params.options] - The operation options
 * @param {Function} [params.formatter] - The function used to format incoming data
 * @param {any[]} [params.formatterParams] - The optional parameters of formatter
 * @param {Partial<DataResult<any>>} [params.confState] - The state of the configuration query used as filter
 * @param {PrimitiveAtom<any>} params.atom - The atom used to store the formatted data
 */
export const useData = ({
  operation,
  options,
  formatter,
  formatterParams,
  confState,
  atom,
}) => {
  const [{ data, fetching, error }, reexecuteOperation] = operation(options);

  if (error) {
    console.error(error);
  }

  let formattedData = data;

  if (formatter) {
    if (formatterParams) {
      formattedData = formatter(data, ...formatterParams);
    } else {
      formattedData = formatter(data);
    }
  }

  const setDataCallback = useAtomCallback(
    useCallback(
      (_, set) => {
        set(atom, {
          data: formattedData,
          fetching: confState?.fetching || fetching,
          error: confState?.error ?? error,
          reexecuteOperation,
        });
      },
      [atom, formattedData, confState, fetching, error, reexecuteOperation]
    )
  );

  useEffect(() => {
    setDataCallback();
  }, [setDataCallback]);
};
