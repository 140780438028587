import { useConfiguredAlerts } from "../hooks/useConfiguredAlerts";
import { useConfiguredASNs } from "../hooks/useConfiguredASNs";
import { useConfiguredDataServices } from "../hooks/useConfiguredDataServices";
import { useConfiguredPrefixes } from "../hooks/useConfiguredPrefixes";
import { MainGlobalState } from "./MainGlobalState";

export const ConfGlobalState = () => {
  useConfiguredASNs();
  useConfiguredPrefixes();
  useConfiguredAlerts();
  useConfiguredDataServices();

  return <MainGlobalState />;
};
