import * as React from "react";
import { ReactElement, MouseEventHandler, useState } from "react";
import { SvgWrapper } from "./SvgWrapper";
import { IconButton, ListItem, Stack, Tooltip } from "@mui/material";
import { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { COLUMNS_ENUM } from "../constants/components/confAlertsTable";
import { usePopupState } from "material-ui-popup-state/hooks";

/**
 * @param {Function} props.sortCallback
 * @returns {ReactElement}
 */
export const SortAlertRulesDataButton = ({ sortCallback }) => {
  const iconJSX = (
    <SvgWrapper
      filename="SortByIcon"
      sx={{ "&:hover": { color: "#0c3153" } }}
    />
  );

  const IgnoreDisabledListItem = React.forwardRef(
    function IgnoreDisabledListItem({ disabled, ...other }, ref) {
      return (
        <ListItem
          {...other}
          ref={ref}
          style={{ fontFamily: "Roboto", color: "#646464", fontWeight: 500 }}
        />
      );
    }
  );

  const [nameButton, setNameButton] = useState(true);
  const [timeStampButton, setTimeStampButton] = useState(false);
  const [typeButton, setTypeButton] = useState(false);

  const handleCLick = (clickedButton) => {
    if (clickedButton === "name") {
      sortCallback({ name: COLUMNS_ENUM.NAME.name, direction: "asc" });
      setNameButton(true);
      setTimeStampButton(false);
      setTypeButton(false);
    } else if (clickedButton === "timestamp") {
      sortCallback({
        name: "Time Created",
        direction: "desc",
      });
      setNameButton(false);
      setTimeStampButton(true);
      setTypeButton(false);
    } else {
      sortCallback({ name: COLUMNS_ENUM.TYPE.name, direction: "asc" });
      setNameButton(false);
      setTimeStampButton(false);
      setTypeButton(true);
    }
  };

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-menu",
  });

  const buttonJSX = (
    <Stack style={{ order: -1 }}>
      <Tooltip title="Sort By">
        <IconButton
          variant="contained"
          {...bindTrigger(popupState)}
          style={{ order: -1 }}
          sx={{ color: "#727272" }}
        >
          {iconJSX}
        </IconButton>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          style: {
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            background: "#F9F9F9",
            borderRadius: "4px",
          },
        }}
      >
        <IgnoreDisabledListItem disabled>
          {"Sort By"}
          <IconButton sx={{ marginLeft: "40px" }} onClick={popupState.close}>
            <CloseIcon />
          </IconButton>
        </IgnoreDisabledListItem>
        <MenuItem onClick={() => handleCLick("name")} selected={nameButton}>
          Name
        </MenuItem>
        <MenuItem
          onClick={() => handleCLick("timestamp")}
          selected={timeStampButton}
        >
          Time Created
        </MenuItem>
        <MenuItem onClick={() => handleCLick("type")} selected={typeButton}>
          Type
        </MenuItem>
      </Menu>
    </Stack>
  );
  return buttonJSX;
};
