import { useBgpUpdateRateSubscription } from "../graphql/generated/operations";
import { useData } from "./useData";
import { bgpUpdateRateCountAtom } from "../store/overviewContent";
import { formatter } from "../utils/hooks/useBGPUpdateRateCount";

export const useBGPUpdateRateCount = () => {
  useData({
    operation: useBgpUpdateRateSubscription,
    formatter,
    atom: bgpUpdateRateCountAtom,
  });
};
