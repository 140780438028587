import { ReactElement } from "react";
// import { useNavigate } from "react-router-dom";
import { Card, Stack, Typography } from "@mui/material";
// import { ActionButton } from "./ActionButton";
import { defaultSX } from "../constants/components/overviewCard";
import {
  bgpUpdateRateCountAtom,
  avgBGPUpdateRateCountAtom,
} from "../store/overviewContent";
import { SvgWrapper } from "./SvgWrapper";
import { BGPUpdateRatesCardSection } from "./BGPUpdateRatesCardSection";
// import { PATHS } from "../constants/paths";

/**
 * @param {object} props
 * @param {number} props.numberOfColumns
 * @returns {ReactElement}
 */
export const BGPUpdateRatesOverviewCard = ({ numberOfColumns }) => {
  // const navigate = useNavigate();

  const cardSX = {
    ...defaultSX,
    gridColumn: "span 3",
    p: "0rem",
    height: "76px",
  };

  const cardSXVertical = {
    ...defaultSX,
    gridColumn: "span 1",
    p: "0rem",
    height: "250px",
  };

  if (numberOfColumns > 2) {
    return (
      <Card sx={cardSX}>
        <Stack spacing={8} direction={"row"}>
          <Stack sx={{ backgroundColor: "#0C3153" }}>
            <Stack
              direction={"row"}
              sx={{
                color: "white",
                margin: "auto",
                marginLeft: "48px",
                marginRight: "48px",
              }}
            >
              <SvgWrapper filename={"SpeedIcon"} />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  marginLeft: "16px",
                }}
              >
                BGP update rates
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            <BGPUpdateRatesCardSection
              rateName="Current (#/min)"
              atom={bgpUpdateRateCountAtom}
              numberOfColumns={numberOfColumns}
            />
            <BGPUpdateRatesCardSection
              rateName="Daily Avg. (#/min)"
              atom={avgBGPUpdateRateCountAtom}
              numberOfColumns={numberOfColumns}
            />
          </Stack>
        </Stack>
      </Card>
    );
  } else {
    return (
      <Card sx={cardSXVertical}>
        <Stack spacing={3}>
          <Stack sx={{ backgroundColor: "#0C3153" }}>
            <Stack
              direction={"row"}
              sx={{
                color: "white",
                p: "24px",
                marginRight: "48px",
              }}
            >
              <SvgWrapper filename={"SpeedIcon"} />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  marginLeft: "15px",
                }}
              >
                BGP update rates
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={5}>
            <BGPUpdateRatesCardSection
              rateName="Current (#/min)"
              atom={bgpUpdateRateCountAtom}
              numberOfColumns={numberOfColumns}
            />
            <BGPUpdateRatesCardSection
              rateName="Daily Avg. (#/min)"
              atom={avgBGPUpdateRateCountAtom}
              numberOfColumns={numberOfColumns}
            />
          </Stack>
        </Stack>
      </Card>
    );
  }
};
