import { ReactElement, useEffect } from "react";
import { Table } from "./Table";
import {
  getColumns,
  getCustomOptions,
  getSources,
  onViewColumnsChange,
} from "../utils/dataSourcesTable";
import { getDataSourcesTableStateAtom } from "../utils/other/store";
import { useAtom } from "jotai";
import { TABLE_KEY_ENUM } from "../constants/components/table";

/**
 * @param {object} props
 * @param {Function} props.sourcesSubscription
 * @param {object} props.options
 * @param {string} props.title
 * @param {object} [props.parentComponent]
 * @param {string} props.atomKey
 * @param {string} props.exportDataFileName
 * @returns {ReactElement}
 */
export const DataSourcesTable = ({
  sourcesSubscription,
  options,
  title,
  parentComponent,
  atomKey,
  exportDataFileName,
}) => {
  const [
    { data: sourcesData, fetching: sourcesFetching, error: sourcesError },
    reexecuteSourcesSubscription,
  ] = sourcesSubscription(options);

  useEffect(() => {
    reexecuteSourcesSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sources = getSources({ sourcesData, parentComponent });

  const {
    pageAtom,
    rowsPerPageAtom,
    countAtom,
    isDataServiceVisibleAtom,
    isRouteCollectorVisibleAtom,
    isIPVisibleAtom,
    isASNVisibleAtom,
    isCityVisibleAtom,
    isCountryVisibleAtom,
    isContinentVisibleAtom,
    isLastUpdateVisibleAtom,
    isURIVisibleAtom,
    isEndpointVisibleAtom,
  } = getDataSourcesTableStateAtom({ atomKey });
  const [isDataServiceVisible, setIsDataServiceVisible] = useAtom(
    isDataServiceVisibleAtom
  );
  const [isRouteCollectorVisible, setIsRouteCollectorVisible] = useAtom(
    isRouteCollectorVisibleAtom
  );
  const [isIPVisible, setIsIPVisible] = useAtom(isIPVisibleAtom);
  const [isASNVisible, setIsASNVisible] = useAtom(isASNVisibleAtom);
  const [isCityVisible, setIsCityVisible] = useAtom(isCityVisibleAtom);
  const [isCountryVisible, setIsCountryVisible] = useAtom(isCountryVisibleAtom);
  const [isContinentVisible, setIsContinentVisible] = useAtom(
    isContinentVisibleAtom
  );
  const [isLastUpdateVisible, setIsLastUpdateVisible] = useAtom(
    isLastUpdateVisibleAtom
  );
  const [isURIVisible, setIsURIVisible] = useAtom(isURIVisibleAtom);
  const [isEndpointVisible, setIsEndpointVisible] = useAtom(
    isEndpointVisibleAtom
  );

  /**
   * @param {object} params
   * @param {string} params.changedColumn
   * @param {string} params.action
   * @returns {void}
   */
  const onViewColumnsChangeCallback = ({ changedColumn, action }) => {
    onViewColumnsChange({
      changedColumn,
      action,
      setIsDataServiceVisible,
      setIsRouteCollectorVisible,
      setIsIPVisible,
      setIsASNVisible,
      setIsCityVisible,
      setIsCountryVisible,
      setIsContinentVisible,
      setIsLastUpdateVisible,
      setIsURIVisible,
      setIsEndpointVisible,
    });
  };

  const columns = getColumns({
    sources,
    parentComponent,
    isDataServiceVisible,
    isRouteCollectorVisible,
    isIPVisible,
    isASNVisible,
    isCityVisible,
    isCountryVisible,
    isContinentVisible,
    isLastUpdateVisible,
    isURIVisible,
    isEndpointVisible,
  });
  const customOptions = getCustomOptions({ parentComponent });
  const [count, setCount] = useAtom(countAtom);

  return (
    <Table
      title={title}
      data={sources}
      loading={sourcesFetching}
      error={sourcesError}
      columns={columns}
      themeKey={TABLE_KEY_ENUM.INNER}
      customOptions={customOptions}
      pageAtom={pageAtom}
      rowsPerPageAtom={rowsPerPageAtom}
      count={count}
      setCount={setCount}
      onViewColumnsChange={onViewColumnsChangeCallback}
      exportDataFileName={exportDataFileName}
    />
  );
};
