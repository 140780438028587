import { alertSeverity } from "../../constants/components/confAlertsInfoTable";
import { getValueFromEnum } from "../formatting";
import { FORMAT_RPKI_ENUM } from "../../constants/components/routes";

/**
 * @param {object} args
 * @returns {object[]}
 */
export const getRows = (args) => {
  const rows = [];
  for (var [key, value] of Object.entries(args)) {
    switch (key) {
      case "severity":
        value = alertSeverity[value];
        break;
      case "rpki_status":
        value = getValueFromEnum(FORMAT_RPKI_ENUM, value);
        break;
      default:
        break;
    }
    rows.push({ parameter: key, value });
  }
  return rows;
};
