import { Box, Typography } from "@mui/material";
import { CountryTooltip } from "../components/CountryTooltip";
import { TooltipLinearProgress } from "../components/TooltipLinearProgress";

export const getNameJSX = (name, nameLoading, nameError) => {
  let nameJSX;

  if (nameLoading) {
    nameJSX = <TooltipLinearProgress />;
  } else if (nameError) {
    nameJSX = "Could not fetch name.";
  } else {
    nameJSX = name ?? "Not found.";
  }

  return nameJSX;
};

export const getCountriesJSX = (
  countries,
  countriesLoading,
  countriesError,
  fontSize
) => {
  let countriesJSX;

  if (countriesLoading) {
    countriesJSX = <TooltipLinearProgress />;
  } else if (countriesError) {
    countriesJSX = "Could not fetch country.";
  } else if (countries && countries.length) {
    countriesJSX = (
      <Box sx={{ width: "50%" }}>
        {countries.map((countryCode) => (
          <CountryTooltip key={countryCode} countryCode={countryCode} />
        ))}
      </Box>
    );
  } else {
    countriesJSX = (
      <Typography sx={{ width: "50%", color: "blue1.main", fontSize }}>
        Not found.
      </Typography>
    );
  }

  return countriesJSX;
};

export const getAbuseEmailsJSX = (
  abuseEmails,
  abuseEmailsLoading,
  abuseEmailsError
) => {
  let abuseEmailsJSX;

  if (abuseEmailsLoading) {
    abuseEmailsJSX = <TooltipLinearProgress />;
  } else if (abuseEmailsError) {
    abuseEmailsJSX = "Could not fetch abuse contact.";
  } else {
    abuseEmailsJSX = abuseEmails ?? "Not found.";
  }

  return abuseEmailsJSX;
};
