import { useState, useEffect } from "react";
import { WelcomeEditorDialog } from "./WelcomeEditorDialog";
import { Snackbar, Stack } from "@mui/material";
import { Alert } from "../components/Alert";
import { useOpenClose } from "../hooks/useOpenClose";
import { useOpenCloseSnackbar } from "../hooks/useOpenCloseSnackbar";
import { useRole } from "../hooks/useRole";
import { ROLES } from "../other/constants";
import { useAtom } from "jotai";
import { configuredASNsAtom, configuredPrefixesAtom } from "../other/store";
import { noAutonomousSystemsConfigured } from "../utils/hooks/useConfiguredASNs";

export const WelcomeDialog = () => {
  const role = useRole();
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(configuredASNsAtom);

  const [
    {
      data: configuredPrefixes,
      fetching: configuredPrefixesFetching,
      error: configuredPrefixesError,
    },
  ] = useAtom(configuredPrefixesAtom);

  const {
    isOpen: isWelcomeEditorDialogOpen,
    open: openWelcomeEditorDialog,
    close: closeWelcomeEditorDialog,
  } = useOpenClose();
  const {
    isOpen: isSnackbarOpen,
    open: openSnackbar,
    close: closeSnackbar,
  } = useOpenCloseSnackbar();

  const isEditor = role === ROLES.editor;
  const shouldShowDialog =
    (isEditor &&
      !configuredASNsError &&
      !configuredASNsFetching &&
      noAutonomousSystemsConfigured(configuredASNs) &&
      !configuredPrefixesError &&
      !configuredPrefixesFetching &&
      configuredPrefixes?.length === 0) || //TO-DO: create noPrefixesConfigured function
    isWelcomeEditorDialogOpen;

  const [alertMessage, setAlertMessage] = useState({ type: "", message: "" });

  useEffect(() => {
    if (shouldShowDialog) {
      openWelcomeEditorDialog();
    }
  }, [shouldShowDialog, openWelcomeEditorDialog]);

  const welcomeDialog = shouldShowDialog ? (
    <Stack>
      <WelcomeEditorDialog
        isOpen={isWelcomeEditorDialogOpen}
        close={closeWelcomeEditorDialog}
        openSnackbar={openSnackbar}
        setAlertMessage={setAlertMessage}
      />
    </Stack>
  ) : undefined;

  return (
    <Stack>
      {welcomeDialog}
      <Snackbar
        open={isSnackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={alertMessage.type}>
          {alertMessage.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
