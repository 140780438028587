import { ReactElement } from "react";
import { Stack } from "@mui/material";
import { AlertFieldAndValue } from "./AlertFieldAndValue";

/**
 * @param {object} props
 * @param {string} props.startedDate
 * @param {string} props.endedDate
 * @param {string} props.receiverType
 * @param {string} props.receiverEndpoint
 * @param {string} props.selectedTab
 * @returns {ReactElement}
 */
export const AlertsExpandedRowHeader = ({
  startedDate,
  endedDate,
  receiverType,
  receiverEndpoint,
  selectedTab,
}) => {
  const notifications = receiverType + " (" + receiverEndpoint + ")";

  switch (selectedTab) {
    case "active":
      return (
        <Stack direction="row" fontSize="0.875rem">
          <Stack sx={{ mr: "0.5rem" }}>
            <AlertFieldAndValue
              field="Started"
              value={startedDate}
              fontSize="0.875rem"
            />
          </Stack>
          <Stack sx={{ ml: "1.5rem" }}>
            <AlertFieldAndValue
              field="Notifications"
              value={notifications}
              fontSize="0.875rem"
            />
          </Stack>
        </Stack>
      );
    case "resolved":
      return (
        <Stack direction="row" fontSize="0.875rem">
          <Stack sx={{ mr: "0.5rem" }}>
            <AlertFieldAndValue
              field="Started"
              value={startedDate}
              fontSize="0.875rem"
            />
          </Stack>
          |
          <Stack sx={{ mr: "0.5rem", ml: "0.5rem" }}>
            <AlertFieldAndValue
              field="Ended"
              value={endedDate}
              fontSize="0.875rem"
            />
          </Stack>
          <Stack sx={{ ml: "1.5rem" }}>
            <AlertFieldAndValue
              field="Notifications"
              value={notifications}
              fontSize="0.875rem"
            />
          </Stack>
        </Stack>
      );
    default:
      return <></>;
  }
};
