import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Stack, Typography } from "@mui/material";
import { ActionButton } from "./ActionButton";
import { defaultSX } from "../constants/components/overviewCard";
import { SvgWrapper } from "./SvgWrapper";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.redirectURL
 * @param {ReactElement} [props.numberOfItemsJSX]
 * @param {string} [props.buttonTitle]
 * @param {string} [props.icon]
 * @returns {ReactElement}
 */
export const OverviewCard = ({
  title,
  redirectURL,
  numberOfItemsJSX,
  buttonTitle = "View",
  icon,
}) => {
  const navigate = useNavigate();

  const content = icon ? (
    <Stack sx={{ justifyContent: "space-between" }} direction="row">
      <SvgWrapper
        filename={icon}
        sx={{ color: "blue4.main", mr: "0.5rem", ml: "-0.7rem", fontSize: 48 }}
      />
      <Typography sx={{ whiteSpace: "pre" }}>{title}</Typography>
    </Stack>
  ) : (
    <Typography sx={{ pb: "0.35rem" }}>{title}</Typography>
  );

  return (
    <Card sx={defaultSX}>
      <Stack sx={{ justifyContent: "space-between" }}>
        <Stack>
          {content}
          {numberOfItemsJSX}
        </Stack>
        <ActionButton
          customStyles={{ width: "10rem" }}
          title={buttonTitle}
          onClick={() => {
            navigate(redirectURL);
          }}
        />
      </Stack>
    </Card>
  );
};
