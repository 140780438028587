import { ReactElement, MouseEventHandler } from "react";
import { SecondaryButton } from "./SecondaryButton";

/**
 * @param {object} props
 * @param {MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {object} [props.customStyles]
 * @returns {ReactElement}
 */
export const CancelButton = ({ onClick, customStyles }) => {
  return (
    <SecondaryButton
      onClick={onClick}
      customStyles={customStyles}
      title="Cancel"
    />
  );
};
