import { ReactElement } from "react";
import {
  COLUMNS_ENUM,
  COMMUNITIES_COLUMN_INDEX,
  NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM,
  SELECT_PREFIX,
} from "../constants/queries";
import {
  getGraphqlAPILink,
  getTextFieldFilterTypeColumn,
  getAnswerTableData,
  getSubscription,
} from "../other/utils";
import { QueryEntry } from "./QueryEntry";
import { useSelectedValue } from "../hooks/useSelectedValue";
import { TableCell, TableRow } from "@mui/material";
import {
  getCommunitiesColumn,
  getNeighborsToWhichPrefixIsAnnounced,
  isQueryTableRowExpandable,
} from "../utils/queries";
import { formatASN } from "../utils/formatting";
import { useAtom } from "jotai";
import { displayedConfiguredASNsAtom } from "../other/store";
import {
  NeighborsToWhichPrefixIsAnnouncedDocument,
  useNeighborsToWhichPrefixIsAnnouncedSubscription,
} from "../graphql/generated/operations";
import {
  getAnswerInputJSX,
  getRowID,
} from "../utils/components/neighborsToWhichPrefixIsAnnouncedQuery";
import { getQueryAnswerTableStateAtom } from "../utils/other/store";

const MY_ASN_COLUMN = 0;
const NEIGHBOR_ASN_COLUMN = 1;

/**
 * @param {object} props
 * @param {string[]} props.prefixesToSelect
 * @returns {ReactElement}
 */
export const NeighborsToWhichPrefixIsAnnouncedQuery = ({
  prefixesToSelect,
}) => {
  const [
    {
      data: configuredASNs,
      fetching: configuredASNsFetching,
      error: configuredASNsError,
    },
  ] = useAtom(displayedConfiguredASNsAtom);
  const {
    selectedValue: selectedPrefix,
    setSelectedValue: setSelectedPrefix,
    hasSelectedValue,
  } = useSelectedValue({ defaultValue: SELECT_PREFIX });
  const [{ data, fetching, error }] =
    useNeighborsToWhichPrefixIsAnnouncedSubscription({
      variables: { asns: configuredASNs, prefix: selectedPrefix },
      pause: !hasSelectedValue,
    });
  const {
    isConfiguredASVisibleAtom,
    isNeighborASVisibleAtom,
    isCommunitiesVisibleAtom,
  } = getQueryAnswerTableStateAtom({
    atomKey: NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM.atomKey,
  });
  const [isConfiguredASVisible] = useAtom(isConfiguredASVisibleAtom);
  const [isNeighborASVisible] = useAtom(isNeighborASVisibleAtom);
  const [isCommunitiesVisible] = useAtom(isCommunitiesVisibleAtom);

  const answerTableData = getAnswerTableData(
    hasSelectedValue,
    data,
    getNeighborsToWhichPrefixIsAnnounced
  );

  const answerTableColumns = [
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.CONFIGURED_AS.name, {
      display: isConfiguredASVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASN(answerTableData, dataIndex, MY_ASN_COLUMN),
    }),
    getTextFieldFilterTypeColumn(COLUMNS_ENUM.NEIGHBOR_AS.name, {
      display: isNeighborASVisible,
      customBodyRenderLite: (dataIndex) =>
        formatASN(answerTableData, dataIndex, NEIGHBOR_ASN_COLUMN),
    }),
    getCommunitiesColumn(answerTableData, isCommunitiesVisible),
  ];

  const answerInputJSX = getAnswerInputJSX({
    prefixesToSelect,
    selectedPrefix,
    setSelectedPrefix,
    configuredASNsFetching,
    configuredASNsError,
  });

  return (
    <QueryEntry
      questionTitle={NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM.questionTitle}
      answerInput={answerInputJSX}
      loading={fetching}
      error={error}
      hasSelectedValue={hasSelectedValue}
      answerTableData={answerTableData}
      answerTableColumns={answerTableColumns}
      answerTableOptions={{
        expandableRows: true,
        isRowExpandable: (dataIndex) =>
          isQueryTableRowExpandable(dataIndex, answerTableData),
        renderExpandableRow: (rowData, rowMeta) => {
          const formattedCommunities = answerTableData[rowMeta.rowIndex][
            COMMUNITIES_COLUMN_INDEX
          ]?.split(" ").map((x) => `${x}\n`);
          return (
            <TableRow>
              <TableCell sx={{ whiteSpace: "pre" }} colSpan={1}>
                <b>Communities:</b>
                <br />
                {formattedCommunities}
              </TableCell>
            </TableRow>
          );
        },
      }}
      graphqlAPILink={getGraphqlAPILink(
        getSubscription(NeighborsToWhichPrefixIsAnnouncedDocument),
        JSON.stringify({ asns: configuredASNs, prefix: selectedPrefix })
      )}
      atomKey={NEIGHBORS_TO_WHICH_PREFIX_IS_ANNOUNCED_ENUM.atomKey}
      getRowID={getRowID}
    />
  );
};
