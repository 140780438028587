import PropTypes from "prop-types";
import { AccordionSummary, Typography } from "@mui/material";
import { SvgWrapper } from "./SvgWrapper";

export const QueryQuestion = ({ title }) => {
  return (
    <AccordionSummary
      sx={{ flexDirection: "row-reverse" }}
      expandIcon={
        <SvgWrapper
          sx={{ color: "blue2.main", fontSize: "larger" }}
          filename={"DropdownArrowIcon"}
          viewBox="0 0 26 26"
        />
      }
    >
      <Typography sx={{ color: "blue2.main", fontSize: "large", pl: "1rem" }}>
        {title}
      </Typography>
    </AccordionSummary>
  );
};

QueryQuestion.propTypes = {
  title: PropTypes.string.isRequired,
};
